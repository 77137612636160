import styled from 'styled-components'
import * as Icon from 'react-bootstrap-icons'
import { BarBG, DateWrapper, DayRange, BasicInput } from '../../../middleware/utilityStyle'

export const InputBarBg = styled(BarBG)`
  @media screen and (max-width: 1910px){
    & {
      flex-direction: column;
      justify-content: center;
    }
  }
`

export const InputBarRWD = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 1910px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`

export const FirstInputBar = styled.div`
  @media screen and (max-width: 1910px) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
`

export const InputBar = styled.div`
  margin-left: 20px;
  @media screen and (max-width: 1910px) {
    display: flex;
    justify-content: space-between;
    margin-left: 0px;
    margin-bottom: 10px;
    width: 263px;
  }
  
`

export const Input = styled(BasicInput)`
  width: 195px;
  margin-left: 20px;
`

export const IconStyle = styled(Icon.CalendarRange)`
  color: orange;
`

export const SignUpWrapper = styled(DateWrapper)`
  margin-left: 20px;
  @media screen and (max-width: 1910px) {
    flex-direction: column;
  }
`

export const LastGameWrapper = styled(DateWrapper)`
  @media screen and (max-width: 1910px) {
    flex-direction: column;
  }
`

export const DatePickerInput = styled(BasicInput)`
  width: 195px;
`

export const RangeGroup = styled(DayRange)`
  @media screen and (max-width: 1910px) {
    margin-top: 10px;
  }
`

export const NavRTwo = styled.div`
  display: flex;
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 1910px) {
    flex-direction: column;
    justify-content: center;
    margin-top: 0px;
  }
`

export const RangeInputStyle = styled(BasicInput)`
  width: 150px;
`

export const Start = styled.div`
  margin-left: 20px;
`

export const StartInput = styled(BasicInput)`
  width: 150px;
  margin-left: 20px;
`

export const End = styled.div`
  margin-left: 5px;
`

export const EndInput = styled(BasicInput)`
  width: 150px;
  margin-left: 5px;
`

export const BtnGroup = styled.div`
  @media screen and (max-width: 1910px){
    & {
      margin-top: 10px;
      display: flex;
      justify-content: center;
    }
  }
`

export const Frame = styled.td`
  height: 160px;
  width: 160px;
  position: relative;
`

export const Avatar = styled.img`
  max-height: 100%;  
  max-width: 100%; 
  width: auto;
  height: auto;
  position: absolute;  
  top: 0;  
  bottom: 0;  
  left: 0;  
  right: 0;  
  margin: auto;
`