import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import Stack from '@mui/material/Stack'
import { TimezoneTranslator } from "../../../middleware/dateUtils"
import errorCode from "../../../assets/config/errorCode.json"
import AddRevisePU from "../../../components/popUpWindow/dzbMissionEvent/AddRevisePU"
import { getAccessArr } from "../../../middleware/utils"
import { getDZBMissionEventList } from "../../../middleware/Api/eventManagement/dzbMissionEventApi.js"
import {
  BasicTable,
  BasicRowTitle,
  TableContent,
  AddBtn,
  PopDetail,
  PageStyle
} from "../../../middleware/utilityStyle.js"

export default function dzbMissionEvent() {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const history = useHistory()
  const [panelInfo, setPanelInfo] = useState([])
  const [dataCopy, setDataCopy] = useState([])
  const [targetObj, setTargetObj] = useState({
    ACTIVITY_ID: '',
    ACTIVITY_NAME: '',
    START_DATE: null,
    END_DATE: null,
    CLOSE_DATE: null,
    GAME_ID: '',
    GEM_MISSION_STATE: 0,
    AD_RESET_TIMES: '',
    GEM_MISSION_TEXT: '',
    REACH_TIMES: '',
    SEND_GEM_NUM: ''
  })
  const [calender, setCalender] = useState({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
    closeDate: null,
    closeTime: null
  })

  const [clickStatus, setClickStatus] = useState('')
  const [page, setPage] = useState(1)
  const [pageCounter, setPageCounter] = useState(0)  //最大頁數
  const handleChangePage = (event, newPage) => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPage(newPage)
    })
  }

  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      getDZBMissionEventList().then(db=> {
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if (db.error === errorCode.SERVER_ERROR.code) {
          alert(`※  ${errorCode.SERVER_ERROR.memo}`)
          return
        }
        let data = []
        for (var i = 0; i < db.data.length; i += 30) {
          data.push(db.data.slice(i, i + 30))
        }
        setPageCounter(data.length)
        setPanelInfo(data)
        setDataCopy(db.data)
      })    
    })        
  }, [])

  const handleSearchReport = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 87)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      history.push(`/admin/dzbMissionEventReport?activityID=${+e.target.dataset.activityid}&activityName=${e.target.dataset.activityname}`)
    })
  }

  const handleIndividualSetting = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 85)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      history.push(`/admin/dzbMissionIndividualSetting?activityID=${+e.target.dataset.activityid}&activityName=${e.target.dataset.activityname}`)
    })
  }

  function handleCleanPopData() {
    setTargetObj({
      ACTIVITY_ID: '',
      ACTIVITY_NAME: '',
      START_DATE: null,
      END_DATE: null,
      CLOSE_DATE: null,
      GAME_ID: '',
      GEM_MISSION_STATE: 0,
      AD_RESET_TIMES: '',
      GEM_MISSION_TEXT: '',
      REACH_TIMES: '',
      SEND_GEM_NUM: ''
    })
    let newCalender = (JSON.parse(JSON.stringify(calender)))
      for (let key in newCalender) {
        newCalender[key] = null
      }
    setCalender(newCalender)
  }
  const [showAddReviseMissionEvent, setAddReviseMissionEventShow] = useState(false)
  const handleAddReviseMissionEventShow = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setAddReviseMissionEventShow(true)
    })
  }
  const handleAddReviseMissionEventClose = () => setAddReviseMissionEventShow(false)
  const handleReviseActivity = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 84)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      } else {
        setSwitchSave(true)
        setSwitchDelete(true)
        setClickStatus('revise')
      }
      handleAddReviseMissionEventShow()
      setTargetObj(dataCopy.find(ele => +ele.ACTIVITY_ID === +e.target.dataset.activityid))
    })
  }
  const [switchDelete, setSwitchDelete] = useState(false)
  const [switchSave, setSwitchSave] = useState(false)

  const handleAddMissionEvent = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 83)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      handleAddReviseMissionEventShow()
      setSwitchSave(true)
      setSwitchDelete(false)
      setClickStatus('add')
      let newCalender = (JSON.parse(JSON.stringify(calender)))
        for (let key in newCalender) {
          newCalender[key] = null
        }
      setCalender(newCalender)
    })
  }

  return (
    <>
      <BasicTable className="table table-hover table-bordered">
        <thead>
          <BasicRowTitle>
            <th scope="col">活動ID</th>
            <th scope="col">活動名稱</th>
            <th scope="col">起始時間</th>
            <th scope="col">結束時間</th>
            <th scope="col">關閉時間</th>
            <th scope="col">總達成數</th>
            <th scope="col">鬥陣寶發出數量</th>
            <th scope="col">活動報表</th>
            <th scope="col">活動開啟狀態</th>
            <th scope="col">個別任務設定</th>
            <th scope="col">活動設定</th>
          </BasicRowTitle>
        </thead>
        <tbody>
          {
            Array.isArray(panelInfo) && panelInfo.length ?
              (panelInfo[page-1].map((data, index) => {
                return (
                  <TableContent key= {index}>
                    <td>{data.ACTIVITY_ID}</td>
                    <td>{data.ACTIVITY_NAME}</td>
                    <td>{data.START_DATE ? TimezoneTranslator(data.START_DATE) : ''}</td>
                    <td>{data.END_DATE ? TimezoneTranslator(data.END_DATE) : ''}</td>
                    <td>{data.CLOSE_DATE ? TimezoneTranslator(data.CLOSE_DATE) : ''}</td>
                    <td>{data.REACH_TIMES}</td>
                    <td>{data.SEND_GEM_NUM}</td>
                    <PopDetail onClick= {handleSearchReport} data-activityid= {data.ACTIVITY_ID} data-activityname= {data.ACTIVITY_NAME}>查詢</PopDetail>
                    {data.GEM_MISSION_STATE ? <td style= {{color: '#00cc00'}}>開啟</td> : <td style= {{color: 'red'}}>關閉</td>}
                    <PopDetail onClick= {handleIndividualSetting} data-activityid= {data.ACTIVITY_ID} data-activityname= {data.ACTIVITY_NAME}>修改</PopDetail>
                    <PopDetail onClick= {handleReviseActivity} data-activityid= {data.ACTIVITY_ID} data-activityname= {data.ACTIVITY_NAME}>修改</PopDetail>
                  </TableContent>
                )
              }))
            : <tr key= "noInfo"><td colSpan= "11">尚無資料</td></tr>
          }
        </tbody>
        <tfoot></tfoot>
      </BasicTable>
      {
        Array.isArray(panelInfo) && panelInfo.length ?
          <Stack spacing={2}>
            <PageStyle count={pageCounter}
              page= {page}
              color="warning"
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </Stack>
        : ''
      }

      <AddBtn onClick= {handleAddMissionEvent}>新增</AddBtn>

      <AddRevisePU
        showAddReviseMissionEvent= {showAddReviseMissionEvent}
        handleAddReviseMissionEventClose= {handleAddReviseMissionEventClose}
        handleCleanPopData= {handleCleanPopData}
        targetObj= {targetObj}
        clickStatus= {clickStatus}
        switchSave = {switchSave}
        switchDelete= {switchDelete}
        calender= {calender}
        setPageCounter= {setPageCounter}
        setPanelInfo= {setPanelInfo}
        setDataCopy= {setDataCopy}
        setPage= {setPage}
      />
    </>
  )
}