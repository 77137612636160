import Modal from 'react-bootstrap/Modal'
import React from "react";
import { getAllAuth } from "../../../middleware/Api/publicApi";
import { deleteAuth } from "../../../middleware/Api/authApi";
import { Button } from "react-bootstrap"
import { ModalBtn, ModalText } from "../../../middleware/utilityStyle"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"

export default function DeleteAuth(props) {
  const {
    popData,
    showDelete,
    handleDeleteClose,
    setPanelInfo,
    history,
    setAuthInfo
  } = props

  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      deleteAuth(popData.ID).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        handleDeleteClose()
        getAllAuth().then(db => {
          if (db.error === errorCode.NULL_DATA.code) {
            alert(`※  ${errorCode.NULL_DATA.memo}`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          setPanelInfo(db.data)
          setAuthInfo(db.data)
        })
      })
    })
  }

  return (
    <Modal
      show={showDelete}
      onHide={handleDeleteClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>刪除確認</Modal.Title>
      </Modal.Header>
      <ModalText>
        將刪除權限值 : {popData.ID}，真的確定要嗎？
      </ModalText>
      <ModalBtn>
        <Button variant="info" onClick={handleDelete}>要</Button>
        <Button variant="light" onClick={handleDeleteClose}>先不要</Button>
      </ModalBtn>
    </Modal>
  )
}