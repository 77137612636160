import styled from 'styled-components'
import { RangePack, DayRange, BasicInput, BasicRowTitle } from '../../../middleware/utilityStyle'

export const InputBarRWD = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 1705px) {
    flex-direction: column;
  }
`

export const FirstInputBar = styled.div`
  @media screen and (max-width: 1705px) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 334px;
  }
`

export const InputBar = styled.div`
  margin-left: 20px;
  @media screen and (max-width: 1705px) {
    display: flex;
    justify-content: space-between;
    margin-left: 0px;
    margin-bottom: 10px;
    width: 334px;
  }
`

export const SelectWrapper = styled.span`
  margin-left: 20px;
  @media screen and (max-width: 1705px) {
    display: flex;
    justify-content: space-between;
    margin-left: 0px;
    margin-bottom: 10px;
  }
`


export const Dropdown = styled.select`
  margin-left: 20px;
  border: 1px solid black;
  border-radius: 5px;
  height: 1.7rem;
  width: 250px;
`

export const SecondRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  @media screen and (max-width: 1705px) {
    flex-direction: column;
    margin-top: 0px;
  }
`

export const Input = styled(BasicInput)`
  width: 250px;
  margin-left: 20px;
`

export const FirstSelect = styled.div`
  @media screen and (max-width: 1705px) {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
`

export const DepositDateWrapper = styled(RangePack)`
  margin-left: 20px;
  @media screen and (max-width: 1705px) {
    margin-left: 0px;
    justify-content: center;
    flex-direction: column;
  }
`


export const DatePickerInput = styled(BasicInput)`
  width: 250px;
  @media screen and (max-width: 720px) {
    width: 115px;
  }
`

export const LeftPart = styled.div`
  display: flex;
  @media screen and (max-width: 1705px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`

export const RangeGroup = styled(DayRange)`
  @media screen and (max-width: 1705px) {
    margin-top: 10px;
  }
`

export const BtnGroup = styled.div`
  display: flex;
  @media screen and (max-width: 1705px) {
    display: flex;
    justify-content: center;
  }
`

export const Frame = styled.td`
  height: 160px;
  width: 160px;
  position: relative;
`

export const Avatar = styled.img`
  max-height: 100%;  
  max-width: 100%; 
  width: auto;
  height: auto;
  position: absolute;  
  top: 0;  
  bottom: 0;  
  left: 0;  
  right: 0;  
  margin: auto;
`

export const CalculateTitle = styled(BasicRowTitle)`
  background: #ed6c02;
`