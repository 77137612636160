import Modal from 'react-bootstrap/Modal'
import React from "react"
import { BigSquareTitle, BigSquareInput, ModalBtn } from '../../../middleware/utilityStyle.js'
import { Button } from "react-bootstrap"
import { reviseAuth } from "../../../middleware/Api/authApi"
import { getAllAuth } from "../../../middleware/Api/publicApi"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"

export default function ReviseAuth(props) {
  const {
    popData,
    showRevise,
    change,
    handleChange,
    handleReviseClose,
    setPanelInfo,
    history,
    setAuthInfo
  } = props
  const handleRevise = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!change.reviseName || !change.reviseDes) {
        alert('請輸入空白處⊙ w ⊙')
        return
      } else {
        reviseAuth(+popData.ID, change.reviseName, change.reviseDes).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          handleReviseClose()
          getAllAuth().then(db => {
            if (db.error === errorCode.NULL_DATA.code) {
              alert(`※  ${errorCode.NULL_DATA.memo}`)
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            setPanelInfo(db.data)
            setAuthInfo(db.data)
          })
        })
      }
    })
  }
  return (
    <>
      <Modal
        show={showRevise}
        onHide={handleReviseClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>修改權限</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <tr>
              <BigSquareTitle>權限ID</BigSquareTitle>
              <td>
                {popData.ID}
              </td>
            </tr>
            <tr>
              <BigSquareTitle>權限名稱</BigSquareTitle>
              <td>
                <BigSquareInput placeholder="請輸入欲設定名稱" name="reviseName" value={change.reviseName} onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <BigSquareTitle>權限描述</BigSquareTitle>
              <td>
                <BigSquareInput placeholder="請輸入此名稱備註內容" name="reviseDes" value={change.reviseDes} onChange={handleChange} />
              </td>
            </tr>
          </table>
        </Modal.Body>
        <ModalBtn>
          <Button variant="info" onClick={handleRevise}>確定</Button>
          <Button variant="light" onClick={handleReviseClose}>返回</Button>
        </ModalBtn>
      </Modal>
    </>
  )
}