import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import Box from '@mui/material/Box'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import gameCategories from "../../../assets/config/gameCategories.json"
import ChartistGraph from "react-chartist"
import { TimezoneTranslator } from "../../../middleware/dateUtils"
import { getUsersHistory } from "../../../middleware/Api/operation/usersHistoryApi"
import {
  Card,
  Row,
  Col,
} from "react-bootstrap"
import {
  InputBarBg,
  InputBarRWD,
  DateWrapper,
  DateTitle,
  PickerInput,
  Dropdown,
  BtnGroup
} from "./styledComponent"
import {
  SearchButton,
  ResetButton,
  LineChartBtn,
  OnlineNum,
  TimeBar,
  ChartForm,
  Line4,
  Line5,
  Line6,
  Line7,
  Line8,
  Line9,
  Line10,
  Line11,
  Line12,
  LineNone,
  ChartDetail,
  CardNoData,
  BasicTable,
  BasicRowTitle,
  TableContent
} from "../../../middleware/utilityStyle.js"

export default function concurrentUsersHistory() {
  const history = useHistory()
  const [TTInfo, setTTInfo] = useState([])
  const [AIInfo, setAIInfo] = useState([])
  const [tableSwitch, setTableSwitch] = useState(true)
  const [chartSwitch, setChartSwitch] = useState(false)
  const [noChartCard, setNoChartCard] = useState(false)
  const [chartValue, setChartValue] = useState({
    gameType: '',
    LW3: '',
    LW2: '',
    LW1: '',
    bfYesterday: '',
    LYesterday: '',
    D_day: ''
  })

  const [series, setSeries] = useState([])
  const [label, setLabel] = useState([])
  const [hNum, setHNum] = useState({
    LW3Line: '',
    LW2Line: '',
    LW1Line: '',
    bfYesterdayLine: '',
    yesterdayLine: '',
    D_dayLine: '',
    AILW3Line: '',
    AILW2Line: '',
    AILW1Line: '',
    AIbfYesterdayLine: '',
    AIyesterdayLine: '',
    AID_dayLine:'',
    TTLW3Line: '',
    TTLW2Line: '',
    TTLW1Line: '',
    TTbfYesterdayLine: '',
    TTyesterdayLine: '',
    TTD_dayLine: ''
  })
  const [avrNum, setAvrNum] = useState({
    LW3Line: '',
    LW2Line: '',
    LW1Line: '',
    bfYesterdayLine: '',
    yesterdayLine: '',
    D_dayLine: '',
    AILW3Line: '',
    AILW2Line: '',
    AILW1Line: '',
    AIbfYesterdayLine: '',
    AIyesterdayLine: '',
    AID_dayLine:'',
    TTLW3Line: '',
    TTLW2Line: '',
    TTLW1Line: '',
    TTbfYesterdayLine: '',
    TTyesterdayLine: '',
    TTD_dayLine: ''
  })

  const CURRENT_T = new Date().getTime()
  const A_DAY = 24 * 60 * 60 * 1000

  const yesterday = new Date(`${new Date(CURRENT_T  - A_DAY * 2).toISOString().slice(0, 10)}T16:00:00.000Z`)
  const yesterdayE = new Date(`${new Date(CURRENT_T - A_DAY).toISOString().slice(0, 10)}T15:59:59.000Z`)

  const [change, setChange] = useState({gameType: '1', dateValue: yesterday})
  const handleChange = (e, key) => {
    setChange((prev) => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  function lineMaker(dbData, day, dayE, logType) {
    let targetPeriod = dbData.filter(ele => new Date(ele.LOG_DATE).getTime() >= day && new Date(ele.LOG_DATE).getTime() <= dayE)
    let targetLogType = targetPeriod.filter(ele => +ele.LOG_TYPE === logType)
    let timeOrder = targetLogType.sort(function order(a, b) {
      return new Date(a.LOG_DATE).getTime() - new Date(b.LOG_DATE).getTime()
    })
    let targetDate = new Date(day).toISOString().split('T')[0]
    let hour = ['16', '17', '18', '19', '20', '21', '22', '23', '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15']
    let minute = ['00', '10', '20', '30', '40', '50']
    let inCompleteDataTime = []
    let tArr = targetDate.split('-')
    let tomorrowDate = Number(tArr[2]) + 1
    let getDay = new Date(new Date(targetDate).getFullYear(), new Date(targetDate).getMonth() + 1, 0)
    let monthDays = getDay.getDate()
    let nextMonth = Number(tArr[1]) + 1
    let nextYear = Number(tArr[0]) + 1
    if (timeOrder.length < 144) {
      for (let i = 0; i< hour.length; i++) {
        if (hour[i] === '00') {
          if (tomorrowDate < 10) {
            tArr.splice(2, 1, '0'+tomorrowDate.toString())
          } else {
            tArr.splice(2, 1, tomorrowDate.toString())
          }
          if (tomorrowDate > monthDays) {
            tArr.splice(2, 1, '01')
            if (nextMonth < 10) {
              tArr.splice(1, 1, '0'+nextMonth.toString())
            }
            if (nextMonth > 12) {
              tArr.splice(2, 1, '01')
              tArr.splice(1, 1, '01')
              tArr.splice(0, 1, nextYear.toString())
            }
          }
        }
        for (let k = 0; k < minute.length; k++) {
          inCompleteDataTime.push(tArr.join('-')+`T${hour[i]}:${minute[k]}:00.000Z`)
        }
      }
  
      for (let j =0; j < inCompleteDataTime.length; j++) {
        let found = timeOrder.find(ele => ele.LOG_DATE === inCompleteDataTime[j])
        if (!found) {
          timeOrder.push({GAME_ID: Number(change.gameType), ONLINE_NUM: 0, LOG_DATE: inCompleteDataTime[j]})
        }
          timeOrder.sort(function order(a, b) {
          return new Date(a.LOG_DATE).getTime() - new Date(b.LOG_DATE).getTime()
        })
      }
    }
    let list = []
    timeOrder.map(ele => list.push(ele.ONLINE_NUM))
    return list
  }
  
  function getHighestNum (dbData, day, dayE, logType) {
    let result = ''
    if (dbData.length > 0 && Array.isArray(dbData)) {
      let dataList = dbData.filter(ele => new Date(ele.LOG_DATE).getTime() >= day && new Date(ele.LOG_DATE).getTime() <= dayE)
      let targetLogType = dataList.filter(ele => +ele.LOG_TYPE === logType)
      targetLogType.sort(function order(a, b) {
        return a.ONLINE_NUM - b.ONLINE_NUM
      })
      if (targetLogType[targetLogType.length-1]) {
        result = targetLogType[targetLogType.length-1]
      }
      if (!targetLogType[targetLogType.length-1]) {
        result = {ONLINE_NUM: 0}
      }
      return result
    }
  }
  
  function getAverageNum (dbData, day, dayE, logType) {
    if (dbData.length > 0 && Array.isArray(dbData)) {
      let targetList = dbData.filter(ele => new Date(ele.LOG_DATE).getTime() >= day && new Date(ele.LOG_DATE).getTime() <= dayE)
      let targetLogType = targetList.filter(ele => +ele.LOG_TYPE === logType)
      let totalUsers = 0
      for (let i = 0; i < targetLogType.length; i++) {
        totalUsers += targetLogType[i].ONLINE_NUM
      }
      let result = Math.round(totalUsers/144)
      return result
    }
  }
  
  const LW3 = new Date(`${new Date(new Date(change.dateValue).getTime() - A_DAY * 9).toISOString().slice(0, 10)}T16:00:00.000Z`)
  const LW3E = new Date(`${new Date(new Date(change.dateValue).getTime() - A_DAY * 8).toISOString().slice(0, 10)}T15:59:59.000Z`)
  const LW2 = new Date(`${new Date(new Date(change.dateValue).getTime() - A_DAY * 8).toISOString().slice(0, 10)}T16:00:00.000Z`)
  const LW2E = new Date(`${new Date(new Date(change.dateValue).getTime() - A_DAY * 7).toISOString().slice(0, 10)}T15:59:59.000Z`)
  const LW1 = new Date(`${new Date(new Date(change.dateValue).getTime() - A_DAY * 7).toISOString().slice(0, 10)}T16:00:00.000Z`)
  const LW1E = new Date(`${new Date(new Date(change.dateValue).getTime() - A_DAY * 6).toISOString().slice(0, 10)}T15:59:59.000Z`)
  const D_day = new Date(`${new Date(new Date(change.dateValue).getTime()).toISOString().slice(0, 10)}T16:00:00.000Z`)
  const D_dayE = new Date(`${new Date(new Date(change.dateValue).getTime() + A_DAY).toISOString().slice(0, 10)}T15:59:59.000Z`)
  const LYesterday = new Date(`${new Date(new Date(change.dateValue).getTime() - A_DAY).toISOString().slice(0, 10)}T16:00:00.000Z`)
  const LYesterdayE = new Date(`${new Date(new Date(change.dateValue).getTime()).toISOString().slice(0, 10)}T15:59:59.000Z`)
  const bfYesterday = new Date(`${new Date(new Date(change.dateValue).getTime() - A_DAY * 2).toISOString().slice(0, 10)}T16:00:00.000Z`)
  const bfYesterdayE = new Date(`${new Date(new Date(change.dateValue).getTime() - A_DAY).toISOString().slice(0, 10)}T15:59:59.000Z`)

  function getHistoryData(dataType, date) {
    let day = [[LW3, LW3E], [LW2, LW2E], [LW1, LW1E], [bfYesterday, bfYesterdayE], [LYesterday, LYesterdayE], [D_day, D_dayE]]
    let stateKeyName = ['LW3Line', 'LW2Line', 'LW1Line', 'bfYesterdayLine', 'yesterdayLine', 'D_dayLine']
    let AIstateKeyName = ['AILW3Line', 'AILW2Line', 'AILW1Line', 'AIbfYesterdayLine', 'AIyesterdayLine', 'AID_dayLine']
    let TTstateKeyName = ['TTLW3Line', 'TTLW2Line', 'TTLW1Line', 'TTbfYesterdayLine', 'TTyesterdayLine', 'TTD_dayLine']
    getUsersHistory(dataType, date).then(db => {
      if (db.error === errorCode.INCORRECT_DATA.code) {
        alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.INCORRECT_FORMAT.code) {
        alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.NULL_DATA.code) {
        alert(`※  ${errorCode.NULL_DATA.memo}`)
        return
      }
      if (db.error === errorCode.EXCEPTION.code) {
        alert(`※  ${errorCode.EXCEPTION.memo}`)
        return
      }
      let list = []
      for (let i = 2; i < day.length; i++) {
        list.push(lineMaker(db.data, day[i][0], day[i][1], 1))
      }
      for (let i = 2; i < day.length; i++) {
        list.push(lineMaker(db.data, day[i][0], day[i][1], 2))        
      }
      for (let i = 2; i < day.length; i++) {
        list.push(lineMaker(db.data, day[i][0], day[i][1], 0))
      }
      setSeries(list)
      if (list.every(ele => !+ele.length)) {
        setNoChartCard(true)
      }
      if (db.data.length > 0) {
        for (let j = 0; j < stateKeyName.length; j++) {
          setHNum(prev => ({
            ...prev,
            [stateKeyName[j]]: getHighestNum(db.data, day[j][0], day[j][1], 1).ONLINE_NUM,
            [AIstateKeyName[j]]: getHighestNum(db.data, day[j][0], day[j][1], 2).ONLINE_NUM,
            [TTstateKeyName[j]]: getHighestNum(db.data, day[j][0], day[j][1], 0).ONLINE_NUM
          }))
          setAvrNum(prev => ({
            ...prev,
            [stateKeyName[j]]: getAverageNum(db.data, day[j][0], day[j][1], 1),
            [AIstateKeyName[j]]: getAverageNum(db.data, day[j][0], day[j][1], 2),
            [TTstateKeyName[j]]: getAverageNum(db.data, day[j][0], day[j][1], 0)
          }))
        }
        setNoChartCard(false)
      }
    })
  }

  const transGameName = new Map([
    [0, '全部'],
    [7001, '暗棋'],
    [7002, '麻將'],
    [7003, '多米諾'],
    [7004, '二人麻將'],
    [8001, '暗棋競技場'],
    [8002, '麻將競技場']
  ])

  useEffect(()=> {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      let num = 0
        let timeList = []
        for (let k = 0; k < 24; k++) {
          if (k < 10) {
            num = '0' + k.toString()
          } else {
            num = k.toString()
          }
          timeList.push(num, '.', '.', '.', '.', '.')
        }
        setLabel(timeList)
    })
  }, [TTInfo])

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (+change.gameType === 1) {
        if (!change.dateValue === true || D_day.getTime() === yesterday.getTime()) {
          getUsersHistory(0, yesterday).then(db => {
            if (!db.data.length) {
              setTTInfo([])
              setAIInfo([])
            }
            if (db.data.length) {
              let tableInfo = db.data.filter(ele => new Date(ele.LOG_DATE).getTime() >= yesterday && new Date(ele.LOG_DATE).getTime() <= yesterdayE)
              tableInfo.sort(function order(a, b) {
                return new Date(a.LOG_DATE).getTime() - new Date(b.LOG_DATE).getTime()
              })
              let TT = tableInfo.filter(ele => +ele.LOG_TYPE === 0)
              let AI = tableInfo.filter(ele => +ele.LOG_TYPE === 2)
              setTTInfo(TT)
              setAIInfo(AI)
            }
          })
        }

        if (D_day.getTime() !== yesterday.getTime() && change.dateValue) {
          getUsersHistory(0, D_day).then(db => {
            if (db.error === errorCode.INCORRECT_DATA.code) {
              alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.INCORRECT_FORMAT.code) {
              alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.NULL_DATA.code) {
              alert(`※  ${errorCode.NULL_DATA.memo}`)
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            if (!db.data.length) {
              setTTInfo([])
              setAIInfo([])
            }
            if (db.data.length) {
              let tableInfo = db.data.filter(ele => new Date(ele.LOG_DATE).getTime() >= D_day && new Date(ele.LOG_DATE).getTime() <= D_dayE)
              tableInfo.sort(function order(a, b) {
                return new Date(a.LOG_DATE).getTime() - new Date(b.LOG_DATE).getTime()
              })
              let TT = tableInfo.filter(ele => +ele.LOG_TYPE === 0)
              let AI = tableInfo.filter(ele => +ele.LOG_TYPE === 2)
              setTTInfo(TT)
              setAIInfo(AI)
            }
          })
        }
      }
      

      if (+change.gameType !== 1) {
        if (!change.dateValue === true || D_day.getTime() === yesterday.getTime()) {
          getUsersHistory(+change.gameType, yesterday).then(db => {
            if (db.error === errorCode.INCORRECT_DATA.code) {
              alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.INCORRECT_FORMAT.code) {
              alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.NULL_DATA.code) {
              alert(`※  ${errorCode.NULL_DATA.memo}`)
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            if (!db.data.length) {
              setTTInfo([])
              setAIInfo([])
            }
            if (db.data.length) {
              let tableInfo = db.data.filter(ele => new Date(ele.LOG_DATE).getTime() >= yesterday && new Date(ele.LOG_DATE).getTime() <= yesterdayE)
              tableInfo.sort(function order(a, b) {
                return new Date(a.LOG_DATE).getTime() - new Date(b.LOG_DATE).getTime()
              })
              let TT = tableInfo.filter(ele => +ele.LOG_TYPE === 0)
              let AI = tableInfo.filter(ele => +ele.LOG_TYPE === 2)
              setTTInfo(TT)
              setAIInfo(AI)
            }
          })
        }
        if (D_day.getTime() !== yesterday.getTime() && change.dateValue) {
          getUsersHistory(+change.gameType, D_day).then(db => {
            if (db.error === errorCode.INCORRECT_DATA.code) {
              alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.INCORRECT_FORMAT.code) {
              alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.NULL_DATA.code) {
              alert(`※  ${errorCode.NULL_DATA.memo}`)
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            if (!db.data.length) {
              setTTInfo([])
              setAIInfo([])
            }
            if (db.data.length) {
              let tableInfo = db.data.filter(ele => new Date(ele.LOG_DATE).getTime() >= D_day && new Date(ele.LOG_DATE).getTime() <= D_dayE)
              tableInfo.sort(function order(a, b) {
                return new Date(a.LOG_DATE).getTime() - new Date(b.LOG_DATE).getTime()
              })
              let TT = tableInfo.filter(ele => +ele.LOG_TYPE === 0)
              let AI = tableInfo.filter(ele => +ele.LOG_TYPE === 2)
              setTTInfo(TT)
              setAIInfo(AI)
            }
          })
        }
      }
      setTableSwitch(true)
      setChartSwitch(false)
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setTableSwitch(false)
      setChartSwitch(false)
      setChange({
        gameType: '1',
        dateValue: yesterday
      })
    })
  }

  const handleChart = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      if (+change.gameType === 1) {
        setTableSwitch(false)
        setChartSwitch(false)
        setNoChartCard(false)
        setChange({
          gameType: '1',
          dateValue: yesterday
        })
        
      }

      setChartValue({
        gameType: change.gameType,
        LW3,
        LW2,
        LW1,
        bfYesterday,
        LYesterday,
        D_day
      })

      if (+change.gameType === 1) {
        if (!change.dateValue === true || D_day.getTime() === yesterday.getTime()) {
          getHistoryData(0, yesterday)
        }
        if (D_day.getTime() !== yesterday.getTime() && change.dateValue) {
          getHistoryData(0, D_day)
        }
      }

      if (+change.gameType !== 1) {
        if (!change.dateValue === true || D_day.getTime() === yesterday.getTime()) {
          getHistoryData(+change.gameType, yesterday)
        }
        if (D_day.getTime() !== yesterday.getTime() && change.dateValue) {
          getHistoryData(+change.gameType, D_day)
        }
      }
      setTableSwitch(false)
      setChartSwitch(true)
    })
  }

  const handleOnLineNum = () => {
    let toMax = Object.values(hNum).sort(function order(a, b) {
      return a - b
    })
    for (let i in toMax) {
      if (toMax[i] === '') {
        toMax[i] = 0
      }
    }
    let result = toMax[toMax.length-1]
    return result
  }

  function gameCategory() {
    let gameList = []
    gameCategories.map((ele, index) => {
      gameList.push(<option value = {ele.gameID} key= {index}>{ele.gameName}</option>)
    })
    return gameList
  }

  function getNumber(num) {
    if (isNaN(Number(num))) {
      return 0
    } else {
      return +num
    }
  }
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <InputBarRWD>
          <div>
            <span>遊戲</span>
            <Dropdown
              value= {change.gameType}
              onChange= {e => {
              handleChange(e, 'gameType')
              setTableSwitch(false)
              setChartSwitch(false)
              for (let key in chartValue) {
                chartValue[key] = ''
              }
              for (let key in hNum) {
                hNum[key] = ''
              }
              for (let key in avrNum) {
                avrNum[key] = ''
              }
              setSeries([])
            }}>
              <option value="1">全部</option>
              {gameCategory()}
            </Dropdown>
          </div>
          <DateWrapper>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTitle>日期</DateTitle>
              <DatePicker
                label= "日期"
                value={change.dateValue}
                onChange={(newValue) => {
                  setTableSwitch(false)
                  setChartSwitch(false)
                  for (let key in chartValue) {
                    chartValue[key] = ''
                  }
                  for (let key in hNum) {
                    hNum[key] = ''
                  }
                  for (let key in avrNum) {
                    avrNum[key] = ''
                  }
                  setSeries([])
                  setChange((prev) => ({
                    ...prev,
                    dateValue: newValue 
                  }))
                }}
                renderInput={({ inputRef, inputProps, InputProps }) => {
                  inputProps.placeholder = '請選擇日期'
                  return (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <PickerInput id= "datePicker" ref={inputRef} {...inputProps}/>
                      <div id="dateBtn">{InputProps?.endAdornment}</div>
                    </Box>
                  )
                }}
              />
            </LocalizationProvider>
          </DateWrapper>
        </InputBarRWD>
        <BtnGroup>
          <SearchButton onClick= {handleSearch}>搜索</SearchButton>
          <ResetButton onClick= {handleReset}>重置</ResetButton>
          <LineChartBtn onClick= {handleChart}>曲線圖</LineChartBtn>
        </BtnGroup>
      </InputBarBg>

      <BasicTable className="table table-hover table-bordered" style= {tableSwitch ? {display: 'table'} : {display: 'none'}}>
        <thead>
          <BasicRowTitle>
            <th>遊戲名稱</th>
            <th>在線總人數</th>
            <th>AI在線人數</th>
            <th>時間</th>
          </BasicRowTitle>
        </thead>
        <tbody>
          {
            Array.isArray(TTInfo) && TTInfo.length > 0 ?
            TTInfo.map((ele, index) => {
              return (
                <TableContent key= {index}>
                  <td>{transGameName.get(+ele.GAME_ID)}</td>
                  <td>{ele.ONLINE_NUM}</td>
                  <td>{AIInfo.find(obj=> new Date(obj.LOG_DATE).getTime() === new Date(ele.LOG_DATE).getTime()) ? AIInfo.find(obj=> new Date(obj.LOG_DATE).getTime() === new Date(ele.LOG_DATE).getTime()).ONLINE_NUM : ''}</td>
                  <td>{TimezoneTranslator(ele.LOG_DATE)}</td>
                </TableContent>
              )
            })
            : <tr key= "noInfo"><td colSpan= "4">尚無資料</td></tr>
          }
        </tbody>
      </BasicTable>

      <ChartForm fluid= "XL" style= {chartSwitch ? {display: 'block'} : {display: 'none'}}>
        <Row>
          <Col>
            <Card style={{ height: '50rem'}}>
              <Card.Header>
                <Card.Title as="h4">{gameCategories.find(obj => obj.gameID === +chartValue.gameType) ? gameCategories.find(obj => obj.gameID === +chartValue.gameType).gameName : ''}</Card.Title>
                <p className="card-category">全部房間</p>
              </Card.Header>
              {
                noChartCard ? <CardNoData>尚無資料</CardNoData>
                : <Card.Body style={noChartCard ? {display: 'none'} : {display: 'block'}}>
                    <div className="ct-chart" id="chartHours">
                    <OnlineNum className="card-category">在線人數</OnlineNum>
                      <ChartistGraph
                        data={{
                          labels: label,
                          series: series
                        }}
                        type="Line"
                        options={{
                          axisX: {
                            showGrid: true,
                          },
                          axisY : {
                            high: handleOnLineNum(),
                            low: 0,
                            onlyInteger: true
                          },
                          lineSmooth: true,
                          showLine: true,
                          showPoint: true,
                          fullWidth: true,
                          chartPadding: {
                            right: 50,
                            left: 50,
                            top: 20,
                            bottom: 80
                          },
                          showArea: false,
                          height: "450px",
                          stretch: true,
                          distributeSeries:true
                        }}
                        responsiveOptions={[
                          [
                            "screen and (max-width: 640px)",
                            {
                              axisX: {
                                labelInterpolationFnc: function (value) {
                                  return value[0]
                                },
                              },
                            },
                          ],
                        ]}
                      />
                    </div>
                  </Card.Body>
              }
              <TimeBar className="card-category" style={noChartCard ? {display: 'none'} : {display: 'block'}}>時間</TimeBar>
              <hr></hr>
              <Card.Footer>
                <ChartDetail className="legend">
                  <span>
                    <LineNone className="fas fa-circle"/>{chartValue.LW3.toLocaleString().split(' ')[0]} 最高在線: {getNumber(hNum.LW3Line)} 平均在線: {getNumber(avrNum.LW3Line)}
                    <LineNone className="fas fa-circle"/>{chartValue.LW2.toLocaleString().split(' ')[0]} 最高在線: {getNumber(hNum.LW2Line)} 平均在線: {getNumber(avrNum.LW2Line)}
                    <i className="fas fa-circle text-info"/>{chartValue.LW1.toLocaleString().split(' ')[0]} 最高在線: {getNumber(hNum.LW1Line)} 平均在線: {getNumber(avrNum.LW1Line)}                  
                  </span>
                  <span>
                    <i className="fas fa-circle text-danger"/>{chartValue.bfYesterday.toLocaleString().split(' ')[0]} 最高在線: {getNumber(hNum.bfYesterdayLine)} 平均在線: {getNumber(avrNum.bfYesterdayLine)}
                    <i className="fas fa-circle text-warning"/>{chartValue.LYesterday.toLocaleString().split(' ')[0]} 最高在線: {getNumber(hNum.yesterdayLine)} 平均在線: {getNumber(avrNum.yesterdayLine)}
                    <Line4 className="fas fa-circle"/>{chartValue.D_day.toLocaleString().split(' ')[0]} 最高在線: {getNumber(hNum.D_dayLine)} 平均在線: {getNumber(avrNum.D_dayLine)}
                  </span>

                  <span>
                    <LineNone className="fas fa-circle" />{chartValue.LW3.toLocaleString().split(' ')[0]} AI最高在線: {getNumber(hNum.AILW3Line)} AI平均在線: {getNumber(avrNum.AILW3Line)}
                    <LineNone className="fas fa-circle" />{chartValue.LW2.toLocaleString().split(' ')[0]} AI最高在線: {getNumber(hNum.AILW2Line)} AI平均在線: {getNumber(avrNum.AILW2Line)}
                    <Line5 className="fas fa-circle"/>{chartValue.LW1.toLocaleString().split(' ')[0]} AI最高在線: {getNumber(hNum.AILW1Line)} AI平均在線: {getNumber(avrNum.AILW1Line)}
                  </span>
                  <span>
                    <Line6 className="fas fa-circle"/>{chartValue.bfYesterday.toLocaleString().split(' ')[0]} AI最高在線: {getNumber(hNum.AIbfYesterdayLine)} AI平均在線: {getNumber(avrNum.AIbfYesterdayLine)}
                    <Line7 className="fas fa-circle"/>{chartValue.LYesterday.toLocaleString().split(' ')[0]} AI最高在線: {getNumber(hNum.AIyesterdayLine)} AI平均在線: {getNumber(avrNum.AIyesterdayLine)}
                    <Line8 className="fas fa-circle"/>{chartValue.D_day.toLocaleString().split(' ')[0]} AI最高在線: {getNumber(hNum.AID_dayLine)} AI平均在線: {getNumber(avrNum.AID_dayLine)}
                  </span>

                  <span>
                    <LineNone className="fas fa-circle" />{chartValue.LW3.toLocaleString().split(' ')[0]} 總最高在線: {getNumber(hNum.TTLW3Line)} 總平均在線: {getNumber(avrNum.TTLW3Line)}
                    <LineNone className="fas fa-circle" />{chartValue.LW2.toLocaleString().split(' ')[0]} 總最高在線: {getNumber(hNum.TTLW2Line)} 總平均在線: {getNumber(avrNum.TTLW2Line)}
                    <Line9 className="fas fa-circle"/>{chartValue.LW1.toLocaleString().split(' ')[0]} 總最高在線: {getNumber(hNum.TTLW1Line)} 總平均在線: {getNumber(avrNum.TTLW1Line)}
                  </span>
                  <span>
                    <Line10 className="fas fa-circle"/>{chartValue.bfYesterday.toLocaleString().split(' ')[0]} 總最高在線: {getNumber(hNum.TTbfYesterdayLine)} 總平均在線: {getNumber(avrNum.TTbfYesterdayLine)}
                    <Line11 className="fas fa-circle"/>{chartValue.LYesterday.toLocaleString().split(' ')[0]} 總最高在線: {getNumber(hNum.TTyesterdayLine)} 總平均在線: {getNumber(avrNum.TTyesterdayLine)}
                    <Line12 className="fas fa-circle"/>{chartValue.D_day.toLocaleString().split(' ')[0]} 總最高在線: {getNumber(hNum.TTD_dayLine)} 總平均在線: {getNumber(avrNum.TTD_dayLine)}
                  </span>
                </ChartDetail>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </ChartForm>
    </>
  )
}