import { getAuthToken } from '../../utils.js'
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 取得活動設定 PID60 ?//
export const getActivity = (activityID, sDate, eDate, sRow, eRow) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/eventSetting?activityID=${activityID}&sDate=${sDate}&eDate=${eDate}&sRow=${sRow}&eRow=${eRow}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 新增活動設定 PID61 ?//
export const addActivity = (activityID, activityType, activityName, sDate, eDate, state, barStart, barEnd, barState, barTargetNum, ifReset, barReward) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/eventSetting`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      activityID,
      activityType,
      activityName,
      sDate,
      eDate,
      state,
      barStart,
      barEnd,
      barState,
      barTargetNum,
      ifReset,
      barReward
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 更改活動設定 PID62 ?//
export const reviseActivity = (activityID, activityType, activityName, sDate, eDate, state, barStart, barEnd, barState, barTargetNum, ifReset, barReward) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/eventSetting`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      activityID,
      activityType,
      activityName,
      sDate,
      eDate,
      state,
      barStart,
      barEnd,
      barState,
      barTargetNum,
      ifReset,
      barReward
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 刪除活動設定 PID63 ?//
export const deleteActivity = activityID => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/eventSetting/delete`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      activityID
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}