import styled from 'styled-components'
import Box from '@mui/material/Box'
import { BasicInput } from '../../../middleware/utilityStyle'

export const BtnGroup = styled.div`
  @media screen and (max-width: 1650px) {
    margin-top: 10px;
  }
`

export const Title = styled.td`
  text-align: center;
`

export const RadioWrapper = styled.div`
  display: flex;
`

export const TimeFormat = styled(Box)`
  margin-left: 20px;
`

export const PickerWrapper = styled.div`
  display: flex;
`

export const WeekGroup = styled.span`
  margin-right: 10px;
`

export const TimeInput = styled(BasicInput)`
  width: 150px;
  height: 25px;
  & + div {
    margin-left: 0px;
  }
`

export const PeriodGroup = styled.div`
  display: flex;
`

export const TimeRange = styled.span`
  margin-left: 20px;
`

export const DateTime = styled(BasicInput)`
  height: 28px;
  & + div {
    margin-left: 0px;
  }
`