import React, { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import Box from '@mui/material/Box'
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { announcementDetail } from "../../../middleware/Api/playerInfo/announcementApi"
import SavePU from "./saveBtnPU"
import CancelPU from "./cancelBtnPU"
import DeletePU from "./deleteBtnPU"
import {
  Title,
  SendTimeWrapper,
  TimeFormat,
  PickerWrapper,
  PickerInput
} from "../../../views/playerInfo/announcement/styledComponent"
import { ModalFormat, ModalBtn } from "../../../middleware/utilityStyle"

export default function AnnouncementInfo(props) {
  const {
    history,
    showInfoPU,
    handleInfoPUShow,
    handleInfoPUClose,
    targetObj,
    editable,
    okBtn,
    cancelBtn,
    deleteBtn,
    checkDetails,
    handleCleanData,
    setPanelInfo,
    dataCopy,
    setDataCopy
  } = props
  //targetList for this page's view

  const [detailCopy, setDetailCopy] = useState({
    TITLE: '',
    SUBTITLE: '',
    TEXT: '',
    TYPE: '',
    BEGIN_DATE: '',
    EXPIRE_DATE: '',
    STATE: 1,
    ORDER_ID: ''
  })

  const [targetList, setTargetList] = useState({
    TITLE: '',
    SUBTITLE: '',
    TEXT: '',
    TYPE: '',
    BEGIN_DATE: '',
    EXPIRE_DATE: '',
    STATE: 1,
    ORDER_ID: ''
  })

  const [dateGroup, setDateGroup] = useState({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null
  })

  useEffect(()=> {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (checkDetails) {
        if (targetObj.ORDER_ID) {
          announcementDetail(targetObj.ORDER_ID).then(db => {
            if (db.error === errorCode.INCORRECT_DATA.code) {
              alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.INCORRECT_FORMAT.code) {
              alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.NULL_DATA.code) {
              alert(`※  ${errorCode.NULL_DATA.memo}`)
              return
            }
            setTargetList(db.data)
            setDetailCopy(db.data)
            setDateGroup({
              startDate: db.data.BEGIN_DATE,
              startTime: db.data.BEGIN_DATE,
              endDate: db.data.EXPIRE_DATE,
              endTime: db.data.EXPIRE_DATE
            })
          })
        }
      } else {
        setTargetList({
          ORDER_ID: '',
          STATE: 1,
          TYPE: '',
          BEGIN_DATE: '',
          EXPIRE_DATE: '',
          TITLE: '',
          TEXT: '',
          SUBTITLE: ''
        })
      }
    })
  }, [targetObj, checkDetails])

  const  handleTarget = (e, key) => {
    setTargetList(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const handleOnHide = () => {
    handleInfoPUClose()
    setTimeout(() => {
      handleCleanData()
      setTargetList({
        ORDER_ID: '',
        STATE: 1,
        TYPE: '',
        BEGIN_DATE: '',
        EXPIRE_DATE: '',
        TITLE: '',
        TEXT: '',
        SUBTITLE: ''
      })
      for (let key in dateGroup) {
        dateGroup[key] = null
      }
    }, 500)
  }

  const [showSavePU, setShowSavePU] = useState(false)
  const handleSavePUClose = () => {
    setShowSavePU(false)
  }
  const handleSavePUShow = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!checkDetails) {
        if (Array.isArray(dataCopy) && dataCopy.length) {
          if (dataCopy.find(ele => +ele.ORDER_ID === +targetList.ORDER_ID)) {
            alert('※ 排序欄位與資料庫重複，請重新填入')
            return
          }
        }
      }
      
      let Begin = new Date(
        new Date(dateGroup.startDate).getFullYear(),
        new Date(dateGroup.startDate).getMonth(),
        new Date(dateGroup.startDate).getDate(),
        new Date(dateGroup.startTime).getHours(),
        new Date(dateGroup.startTime).getMinutes()
      )

      let Expire = new Date(
        new Date(dateGroup.endDate).getFullYear(),
        new Date(dateGroup.endDate).getMonth(),
        new Date(dateGroup.endDate).getDate(),
        new Date(dateGroup.endTime).getHours(),
        new Date(dateGroup.endTime).getMinutes()
      )
      
      if (!targetList.ORDER_ID) {
        alert('※  請輸入排序欄位')
        return
      }
      if (!+targetList.ORDER_ID) {
        alert('※  排序欄位請填入數字')
        return
      }

      if (+targetList.ORDER_ID < 0 || +targetList.ORDER_ID > 32767) {
        alert('※  排序有效範圍為0~32767')
        return
      }

      if (!dateGroup.startDate || !dateGroup.startTime || !dateGroup.endDate || !dateGroup.endTime) {
        alert('※  請輸入日期、時間')
        return
      }

      if (Expire < Begin) {
        alert('※  結束時間不可早於開始時間')
        return
      } else {
        setTargetList(prev => ({
          ...prev,
          BEGIN_DATE: Begin,
          EXPIRE_DATE: Expire
        }))
      }

      if (!targetList.TITLE) {
        alert('※  請輸入頁籤標題欄位')
        return
      }

      if (targetList.TITLE.length > 8) {
        alert('※  頁籤標題至多8個字元')
        return
      }

      if (!targetList.SUBTITLE) {
        alert('※  請輸入內文標題欄位')
        return
      }

      if (targetList.SUBTITLE.length > 17) {
        alert('※  內文標題至多17個字元')
        return
      }

      if (!targetList.TEXT) {
        alert('※  請輸入內容欄位')
        return
      }
      
      if (checkDetails) {
        let compareTheSame = true
        for(let key in targetList){
          if(targetList[key] !== detailCopy[key]) {
            compareTheSame = false
            break
          }
        }
        if (new Date(detailCopy.BEGIN_DATE) !== Begin || new Date(detailCopy.EXPIRE_DATE) !== Expire) {
          compareTheSame = false
        }
        if(compareTheSame) {
          alert('資料未更動 ⊙ w ⊙')
          return
        }
      }
      handleInfoPUClose()
      setShowSavePU(true)
      setDateGroup({
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null
      })
    })
  }

  const [showCancelPU, setShowCancelPU] = useState(false)
  const handleCancelPUClose = () => setShowCancelPU(false)
  const handleCancelPUShow = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleInfoPUClose()
      setShowCancelPU(true)
    })
  }

  const [showDeletePU, setShowDeletePU] = useState(false)
  const handleDeletePUClose = () => setShowDeletePU(false)
  const handleDeletePUShow = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      // if (!userPID.find(ele => +ele === 20)) {
      //   alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
      //   return
      // }
      handleInfoPUClose()
      setShowDeletePU(true)
    })
  }
  return (
    <>
      <ModalFormat
        show={showInfoPU}
        onHide={handleOnHide}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{checkDetails ? '正式公告' : '新增公告'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
          <tbody>
            <tr>
              <Title className="table-active">排序</Title>
                <td>
                  <textarea
                    className="form-control"
                    rows="1"
                    placeholder="請輸入排序 (0~32767)"
                    value= {targetList.ORDER_ID}
                    onChange= {e => {handleTarget(e, 'ORDER_ID')}}
                    disabled= {editable ? false : true}
                  />
                </td>
            </tr>

            <tr >
              <Title className="table-active">狀態</Title>
              <td>
                <SendTimeWrapper>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      id= "sendNow"
                      type="radio"
                      value= "1"
                      name= "state"
                      onChange= {e => {handleTarget(e, 'STATE')}}
                      disabled= {editable ? false : true}
                      checked= {+targetList.STATE === 1 ? true : false}
                    />開啟
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      id= "sendSpecific"
                      type="radio"
                      value= "0"
                      name= "state"
                      onChange= {e => {handleTarget(e, 'STATE')}}
                      disabled= {editable ? false : true}
                      checked= {+targetList.STATE === 0 ? true : false}
                    />關閉
                  </div>
                  
                </SendTimeWrapper>
              </td>
            </tr>

            <tr>
              <Title className="table-active">類型</Title>
              <td>
                <SendTimeWrapper>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      id= "sendNow"
                      type="radio"
                      name="type"
                      value= "2"
                      onChange= {e => {handleTarget(e, 'TYPE')}}
                      disabled= {editable ? false : true}
                      checked= {+targetList.TYPE === 2 ? true : false}
                    />即時
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      id= "sendSpecific"
                      type="radio"
                      name="type"
                      value= "1"
                      onChange= {e => {handleTarget(e, 'TYPE')}}
                      disabled= {editable ? false : true}
                      checked= {+targetList.TYPE === 1 ? true : false}
                    />常態
                  </div>
                </SendTimeWrapper>
              </td>
            </tr>

            <tr>
              <Title className="table-active">開始時間</Title>
              <td>
                <PickerWrapper>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label= "日期"
                    value={dateGroup.startDate}
                    disabled= {editable ? false : true}
                    onChange={(newValue) => {
                      setDateGroup(prev => ({
                        ...prev,
                        startDate: newValue
                      }))
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => {
                      inputProps.placeholder = '開始日期'
                      return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <PickerInput name= "startDatePicker" disabled= {editable ? false : true} ref={inputRef} {...inputProps} />
                          {InputProps?.endAdornment}
                        </Box>
                      )
                    }}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    label="時間"
                    value={dateGroup.startTime}
                    disabled= {editable ? false : true}
                    onChange={(newValue) => {
                      setDateGroup(prev => ({
                        ...prev,
                        startTime: newValue
                      }))
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => {
                      inputProps.placeholder = '開始時間'
                      return (
                        <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                          <PickerInput name= "startTimePicker" disabled= {editable ? false : true} ref={inputRef} {...inputProps} />
                          {InputProps?.endAdornment}
                        </TimeFormat>
                      )
                    }}
                  />
                </LocalizationProvider>
                </PickerWrapper> 
                
              </td>
            </tr>

            <tr>
              <Title className="table-active">結束時間</Title>
              <td>
                <PickerWrapper>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label= "日期"
                      value= {dateGroup.endDate}
                      disabled= {editable ? false : true}
                      onChange={(newValue) => {
                        setDateGroup(prev => ({
                          ...prev,
                          endDate: newValue
                        }))
                      }}
                      renderInput={({ inputRef, inputProps, InputProps }) => {
                        inputProps.placeholder = '結束日期'
                        return (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <PickerInput name= "endDatePicker" disabled= {editable ? false : true} ref={inputRef} {...inputProps} />
                            <div>{InputProps?.endAdornment}</div>
                          </Box>
                        )
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label="時間"
                      value= {dateGroup.endTime}
                      disabled= {editable ? false : true}
                      onChange={(newValue) => {
                        setDateGroup(prev => ({
                          ...prev,
                          endTime: newValue
                        }))
                      }}
                      renderInput={({ inputRef, inputProps, InputProps }) => {
                        inputProps.placeholder = '結束時間'
                        return (
                          <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                            <PickerInput name= "endTimePicker" disabled= {editable ? false : true} ref={inputRef} {...inputProps} />
                            <div>{InputProps?.endAdornment}</div>
                          </TimeFormat>
                        )
                      }}
                    />
                  </LocalizationProvider>
                </PickerWrapper>
              </td>
            </tr>

            <tr>
              <Title className="table-active">頁籤標題</Title>
              <td>
                <textarea
                  className="form-control"
                  rows="1"
                  placeholder="請輸入頁籤標題 (至多8個字元)"
                  name= "sideTitle"
                  value= {targetList.TITLE}
                  disabled= {editable ? false : true}
                  onChange= {e => {handleTarget(e, 'TITLE')}}
                />
              </td>
            </tr>

            <tr>
              <Title className="table-active">內文標題</Title>
              <td>
                <textarea
                  className="form-control"
                  rows="1"
                  placeholder="請輸入內文標題 (至多17個字元)"
                  name= "contentTitle"
                  value = {targetList.SUBTITLE}
                  disabled= {editable ? false : true}
                  onChange= {e => {handleTarget(e, 'SUBTITLE')}}
                />
              </td>
            </tr>

            <tr>
              <Title className="table-active">內容</Title>
              <td>
                <textarea
                  className="form-control"
                  rows="5"
                  placeholder="請輸入內容"
                  name= "content"
                  value= {targetList.TEXT}
                  disabled= {editable ? false : true}
                  onChange= {e => {handleTarget(e, 'TEXT')}}
                />
              </td>
            </tr>
          </tbody>
          <tfoot></tfoot>
          </table>
        </Modal.Body>
        
        <ModalBtn>
          <Button style= {editable ? {display: 'block'} : {display: 'none'}} variant="success" onClick= {handleSavePUShow}>儲存</Button>
          <Button style= {checkDetails ? okBtn  ? {display: 'block'} : {display: 'none'} : {display: 'none'}} variant="info" onClick={handleInfoPUClose}>確認</Button>
          <Button style= {checkDetails ? cancelBtn  ? {display: 'block'} : {display: 'none'} : {display: 'none'}} variant="secondary" onClick= {handleCancelPUShow}>取消</Button>
          <Button style= {checkDetails ? deleteBtn  ? {display: 'block'} : {display: 'none'} : {display: 'none'}} variant="danger" onClick= {handleDeletePUShow}>刪除</Button>
        </ModalBtn>
        
      </ModalFormat>

      <SavePU
        history= {history}
        showSavePU= {showSavePU}
        handleInfoPUShow= {handleInfoPUShow}
        handleSavePUClose= {handleSavePUClose}
        targetList= {targetList}
        setTargetList= {setTargetList}
        detailCopy= {detailCopy}
        handleCleanData= {handleCleanData}
        checkDetails= {checkDetails}
        setPanelInfo= {setPanelInfo}
        setDataCopy= {setDataCopy}
      />

      <CancelPU
        history= {history}
        showCancelPU= {showCancelPU}
        handleInfoPUShow= {handleInfoPUShow}
        handleCancelPUClose= {handleCancelPUClose}
        setTargetList= {setTargetList}
        detailCopy= {detailCopy}
        handleCleanData= {handleCleanData}
        dateGroup= {dateGroup}
        setPanelInfo= {setPanelInfo}
      />

      <DeletePU
        history= {history}
        showDeletePU= {showDeletePU}
        handleInfoPUShow= {handleInfoPUShow}
        handleDeletePUClose= {handleDeletePUClose}
        targetList= {targetList}
        setTargetList= {setTargetList}
        handleCleanData= {handleCleanData}
        setPanelInfo= {setPanelInfo}
        setDataCopy= {setDataCopy}
        dateGroup= {dateGroup}
      />
    </>
  )
}