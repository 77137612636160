import { getAuthToken } from "../../utils"
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 取得暗棋倍數控制資料 PID80 ?//
export const getChessMultiInfo = chessType => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/serverEnv/chessMultiplication?chessType=${chessType}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}
//? 修改暗棋倍數控制資料 PID81 ?//
export const reviseChessMultiInfo = (chessInfo, chessType) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/serverEnv/reviseChessMultiplication`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      chessInfo,
      chessType
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}