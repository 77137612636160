import styled from "styled-components"
import { Container, Card } from "react-bootstrap"
import Stack from '@mui/material/Stack'
import Modal from 'react-bootstrap/Modal'
import Pagination from '@mui/material/Pagination'
import TablePagination from '@mui/material/TablePagination'



//* navbar *//
export const BarBG = styled.nav`
  display: flex;
  width: 100%;
  background: #d9d9d9 !important;
  border: 0px;
  border-radius: 15px;
`
//BTN
export const SearchButton = styled.button`
  margin-left: 20px;
  color: orange;
  border: 1px solid orange;
  border-radius: 5px;
  transition: 0.7s;
  background: white;

  :hover {
    color: white;
    font-weight: bold;
    background: orange;
  }
`
export const ResetButton = styled.button`
  margin-left: 20px;
  color: rgb(255, 26, 26);
  border: 1px solid rgb(255, 26, 26);
  border-radius: 5px;
  transition: 0.7s;
  background: white;

  :hover {
    color: white;
    font-weight: bold;
    background: rgb(255, 26, 26);
  }
`
//Day Range
export const RangePack = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const DateTitle = styled.div`
  margin-right: 20px;
`
export const DateStack = styled(Stack)`
  display: flex;a
  flex-direction: row !important;
  align-items: center;
`
export const DayRange = styled.div`
  display: flex;
`
export const ClickRange = styled.div`
  margin-left: 10px;
  color: #002080;
  font-weight: 700;
  cursor: pointer;

  : hover {
    text-decoration: underline;
    color: #1a53ff;
  }
`

//* 曲線圖 *//
export const LineChartBtn = styled.button`
  margin-left: 20px;
  color: #33ccff;
  border: 1px solid #33ccff;
  border-radius: 5px;
  transition: 0.7s;
  background: white;

  :hover {
    color: white;
    font-weight: bold;
    background: #33ccff;
  }
`
export const OnlineNum = styled.p`
  margin-left: 35px;
`
export const TimeBar = styled.p`
  margin-left: 60px;
`
export const ChartForm = styled(Container)`
  margin-top: 30px;
`
export const Line4 = styled.i`
  color: #9368E9;
`
export const Line5 = styled.i`
  color: #87CB16;
`
export const Line6 = styled.i`
  color: #1F77D0;
`
export const Line7 = styled.i`
  color: #5e5e5e;
`
export const Line8 = styled.i`
  color: #dd4b39;
`
export const Line9 = styled.i`
  color: #35465c;
`
export const Line10 = styled.i`
  color: #e52d27;
`
export const Line11 = styled.i`
  color: #55acee;
`
export const Line12 = styled.i`
  color: #cc2127;
`

export const LineNone = styled.i`
  color: #e0e0eb;
`
export const ChartDetail = styled.div`
  text-align: center;
  display: flex!important;
  justify-content: space-around!important;
  flex-direction: column;
`
export const CardNoData = styled(Card.Body)`
  display: flex!important;
  justify-content: center;
  align-items: center;
  font-size: 30px;
`

//* Button *//
//公告跑馬燈_新增/通知
export const AddBtn = styled.button`
  color: #00cc00;
  border: 1px solid #00cc00;
  border-radius: 5px;
  transition: 0.7s;
  background: white;
  height: 3rem;
  width: 6rem;

  :hover {
    color: white;
    font-weight: bold;
    background: #00cc00;
  }
`
//通知
export const InformBtn = styled.button`
  margin-left: 5rem;
  color: orange;
  border: 1px solid orange;
  border-radius: 5px;
  transition: 0.7s;
  background: white;
  height: 3rem;
  width: 6rem;

  :hover {
    color: white;
    font-weight: bold;
    background: orange;
  }
`

//產出excel報表
export const GenerateExcel = styled.button`
  border: 1px solid #00cc00;
  border-radius: 5px;
  color: #00cc00;
  transition: 0.7s;
  background: white;
  height: 2rem;
  :hover {
    color: white;
    font-weight: bold;
    background: #00cc00;
  }
`

//批次操作
export const ManipulateGroup = styled.button`
  color: #00ccff;
  border: 1px solid #00ccff;
  border-radius: 5px;
  transition: 0.7s;
  background: white;
  height: 2rem;

  :hover {
    color: white;
    font-weight: bold;
    background: #00ccff;
  }
`
//批次查看
export const CheckGroup = styled.button`
  color: #9933ff;
  border: 1px solid #9933ff;
  border-radius: 5px;
  transition: 0.7s;
  background: white;
  height: 2rem;

  :hover {
    color: white;
    font-weight: bold;
    background: #9933ff;
  }
`

//新增商品分類/細項
export const AddItemInfoBtn =  styled.button`
  margin-top: 30px;
  color: #00cc00;
  border: 1px solid #00cc00;
  border-radius: 5px;
  transition: 0.7s;
  background: white;
  height: 3rem;
  width: 9rem;

  :hover {
    color: white;
    font-weight: bold;
    background: #00cc00;
  }
`

//dzbSetting
export const ReviseBtnM = styled.button`
  margin-top: 10px;
  justify-content: center;
  color:  #33d6ff;
  border: 1px solid  #33d6ff;
  border-radius: 5px;
  transition: 0.7s;
  background: white;
  height: 3rem;
  width: 6rem;

  :hover {
    color: white;
    background: #33d6ff;
    font-weight: bold;
  }
`
export const InformBtnM = styled.button`
  margin-top: 10px;
  justify-content: center;
  color: orange;
  border: 1px solid orange;
  border-radius: 5px;
  transition: 0.7s;
  background: white;
  height: 3rem;
  width: 6rem;

  :hover {
    color: white;
    background: orange;
    font-weight: bold;
  }
`
export const CancelReviseBtnM = styled.button`
  margin-top: 10px;
  color:  #a9a9a9;
  border: 1px solid  #a9a9a9;
  border-radius: 5px;
  transition: 0.7s;
  background: white;
  height: 3rem;
  width: 6rem;

  :hover {
    color: white;
    background: #a9a9a9;
    font-weight: bold;
  }
`

//* Table *//
export const BasicTable = styled.table`
  margin-top: 30px;
  text-align: center;
`
export const BasicRowTitle = styled.tr`
  font-size: 20px;
  font-weight: 600 !important;
`
export const BasicHeadTitle = styled.th`
  font-size: 20px;
  font-weight: 600 !important;
  text-align: center;
`
export const TableContent = styled.tr`
  font-size: 15px;
`

//* Modal Pop Up*//
export const BigSquareTitle = styled.td`
  text-align: center;
  vertical-align: middle !important;
`
export const BigSquareInput = styled.input`
  border-radius: 0.25rem;
  border: 0.5px solid #999999;
  width: 300px;
`
export const PUInput = styled.input`
  border: 1px solid black;
  border-radius: 5px;
  height: 28px;
  width: 240px;
`
export const ModalFormat = styled(Modal)`
  & div {
    transform: translate(0, 0%) !important;
  }
`
export const ModalBtn = styled(Modal.Footer)`
  display: flex;
  justify-content: space-around !important;
`
export const ModalText = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

//* other *//
export const BasicInput = styled.input`
  border: 1px solid black;
  border-radius: 5px;
`

export const PopDetail = styled.td`
  color: #002080;
  vertical-align: middle !important;
  text-align: center;
  : hover {
    color: #0000cc;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
  }
`

export const PageStyle = styled(Pagination)`
  display: flex;
  justify-content: center;
`

export const TablePaginationStyle = styled(TablePagination)`
  display: flex;
  justify-content: center;
`

//* table底下設定按鈕 *//
export const TableBtnGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const BtnPosition = styled.div`
  display: flex;
  justify-content: space-evenly;
`
//新增->綠色
export const NewBtn = styled.button`
color: #00cc00;
border: 1px solid #00cc00;
border-radius: 5px;
transition: 0.7s;
background: white;
height: 2.5rem;
width: 5rem;
:hover {
  color: white;
  font-weight: bold;
  background: #00cc00;
}
`
//修改->天空藍
export const ReviseBtn = styled.button`
color: #33d6ff;
border: 1px solid #33d6ff;
border-radius: 5px;
transition: 0.7s;
background: white;
height: 2.5rem;
width: 5rem;
:hover {
  color: white;
  font-weight: bold;
  background: #33d6ff;
}
`
//刪除->紅色
export const DeleteBtn = styled.button`
color: rgb(255, 26, 26);
border: 1px solid rgb(255, 26, 26);
border-radius: 5px;
transition: 0.7s;
background: white;
height: 2.5rem;
width: 5rem;
:hover {
  color: white;
  font-weight: bold;
  background: rgb(255, 26, 26);
}
`
//啟用->紫色
export const LaunchBtn = styled.button`
  color: #8a00e6;
  border: 1px solid #8a00e6;
  border-radius: 5px;
  transition: 0.7s;
  background: white;
  height: 2.5rem;
  width: 5rem;
  :hover {
    color: white;
    font-weight: bold;
    background: #8a00e6;
  }
`
//禁用->灰色
export const BanBtn = styled.button`
  color: #808080;
  border: 1px solid #808080;
  border-radius: 5px;
  transition: 0.7s;
  background: white;
  height: 2.5rem;
  width: 5rem;
  :hover {
    color: white;
    font-weight: bold;
    background: #808080;
  }
`
//維護->橘色
export const MaintainBtn = styled.button`
  color: orange;
  border: 1px solid orange;
  border-radius: 5px;
  transition: 0.7s;
  background: white;
  height: 2.5rem;
  width: 5rem;
  :hover {
    color: white;
    font-weight: bold;
    background: orange;
  }
`