import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import {
  InputGroup,
  InputBar,
  Dropdown,
  BTNGroup
} from "./styledComponent"
import {
  BarBG,
  SearchButton,
  ResetButton,
  BasicTable,
  BasicRowTitle,
  TableContent,
  Content,
  LaunchBtn,
  BanBtn,
  MaintainBtn,
  TableBtnGroup
} from "../../../middleware/utilityStyle.js"

export default function serverPeriodSetting() {
  let list = []
  const history = useHistory()
  const [change, setChange] = useState({
    gameType: 0,
    IPStatus: 0
  })
  const [panelInfo, setPanelInfo] = useState([])
  const [popData, setPopData] = useState({})
  const handleChange = (e, key) => {
    setChange((prev) => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPanelInfo(list)
    })
  }, [])

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      if (+change.gameType === 0 && +change.IPStatus === 0) {
        setPanelInfo(list)
      }
      if (+change.gameType === 0 && +change.IPStatus === 1) {
        setPanelInfo(list.filter(ele => +ele.gameType !== 0 && +ele.IPStatus === 1))
      }
      if (+change.gameType === 0 && +change.IPStatus === 2) {
        setPanelInfo(list.filter(ele => +ele.gameType !== 0 && +ele.IPStatus === 2))
      }
      if (+change.gameType === 1 && +change.IPStatus === 0) {
        setPanelInfo(list.filter(ele => +ele.gameType === 1 && +ele.IPStatus !== 0))
      }
      if (+change.gameType === 1 && +change.IPStatus === 1) {
        setPanelInfo(list.filter(ele => +ele.gameType === 1 && +ele.IPStatus === 1))
      }
      if (+change.gameType === 1 && +change.IPStatus === 2) {
        setPanelInfo(list.filter(ele => +ele.gameType === 1 && +ele.IPStatus === 2))
      }
      if (+change.gameType === 2 && +change.IPStatus === 0) {
        setPanelInfo(list.filter(ele => +ele.gameType === 2 && +ele.IPStatus !== 0))
      }
      if (+change.gameType === 2 && +change.IPStatus === 1) {
        setPanelInfo(list.filter(ele => +ele.gameType === 2 && +ele.IPStatus === 1))
      }
      if (+change.gameType === 2 && +change.IPStatus === 2) {
        setPanelInfo(list.filter(ele => +ele.gameType === 2 && +ele.IPStatus === 2))
      }

      setChange({
        gameType: 0,
        IPStatus: 0
      })
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({
        gameType: 0,
        IPStatus: 0
      })
    })
  }

  const transArr = new Map([
    [1, '暗棋'],
    [2, '麻將']
  ])
  const transGameType = new Map(transArr)

  const statusArr = new Map([
    [0, '禁用'],
    [1, '啟用'],
    [2, '維護']
  ])
  const transStatus = new Map(statusArr)

  const handleRadio = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      let result = list.find(ele => +ele.dataID === +e.target.value)
      setPopData(result)
    })
  }
  return (
    <>
      <BarBG className="navbar navbar-light bg-light">
      <InputGroup>
        <div>
          <span>遊戲</span>
          <Dropdown id= "item1" value={change.gameType} onChange= {e => {handleChange(e, "gameType")}}>
            <option value="0">全部</option>
            <option value="1">暗棋</option>
            <option value="2">麻將</option>
          </Dropdown>
        </div>
        <InputBar>
          <span>IP狀態</span>
          <Dropdown id= "item1" value={change.IPStatus} onChange= {e => {handleChange(e, "IPStatus")}}>
            <option value="0">全部</option>
            <option value="1">開啟</option>
            <option value="2">關閉</option>
          </Dropdown>
        </InputBar>
      </InputGroup>

      <div>
        <SearchButton onClick={handleSearch}>搜索</SearchButton>
        <ResetButton onClick={handleReset}>重置</ResetButton>
      </div>
      </BarBG>

      <BasicTable className="table table-hover table-bordered">
      <thead>
        <BasicRowTitle>
          <th><input type= "radio" disabled/></th>
          <th scope="col">遊戲名稱</th>
          <th scope="col">伺服器類型</th>
          <th scope="col">伺服器名稱</th>
          <th scope="col">IP地址</th>
          <th scope="col">IP狀態</th>
          <th scope="col">人數</th>
          <th scope="col">滿員率</th>
          <th scope= "col">人數上限</th>
          <th scope= "col">伺服器狀態</th>
          <th scope = "col">最後使用紀錄時間</th>
        </BasicRowTitle>
      </thead>
      <tbody>
        {
          Array.isArray(panelInfo) && panelInfo.length > 0 ?
          panelInfo.map((ele, index)=> {
            return (
              <TableContent key= {index}>
                <td>
                  <input type= "radio" name= "table" value= {ele.dataID} onChange= {handleRadio}/>
                </td>
                <td>{transGameType.get(+ele.gameType)}</td>
                <td>{ele.serverType}</td>
                <td>{ele.serverName}</td>
                <td>{ele.IPAddress}</td>
                <td>{+ele.IPStatus === 1 ? '開啟' : '關閉'}</td>
                <td>{ele.userNum}</td>
                <td>{ele.fullServerRate}</td>
                <td>{ele.limitUsers}</td>
                <td>{transStatus.get(+ele.serverStatus)}</td>
                <td>{ele.lastTimeRecord}</td>
              </TableContent>
            )
          })
          : <tr key= "noInfo"><td colSpan= "11">尚無資料</td></tr>
        }
      </tbody>
      </BasicTable>

      <LaunchBtn>啟用</LaunchBtn>
      <BanBtn>禁用</BanBtn>
      <MaintainBtn>維護</MaintainBtn>
    </>
  )
}