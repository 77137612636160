import React, { useState }from "react"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { ModalText, ModalBtn } from "../../../middleware/utilityStyle"
import { deleteItemConfig }  from "../../../middleware/Api/operation/itemConfigApi.js"
import { getNavAcc } from "../../../middleware/utils"
import { PUInput } from "../../../middleware/utilityStyle"

export default function DeletePU(props) {
  const {
    showDeletePU,
    handleDeletePUClose,
    popData,
    setPopData,
    setPanelInfo
  } = props

  const [optionChange, setOptionChange] = useState({
    goldNum: 0
  })

  const handleOptionChange = (e, key) => {
    setOptionChange(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const [deleteStatus, setDeleteStatus] = useState({
    status: 0
  })

  const handleDeleteStatus = (status, key) => {
    setDeleteStatus(prev => ({
      ...prev,
      [key]: status
    }))
  }

  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      /*
      if (new Date(popData.START_DATE) <= new Date(Date.now()) && new Date(popData.END_DATE) >= new Date(Date.now())) {
        alert(`※ 該競技場正在活動期間，無法刪除`)
        return
      }*/
      let userName = getNavAcc()

      deleteItemConfig(+popData.ITEM_ID, optionChange.goldNum, userName).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if (db.error === errorCode.SERVER_ERROR.code) {
          alert(`※  ${errorCode.SERVER_ERROR.memo}`)
          return
        }
        setPanelInfo([])
        setPopData({})
      })
      handleDeletePUClose()
    })
  }

  const handleBack = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleDeletePUClose()

      setDeleteStatus({
        status: 0
      })
      setOptionChange({
        goldNum: 0
      })
    })
  }
  return (
    <>
      <Modal
        show={showDeletePU}
        onHide={handleDeletePUClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>移除道具</Modal.Title>
        </Modal.Header>
        <ModalText style= { deleteStatus.status == 0 ? {display: 'block'} : {display:'none'}}>
          確認移除道具{popData.ITEM_NAME}?<br />
          若有玩家該道具狀態為使用中，則會消除其狀態<br />
          若有玩家該造型為套用中，則會恢復預設造型<br />
          ※請務必在關機時再進行刪除！<br />
          <br />
        </ModalText>
        <ModalText style= { deleteStatus.status == 1 ? {display: 'block'} : {display:'none'}}>
          即將移除該道具{popData.ITEM_NAME}<br />
          是否要給予持有玩家相對的金幣補償？<br />
          ※輸入 0 代表無補償，確認時將會補發至信箱<br />
          <PUInput placeholder= '請輸入數字' value= {optionChange.goldNum} onChange= {e => {handleOptionChange(e, 'goldNum')}}/>
          <br />
          <br />
        </ModalText>
        
        <ModalBtn>
          <Button style= { deleteStatus.status == 0 ? {display: 'block'} : {display:'none'}} variant="danger" onClick={e => handleDeleteStatus(1, 'status')}>確認</Button>
          <Button style= { deleteStatus.status == 1 ? {display: 'block'} : {display:'none'}} variant="danger" onClick={handleDelete}>確認</Button>
          <Button variant="secondary" onClick={handleBack}>取消</Button>
        </ModalBtn>
      </Modal>
    </>
  )
}