import { getAuthToken } from '../../utils.js'
import { BaseUrl, UploadUrl } from "../../../assets/config/urlForBuild"

//? 取得鬥陣寶係數設定 PID95 ?//
export const getShopConfig = (classId, status) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/shopConfig?classId=${classId}&status=${status}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 新增鬥陣寶係數設定 PID96 ?//
export const addShopConfig = (classId, itemId, orderId, productType, imgUrl, productName, costType, costNum, itemTag, startDate, endDate, accountLimit, dayLimit, weekLimit, monthLimit, totalLimit, text, status, isPermanent) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/shopConfig`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      classId, 
      itemId, 
      orderId, 
      productType, 
      imgUrl, 
      productName, 
      costType, 
      costNum, 
      itemTag, 
      startDate, 
      endDate, 
      accountLimit, 
      dayLimit, 
      weekLimit, 
      monthLimit, 
      totalLimit, 
      text, 
      status, 
      isPermanent
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 更改鬥陣寶係數設定 PID97 ?//
export const reviseShopConfig = (productId, classId, itemId, orderId, productType, imgUrl, productName, costType, costNum, itemTag, startDate, endDate, accountLimit, dayLimit, weekLimit, monthLimit, totalLimit, text, status, isPermanent) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/shopConfig`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      productId,
      classId, 
      itemId, 
      orderId, 
      productType, 
      imgUrl, 
      productName, 
      costType, 
      costNum, 
      itemTag, 
      startDate, 
      endDate, 
      accountLimit, 
      dayLimit, 
      weekLimit, 
      monthLimit, 
      totalLimit, 
      text, 
      status, 
      isPermanent
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 刪除鬥陣寶係數設定 PID98 ?//
export const deleteShopConfig = productId => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/shopConfig/delete`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      productId
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 取得指定商品設定?//
export const getItemConfigByItemId = itemId => {
  const token = getAuthToken()
  console.log("要發送了 = " + itemId)
  return fetch(`${BaseUrl}/shopConfig/itemConfig?itemId=${itemId}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

export const uploadItem = formData => {
  return fetch(UploadUrl, {
    method: 'POST',
    body: formData,
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}