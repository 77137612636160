import { getAuthToken } from '../../utils.js'
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 取得活動獎品配置 PID64 ?//
export const getPrizeWeight = (activityId, state, beginRowNum, endRowNum) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/prizeWeight?activityId=${activityId}&state=${state}&beginRowNum=${beginRowNum}&endRowNum=${endRowNum}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 新增活動獎品配置 PID65 ?//
export const addPrizeWeight = (activityId, levelNum, awardType, awardNum, randomWeight, state,ITEM_ID) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/prizeWeight`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      activityId,
      levelNum,
      awardType,
      awardNum,
      randomWeight,
      state,
      ITEM_ID
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 修改活動獎品配置 PID66?//
export const revisePrizeWeight = (aacId, activityId, levelNum, awardType, awardNum, randomWeight, state, ITEM_ID) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/prizeWeight`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      aacId,
      activityId,
      levelNum,
      awardType,
      awardNum,
      randomWeight,
      state,
      ITEM_ID
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 刪除活動獎品配置 PID67 ?//
export const deletePrizeWeight = aacId => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/prizeWeight/delete`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      aacId
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}
