import styled from "styled-components"
import { BarBG, BasicInput } from '../../../middleware/utilityStyle'

export const InputBarBg = styled(BarBG)`
  @media screen and (max-width: 1350px){
    & {
      flex-direction: column;
    }
    & div + div {
      margin-top: 10px;
    }
  };
  @media screen and (min-width:0px) and (max-width: 600px){
    & {
      justify-content: center;
    }
  }
`

export const InputBarRWD = styled.div`
  display: flex;
  @media screen and (max-width: 1350px){
    & {
      flex-direction: column;
    }
  };
`

export const FirstInputBar = styled.div`
  @media screen and (max-width: 1350px) {
    display: flex;
    justify-content: space-between
  }
`

export const InputBar = styled.div`
  margin-left: 20px;
  @media screen and (max-width: 1350px){
    margin-left: 0px;
    display: flex;
    justify-content: space-between;
  }

  @media screen and (min-width:0px) and (max-width: 600px){
    & {
      margin-left: 0px;
    }
  }
`

export const Input = styled(BasicInput)`
  width: 350px;
  margin-left: 20px;
  border: 1px solid black;
  @media screen and (min-width:400px) and (max-width: 600px){
    & {
      width: 250px;
    }
  }
  @media screen and (max-width: 400px){
    & {
      width: 150px;
    }
  }
`

export const OrangeTd = styled.td`
  background: orange;
  color: white;
`

export const Frame = styled.td`
  height: 160px;
  width: 160px;
  position: relative;
`

export const Avatar = styled.img`
  max-height: 100%;  
  max-width: 100%; 
  width: auto;
  height: auto;
  position: absolute;  
  top: 0;  
  bottom: 0;  
  left: 0;  
  right: 0;  
  margin: auto;
`

export const TitleTr = styled.tr`
  background: #66ccff;
  text-align: center;
`

export const TdBg = styled.td`
  background: #cceeff;
`

export const GameList = styled.th`
  background: #49d049;
`

export const Link = styled.a`
  text-decoration: underline;
  font-weight: 600;
  color: black;

  :hover {
    color: blue !important;
    text-decoration: none;
    cursor: pointer;
  }
`

export const Dropdown = styled.select`
  margin-left: 20px;
  border: 1px solid black;
  border-radius: 5px;
  height: 28px;
  width: 150px;
`