import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import itemMainType from "../../../assets/config/itemType.json"
import { getAccessArr } from "../../../middleware/utils"
import AddRevisePU from "../../../components/popUpWindow/userItem/AddRevisePU"
import { getUserItem } from "../../../middleware/Api/operation/userItemApi.js"

import {
  InputBarBg,
  BTN,
  Group,
  Dropdown
} from "./styledComponent"
import {
  SearchButton,
  ResetButton,
  BasicTable,
  BasicRowTitle,
  TableContent,
  TableBtnGroup,
  NewBtn,
  ReviseBtn,
  PUInput
} from "../../../middleware/utilityStyle.js"

export default function UserItem() {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const history = useHistory()
  const [panelInfo, setPanelInfo] = useState([])
  const [change, setChange] = useState({
    uid: -1, 
    state: -1, 
    itemId: -1
  })
  const handleChange = (e, key) => {
    setChange(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }
  const [dateValue, setDateValue] = useState([null, null])
  const [popData, setPopData] = useState({
    UID: 0,
    ITEM_ID: 99,
    ITEM_NAME: "",
    ITEM_NUM: 0,
    MAIN_TYPE: 1,
    SUB_TYPE: 1001,
    DETAIL_TYPE: 1001001,
    EXPIRE_DATE: null,
    STATE: 0, 
    START_DATE: null, 
    END_DATE: null
  })

  useEffect(() => {
  }, [panelInfo])

  const [changePagePara, setChangePagePara] = useState({
    uid: -1, 
    state: -1, 
    itemId: -1
  })

  const [pageCounter, setPageCounter] = useState(0)
  const [page, setPage] = useState(1)
  const handleChangePage = (event, newPage) => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPage(newPage)
      const {uid, state, itemId} = changePagePara
      handleGetUserItem(uid, state, itemId)
    })
  }

  function handleGetUserItem(uid, state, itemId) {
    getUserItem(+uid, +state, +itemId).then(db => {
      if (db.error === errorCode.INCORRECT_DATA.code) {
        alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.INCORRECT_FORMAT.code) {
        alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.EXCEPTION.code) {
        alert(`※  ${errorCode.EXCEPTION.memo}`)
        return
      }
      if (db.error === errorCode.SERVER_ERROR.code) {
        alert(`※  ${errorCode.SERVER_ERROR.memo}`)
        return
      }
      if (db.error === errorCode.NULL_DATA.code) {
        alert(`※  ${errorCode.NULL_DATA.memo}`)
        setPanelInfo([])
        return
      }
      setChangePagePara({
        uid: uid,
        state: state, 
        itemId: itemId
      })
      if(db.data) {
        if (db.data.length > 0) {
          setPageCounter(
            db.data[0].TOTAL_ROW_NUM%10 === 0
            ? Math.floor(db.data[0].TOTAL_ROW_NUM/10)
            : (Math.floor(db.data[0].TOTAL_ROW_NUM/10)) + 1
          )
        }
      }
      //alert(db.data[0].START_DATE)
      //db.data[0].START_DATE = TimezoneTranslator(new Date(db.data[0].START_DATE).toISOString())
      //db.data[0].END_DATE = TimezoneTranslator(new Date(db.data[0].END_DATE).toISOString())
      setPanelInfo(db.data)
      setPage(1)
    })
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") handleSearch()
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      let uid = change.uid > 0 ? change.uid : -1;
      let state = change.state;
      let itemId = change.itemId > 0 ? change.itemId: -1;

      handleGetUserItem(uid, state, itemId)
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({uid: -1, state: -1, itemId: -1})
    })
  }

  const [addStatus, setAddStatus] = useState(false)
  const[showAddRevise, setAddReviseShow] = useState(false)
  const handleAddReviseClose = () => setAddReviseShow(false)
  const handleAddReviseShow = () => setAddReviseShow(true)

  const handleAdd = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 112)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      setAddStatus(true)
      handleAddReviseShow()
    })
  }

  const handleRevise = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 113)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      let info = e.target.dataset.id;
      let infoList = info.toString().split(",")
      setPopData(panelInfo.find(ele => +ele.ITEM_ID === +infoList[1] && +ele.UID === +infoList[0]))
      setAddStatus(false)
      handleAddReviseShow()
    })
  }

  function itemMainShow(mainType) {
    let name = ""
    for (let i = 0; i < itemMainType.length; i++) {
      if (itemMainType[i].mainType == mainType) {
        name = itemMainType[i].mainName
      }
    }
    return name
  }

  function itemSubShow(subType) {
    let name = []
    for (let i = 0; i < itemMainType.length; i++) {
      let subItem = itemMainType[i].subItem
      for (let j = 0; j < subItem.length; j++) {
        if (subItem[j].subType == subType) {
          name = subItem[j].subName
        }
      }
    }
    return name
  }

  function itemDetailShow(detailType) {
    let name = []
    for (let i = 0; i < itemMainType.length; i++) {
      let subItem = itemMainType[i].subItem
      for (let j = 0; j < subItem.length; j++) {
        let detailItem = subItem[j].detailItem
        for (let k = 0; k < detailItem.length; k++) {
          if (detailItem[k].detailType == detailType) {
            name = subItem[k].subName
          }
        }
      }
    }
    return name
  }

  const transStatus = new Map ([
    [0, '未使用'],
    [1, '使用中'],
    [2, '套用中'],
    [3, '未套用']
  ])

  console.warn(JSON.stringify(panelInfo))
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <Group>
          <div>
            <span></span>
            用戶ID查詢&nbsp;&nbsp;&nbsp;&nbsp;
            <PUInput placeholder= "請輸入ID" value= {change.uid == -1 ? '' : change.uid} onChange= {e => {handleChange(e, 'uid')}} onKeyDown={handleKeyDown}/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span></span>
            道具ID查詢&nbsp;&nbsp;&nbsp;&nbsp;
            <PUInput placeholder= "請輸入ID" value= {change.itemId == -1 ? '' : change.itemId} onChange= {e => {handleChange(e, 'itemId')}} onKeyDown={handleKeyDown}/>
            &nbsp;&nbsp;
          </div>
        </Group>
        <Group>
          <div>
            <span></span>
            狀態查詢
            <Dropdown
              value= {change.state}
              onChange= {e => {
                handleChange(e, 'state')
              }}
            >
              <option value="-1">全部</option>
              <option value="0">未使用</option>
              <option value="1">使用中</option>
              <option value="3">未套用</option>
              <option value="2">套用中</option>
            </Dropdown>
          </div>
        </Group>      
        <Group>
          <SearchButton onClick={handleSearch}>搜索</SearchButton>
          <ResetButton onClick={handleReset}>重置</ResetButton>
        </Group>
      </InputBarBg>
      
      <TableBtnGroup>
        <BasicTable className="table table-hover table-bordered">
          <thead>
            <BasicRowTitle>
              <th>用戶ID</th>
              <th>持有道具ID</th>
              <th>道具名稱</th>
              <th>分類</th>
              <th>子分類</th>
              <th>細項分類</th>
              <th>數量</th>
              <th>狀態</th>
              <th>使用時間</th>
              <th>結束時間</th>
              <th>修改</th>
            </BasicRowTitle>
          </thead>
          <tbody>
            {
              Array.isArray(panelInfo) && panelInfo.length > 0 ?
              panelInfo.map((ele, index) => {
                if (ele.MAIN_TYPE) {
                return (
                  <TableContent key= {index}>
                    <td>{ele.UID}</td>
                    <td>{ele.ITEM_ID}</td>
                    <td>{ele.ITEM_NAME}</td>
                    <td>{itemMainShow(ele.MAIN_TYPE)}</td>
                    <td>{itemSubShow(ele.SUB_TYPE)}</td>
                    <td>{itemDetailShow(ele.DETAIL_TYPE)}</td>
                    <td>{ele.ITEM_NUM}</td>
                    <td>{transStatus.get(+ele.STATE)}</td>
                    <td>{+ele.STATE == 0 ? '' : new Date(ele.START_DATE).toLocaleString("sv", { hour12: false, timeZone: "Asia/Taipei"})}</td>
                    <td>{+ele.STATE == 0 ? '' : new Date(ele.END_DATE).toLocaleString("sv", { hour12: false, timeZone: "Asia/Taipei"})}</td>
                    <td><ReviseBtn data-id = {ele.UID + "," +  ele.ITEM_ID} onClick= {handleRevise}>修改</ReviseBtn></td>
                  </TableContent>
                )}
              })
              : <tr key= "noInfo"><td colSpan= "11">尚無資料</td></tr>
            }
          </tbody>
        </BasicTable>        
        <BTN>
          <NewBtn onClick= {handleAdd}>新增</NewBtn>
        </BTN>
      </TableBtnGroup>

      <AddRevisePU
        showAddRevise= {showAddRevise}
        handleAddReviseShow= {handleAddReviseShow}
        handleAddReviseClose= {handleAddReviseClose}
        addStatus= {addStatus}
        popData= {popData}
        setPanelInfo= {setPanelInfo}
        setPopData= {setPopData}
      />
    </>
  )
}
