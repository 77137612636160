import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { sessionVerified, getAllEventList } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import prizeType from "../../../assets/config/eventPrizeType.json"
import AddRevisePU from "../../../components/popUpWindow/prizeLimit/AddRevisePU"
import DeletePU from "../../../components/popUpWindow/prizeLimit/DeletePU"
import Stack from '@mui/material/Stack'
import { getAccessArr } from "../../../middleware/utils"
import { TimezoneTranslator } from "../../../middleware/dateUtils.js"
import { getPrizeLimit } from "../../../middleware/Api/eventManagement/prizeLimitApi"
import {
  InputBarBg,
  NavLeft,
  BtnGroup,
  BTN,
  Dropdown
} from "./styledComponent"
import {
  SearchButton,
  ResetButton,
  BasicTable,
  BasicRowTitle,
  TableContent,
  PageStyle,
  TableBtnGroup,
  NewBtn,
  ReviseBtn,
  DeleteBtn,
} from "../../../middleware/utilityStyle.js"

export default function PrizeLimit() {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const history = useHistory()
  const [panelInfo, setPanelInfo] = useState([])
  const [change, setChange] = useState({activityId: ''})
  const handleChange = (e, key) => {
    setChange(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }
  const [allEvent, setAllEvent] = useState([])
  useEffect(() => {
    sessionVerified().then(db=>{
      if (db.error === errorCode.NULL_DATA.code) {
        alert(`※  ${errorCode.NULL_DATA.memo}`)
        return
      }
      if (db.error === errorCode.SERVER_ERROR.code) {
        alert(`※  ${errorCode.SERVER_ERROR.memo}`)
        return
      }
      getAllEventList().then(gDB => {
        if (Array.isArray(gDB.data) && gDB.data.length) {
          setAllEvent(gDB.data)
          setChange(prev => ({
            ...prev,
            activityId: gDB.data[0].ACTIVITY_ID
          }))
        }
      })
    })


  }, [])
  const [popData, setPopData] = useState({})
  const handleRadio = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPopData(panelInfo.find(ele => +ele.AAC_ID === +e.target.value))
    })
  }

  const [changePagePara, setChangePagePara] = useState({activityId: ''})
  const [pageCounter, setPageCounter] = useState(0)
  const [page, setPage] = useState(1)

  function handleGetPrizeLimit(activityId, sR, eR) {
    getPrizeLimit(activityId, sR, eR).then(db => {
      if (db.error === errorCode.INCORRECT_DATA.code) {
        alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.INCORRECT_FORMAT.code) {
        alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.EXCEPTION.code) {
        alert(`※  ${errorCode.EXCEPTION.memo}`)
        return
      }
      if (db.error === errorCode.SERVER_ERROR.code) {
        alert(`※  ${errorCode.SERVER_ERROR.memo}`)
        return
      }
      if (db.error === errorCode.NULL_DATA.code) {
        alert(`※  ${errorCode.NULL_DATA.memo}`)
        setPanelInfo([])
        return
      }
      if (db.error){
        alert(`※  ${db.data} in route`)
        return 
      }
      setChangePagePara({
        activityId: +activityId
      })
      if(db.data) {
        if (db.data.length > 0) {
          setPageCounter(
            db.data[0].TOTAL_ROW_NUM%30 === 0
            ? Math.floor(db.data[0].TOTAL_ROW_NUM/30)
            : (Math.floor(db.data[0].TOTAL_ROW_NUM/30)) + 1
          )
        }
      }
      setPanelInfo(db.data)
      setPage(1)
    })
  }

  const handleChangePage = (event, newPage) => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPage(newPage)
      
      handleGetPrizeLimit(changePagePara.activityId, 1+(newPage-1)*30, newPage*30)
    })
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleGetPrizeLimit(change.activityId, 1, 30)
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({activityId: allEvent[0].ACTIVITY_ID})
    })
  }

  const [addStatus, setAddStatus] = useState(false)
  const[showAddRevise, setAddReviseShow] = useState(false)
  const handleAddReviseClose = () => setAddReviseShow(false)
  const handleAddReviseShow = () => setAddReviseShow(true)
  const handleAdd = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 71)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      setAddStatus(true)
      handleAddReviseShow()
    })
  }
  const handleRevise = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 72)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (!+popData.AAC_ID) {
        alert('※  請選擇欲修改的項目')
        return
      }
      setAddStatus(false)
      handleAddReviseShow()
    })
  }

  const [showDeletePU, setDeletePUShow] = useState(false)
  const handleDeletePUClose = () => setDeletePUShow(false)
  const handleDeletePUShow = () => setDeletePUShow(true)
  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 73)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (!+popData.AAC_ID) {
        alert('※  請選擇欲刪除的項目')
        return
      }
      handleDeletePUShow()
    })
  }

  function handleName(activityId) {
    if (allEvent.find(ele => ele.ACTIVITY_ID === +activityId)) {
      return allEvent.find(ele => ele.ACTIVITY_ID === +activityId).ACTIVITY_NAME
    }
  }

  function handleType(type) {
    if (prizeType.find(ele => +ele.num === +type)) {
      return prizeType.find(ele => +ele.num === +type).name
    }
  }
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <NavLeft>
          <div>
            <span>活動名稱</span>
            <Dropdown value= {change.activityId} onChange= {e=> {handleChange(e, 'activityId')}}>
              {
                Array.isArray(prizeType) && allEvent.length ?
                  allEvent.map((ele, index) => {
                    return <option value= {ele.ACTIVITY_ID} key= {index}>{ele.ACTIVITY_NAME}</option>
                  })
                : ''
              }
            </Dropdown>
          </div>
        </NavLeft>
      
        <BtnGroup>
          <SearchButton onClick={handleSearch}>搜索</SearchButton>
          <ResetButton onClick={handleReset}>重置</ResetButton>
        </BtnGroup>
      </InputBarBg>
      
      <TableBtnGroup>
        <BasicTable className="table table-hover table-bordered">
          <thead>
            <BasicRowTitle>
              <th>
                <input type= "radio" disabled/>
              </th>
              <th>活動名稱</th>
              <th>獎勵類型</th>
              <th>獎勵數量</th>
              <th>次數限制</th>
              <th>總次數</th>
              <th>每日次數限制</th>
              <th>當前次數</th>
              <th>最近發獎時間</th>
            </BasicRowTitle>
          </thead>
          <tbody>
            {
              Array.isArray(panelInfo) && panelInfo.length > 0 ?
              panelInfo.map((ele, index) => {
                return (
                  <TableContent key= {index}>
                    <td>
                      <input type= "radio" name= "eventList" value= {ele.AAC_ID} onChange= {handleRadio}/>
                    </td>
                    <td>{handleName(ele.ACTIVITY_ID)}</td>
                    <td>{+ele.AWARD_TYPE !== 6? handleType(+ele.AWARD_TYPE):`${handleType(+ele.AWARD_TYPE)}(${+ele.ITEM_ID})`}</td>
                    <td>{ele.AWARD_NUM}</td>
                    <td>{ele.TOTAL_LIMIT ? ele.TOTAL_LIMIT : '無限制'}</td>
                    <td>{ele.TOTAL_NUM}</td>
                    <td>{ele.DAILY_LIMIT}</td>
                    <td>{ele.DAILY_NUM}</td>
                    <td>{ele.LAST_AWARD_DATE ? TimezoneTranslator(ele.LAST_AWARD_DATE) : '---'}</td>
                  </TableContent>
                )
              })
              : <tr key= "noInfo"><td colSpan= "9">尚無資料</td></tr>
            }
          </tbody>
        </BasicTable>
        <BTN>
          <NewBtn onClick= {handleAdd}>新增</NewBtn>
          <ReviseBtn onClick= {handleRevise}>修改</ReviseBtn>
          <DeleteBtn onClick= {handleDelete}>刪除</DeleteBtn>
        </BTN>
      </TableBtnGroup>

      <AddRevisePU
        showAddRevise= {showAddRevise}
        handleAddReviseShow= {handleAddReviseShow}
        handleAddReviseClose= {handleAddReviseClose}
        addStatus= {addStatus}
        popData= {popData}
        setPopData= {setPopData}
        setPanelInfo= {setPanelInfo}
        allEvent= {allEvent}
        prizeType= {prizeType}
      />

      <DeletePU
        showDeletePU= {showDeletePU}
        handleDeletePUClose= {handleDeletePUClose}
        setPanelInfo= {setPanelInfo}
        popData= {popData}
        setPopData= {setPopData}
      />

      {
        Array.isArray(panelInfo) && panelInfo.length ?
          <Stack spacing={2}>
            <PageStyle count={pageCounter}
              color="warning"
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </Stack>
        : ''
      }
    </>
  )
}