import React, { useState, useEffect, useContext}from "react"
import { useHistory } from "react-router-dom"
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';


import{
  BasicInput,
  BigSquareInput,
} from "../../../middleware/utilityStyle"
import {
  PULeftTitle,
  PUSelect,
  PUInput
} from "../../../views/backstageManagement/adSettingForFinalCalculate/styledComponent"


import prizeContent from "./prizeContent.json"
import {data} from "./Contexts"


export default function SelectComponent({
  dataNumber
}){
  const [rollSetting, handleRollSetting] = useContext(data)

  const passData =(e, key) =>{
    let keys = Object.keys(rollSetting)
    let v = e.target.value.split(".")
    if (key === "quantity") {
      if ( v.length >1){
        alert("數量不存在小數點")
        return 
      }
    }
    if (key === "weight") {
      if ( v.length === 2){
        if (v[1].length>2) {
          alert("支援到小數點第二位")
          return 
        }
      }
    }
    if (!keys.includes(`${dataNumber}`)){
    let temp = {
      prizeType:"",
      itemID:"",
      quantity:"",
      weight:""      
    }
      temp[key]=  e.target.value
      handleRollSetting(dataNumber, temp)
    }else {
      let targetSet = rollSetting[dataNumber]
      let pointToSet = key === `prizeType`&&+e.target.value === 0? 
      {
        prizeType:"0",
        itemID:"",
        quantity:"",
        weight:""      
      }:
      {
        ...targetSet,
        [key]: e.target.value
      }
      handleRollSetting(dataNumber, pointToSet)
    }
  }
  return (
    <>
      <Row>
        <Col xs={2}>
          <label>{`${dataNumber<1?1:dataNumber}. `}</label>

        </Col>
        <Col>
        <PUSelect
            value={Object.prototype.toString.call(rollSetting[dataNumber]) !== "[object Object]"? "": rollSetting[dataNumber].prizeType}
            onChange={e=>{passData(e, "prizeType")
            }}
          >
            {prizeContent.map((set,index) =>{
              const [first, second] = Object.keys(set)
              return (
                <>
                <option value={set[first]} >{set[second]}</option>
                </>
              )
            })}
          </PUSelect>
        </Col>
        <Col>
        <label>道具:</label>
        </Col>
        <Col>
          <PUInput 
          placeholder="請輸入ID" 
          onChange={e=>{passData(e,"itemID")}}
          value={Object.prototype.toString.call(rollSetting[dataNumber]) !== "[object Object]"|| +rollSetting[dataNumber].prizeType !==3? "": rollSetting[dataNumber].itemID}
          disabled={Object.prototype.toString.call(rollSetting[dataNumber]) !== "[object Object]"|| rollSetting[dataNumber].prizeType ==="3"?false:true}
          ></PUInput>
        </Col>
      </Row>
      <Row>
        <Col xs={2}>
          <label>
            數量:
          </label>
        </Col>
        <Col>
          <PUInput 
            type="number"
            placeholder="請輸入數量" 
            onChange={e=>{passData(e,"quantity")}}
            value={Object.prototype.toString.call(rollSetting[dataNumber]) !== "[object Object]" ? "": rollSetting[dataNumber].quantity}
            disabled={Object.prototype.toString.call(rollSetting[dataNumber]) !== "[object Object]" ||+rollSetting[dataNumber].prizeType<1 ?true :false}
            min={0}
          ></PUInput>
        </Col>
        <Col>
          <label>
            權重:
          </label>
        </Col>
        <Col>
          <PUInput 
              type="number"
              placeholder="請輸入權重" 
              onChange={e=>{passData(e,"weight")}}
              value={Object.prototype.toString.call(rollSetting[dataNumber]) !== "[object Object]"? "": rollSetting[dataNumber].weight}
              disabled={Object.prototype.toString.call(rollSetting[dataNumber]) !== "[object Object]" || +rollSetting[dataNumber].prizeType<1 || +dataNumber<1 ?true :false}
              min={0}
          ></PUInput>
        </Col>
      </Row>
    </>
  )
}