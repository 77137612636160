import styled from 'styled-components'
import { BarBG, BtnPosition } from "../../../middleware/utilityStyle"

export const InputBarBg = styled(BarBG)`
  @media screen and (max-width: 1650px){
    flex-direction: column;
  }
`

export const NavLeft = styled.div`
  display: flex;
  @media screen and (max-width: 1530px) {
    flex-direction: column;
    justify-content: center;
  }
`

export const BtnGroup = styled.div`
  @media screen and (max-width: 1650px) {
    margin-top: 10px;
  }
`

export const PULeftTitle = styled.th`
  text-align: center;
`

export const PUSelect = styled.select`
  border: 1px solid black;
  border-radius: 5px;
  height: 28px;
  width: 240px;
`

export const Dropdown = styled.select`
  margin-left: 20px;
  border: 1px solid black;
  border-radius: 5px;
  height: 1.7rem;
  width: 200px;
`

export const BTN = styled(BtnPosition)`
  width: 500px;
`