import { getAuthToken } from '../../utils.js'
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 取得鬥陣寶任務活動列表 PID82 SP_Backstage_Get_Gem_Mission_Config ?//
export const getDZBMissionEventList = () => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/dzbMissionEvent`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }  
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 新增鬥陣寶任務活動 PID83 SP_Insert_Gem_Mission_Config ?//
export const addDZBMissionEvent = (activityName, sDate, eDate,
closeDate, gameID, gemMissionState, adResetTimes, gemMissionText) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/dzbMissionEvent`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      activityName,
      sDate,
      eDate,
      closeDate,
      gameID,
      gemMissionState,
      adResetTimes,
      gemMissionText
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 修改鬥陣寶任務活動 PID84 SP_Update_Gem_Mission_Config ?//
export const reviseDZBMissionEvent = (activityID, activityName, sDate, eDate,
closeDate, gameID, gemMissionState, adResetTimes, gemMissionText) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/dzbMissionEvent`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      activityID,
      activityName,
      sDate,
      eDate,
      closeDate,
      gameID,
      gemMissionState,
      adResetTimes,
      gemMissionText
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 刪除鬥陣寶任務活動 PID88 SP_Delete_Gem_Mission_Config ?//
export const deleteDZBMissionEvent = activityID => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/dzbMissionEvent/delete`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      activityID
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 取得鬥陣寶活動任務個別設定 PID85 SP_Backstage_Get_Gem_Mission_Award_Config ?//
export const getDZBMissionIndividualSetting = activityID => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/dzbMissionEvent/individualSetting?activityID=${activityID}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

// // // //? 新增鬥陣寶任務活動個別設定 PID SP_Insert_User_Gem_Mission?//
// // export const addDZBMissionIndividualSetting = (activityID, uid, gameID, missionID,
// // missionState, awardNum, missionLevel) => {
// //   const token = getAuthToken()
// //   return fetch(`${BaseUrl}/dzbMissionEvent/individualSetting`, {
// //     method: 'POST',
// //     headers: {
// //       'Authorization': `Bearer ${token}`,
// //       'content-type': 'application/json'
// //     },
// //     body: JSON.stringify({
// //       activityID,
// //       uid,
// //       gameID,
// //       missionID,
// //       missionState,
// //       awardNum,
// //       missionLevel
// //     })
// //   }).then(res => res.json())
// //   .catch(err => console.log('err: ', err))
// // }

//? 修改鬥陣寶任務活動個別設定 PID86 SP_Update_Gem_Mission_Award_Config ?//
export const reviseDZBMissionIndividualSetting = (activityID, gameID, missionID, missionName,
missionWeight, gemNum, gameType, missionState) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/dzbMissionEvent/individualSetting`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      activityID,
      gameID,
      missionID,
      missionName,
      missionWeight,
      gemNum,
      gameType,
      missionState
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 取得鬥陣寶任務活動報表 PID87 SP_Get_Gem_Mission_Daily_Report ?//
export const getDZBMissionEventReport = (activityID, gameID) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/dzbMissionEvent/report?activityID=${activityID}&gameID=${gameID}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}