import styled from 'styled-components'
import { BtnPosition } from '../../../middleware/utilityStyle'

export const InputGroup = styled.div`
  display: flex;
`

export const InputBar = styled.div`
  margin-left: 20px;
`

export const Dropdown = styled.select`
  margin-left: 20px;
  border: 1px solid black;
  border-radius: 5px;
  height: 28px;
  width: 5rem;
`

export const LaunchBtn = styled.button`
  color: #00cc00;
  border: 1px solid #00cc00;
  border-radius: 5px;
  transition: 0.7s;
  background: white;
  height: 2.5rem;
  width: 5rem;

  :hover {
    color: white;
    font-weight: bold;
    background: #00cc00;
  }
`

export const BanBtn = styled.button`
  margin-left: 20px;
  color: #808080;
  border: 1px solid #808080;
  border-radius: 5px;
  transition: 0.7s;
  background: white;
  height: 2.5rem;
  width: 5rem;

  :hover {
    color: white;
    font-weight: bold;
    background: #808080;
  }
`

export const MaintainBtn = styled.button`
  margin-left: 20px;
  color: #33d6ff;
  border: 1px solid #33d6ff;
  border-radius: 5px;
  transition: 0.7s;
  background: white;
  height: 2.5rem;
  width: 5rem;

  :hover {
    color: white;
    font-weight: bold;
    background: #33d6ff;
  }
`