import { getAuthToken } from '../../utils.js'
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 取得跑馬燈清單 PID23 ?//
export const newsTickerList = () => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/newsTicker`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 寫入暫存跑馬燈內容 PID24 ?//
export const addNewsTicker = (orderId, state, type, bDate, eDate, weekDate, sIntervalA, eIntervalA,
  sIntervalB, eIntervalB, sIntervalC, eIntervalC, contain) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/newsTicker`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      orderId,
      state,
      type,
      bDate,
      eDate,
      weekDate,
      sIntervalA,
      eIntervalA,
      sIntervalB,
      eIntervalB,
      sIntervalC,
      eIntervalC,
      contain
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//?  刪除暫存跑馬燈內容 PID25 ?//
export const deleteNewsTicker = orderId => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/newsTicker/${orderId}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      orderId
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 修改暫存跑馬燈內容 PID26 ?//
export const updateNewsTicker = (oldOrderId, orderId, state, type, bDate, eDate, weekDate,
  sIntervalA, eIntervalA, sIntervalB, eIntervalB, sIntervalC, eIntervalC, contain) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/newsTicker`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      oldOrderId,
      orderId,
      state,
      type,
      bDate,
      eDate,
      weekDate,
      sIntervalA,
      eIntervalA,
      sIntervalB,
      eIntervalB,
      sIntervalC,
      eIntervalC,
      contain
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 送出暫存跑馬燈 PID27 ?//
export const submitNewsTicker = () => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/newsTicker/submit`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}