import ExcelJs from "exceljs"
export default function generateExcel(fileName, workSheetName, columnList, rowList) {
  const workbook = new ExcelJs.Workbook()
  const sheet = workbook.addWorksheet(workSheetName)
  sheet.addTable({
    name: 'table',
    ref: 'A1',
    columns: columnList,
    rows: rowList
  })
  workbook.xlsx.writeBuffer().then(content => {
    const link = document.createElement("a")
    const blobData = new Blob([content], {
      type: "application/vnd.ms-excel;charset=utf-8;"
    })
    link.download = fileName
    link.href = URL.createObjectURL(blobData)
    link.click()
  })
}
