import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { getAccessArr } from "../../../middleware/utils"

import AddRevisePU from "../../../components/popUpWindow/shopClassConfig/AddRevisePU"
import DeletePU from "../../../components/popUpWindow/shopClassConfig/DeletePU"
import { getShopClassConfig } from "../../../middleware/Api/operation/shopClassConfigApi.js"

import {
  Dropdown
} from "./styledComponent"
import {
  BarBG,
  SearchButton,
  ResetButton,
  BasicTable,
  BasicRowTitle,
  TableContent,
  PopDetail,
  AddItemInfoBtn
} from "../../../middleware/utilityStyle.js"

export default function ShopClassConfig() {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const history = useHistory()
  const [change, setChange] = useState({
    navSwitch: -1
  })
  const [categoryInfo, setCategoryInfo] = useState([])
  const [dataCopy, setDataCopy] = useState([])
  const [addType, setAddType] = useState(false)
  const [form, setForm] = useState({
    CLASS_ID: 0,
    ORDER_ID: '',
    CLASS_TYPE: 1,
    CLASS_NAME: '',
    STATUS: 0,
    ITEM_NUM: 0
  })
  const [targetObj, setTargetObj] = useState({
    CLASS_ID: 0,
    ORDER_ID: '',
    CLASS_TYPE: 1,
    CLASS_NAME: '',
    STATUS: 0,
    ITEM_NUM: 0
  })
  const [deleteSwitch, setDeleteSwitch] = useState(false)

  const handleChange = (e, key) => {
    setChange((prev) => ({
      ...prev,
      [key]: e.target.value
    }))
  }
  
  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
    })
    getShopClassConfig(-1).then(db => {
      if (db.error === errorCode.NULL_DATA.code) {
        alert(`※  ${errorCode.NULL_DATA.memo}`)
        return
      }
      if (db.error === errorCode.EXCEPTION.code) {
        alert(`※  ${errorCode.EXCEPTION.memo}`)
        return
      }
      setDataCopy(db.data)
    })
  }, [])

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (+change.navSwitch === 1) {
        let searchList = dataCopy.filter(info => info.STATUS)
        setCategoryInfo(searchList)
      } else if (+change.navSwitch === 0) {
        let searchArr = dataCopy.filter(info => !info.STATUS) 
          setCategoryInfo(searchArr)
      } else {
        setCategoryInfo(dataCopy)
      }
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({navSwitch: -1})
    })
  }

  const [showCategory, setCategoryShow] = useState(false)
  const handleCategoryClose = () => {
    if(addType) {
      setChange({navSwitch: -1})
    } else {
      for (let key in targetObj) {
        if (typeof targetObj[key] === 'number') {
          targetObj[key]  = 0
        } else if (typeof targetObj[key] === 'string') {
          targetObj[key] = ''
        } else {
          targetObj[key] = null
        }
      }
    }
    setDeleteSwitch(false)
    setCategoryShow(false)
  }
  const handleCategoryShow = () => setCategoryShow(true)

  const handleAddItem = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 116)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      setAddType(true)
      /*
      for (let key in form) {
        if (typeof form[key] === 'number') {
          form[key]  = 0
        } else if (typeof form[key] === 'string') {
          form[key] = ''
        } else {
          form[key] = null
        }
      }*/
      handleCategoryShow()
    })
  }

  const handleDetailSetting = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 119)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      history.push(`/admin/shopConfig?CLASS_ID=${+e.target.dataset.id}&CLASS_NAME=${e.target.dataset.name}`)
    })
  }

  const handleRevise = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setAddType(false)
      setDeleteSwitch(true)
      let checkTarget = dataCopy.find(ele => +ele.CLASS_ID === +e.target.dataset.id)
      setTargetObj(JSON.parse(JSON.stringify(checkTarget)))
      handleCategoryShow()
    })
  }

  function classTypeshow(classType) {
    let name = "其他"
    switch(+classType) {
      case 1:
        name = "遊戲造型";
        break;
    }
    return name
  }

  return (
    <>
      <BarBG className="navbar navbar-light bg-light">
        <div>
          <span>開關</span>
          <Dropdown id= "item1" value={change.navSwitch} onChange= {e => {handleChange(e, "navSwitch")}}>
            <option value="-1">全部</option>
            <option value="1">開啟</option>
            <option value="0">關閉</option>
          </Dropdown>
        </div>
        <div>
          <SearchButton onClick={handleSearch}>搜索</SearchButton>
          <ResetButton onClick={handleReset}>重置</ResetButton>
        </div>
      </BarBG>

      <AddItemInfoBtn variant="success" onClick= {handleAddItem}>新增商品分類</AddItemInfoBtn>

      <BasicTable className="table table-bordered">
        <thead>
          <BasicRowTitle>
            <th scope="col">ID</th>
            <th scope="col">排序</th>
            <th scope="col">類型</th>
            <th scope="col">分類名稱</th>
            <th scope="col">開關</th>
            <th scope="col">品項數量</th>
            <th scope="col">商品細項</th>
            <th scope="col">修改</th>

          </BasicRowTitle>
        </thead>
        <tbody>
          {
            Array.isArray(categoryInfo) && categoryInfo.length ? 
              categoryInfo.map((obj, index)=> {
                return (
                  <TableContent key={index}>
                    <td>{obj.CLASS_ID}</td>
                    <td>{obj.ORDER_ID}</td>
                    <td>{classTypeshow(obj.CLASS_TYPE)}</td>
                    <td>{obj.CLASS_NAME}</td>
                    <td>{obj.STATUS ? '開啟' : '關閉'}</td>
                    <td>{+obj.COUNT}</td>
                    <PopDetail onClick= {handleDetailSetting} data-id= {obj.CLASS_ID} data-name= {obj.CLASS_NAME}>設定</PopDetail>
                    <PopDetail  onClick= {handleRevise} data-id= {obj.CLASS_ID}>修改</PopDetail>
                  </TableContent>
                )
              })
            : <tr key= "noInfo"><td colSpan= "8">尚無資料</td></tr>
          }
        </tbody>
      </BasicTable>

      <AddRevisePU
        showCategory= {showCategory}
        handleCategoryClose= {handleCategoryClose}
        targetObj= {targetObj}
        setTargetObj= {setTargetObj}
        addType= {addType}
        form= {form}
        setForm= {setForm}
        deleteSwitch= {deleteSwitch}
        dataCopy= {dataCopy}
        setDataCopy= {setDataCopy}
        setCategoryInfo= {setCategoryInfo}
      />
    </>
  )
}
