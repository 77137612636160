import React, { useState, useEffect }from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import { getAccessArr } from "../../../middleware/utils"
import errorCode from "../../../assets/config/errorCode.json"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import Box from '@mui/material/Box'
import {
  DetailInput,
  SendTimeWrapper,
  TimeFormat,
  TimeLimited,
  LimitGroup,
  Limit,
  LimitTimes,
  PUDropdown,
  PopFrame,
  Avatar,
  NoImg,
  Title,
  LabelBtn, 
  LabelBtnNew
} from "../../../views/operation/shopConfig/styledComponent"
import { ModalFormat, PopDetail, ModalBtn, BasicHeadTitle } from "../../../middleware/utilityStyle"
import { getShopConfig, addShopConfig, reviseShopConfig, getItemConfigByItemId, uploadItem } from "../../../middleware/Api/operation/shopConfigApi.js"
import DeletePU from "./DeletePU"
import HandleEndStatus from "./handleEndStatus"

export default function AddPU(props) {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const history = useHistory()
  const {
    showDetailSetting,
    handleDetailSettingClose,
    targetObj,
    clickStatus,
    setClickStatus,
    calender,
    switchDelete,
    switchSave,
    queryValue,
    detailCopy,
    setDetailCopy,
    setDetailInfo
  } = props

  function getDetail(classID) {
    getShopConfig(classID, -1).then(db => {
      if (db.error === errorCode.INCORRECT_DATA.code) {
        alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.INCORRECT_FORMAT.code) {
        alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.EXCEPTION.code) {
        alert(`※  ${errorCode.EXCEPTION.memo}`)
        return
      }
      if (db.error === errorCode.NULL_DATA.code) {
        alert(`※  ${errorCode.NULL_DATA.memo}`)
        return
      }
      setDetailCopy(db.data)
      setDetailInfo(db.data)
    })
  }

  const [targetInfo, setTargetInfo] = useState({
    PRODUCT_ID: 0, 
    CLASS_ID: 0, 
    ITEM_ID: 0,
    ORDER_ID: '',
    PRODUCT_TYPE: '',
    IMG_URL: '',
    PRODUCT_NAME: '',
    COST_TYPE: 1,
    COST_NUM: '',
    ITEM_TAG: 0,
    START_DATE: '',
    END_DATE: '',
    ACCOUNT_LIMIT: '',
    DAY_LIMIT: '',
    WEEK_LIMIT: '',
    MONTH_LIMIT: '',
    TOTAL_LIMIT: '',
    TEXT: '',
    STATUS: 0,
    DAY_CURRENT: '',
    WEEK_CURRENT: '',
    MONTH_CURRENT: '',
    TOTAL_CURRENT: '', 
    IS_PERMANENT: 1
  })
  const [calenderInfo, setCalenderInfo] = useState({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null
  })

  const [limitState, setLimitState] = useState({
    perAccount: 0, 
    perDay: 0, 
    perWeek: 0, 
    perMonth: 0,
    server: 0})

  const [itemIdForSearch, setItemIdForSearch] = useState({
      itemId: 0})

  
      const handleChangeForSearch = (e, key) => {
        setItemIdForSearch((prev) => ({
          ...prev,
          [key]: e
        }))
      }

  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setTargetInfo({
        PRODUCT_ID: '', 
        CLASS_ID: 0, 
        ITEM_ID: '',
        ORDER_ID: '',
        PRODUCT_TYPE: '',
        IMG_URL: '',
        PRODUCT_NAME: '',
        COST_TYPE: 1,
        COST_NUM: '',
        ITEM_TAG: 0,
        START_DATE: '',
        END_DATE: '',
        ACCOUNT_LIMIT: '',
        DAY_LIMIT: '',
        WEEK_LIMIT: '',
        MONTH_LIMIT: '',
        TOTAL_LIMIT: '',
        TEXT: '',
        STATUS: 0,
        DAY_CURRENT: '',
        WEEK_CURRENT: '',
        MONTH_CURRENT: '',
        TOTAL_CURRENT: '', 
        IS_PERMANENT: 1
      })
      if (clickStatus !== 'add') {
        setCalenderInfo({
          startDate: targetObj.START_DATE,
          startTime: targetObj.START_DATE,
          endDate: targetObj.END_DATE,
          endTime: targetObj.END_DATE
        })
        setLimitState({
          perAccount: +targetObj.ACCOUNT_LIMIT ? 1 : 0,
          perDay: +targetObj.DAY_LIMIT ? 1 : 0,
          perWeek: +targetObj.WEEK_LIMIT ? 1 : 0,
          perMonth: +targetObj.MONTH_LIMIT ? 1 : 0,
          server: +targetObj.TOTAL_LIMIT ? 1 : 0
        })

        setTargetInfo(JSON.parse(JSON.stringify(targetObj)))

        setTargetInfo(prev => ({
          ...prev,
          IS_PERMANENT: targetObj.START_DATE == null && targetObj.END_DATE == null ? 1 : 0
        }))
        
        if (clickStatus === 'revise' && +targetObj.STATUS === 2) {
          setClickStatus('check')
          setTimeout(()=> {
            alert('※  此商品兌換已結束，無法進行修改')
          },200)
          return
        }
      } else {
        setCalenderInfo(JSON.parse(JSON.stringify(calender)))
        setLimitState({
          perAccount: 0, 
          perDay: 0, 
          perWeek: 0, 
          perMonth: 0,
          server: 0
        })
      }
    })
  }, [targetObj, calender, detailCopy])

  const [imgData, setImgData] = useState()
  const [fileData, setFileData] = useState(false)
  const handleUploadImg = e => {
    e.preventDefault()
    const file = e.target.files[0]
    if(file) {
      const formData = new FormData()
      formData.append('file', file)
      setImgData(formData)
      setFileData(true)
      const imgReader = new FileReader()
      imgReader.readAsDataURL(file)
      imgReader.onload = e => {
        setTargetInfo(prev => ({
          ...prev,
          IMG_URL: e.target.result
        }))
      }
    }
  }

  const [showDeleteHint, setDeleteHintShow] = useState(false)
  const handleDeleteHintClose = () => setDeleteHintShow(false)
  const handleDeleteHintShow = () => setDeleteHintShow(true)

  const [showEnd, setEndShow] = useState(false)
  const handleEndClose = () => setEndShow(false)
  const handleEndShow = () => setEndShow(true)

  const handleTarget = (e, key) => {
    setTargetInfo((prev) => ({
      ...prev,
        [key]: e
    }))
  }

  const handleTerm = (e, key) => {
    if (+e === 1) {
      setTargetInfo((prev) => ({
        ...prev,
        [key]: e,
        START_DATE: null,
        END_DATE: null
      }))

      for (let key in calenderInfo) {
        calenderInfo[key] = null
      }
    }
  }

  const handleLimit = (e, key) => {
    setLimitState(prev => ({
      ...prev,
      [key]: e
    }))
  }

  const handlePerAccountClose = () => {
    setTargetInfo(prev => ({
      ...prev,
      ACCOUNT_LIMIT: ''
    }))
  }

  const handlePerDayClose = () => {
    setTargetInfo(prev => ({
      ...prev,
      DAY_LIMIT: ''
    }))
  }
  
  const handlePerWeekClose = () => {
    setTargetInfo(prev => ({
      ...prev,
      WEEK_LIMIT: ''
    }))
  }

  const handlePerMonthClose = () => {
    setTargetInfo(prev => ({
      ...prev,
      MONTH_LIMIT: ''
    }))
  }

  const handleServerClose = () => {
    setTargetInfo((prev) => ({
      ...prev,
      TOTAL_LIMIT: ''
    }))
  }

  const handlePopUpClose = () => {
    handleDetailSettingClose()
    setTimeout(() => {
      setTargetInfo({
        PRODUCT_ID: 0, 
        CLASS_ID: 0, 
        ITEM_ID: 0,
        ORDER_ID: '',
        PRODUCT_TYPE: '',
        IMG_URL: '',
        PRODUCT_NAME: '',
        COST_TYPE: 1,
        COST_NUM: '',
        ITEM_TAG: 0,
        START_DATE: '',
        END_DATE: '',
        ACCOUNT_LIMIT: '',
        DAY_LIMIT: '',
        WEEK_LIMIT: '',
        MONTH_LIMIT: '',
        TOTAL_LIMIT: '',
        TEXT: '',
        STATUS: 0,
        DAY_CURRENT: '',
        WEEK_CURRENT: '',
        MONTH_CURRENT: '',
        TOTAL_CURRENT: '', 
        IS_PERMANENT: 1
      })
      setFileData(false)
      for (let key in calenderInfo) {
        calenderInfo[key] = ''
      }
    }, 500)
  }

  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 122)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      handleDetailSettingClose()
      handleDeleteHintShow()
      for (let key in calenderInfo) {
        calenderInfo[key] = ''
      }
    })
  }

  function dateMaker(startDate, startTime, endDate, endTime) {
    let SSet = ''
    let ESet = ''
    if (!startDate || !startTime) {
      alert('※  請輸入開始日期及時間')
      return
    }
    if (!endDate || !endTime) {
      alert('※  請輸入結束日期及時間')
      return
    }
    let SDate = new Date(startDate).toString().split(' ').slice(0, 4).join(' ')
    let STime = new Date(startTime).toString().split(' ').slice(4).join(' ')
    let EDate = new Date(endDate).toString().split(' ').slice(0, 4).join(' ')
    let ETime = new Date(endTime).toString().split(' ').slice(4).join(' ')
  
    SSet = `${SDate} ${STime}`
    ESet = `${EDate} ${ETime}`
    if (new Date(SSet).getTime() >= new Date(ESet).getTime()) {
      alert('※  結束時間不可早於或等於開始日期')
      return
    }
    return {SSet, ESet}
  }

  const handleDeleteItem = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setTargetInfo(prev => ({
        ...prev,
        IMG_URL: ''
      }))
      
    })
  }

  const handleGetItemConfigByItemId = (e) => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      getItemConfigByItemId(e).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if (db.data.length <= 0) {
          alert(`※  無此道具ID！`)
          return
        }
        setTargetInfo(prev => ({
          ...prev,
          IMG_URL: db.data[0].MAIN_IMG, 
          PRODUCT_NAME: db.data[0].ITEM_NAME, 
          TEXT: db.data[0].TEXT
        }))
      })
    })
  }

  

  const handleSave = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (clickStatus === 'add') {
        if (!userPID.find(ele => +ele === 120)) {
          alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
          return
        }
      }

      if (clickStatus === 'revise') {
        if (!userPID.find(ele => +ele === 121)) {
          alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
          return
        }
      }
      
      if (!targetInfo.ORDER_ID) {
        alert('※  請填入排序欄位')
        return
      }
      if (isNaN(+targetInfo.ORDER_ID)) {
        alert('※  排序欄位請填入數字')
        return
      }
      if (clickStatus === 'add') {
        if (detailCopy.find(ele => +ele.ORDER_ID === +targetInfo.ORDER_ID)) {
          alert('※  排序不可重複')
          setTargetInfo(prev => ({
            ...prev,
            ORDER_ID: ''
          }))
          return
        }
      }
      
      if (clickStatus === 'revise') {
        if (+targetObj.ORDER_ID !== +targetInfo.ORDER_ID) {
          if (detailCopy.find(ele => +ele.ORDER_ID === +targetInfo.ORDER_ID)) {
            alert('※  排序不可重複')
          setTargetInfo(prev => ({
            ...prev,
            ORDER_ID: +targetObj.ORDER_ID
          }))
          return
          }
        }
      }

      if (targetInfo.PRODUCT_NAME.length > 12) {
        alert('※  名稱欄位字數不符')
        return
      }
      if (targetInfo.PRODUCT_NAME.indexOf(' ') >= 0) {
        alert('※  名稱欄位不可包含空白格')
        setTargetInfo(prev => ({
          ...prev,
          PRODUCT_NAME: ''
        }))
        return
      }
      if (!targetInfo.COST_TYPE) {
        alert('※  請填入價格欄位')
        return
      }
      if (isNaN(+targetInfo.COST_NUM)) {
        alert('※  價格欄位請填入數字')
        return
      }
      if (+targetInfo.STATUS === 2) {
        handleDetailSettingClose()
        handleEndShow()
        return
      }

      if (clickStatus === 'add') {
        if (fileData) {
          uploadItem(imgData).then(db => {
            if (db.error === errorCode.INCORRECT_DATA.code) {
              alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.INCORRECT_FORMAT.code) {
              alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            setTargetInfo(prev => ({
              ...prev,
              IMG_URL: db.data.url
            }))
            let targetUrl = db.data.url
            
            let startDateWrite = targetInfo.START_DATE;
            let endDateWrite = targetInfo.END_DATE;
            if (+targetInfo.IS_PERMANENT === 0) {
              let result = dateMaker(calenderInfo.startDate, calenderInfo.startTime, calenderInfo.endDate, calenderInfo.endTime)
              setTargetInfo(prev => ({
                ...prev,
                START_DATE: new Date(result.SSet),
                END_DATE: new Date(result.ESet)
              }))
              startDateWrite = new Date(result.SSet)
              endDateWrite = new Date(result.ESet)
            } else {
              startDateWrite = null
              endDateWrite = null
            }

            addShopConfig(
              +queryValue.CLASS_ID, 
              +targetInfo.ITEM_ID, 
              +targetInfo.ORDER_ID, 
              targetInfo.PRODUCT_TYPE,
              targetUrl, 
              targetInfo.PRODUCT_NAME,  
              +targetInfo.COST_TYPE, 
              +targetInfo.COST_NUM,
              +targetInfo.ITEM_TAG, 
              startDateWrite, 
              endDateWrite, 
              +targetInfo.ACCOUNT_LIMIT,
              +targetInfo.DAY_LIMIT,
              +targetInfo.WEEK_LIMIT,
              +targetInfo.MONTH_LIMIT,
              +targetInfo.TOTAL_LIMIT,
              targetInfo.TEXT, 
              +targetInfo.STATUS, 
              +targetInfo.IS_PERMANENT).then(db => {
                if (db.error === errorCode.INCORRECT_DATA.code) {
                  alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                  return
                }
                if (db.error === errorCode.INCORRECT_FORMAT.code) {
                  alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                  return
                }
                if (db.error === errorCode.EXCEPTION.code) {
                  alert(`※  ${errorCode.EXCEPTION.memo}`)
                  return
                }
                getDetail(+queryValue.CLASS_ID)
                handleDetailSettingClose()
                setTimeout(() => {
                  alert('※  商品細項設定儲存成功')
                }, 200)
              })
          })
        }
      
        if (!fileData) {
          let startDateWrite = targetInfo.START_DATE;
          let endDateWrite = targetInfo.END_DATE;
          if (+targetInfo.IS_PERMANENT === 0) {
            let result = dateMaker(calenderInfo.startDate, calenderInfo.startTime, calenderInfo.endDate, calenderInfo.endTime)
            setTargetInfo(prev => ({
              ...prev,
              START_DATE: new Date(result.SSet),
              END_DATE: new Date(result.ESet)
            }))
            startDateWrite = new Date(result.SSet)
            endDateWrite = new Date(result.ESet)
          } else {
            startDateWrite = null
            endDateWrite = null
          }

          addShopConfig(
            +queryValue.CLASS_ID, 
            +targetInfo.ITEM_ID, 
            +targetInfo.ORDER_ID, 
            targetInfo.PRODUCT_TYPE, 
            targetInfo.IMG_URL, 
            targetInfo.PRODUCT_NAME,  
            +targetInfo.COST_TYPE, 
            +targetInfo.COST_NUM,
            +targetInfo.ITEM_TAG, 
            startDateWrite, 
            endDateWrite, 
            +targetInfo.ACCOUNT_LIMIT,
            +targetInfo.DAY_LIMIT,
            +targetInfo.WEEK_LIMIT,
            +targetInfo.MONTH_LIMIT,
            +targetInfo.TOTAL_LIMIT,
            targetInfo.TEXT, 
            +targetInfo.STATUS, 
            +targetInfo.IS_PERMANENT).then(db => {
              if (db.error === errorCode.INCORRECT_DATA.code) {
                alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                return
              }
              if (db.error === errorCode.INCORRECT_FORMAT.code) {
                alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                return
              }
              if (db.error === errorCode.EXCEPTION.code) {
                alert(`※  ${errorCode.EXCEPTION.memo}`)
                return
              }
              getDetail(+queryValue.CLASS_ID)
              handleDetailSettingClose()
              setTimeout(() => {
                alert('※  商品細項設定儲存成功')
              }, 200)
            })
        }
      }

      //alert("???")
      
      if (clickStatus === 'revise') {
        if (fileData) {
          uploadItem(imgData).then(db => {
            if (db.error === errorCode.INCORRECT_DATA.code) {
              alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.INCORRECT_FORMAT.code) {
              alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            setTargetInfo(prev => ({
              ...prev,
              IMG_URL: db.data.url
            }))
            let targetUrl = db.data.url

            let startDateWrite = targetInfo.START_DATE;
            let endDateWrite = targetInfo.END_DATE;         
            if (+targetInfo.IS_PERMANENT === 0) {
              let result = dateMaker(calenderInfo.startDate, calenderInfo.startTime, calenderInfo.endDate, calenderInfo.endTime)
              setTargetInfo(prev => ({
                ...prev,
                START_DATE: new Date(result.SSet),
                END_DATE: new Date(result.ESet)
              }))
              startDateWrite = new Date(result.SSet)
              endDateWrite = new Date(result.ESet)
            } else {
              startDateWrite = null
              endDateWrite = null
            }

            reviseShopConfig(
              +targetInfo.PRODUCT_ID, 
              +queryValue.CLASS_ID, 
              +targetInfo.ITEM_ID, 
              +targetInfo.ORDER_ID, 
              targetInfo.PRODUCT_TYPE,
              targetUrl, 
              targetInfo.PRODUCT_NAME,  
              +targetInfo.COST_TYPE, 
              +targetInfo.COST_NUM,
              +targetInfo.ITEM_TAG, 
              startDateWrite, 
              endDateWrite, 
              +targetInfo.ACCOUNT_LIMIT,
              +targetInfo.DAY_LIMIT,
              +targetInfo.WEEK_LIMIT,
              +targetInfo.MONTH_LIMIT,
              +targetInfo.TOTAL_LIMIT,
              targetInfo.TEXT, 
              +targetInfo.STATUS, 
              +targetInfo.IS_PERMANENT).then(db => {
                if (db.error === errorCode.INCORRECT_DATA.code) {
                  alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                  return
                }
                if (db.error === errorCode.INCORRECT_FORMAT.code) {
                  alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                  return
                }
                if (db.error === errorCode.EXCEPTION.code) {
                  alert(`※  ${errorCode.EXCEPTION.memo}`)
                  return
                }
                getDetail(+queryValue.CLASS_ID)
                handleDetailSettingClose()
                setTimeout(() => {
                  alert('※  商品細項設定儲存成功')
                }, 200)
              })
          })
        }
        
        if (!fileData) {
          let startDateWrite = targetInfo.START_DATE;
          let endDateWrite = targetInfo.END_DATE;
          //alert("IN = " + +targetInfo.IS_PERMANENT)
          if (+targetInfo.IS_PERMANENT === 0) {
            //alert(calenderInfo.endDate)
            let result = dateMaker(calenderInfo.startDate, calenderInfo.startTime, calenderInfo.endDate, calenderInfo.endTime)
            setTargetInfo(prev => ({
              ...prev,
              START_DATE: new Date(result.SSet),
              END_DATE: new Date(result.ESet)
            }))
            startDateWrite = new Date(result.SSet)
            endDateWrite = new Date(result.ESet)
          } else {
            startDateWrite = null
            endDateWrite = null
          }

          reviseShopConfig(
            +targetInfo.PRODUCT_ID,
            +queryValue.CLASS_ID, 
            +targetInfo.ITEM_ID, 
            +targetInfo.ORDER_ID, 
            targetInfo.PRODUCT_TYPE, 
            targetInfo.IMG_URL, 
            targetInfo.PRODUCT_NAME,  
            +targetInfo.COST_TYPE, 
            +targetInfo.COST_NUM,
            +targetInfo.ITEM_TAG, 
            startDateWrite, 
            endDateWrite, 
            +targetInfo.ACCOUNT_LIMIT,
            +targetInfo.DAY_LIMIT,
            +targetInfo.WEEK_LIMIT,
            +targetInfo.MONTH_LIMIT,
            +targetInfo.TOTAL_LIMIT,
            targetInfo.TEXT, 
            +targetInfo.STATUS, 
            +targetInfo.IS_PERMANENT).then(db => {
              if (db.error === errorCode.INCORRECT_DATA.code) {
                alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                return
              }
              if (db.error === errorCode.INCORRECT_FORMAT.code) {
                alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                return
              }
              if (db.error === errorCode.EXCEPTION.code) {
                alert(`※  ${errorCode.EXCEPTION.memo}`)
                return
              }
              getDetail(+queryValue.CLASS_ID)
              handleDetailSettingClose()
              setTimeout(() => {
                alert('※  商品細項設定儲存成功')
              }, 200)
            })
        }
      }
      setTimeout(() => {
        setLimitState({perAccount: 0, 
          perDay: 0, 
          perWeek: 0, 
          perMonth: 0,
          server: 0})
      }, 2000)
      setFileData(false)
    })
  }

  return (
    <>
      <ModalFormat
        show={showDetailSetting}
        onHide={handlePopUpClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>商品細項{clickStatus == 'check' ? '設定' : (clickStatus == 'add' ? '新增' : '修改')} : </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <BasicHeadTitle>商品ID</BasicHeadTitle>
              <td colSpan= "3">
                <DetailInput
                  placeholder= "自動產生"
                  disabled={clickStatus == 'add' || clickStatus == 'check' ? true : false}
                  value= {targetInfo.PRODUCT_ID}
                  onChange= {e => {handleTarget(e.target.value, 'PRODUCT_ID')}}
                />
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>商品排序</BasicHeadTitle>
              <td colSpan= "3">
                <DetailInput
                  placeholder= "請輸入排序"
                  value= {targetInfo.ORDER_ID}
                  onChange= {e => {handleTarget(e.target.value, 'ORDER_ID')}}
                  disabled= {clickStatus === 'check' ? true : false}
                />
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>商品子分類</BasicHeadTitle>
              <td colSpan= "3">
                <DetailInput
                  placeholder= "請輸入名稱"
                  value= {targetInfo.PRODUCT_TYPE}
                  onChange= {e => {handleTarget(e.target.value, 'PRODUCT_TYPE')}}
                  disabled= {clickStatus === 'check' ? true : false}
                />
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>商品道具ID</BasicHeadTitle>
              <td colSpan= "2">
                <DetailInput
                  placeholder= "請輸入道具ID"
                  value= {targetInfo.ITEM_ID}
                  onChange= {e => {handleTarget(e.target.value, 'ITEM_ID'); handleChangeForSearch(e.target.value, 'itemId')}}
                  disabled= {clickStatus === 'check' ? true : false}
                />
              </td>
              <td>
              <Button 
                style={clickStatus === 'check' ? {display: 'none'} : {display: 'block'}} 
                onClick={(e) =>{
                  console.log('clickStart')
                  handleGetItemConfigByItemId(itemIdForSearch.itemId)
                  e.stopPropagation();
                  console.log('label trigger')
                }} variant="info">讀取</Button>
              
              </td>
            </tr>

            <tr>
              <Title>商品圖</Title>
              <PopFrame>
                <Avatar
                  src={targetInfo.IMG_URL}
                  className="rounded"
                />
                <NoImg style= {targetInfo.IMG_URL ? {display: 'none'} : {display: 'block'}}>無商品圖</NoImg>
              </PopFrame>
              <LabelBtn style={clickStatus === 'check' ? {display: 'none'} : {display: ''}}>
                <span>上傳</span>
                <input type= "file" name= "file" style={{display: "none", cursor: 'pointer'}} onChange= {handleUploadImg} disabled= {clickStatus === 'check' || +targetObj.STATUS === 2 ? true : false}/>
              </LabelBtn>
              <td>※此處更改不影響原道具設定</td>
            </tr>

            <tr>
              <BasicHeadTitle>商品名稱</BasicHeadTitle>
              <td colSpan= "3">
                <DetailInput
                  placeholder= "名稱最多12個字"
                  value= {targetInfo.PRODUCT_NAME}
                  onChange= {e => {handleTarget(e.target.value, 'PRODUCT_NAME')}}
                  disabled= {clickStatus === 'check' ? true : false}
                />
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>付費</BasicHeadTitle>
              <td colSpan= "3">
                <PUDropdown
                  className= "btn dropdown-toggle"
                  value= {+targetInfo.COST_TYPE}
                  onChange= {e =>{handleTarget(e.target.value, 'COST_TYPE')}}
                  disabled= {clickStatus === 'check' ? true : false}
                >
                  <option className= "dropdown-item" value= "1">金幣</option>
                  <option className= "dropdown-item" value= "2">鬥陣寶</option>
                </PUDropdown>
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>價格</BasicHeadTitle>
              <td colSpan= "3">
                <DetailInput
                  placeholder= "請輸入兌換價格"
                  value= {targetInfo.COST_NUM}
                  onChange= {e => {handleTarget(e.target.value, 'COST_NUM')}}
                  disabled= {clickStatus === 'check' ? true : false}
                />
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>標籤設定</BasicHeadTitle>
              <td colSpan= "3">
                <PUDropdown
                  className= "btn dropdown-toggle"
                  value= {targetInfo.ITEM_TAG}
                  onChange= {e => {handleTarget(e.target.value, 'ITEM_TAG')}}
                  disabled= {clickStatus === 'check' ? true : false}
                >
                  <option className= "dropdown-item" value= "0">無</option>
                  <option className= "dropdown-item" value= "1">限量</option>
                  <option className= "dropdown-item" value= "2">限時</option>
                  <option className= "dropdown-item" value= "3">活動</option>
                  <option className= "dropdown-item" value= "4">熱門</option>
                  <option className= "dropdown-item" value= "5">推薦</option>
                </PUDropdown>
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>※時間設定</BasicHeadTitle>
              <td colSpan= "3">
                <input
                  type= "radio"
                  value= "1"
                  onChange= {e => {handleTerm(e.target.value, 'IS_PERMANENT')}}
                  checked= {+targetInfo.IS_PERMANENT === 1 ? true : false}
                  disabled= {clickStatus === 'check' ? true : false}
                />永久
                <TimeLimited
                  type= "radio"
                  value= "0"
                  onChange= {e => {handleTarget(e.target.value, 'IS_PERMANENT')}}
                  checked= {+targetInfo.IS_PERMANENT === 0 ? true : false}
                  disabled= {clickStatus === 'check' ? true : false}
                />期間限定
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>開始日期</BasicHeadTitle>
              <td colSpan= "3">
                <SendTimeWrapper>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label= "日期"
                      name= "startDate"
                      value= {calenderInfo.startDate === "2001-01-01T00:00:00.000Z"? '' : calenderInfo.startDate}
                      onChange= {(newValue) => {
                        setCalenderInfo(prev => ({
                          ...prev,
                          startDate: newValue
                        }))
                      }}
                      disabled= {clickStatus === 'check' ? true : +targetInfo.IS_PERMANENT === 1 ? true :false}
                      renderInput={({ inputRef, inputProps, InputProps }) => {
                        inputProps.placeholder = '請選擇開始日期'
                        return (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <DetailInput
                              id= "datePicker"
                              ref= {inputRef} {...inputProps}
                              disabled= {clickStatus === 'check' ? true : +targetInfo.IS_PERMANENT === 1 ? true :false}
                            />
                            <div id="dateBtn1">{InputProps?.endAdornment}</div>
                          </Box>
                        )
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label="時間"
                      name= "startTime"
                      value= {calenderInfo.startTime === "2001-01-01T00:00:00.000Z" ? '' : calenderInfo.startTime}
                      onChange= {(newValue) => {
                        setCalenderInfo(prev => ({
                          ...prev,
                          startTime: newValue
                        }))
                      }}
                      disabled= {clickStatus === 'check' ? true : +targetInfo.IS_PERMANENT === 1 ? true :false}
                      renderInput= {({ inputRef, inputProps, InputProps }) => {
                        inputProps.placeholder = '請選擇開始時間'
                        return (
                          <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                            <DetailInput
                              id= "timePicker"
                              ref= {inputRef} {...inputProps}
                              disabled= {clickStatus === 'check' ? true : +targetInfo.IS_PERMANENT === 1 ? true :false}
                            />
                            {InputProps?.endAdornment}
                          </TimeFormat>
                        )
                      }}
                    />
                  </LocalizationProvider>
                </SendTimeWrapper>
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>結束日期</BasicHeadTitle>
              <td colSpan= "3">
                <SendTimeWrapper>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label= "日期"
                      name= "endDate"
                      value={calenderInfo.endDate === "2100-12-31T23:59:59.000Z" ? '' : calenderInfo.endDate}
                      onChange= {(newValue) => {
                        setCalenderInfo(prev => ({
                          ...prev,
                          endDate: newValue
                        }))
                      }}
                      disabled= {clickStatus === 'check' ? true : +targetInfo.IS_PERMANENT === 1 ? true :false}
                      renderInput={({ inputRef, inputProps, InputProps }) => {
                        inputProps.placeholder = '請選擇結束日期'
                        return (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <DetailInput
                              id= "datePicker"
                              ref={inputRef} {...inputProps}
                              disabled= {clickStatus === 'check' ? true : +targetInfo.IS_PERMANENT === 1 ? true :false}
                            />
                            <div id="dateBtn2">{InputProps?.endAdornment}</div>
                          </Box>
                        )
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label="時間"
                      name= "endTime"
                      value={calenderInfo.endTime === "2100-12-31T23:59:59.000Z" ? '' : calenderInfo.endTime}
                      onChange= {(newValue) => {
                        setCalenderInfo(prev => ({
                          ...prev,
                          endTime: newValue
                        }))
                      }}
                      disabled= {clickStatus === 'check' ? true : +targetInfo.IS_PERMANENT === 1 ? true :false}
                      renderInput={({ inputRef, inputProps, InputProps }) => {
                        inputProps.placeholder = '請選擇結束時間'
                        return (
                          <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                            <DetailInput
                            id= "timePicker"
                            ref={inputRef} {...inputProps}
                            disabled= {clickStatus === 'check' ? true : +targetInfo.IS_PERMANENT === 1 ? true :false}
                          />
                            {InputProps?.endAdornment}
                          </TimeFormat>
                        )
                      }}
                    />
                  </LocalizationProvider>
                </SendTimeWrapper>
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>帳號限購</BasicHeadTitle>
              <td colSpan= "3">
                <LimitGroup>
                  <div>
                    <input
                      type ="radio"
                      value= "0"
                      onChange=  {e => {handleLimit(e.target.value, 'perAccount')}}
                      checked= {!+limitState.perAccount ? true : false}
                      disabled= {clickStatus === 'check' ? true : false}
                      onClick= {handlePerAccountClose}
                    />關閉
                  </div>
                  <Limit>
                    <div>
                      <input
                        type ="radio"
                        value= "1"
                        onChange=  {e => {handleLimit(e.target.value, 'perAccount')}}
                        checked= {+limitState.perAccount === 1  ? true : false}
                        disabled= {clickStatus === 'check' ? true : false}
                      />開啟 : 次數
                    </div>
                    <LimitTimes
                      value= {!+targetInfo.ACCOUNT_LIMIT ? '' : targetInfo.ACCOUNT_LIMIT}
                      placeholder= "請輸入數字"
                      onChange= {e => {handleTarget(e.target.value, 'ACCOUNT_LIMIT')}}
                      disabled= {clickStatus === 'check' ? true : !+limitState.perAccount ? true : false}
                    />
                  </Limit>
                </LimitGroup>
              </td>

            </tr>

            <tr>
              <BasicHeadTitle>單日限購</BasicHeadTitle>
              <td colSpan= "3">
                <LimitGroup>
                  <div>
                    <input
                      type ="radio"
                      value= "0"
                      onChange=  {e => {handleLimit(e.target.value, 'perDay')}}
                      checked= {!+limitState.perDay ? true : false}
                      disabled= {clickStatus === 'check' ? true : false}
                      onClick= {handlePerDayClose}
                    />關閉
                  </div>
                  <Limit>
                    <div>
                      <input
                        type ="radio"
                        value= "1"
                        onChange=  {e => {handleLimit(e.target.value, 'perDay')}}
                        checked= {+limitState.perDay === 1  ? true : false}
                        disabled= {clickStatus === 'check' ? true : false}
                      />開啟 : 次數
                    </div>
                    <LimitTimes
                      value= {!+targetInfo.DAY_LIMIT ? '' : targetInfo.DAY_LIMIT}
                      placeholder= "請輸入數字"
                      onChange= {e => {handleTarget(e.target.value, 'DAY_LIMIT')}}
                      disabled= {clickStatus === 'check' ? true : !+limitState.perDay ? true : false}
                    />
                  </Limit>
                </LimitGroup>
              </td>

            </tr>

            <tr>
              <BasicHeadTitle>單周限購</BasicHeadTitle>
              <td colSpan= "3">
                <LimitGroup>
                  <div>
                    <input
                      type ="radio"
                      value= "0"
                      onChange=  {e => {handleLimit(e.target.value, 'perWeek')}}
                      checked= {!+limitState.perWeek ? true : false}
                      disabled= {clickStatus === 'check' ? true : false}
                      onClick= {handlePerWeekClose}
                    />關閉
                  </div>
                  <Limit>
                    <div>
                      <input
                        type ="radio"
                        value= "1"
                        onChange=  {e => {handleLimit(e.target.value, 'perWeek')}}
                        checked= {+limitState.perWeek === 1  ? true : false}
                        disabled= {clickStatus === 'check' ? true : false}
                      />開啟 : 次數
                    </div>
                    <LimitTimes
                      value= {!+targetInfo.WEEK_LIMIT ? '' : targetInfo.WEEK_LIMIT}
                      placeholder= "請輸入數字"
                      onChange= {e => {handleTarget(e.target.value, 'WEEK_LIMIT')}}
                      disabled= {clickStatus === 'check' ? true : !+limitState.perWeek ? true : false}
                    />
                  </Limit>
                </LimitGroup>
              </td>

            </tr>

            <tr>
              <BasicHeadTitle>單月限購</BasicHeadTitle>
              <td colSpan= "3">
                <LimitGroup>
                  <div>
                    <input
                      type ="radio"
                      value= "0"
                      onChange=  {e => {handleLimit(e.target.value, 'perMonth')}}
                      checked= {!+limitState.perMonth ? true : false}
                      disabled= {clickStatus === 'check' ? true : false}
                      onClick= {handlePerMonthClose}
                    />關閉
                  </div>
                  <Limit>
                    <div>
                      <input
                        type ="radio"
                        value= "1"
                        onChange=  {e => {handleLimit(e.target.value, 'perMonth')}}
                        checked= {+limitState.perMonth === 1  ? true : false}
                        disabled= {clickStatus === 'check' ? true : false}
                      />開啟 : 次數
                    </div>
                    <LimitTimes
                      value= {!+targetInfo.MONTH_LIMIT ? '' : targetInfo.MONTH_LIMIT}
                      placeholder= "請輸入數字"
                      onChange= {e => {handleTarget(e.target.value, 'MONTH_LIMIT')}}
                      disabled= {clickStatus === 'check' ? true : !+limitState.perMonth ? true : false}
                    />
                  </Limit>
                </LimitGroup>
              </td>

            </tr>

            <tr>
              <BasicHeadTitle>全服限購</BasicHeadTitle>
              <td colSpan= "3">
              <LimitGroup>
                <div>
                  <input
                    type ="radio"
                    value= "0"
                    onChange=  {e=> {handleLimit(e.target.value, 'server')}}
                    checked= {!+limitState.server ? true : false}
                    disabled= {clickStatus === 'check' ? true : false}
                    onClick= {handleServerClose}
                  />關閉
                </div>
                <Limit>
                  <div>
                    <input
                      type ="radio"
                      value= "1"
                      onChange=  {e => {handleLimit(e.target.value, 'server')}}
                      checked= {+limitState.server ? true : false}
                      disabled= {clickStatus === 'check' ? true : false}
                    />開啟 : 次數
                  </div>
                  <LimitTimes
                    placeholder= "請輸入數字"
                    value= {!+targetInfo.TOTAL_LIMIT ? '' : targetInfo.TOTAL_LIMIT}
                    onChange= {e => {handleTarget(e.target.value, 'TOTAL_LIMIT')}}
                    disabled= {clickStatus === 'check' ? true : !+limitState.server ? true : false}
                  />
                </Limit>
              </LimitGroup>
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>說明文</BasicHeadTitle>
              <td colSpan= "2">
                <textarea
                  className="form-control"
                  rows= "5"
                  placeholder= "請填寫內文"
                  value= {targetInfo.TEXT}
                  onChange= {e => {handleTarget(e.target.value, 'TEXT')}}
                  disabled= {clickStatus === 'check' ? true : false}
                />
              </td>
              <td>
              ※此處更改不影響原道具設定
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>開關</BasicHeadTitle>
              <td colSpan= "3">
                <PUDropdown
                  className= "btn dropdown-toggle"
                  value= {+targetInfo.STATUS}
                  onChange= {e => {handleTarget(e.target.value, 'STATUS')}}
                  disabled= {clickStatus === 'check' ? true : false}
                >
                  <option className= "dropdown-item" value= "0">關閉</option>
                  <option className= "dropdown-item" value= "1">開啟</option>
                  <option className= "dropdown-item" value= "2">結束</option>
                </PUDropdown>
              </td>
            </tr>

          </tbody>
        </table>
        </Modal.Body>
        <ModalBtn>
          <Button style= {switchDelete ? {display: 'block'} : {display: 'none'}} onClick= {handleDelete} variant="danger">刪除</Button>
          <Button style= {switchSave ? {display: 'block'} : {display: 'none'}} onClick= {handleSave} variant="success">儲存</Button>
        </ModalBtn>
      </ModalFormat>

      <DeletePU
        showDeleteHint= {showDeleteHint}
        handleDeleteHintClose= {handleDeleteHintClose}
        targetInfo= {targetInfo}
        setDetailCopy= {setDetailCopy}
        setDetailInfo= {setDetailInfo}
        queryValue= {queryValue}
      />

      <HandleEndStatus
        showEnd= {showEnd}
        handleEndClose= {handleEndClose}
        calenderInfo= {calenderInfo}
        targetInfo= {targetInfo}
        setTargetInfo= {setTargetInfo}
        queryValue= {queryValue}
        setDetailCopy= {setDetailCopy}
        setDetailInfo= {setDetailInfo}
        clickStatus= {clickStatus}
        imgData= {imgData}
        fileData= {fileData}
        setFileData= {setFileData}
        dateMaker= {dateMaker}
        setLimitState= {setLimitState}
      />
    </>
  )
}