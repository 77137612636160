export const BaseUrl = "https://dzxqwebservice-test.shengxingamers.com:5080"
//開發: "https://dzxqwebservicedev.shengxingamers.com:5080"
//測試: "https://dzxqwebservice-test.shengxingamers.com:5080"
//正式: "https://dzxqwebservice.shengqigamers.com/backstage5080api"
//virtualBox: "http://127.0.0.1:5080"
export const UploadUrl = "https://dzxqwebservice-test.shengxingamers.com:3080/upload/exchangeImages"
//開發: https://dzxqwebservicedev.shengxingamers.com:3080/upload/exchangeImages
//測試: https://dzxqwebservice-test.shengxingamers.com:3080/upload/exchangeImages
//正式: https://dzxqwebservice.shengqigamers.com/verify3080/upload/exchangeImages
export const UploadMaterial = "https://dzxqwebservice-test.shengxingamers.com:3080/upload/materialImages"
//開發: https://dzxqwebservicedev.shengxingamers.com:3080/upload/materialImages
//測試: https://dzxqwebservice-test.shengxingamers.com:3080/upload/materialImages
//正式: https://dzxqwebservice.shengqigamers.com/verify3080/upload/materialImages