import styled from 'styled-components'
import { BasicInput, AddBtn } from '../../../middleware/utilityStyle'

export const NavRow = styled.div`
  display: flex;
  justify-content: space-between;
`

export const GameDropdown1 = styled.select`
  border: 1px solid black;
  border-radius: 5px;
  height: 2rem;
  width: 10rem;
`

export const GameDropdown2 = styled.select`
  margin-left: 20px;
  border: 1px solid black;
  border-radius: 5px;
  height: 2rem;
  width: 10rem;
`

export const SearchBtn = styled.button`
  margin-left: 20px;
  color: orange;
  border: 1px solid orange;
  border-radius: 5px;
  transition: 0.7s;
  background: white;
  height: 2rem;
  width: 4rem;

  :hover {
    color: white;
    font-weight: bold;
    background: orange;
  }
`

export const ReviseInput = styled(BasicInput)`
  text-align: center;
  height: 28px;
  width: 100px;
`

export const RBtn = styled.div`
  display: flex;
  justify-content: space-around;
`