const TOKEN__Name = 'token'

export const setAuthToken = token => {
  localStorage.setItem(TOKEN__Name, token)
}

export const getAuthToken = () => {
  return localStorage.getItem(TOKEN__Name)
}

export const setNavAcc = name => {
  localStorage.setItem('NavAcc', name)
}

export const getNavAcc = () => {
  return localStorage.getItem('NavAcc')
}

export const setAccessArr = accessArr => {
  localStorage.setItem('AccessArr', accessArr)
}

export const getAccessArr = () => {
  return JSON.parse(localStorage.getItem('AccessArr'))
}

export const setCharacterType = characterType => {
  localStorage.setItem('CharacterType', characterType)
}

export const getCharacterType = () => {
  return localStorage.getItem('CharacterType')
}