import { getAuthToken } from '../../utils.js'
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 新增信件 PID29 ?//
export const AddMail = (isNow, isAll, idList, title, contain, itemList, mailDate, userName) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/gameMail`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      isNow,
      isAll,
      idList,
      title,
      contain,
      itemList,
      mailDate,
      userName
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}