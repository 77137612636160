import React from "react"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { deleteAnnouncement } from "../../../middleware/Api/playerInfo/announcementApi"
import { announcementList } from "../../../middleware/Api/playerInfo/announcementApi"
import { ModalBtn, ModalText } from "../../../middleware/utilityStyle"

export default function DeletePU(props) {
  const {
    history,
    showDeletePU,
    handleDeletePUClose,
    handleInfoPUShow,
    targetList,
    setTargetList,
    handleCleanData,
    setPanelInfo,
    setDataCopy,
    dateGroup
  } = props

  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      deleteAnnouncement(+targetList.ORDER_ID).then(db=> {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        handleDeletePUClose()
        handleCleanData()
        setTargetList({
          ORDER_ID: '',
          STATE: 1,
          TYPE: '',
          BEGIN_DATE: '',
          EXPIRE_DATE: '',
          TITLE: '',
          TEXT: '',
          SUBTITLE: ''
        })
        for (let key in dateGroup) {
          dateGroup[key] = null
        }
        announcementList().then(db => {
          if (db.error === errorCode.NULL_DATA.code) {
            alert(`※  ${errorCode.NULL_DATA.memo}`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          if (Array.isArray(db.data) && db.data.length) {
            db.data.sort(function order(a, b) {
              return a.ORDER_ID - b.ORDER_ID
            })
            db.data.sort(function order(a, b) {
              return b.TYPE - a.TYPE
            })
            setPanelInfo(db.data)
            setDataCopy(db.data)
          }
        })
      })
    })
  }

  const handleRefuseDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleInfoPUShow()
      handleDeletePUClose()
    })
  }
  return (
    <Modal
      show={showDeletePU}
      onHide={handleDeletePUClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>刪除確認</Modal.Title>
      </Modal.Header>
      <ModalText>
        確定要刪除資料嗎?
      </ModalText>
      <ModalBtn>
        <Button variant="info" onClick={handleDelete}>刪除</Button>
        <Button variant="secondary" onClick={handleRefuseDelete}>上一步</Button>
      </ModalBtn>
    </Modal>
  )
}