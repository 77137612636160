import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import gameCategories from "../../../assets/config/gameCategories.json"
import { getCurrentUsers } from "../../../middleware/Api/operation/currentUsersApi"
import ChartistGraph from "react-chartist"
import {
  Card,
  Row,
  Col,
} from "react-bootstrap"
import {
  InputBarBg,
  InputBarRWD,
  Dropdown,
  BtnGroup,
  TitleBg
} from "./styledComponent"
import {
  SearchButton,
  ResetButton,
  LineChartBtn,
  OnlineNum,
  TimeBar,
  ChartForm,
  Line4,
  Line5,
  Line6,
  Line7,
  Line8,
  Line9,
  Line10,
  Line11,
  Line12,
  ChartDetail,
  CardNoData,
  BasicTable,
  TableContent,
  BasicRowTitle,
} from "../../../middleware/utilityStyle.js"
import { getUsersHistory } from "../../../middleware/Api/operation/usersHistoryApi"

function getHighestNum (dbData, day, dayE, logType) {
  let result = ''
  if (dbData.length > 0 && Array.isArray(dbData)) {
    let dataList = dbData.filter(ele => new Date(ele.LOG_DATE).getTime() >= day && new Date(ele.LOG_DATE).getTime() <= dayE)
    let targetLogType = dataList.filter(ele => +ele.LOG_TYPE === logType)
    targetLogType.sort(function order(a, b) {
      return a.ONLINE_NUM - b.ONLINE_NUM
    })
    if (targetLogType[targetLogType.length-1]) {
      result = targetLogType[targetLogType.length-1]
    }
    if (!targetLogType[targetLogType.length-1]) {
      result = {ONLINE_NUM: 0}
    }
    return result
  }
}

function getAverageNum (dbData, day, dayE, logType) {
  if (dbData.length > 0 && Array.isArray(dbData)) {
    let targetList = dbData.filter(ele => new Date(ele.LOG_DATE).getTime() >= day && new Date(ele.LOG_DATE).getTime() <= dayE)
    let targetLogType = targetList.filter(ele => +ele.LOG_TYPE === logType)
    let totalUsers = 0
    for (let i = 0; i < targetLogType.length; i++) {
      totalUsers += targetLogType[i].ONLINE_NUM
    }
    let result = Math.round(totalUsers/144)
    return result
  }
}

export default function concurrentOnlineUsers() {
  const history = useHistory()
  const [realPlayer, setRealPlayer] = useState(0)
  const [AIPlayer, setAIPlayer] = useState(0)
  const [TTPlayer, setTTPlayer] = useState(0)

  const [chessNum, setChessNum] = useState('')
  const [chessAINum, setChessAINum] = useState('')
  const [MJNum, setMJNum] = useState('')
  const [MJAINum, setMJAINum] = useState('')
  const [dominoNum, setDominoNum] = useState('')
  const [dominoAINum, setDominoAINum] = useState('')
  const [MJ2PNum, setMJ2PNum] = useState('')
  const [MJ2PAINum, setMJ2PAINum] = useState('')
  const [chessArenaNum, setChessArenaNum] = useState('')
  const [chessArenaAINum, setChessArenaAINum] = useState('')
  const [MJArenaNum, setMJArenaNum] = useState('')
  const [MJArenaAINum, setMJArenaAINum] = useState('')

  const [showChess, setShowChess] = useState(false)
  const [showMJ, setShowMJ] = useState(false)
  const [showDomino, setShowDomino] = useState(false)
  const [showMJ2P, setShowMJ2P] = useState(false)
  const [showChessArena, setShowChessArena] = useState(false)
  const [showMJArena, setShowMJArena] = useState(false)

  const [yDayChessNum, setYDayChessNum]= useState(0)
  const [yDayMJNum, setYDayMJNum]= useState(0)
  const [yDayDominoNum, setYDayDominoNum] = useState(0)
  const [yDayMJ2PNum, setYDayMJ2PNum]= useState(0)
  const [yDayChessArenaNum, setYDayChessArenaNum] = useState(0)
  const [yDayMJArenaNum, setYDayMJArenaNum] = useState(0)

  const [change, setChange] = useState({gameType: 1})
  const handleChange = (e, key) => {
    setChange(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  function lineMaker(dbData, day, dayE, logType) {
    let targetPeriod = dbData.filter(ele => new Date(ele.LOG_DATE).getTime() >= day && new Date(ele.LOG_DATE).getTime() <= dayE)
    let targetLogType = targetPeriod.filter(ele => +ele.LOG_TYPE === logType)
    let timeOrder = targetLogType.sort(function order(a, b) {
      return new Date(a.LOG_DATE).getTime() - new Date(b.LOG_DATE).getTime()
    })
    let targetDate = new Date(day).toISOString().split('T')[0]
    let hour = ['16', '17', '18', '19', '20', '21', '22', '23', '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15']
    let minute = ['00', '10', '20', '30', '40', '50']
    let inCompleteDataTime = []
    let tArr = targetDate.split('-')
    let tomorrowDate = Number(tArr[2]) + 1
    let getDay = new Date(new Date(targetDate).getFullYear(), new Date(targetDate).getMonth() + 1, 0)
    let monthDays = getDay.getDate()
    let nextMonth = Number(tArr[1]) + 1
    let nextYear = Number(tArr[0]) + 1
    if (timeOrder.length < 144) {
      for (let i = 0; i< hour.length; i++) {
        if (hour[i] === '00') {
          if (tomorrowDate < 10) {
            tArr.splice(2, 1, '0'+tomorrowDate.toString())
          } else {
            tArr.splice(2, 1, tomorrowDate.toString())
          }
          if (tomorrowDate > monthDays) {
            tArr.splice(2, 1, '01')
            if (nextMonth < 10) {
              tArr.splice(1, 1, '0'+nextMonth.toString())
            }
            if (nextMonth > 12) {
              tArr.splice(2, 1, '01')
              tArr.splice(1, 1, '01')
              tArr.splice(0, 1, nextYear.toString())
            }
          }
        }
        for (let k = 0; k < minute.length; k++) {
          inCompleteDataTime.push(tArr.join('-')+`T${hour[i]}:${minute[k]}:00.000Z`)
        }
      }

      for (let j =0; j < inCompleteDataTime.length; j++) {
        let found = timeOrder.find(ele => ele.LOG_DATE === inCompleteDataTime[j])
        if (!found) {
          timeOrder.push({GAME_ID: Number(change.gameType), ONLINE_NUM: 0, LOG_DATE: inCompleteDataTime[j]})
        }
          timeOrder.sort(function order(a, b) {
          return new Date(a.LOG_DATE).getTime() - new Date(b.LOG_DATE).getTime()
        })
      }
    }
    let list = []
    timeOrder.map(ele => list.push(ele.ONLINE_NUM))
    return list
  }

  function getHistoryData(dataType) {
    let day = [[sevenDays, sevenDaysE], [bfYesterday, bfYesterdayE], [yesterday, yesterdayE], [today, todayE]]
    let stateKeyName = ['sevenDaysLine', 'bfYesterdayLine', 'yesterdayLine', 'todayLine']
    let AIstateKeyName = ['AIsevenDaysLine', 'AIbfYesterdayLine', 'AIyesterdayLine', 'AItodayLine']
    let TTstateKeyName = ['TTsevenDaysLine', 'TTbfYesterdayLine', 'TTyesterdayLine', 'TTtodayLine']
    getUsersHistory(dataType, today).then(db => {
      if (db.error === errorCode.INCORRECT_DATA.code) {
        alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.INCORRECT_FORMAT.code) {
        alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.NULL_DATA.code) {
        alert(`※  ${errorCode.NULL_DATA.memo}`)
        return
      }
      if (db.error === errorCode.EXCEPTION.code) {
        alert(`※  ${errorCode.EXCEPTION.memo}`)
        return
      }
      if (db.data.length && Array.isArray(db.data)) {
        let list = []
        for (let i = 0; i < day.length; i++) {
          list.push(lineMaker(db.data, day[i][0], day[i][1], 1))
        }
        for (let i = 0; i < day.length; i++) {
          list.push(lineMaker(db.data, day[i][0], day[i][1], 2))
        }
        for (let i = 0; i < day.length; i++) {
          list.push(lineMaker(db.data, day[i][0], day[i][1], 0))
        }
        setSeries(list)  //順序一般玩家 -> 機器人 -> 加總
        let num = 0
        let timeList = []
        for (let k = 0; k < 24; k++) {
          if (k < 10) {
            num = '0' + k.toString()
          } else {
            num = k.toString()
          }
          timeList.push(num, '.', '.', '.', '.', '.')
        }
        setLabel(timeList)
        if (list.every(ele => !+ele.length)) {
          setNoChartCard(true)
        }
        for (let j = 0; j < day.length; j++) {
          setHNum(prev => ({
            ...prev,
            [stateKeyName[j]]: getHighestNum(db.data, day[j][0], day[j][1], 1).ONLINE_NUM,
            [AIstateKeyName[j]]: getHighestNum(db.data, day[j][0], day[j][1], 2).ONLINE_NUM,
            [TTstateKeyName[j]]: getHighestNum(db.data, day[j][0], day[j][1], 0).ONLINE_NUM
          }))
          setAvrNum(prev => ({
            ...prev,
            [stateKeyName[j]]: getAverageNum(db.data, day[j][0], day[j][1], 1),
            [AIstateKeyName[j]]: getAverageNum(db.data, day[j][0], day[j][1], 2),
            [TTstateKeyName[j]]: getAverageNum(db.data, day[j][0], day[j][1], 0)
          }))
        }
        setNoChartCard(false)
      }
    })
  }

  const [tableSwitch, setTableSwitch] = useState(false)
  const [chartSwitch, setChartSwitch] = useState(false)
  const [noChartCard, setNoChartCard] = useState(false)
  const [chartValue, setChartValue] = useState({
    gameType: '',
    sevenDays: '',
    bfYesterday: '',
    yesterday: '',
    today: ''
  })

  const [sevenDays, setSevenDays] = useState('')
  const [sevenDaysE, setSevenDaysE] = useState('')
  const [bfYesterday, setBfYesterday] = useState('')
  const [bfYesterdayE, setBfYesterdayE] = useState('')
  const [yesterday, setYesterday] = useState('')
  const [yesterdayE, setYesterdayE] = useState('')
  const [today, setToday] = useState('')
  const [todayE, setTodayE] = useState('')

  const [label, setLabel] = useState([])
  const [series, setSeries] = useState([])
  const [hNum, setHNum] = useState({
    sevenDaysLine: '',
    bfYesterdayLine: '',
    yesterdayLine: '',
    todayLine: '',
    AIsevenDaysLine: '',
    AIbfYesterdayLine: '',
    AIyesterdayLine: '',
    AItodayLine: '',
    TTsevenDaysLine: '',
    TTbfYesterdayLine: '',
    TTyesterdayLine: '',
    TTtodayLine: ''
  })
  const [avrNum, setAvrNum] = useState({
    sevenDaysLine: '',
    bfYesterdayLine: '',
    yesterdayLine: '',
    todayLine: '',
    AIsevenDaysLine: '',
    AIbfYesterdayLine: '',
    AIyesterdayLine: '',
    AItodayLine: '',
    TTsevenDaysLine: '',
    TTbfYesterdayLine: '',
    TTyesterdayLine: '',
    TTtodayLine: ''
  })

  const handleOnLineNum = () => {
    let toMax = Object.values(hNum).sort(function order(a, b) {
      return a - b
    })
    for (let i in toMax) {
      if (toMax[i] === '') {
        toMax[i] = 0
      }
    }
    let result = toMax[toMax.length-1]
    return result
  }

  const A_DAY = 24 * 60 * 60 * 1000
  function getStartDay(beforeDays) {
    let time = new Date().getTime()
    let start = new Date(`${new Date(time - A_DAY * beforeDays).toISOString().slice(0, 10)}T16:00:00.000Z`)
    return start
  }
  function getEndDay(beforeDays) {
    let time = new Date().getTime()
    let end = new Date(`${new Date(time - A_DAY * (beforeDays - 1)).toISOString().slice(0, 10)}T15:59:59.000Z`)
    return end
  }
  function setDay() {
    setSevenDays(getStartDay(8))  //前七天
    setSevenDaysE(getEndDay(8))
    setBfYesterday(getStartDay(3))  //前天
    setBfYesterdayE(getEndDay(3))
    setYesterday(getStartDay(2))  //昨天
    setYesterdayE(getEndDay(2))
    setToday(getStartDay(1))  //當天
    setTodayE(getEndDay(1))
  }

  useEffect(() => {
    setDay()
  }, []) 

  const [lastDayLog, setLastDayLog] = useState([])

  const transGameName = new Map([
    [1, '所有遊戲'],
    [7001, '暗棋'],
    [7002, '麻將'],
    [7003, '多米諾'],
    [8001, '暗棋競技場'],
    [8002, '麻將競技場']
  ])

  const handleChart = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      setChartValue({
        gameType: change.gameType,
        sevenDays,
        bfYesterday,
        yesterday,
        today
      })
      if (+change.gameType !== 1 ) {
        getHistoryData(+change.gameType)
      }
      if (+change.gameType === 1) {
        getHistoryData(0)
      }
      setTableSwitch(false)
      setChartSwitch(true)
    })
  }

  function get2Decimal (todayNum, yesterdayNum) {
    if(isNaN(todayNum) || isNaN(yesterdayNum) || yesterdayNum <= 0) {
      return 'N/A';
    }

    const subNum = todayNum - yesterdayNum;
    if(subNum == 0) {
      return '0.00 %';
    }

    const result = (subNum / yesterdayNum * 100).toFixed(2)
    return subNum > 0 ? ('+' + result) : result;
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      getCurrentUsers(+change.gameType).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setLastDayLog(db.data.gameOnlineLogArr)
        setRealPlayer(db.data.lobbyTTNum)
        setAIPlayer(db.data.lobbyAINum)
        setTTPlayer(db.data.lobbyTTNum + db.data.lobbyAINum)

        let getChess = db.data.gameOnlineArr.find(ele => +ele.gameID === 7001)
        setShowChess(getChess ? true : false)
        setChessNum(getChess ? getChess.normalOnlineNum : '')
        setChessAINum(getChess ? getChess.AIOnlineNum : '')
        let chessHistoryLog = db.data.gameOnlineLogArr.find(ele => +ele.gameID === 7001 && +ele.logType === 1)
        setYDayChessNum(chessHistoryLog ? chessHistoryLog.onlineNum : '')

        let getMJ = db.data.gameOnlineArr.find(ele => +ele.gameID === 7002)
        setShowMJ(getMJ ? true : false)
        setMJNum(getMJ ? getMJ.normalOnlineNum : '')
        setMJAINum(getMJ ? getMJ.AIOnlineNum : '')
        let MJHistoryLog = db.data.gameOnlineLogArr.find(ele => +ele.gameID === 7002 && +ele.logType === 1)
        setYDayMJNum(MJHistoryLog ? MJHistoryLog.onlineNum : '')

        let getDomino = db.data.gameOnlineArr.find(ele => +ele.gameID === 7003)
        setShowDomino(getDomino ? true : false)
        setDominoNum(getDomino ? getDomino.normalOnlineNum : '')
        setDominoAINum(getDomino ? getDomino.AIOnlineNum : '')
        let dominoHistoryLog = db.data.gameOnlineLogArr.find(ele => +ele.gameID === 7003 && +ele.logType === 1)
        setYDayDominoNum(dominoHistoryLog ? dominoHistoryLog.onlineNum : '')

        let getMJ2P = db.data.gameOnlineArr.find(ele => +ele.gameID === 7004)
        setShowMJ2P(getMJ2P ? true : false)
        setMJ2PNum(getMJ2P ? getMJ2P.normalOnlineNum : '')
        setMJ2PAINum(getMJ2P ? getMJ2P.AIOnlineNum : '')
        let MJ2PHistoryLog = db.data.gameOnlineLogArr.find(ele => +ele.gameID === 7004 && +ele.logType === 1)
        setYDayMJ2PNum(MJ2PHistoryLog ? MJ2PHistoryLog.onlineNum : '')

        let getChessArena = db.data.gameOnlineArr.find(ele => +ele.gameID === 8001)
        setShowChessArena(getChessArena ? true : false)
        setChessArenaNum(getChessArena ? getChessArena.normalOnlineNum : '')
        setChessArenaAINum(getChessArena ? getChessArena.AIOnlineNum : '')
        let chessArenaHistoryLog = db.data.gameOnlineLogArr.find(ele => +ele.gameID === 8001 && +ele.logType === 1)
        setYDayChessArenaNum(chessArenaHistoryLog ? chessArenaHistoryLog.onlineNum : '')

        let getMJArena = db.data.gameOnlineArr.find(ele => +ele.gameID === 8002)
        setShowMJArena(getMJArena ? true : false)
        setMJArenaNum(getMJArena ? getMJArena.normalOnlineNum : '')
        setMJArenaAINum(getMJArena ? getMJArena.AIOnlineNum : '')
        let MJArenaHistoryLog = db.data.gameOnlineLogArr.find(ele => +ele.gameID === 8002 && +ele.logType === 1)
        setYDayMJArenaNum(MJArenaHistoryLog ? MJArenaHistoryLog.onlineNum : '')

        if (db.data.gameOnlineArr.length > 0) {
          setTableSwitch(true)
        } else {
          setTableSwitch(false)
        }
      })
      setTableSwitch(true)
      setChartSwitch(false)
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({gameType: 1})
    })
  }

  function gameCategory() {
    let gameList = []
    gameCategories.map((ele, index) => {
      gameList.push(<option value = {ele.gameID} key= {index}>{ele.gameName}</option>)
    })
    return gameList
  }

  function getNumber(num) {
    if (isNaN(Number(num))) {
      return 0
    } else {
      return +num
    }
  }

  const handleYesterdayVisitor = gameID => {
    let target = lastDayLog.find(ele => +ele.gameID === +gameID && +ele.logType === 1)
    if (target) {
      return `(${target.onlineNum})`
    } else {
      return ''
    }
  }
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <InputBarRWD>
          <div>
            <span>遊戲</span>
            <Dropdown
              value= {change.gameType}
              onChange= {e => {
                handleChange(e, 'gameType')
              }}
              onClick= {() => {
                setChessNum(0)
                setMJNum(0)
                setTableSwitch(false)
                setChartSwitch(false)
                setShowChess(false)
                setShowMJ(false)
              }}
            >
              <option value="1">全部</option>
              {gameCategory()}
            </Dropdown>
          </div>
        </InputBarRWD>

        <BtnGroup>
          <SearchButton onClick= {handleSearch}>搜索</SearchButton>
          <ResetButton onClick= {handleReset}>重置</ResetButton>
          <LineChartBtn onClick= {handleChart}>曲線圖</LineChartBtn>
        </BtnGroup>
      </InputBarBg>

      <BasicTable  className="table table-hover table-bordered" style= {tableSwitch ? {display: 'table'} : {display: 'none'}}>
        <thead>
          <BasicRowTitle>
            <TitleBg>總在線人數: {TTPlayer}</TitleBg>
            <TitleBg>實際在線人數: {realPlayer}</TitleBg>
            <TitleBg>AI在線人數: {AIPlayer}</TitleBg>
          </BasicRowTitle>
        </thead>
        <tbody>
          <TableContent style= {showChess ? {display: 'table-row'} : {display: 'none'}}>
            <td>暗棋 : {chessNum + chessAINum}</td>
            <td>暗棋實際人數 : {chessNum}{handleYesterdayVisitor(7001)} {get2Decimal(chessNum, yDayChessNum)}</td>
            <td>暗棋 : {chessAINum}</td>
          </TableContent>
          <TableContent style= {showMJ ? {display: 'table-row'} : {display: 'none'}}>
            <td>麻將 : {MJNum + MJAINum}</td>
            <td>麻將實際人數 : {MJNum}{handleYesterdayVisitor(7002)} {get2Decimal(MJNum, yDayMJNum)} </td>
            <td>麻將 : {MJAINum}</td>
          </TableContent>
          <TableContent style= {showDomino ? {display: 'table-row'} : {display: 'none'}}>
            <td>多米諾 : {dominoNum + dominoAINum}</td>
            <td>多米諾實際人數 : {dominoNum}{handleYesterdayVisitor(7003)} {get2Decimal(dominoNum, yDayDominoNum)}</td>
            <td>多米諾 : {dominoAINum}</td>
          </TableContent>
          <TableContent style= {showMJ2P ? {display: 'table-row'} : {display: 'none'}}>
            <td>二人麻將 : {MJ2PNum + MJ2PAINum}</td>
            <td>二人麻將實際人數 : {MJ2PNum}{handleYesterdayVisitor(7002)} {get2Decimal(MJ2PNum, yDayMJ2PNum)} </td>
            <td>二人麻將 : {MJ2PAINum}</td>
          </TableContent>
          <TableContent style= {showChessArena ? {display: 'table-row'} : {display: 'none'}}>
            <td>暗棋競技場 : {chessArenaNum + chessArenaAINum}</td>
            <td>暗棋競技場實際人數 : {chessArenaNum}{handleYesterdayVisitor(8001)} {get2Decimal(chessArenaNum, yDayChessArenaNum)}</td>
            <td>暗棋競技場 : {chessArenaAINum}</td>
          </TableContent>
          <TableContent style= {showMJArena ? {display: 'table-row'} : {display: 'none'}}>
            <td>麻將競技場 : {MJArenaNum + MJArenaAINum}</td>
            <td>麻將競技場實際人數 : {MJArenaNum}{handleYesterdayVisitor(8002)} {get2Decimal(MJArenaNum, yDayMJArenaNum)}</td>
            <td>麻將競技場 : {MJArenaAINum}</td>
          </TableContent>
        </tbody>
      </BasicTable>

      <ChartForm fluid= "XL" style= {chartSwitch ? {display: 'block'} : {display: 'none'}}>
        <Row>
          <Col>
            <Card style={{ height: '45rem' }}>
              <Card.Header>
                <Card.Title as="h4">{transGameName.get(+change.gameType)}</Card.Title>
                <p className="card-category">全部房間</p>
              </Card.Header>
              {
                noChartCard ? <CardNoData>尚無資料</CardNoData>
                : <Card.Body style={noChartCard ? {display: 'none'} : {display: 'block'}}>
                    <div className="ct-chart" id="chartHours">
                    <OnlineNum className="card-category">在線人數</OnlineNum>
                      <ChartistGraph
                        data={{
                          labels: label,
                          series: series
                        }}
                        type="Line"
                        options={{
                          axisX: {
                            showGrid: true,
                          },
                          axisY : {
                            high: handleOnLineNum(),
                            // high: 100,
                            low: 0,
                            onlyInteger: true
                          },
                          lineSmooth: true,
                          showLine: true,
                          showPoint: true,
                          fullWidth: true,
                          chartPadding: {
                            right: 50,
                            left: 50,
                            top: 20,
                            bottom: 80
                          },
                          showArea: false,
                          height: "450px",
                          stretch: true,
                          distributeSeries:true
                        }}
                        responsiveOptions={[
                          [
                            "screen and (max-width: 640px)",
                            {
                              axisX: {
                                labelInterpolationFnc: function (value) {
                                  return value[0]
                                },
                              },
                            },
                          ],
                        ]}
                      />
                    </div>
                  </Card.Body>
              }
              <TimeBar className="card-category" style={noChartCard ? {display: 'none'} : {display: 'block'}}>時間</TimeBar>
              <hr></hr>
              <Card.Footer>
                <ChartDetail className="legend">
                  <div>
                    {/* 曲線圖順序與series相同 */}
                    <div>
                      <i className="fas fa-circle text-info"></i>{chartValue.sevenDays.toLocaleString().split(' ')[0]} 最高在線: {getNumber(hNum.sevenDaysLine)} 平均在線: {getNumber(avrNum.sevenDaysLine)}
                      <i className="fas fa-circle text-danger"></i>{chartValue.bfYesterday.toLocaleString().split(' ')[0]} 最高在線: {getNumber(hNum.bfYesterdayLine)} 平均在線: {getNumber(avrNum.bfYesterdayLine)}
                      <i className="fas fa-circle text-warning"></i>{chartValue.yesterday.toLocaleString().split(' ')[0]} 最高在線: {getNumber(hNum.yesterdayLine)} 平均在線: {getNumber(avrNum.yesterdayLine)}
                      <Line4 className="fas fa-circle"/>{chartValue.today.toLocaleString().split(' ')[0]} 最高在線: {getNumber(hNum.todayLine)} 平均在線: {getNumber(avrNum.todayLine)}
                    </div>
                    <div>
                      <Line5 className="fas fa-circle"/>{chartValue.sevenDays.toLocaleString().split(' ')[0]} AI最高在線: {getNumber(hNum.AIsevenDaysLine)} AI均在線: {getNumber(avrNum.AIsevenDaysLine)}
                      <Line6 className="fas fa-circle"/>{chartValue.bfYesterday.toLocaleString().split(' ')[0]} AI最高在線: {getNumber(hNum.AIbfYesterdayLine)} AI平均在線: {getNumber(avrNum.AIbfYesterdayLine)}
                      <Line7 className="fas fa-circle"/>{chartValue.yesterday.toLocaleString().split(' ')[0]} AI最高在線: {getNumber(hNum.AIyesterdayLine)} AI平均在線: {getNumber(avrNum.AIyesterdayLine)}
                      <Line8 className="fas fa-circle"/>{chartValue.today.toLocaleString().split(' ')[0]} AI最高在線: {getNumber(hNum.AItodayLine)} AI平均在線: {getNumber(avrNum.AItodayLine)}
                    </div>
                    <div>
                      <Line9 className="fas fa-circle"/>{chartValue.sevenDays.toLocaleString().split(' ')[0]} 總最高在線: {getNumber(hNum.TTsevenDaysLine)} 總平均在線: {getNumber(avrNum.TTsevenDaysLine)}
                      <Line10 className="fas fa-circle"/>{chartValue.bfYesterday.toLocaleString().split(' ')[0]} 總最高在線: {getNumber(hNum.TTbfYesterdayLine)} 總平均在線: {getNumber(avrNum.TTbfYesterdayLine)}
                      <Line11 className="fas fa-circle"/>{chartValue.yesterday.toLocaleString().split(' ')[0]} 總最高在線: {getNumber(hNum.TTyesterdayLine)} 總平均在線: {getNumber(avrNum.TTyesterdayLine)}
                      <Line12 className="fas fa-circle"/>{chartValue.today.toLocaleString().split(' ')[0]} 總最高在線: {getNumber(hNum.TTtodayLine)} 總平均在線: {getNumber(avrNum.TTtodayLine)}
                    </div>
                  </div>
                </ChartDetail>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </ChartForm>
    </>
  )
}