import styled from 'styled-components'
import Box from '@mui/material/Box'
import { BarBG, BasicInput, BtnPosition, BasicRowTitle, NewBtn } from '../../../middleware/utilityStyle'

export const InputBarBg = styled(BarBG)`
  @media screen and (max-width: 700px){
    & {
      flex-direction: column;
    }
  }
`

export const InputBarRWD = styled.div`
  display: flex;
` 

export const InputBar = styled.div`
  margin-left: 20px;
`

export const Input = styled(BasicInput)`
  width: 50px;
  margin-left: 10px;
  margin-right: 8px;
`

export const GameWrapper = styled.div`
  margin-left: 2rem;
`

export const Dropdown = styled.select`
  margin-left: 20px;
  border: 1px solid black;
  border-radius: 5px;
  height: 28px;
  width: 150px;
`

export const BtnGroup = styled.div`
  @media screen and (max-width: 700px) {
    margin-top: 10px;
  }
`

export const PUInputBar = styled.div`
  display: flex;
  justify-content: space-evenly;
  : nth-child(2) {
    margin-top: 10px;
  }
`

export const BTN = styled(BtnPosition)`
  width: 1600px;
  justify-content: start;
`

export const Title = styled.td`
  text-align: center;
  width: 150px;
`

export const PUInput = styled(BasicInput)`
  width: 200px;
  margin-left: 0.1rem;
  height: 28px;
`

export const PUSelect = styled.select`
  box-sizing: border-box;
  border: 1px solid black;
  border-radius: 5px;
  margin: 0 20px 0 0.1rem;
  height: 28px;
  width: 200px;
`

export const TopTitle = styled.h2`
  text-align: center;
  width: 50rem;
  font-size: 2.5rem;
  margin-bottom: 5px;

  :hover {
    font-weight: bold;
}
`
export const SmallSelect = styled.select`
  box-sizing: border-box;
  border: 1px solid black;
  border-radius: 5px;
  margin: 0 5px 0 5px;
  height: 28px;
  width: 75px;
`

export const SimpleInput = styled(BasicInput)`
  width: 150px;
  margin-left: 1rem;
  height: 28px;
`

export const InputBarButton = styled.div`
  display: flex;
  margin-top: 20px;
`

export const LongInput = styled(BasicInput)`
  width: 200px;
  margin-left: 20px;
`

export const Table = styled(BasicRowTitle)`
  font-size: 15px;
  font-weight: 600 !important;
  background: #7FFFD4;
`

export const Red = styled.td`
  color: #FF0000;
`

export const Green = styled.td`
  color: #00cc00;
`

export const Blue = styled.td`
  color: blue;
  text-decoration: underline;
  :hover {
    cursor: pointer
}
`

export const InsertBtn = styled(NewBtn)`
color: black;
font-weight: 600 !important;
border: 1px solid blue;
height: 2.5rem;
width: 10rem;
margin-right: 2.5rem;
:hover {
  color: white;
  font-weight: bold;
  background: #00BFFF;
}
`

export const CodeBtn = styled(NewBtn)`
color: black;
border: 1px solid blue;
height: 2.5rem;
width: 10rem;
margin-left: 1rem;
:hover {
  color: white;
  font-weight: bold;
  background: #FF8C00
}
`

export const RemoveBtn = styled(NewBtn)`
color: black;
font-weight: 600 !important;
border: 1px solid blue;
height: 2.5rem;
width: 10rem;
margin-right: 2.5rem;
:hover {
  color: white;
  font-weight: bold;
  background: red;
}
`

export const TimeFormat = styled(Box)`
  margin-left: 20px;
  @media screen and (max-width: 810px) {
    margin-left: 0px;
    margin-top: 10px;
  }
`
export const Textarea = styled.textarea`
  ::-webkit-input-placeholder {
    line-height: 72px
  }
`