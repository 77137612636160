import styled from 'styled-components'
import { BarBG, BasicInput } from '../../middleware/utilityStyle'

export const InputBarBg = styled(BarBG)`
  @media screen and (max-width: 1470px){
    & {
      flex-direction: column;
    }
    & div {
      margin-top: 10px;
    }
    & div + div {
      margin-top: 10px;
    }
  }
`

export const InputBarRWD = styled.div`
  display: flex;
  @media screen and (max-width: 1350px){
    & {
      flex-direction: column;
      justify-content: center;
    }
  }
` 

export const FirstInputBar = styled.div`
  @media screen and (max-width: 1350px) {
    & {
      display: flex;
      justify-content: space-between;
    }
  }
`

export const InputBar = styled.div`
  margin-left: 20px;
  @media screen and (max-width: 1350px) {
    & {
      display: flex;
      justify-content: space-between;
      margin-left: 0px;
    }
  }
`

export const Input = styled(BasicInput)`
  width: 250px;
  margin-left: 10px;
`

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const BtnG1 = styled.div`
  display: flex;
`

export const Btn = styled.div`
  color: orange;
  text-align: center;
  cursor: pointer;
  border: 1px solid orange;
  border-radius: 30px;
  background: #e6e6e6;
  width: 100px;
  margin-top: 15px;
  transition: 0.7s all;

  :hover {
    color: white;
    font-weight: bold;
    background: orange;
  }

  & + & {
    margin-left : 20px
  }
`