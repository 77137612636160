import { getAuthToken } from '../utils.js';
import { BaseUrl } from "../../assets/config/urlForBuild"

//? 新增角色 PID9 ?//
export const addCharacter = (characterName, description) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/character`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      characterName,
      description
    })
  })
    .then(res => res.json())
    .catch(err => {
      console.log(err)
    })
};

//? 修改角色 PID10 ?//
export const updateCharacter = (characterID, characterName, description) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/character`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      characterID,
      characterName,
      description
    })
  })
    .then(res => res.json())
    .catch(err => {
      console.log(err)
    })
};

//? 刪除角色 PID11 ?//
export const deleteCharacter = (characterID) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/character/delete`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      characterID
    })
  })
    .then(res => res.json())
    .catch(err => {
      console.log(err)
    })
};

//? 取得角色權限 PID12 ?//
export const getCharacterAuth = (id) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/character/access?id=${id}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  })
    .then(res => res.json())
    .catch(err => {
      console.log(err)
    })
};

//? 修改角色權限 PI13 ?//
export const reviseAuth = (characterID, accessIDArr) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/character/access`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      characterID,
      accessIDArr
    })
  })
    .then(res => res.json())
    .catch(err => {
      console.log(err)
    })
};