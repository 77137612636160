import Spinner from "react-bootstrap/Spinner"
import styled from "styled-components"

const Loading = styled(Spinner)`
  position: absolute;
  top:50%;
  left: 50%;
`

export default function LoadingIndicator() {
  return (
    <Loading animation="border" variant="warning" />
  )
}