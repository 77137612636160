import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { getCoupon } from "../../../middleware/Api/operation/couponApi.js"
import { getAccessArr } from "../../../middleware/utils"
import Delete from "../../../components/popUpWindow/coupon/delete.js"
import Insert from "../../../components/popUpWindow/coupon/insert.js"
import Stack from '@mui/material/Stack'
import {
  InputBarBg,
  InputBarRWD,
  GameWrapper,
  BtnGroup,
  InputBarButton,
  LongInput,
  PUSelect,
  Table,
  Red,
  Green,
  Blue,
  BTN,
  InsertBtn,
  RemoveBtn
} from "./styledComponent.js"
import {
  SearchButton,
  ResetButton,
  BasicTable,
  TableContent,
  TableBtnGroup,
  TablePaginationStyle
} from "../../../middleware/utilityStyle.js"

export default function coupon() {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const history = useHistory()
  const [couponInfo, setCouponInfo] = useState([])
  const [box, setBox] = useState([])
  const [edit, setEdit] = useState(false)
  const [myCoupon, setMyCoupon] = useState([])
  const [nextCouponID, setNextCouponID] = useState("")
  const [pageNum, setPageNum] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [dataForPage, setDataForPage] = useState([])
  const [searchInfo, setSearchInfo] = useState({
    codeID: "",
    code: "",
    codeName: "",
    type: "all",
    state: "all"
  })
  const handleSearchInfo = (e, key) => {
    setSearchInfo(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      getCoupon().then(db => {
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if (Array.isArray(db.data) && db.data.length) {
          let data = mergeSort(db.data, "ID")
          setNextCouponID(data[0].ID + 1)
          data = data.filter(obj => obj.STATE !== 2)
          setCouponInfo(data)
          let result = data.slice(pageNum, rowsPerPage)
          setDataForPage(result)
        }
      })
    })
  }, [])

  function mergeSort(arr, keyName) {
    if (arr.length === 1 || arr.length === 0) {
      return arr;
    }
    let mid = Math.floor(arr.length / 2);
    let left = arr.slice(0, mid);
    let right = arr.slice(mid, arr.length);
    return merge(mergeSort(left, keyName), mergeSort(right, keyName), keyName);
  }
  
  function merge(left, right, keyName) {
    let result = [];
    let i = 0;
    let j = 0;
    while (i < left.length && j < right.length) {
      if (left[i][keyName] >= right[j][keyName]) {
        result.push(left[i]);
        i++;
      } else {
        result.push(right[j]);
        j++;
      }
    }
    while (i < left.length) {
      result.push(left[i]);
      i++;
    }
    while (j < right.length) {
      result.push(right[j]);
      j++;
    }
    return result;
  }

  const handleSearch =  (() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      getCoupon().then(db => {
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if (Array.isArray(db.data) && db.data.length) {
          let data = mergeSort(db.data, "ID")
          data = data.filter(obj => obj.STATE !== 2)
          if (searchInfo.codeID !== "") data = data.filter(obj => obj.ID === +searchInfo.codeID)
          if (searchInfo.code !== "") data = data.filter(obj => obj.COUPON_ID === searchInfo.code)
          if (searchInfo.codeName !== "") data = data.filter(obj => obj.COUPON_NAME === searchInfo.codeName)
          if (searchInfo.type !== "all") data = data.filter(obj => obj.ITEM_TYPE === +searchInfo.type)
          if (searchInfo.state !== "all") data = data.filter(obj => obj.STATE === +searchInfo.state)
          setPageNum(0)
          setCouponInfo(data)
          let result = data.slice(0, rowsPerPage)
          setDataForPage(result)
        }
      })
    })
  })

  const handleReset = (() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setSearchInfo({codeID: "",code: "",codeName: "",type: "all",state: "all"})
    })
  })

  const handleKeyDown = (e) => {
    if (e.key === "Enter") handleSearch()
  }

  const handleBox = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      let result = box.findIndex(ele => ele === e.target.value)
      if (result !== -1) box.splice(result, 1)
      else box.push(e.target.value)
    })
  }

  const [showDelete, setShowDelete] = useState(false)
  const handleDeleteClose = () => setShowDelete(false)
  const handleDeleteShow = () => setShowDelete(true)

  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 130)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (box.length === 0) {
        alert('※  請選擇欲刪除的項目')
        return
      }
      handleDeleteShow()
    })
  }

  const [showInsert, setShowInsert] = useState(false)
  const handleInsertClose = () => setShowInsert(false)
  const handleInsertShow = () => setShowInsert(true)

  const handleInsert = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 129)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      handleInsertShow()
    })
  }

  const handleEdit = ele => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 131)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      setMyCoupon(ele)
      setEdit(true)
      handleInsertShow()
    })
  }

  const handleChangePage = (event, newPage) => {
    setPageNum(newPage)
    let toRow = (newPage + 1) * rowsPerPage
    let result = couponInfo.slice(toRow - rowsPerPage ,toRow)
    setDataForPage(result)
  }
  const handleChangeRowsPerPage  = (e) => {
    setRowsPerPage(e.target.value)
    setPageNum(0)
    setDataForPage(couponInfo.slice(0, e.target.value))
  }

  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <div>
        <InputBarRWD>
          <div style={{marginLeft: "48px"}}>
            序號ID查詢<LongInput placeholder="請輸入ID" value={searchInfo.codeID} style={{marginLeft: "23px"}} onChange={e => handleSearchInfo(e, "codeID")} onKeyDown={handleKeyDown}/>
          </div>
          <div style={{marginLeft: "45px"}}>
            序號查詢<LongInput placeholder="請輸入序號" value={searchInfo.code} onChange={e => handleSearchInfo(e, "code")} onKeyDown={handleKeyDown}/>
          </div>
          <div style={{marginLeft: "45px"}}>
            名稱查詢<LongInput placeholder="請輸入名稱" value={searchInfo.codeName} onChange={e => handleSearchInfo(e, "codeName")} onKeyDown={handleKeyDown}/>
          </div>
        </InputBarRWD>

        <InputBarButton>
          <GameWrapper>
            <span style={{marginRight: "20px"}}>兌換內容分類</span>
            <PUSelect id= "item1" value={searchInfo.type} onChange={e => handleSearchInfo(e, "type")}>
              <option value="all">全部</option>
              <option value="1">金幣</option>
              <option value="2">鬥陣寶</option>
              <option value="3">道具</option>
            </PUSelect>
            <span style={{marginRight: "20px", marginLeft: "25px"}}>開放狀態</span>
            <PUSelect id= "item1" value={searchInfo.state} onChange={e => handleSearchInfo(e, "state")}>
              <option value="all">全部</option>
              <option value="1">開放</option>
              <option value="0">關閉</option>
            </PUSelect>
          </GameWrapper>
        </InputBarButton>
        </div>

        <BtnGroup>
          <SearchButton onClick={handleSearch}>搜索</SearchButton>
          <ResetButton onClick={handleReset}>重置</ResetButton>
        </BtnGroup>
      </InputBarBg>

      <TableBtnGroup>
        <BasicTable className="table table-hover table-bordered">
          <thead>
            <Table>
              <th>批次</th>
              <th>序號ID</th>
              <th>序號</th>
              <th>序號名稱</th>
              <th>兌換起始日期</th>
              <th>兌換結束日期</th>
              <th>兌換內容</th>
              <th>兌換數量</th>
              <th>兌換上限</th>
              <th>已兌換數量</th>
              <th>開放狀態</th>
              <th>查看修改</th>
            </Table>
          </thead>
          <tbody>
            {
              Array.isArray(dataForPage) && dataForPage.length > 0 ?
              dataForPage.map((ele, index) => {
                return (
                  <TableContent key={index}>
                    <td>
                      <input type= "checkBox" value={ele.COUPON_ID} onChange={ handleBox }/>
                    </td>
                    <td>{ele.ID}</td>
                    <td>{ele.COUPON_ID}</td>
                    <td>{ele.COUPON_NAME}</td>
                    <td>{new Date(Date.parse(ele.BEGIN_DATE) + 28800000).toISOString().split("T")[0]}</td>
                    <td>{new Date(Date.parse(ele.EXPIRE_DATE) + 28800000).toISOString().split("T")[0]}</td>
                    <td>{ele.ITEM_TYPE === 1 ? "金幣" : ele.ITEM_TYPE === 2 ? "鬥陣寶" : "道具"}</td>
                    <td>{ele.EXCHANGE_NUM}</td>
                    <td>{ele.LIMIT_NUM === -1 ? "0 (無限制)" : ele.LIMIT_NUM}</td>
                    <td>{ele.NOW_NUM}</td>
                    { ele.STATE === 0 ? <Red>關閉</Red> : <Green>開放</Green> }
                    <Blue onClick={() => handleEdit(ele)}>修改</Blue>
                  </TableContent>
                )
              })
              : <tr key= "noInfo"><td colSpan= "12">尚無資料</td></tr>
            }
          </tbody>
        </BasicTable>
        <BTN>
          <InsertBtn onClick={ handleInsert }>新增</InsertBtn>
          <RemoveBtn onClick={ handleDelete }>刪除</RemoveBtn>
        </BTN>
      </TableBtnGroup>

      <Stack spacing={2}>
      <TablePaginationStyle
        component="div"
        count={couponInfo.length}
        page={pageNum}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions ={[15, 25, 50, 100]}
        showFirstButton = {true}
        showLastButton = {true}
      />
      </Stack>

      <Delete
        showDelete = {showDelete}
        handleDeleteClose = {handleDeleteClose}
        box = {box}
      />
      <Insert
        showInsert = {showInsert}
        handleInsertClose = {handleInsertClose}
        edit = {edit}
        setEdit = {setEdit}
        myCoupon = {myCoupon}
        setMyCoupon = {setMyCoupon}
        setCouponInfo = {setCouponInfo}
        nextCouponID = {nextCouponID}
        setNextCouponID = {setNextCouponID}
        pageNum = {pageNum}
        rowsPerPage = {rowsPerPage}
        setDataForPage = {setDataForPage}
      />
    </>
  )
}