import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import AddRole from "../../components/popUpWindow/role/addRole"
import ReviseRole from "../../components/popUpWindow/role/reviseRole"
import DeleteRole from "../../components/popUpWindow/role/deleteRole"
import HandleAuth from "../../components/popUpWindow/role/handleAuth"
import { getAllCharacter } from "../../middleware/Api/publicApi"
import { getCharacterAuth } from "../../middleware/Api/roleApi"
import { sessionVerified } from "../../middleware/Api/publicApi"
import errorCode from "../../assets/config/errorCode.json"
import { getAccessArr } from "../../middleware/utils"
import {
  InputBarBg,
  Input,
  BtnWrapper,
  BtnG1,
  BtnG2,
  Btn
} from './styledComponent.js'
import {
  SearchButton,
  ResetButton,
  BasicTable,
  BasicRowTitle
} from "../../middleware/utilityStyle.js"

export default function RoleSetting() {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const [panelInfo, setPanelInfo] = useState([])  //roleData State
  const history = useHistory()
  const [allRole, setAllRole] = useState([])
  const [change, setChange] = useState({})
  const [popData, setPopData] = useState({characterID: 0, characterName: '超級管理員', description: 'super'})
  const [radioValue, setRadioValue] = useState(0)
  const [checkboxValue, setCheckboxValue] = useState([])  //ID Array

  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      getAllCharacter().then(db => {
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        setPanelInfo(db.data)
        setAllRole(db.data)
      })
    })
  }, [])

  const handleChange = e => {
    let newObj = Object.assign({}, change)
    newObj[e.target.name] = e.target.value
    setChange(newObj)
  }

  const [showAdd, setShowAdd] = useState(false)
  const handleAddClose = () => {
    setShowAdd(false)
    setChange({ roleName: '', roleDes: '' })
  }
  const handleAddShow = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 9)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      setShowAdd(true)
    })
  }
  
  const [showRevise, setShowRevise] = useState(false)
  const handleReviseClose = () => {
    setShowRevise(false)
    setChange({ reviseName: '', reviseDes: '' })
  }
  const handleReviseShow = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 10)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      setShowRevise(true)
    })
  }

  const [showDelete, setShowDelete] = useState(false)
  const handleDeleteClose = () => setShowDelete(false)
  const handleDeleteShow = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 11)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (!+radioValue) {
        alert('※  此角色不可刪除')
        return
      }
      setShowDelete(true)
    })
  }

  const [showAuthSetting, setAuthSetting] = useState(false)
  const handleAuthSettingClose = () => {
    setCheckboxValue([])
    setAuthSetting(false)
  }
  const handleAuthSettingShow = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 12)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      setAuthSetting(true)
      getCharacterAuth(+radioValue).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.RECORD_NOT_FOUND.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        db.data.filter(ele => +ele.isCheck).map(ele => checkboxValue.push(+ele.ID))
        let newArr = checkboxValue.concat()
        setCheckboxValue(newArr)
      })
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({ navRoleName: '' })
    })
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!change.navRoleName) {
        setPanelInfo(allRole)
      } else {
        const target = allRole.find(ele => ele.characterName === change.navRoleName)
        if (target) {
          setPanelInfo([target])
        } else {
          alert(`※  ${errorCode.RECORD_NOT_FOUND.memo}`)
          setPanelInfo(allRole)
        }
      }
    })
    
  }

  const handleRadioValue = e => {
    let chosenUserInfo = panelInfo.find(roleInfo => roleInfo.characterID === +e.target.value)
    setPopData(chosenUserInfo)
    setRadioValue(+e.target.value)
  }
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <div>
          <div>
            角色名稱<Input placeholder="請輸入想查詢的角色名稱" name="navRoleName" value={change.navRoleName} onChange={handleChange} />
          </div >
        </div>
        <div>
          <SearchButton onClick={handleSearch}>搜索</SearchButton>
          <ResetButton onClick={handleReset}>重置</ResetButton>
        </div>
      </InputBarBg>
      <BasicTable className="table table-hover table-bordered">
        <thead>
          <BasicRowTitle>
            <th scope="col">
              <input type="radio" id="flexCheckChecked" disabled />
            </th>
            <th scope="col">角色名稱</th>
            <th scope="col">角色描述</th>
          </BasicRowTitle>
        </thead>
        <tbody>
          {
            Array.isArray(panelInfo) && panelInfo.length ?
              panelInfo.map((data, index) => {
                return (
                  <tr key= {index}>
                    <th scope="row">
                      <input type="radio" name="roleList" value={data.characterID} onChange={handleRadioValue} checked={radioValue === data.characterID ? 'checked' : ''} />
                    </th>
                    <td>{data.characterName}</td>
                    <td>{data.description}</td>
                  </tr>
                )
              })
            : <tr key= "noInfo"><td colSpan= "3">查無資料</td></tr>
          }
        </tbody>
        <tfoot></tfoot>
      </BasicTable>
      <BtnWrapper className="btn-group">
        <BtnG1>
          <Btn className="butt-btn" onClick={handleAddShow}>新增</Btn>
          <Btn className="butt-btn" onClick={handleReviseShow}>修改</Btn>
        </BtnG1>
        <BtnG2>
          <Btn className="butt-btn" onClick={handleDeleteShow}>刪除</Btn>
          <Btn className="butt-btn" onClick={handleAuthSettingShow}>權限控制</Btn>
        </BtnG2>
      </BtnWrapper>

      {/* 新增 */}
      <AddRole
        change= {change}
        setChange= {setChange}
        handleChange= {handleChange}
        showAdd= {showAdd}
        handleAddClose= {handleAddClose}
        setPanelInfo= {setPanelInfo}
        history= {history}
        setAllRole={setAllRole}
      />

      {/* 修改 */}
      <ReviseRole
        change= {change}
        setChange= {setChange}
        handleChange= {handleChange}
        showRevise= {showRevise}
        setShowRevise= {setShowRevise}
        handleReviseClose= {handleReviseClose}
        setPanelInfo= {setPanelInfo}
        popData= {popData}
        setPopData= {setPopData}
        history= {history}
        setAllRole={setAllRole}
      />

      {/* 刪除 */}
      <DeleteRole
        popData= {popData}
        handleDeleteClose= {handleDeleteClose}
        setPanelInfo= {setPanelInfo}
        showDelete= {showDelete}
        setRadioValue= {setRadioValue}
        history= {history}
        setAllRole={setAllRole}
      />

      {/* 權限控制 */}
      <HandleAuth
        checkboxValue= {checkboxValue}
        setCheckboxValue= {setCheckboxValue}
        showAuthSetting= {showAuthSetting}
        handleAuthSettingClose= {handleAuthSettingClose}
        popData= {popData}
        history= {history}
        radioValue= {radioValue}
      />
    </>
  )
}