import React, { useState, useEffect }from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import { getAccessArr } from "../../../middleware/utils"
import errorCode from "../../../assets/config/errorCode.json"
import {
  Input,
  RadioGroup, 
  Dropdown
} from "../../../views/operation/shopClassConfig/styledComponent"
import {ModalBtn } from "../../../middleware/utilityStyle"
import { addShopClassConfig, reviseShopClassConfig, getShopClassConfig, uploadItem } from "../../../middleware/Api/operation/shopClassConfigApi.js"
import DeleteHint from "./DeletePU"

export default function AddPU(props) {
  const history = useHistory()
  const {
    showCategory,
    handleCategoryClose,
    targetObj,
    addType,
    form,
    setForm,
    deleteSwitch,
    dataCopy,
    setDataCopy,
    setCategoryInfo
  } = props

  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })

  const [chosenTarget, setChosenTarget] = useState({
    CLASS_ID: 0,
    ORDER_ID: '',
    CLASS_TYPE: 1,
    CLASS_NAME: '',
    STATUS: 0,
    ITEM_NUM: 0
  })

  function item() {
    getShopClassConfig(-1).then(db => {
      if (db.error === errorCode.NULL_DATA.code) {
        alert(`※  ${errorCode.NULL_DATA.memo}`)
        return
      }
      if (db.error === errorCode.EXCEPTION.code) {
        alert(`※  ${errorCode.EXCEPTION.memo}`)
        return
      }
      setDataCopy(db.data)
      setCategoryInfo(db.data)
    })
  }

  useEffect(() => {
    setChosenTarget(JSON.parse(JSON.stringify(targetObj)))
    setForm({
      CLASS_ID: 0,
      ORDER_ID: '',
      CLASS_TYPE: 1,
      CLASS_NAME: '',
      STATUS: 0,
      ITEM_NUM: 0
    })
  }, [targetObj])

  const handleTarget = (e, key) => {
    if (addType) {
      setForm((prev) => ({
        ...prev,
        [key]: e
    }))
    } else {
      setChosenTarget((prev) => ({
        ...prev,
        [key]: e
      }))
    }
  }
  
  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 118)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      handleCategoryClose()
      handleDeleteHintShow()
    })
  }

  const handleSave = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      if (addType) {
        if (!userPID.find(ele => +ele === 116)) {
          alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
          return
        }
        if (!form.ORDER_ID || !form.CLASS_NAME) {
          alert('請輸入空白處⊙ w ⊙')
          return
        }
        if (isNaN(form.ORDER_ID)) {
          alert('※  排序欄位請填入數字')
          setForm(prev => ({
            ...prev,
            ORDER_ID: ''
          }))
          return
        }
        if (dataCopy.find(ele => +ele.ORDER_ID === +form.ORDER_ID)) {
          alert('※  排序不可重複')
          setForm(prev => ({
            ...prev,
            ORDER_ID: ''
          }))
          return
        }
        if (form.CLASS_NAME.length > 6) {
          alert('※  名稱欄位字數不符')
          return
        }
        if (form.CLASS_NAME.indexOf(' ') >= 0) {
          alert('※  名稱欄位不可包含空白格')
          setForm(prev => ({
            ...prev,
            CLASS_NAME: ''
          }))
          return
        }
        
        let banSymbols = "!@#$%^&*(){[:;}]'?/>.<,+"
        let banSymbolsArr = banSymbols.split('')
        let result = false
        for (let k = 0; k < form.CLASS_NAME.length; k++) {
          for (let i = 0; i < banSymbolsArr.length; i++) {
            if (form.CLASS_NAME[k].indexOf(banSymbolsArr[i]) >= 0) {
              result = false
              break
            } else {
              result = true
            }
          }
          if (result) {
            continue
          } else {
            break
          }
        }
        if (!result) {
          alert('名稱不可包含特殊字元  ʕﾉಠᴥಠʔﾉ')
          return
        }

        addShopClassConfig(form.CLASS_TYPE, form.CLASS_NAME, +form.STATUS, +form.ORDER_ID).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          item()
        })
      } else {
        if (!userPID.find(ele => +ele === 117)) {
          alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
          return
        }
        if (!chosenTarget.ORDER_ID || !chosenTarget.CLASS_NAME) {
          alert('請輸入空白處⊙ w ⊙')
          return
        }
        if (isNaN(chosenTarget.ORDER_ID)) {
          alert('※  排序欄位請填入數字')
          return
        }
        if (+targetObj.ORDER_ID !== +chosenTarget.ORDER_ID) {
          if (dataCopy.find(ele => +ele.ORDER_ID === +chosenTarget.ORDER_ID)) {
            alert('※  排序不可重複')
            setChosenTarget(prev => ({
              ...prev,
              ORDER_ID: +targetObj.ORDER_ID
            }))
            return
          }
        }
        if (chosenTarget.CLASS_NAME.length > 6) {
          alert('※  名稱欄位字數不符')
          return
        }
        reviseShopClassConfig(+chosenTarget.CLASS_ID, +chosenTarget.CLASS_TYPE, chosenTarget.CLASS_NAME, +chosenTarget.STATUS, +chosenTarget.ORDER_ID).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          item()
        })
      }
      handleCategoryClose()
    })
  }

  const [showDeleteHint, setDeleteHintShow] = useState(false)
  const handleDeleteHintClose = () => setDeleteHintShow(false)
  const handleDeleteHintShow = () => setDeleteHintShow(true)
  return (
    <>
      <Modal
        show={showCategory}
        onHide={handleCategoryClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>商品分類設定</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Container>
          <Row className="justify-content-md-center">
            <Col md={4}>分類ID</Col>
            <Col md={4} className="text-center">
              <Input
                placeholder= "自動產生"
                disabled = {addType ? true : false}
                value= {addType ? '' : chosenTarget.CLASS_ID}
                onChange= {e => {handleTarget(e.target.value, 'CLASS_ID')}}
              />
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={4}>類型</Col>
            <Col md={4} className="text-center">
              
              <Dropdown id= "item1" value={addType ? form.CLASS_TYPE : chosenTarget.CLASS_TYPE} onChange= {e => {handleTarget(e.target.value, 'CLASS_TYPE')}}>
                <option value="1">遊戲造型</option>
                <option value="2">其他</option>
              </Dropdown>
              </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={4}>排序</Col>
            <Col md={4} className="text-center">
              <Input
                placeholder= "請輸入數值"
                value= {addType ? form.ORDER_ID : chosenTarget.ORDER_ID}
                onChange= {e => {handleTarget(e.target.value, 'ORDER_ID')}}
              />
              </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={4}>分類名稱</Col>
            <Col md={4} className="text-center">
              <Input
                placeholder= "名稱最多六個字"
                value= {addType ? form.CLASS_NAME : chosenTarget.CLASS_NAME}
                onChange= {e => {handleTarget(e.target.value, 'CLASS_NAME')}}
              />
              </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={4}>開關</Col>
            <RadioGroup md={4} >
              <div>
                <input
                  type="radio"
                  value= "1"
                  checked= {addType ? +form.STATUS === 1 : chosenTarget.STATUS}
                  onChange= {e => {handleTarget(e.target.value, 'STATUS')}}
                />開啟
              </div>
              <div>
                <input
                  type="radio"
                  value= ""
                  checked= {addType ? +form.STATUS === 0 : !chosenTarget.STATUS}
                  onChange= {e => {handleTarget(e.target.value, 'STATUS')}}
                />關閉
              </div>
            </RadioGroup>
          </Row>
        </Container>
        </Modal.Body>
        <ModalBtn>
          <Button style= {deleteSwitch ? {display: 'block'} : {display: 'none'}} variant="danger" onClick= {handleDelete}>刪除</Button>
          <Button variant="success" onClick= {handleSave}>儲存</Button>
        </ModalBtn>
      </Modal>

      <DeleteHint
        showDeleteHint= {showDeleteHint}
        handleDeleteHintClose= {handleDeleteHintClose}
        chosenTarget= {chosenTarget}
        setDataCopy= {setDataCopy}
        setCategoryInfo= {setCategoryInfo}
      />
    </>
  )
}