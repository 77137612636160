import React from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { ModalFormat, ModalText, ModalBtn } from "../../../middleware/utilityStyle"
import { deleteArenaConfig }  from "../../../middleware/Api/eventManagement/arenaConfigApi.js"

export default function DeletePU(props) {
  const {
    showDeletePU,
    handleDeletePUClose,
    popData,
    setPopData,
    setPanelInfo
  } = props

  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      /*
      if (new Date(popData.START_DATE) <= new Date(Date.now()) && new Date(popData.END_DATE) >= new Date(Date.now())) {
        alert(`※ 該競技場正在活動期間，無法刪除`)
        return
      }*/

      deleteArenaConfig(+popData.LADDER_ID).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if (db.error === errorCode.SERVER_ERROR.code) {
          alert(`※  ${errorCode.SERVER_ERROR.memo}`)
          return
        }
        setPanelInfo([])
        setPopData({})
      })
      handleDeletePUClose()
    })
  }

  const handleBack = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleDeletePUClose()
    })
  }
  return (
    <>
      <Modal
        show={showDeletePU}
        onHide={handleDeletePUClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>刪除確認</Modal.Title>
        </Modal.Header>
        <ModalText>
          確定刪除此筆資料嗎?
        </ModalText>
        <ModalBtn>
          <Button variant="danger" onClick={handleDelete}>刪除</Button>
          <Button variant="secondary" onClick={handleBack}>返回</Button>
        </ModalBtn>
      </Modal>
    </>
  )
}