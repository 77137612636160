import React from "react";
import { useLocation, Switch, Redirect } from "react-router-dom"

import AdminNavbar from "components/Navbars/AdminNavbar"
import Footer from "components/Footer/Footer"
import NewSidebar from "components/newSidebar/newSidebar"
import routes from "../middleware/routes/routes.js"
import sidebarImage from "assets/img/sidebar-3.jpg"
import PrivateRoute from '../middleware/routes/privateRoute'

export default function Admin() {
  const [image, setImage] = React.useState(sidebarImage)
  const [color, setColor] = React.useState("black")
  const [hasImage, setHasImage] = React.useState(true)
  const location = useLocation()
  const mainPanel = React.useRef(null)
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      return prop.content.map(page => {
        if (page.layout === "/admin") {
          return (
            <PrivateRoute
              path={page.layout + page.path}
              component={page.component}
              key={key}
            />
          )
        } else {
          return null
        }
      })
    })
  }
  React.useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    mainPanel.current.scrollTop = 0
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open")
      var element = document.getElementById("bodyClick")
      element.parentNode.removeChild(element)
    }
  }, [location])
  return (
    <div className="wrapper">
      <NewSidebar color={color} image={hasImage ? image : ""} routes={routes} />
      <div className="main-panel" ref={mainPanel}>
        <AdminNavbar />
        <div className="content">
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/" to="/login" />
          </Switch>
        </div>
        <Footer />
      </div>
    </div>
  )
}