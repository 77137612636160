import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import itemMainType from "../../../assets/config/itemType.json"
import { getAccessArr } from "../../../middleware/utils"

import Stack from '@mui/material/Stack'
import AddRevisePU from "../../../components/popUpWindow/itemConfig/AddRevisePU"
import DeletePU from "../../../components/popUpWindow/itemConfig/DeletePU"
import { getItemConfig } from "../../../middleware/Api/operation/itemConfigApi.js"

import {
  InputBarBg,
  IdInputBar,
  IdInput,
  BTN, 
  Group,
  Dropdown
} from "./styledComponent"
import {
  SearchButton,
  ResetButton,
  BasicTable,
  BasicRowTitle,
  TableContent,
  PageStyle,
  TableBtnGroup,
  NewBtn,
  ReviseBtn,
  DeleteBtn
} from "../../../middleware/utilityStyle.js"

export default function ItemConfig() {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const history = useHistory()
  const [panelInfo, setPanelInfo] = useState([])
  const [change, setChange] = useState({itemId: -1, mainType: -1, status: -1})
  const handleChange = (e, key) => {
    setChange(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }
  const [popData, setPopData] = useState({
    ITEM_ID: '',
    ITEM_NAME: '',
    MAIN_IMG: '',
    MAIN_TYPE: 1,
    SUB_TYPE: 1001,
    DETAIL_TYPE: 1001001,
    PREVIEW_IMG: '',
    ICON_IMG: '',
    TEXT: '',
    DETAIL_CONFIG: '',
    STATUS: 0, 
    ITEM_LIMIT: 0,
    PLAYER_COUNT: 0, 
    AVG_TAKE: 0
  })

  useEffect(() => {
  }, [panelInfo])

  const handleRadio = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPopData(panelInfo.find(ele => +ele.ITEM_ID === +e.target.value))
    })
  }

  const [changePagePara, setChangePagePara] = useState({
    itemId: -1, 
    mainType: -1, 
    status: -1
  })

  const [pageCounter, setPageCounter] = useState(0)
  const [page, setPage] = useState(1)
  const handleChangePage = (event, newPage) => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPage(newPage)
      const {itemId, mainType, status} = changePagePara
      handleGetItemConfig(itemId, mainType, status, 1+(newPage-1) * 30, newPage * 30)
    })
  }

  function handleGetItemConfig(itemId, mainType, status, sR, eR) {
    getItemConfig(+itemId, +mainType, +status, sR, eR).then(db => {
      if (db.error === errorCode.INCORRECT_DATA.code) {
        alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.INCORRECT_FORMAT.code) {
        alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.EXCEPTION.code) {
        alert(`※  ${errorCode.EXCEPTION.memo}`)
        return
      }
      if (db.error === errorCode.SERVER_ERROR.code) {
        alert(`※  ${errorCode.SERVER_ERROR.memo}`)
        return
      }
      if (db.error === errorCode.NULL_DATA.code) {
        alert(`※  ${errorCode.NULL_DATA.memo}`)
        setPanelInfo([])
        return
      }
      setChangePagePara({
        itemId: itemId,
        mainType: mainType, 
        status: status
      })
      if(db.data) {
        if (db.data.length > 0) {
          setPageCounter(
            db.data[0].TOTAL_ROW_NUM % 30 === 0
            ? Math.floor(db.data[0].TOTAL_ROW_NUM/30)
            : (Math.floor(db.data[0].TOTAL_ROW_NUM/30)) + 1
          )
        }
      }
      setPanelInfo(db.data)
      setPage(1)
    })
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") handleSearch()
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      let itemId = +change.itemId > 0 ? +change.itemId : -1;
      let mainType = change.mainType;
      let status = change.status;

      handleGetItemConfig(itemId, mainType, status, 1, 30)
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({itemId: -1, mainType: -1, status: -1})
    })
  }

  const [addStatus, setAddStatus] = useState(false)
  const[showAddRevise, setAddReviseShow] = useState(false)
  const handleAddReviseClose = () => setAddReviseShow(false)
  const handleAddReviseShow = () => setAddReviseShow(true)
  const [showDeletePU, setDeletePUShow] = useState(false)
  const handleDeletePUClose = () => setDeletePUShow(false)
  const handleDeletePUShow = () => setDeletePUShow(true)

  const handleAdd = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 108)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      setAddStatus(true)
      handleAddReviseShow()
    })
  }

  const handleRevise = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 109)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      setPopData(panelInfo.find(ele => +ele.ITEM_ID === +e.target.dataset.id))
      setAddStatus(false)
      handleAddReviseShow()
    })
  }

  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 110)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (!+popData.ITEM_ID) {
        alert('※  請選擇欲刪除的項目')
        return
      }
      handleDeletePUShow()
    })
  }

  function itemMainShow() {
    let list = []
    for (let i = 0; i < itemMainType.length; i++) {
      list.push(<option key= {itemMainType[i].mainType} value= {itemMainType[i].mainType}>{itemMainType[i].mainName}</option>)
    }
    return list
  }

  const transStatus = new Map ([
    [0, '關閉'],
    [1, '開啟']
  ])
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <IdInputBar>
          道具ID查詢<IdInput placeholder= "請輸入ID" value= {change.itemId == -1 ? '' : change.itemId} onChange= {e=> {handleChange(e, 'itemId')}} onKeyDown={handleKeyDown}/>
        </IdInputBar>
          
        <Group>
          <div>
            <span>類型查詢</span>
            <Dropdown
              value= {change.mainType}
              onChange= {e => {
                handleChange(e, 'mainType')
              }}
            >
              <option value="-1">全部</option>
              {itemMainShow()}
            </Dropdown>
          </div>
        </Group>

        <Group>
          <div>
            <span>狀態查詢</span>
            <Dropdown
              value= {change.status}
              onChange= {e => {
                handleChange(e, 'status')
              }}
            >
              <option value="-1">全部</option>
              <option value="0">關閉</option>
              <option value="1">開啟</option>
            </Dropdown>
          </div>
        </Group>
      
        <Group>
          <SearchButton onClick={handleSearch}>搜索</SearchButton>
          <ResetButton onClick={handleReset}>重置</ResetButton>
        </Group>
      </InputBarBg>
      
      <TableBtnGroup>
        <BasicTable className="table table-hover table-bordered">
          <thead>
            <BasicRowTitle>
              <th></th>
              <th>道具ID</th>
              <th>道具名稱</th>
              <th>類型</th>
              <th>持有數量上限</th>
              <th>全服持有玩家數</th>
              <th>玩家平均持有數</th>
              <th>狀態</th>
              <th>修改</th>
            </BasicRowTitle>
          </thead>
          <tbody>
            {
              Array.isArray(panelInfo) && panelInfo.length > 0 ?
              panelInfo.map((ele, index) => {
                return (
                  <TableContent key= {index}>
                    <td>
                      <input type= "radio" name= "itemId" value= {ele.ITEM_ID} onChange= {handleRadio}/>
                    </td>
                    <td>{ele.ITEM_ID}</td>
                    <td>{ele.ITEM_NAME}</td>
                    <td>{itemMainType.find(obj=> +obj.mainType === +ele.MAIN_TYPE) ? itemMainType.find(obj=> +obj.mainType === +ele.MAIN_TYPE).mainName : '未定義'}</td>
                    <td>{+JSON.parse(ele.DETAIL_CONFIG).ammountLimit}</td>
                    <td>{+ele.PLAYER_COUNT}</td>
                    <td>{+ele.AVG_TAKE}</td>
                    <td>{transStatus.get(+ele.STATUS)}</td>
                    <td><ReviseBtn data-id = {ele.ITEM_ID} onClick= {handleRevise}>修改</ReviseBtn></td>
                  </TableContent>
                )
              })
              : <tr key= "noInfo"><td colSpan= "10">尚無資料</td></tr>
            }
          </tbody>
        </BasicTable>        
        <BTN>
          <NewBtn onClick= {handleAdd}>新增</NewBtn>
          <DeleteBtn onClick= {handleDelete}>刪除</DeleteBtn>
        </BTN>
      </TableBtnGroup>

      <AddRevisePU
        showAddRevise= {showAddRevise}
        handleAddReviseClose= {handleAddReviseClose}
        addStatus= {addStatus}
        popData= {popData}
        itemMainType = {itemMainType}
        setPanelInfo= {setPanelInfo}
        setPopData= {setPopData}
      />

      <DeletePU
        popData= {popData}
        setPopData= {setPopData}
        showDeletePU= {showDeletePU}
        handleDeletePUClose= {handleDeletePUClose}
        setPanelInfo= {setPanelInfo}
      />

      {
        Array.isArray([panelInfo]) && panelInfo.length ?
          <Stack spacing={2}>
            <PageStyle count={pageCounter}
              color="warning"
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </Stack>
        : ''
      }
    </>
  )
}
