import { getAuthToken } from '../../utils.js'
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 取得鬥陣寶係數設定 PID95 ?//
export const getShopClassConfig = (status) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/shopClassConfig?status=${status}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 新增鬥陣寶係數設定 PID96 ?//
export const addShopClassConfig = (classType, className, status, orderId) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/shopClassConfig`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      classType,
      className,
      status,
      orderId
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 更改鬥陣寶係數設定 PID97 ?//
export const reviseShopClassConfig = (classId, classType, className, status, orderId) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/shopClassConfig`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      classId,
      classType,
      className,
      status,
      orderId
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 刪除鬥陣寶係數設定 PID98 ?//
export const deleteShopClassConfig = classId => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/shopClassConfig/delete`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      classId
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}