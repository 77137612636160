import { getAuthToken } from "../../utils.js"
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 取得遊戲配對資訊 PID55 ?//
export const getMatchStatus = () => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/serverEnv/gameQueeStatus`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}