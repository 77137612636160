import React from "react"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { updateAnnouncement } from "../../../middleware/Api/playerInfo/announcementApi"
import { addNewAnnouncement } from "../../../middleware/Api/playerInfo/announcementApi"
import { announcementList } from "../../../middleware/Api/playerInfo/announcementApi"
import { ModalBtn, ModalText } from "../../../middleware/utilityStyle"

export default function SavePU(props) {
  const {
    history,
    showSavePU,
    handleSavePUClose,
    handleInfoPUShow,
    targetList,
    setTargetList,
    detailCopy,
    handleCleanData,
    checkDetails,
    setPanelInfo,
    setDataCopy
  } = props
  
  const {
    TITLE,
    SUBTITLE,
    TEXT,
    TYPE,
    BEGIN_DATE,
    EXPIRE_DATE,
    STATE,
    ORDER_ID
  } = targetList

  const handleSave = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!checkDetails) {
        addNewAnnouncement(targetList.TITLE, targetList.SUBTITLE, targetList.TEXT, +targetList.TYPE,
        targetList.BEGIN_DATE, targetList.EXPIRE_DATE, +targetList.STATE, +targetList.ORDER_ID).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.REPEAT_DATA.code) {
            alert(`※  ${errorCode.REPEAT_DATA.memo}`)
            return
          }
          handleSavePUClose()
          announcementList().then(db => {
            if (db.error === errorCode.NULL_DATA.code) {
              alert(`※  ${errorCode.NULL_DATA.memo}`)
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            if (Array.isArray(db.data) && db.data.length) {
              db.data.sort(function order(a, b) {
                return a.ORDER_ID - b.ORDER_ID
              })
              db.data.sort(function order(a, b) {
                return b.TYPE - a.TYPE
              })
              setPanelInfo(db.data)
              setDataCopy(db.data)
            }
          })
        })
      }
      
      if (checkDetails) {
        updateAnnouncement(TITLE, SUBTITLE, TEXT, +TYPE, BEGIN_DATE, EXPIRE_DATE,
        +STATE, +ORDER_ID, +detailCopy.ORDER_ID).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          
          setTargetList({
            ORDER_ID: '',
            STATE: 1,
            TYPE: '',
            BEGIN_DATE: '',
            EXPIRE_DATE: '',
            TITLE: '',
            TEXT: '',
            SUBTITLE: ''
          })
          handleCleanData()
          for (let key in targetList) {
            targetList[key] = ''
          }
          for (let key in detailCopy) {
            detailCopy[key] = ''
          }
          announcementList().then(db => {
            if (db.error === errorCode.NULL_DATA.code) {
              alert(`※  ${errorCode.NULL_DATA.memo}`)
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            if (Array.isArray(db.data) && db.data.length) {
              db.data.sort(function order(a, b) {
                return a.ORDER_ID - b.ORDER_ID
              })
              db.data.sort(function order(a, b) {
                return b.TYPE - a.TYPE
              })
              setPanelInfo(db.data)
              setDataCopy(db.data)
            }
          })
          handleSavePUClose()
        })
      }
    })
  }

  const handleRefuseSubmit = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleSavePUClose()
      handleInfoPUShow()
    })
  }
  const handleOnHide = () => {
    handleSavePUClose()
    setTargetList(detailCopy)
  }
  return (
    <Modal
      show={showSavePU}
      onHide={handleOnHide}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>送出確認</Modal.Title>
      </Modal.Header>
      <ModalText>
        確定要送出資料嗎?
      </ModalText>
      <ModalBtn>
        <Button variant="info" onClick={handleSave}>送出</Button>
        <Button variant="secondary" onClick={handleRefuseSubmit}>上一步</Button>
      </ModalBtn>
    </Modal>
  )
}