import React from "react"
import Modal from 'react-bootstrap/Modal'
import { Button } from "react-bootstrap"
import { addCharacter } from "../../../middleware/Api/roleApi"
import { BigSquareTitle, BigSquareInput, ModalBtn } from '../../../middleware/utilityStyle'
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"

export default function AddRole(props) {
  const {
    change,
    setChange,
    handleChange,
    showAdd,
    handleAddClose,
    setPanelInfo,
    history,
    setAllRole
  } = props

  const handleAddAccount = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!change.roleName || !change.roleDes) {
        alert('請輸入空白處⊙ w ⊙')
        return
      }
      addCharacter(change.roleName, change.roleDes).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.REPEAT_DATA.code) {
          alert(`※  角色名稱不可重複`)
          let newChange = Object.assign({}, change)
          newChange.roleName = ''
          setChange(newChange)
          return
        }
        setPanelInfo(db.data)
        setAllRole(db.data)
        handleAddClose()
      })
    })
  }
  return (
    <>
      <Modal
        show={showAdd}
        onHide={handleAddClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>新增角色</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <BigSquareTitle>角色名稱</BigSquareTitle>
                <td>
                  <BigSquareInput placeholder="請輸入想註冊的角色名稱" name="roleName" value={change.roleName} onChange={handleChange} />
                </td>
              </tr>
              <tr>
                <BigSquareTitle>角色描述</BigSquareTitle>
                <td>
                  <BigSquareInput placeholder="請輸入角色名稱的備註" name="roleDes" value={change.roleDes} onChange={handleChange} />
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <ModalBtn>
          <Button variant="info" type="submit" onClick={handleAddAccount}>確定</Button>
          <Button variant="light" onClick={handleAddClose}>返回</Button>
        </ModalBtn>
      </Modal>
    </>
  )
}