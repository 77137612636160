import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import gameCategories from "../../../assets/config/gameCategories.json"
import { getUserInfo, getPhone } from "../../../middleware/Api/playerInfo/userInfoApi"
import { getAccessArr } from "../../../middleware/utils"
import ClickControl from "../../../components/popUpWindow/userInfo/clickControl"

import {
  InputBarBg,
  InputBarRWD,
  FirstInputBar,
  InputBar,
  Input,
  OrangeTd,
  Avatar,
  Frame,
  TitleTr,
  TdBg,
  GameList,
  Link,
  Dropdown
} from './styledComponent'
import { TimezoneTranslator } from "../../../middleware/dateUtils.js"
import {
  SearchButton,
  ResetButton,
  BasicTable,
  BasicRowTitle
} from "../../../middleware/utilityStyle.js"

export default function UserInfo() {
  const history = useHistory()
  const [panelInfo, setPanelInfo] = useState({})
  const [change, setChange] = useState({
    id: '',
    nickname: ''
  })
  const [hasData, setHasData] = useState(false)
  const [display, setDisplay] = useState({})
  const [originType, setOriginType] = useState(0)
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })

  let auth = false
  if (userPID.find(ele => +ele === 56)) {
    auth = true
  } else {
    auth = false
  }

  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
    })
  }, [panelInfo])

  const handleChange = e => {
    let newObj = Object.assign({}, change)
    newObj[e.target.name] = e.target.value
    setChange(newObj)
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({ id: '', nickname: '' })
    })
  }

  const [phoneNum, setPhoneNum] = useState(null)
  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!change.id && !change.nickname) {
        setHasData(false)
        setPanelInfo({})
        alert('※  請至少輸入其中一格欄位')
        return
      }
      let id = 0
      if (+change.id) {
        id = +change.id
      }
      let nickname = 'all'
      if (change.nickname) {
        nickname = change.nickname
      }

      getUserInfo(id, nickname).then(db => {
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setHasData(true)
        setPanelInfo(db.data)
        setOriginType(db.data.USER_TYPE)
        setDisplay(prev => ({
          ...prev,
          USER_TYPE: db.data.USER_TYPE,
          UID: db.data.UID
        }))
        if (db.data.UID && auth) {
          getPhone(+db.data.UID).then(db => {
            if (db.error === errorCode.INCORRECT_DATA.code) {
              alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.INCORRECT_FORMAT.code) {
              alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.NULL_DATA.code) {
              alert(`※  ${errorCode.NULL_DATA.memo}`)
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            setPhoneNum(db.data)
          })
        }
      })
    })
  }

  const handlePageChange = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (hasData) {
        history.push(`/admin/gameCalculate?UID=${+panelInfo.UID}`)
      } else {
        alert('※ 尚無玩家資訊')
      }
    })
  }
  const transSocialName = new Map([
    [1, 'LINE'],
    [2, '遊客'],
    [3, 'FB'],
    [4, 'GOOGLE'],
    [5, 'APPLE']
  ])

  const handleChangeAuth = () => {
    if (!userPID.find(ele => +ele === 59)){
      alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
      return
    }
  }

  const handleTypeOption = (e, key) => {
    setDisplay(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const handlePop = (e, key) => {
    handleTypeOption(e, key)
    handlePopShow()
  }

  const [showPop, setShowPop] = useState(false)
  const handlePopClose = () => {
    setShowPop(false)
  }

  const handlePopShow = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setShowPop(true)
    })
  }
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <InputBarRWD>
          <FirstInputBar>
            用戶ID<Input name='id' placeholder="請輸入欲查詢玩家Id" value={change.id} onChange={handleChange} />
          </FirstInputBar>
          <InputBar>
            暱稱<Input name='nickname' placeholder="請輸入欲查詢玩家暱稱" value={change.nickname} onChange={handleChange} />
          </InputBar>
        </InputBarRWD>
        <div>
          <SearchButton onClick={handleSearch}>搜索</SearchButton>
          <ResetButton onClick={handleReset}>重置</ResetButton>
        </div>
      </InputBarBg>

      {
        hasData ?
        <BasicTable className="table table-bordered">
          <tbody>
            <tr>
              <OrangeTd className="table-active">玩家頭像</OrangeTd>
              <td className="table-active">登錄IP</td>
              <td>{panelInfo.LAST_LOGIN_IP}</td>
              <td className="table-active">電話號碼</td>
              <td>{phoneNum ? phoneNum : '---'}</td>
              <td className="table-active" rowSpan="2">註冊時間</td>
              <td rowSpan="2">{panelInfo.REG_DATE ? TimezoneTranslator(panelInfo.REG_DATE) : '---'}</td>
            </tr>
            <tr>
              <Frame>
                <Avatar src={panelInfo.USER_IMG_URL} className="rounded" alt="頭像" />
              </Frame>
              <td className="table-active">註冊版本</td>
              <td>{panelInfo.REG_VERSION}</td>
              <td className="table-active">註冊平台</td>
              <td>{transSocialName.get(+panelInfo.BIND_SOCIAL)}</td>
            </tr>
            <tr>
              <OrangeTd className="table-active">暱稱(UID)</OrangeTd>
              <td className="table-active">被誰邀請</td>
              <td>{!+panelInfo.UP_LINE ? '---' : panelInfo.UP_LINE}</td>
              <td className="table-active">連續簽到天數</td>
              <td>{panelInfo.SIGN_IN_STATE ? panelInfo.SIGN_IN_STATE.split('_')[0] : '---'}</td>
              <td className="table-active">最後遊戲類型</td>
              <td>{+panelInfo.LAST_GAME_TYPE ? gameCategories.find(ele => +ele.gameID === +panelInfo.LAST_GAME_TYPE).gameName : '---'}</td>
            </tr>
            <tr>
              <td>{`${panelInfo.NICKNAME}(${panelInfo.UID})`}</td>
              <td className="table-active">擁有金幣</td>
              <td>{panelInfo.GOLD}</td>
              <td className="table-active">擁有鬥陣寶</td>
              <td>{panelInfo.GEM}</td>
              <td className="table-active">邀請好禮人數</td>
              <td>{panelInfo.DOWN_LINE_NUM}</td>
            </tr>
            <tr>
              <td></td>
              <td className="table-active"></td>
              <td></td>
              <td className="table-active"></td>
              <td></td>
              <td className="table-active">用戶類型</td>
              <td>
                <Dropdown value= {display.USER_TYPE} onClick= {handleChangeAuth} onChange= {e => {handlePop(e, 'USER_TYPE')}}>
                  <option value= '1'>一般玩家</option>
                  <option value= '2'>官方帳號</option>
                  <option value= '3'>AI帳號</option>
                </Dropdown>
              </td>
            </tr>
          </tbody>
        </BasicTable>
      : <BasicTable className="table table-bordered">
          <thead>
            <BasicRowTitle><th>尚無選取資料</th></BasicRowTitle>
          </thead>
        </BasicTable>
      }

      <BasicTable className="table table-bordered">
        <thead>
          <TitleTr>
            <th colSpan="4">遊戲訊息</th>
            <GameList colSpan="1"><Link onClick= {handlePageChange}>戰績列表</Link></GameList>
          </TitleTr>
        </thead>
        <tbody>
          <tr>
            <TdBg>總贏局數</TdBg>
            <td>{+panelInfo.TOTAL_WIN >= 0 ? panelInfo.TOTAL_WIN : '---'}</td>
            <TdBg>總輸局數</TdBg>
            <td>{+panelInfo.TOTAL_LOSE >= 0 ? panelInfo.TOTAL_LOSE : '---'}</td>
            <td rowSpan="2">---</td>
          </tr>
          <tr>
            <TdBg>總和局數</TdBg>
            <td>{+panelInfo.TOTAL_TIE >= 0 ? panelInfo.TOTAL_TIE : '---'}</td>
            <TdBg>玩家勝率</TdBg>
            <td>
              {!panelInfo.TOTAL_WIN || +panelInfo.TOTAL_WIN+(+panelInfo.TOTAL_LOSE)+(+panelInfo.TOTAL_TIE) === 0 ?
                '---'
              : ((+panelInfo.TOTAL_WIN/(+panelInfo.TOTAL_WIN + (+panelInfo.TOTAL_LOSE) + (+panelInfo.TOTAL_TIE)))*100).toString().substring(0, 5)}
            </td>
          </tr>
        </tbody>
      </BasicTable>

      <BasicTable className="table table-bordered">
        <thead>
          <TitleTr>
            <th colSpan="6">暗棋</th>
          </TitleTr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="6">休閒場</td>
          </tr>
          <tr>
            <TdBg>遊戲局數</TdBg>
            <td>{+panelInfo.CHESS_GAME_GAME_TYPE1_TOTAL_NUM >= 0 ? panelInfo.CHESS_GAME_GAME_TYPE1_TOTAL_NUM : '---'}</td>
            <TdBg>總贏金幣</TdBg>
            <td>{+panelInfo.CHESS_GAME_GAME_TYPE1_TOTAL_GET_GOLD >= 0 ? panelInfo.CHESS_GAME_GAME_TYPE1_TOTAL_GET_GOLD : '---'}</td>
            <TdBg>總輸金幣</TdBg>
            <td>{panelInfo.CHESS_GAME_GAME_TYPE1_TOTAL_LOSE_GOLD ? panelInfo.CHESS_GAME_GAME_TYPE1_TOTAL_LOSE_GOLD : '---'}</td>
          </tr>
          <tr>
            <td colSpan="6">倍數場</td>
          </tr>
          <tr>
            <TdBg>遊戲局數</TdBg>
            <td>{+panelInfo.CHESS_GAME_GAME_TYPE2_TOTAL_NUM >= 0 ? panelInfo.CHESS_GAME_GAME_TYPE2_TOTAL_NUM : '---'}</td>
            <TdBg>總贏金幣</TdBg>
            <td>{+panelInfo.CHESS_GAME_GAME_TYPE2_TOTAL_GET_GOLD >= 0 ? panelInfo.CHESS_GAME_GAME_TYPE2_TOTAL_GET_GOLD : '---'}</td>
            <TdBg>總輸金幣</TdBg>
            <td>{panelInfo.CHESS_GAME_GAME_TYPE2_TOTAL_LOSE_GOLD ? panelInfo.CHESS_GAME_GAME_TYPE2_TOTAL_LOSE_GOLD : '---'}</td>
          </tr>
        </tbody>
      </BasicTable>

      <BasicTable className="table table-bordered">
        <thead>
          <TitleTr>
            <th colSpan="6">麻將</th>
          </TitleTr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="6">休閒場</td>
          </tr>
          <tr>
            <TdBg>遊戲局數</TdBg>
            <td>{+panelInfo.MAHJONG_GAME_GAME_TYPE1_TOTAL_NUM >= 0 ? panelInfo.MAHJONG_GAME_GAME_TYPE1_TOTAL_NUM : '---'}</td>
            <TdBg>總贏金幣</TdBg>
            <td>{+panelInfo.MAHJONG_GAME_GAME_TYPE1_TOTAL_GET_GOLD >= 0 ? panelInfo.MAHJONG_GAME_GAME_TYPE1_TOTAL_GET_GOLD : '---'}</td>
            <TdBg>總輸金幣</TdBg>
            <td>{panelInfo.MAHJONG_GAME_GAME_TYPE1_TOTAL_LOSE_GOLD ? panelInfo.MAHJONG_GAME_GAME_TYPE1_TOTAL_LOSE_GOLD : '---'}</td>
          </tr>
          <tr>
            <td colSpan="6">倍數場</td>
          </tr>
          <tr>
            <TdBg>遊戲局數</TdBg>
            <td>{+panelInfo.MAHJONG_GAME_GAME_TYPE2_TOTAL_NUM >= 0 ? panelInfo.MAHJONG_GAME_GAME_TYPE2_TOTAL_NUM : '---'}</td>
            <TdBg>總贏金幣</TdBg>
            <td>{+panelInfo.MAHJONG_GAME_GAME_TYPE2_TOTAL_GET_GOLD >= 0 ? panelInfo.MAHJONG_GAME_GAME_TYPE2_TOTAL_GET_GOLD : '---'}</td>
            <TdBg>總輸金幣</TdBg>
            <td>{panelInfo.MAHJONG_GAME_GAME_TYPE2_TOTAL_LOSE_GOLD ? panelInfo.MAHJONG_GAME_GAME_TYPE2_TOTAL_LOSE_GOLD : '---'}</td>
          </tr>
        </tbody>
      </BasicTable>

      <BasicTable className="table table-bordered">
        <thead>
          <TitleTr>
            <th colSpan="6">多米諾</th>
          </TitleTr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="6">休閒場</td>
          </tr>
          <tr>
            <TdBg>遊戲局數</TdBg>
            <td>{+panelInfo.DOMINO_GAME_GAME_TYPE1_TOTAL_NUM >= 0 ? panelInfo.DOMINO_GAME_GAME_TYPE1_TOTAL_NUM : '---'}</td>
            <TdBg>總贏金幣</TdBg>
            <td>{+panelInfo.DOMINO_GAME_GAME_TYPE1_TOTAL_GET_GOLD >= 0 ? panelInfo.DOMINO_GAME_GAME_TYPE1_TOTAL_GET_GOLD : '---'}</td>
            <TdBg>總輸金幣</TdBg>
            <td>{panelInfo.DOMINO_GAME_GAME_TYPE1_TOTAL_LOSE_GOLD ? panelInfo.DOMINO_GAME_GAME_TYPE1_TOTAL_LOSE_GOLD : '---'}</td>
          </tr>
          <tr>
            <td colSpan="6">倍數場</td>
          </tr>
          <tr>
            <TdBg>遊戲局數</TdBg>
            <td>{+panelInfo.DOMINO_GAME_GAME_TYPE2_TOTAL_NUM >= 0 ? panelInfo.DOMINO_GAME_GAME_TYPE2_TOTAL_NUM : '---'}</td>
            <TdBg>總贏金幣</TdBg>
            <td>{+panelInfo.DOMINO_GAME_GAME_TYPE2_TOTAL_GET_GOLD >= 0 ? panelInfo.DOMINO_GAME_GAME_TYPE2_TOTAL_GET_GOLD : '---'}</td>
            <TdBg>總輸金幣</TdBg>
            <td>{panelInfo.DOMINO_GAME_GAME_TYPE2_TOTAL_LOSE_GOLD ? panelInfo.DOMINO_GAME_GAME_TYPE2_TOTAL_LOSE_GOLD : '---'}</td>
          </tr>
        </tbody>
      </BasicTable>

      <BasicTable className="table table-bordered">
        <thead>
          <TitleTr>
            <th colSpan="6">二人麻將</th>
          </TitleTr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="6">休閒場</td>
          </tr>
          <tr>
            <TdBg>遊戲局數</TdBg>
            <td>{+panelInfo.MAHJONG_2P_GAME_GAME_TYPE1_TOTAL_NUM >= 0 ? panelInfo.MAHJONG_2P_GAME_GAME_TYPE1_TOTAL_NUM : '---'}</td>
            <TdBg>總贏金幣</TdBg>
            <td>{+panelInfo.MAHJONG_2P_GAME_GAME_TYPE1_TOTAL_GET_GOLD >= 0 ? panelInfo.MAHJONG_2P_GAME_GAME_TYPE1_TOTAL_GET_GOLD : '---'}</td>
            <TdBg>總輸金幣</TdBg>
            <td>{panelInfo.MAHJONG_2P_GAME_GAME_TYPE1_TOTAL_LOSE_GOLD ? panelInfo.MAHJONG_2P_GAME_GAME_TYPE1_TOTAL_LOSE_GOLD : '---'}</td>
          </tr>
          <tr>
            <td colSpan="6">倍數場</td>
          </tr>
          <tr>
            <TdBg>遊戲局數</TdBg>
            <td>{+panelInfo.MAHJONG_2P_GAME_GAME_TYPE2_TOTAL_NUM >= 0 ? panelInfo.MAHJONG_2P_GAME_GAME_TYPE2_TOTAL_NUM : '---'}</td>
            <TdBg>總贏金幣</TdBg>
            <td>{+panelInfo.MAHJONG_2P_GAME_GAME_TYPE2_TOTAL_GET_GOLD >= 0 ? panelInfo.MAHJONG_2P_GAME_GAME_TYPE2_TOTAL_GET_GOLD : '---'}</td>
            <TdBg>總輸金幣</TdBg>
            <td>{panelInfo.MAHJONG_2P_GAME_GAME_TYPE2_TOTAL_LOSE_GOLD ? panelInfo.MAHJONG_2P_GAME_GAME_TYPE2_TOTAL_LOSE_GOLD : '---'}</td>
          </tr>
        </tbody>
      </BasicTable>

      <BasicTable className="table table-bordered">
        <thead>
          <TitleTr>
            <th colSpan="6">兌換與儲值詳細資訊</th>
          </TitleTr>
        </thead>
        <tbody>
          <tr>
            <TdBg>儲值金幣總數</TdBg>
            <td>{+panelInfo.TOTAL_INFULL_NUM >= 0 ? +panelInfo.TOTAL_INFULL_NUM : '---'}</td>
            <TdBg>兌換商品總數</TdBg>
            <td>{+panelInfo.TOTAL_EXCHANGE_NUM >= 0 ? +panelInfo.TOTAL_EXCHANGE_NUM : '---'}</td>
          </tr>
        </tbody>
      </BasicTable>

      <ClickControl
        showPop= {showPop}
        setShoPop= {setShowPop}
        handlePopClose= {handlePopClose}
        handlePopShow= {handlePopShow}
        display= {display}
        setDisplay= {setDisplay}
        originType= {originType}
        setOriginType= {setOriginType}
        setPanelInfo= {setPanelInfo}
      />
    </>
  )
}