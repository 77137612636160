import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { sessionVerified, getAllEventList } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import prizeType from "../../../assets/config/eventPrizeType.json"
import AddPU from "../../../components/popUpWindow/eventUserList/AddPU"
import Stack from '@mui/material/Stack'
import { getAccessArr } from "../../../middleware/utils"
import { TimezoneTranslator } from "../../../middleware/dateUtils"
import { getEventUserList } from "../../../middleware/Api/eventManagement/eventUserListApi"

import {
  InputBarBg,
  NavLeft,
  IdInputBar,
  IdInput,
  BtnGroup,
  AddBtn,
  Dropdown,
  SecondCell
} from "./styledComponent"
import {
  SearchButton,
  ResetButton,
  BasicTable,
  BasicRowTitle,
  TableContent,
  PageStyle
} from "../../../middleware/utilityStyle.js"

export default function EventUserList() {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const history = useHistory()
  const [panelInfo, setPanelInfo] = useState([])
  const [change, setChange] = useState({uid: '', activityId: ''})
  const handleChange = (e, key) => {
    setChange(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }
  const [popData, setPopData] = useState({})
  const [allEvent, setAllEvent] = useState([])

  useEffect(() => {
    getAllEventList().then(db => {
      if (Array.isArray(db.data) && db.data.length) {
        setAllEvent(db.data)
        if (!change.activityId) {
          setChange(prev => ({
            ...prev,
            activityId: db.data[0].ACTIVITY_ID
          }))
        }
      }
    })

    if (popData.ACTIVITY_ID && panelInfo.length) {
      if (popData.ACTIVITY_ID !== panelInfo[0].ACTIVITY_ID) {
        setPopData(panelInfo[0])
      }
    }
  }, [panelInfo])

  const handleRadio = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPopData(panelInfo.find(ele => +ele.UID === +e.target.value))
    })
  }

  const [changePagePara, setChangePagePara] = useState({
    uid: '',
    activityId: ''
  })

  const [pageCounter, setPageCounter] = useState(0)
  const [page, setPage] = useState(1)

  function handleGetUserList(uid, activityId, sR, eR) {
    getEventUserList(uid, activityId, sR, eR).then(db => {
      if (db.error === errorCode.INCORRECT_DATA.code) {
        alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.INCORRECT_FORMAT.code) {
        alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.EXCEPTION.code) {
        alert(`※  ${errorCode.EXCEPTION.memo}`)
        return
      }
      if (db.error === errorCode.SERVER_ERROR.code) {
        alert(`※  ${errorCode.SERVER_ERROR.memo}`)
        return
      }
      if (db.error === errorCode.NULL_DATA.code) {
        alert(`※  ${errorCode.NULL_DATA.memo}`)
        setPanelInfo([])
        return
      }
      setChangePagePara({
        uid,
        activityId
      })
      if(db.data) {
        if (db.data.length > 0) {
          setPageCounter(
            db.data[0].TOTAL_ROW_NUM%30 === 0
            ? Math.floor(db.data[0].TOTAL_ROW_NUM/30)
            : (Math.floor(db.data[0].TOTAL_ROW_NUM/30)) + 1
          )
        }
      }
      setPanelInfo(db.data)
      setPage(1)
    })
  }

  const handleChangePage = (event, newPage) => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPage(newPage)
      handleGetUserList(+changePagePara.uid, +changePagePara.activityId, 1+(newPage-1)*30, newPage*30)
    })
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleGetUserList(change.activityId, change.uid, 1, 30)
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({uid: '', activityId: allEvent[0].ACTIVITY_ID})
    })
  }

  const[showAddRevise, setAddReviseShow] = useState(false)
  const handleAddReviseClose = () => setAddReviseShow(false)
  const handleAddReviseShow = () => setAddReviseShow(true)
  const handleAdd = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleAddReviseShow()
    })
  }
  const handleType = ID => {
    if (allEvent.find(ele => +ele.ACTIVITY_ID === +ID)) {
      return allEvent.find(ele => +ele.ACTIVITY_ID === +ID).ACTIVITY_NAME
    }
  }
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <NavLeft>
          <IdInputBar>
            用戶ID<IdInput placeholder= "請輸入用戶ID" value= {change.uid} onChange= {e=> {handleChange(e, 'uid')}}/>
          </IdInputBar>
          <SecondCell>
            <span>活動名稱</span>
              <Dropdown value= {change.activityId} onChange= {e=> {handleChange(e, 'activityId')}}>
              {
                Array.isArray(allEvent) && allEvent.length ?
                  allEvent.map((ele, index) => {
                    return <option value= {ele.ACTIVITY_ID} key= {index}>{ele.ACTIVITY_NAME}</option>
                  })
                : ''
              }
              </Dropdown>            
          </SecondCell>
        </NavLeft>
      
        <BtnGroup>
          <SearchButton onClick={handleSearch}>搜索</SearchButton>
          <ResetButton onClick={handleReset}>重置</ResetButton>
        </BtnGroup>
      </InputBarBg>
      
      <BasicTable className="table table-hover table-bordered">
        <thead>
          <BasicRowTitle>
            <th>
              <input type= "radio" disabled/>
            </th>
            <th>活動名稱</th>
            <th>用戶ID</th>
            <th>總遊玩次數</th>
            <th>當日遊玩次數</th>
            <th>最近遊玩時間</th>
            <th>創建時間</th>
            <th>金幣</th>
            <th>現金</th>
            <th>獲獎內容</th>
          </BasicRowTitle>
        </thead>
        <tbody>
          {
            Array.isArray(panelInfo) && panelInfo.length > 0 ?
            panelInfo.map((ele, index) => {
              return (
                <TableContent key= {index}>
                  <td>
                    <input type= "radio" name= "eventList" value= {ele.UID} onChange= {handleRadio}/>
                  </td>
                  <td>{handleType(ele.ACTIVITY_ID)}</td>
                  <td>{ele.UID}</td>
                  <td>{ele.TOTAL_PLAY_NUM}</td>
                  <td>{ele.DAILY_PLAY_NUM}</td>
                  <td>{ele.LAST_PLAY_DATE ? TimezoneTranslator(ele.LAST_PLAY_DATE) : '---'}</td>
                  <td>{ele.CREATE_DATE ? TimezoneTranslator(ele.CREATE_DATE): '---'}</td>
                  <td>{ele.AWARD_GOLD_NUM}</td>
                  <td>{ele.AWARD_CASH_NUM}</td>
                  <td>{ele.SCORE ? prizeType.find(obj => +obj.num === +ele.SCORE).name : '---'}</td>
                </TableContent>
              )
            })
            : <tr key= "noInfo"><td colSpan= "10">尚無資料</td></tr>
          }
        </tbody>
      </BasicTable>
      
      <AddBtn onClick= {handleAdd}>增加活動次數</AddBtn>

      <AddPU
        showAddRevise= {showAddRevise}
        handleAddReviseShow= {handleAddReviseShow}
        handleAddReviseClose= {handleAddReviseClose}
        popData= {popData}
        setPopData= {setPopData}
        setPanelInfo= {setPanelInfo}
        allEvent= {allEvent}
        userPID= {userPID}
      />

      {
        Array.isArray(panelInfo) && panelInfo.length ?
          <Stack spacing={2}>
            <PageStyle count={pageCounter}
              color="warning"
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </Stack>
        : ''
      }
    </>
  )
}