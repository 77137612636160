import { getAuthToken } from '../../utils.js'
import { BaseUrl, UploadMaterial } from "../../../assets/config/urlForBuild"

//? 取得道具設定 PID107 ?//
export const getItemConfig = (itemId, mainType, status, sRow, eRow) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/itemConfig?itemId=${itemId}&mainType=${mainType}&status=${status}&sRow=${sRow}&eRow=${eRow}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 新增道具設定 PID108 ?//
export const addItemConfig = (itemId, itemName, mainImg, mainType, subType, detailType, previewImg, iconImg, text, detailConfig, status) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/itemConfig`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      itemId,
      itemName,
      mainImg,
      mainType,
      subType,
      detailType,
      previewImg,
      iconImg,
      text,
      detailConfig,
      status
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 更改道具設定 PID109 ?//
export const reviseItemConfig = (itemId, itemName, mainImg, mainType, subType, detailType, previewImg, iconImg, text, detailConfig, status) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/itemConfig`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      itemId,
      itemName,
      mainImg,
      mainType,
      subType,
      detailType,
      previewImg,
      iconImg,
      text,
      detailConfig,
      status
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 刪除道具設定 PID110 ?//
export const deleteItemConfig = (itemId, goldNum, userName) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/itemConfig/delete`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      itemId, 
      goldNum, 
      userName
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

export const uploadItem = formData => {
  return fetch(UploadMaterial, {
    method: 'POST',
    body: formData,
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}