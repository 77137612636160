import React, { useState, useEffect, useContext}from "react"
import { useHistory } from "react-router-dom"

import{BasicInput} from "../../../middleware/utilityStyle"
import {
  PULeftTitle,
  PUSelect,
  PUInput,
  Text
} from "../../../views/backstageManagement/adSettingForFinalCalculate/styledComponent"


import prizeContent from "./prizeContent.json"

import {noticeMessageData} from "./Contexts"

export default function NoticeMessageComponent({}){
  const [noticeMessage, setNoticeMessage] = useContext(noticeMessageData)

  const handleSetNotice = (e, key) =>{
    let temp = {...noticeMessage}
    if (key ==="switch" && +e.target.value===0 ){
      let keys = Object.keys(temp).filter(k=> k !=="switch")
      keys.forEach(k => {temp[k] =""})

    }
    temp[key] = e.target.value
    setNoticeMessage(temp)
  }
  return (
    <>
      <div>
        <select
          value={noticeMessage.switch}
          onChange={e=>{handleSetNotice(e,"switch")}}
        >
          <option value ="1" >啟用</option>
          <option value ="0" selected>關閉</option>
        </select>
        
      </div>
      <Text 
        className="form-control"
        rows="3"
        placeholder="請輸入"
        value={noticeMessage.message?noticeMessage.message: ""}
        onChange={e=>{handleSetNotice(e,"message")}}
        disabled={noticeMessage.switch === "0"?true: false}
      />
    
    </>
  )
}