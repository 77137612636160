import { getAuthToken } from '../utils.js'
import { BaseUrl } from "../../assets/config/urlForBuild"

//? 新增權限 ?//
export const addAuth = (accessID, accessName, description) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/access`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      accessID,
      accessName,
      description
    })
  })
    .then(res => res.json())
    .catch(err => {
      console.log('err: ', err)
    })
}

//? 修改權限資訊 ?//
export const reviseAuth = (accessID, accessName, description) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/access`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      accessID,
      accessName,
      description
    })
  })
    .then(res => res.json())
    .catch(err => {
      console.log('err: ', err)
    })
}

//? 刪除權限 ?//
export const deleteAuth = (accessID) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/access`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      accessID
    })
  })
    .then(res => res.json())
    .catch(err => {
      console.log('err: ', err)
    })
}
