import React from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { defaultDate, defaultDateE, TimezoneTranslator } from "../../../middleware/dateUtils"
import {  ModalBtn, ModalText } from "../../../middleware/utilityStyle"
import { updateOrderDetail, orderList } from "../../../middleware/Api/operation/orderManagementApi"

export default function HandleEndStatus(props) {
  const history = useHistory()
  const {
    showEnd,
    handleEndClose,
    unoManiStatus,
    display,
    multiManiStatus,
    setChangePagePara,
    setPanelInfo,
    setPageCounter,
    displayMulti
  } = props

  const handleCancel = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleEndClose()
    })
  }

  const handleCheckEnd = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (unoManiStatus) {
        updateOrderDetail(display.ORDER_ID, +display.PACKAGE_STATUS, display.LOGISTICS,
        display.PACKAGE_NUMBER, display.REMARK, +display.FINISH_STATUS).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          handleEndClose()
          setTimeout(() => {
            alert('訂單設定儲存成功  ʕ๑´ㅂ`๑ʔ')
          }, 200)
        })
      }
      if (multiManiStatus) {
        let error = 0
        for (let i = 0; i < displayMulti.length; i++) {
          updateOrderDetail(`${displayMulti[i].ORDER_ID}`, +displayMulti[i].PACKAGE_STATUS, displayMulti[i].LOGISTICS,
          displayMulti[i].PACKAGE_NUMBER, displayMulti[i].REMARK, +displayMulti[i].FINISH_STATUS).then(db => {
            if (db.error === errorCode.INCORRECT_DATA.code) {
              alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.INCORRECT_FORMAT.code) {
              alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            if (db.error) {
              error = 1
              alert('批次儲存失敗，相關問題請洽詢工程部  ʕ๑•́ᴥ•̀๑ʔ')
              return
            }
          })
        }
        if (!error) {
          handleEndClose()
          setTimeout(() => {
            alert('訂單設定儲存成功  ʕ๑´ㅂ`๑ʔ')
          }, 200)
        }
      }
    })
  }
  return (
    <>
      <Modal
        show={showEnd}
        onHide={handleEndClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>結束確認</Modal.Title>
        </Modal.Header>
        <ModalText>
          訂單設為結案之後無法再編輯與調整，是否確定儲存結案？
        </ModalText>
        <ModalBtn>
          <Button variant="secondary" onClick={handleCancel}>取消</Button>
          <Button variant="warning" onClick= {handleCheckEnd}>結案</Button>
        </ModalBtn>
      </Modal>
    </>
  )
}
