import React, { useState, useEffect }from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import Box from '@mui/material/Box'
import {
  PULeftTitle,  
  DateTimeWrapper,
  PUSelect,
  TimeFormat, 
  WeekGroup,
  PopFrame,
  Avatar,
  NoImg,
  LabelBtn, 
  TimeInput
} from "../../../views/backstageManagement/gemRatioConfig/styledComponent"
import { ModalFormat, PUInput, ModalBtn } from "../../../middleware/utilityStyle"
import { addGemRatio, reviseGemRatio, uploadItem } from "../../../middleware/Api/backstageManagement/gemRatioConfigApi.js"
import DeletePU from "./DeletePU"

export default function AddPU(props) {
  const {
    showAddRevise,
    handleAddReviseShow,
    handleAddReviseClose,
    addStatus,
    popData,
    gameTypeList,
    hallTypeList,
    roomTypeList,
    setPanelInfo,
    setPopData
  } = props
  const history = useHistory()
  const [form, setForm] = useState({
    GMGRC_ID: '',
    GAME_TYPE: 99,
    GAME_TYPE_IMG: '',
    HALL_TYPE: 99,
    HALL_TYPE_IMG: '',
    ROOM_TYPE: 99,
    ROOM_TYPE_IMG: '',
    MIN_RATIO: 1,
    MAX_RATIO: 1,
    BEGIN_DATE: null,
    END_DATE: null,
    INTERVAL_WEEK: '0,0,0,0,0,0,0',
    INTERVAL_BEGIN: null,
    INTERVAL_END: null,
    OPEN_STATE: 0
  })

  const handleForm = (e, key) => {
    setForm(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const [dateGroup, setDateGroup] = useState({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null
  })

  const [intervalTimeGroup, setIntervalTimeGroup] = useState({
    startTime: null,
    endTime: null
  })

  const [gameTypeImgData, setGameTypeImgData] = useState()
  const [gameTypeFileData, setGameTypeFileData] = useState(false)
  const handleUploadGameTypeImg = e => {
    e.preventDefault()
    const file = e.target.files[0]
    if(file) {
      const formData = new FormData()
      formData.append('file', file)
      setGameTypeImgData(formData)
      setGameTypeFileData(true)
      const imgReader = new FileReader()
      imgReader.readAsDataURL(file)
      imgReader.onload = e => {
        setForm(prev => ({
          ...prev,
          GAME_TYPE_IMG: e.target.result
        }))
      }
    }
  }
  const [hallTypeImgData, setHallTypeImgData] = useState()
  const [hallTypeFileData, setHallTypeFileData] = useState(false)
  const handleUploadHallTypeImg = e => {
    e.preventDefault()
    const file = e.target.files[0]
    if(file) {
      const formData = new FormData()
      formData.append('file', file)
      setHallTypeImgData(formData)
      setHallTypeFileData(true)
      const imgReader = new FileReader()
      imgReader.readAsDataURL(file)
      imgReader.onload = e => {
        setForm(prev => ({
          ...prev,
          HALL_TYPE_IMG: e.target.result
        }))
      }
    }
  }
  const [roomTypeImgData, setRoomTypeImgData] = useState()
  const [roomTypeFileData, setRoomTypeFileData] = useState(false)
  const handleUploadRoomTypeImg = e => {
    e.preventDefault()
    const file = e.target.files[0]
    if(file) {
      const formData = new FormData()
      formData.append('file', file)
      setRoomTypeImgData(formData)
      setRoomTypeFileData(true)
      const imgReader = new FileReader()
      imgReader.readAsDataURL(file)
      imgReader.onload = e => {
        setForm(prev => ({
          ...prev,
          ROOM_TYPE_IMG: e.target.result
        }))
      }
    }
  }

  const cleanData = () => {
    setForm({
      GMGRC_ID: '',
      GAME_TYPE: 99,
      GAME_TYPE_IMG: '',
      HALL_TYPE: 99,
      HALL_TYPE_IMG: '',
      ROOM_TYPE: 99,
      ROOM_TYPE_IMG: '',
      MIN_RATIO: 1,
      MAX_RATIO: 1,
      BEGIN_DATE: null,
      END_DATE: null,
      INTERVAL_WEEK: '0,0,0,0,0,0,0',
      INTERVAL_BEGIN: null,
      INTERVAL_END: null,
      OPEN_STATE: 0
    })
    setDateGroup({
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null
    })
    setIntervalTimeGroup({
      startTime: null,
      endTime: null
    })
    setGameTypeFileData(false)
    setHallTypeFileData(false)
    setRoomTypeFileData(false)
  }
  
  useEffect(() => {    
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if(!addStatus) {
        setForm(JSON.parse(JSON.stringify(popData)))
        setDateGroup({
          startDate: popData.BEGIN_DATE,
          startTime: popData.BEGIN_DATE,
          endDate: popData.END_DATE,
          endTime: popData.END_DATE
        })
        setIntervalTimeGroup({
          startTime: popData.INTERVAL_BEGIN,
          endTime: popData.INTERVAL_END
        })
      }
      if(addStatus) {
        cleanData()
      }
    })
  }, [popData, addStatus])

  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }

      handleAddReviseClose()
      handleConfirmShow()
    })
  }

  async function handelFileUpload() {
    let gameTypeTargetUrl = form.GAME_TYPE_IMG
    if (gameTypeFileData) {
      await uploadItem(gameTypeImgData).then(async db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setForm(prev => ({
          ...prev,
          GAME_TYPE_IMG: db.data.url
        }))
        gameTypeTargetUrl = db.data.url
      })
    }
    let hallTypeTargetUrl = form.HALL_TYPE_IMG
    if (hallTypeFileData) {
      await uploadItem(hallTypeImgData).then(async db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setForm(prev => ({
          ...prev,
          HALL_TYPE_IMG: db.data.url
        }))
        hallTypeTargetUrl = db.data.url
      })
    }
    let roomTypeTargetUrl = form.ROOM_TYPE_IMG
    if (roomTypeFileData) {
      await uploadItem(roomTypeImgData).then(async db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setForm(prev => ({
          ...prev,
          ROOM_TYPE_IMG: db.data.url
        }))
        roomTypeTargetUrl = db.data.url
      })
    }
    return {
      'gameTypeTargetUrl': gameTypeTargetUrl, 
      'hallTypeTargetUrl': hallTypeTargetUrl, 
      'roomTypeTargetUrl': roomTypeTargetUrl
    }
  } 

  async function handleSubmit () {
    sessionVerified().then(async db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!form.MIN_RATIO) {
        alert('※  請輸入最小活動係數')
        return
      }
      if (!form.MAX_RATIO) {
        alert('※  請輸入最大活動係數')
        return
      }
      if (isNaN(+form.MIN_RATIO) || isNaN(+form.MAX_RATIO)) {
        alert('※  活動係數欄位請輸入數字')
        setForm(prev => ({
          ...prev,
          MIN_RATIO: '', 
          MAX_RATIO: ''
        }))
        return
      }
      if (+form.MIN_RATIO < 1 || +form.MAX_RATIO < 1) {
        alert('※  活動係數不得低於1')
        return
      }
      if (+form.MIN_RATIO > 999 || +form.MAX_RATIO > 999) {
        alert('※  活動係數不得超過3位數')
        return
      }
      
      for (let key in dateGroup) {
        if (!dateGroup[key]) {
          alert('※  開始及結束的日期、時間不可為空')
        return
        }
      }

      
      for (let key in intervalTimeGroup) {
        if (!intervalTimeGroup[key]) {
          //alert('※  循環設定時間不可為空')
          //return
          switch(key) {
            case "startTime":
              intervalTimeGroup[key] = "1970-01-01 16:00:00"
              break;
            case "endTime":
              intervalTimeGroup[key] = "1970-01-02 15:59:59"
              break;
            default:
              intervalTimeGroup[key] = "1970-01-01 16:00:00"
              break;
          }
        }
      }

      let getResult = await handelFileUpload();

      let formWeekCheck = form.INTERVAL_WEEK
      if (form.INTERVAL_WEEK == '0,0,0,0,0,0,0') {
        formWeekCheck = '1,1,1,1,1,1,1';
      }

      let saveStartTime = new Date(
        new Date(dateGroup.startDate).getFullYear(),
        new Date(dateGroup.startDate).getMonth(),
        new Date(dateGroup.startDate).getDate(),
        new Date(dateGroup.startTime).getHours(),
        new Date(dateGroup.startTime).getMinutes()
        )
  
      let saveEndTime = new Date(
        new Date(dateGroup.endDate).getFullYear(),
        new Date(dateGroup.endDate).getMonth(),
        new Date(dateGroup.endDate).getDate(),
        new Date(dateGroup.endTime).getHours(),
        new Date(dateGroup.endTime).getMinutes()
      )

      if (saveStartTime > saveEndTime) {
        alert('※  結束時間不可早於開始時間')
        return
      }
      
      if (!addStatus) {
        reviseGemRatio(+form.GMGRC_ID, +form.GAME_TYPE, getResult.gameTypeTargetUrl, +form.HALL_TYPE, getResult.hallTypeTargetUrl, +form.ROOM_TYPE, getResult.roomTypeTargetUrl, +form.MIN_RATIO, +form.MAX_RATIO
          , saveStartTime, saveEndTime, formWeekCheck, intervalTimeGroup.startTime, intervalTimeGroup.endTime, +form.OPEN_STATE).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          if (db.error === errorCode.SERVER_ERROR.code) {
            alert(`※  ${errorCode.SERVER_ERROR.memo}`)
            return
          }
          setPanelInfo([])
          setPopData({})
        })
      }
      if (addStatus) {
        addGemRatio(+form.GAME_TYPE, getResult.gameTypeTargetUrl, +form.HALL_TYPE, getResult.hallTypeTargetUrl, +form.ROOM_TYPE, getResult.roomTypeTargetUrl, +form.MIN_RATIO, +form.MAX_RATIO
          , saveStartTime, saveEndTime, formWeekCheck, intervalTimeGroup.startTime, intervalTimeGroup.endTime, +form.OPEN_STATE).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          if (db.error === errorCode.SERVER_ERROR.code) {
            alert(`※  ${errorCode.SERVER_ERROR.memo}`)
            return
          }
          setPanelInfo([])
          setPopData({})
        })
      }

      cleanData()
      handleConfirmClose()
      handleAddReviseClose()
    })    
  }

  const handleBack = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleAddReviseClose()
      setTimeout(() => {
        if (addStatus) {
          cleanData()
        }
        if (!addStatus) {
          setForm(JSON.parse(JSON.stringify(popData)))
          setDateGroup({
            startDate: popData.BEGIN_DATE,
            startTime: popData.BEGIN_DATE,
            endDate: popData.END_DATE,
            endTime: popData.END_DATE
          })
          setIntervalTimeGroup({
            startTime: popData.INTERVAL_BEGIN, 
            endTime: popData.INTERVAL_END
          })
        }
      }, 500)
    })
  }

  const handleOnHide = () => {
    handleAddReviseClose()
    if (addStatus) {
      cleanData()
    }
  }

  const handleWeek = e => {
    let dayArr = form.INTERVAL_WEEK.split(',')
    if (+dayArr[+e.target.dataset.dayindex] === 1) {
      dayArr.splice(+e.target.dataset.dayindex, 1, '0')
      setForm(prev => ({
        ...prev,
        INTERVAL_WEEK: dayArr.join(',')
      }))
    } else if (+dayArr[+e.target.dataset.dayindex] === 0) {
      dayArr.splice(+e.target.dataset.dayindex, 1, '1')
      setForm(prev => ({
        ...prev,
        INTERVAL_WEEK: dayArr.join(',')
      }))
    }
  }

  const handleDate = e => {
    let saveStartTime = new Date(
      new Date(dateGroup.startDate).getFullYear(),
      new Date(dateGroup.startDate).getMonth(),
      new Date(dateGroup.startDate).getDate(),
      new Date(dateGroup.startTime).getHours(),
      new Date(dateGroup.startTime).getMinutes()
      )

    let saveEndTime = new Date(
      new Date(dateGroup.endDate).getFullYear(),
      new Date(dateGroup.endDate).getMonth(),
      new Date(dateGroup.endDate).getDate(),
      new Date(dateGroup.endTime).getHours(),
      new Date(dateGroup.endTime).getMinutes()
    )

    setForm(prev => ({
      ...prev,
      BEGIN_DATE: saveStartTime,
      END_DATE: saveEndTime
    }))
  }

  const handleTime = e => {
    setForm(prev => ({
      ...prev,
      INTERVAL_BEGIN: intervalTimeGroup.startTime
    }))
    setForm(prev => ({
      ...prev,
      INTERVAL_END: intervalTimeGroup.endTime
    }))
  }

  const [showConfirm, setConfirmShow] = useState(false)
  const handleConfirmClose = () => setConfirmShow(false)
  const handleConfirmShow = () => setConfirmShow(true)

  function handleGameType() {
    let list = []
    list.push(<option value= {99}>全部</option>)
    for (let i = 0; i < gameTypeList.length; i++) {
      list.push(<option value= {gameTypeList[i].gameID}>{gameTypeList[i].gameName}</option>)
    }    
    return list
  }

  function handleHallType() {
    let list = []
    list.push(<option value= {99}>全部</option>)
    for (let i = 0; i < hallTypeList.length; i++) {
      list.push(<option value= {hallTypeList[i].typeNum}>{hallTypeList[i].typeName}</option>)
    }
    return list
  }

  function handleRoomType() {
    let list = []
    list.push(<option value= {99}>全部</option>)
    for (let i = 0; i < roomTypeList.length; i++) {
      if (roomTypeList[i].typeNum != 1 && roomTypeList[i].typeNum <= 4)
      list.push(<option value= {roomTypeList[i].typeNum}>{roomTypeList[i].typeName}</option>)
    }
    return list
  }

  return (
    <>
      <ModalFormat
        show={showAddRevise}
        onHide={handleOnHide}
        backdrop="static"
        keyboard={false}
        size= "lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{addStatus ? '新增' : '修改'}任務活動</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <PULeftTitle>ID</PULeftTitle>
                <td>
                  <PUInput placeholder= {addStatus ? '(系統自動產生)' : form.GMGRC_ID} value= {form.GMGRC_ID}
                    disabled={true} style= {{cursor: 'not-allowed'}}
                  />
                </td>
              </tr>
              <tr>
                <PULeftTitle>遊戲</PULeftTitle>
                <td style={{display : "flex", alignItems: "center"}}>
                  <PUSelect value= {form.GAME_TYPE} onChange= {e => {handleForm(e, 'GAME_TYPE')}}>
                    {handleGameType()}
                  </PUSelect>
                  <LabelBtn>
                    <span>上傳標籤圖片</span>
                    <input type= "file" name= "file" style={{display: "none", cursor: 'pointer'}} onChange= {handleUploadGameTypeImg}/>
                  </LabelBtn>
                  <PopFrame>
                    <Avatar
                      src={form.GAME_TYPE_IMG}
                      className="rounded"
                    />
                    <NoImg style= {form.GAME_TYPE_IMG ? {display: 'none'} : {display: 'block'}}>無示意圖</NoImg>             
                  </PopFrame>
                </td>
              </tr>
              <tr>
                <PULeftTitle>廳別(若有)</PULeftTitle>
                <td style={{display : "flex", alignItems: "center"}}>
                  <PUSelect value= {form.HALL_TYPE} onChange= {e => {handleForm(e, 'HALL_TYPE')}}>
                    {handleHallType()}
                  </PUSelect>
                  <LabelBtn>
                    <span>上傳標籤圖片</span>
                    <input type= "file" name= "file" style={{display: "none", cursor: 'pointer'}} onChange= {handleUploadHallTypeImg}/>
                  </LabelBtn>
                  <PopFrame>
                    <Avatar
                      src={form.HALL_TYPE_IMG}
                      className="rounded"
                    />
                    <NoImg style= {form.HALL_TYPE_IMG ? {display: 'none'} : {display: 'block'}}>無示意圖</NoImg>
                  </PopFrame>
                </td>
              </tr>
              <tr>
                <PULeftTitle>場次</PULeftTitle>
                <td style={{display : "flex", alignItems: "center"}}>
                  <PUSelect value= {form.ROOM_TYPE} onChange= {e => {handleForm(e, 'ROOM_TYPE')}}>
                    {handleRoomType()}
                  </PUSelect>
                  <LabelBtn>
                    <span>上傳標籤圖片</span>
                    <input type= "file" name= "file" style={{display: "none", cursor: 'pointer'}} onChange= {handleUploadRoomTypeImg}/>
                  </LabelBtn>
                  <PopFrame>
                    <Avatar
                      src={form.ROOM_TYPE_IMG}
                      className="rounded"
                    />
                    <NoImg style= {form.ROOM_TYPE_IMG ? {display: 'none'} : {display: 'block'}}>無示意圖</NoImg>
                  </PopFrame>
                </td>
              </tr>
              <tr>
                <PULeftTitle>最小<br/>活動係數</PULeftTitle>
                <td>
                  <PUInput placeholder= "請輸入數字" value= {form.MIN_RATIO} onChange= {e => {handleForm(e, 'MIN_RATIO')}}/>
                  &nbsp;&nbsp;&nbsp;&nbsp;※不得低於1
                </td>
              </tr>
              <tr>
                <PULeftTitle>最大<br/>活動係數</PULeftTitle>
                <td>
                  <PUInput placeholder= "請輸入數字" value= {form.MAX_RATIO} onChange= {e => {handleForm(e, 'MAX_RATIO')}}/>
                  &nbsp;&nbsp;&nbsp;&nbsp;※不得低於1
                </td>
              </tr>
              
              <tr>
                <PULeftTitle>起始時間</PULeftTitle>
                <td>
                  <DateTimeWrapper>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label= "日期"
                        value={dateGroup.startDate}
                        onChange={(newValue) => {
                          setDateGroup(prev => ({
                            ...prev,
                            startDate: newValue
                          }))
                          handleDate()
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇日期'
                          return (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <PUInput id= "datePicker1" ref={inputRef} {...inputProps} />
                              <div id="dateBtn1">{InputProps?.endAdornment}</div>
                            </Box>
                          )
                        }}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="時間"
                        value={dateGroup.startTime}
                        onChange={(newValue) => {
                          setDateGroup(prev => ({
                            ...prev,
                            startTime: newValue
                          }))
                          handleDate()
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇時間'
                          return (
                            <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                              <PUInput id= "timePicker1" ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </TimeFormat>
                          )
                        }}
                      />
                    </LocalizationProvider>
                  </DateTimeWrapper>
                </td>
              </tr>
              <tr>
                <PULeftTitle>結束時間</PULeftTitle>
                <td>
                  <DateTimeWrapper>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label= "日期"
                        value={dateGroup.endDate}
                        onChange={(newValue) => {
                          setDateGroup(prev => ({
                            ...prev,
                            endDate: newValue
                          }))
                          handleDate()
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇日期'
                          return (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <PUInput id= "datePicker2" ref={inputRef} {...inputProps} />
                              <div id="dateBtn2">{InputProps?.endAdornment}</div>
                            </Box>
                          )
                        }}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="時間"
                        value={dateGroup.endTime}
                        onChange={(newValue) => {
                          setDateGroup(prev => ({
                            ...prev,
                            endTime: newValue
                          }))
                          handleDate()
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇時間'
                          return (
                            <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                              <PUInput id= "timePicker2" ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </TimeFormat>
                          )
                        }}
                      />
                    </LocalizationProvider>
                  </DateTimeWrapper>
                </td>
              </tr>

              <tr>
                <PULeftTitle>循環設定</PULeftTitle>
                <td>
                  <WeekGroup>
                    <input
                      type= "checkbox"
                      data-dayindex= "0"
                      value= {form.INTERVAL_WEEK ? form.INTERVAL_WEEK.split(',')[0] : 0}
                      onChange= {handleWeek}
                      checked= {form.INTERVAL_WEEK && +form.INTERVAL_WEEK.split(',')[0] === 1 ? true: false}/> 星期一
                  </WeekGroup>
                  <WeekGroup>
                    <input
                      type= "checkbox"
                      data-dayindex= "1"
                      value= {form.INTERVAL_WEEK ? form.INTERVAL_WEEK.split(',')[1] : 0}
                      onChange= {handleWeek}
                      checked= {form.INTERVAL_WEEK && +form.INTERVAL_WEEK.split(',')[1] === 1 ? true: false}/> 星期二
                  </WeekGroup>
                  <WeekGroup>
                    <input
                      type= "checkbox"
                      data-dayindex= "2"
                      value= {form.INTERVAL_WEEK ? form.INTERVAL_WEEK.split(',')[2] : 0}
                      onChange= {handleWeek}
                      checked= {form.INTERVAL_WEEK && +form.INTERVAL_WEEK.split(',')[2] === 1 ? true: false}/> 星期三
                  </WeekGroup>
                  <WeekGroup>
                    <input
                      type= "checkbox"
                      data-dayindex= "3"
                      value= {form.INTERVAL_WEEK ? form.INTERVAL_WEEK.split(',')[3] : 0}
                      onChange= {handleWeek}
                      checked= {form.INTERVAL_WEEK && +form.INTERVAL_WEEK.split(',')[3] === 1 ? true: false}/> 星期四
                  </WeekGroup>
                  <WeekGroup>
                    <input
                      type= "checkbox"
                      data-dayindex= "4"
                      value= {form.INTERVAL_WEEK ? form.INTERVAL_WEEK.split(',')[4] : 0}
                      onChange= {handleWeek}
                      checked= {form.INTERVAL_WEEK && +form.INTERVAL_WEEK.split(',')[4] === 1 ? true: false}/> 星期五
                  </WeekGroup>
                  <WeekGroup>
                    <input
                      type= "checkbox"
                      data-dayindex= "5"
                      value= {form.INTERVAL_WEEK ? form.INTERVAL_WEEK.split(',')[5] : 0}
                      onChange= {handleWeek}
                      checked= {form.INTERVAL_WEEK && +form.INTERVAL_WEEK.split(',')[5] === 1 ? true: false}/> 星期六
                  </WeekGroup>
                  <WeekGroup>
                    <input
                      type= "checkbox"
                      data-dayindex= "6"
                      value= {form.INTERVAL_WEEK ? form.INTERVAL_WEEK.split(',')[6] : 0}
                      onChange= {handleWeek}
                      checked= {form.INTERVAL_WEEK && +form.INTERVAL_WEEK.split(',')[6] === 1 ? true: false}/> 星期日
                  </WeekGroup>
                  <DateTimeWrapper>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="從"
                        value={intervalTimeGroup.startTime}
                        onChange={(newValue) => {
                          setIntervalTimeGroup(prev => ({
                            ...prev,
                            startTime: newValue
                          }))
                          handleTime()
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇時間'
                          return (
                            <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                              <TimeInput id= "timePicker3" ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </TimeFormat>
                          )
                        }}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="到"
                        value={intervalTimeGroup.endTime}
                        onChange={(newValue) => {
                          setIntervalTimeGroup(prev => ({
                            ...prev,
                            endTime: newValue
                          }))
                          handleTime()
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇時間'
                          return (
                            <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                              <TimeInput id= "timePicker3" ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </TimeFormat>
                          )
                        }}
                      />
                    </LocalizationProvider>
                  </DateTimeWrapper>
                </td>
              </tr>

              <tr>
                <PULeftTitle>開關</PULeftTitle>
                <td>
                  <PUSelect value= {form.OPEN_STATE} onChange= {e => {handleForm(e, 'OPEN_STATE')}}>
                    <option value= "1">開啟</option>
                    <option value= "0">關閉</option>
                  </PUSelect>
                </td>
              </tr>

            </tbody>
          </table>
        </Modal.Body>
        <ModalBtn>
          <Button variant="secondary" onClick= {addStatus ? handleBack : handleDelete}>{addStatus ? '取消' : '刪除'}</Button>
          <Button variant="info" onClick= {handleSubmit}>{addStatus ? '新增' : '儲存'}</Button>          
        </ModalBtn>
      </ModalFormat>
      
      <DeletePU
        showConfirm= {showConfirm}
        handleAddReviseShow= {handleAddReviseShow}
        handleConfirmClose= {handleConfirmClose}
        addStatus= {addStatus}
        form= {form}
        setPanelInfo= {setPanelInfo}
        setPopData= {setPopData}
        cleanData = {cleanData}
      />
    </>
  )
}