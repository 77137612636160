import React from "react"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { ModalBtn, ModalText } from "../../../middleware/utilityStyle"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { deleteCategory, itemCategory, deleteDetailList, detailList } from "../../../middleware/Api/operation/itemSettingApi"

export default function DeleteHint(props) {
  const {
    showDeleteHint,
    handleDeleteHintClose,
    chosenTarget,
    setDataCopy,
    setCategoryInfo,
    deleteDetail,
    targetInfo,
    queryValue,
    setDetailCopy,
    setDetailInfo
  } = props

  const handleCancel = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleDeleteHintClose()
    })
  }

  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!deleteDetail) {
        deleteCategory(+chosenTarget.CLASS_ID, chosenTarget.CLASS_NAME).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        handleDeleteHintClose()
        itemCategory().then(db => {
          if (db.error === errorCode.NULL_DATA.code) {
            alert(`※  ${errorCode.NULL_DATA.memo}`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          setDataCopy(db.data)
          setCategoryInfo(db.data)
        })
      })
      }
      
      if (deleteDetail) {
        deleteDetailList(+targetInfo.ITEM_ID, targetInfo.ITEM_NAME).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          handleDeleteHintClose()

          detailList(+queryValue.CLASS_ID).then(db => {
            if (db.error === errorCode.INCORRECT_DATA.code) {
              alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.INCORRECT_FORMAT.code) {
              alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.NULL_DATA.code) {
              alert(`※  ${errorCode.NULL_DATA.memo}`)
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            setDetailCopy(db.data)
            setDetailInfo(db.data)
          })
        })
      }
    })
  }
  return (
    <>
      <Modal
        show={showDeleteHint}
        onHide={handleDeleteHintClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>刪除提示</Modal.Title>
        </Modal.Header>
        <ModalText>
          {deleteDetail ? '刪除後資料不保留，是否進行刪除?' : '刪除商品分類後，商品設定內容也會刪除，是否進行刪除?'}
        </ModalText>
        <ModalBtn>
          <Button variant="secondary" onClick={handleCancel}>取消</Button>
          <Button variant="danger" onClick= {handleDelete}>刪除</Button>
        </ModalBtn>
      </Modal>
    </>
  )
}