import React, { useEffect, useState } from "react"
import Modal from 'react-bootstrap/Modal'
import { Button } from "react-bootstrap"
import { reviseAuth } from "../../../middleware/Api/roleApi"
import { getAllAuth } from "../../../middleware/Api/publicApi"
import { BasicTable, BasicRowTitle, ModalFormat, ModalBtn } from "../../../middleware/utilityStyle"
import { PopupName } from "../../../views/BTNroleSetting/styledComponent"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { getAccessArr } from "../../../middleware/utils"

export default function HandleAuth(props) {
  const {
    checkboxValue,
    setCheckboxValue,
    showAuthSetting,
    handleAuthSettingClose,
    popData,
    history,
    radioValue
  } = props

  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })

  const [allAuth, setAllAuth] = useState([])
  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      getAllAuth().then(db => {
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setAllAuth(db.data)
      })
    })
  }, [checkboxValue, radioValue])

  const handleCheckbox = e => {
    if (e.target.checked) {
      if (checkboxValue.find(value => value === e.target.value)) return
      setCheckboxValue([...checkboxValue, +e.target.value])
    } else {
      setCheckboxValue(checkboxValue.filter(value => value !== +e.target.value))
    }
  }

  const handleAuth = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 13)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      reviseAuth(popData.characterID, checkboxValue).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        handleAuthSettingClose()
      })
    })
  }
  return (
    <>
      <ModalFormat
        show={showAuthSetting}
        onHide={handleAuthSettingClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>權限設定</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PopupName>角色名稱: {popData.characterName}</PopupName>
          <BasicTable className="table table-hover table-bordered">
            <thead>
              <BasicRowTitle>
                <th scope="col">
                  <input type="checkbox" id="flexCheckChecked" disabled />
                </th>
                <th scope="col">權限值</th>
                <th scope="col">權限名稱</th>
                <th scope="col">權限描述</th>
              </BasicRowTitle>
            </thead>
            <tbody>
              {
                Array.isArray(allAuth) && allAuth.length ?
                  allAuth.map(data => {
                    return (
                      <tr key={data.ID}>
                        <th scope="row">
                          <input
                            type="checkbox"
                            name="authList"
                            value={data.ID}
                            onChange={handleCheckbox}
                            checked={checkboxValue.find(ele => +ele === +data.ID) || 0}
                          />
                        </th>
                        <td>{data.ID}</td>
                        <td>{data.accessName}</td>
                        <td>{data.description}</td>
                      </tr>
                    )
                  })
                  : <tr key= "noInfo"><td colSpan= "4">查無資料</td></tr>
              }
            </tbody>
            <tfoot></tfoot>
          </BasicTable>
        </Modal.Body>
        <ModalBtn>
          <Button variant="info" onClick={handleAuth}>確定</Button>
          <Button variant="light" onClick={handleAuthSettingClose}>取消</Button>
        </ModalBtn>
      </ModalFormat>
    </>
  )
}