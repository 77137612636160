import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import {
  Title,
  RadioWrapper,
  FirstRadio,
  SecondRadio,
  BtnWrapper,
  SubmitBtn,
  ResetButton
} from "./styledComponent"
import HandleSubmit from "../../../components/popUpWindow/textSending/handleSubmit"
import { sessionVerified } from "../../../middleware/Api/publicApi"
import errorCode from "../../../assets/config/errorCode.json"

export default function TextSending() {
  const history = useHistory()
  const [change, setChange] = useState({
    toAll: '',
    phoneNum: '',
    content: ''
  })

  const [phoneList, setPhoneList] = useState([])
  const [showSubmit, setSubmitShow] = useState(false)
  const handleSubmitClose = () => {
    setSubmitShow(false)
    setPhoneList([])
  }
  const handleSubmitShow = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!+change.toAll) {
        if (!change.phoneNum) {
          alert('請輸入手機號碼⊙ w ⊙')
          return
        }
        if (change.phoneNum) {
          let pArr = change.phoneNum.split(',')
          let rightLength = e => Number(e).toString().length === 9
          if (!pArr.every(rightLength)) {
            alert('請確認號碼是否正確、是否包含空白字元或是多餘的標點符號⊙ w ⊙')
            return
          } else {
            let rule = e => e.match(/^09[0-9]{8}$/)
            let TArr = []
            pArr.forEach(ele => {
              TArr.push(ele.slice(ele.indexOf('0'), ele.indexOf('0')+10))
            })
            if (!TArr.every(rule)) {
              alert('請確認號碼是否正確、是否包含空白字元或是多餘的標點符號⊙ w ⊙')
              return
            } else {
              setPhoneList(TArr)
            }
          }
        }
      }

      if (!change.content) {
        alert(('請輸入簡訊內容⊙ w ⊙'))
        return
      }
      setSubmitShow(true)
    })
  }

  const handleChange = (e, key) => {
    setChange(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({toAll : '', phoneNum: '', content: ''})
    })
  }

  const handleCleanPNum = () => {
    setChange(prev => ({
      ...prev,
      phoneNum: ''
    }))
  }
  return (
    <>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <Title className="table-active">手機號碼</Title>
            <td>
              <RadioWrapper>
                <FirstRadio>
                  <input
                    className="form-check-input"
                    type="radio"
                    value= '1'
                    onChange= {e => {handleChange(e, 'toAll')}}
                    checked= {+change.toAll ? true : false}
                    onClick= {handleCleanPNum}
                  />全部玩家
                </FirstRadio>
                <SecondRadio>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="toAll"
                    value= '0'
                    onChange= {e => {handleChange(e, 'toAll')}}
                    checked= {!+change.toAll ? true : false}
                  />特定用戶
                </SecondRadio>
              </RadioWrapper>
              <div>
              <textarea
                className= "form-control"
                value= {change.phoneNum}
                rows="10"
                placeholder= "請輸入要發送簡訊的電話號碼(勿使用空白字元)，並用逗號隔開。"
                onChange= {e => {handleChange(e, 'phoneNum')}}
                style= {+change.toAll ? {display: 'none'} : {display: 'block'}}
                />
              </div>
            </td>
          </tr>

          <tr>
            <Title className="table-active">簡訊內容</Title>
            <td>
              <div>
                <textarea
                  className= "form-control"
                  value= {change.content}
                  rows="10"
                  placeholder= "請輸入要發送簡訊的內容。"
                  onChange= {e => {handleChange(e, 'content')}}
                  />
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <BtnWrapper>
        <SubmitBtn id= "submitBtn" onClick= {handleSubmitShow}>發送</SubmitBtn>
        <ResetButton id= "resetBtn" onClick= {handleReset}>重置</ResetButton>
      </BtnWrapper>

      <HandleSubmit
        showSubmit= {showSubmit}
        handleSubmitClose= {handleSubmitClose}
        change= {change}
        setChange= {setChange}
        phoneList= {phoneList}
      />
    </>
  )
}