import React from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { ModalFormat, ModalText, ModalBtn } from "../../../middleware/utilityStyle"
import { addPrizeLimit, revisePrizeLimit } from "../../../middleware/Api/eventManagement/prizeLimitApi"

export default function ConfirmPU(props) {
  const {
    showConfirm,
    handleAddReviseShow,
    handleConfirmClose,
    addStatus,
    form,
    setForm,
    setPopData,
    setPanelInfo
  } = props
  const history = useHistory()
  const handleSubmit = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      const { AAC_ID, ACTIVITY_ID, AWARD_TYPE, AWARD_NUM, TOTAL_LIMIT, TOTAL_NUM, DAILY_LIMIT, DAILY_NUM,ITEM_ID} = form
      if (addStatus) {
        addPrizeLimit(ACTIVITY_ID, AWARD_TYPE, AWARD_NUM, TOTAL_LIMIT, TOTAL_NUM, DAILY_LIMIT, DAILY_NUM,ITEM_ID).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          if (db.error === errorCode.SERVER_ERROR.code) {
            alert(`※  ${errorCode.SERVER_ERROR.memo}`)
            return
          }
        })
      }
      if (!addStatus) {
        revisePrizeLimit(AAC_ID, ACTIVITY_ID, AWARD_TYPE, AWARD_NUM, TOTAL_LIMIT, TOTAL_NUM, DAILY_LIMIT, DAILY_NUM,ITEM_ID).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          if (db.error === errorCode.SERVER_ERROR.code) {
            alert(`※  ${errorCode.SERVER_ERROR.memo}`)
            return
          }
        })
      }
      setPanelInfo([])
      setPopData({})
      handleConfirmClose()
    })
  }

  const handleBack = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleConfirmClose()
      handleAddReviseShow()
    })
  }
  return (
    <>
      <ModalFormat
        show={showConfirm}
        onHide={handleConfirmClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{addStatus ? '新增' : '修改'}確認</Modal.Title>
        </Modal.Header>
        <ModalText>
          資料無誤，確定{addStatus ? '新增' : '修改'}?
        </ModalText>
        <ModalBtn>
          <Button variant="info" onClick= {handleSubmit}>確定</Button>
          <Button variant="secondary" onClick= {handleBack}>返回</Button>
        </ModalBtn>
      </ModalFormat>
    </>
  )
}