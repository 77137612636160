import Modal from 'react-bootstrap/Modal'
import React from "react"
import { Button } from "react-bootstrap"
import { getAllAcc } from "../../../middleware/Api/publicApi"
import { reviseAcc } from "../../../middleware/Api/accountApi"
import { BigSquareTitle, ModalBtn } from "../../../middleware/utilityStyle"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"

export default function ReviseAcc(props) {
  const {
    setPanelInfo,
    characterIdList,
    data,
    show,
    setShow,
    change,
    handleChange,
    history,
    setAllAccount
  } = props

  const { userID, account, userName } = data
  const handleReviseClose = () => setShow(false)
  const handleRevise = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      reviseAcc(userID, +change.reviseAuth).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        getAllAcc().then(db => {
          if (db.error === errorCode.NULL_DATA.code) {
            alert(`※  ${errorCode.NULL_DATA.memo}`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          setPanelInfo(db.data)
          setAllAccount(db.data)
          handleReviseClose()
        })
      })
    })
  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleReviseClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>修改帳號</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <BigSquareTitle>帳號</BigSquareTitle>
                <td>
                  {account}
                </td>
              </tr>
              <tr>
                <BigSquareTitle>姓名</BigSquareTitle>
                <td>
                  {userName}
                </td>
              </tr>
              <tr>
                <BigSquareTitle>角色</BigSquareTitle>
                <td>
                  <select className="btn dropdown-toggle" value={change.reviseAuth} onChange={e => {handleChange(e, 'reviseAuth')}}>
                    {
                      Array.isArray(characterIdList) && characterIdList.length > 0?
                        characterIdList.map((obj, index) => {
                          return (
                            <option className="dropdown-item" key={index} value={obj.characterID}>{obj.characterName}</option>
                          )
                        })
                        : ''
                    }
                  </select>
                </td>
              </tr>
              </tbody>
          </table>
        </Modal.Body>
        <ModalBtn>
          <Button variant="info" onClick={handleRevise}>確定</Button>
          <Button variant="light" onClick={handleReviseClose}>返回</Button>
        </ModalBtn>
      </Modal>
    </>
  )
}