import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker'
import { today, yesterday, thisWeek, lastWeek, thisMonth, defaultDate, defaultDateE } from "../../../middleware/dateUtils"
import { getUserList } from "../../../middleware/Api/playerInfo/userListApi"
import {
  InputBarBg,
  InputBarRWD,
  FirstInputBar,
  InputBar,
  SignUpWrapper,
  LastGameWrapper,
  DatePickerInput,
  RangeGroup,
  NavRTwo,
  Input,
  BtnGroup,
  Frame,
  Avatar
} from './styledComponent'

import {
  DateWrapper,
  DateTitle,
  DateStack,
  ClickRange,
  SearchButton,
  ResetButton,
  BasicTable,
  BasicRowTitle,
  TableContent,
  PageStyle
} from "../../../middleware/utilityStyle.js"

export default function UserList() {
  const history = useHistory()
  const [panelInfo, setPanelInfo] = useState([])
  const [change, setChange] = useState({
    id: '',
    nickname: '',
    upLine: '',
    regD: [new Date(defaultDate), new Date(defaultDateE)],
    lastGameD: [new Date(defaultDate), new Date(defaultDateE)]
  })
  const [pageCounter, setPageCounter] = useState(0)  //最大頁數
  const handleChange = e => {
    let newObj = Object.assign({}, change)
    newObj[e.target.name] = e.target.value
    setChange(newObj)
  }

  const [page, setPage] = React.useState(1)
  const handleChangePage = (event, newPage) => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPage(newPage)
    })
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      let id = 0
      if (change.id) {
        if(isNaN(+change.id)) {
          alert('※ 用戶ID請輸入數字')
          return
        } else {
          id = +change.id
        }
      }

      let nickname = 'all'
      if (change.nickname !== '') {
        nickname = change.nickname
      }

      let upLine = 0
      if (isNaN(+change.upLine)) {
        alert('※ 推廣員欄位請輸入數字')
        return
      } else {
        upLine = +change.upLine
      }

      let regSD = ''
      let regED = ''
      if (change.regD[0] === null && change.regD[1] === null) {
        regSD = new Date(defaultDate).toISOString()
        regED = new Date(defaultDateE).toISOString()
      } else if (change.regD[0] && change.regD[1]) {
        regSD = new Date(change.regD[0]).toISOString()
        let end = new Date(change.regD[1]).toString()
        regED = new Date(end.replace('00:00:00', '23:59:59')).toISOString()
      } else {
        alert('※ 請輸入完整註冊時間')
        return
      }

      let lastGameSD = ''
      let lastGameED = ''

      if (change.lastGameD[0] === null && change.lastGameD[1] === null) {
        lastGameSD = new Date(defaultDate).toISOString()
        lastGameED = new Date(defaultDateE).toISOString()
      } else if (change.lastGameD[0] && change.lastGameD[1]) {
        lastGameSD = new Date(change.lastGameD[0]).toISOString()
        let endLG = new Date(change.lastGameD[1]).toString()
        lastGameED = new Date(endLG.replace('00:00:00', '23:59:59')).toISOString()
      } else {
        alert('※ 請輸入完整最後遊戲時間')
        return
      }
      getUserList(id, nickname, upLine, regSD, regED, lastGameSD, lastGameED).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          getUserList(0, 'all', 0, new Date(defaultDate).toISOString(), new Date(defaultDateE).toISOString(), new Date(defaultDate).toISOString(), new Date(defaultDateE).toISOString()).then(db => {
            if (db.error === errorCode.INCORRECT_DATA.code) {
              alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.INCORRECT_FORMAT.code) {
              alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.NULL_DATA.code) {
              alert(`※  ${errorCode.RECORD_NOT_FOUND.memo}`)
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            setPage(1)
            let data = []
            for (var i = 0; i < db.data.length; i += 30) {
              data.push(db.data.slice(i, i + 30))
            }
            setPageCounter(data.length)
            setPanelInfo(data)
          })
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setPage(1)
        let data = []
        for (var i = 0; i < db.data.length; i += 30) {
          data.push(db.data.slice(i, i + 30))
        }
        setPageCounter(data.length)
        setPanelInfo(data)
      })
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({
        id: '',
        nickname: '',
        upLine: '',
        regD: [new Date(defaultDate), new Date(defaultDateE)],
        lastGameD: [new Date(defaultDate), new Date(defaultDateE)]
      })
    })
  }
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <InputBarRWD>
          <FirstInputBar>
            用戶ID<Input placeholder="請輸入欲查詢ID" name="id" value={change.id} onChange={handleChange} />
          </FirstInputBar>
          <InputBar>
            暱稱
            <Input placeholder="請輸入欲查詢暱稱" name="nickname" value={change.nickname} onChange={handleChange} />
          </InputBar>
          <InputBar>
            推廣員
            <Input placeholder="請輸入推廣員ID" name="upLine" value={change.upLine} onChange={handleChange} />
          </InputBar>
          <SignUpWrapper>
            <DateWrapper>
              <DateTitle>註冊時間</DateTitle>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateStack spacing={3}>
                  <DesktopDateRangePicker
                    startText=""
                    endText=""
                    value={change.regD}
                    onChange={(newValue) => {
                      setChange(prev => ({
                        ...prev,
                        regD: newValue
                      }))
                    }}
                    renderInput={(startProps, endProps) => {
                      startProps.inputProps.placeholder = '請輸入日期'
                      endProps.inputProps.placeholder = '請輸入日期'
                      return (
                        <React.Fragment>
                          <DatePickerInput ref={startProps.inputRef} {...startProps.inputProps} />
                          <Box sx={{ mx: 1 }}>至</Box>
                          <DatePickerInput ref={endProps.inputRef} {...endProps.inputProps} />
                        </React.Fragment>
                      )
                    }}
                  />
                </DateStack>
              </LocalizationProvider>
            </DateWrapper>
            <RangeGroup>
              <ClickRange
                onClick= {() => {
                  setChange(prev => ({
                    ...prev,
                      regD: today
                  }))
                }}>今天
              </ClickRange>
              <ClickRange
                onClick= {() => {
                  setChange(prev => ({
                    ...prev,
                      regD: yesterday
                  }))
                }}>昨天
              </ClickRange>
              <ClickRange
                onClick= {() => {
                  setChange(prev => ({
                    ...prev,
                    regD: thisWeek
                  }))
                }}>本周
              </ClickRange>
              <ClickRange
                onClick= {() => {
                  setChange(prev => ({
                    ...prev,
                      regD: lastWeek
                  }))
                }}>上周
              </ClickRange>
              <ClickRange
                onClick= {() => {
                  setChange(prev => ({
                    ...prev,
                      regD: thisMonth
                  }))
                }}>本月
              </ClickRange>
            </RangeGroup>
          </SignUpWrapper>
        </InputBarRWD>
        
        <NavRTwo>
          <LastGameWrapper>
            <DateWrapper>
              <DateTitle>最後遊戲時間</DateTitle>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateStack spacing={3}>
                  <DesktopDateRangePicker
                    startText=""
                    endText=""
                    value={change.lastGameD}
                    onChange={(newValue) => {
                      setChange(prev => ({
                        ...prev,
                        lastGameD: newValue
                      }))
                    }}
                    renderInput={(startProps, endProps) => {
                      startProps.inputProps.placeholder = '請輸入日期'
                      endProps.inputProps.placeholder = '請輸入日期'
                      return (
                        <React.Fragment>
                          <DatePickerInput ref={startProps.inputRef} {...startProps.inputProps} />
                          <Box sx={{ mx: 1 }}>至</Box>
                          <DatePickerInput ref={endProps.inputRef} {...endProps.inputProps} />
                        </React.Fragment>
                      )
                    }}
                  />
                </DateStack>
              </LocalizationProvider>
            </DateWrapper>
            <RangeGroup>
              <ClickRange onClick={() => {
                setChange(prev => ({
                  ...prev,
                  lastGameD: today
                }))
              }}>今天</ClickRange>
              <ClickRange
                onClick={() => {
                  setChange(prev => ({
                    ...prev,
                    lastGameD: yesterday
                  }))
                }}>昨天
              </ClickRange>
              <ClickRange
                onClick={() => {
                  setChange(prev => ({
                    ...prev,
                    lastGameD: thisWeek
                  }))
                }}>本周
              </ClickRange>
              <ClickRange
                onClick={() => {
                  setChange(prev => ({
                    ...prev,
                    lastGameD: lastWeek
                  }))
                }}>上周
              </ClickRange>
              <ClickRange
                onClick={() => {
                  setChange(prev => ({
                    ...prev,
                    lastGameD: thisMonth
                  }))
                }}>本月
              </ClickRange>
            </RangeGroup>
          </LastGameWrapper>
          <BtnGroup>
            <SearchButton onClick={handleSearch}>搜索</SearchButton>
            <ResetButton onClick={handleReset}>重置</ResetButton>
          </BtnGroup> 
        </NavRTwo>
      </InputBarBg>

      <BasicTable className="table table-hover table-bordered">
        <thead>
          <BasicRowTitle>
            <th scope="col">用戶頭像</th>
            <th scope="col">用戶暱稱(ID)</th>
            <th scope="col">金幣</th>
            <th scope="col">鬥陣寶</th>
            <th scope="col">遊戲局數</th>
            <th scope="col">充值(元)</th>
            <th scope="col">是否在線</th>
            <th scope="col">最後遊戲時間</th>
            <th scope="col">推廣員</th>
            <th scope="col">註冊時間</th>
          </BasicRowTitle>
        </thead>
        <tbody>
          {
            Array.isArray(panelInfo) && panelInfo.length ?
              panelInfo[page-1].map((EachData, index) => {
                return (
                  <TableContent key= {index}>
                    <Frame>
                      <Avatar src={EachData.USER_IMG_URL ? EachData.USER_IMG_URL : '---'} className="rounded" alt="頭像" />
                    </Frame>
                    <td>{`${EachData.NICKNAME}(${EachData.UID})`}</td>
                    <td>{EachData.GOLD}</td>
                    <td>{EachData.GEM}</td>
                    <td>{EachData.TOTAL_GAME_NUM}</td>
                    <td>{EachData.TOTAL_INFULL_NUM}</td>
                    <td>{EachData.ONLINE ? '是' : '否'}</td>
                    <td>{new Date(EachData.LAST_GAME_DATE).toLocaleString()}</td>
                    <td>{EachData.UP_LINE}</td>
                    <td>{new Date(EachData.REG_DATE).toLocaleString()}</td>
                  </TableContent>
                )
              })
              : <tr key= "noInfo"><td colSpan= "10">尚無資料</td></tr>
          }
        </tbody>
        <tfoot></tfoot>
      </BasicTable>

      {
        Array.isArray(panelInfo) && panelInfo.length ?
          <Stack spacing={2}>
            <PageStyle count={pageCounter}
              color="warning"
              page= {page}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </Stack>
        : ''
      }
    </>
  )
}