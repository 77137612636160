import React from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { ModalFormat, ModalText, ModalBtn } from "../../../middleware/utilityStyle"
import { addActivity, reviseActivity } from "../../../middleware/Api/eventManagement/eventSettingApi.js"

export default function ConfirmPU(props) {
  const {
    showConfirm,
    handleAddReviseShow,
    handleConfirmClose,
    addStatus,
    form,
    setPanelInfo,
    setPopData,
    setForm,
    setDateGroup,
    setChargeDateGroup,
    cleanData
  } = props
  const history = useHistory()
  const {
    ACTIVITY_ID,
    ACTIVITY_TYPE,
    ACTIVITY_NAME,
    START_DATE,
    END_DATE,
    STATE,
    BAR_START,
    BAR_END,
    BAR_STATE,
    BAR_TARGET_NUM,
    IF_RESET,
    BAR_REWARD
  } = form
  
  const handleSubmit = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (addStatus) {
        addActivity(+ACTIVITY_ID, +ACTIVITY_TYPE, ACTIVITY_NAME, START_DATE, END_DATE, +STATE, BAR_START,
          BAR_END, +BAR_STATE, +BAR_TARGET_NUM, +IF_RESET, +BAR_REWARD).then(db => {
            if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          if (db.error === errorCode.SERVER_ERROR.code) {
            alert(`※  ${errorCode.SERVER_ERROR.memo}`)
            return
          }
          if (db.error === errorCode.NULL_DATA.code) {
            alert(`※  ${errorCode.NULL_DATA.memo}`)
            return
          }
          if (db.error === errorCode.REPEAT_DATA.code) {
            alert(`※  活動ID不可重複`)
            return
          }
          setPanelInfo([])
        })
      }
      if (!addStatus) {
        reviseActivity(+ACTIVITY_ID, +ACTIVITY_TYPE, ACTIVITY_NAME, START_DATE, END_DATE, +STATE, BAR_START,
          BAR_END, +BAR_STATE, +BAR_TARGET_NUM, +IF_RESET, +BAR_REWARD).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          if (db.error === errorCode.SERVER_ERROR.code) {
            alert(`※  ${errorCode.SERVER_ERROR.memo}`)
            return
          }
          setPanelInfo([])
          setPopData({})
        })
      }
      cleanData()
      handleConfirmClose()
    })
  }

  const handleBack = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v 。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v 。`)
        history.push("/login")
        return
      }
      handleConfirmClose()
      handleAddReviseShow()
    })
  }
  return (
    <>
      <ModalFormat
        show={showConfirm}
        onHide={handleConfirmClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{addStatus ? '新增' : '修改'}確認</Modal.Title>
        </Modal.Header>
        <ModalText>
          資料無誤，確定{addStatus ? '新增' : '修改'}?
        </ModalText>
        <ModalBtn>
          <Button variant="info" onClick= {handleSubmit}>確定</Button>
          <Button variant="secondary" onClick= {handleBack}>返回</Button>
        </ModalBtn>
      </ModalFormat>
    </>
  )
}