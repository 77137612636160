import React, { useState, useEffect }from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { PUInputBar, Title, Input, PUSelect, PUInput, TopTitle} from "../../../views/operation/gameIdSetting/styledComponent.js"
import { ModalFormat, ModalBtn, BasicInput } from "../../../middleware/utilityStyle.js"
import { getGameListDetail, updateQuickMatch } from "../../../middleware/Api/operation/gameIdSettingApi"

export default function QuickMatch (props) {
    const {
        showQuickMatch,
        handleQuickMatchClose,
        editData,
        edit,
        setEdit,
        setOpenState,
        panelInfo
    } = props
    const history = useHistory()

    useEffect(() => {
      getGameListDetail().then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }

        if (Array.isArray(db.data) && db.data.length) {
          for (let obj of db.data) {
            handleOpenState(obj.QUICK_START_STATE, obj.GAME_ID)
          }
          for (let obj of db.data) {
            if (obj.QUICK_START_CONFIG === "") {
              updateQuickMatch(+obj.GAME_ID, +form.openState, form.quickMatch, +form.time)
            }
          }

          let game = db.data.find((obj) => obj.GAME_ID === editData.GAME_ID)
          game.quickMatch = JSON.parse(game.QUICK_START_CONFIG)
          game.time = game.SWITCH_INTERVAL
          game.openState = game.QUICK_START_STATE
          setForm(game)
        }
      })
    }, [edit, panelInfo])

    const [form, setForm] = useState({
      quickMatch: {
        LSwitch: 1,LMin: 0,LAI: 0,LMax: 0,LWeight: 1,
        BSwitch: 1,BMin: 0,BAI: 0,BMax: 0,BWeight: 1,
        MSwitch: 1,MMin: 0,MAI: 0,MMax: 0,MWeight: 1,
        HSwitch: 1,HMin: 0,HAI: 0,HMax: 0,HWeight: 1,
      },
      time: 5,
      openState: 1
    })

    const handleOpenState = (value, key) => {
      setOpenState(prev => ({
        ...prev,
        [key]: value
      }))
    }

    const handleForm = (e, key) => {
      setForm(prev => ({
        ...prev,
        [key]: isNaN(e.target.value) ? form[key]: +e.target.value,
        quickMatch: {
          ...prev.quickMatch,
          [key]: isNaN(e.target.value) ? form.quickMatch[key]: +e.target.value
        } 
      }))
    }
    
    const handleSend = () => {
      sessionVerified().then(db => {
        if (db.error === errorCode.CODE_NOT_MATCH.code) {
            alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
            history.push("/login")
            return
            }
        if (db.error === errorCode.SESSION_EXPIRED.code) {
            alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
            history.push("/login")
            return
        }

        updateQuickMatch(+editData.GAME_ID, +form.openState, form.quickMatch, +form.time).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          
          if (Array.isArray(db.data) && db.data[0].RESULT === 1) {
            alert("修改速配設定成功 !")
            return
          }
        })
      })
      handleBack()
    }

    const handleBack = () => {
        sessionVerified().then(db => {
            if (db.error === errorCode.CODE_NOT_MATCH.code) {
                alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
                history.push("/login")
                return
                }
            if (db.error === errorCode.SESSION_EXPIRED.code) {
                alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
                history.push("/login")
                return
            }
            setEdit(0)
            handleQuickMatchClose()
        })
    }

    return (
        <>
        <ModalFormat
            show={showQuickMatch}
            onHide={handleBack}
            backdrop="static"
            keyboard={false}
            size= "lg"
        >
            <Modal.Header closeButton>
            <TopTitle>快速配對設定內容</TopTitle>
            </Modal.Header>
            <Modal.Body>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <Title className="table-active">遊戲ID</Title>
                  <td>{editData.GAME_ID}</td>
                </tr>

                <tr>
                  <Title className="table-active">遊戲名稱</Title>
                  <td>{editData.GAME_NAME}</td>
                </tr>

                <tr>
                <Title className="table-active">休閒場設定</Title>
                  <td>
                    <div>
                      <span>開關
                        <PUSelect style={{marginLeft: "35px"}} value= {form.quickMatch.LSwitch} onChange={e => handleForm(e, 'LSwitch')}>
                          <option value="1">開啟</option>
                          <option value="0">關閉</option>
                        </PUSelect>
                      </span>
                      <span>最低持有財產限制
                        <PUInput type="text" placeholder="請輸入金額" value={form.quickMatch.LMin} onChange={e => handleForm(e, 'LMin')}/>
                      </span>
                    </div>

                    <div>
                      <span>AI判定
                        <PUSelect value= {form.quickMatch.LAI} onChange={e => handleForm(e, 'LAI')}>
                          <option value="1">開啟</option>
                          <option value="0">關閉</option>
                        </PUSelect>
                      </span>
                    <span>最高持有財產限制
                      <PUInput type="text" placeholder="請輸入金額" value={form.quickMatch.LMax} onChange={e => handleForm(e, 'LMax')}/>
                    </span>
                    </div>
                    <span>權重比
                      <PUInput type="text" placeholder="請輸入權重" value={form.quickMatch.LWeight} onChange={e => handleForm(e, 'LWeight')}/>
                    </span>
                  </td>
                </tr>

                <tr>
                <Title className="table-active">初級場設定</Title>
                  <td>
                    <div>
                      <span>開關
                        <PUSelect style={{marginLeft: "35px"}} value= {form.quickMatch.BSwitch} onChange={e => handleForm(e, 'BSwitch')}>
                          <option value="1">開啟</option>
                          <option value="0">關閉</option>
                        </PUSelect>
                      </span>
                    <span>最低持有財產限制
                      <PUInput type="text" placeholder="請輸入金額" value={form.quickMatch.BMin} onChange={e => handleForm(e, 'BMin')}/>
                    </span>
                    </div>

                    <div>
                      <span>AI判定
                        <PUSelect value= {form.quickMatch.BAI} onChange={e => handleForm(e, 'BAI')}>
                          <option value="1">開啟</option>
                          <option value="0">關閉</option>
                        </PUSelect>
                      </span>
                    <span>最高持有財產限制
                      <PUInput type="text" placeholder="請輸入金額" value={form.quickMatch.BMax} onChange={e => handleForm(e, 'BMax')}/>
                    </span>
                    </div>
                    <span>權重比
                      <PUInput type="text" placeholder="請輸入權重" value={form.quickMatch.BWeight} onChange={e => handleForm(e, 'BWeight')}/>
                    </span>
                  </td>
                </tr>
                <tr>
                <Title className="table-active">中級場設定</Title>
                  <td>
                    <div>
                      <span>開關
                        <PUSelect style={{marginLeft: "35px"}} value={form.quickMatch.MSwitch} onChange={e => handleForm(e, 'MSwitch')}>
                          <option value="1">開啟</option>
                          <option value="0">關閉</option>
                        </PUSelect>
                      </span>
                    <span>最低持有財產限制
                      <PUInput type="text" placeholder="請輸入金額" value={form.quickMatch.MMin} onChange={e => handleForm(e, 'MMin')}/>
                    </span>
                    </div>

                    <div>
                      <span>AI判定
                        <PUSelect value={form.quickMatch.MAI} onChange={e => handleForm(e, 'MAI')}>
                          <option value="1">開啟</option>
                          <option value="0">關閉</option>
                        </PUSelect>
                      </span>
                    <span>最高持有財產限制
                      <PUInput type="text" placeholder="請輸入金額" value={form.quickMatch.MMax} onChange={e => handleForm(e, 'MMax')}/>
                    </span>
                    </div>
                    <span>權重比
                      <PUInput type="text" placeholder="請輸入權重" value={form.quickMatch.MWeight} onChange={e => handleForm(e, 'MWeight')}/>
                    </span>
                  </td>
                </tr>
                <tr>
                <Title className="table-active">高級場設定</Title>
                  <td>
                    <div>
                      <span>開關
                        <PUSelect style={{marginLeft: "35px"}} value={form.quickMatch.HSwitch} onChange={e => handleForm(e, 'HSwitch')}>
                          <option value="1">開啟</option>
                          <option value="0">關閉</option>
                        </PUSelect>
                      </span>
                    <span>最低持有財產限制
                      <PUInput type="text" placeholder="請輸入金額" value={form.quickMatch.HMin} onChange={e => handleForm(e, 'HMin')}/>
                    </span>
                    </div>

                    <div>
                      <span>AI判定
                        <PUSelect value={form.quickMatch.HAI} onChange={e => handleForm(e, 'HAI')}>
                          <option value="1">開啟</option>
                          <option value="0">關閉</option>
                        </PUSelect>
                      </span>
                    <span>最高持有財產限制
                      <PUInput type="text" placeholder="請輸入金額" value={form.quickMatch.HMax} onChange={e => handleForm(e, 'HMax')}/>
                    </span>
                    </div>
                    <span>權重比
                      <PUInput type="text" placeholder="請輸入權重" value={form.quickMatch.HWeight} onChange={e => handleForm(e, 'HWeight')}/>
                    </span>
                  </td>
                </tr>
                <tr>
                  <Title className="table-active">切換時間間隔</Title>
                <td>
                  <PUInput style={{margin: "0"}} type="text" placeholder="請輸入秒數" value={form.time} onChange={e => handleForm(e, 'time')}/>
                </td>
                </tr>
                <tr>
                  <Title className="table-active">快速配對功能</Title>
                  <td>
                    <PUSelect style={{margin: "0"}} value={form.openState} onChange={e => {handleForm(e, 'openState')}}>
                          <option value="1">開啟</option>
                          <option value="0">關閉</option>
                    </PUSelect>
                  </td>
                </tr>
              </tbody>
            </table>
            </Modal.Body>
            <ModalBtn>
            <Button variant="info" onClick={handleSend}>確認</Button>
            <Button variant="secondary" onClick= {handleBack}>返回</Button>
            </ModalBtn>
        </ModalFormat>
        </>
    )
}