import React from "react"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { ModalBtn, ModalText } from "../../../middleware/utilityStyle"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { getDZBMissionEventList, deleteDZBMissionEvent } from "../../../middleware/Api/eventManagement/dzbMissionEventApi"

export default function DeleteHint(props) {
  const {
    showDeleteHint,
    handleDeleteHintClose,
    targetInfo,
    setTargetInfo,
    calenderInfo,
    setCalenderInfo,
    handleCleanPopData,
    setPage,
    setPageCounter,
    setPanelInfo,
    setDataCopy
  } = props

  const handleCancel = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleDeleteHintClose()
      handleCleanPopData()
    })
  }

  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      deleteDZBMissionEvent(+targetInfo.ACTIVITY_ID).then(db => {
        handleDeleteHintClose()
        setTimeout(() => {
          handleCleanPopData()
          setTargetInfo({
            ACTIVITY_ID: '',
            ACTIVITY_NAME: '',
            START_DATE: null,
            END_DATE: null,
            CLOSE_DATE: null,
            GAME_ID: '',
            GEM_MISSION_STATE: 0,
            AD_RESET_TIMES: '',
            GEM_MISSION_TEXT: '',
            REACH_TIMES: '',
            SEND_GEM_NUM: ''
          })
          let newCalenderInfo = (JSON.parse(JSON.stringify(calenderInfo)))
          for (let key in newCalenderInfo) {
            newCalenderInfo[key] = null
          }
          setCalenderInfo(newCalenderInfo)
          alert('刪除成功  ʕ๑´ㅂ`๑ʔ')
        }, 500)
        getDZBMissionEventList().then(db=> {
          if (db.error === errorCode.NULL_DATA.code) {
            alert(`※  ${errorCode.NULL_DATA.memo}`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          if (db.error === errorCode.SERVER_ERROR.code) {
            alert(`※  ${errorCode.SERVER_ERROR.memo}`)
            return
          }
          let data = []
          for (var i = 0; i < db.data.length; i += 30) {
            data.push(db.data.slice(i, i + 30))
          }
          setPage(1)
          setPageCounter(data.length)
          setPanelInfo(data)
          setDataCopy(db.data)
        })
      })
    })
  }
  return (
    <>
      <Modal
        show={showDeleteHint}
        onHide={handleDeleteHintClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>刪除提示</Modal.Title>
        </Modal.Header>
        <ModalText>
          刪除活動後相關設定及內容將一併消失，是否進行刪除?
        </ModalText>
        <ModalBtn>
          <Button variant="secondary" onClick={handleCancel}>取消</Button>
          <Button variant="danger" onClick= {handleDelete}>刪除</Button>
        </ModalBtn>
      </Modal>
    </>
  )
}