import styled from 'styled-components'
import { BarBG, BasicInput, BtnPosition } from '../../../middleware/utilityStyle'

export const InputBarBg = styled(BarBG)`
  @media screen and (max-width: 1350px){
    & {
      flex-direction: column;
    }
    & div {
      margin-top: 10px;
    }
    & div + div {
      margin-top: 10px;
    }
  }
  @media screen and (min-width:0px) and (max-width: 600px){
    & {
      justify-content: center;
    }
  }
`

export const InputBarRWD = styled.div`
  display: flex;
  @media screen and (max-width: 1350px){
    & {
      flex-direction: column;
    }
    & div {
      margin-left: 0px;
      display: flex;
      justify-content: space-between;
    }
  }
` 

export const InputBar = styled.div`
  margin-left: 20px;
`

export const Input = styled(BasicInput)`
  width: 350px;
  margin-left: 20px;
  @media screen and (min-width:400px) and (max-width: 600px){
    & {
      width: 250px;
    }
  }
  @media screen and (max-width: 400px){
    & {
      width: 150px;
    }
  }
`

export const PUInput = styled(BasicInput)`
  width: 650px;
  height: 28px;
  text-align: center;
`

export const PUInputBar = styled.div`
  display: flex;
  justify-content: space-between;
  
  : nth-child(2) {
    margin-top: 10px;
  }

  : nth-child(3) {
    margin-top: 10px;
  }
`

export const BTN = styled(BtnPosition)`
  width: 500px;
`