import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import typeName from "../../../assets/config/eventSettingType.json"
import { getAccessArr } from "../../../middleware/utils"

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker'
import { today, yesterday, thisWeek, lastWeek, thisMonth, defaultDate, defaultDateE } from "../../../middleware/dateUtils"
import AddRevisePU from "../../../components/popUpWindow/eventSetting/AddRevisePU"
import DeletePU from "../../../components/popUpWindow/eventSetting/DeletePU"
import { getActivity } from "../../../middleware/Api/eventManagement/eventSettingApi.js"

import {
  InputBarBg,
  NavLeft,
  IdInputBar,
  IdInput,
  RangeWrapper,
  DatePickerInput,
  RangeGroup,
  BtnGroup,
  BTN
} from "./styledComponent"
import {
  DateWrapper,
  DateTitle,
  ClickRange,
  SearchButton,
  ResetButton,
  BasicTable,
  BasicRowTitle,
  TableContent,
  PageStyle,
  TableBtnGroup,
  NewBtn,
  ReviseBtn,
  DeleteBtn
} from "../../../middleware/utilityStyle.js"

export default function EventSetting() {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const history = useHistory()
  const [panelInfo, setPanelInfo] = useState([])
  const [change, setChange] = useState({activityID: '', sDate: '', eDate: ''})
  const handleChange = (e, key) => {
    setChange(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }
  const [dateValue, setDateValue] = useState([null, null])
  const [popData, setPopData] = useState({})

  useEffect(() => {
  }, [panelInfo])

  const handleRadio = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPopData(panelInfo.find(ele => +ele.ACTIVITY_ID === +e.target.value))
    })
  }

  const [changePagePara, setChangePagePara] = useState({
    activityID: 0,
    sDate: new Date(defaultDate),
    eDate: new Date(defaultDateE)
  })

  const [pageCounter, setPageCounter] = useState(0)
  const [page, setPage] = useState(1)
  const handleChangePage = (event, newPage) => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPage(newPage)
      const {activityID, sDate, eDate} = changePagePara
      handleGetActivity(activityID, sDate, eDate, 1+(newPage-1)*30, newPage*30)
    })
  }

  function handleGetActivity(id, sD, eD, sR, eR) {
    getActivity(+id, new Date(sD).toISOString(), new Date(eD).toISOString(), sR, eR).then(db => {
      if (db.error === errorCode.INCORRECT_DATA.code) {
        alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.INCORRECT_FORMAT.code) {
        alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.EXCEPTION.code) {
        alert(`※  ${errorCode.EXCEPTION.memo}`)
        return
      }
      if (db.error === errorCode.SERVER_ERROR.code) {
        alert(`※  ${errorCode.SERVER_ERROR.memo}`)
        return
      }
      if (db.error === errorCode.NULL_DATA.code) {
        alert(`※  ${errorCode.NULL_DATA.memo}`)
        setPanelInfo([])
        return
      }
      setChangePagePara({
        activityID: +id,
        sDate: new Date(sD),
        eDate: new Date(eD)
      })
      if(db.data) {
        if (db.data.length > 0) {
          setPageCounter(
            db.data[0].TOTAL_ROW_NUM%30 === 0
            ? Math.floor(db.data[0].TOTAL_ROW_NUM/30)
            : (Math.floor(db.data[0].TOTAL_ROW_NUM/30)) + 1
          )
        }
      }
      setPanelInfo(db.data)
      setPage(1)
    })
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      let id = 0
      if (isNaN(change.activityID)) {
        alert('※  活動ID請輸入數字')
        return
      } else {
        id = +change.activityID
      }

      let sDate = ''
      let eDate = ''
      if  (dateValue[0] !== null || dateValue[1] !== null) {        
        sDate = new Date (dateValue[0]).toISOString()
        let endRDateStr = new Date (dateValue[1]).toString()
        eDate = new Date(endRDateStr.replace('00:00:00', '23:59:59')).toISOString()
      }
      if  (dateValue[0] === null && dateValue[1] === null) {
        sDate = defaultDate.toISOString()
        eDate= defaultDateE.toISOString()
      }
      handleGetActivity(+id, sDate, eDate, 1, 30)
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({activityID: '', sDate: '', eDate: ''})
      setDateValue([null, null])
    })
  }

  const [addStatus, setAddStatus] = useState(false)
  const[showAddRevise, setAddReviseShow] = useState(false)
  const handleAddReviseClose = () => setAddReviseShow(false)
  const handleAddReviseShow = () => setAddReviseShow(true)

  const handleAdd = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 61)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      setAddStatus(true)
      handleAddReviseShow()
    })
  }

  const handleRevise = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 62)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (!+popData.ACTIVITY_ID) {
        alert('※  請選擇欲修改的項目')
        return
      }
      setAddStatus(false)
      handleAddReviseShow()
    })
  }

  const [showDeletePU, setDeletePUShow] = useState(false)
  const handleDeletePUClose = () => setDeletePUShow(false)
  const handleDeletePUShow = () => setDeletePUShow(true)
  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 63)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (!+popData.ACTIVITY_ID) {
        alert('※  請選擇欲刪除的項目')
        return
      }
      handleDeletePUShow()
    })
  }

  function dateFormat(target) {
    let str = new Date(target).toLocaleDateString()
    let arr = str.split('/')
    if (Number(arr[1]) < 10) {
      arr[1] = '0' + arr[1]
    }
    if (Number(arr[2]) < 10) {
      arr[2] = '0' + arr[2]
    }
    let result = arr.join('-')
    return result
  }

  const transStatus = new Map ([
    [1, '關閉'],
    [2, '開啟']
  ])
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <NavLeft>
          <IdInputBar>
            活動ID<IdInput placeholder= "請輸入活動ID" value= {change.activityID} onChange= {e=> {handleChange(e, 'activityID')}}/>
          </IdInputBar>
          <RangeWrapper>
            <DateWrapper>
              <DateTitle>活動時間</DateTitle>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                  <DesktopDateRangePicker
                    startText="創建時間"
                    endText="結束時間"
                    value={dateValue}
                    onChange={(newValue) => {
                      setDateValue(newValue)
                    }}
                    renderInput={(startProps, endProps) => {
                      startProps.inputProps.placeholder = '開始日期'
                      endProps.inputProps.placeholder= '結束日期'
                      return (
                        <React.Fragment>
                          <DatePickerInput ref={startProps.inputRef} {...startProps.inputProps} />
                          <Box sx={{ mx: 1 }}>至</Box>
                          <DatePickerInput ref={endProps.inputRef} {...endProps.inputProps} />
                        </React.Fragment>
                      )
                    }}
                  />
                </Stack>
              </LocalizationProvider>
            </DateWrapper>
            <RangeGroup>
              <ClickRange onClick={() => { setDateValue(today) }}>今天</ClickRange>
              <ClickRange onClick={() => { setDateValue(yesterday) }}>昨天</ClickRange>
              <ClickRange onClick={() => { setDateValue(thisWeek) }}>本周</ClickRange>
              <ClickRange onClick={() => { setDateValue(lastWeek) }}>上周</ClickRange>
              <ClickRange onClick={() => { setDateValue(thisMonth) }}>本月</ClickRange>
            </RangeGroup>
          </RangeWrapper>
        </NavLeft>
      
        <BtnGroup>
          <SearchButton onClick={handleSearch}>搜索</SearchButton>
          <ResetButton onClick={handleReset}>重置</ResetButton>
        </BtnGroup>
      </InputBarBg>
      
      <TableBtnGroup>
        <BasicTable className="table table-hover table-bordered">
          <thead>
            <BasicRowTitle>
              <th>
                <input type= "radio" disabled/>
              </th>
              <th>活動ID</th>
              <th>類型</th>
              <th>名稱</th>
              <th>狀態</th>
              <th>開始時間</th>
              <th>結束時間</th>
            </BasicRowTitle>
          </thead>
          <tbody>
            {
              Array.isArray(panelInfo) && panelInfo.length > 0 ?
              panelInfo.map((ele, index) => {
                return (
                  <TableContent key= {index}>
                    <td>
                      <input type= "radio" name= "eventList" value= {ele.ACTIVITY_ID} onChange= {handleRadio}/>
                    </td>
                    <td>{ele.ACTIVITY_ID}</td>
                    <td>{typeName.find(obj=> +obj.type === +ele.ACTIVITY_TYPE).name}</td>
                    <td>{ele.ACTIVITY_NAME}</td>
                    <td>{transStatus.get(+ele.STATE)}</td>
                    <td>{dateFormat(ele.START_DATE)}</td>
                    <td>{dateFormat(ele.END_DATE)}</td>
                  </TableContent>
                )
              })
              : <tr key= "noInfo"><td colSpan= "7">尚無資料</td></tr>
            }
          </tbody>
        </BasicTable>        
        <BTN>
          <NewBtn onClick= {handleAdd}>新增</NewBtn>
          <ReviseBtn onClick= {handleRevise}>修改</ReviseBtn>
          <DeleteBtn onClick= {handleDelete}>刪除</DeleteBtn>
        </BTN>
      </TableBtnGroup>

      <AddRevisePU
        showAddRevise= {showAddRevise}
        handleAddReviseShow= {handleAddReviseShow}
        handleAddReviseClose= {handleAddReviseClose}
        addStatus= {addStatus}
        popData= {popData}
        typeName= {typeName}
        setPanelInfo= {setPanelInfo}
        setPopData= {setPopData}
      />

      <DeletePU
        popData= {popData}
        setPopData= {setPopData}
        showDeletePU= {showDeletePU}
        handleDeletePUClose= {handleDeletePUClose}
        setPanelInfo= {setPanelInfo}
      />

      {
        Array.isArray([panelInfo]) && panelInfo.length ?
          <Stack spacing={2}>
            <PageStyle count={pageCounter}
              color="warning"
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </Stack>
        : ''
      }
    </>
  )
}