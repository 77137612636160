import React, { useState, useEffect }from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import {  PUInput, PUInputBar } from "../../../views/operation/ParameterSetting/styledComponent"
import { ModalBtn } from "../../../middleware/utilityStyle"
import ARHint from "./ARHint"

export default function AddAndRevise(props) {
  const {
    showAddRevise,
    handleAddReviseShow,
    handleAddReviseClose,
    addStatus,
    popData
  } = props
  const history = useHistory()
  const [form, setForm] = useState({
    paraId: '',
    paraKey: '',
    paraValue: '',
    paraContent: ''
  })
  const handleForm = (e, key) => {
    setForm(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }
  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!addStatus) {
        setForm(JSON.parse(JSON.stringify(popData)))
      } else {
        setForm({
          paraId: '',
          paraKey: '',
          paraValue: '',
          paraContent: ''
        })
      }
    })
  }, [popData, addStatus])

  const handleSubmit = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!form.paraKey) {
        alert('※  請輸入參數 Key')
        return
      }
      if (!form.paraValue) {
        alert('※  請輸入參數 Value')
        return
      }
      if (isNaN(form.paraValue)) {
        alert('※  參數 Value請輸入數字')
        return
      }
      if (!form.paraContent) {
        alert('※  請輸入參數內容')
        return
      }
      handleAddReviseClose()
      handleARHintShow()
    })
  }

  const handleBack = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleAddReviseClose()
      setTimeout(() => {
        if (addStatus) {
          setForm({
            paraId: '',
            paraKey: '',
            paraValue: '',
            paraContent: ''
          })
        }
        if (!addStatus) {
          setForm(JSON.parse(JSON.stringify(popData)))
        }
      }, 500)
    })
  }

  const [showARHint, setARHintShow] = useState(false)
  const handleARHintClose = () => setARHintShow(false)
  const handleARHintShow = () => setARHintShow(true)
  return (
    <>
      <Modal
        show={showAddRevise}
        onHide={handleAddReviseClose}
        backdrop="static"
        keyboard={false}
        size= "lg"
      >
        <Modal.Header>
          <Modal.Title>{addStatus ? '新增' : '修改'}遊戲設定</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PUInputBar>
            參數 Key: <PUInput placeholder= "請輸入參數Key" value= {form.paraKey} onChange= {e => {handleForm(e, 'paraKey')}}/>
          </PUInputBar>
          <PUInputBar>
            參數 Value: <PUInput placeholder= "請輸入參數Value" value= {form.paraValue} onChange= {e => {handleForm(e, 'paraValue')}}/>
          </PUInputBar>
          <PUInputBar>
            參數詳情: <PUInput placeholder= "請輸入參數詳情" value= {form.paraContent} onChange= {e => {handleForm(e, 'paraContent')}}/>
          </PUInputBar>
        </Modal.Body>
        <ModalBtn>
          <Button variant="info" onClick= {handleSubmit}>確定</Button>
          <Button variant="secondary" onClick= {handleBack}>返回</Button>
        </ModalBtn>
      </Modal>

      <ARHint
        showARHint= {showARHint}
        handleARHintClose= {handleARHintClose}
        handleAddReviseShow= {handleAddReviseShow}
        addStatus= {addStatus}
        setForm= {setForm}
        popData= {popData}
      />
    </>
  )
}