import React, { useState, useEffect, useContext}from "react"
import { useHistory } from "react-router-dom"
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// import{} from "../../../middleware/utilityStyle"
import {PUInput} from "../../../views/backstageManagement/adSettingForFinalCalculate/styledComponent"

import {rateSettingData} from "./Contexts"

export default function RateSettingComponent({
  dataNumber
}){
  const [rateSetting,handleSetRateSetting] = useContext(rateSettingData)

  const passData = (e,key) =>{
    let temp = rateSetting[dataNumber]?rateSetting[dataNumber]:{
      rate:"",
      weight:""
    }
    let v = e.target.value.split(".")
    if (v.length === 2){
      if(v[1].length >2){
        alert("小數點前兩位")
        return 
      }
    }
    temp[key] = e.target.value
    handleSetRateSetting(dataNumber, temp)
  }

  return(
    <>
      <Row xs="auto">
        <Col xs={1}>
        <label>
          {`${dataNumber}.`}
        </label>
        </Col>
        <Col >
        <label>
          {`倍率: x`}
          <PUInput 
          value={rateSetting[dataNumber]&&rateSetting.switch ==="1"?rateSetting[dataNumber].rate:""} 
          onChange={e=>{passData(e, "rate")}} 
          type= "number" 
          min={1}
          placeholder="請輸入值"
          disabled = {rateSetting.switch==="0"?true:false}
        />
        </label>
        </Col>
        <Col >
        <label>
          {`權重: `}
          <PUInput 
          value={rateSetting[dataNumber]&&rateSetting.switch ==="1"?rateSetting[dataNumber].weight:""} 
          onChange={e=>{passData(e, "weight")}} 
          type= "number"
          min={1}
          placeholder="請輸入值"
          disabled = {rateSetting.switch==="0"?true:false}
        />
        </label>
        </Col>
      </Row>
    </>
  )
}