import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import "../../../views/operation/orderManagement/styled.css"
import HandleEndStatus from "./handleEndStatus"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { getAccessArr } from "../../../middleware/utils"
import {
  PUCol,
  PUDropdown,
  PUInput,
  Btn
} from "../../../views/operation/orderManagement/styledComponent"
import { orderDetail, updateOrderDetail, orderList } from "../../../middleware/Api/operation/orderManagementApi"
import { TimezoneTranslator } from "middleware/dateUtils"
import { ModalFormat, BasicTable } from "../../../middleware/utilityStyle"

export default function HandleUnoInfo(props) {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const history = useHistory()
  const {
    showManiUno,
    handleManiUnoClose,
    targetOrderID,
    unoManiStatus,
    setPanelInfo,
    page,
    setPageCounter,
    changePagePara,
    setChangePagePara
  } = props

  const [detailCopy, setDetailCopy] = useState({
    ORDER_ID: '',
    LOG_DATE: '',
    UID: '',
    ITEM_ID: '',
    ITEM_NAME: '',
    RECEIVER_NAME: '',
    RECEIVER_PHONE: '',
    RECEIVER_ADDRESS: '',
    PRICE_TYPE: '',
    ITEM_PRICE: '',
    BALANCE: '',
    PACKAGE_STATUS: '',
    LOGISTICS: '',
    PACKAGE_NUMBER: '',
    FINISH_STATUS: '',
    REMARK: ''
  })

  const [display, setDisplay] = useState({
    ORDER_ID: '',
    LOG_DATE: '',
    UID: '',
    ITEM_ID: '',
    ITEM_NAME: '',
    RECEIVER_NAME: '',
    RECEIVER_PHONE: '',
    RECEIVER_ADDRESS: '',
    PRICE_TYPE: '',
    ITEM_PRICE: '',
    BALANCE: '',
    PACKAGE_STATUS: '',
    LOGISTICS: '',
    PACKAGE_NUMBER: '',
    FINISH_STATUS: '',
    REMARK: ''
  })

  useEffect (() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (targetOrderID) {
        orderDetail(`${targetOrderID}`).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.NULL_DATA.code) {
            alert(`※  ${errorCode.NULL_DATA.memo}`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          setDetailCopy(...db.data)
          setDisplay(...db.data)
        })
      }
    })
  }, [targetOrderID, unoManiStatus])
    
  const handleChange =( e,key) => {
    setDisplay(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }
  
  const nameTrans = [
    [1, "金幣"],
    [2, "鬥陣寶"],
    [3, "兌換券"],
  ]
  const paymentNameTrans = new Map(nameTrans)

  const [showEnd, setEndShow] = useState(false)
  const handleEndClose = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      orderList(changePagePara.packageStatus, changePagePara.orderStatus, changePagePara.uid,
      changePagePara.orderID, changePagePara.sDate, changePagePara.eDate, 1+(page-1)*30, page*30, 0).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setPanelInfo(db.data)
      })
      setEndShow(false)
    })
  }
  const handleEndShow = () => setEndShow(true)

  const handleSave = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 41)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      let compareTheSame = true
      for(let key in detailCopy){
        if (typeof detailCopy[key] === 'string') {
          if (display[key] !== detailCopy[key]) {
            compareTheSame = false
            break
          }
        }
        if (typeof detailCopy[key] === 'number') {
          if (+display[key] !== +detailCopy[key]) {
            compareTheSame = false
            break
          }
        }
      }
      if (compareTheSame) {
        alert('資料未更動  ʕ◍•́ᴥ•̀◍ʔ')
        handleManiUnoClose()
        return
      }
      if (!compareTheSame) {
        if (+display.FINISH_STATUS === 2) {
          handleManiUnoClose()
          handleEndShow()
        } else {
          updateOrderDetail(display.ORDER_ID, +display.PACKAGE_STATUS, display.LOGISTICS,
          display.PACKAGE_NUMBER, display.REMARK, +display.FINISH_STATUS).then(db => {
            if (db.error === errorCode.INCORRECT_DATA.code) {
              alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.INCORRECT_FORMAT.code) {
              alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            handleManiUnoClose()
            setTimeout(() => {
              alert('訂單設定儲存成功  ʕ๑´ㅂ`๑ʔ')
            }, 200)
          })
        }
      }
    })
  }

  const handlePUClose = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleManiUnoClose()
    })
  }

  const handleOnHide = () => {
    handleManiUnoClose()
    setTimeout(() => {
      setDisplay(detailCopy)
    }, 200)
  }
  return (
    <>
      <ModalFormat
        show= {showManiUno}
        onHide= {handleOnHide}
        backdrop= "static"
        dialogClassName="modal-XXL"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body >
          <BasicTable className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">交易單號</th>
                <th scope="col">兌換時間</th>
                <th scope="col">玩家ID</th>
                <th scope="col">商品ID</th>
                <th scope="col">商品項目</th>
                <th scope="col">收件人</th>
                <th scope= "col">連絡電話</th>
                <th scope= "col">收件地址</th>
                <th scope= "col">付費方式</th>
                <th scope= "col">價格</th>
                <th scope= "col">餘額</th>
                <th scope="col">配送狀態</th>
                <th scope="col">物流單位</th>
                <th scope="col">包裹號碼</th>
                <th scope="col">單號狀態</th>
                <th scope="col">備註</th>
              </tr>
            </thead>
            <tbody>
              <tr key= "table">
                <PUCol>{display.ORDER_ID}</PUCol>
                <PUCol>{TimezoneTranslator(display.LOG_DATE).slice(0, 16)}</PUCol>
                <PUCol>{display.UID}</PUCol>
                <PUCol>{display.ITEM_ID}</PUCol>
                <PUCol>{display.ITEM_NAME}</PUCol>
                <PUCol>{display.RECEIVER_NAME}</PUCol>
                <PUCol>{display.RECEIVER_PHONE}</PUCol>
                <PUCol>{display.RECEIVER_ADDRESS}</PUCol>
                <PUCol>{paymentNameTrans.get(display.PRICE_TYPE)}</PUCol>
                <PUCol>{display.ITEM_PRICE}</PUCol>
                <PUCol>{display.PRICE_TYPE === 3 ? "-" : display.BALANCE}</PUCol>
                <PUCol>
                  <PUDropdown
                    value= {+display.PACKAGE_STATUS}
                    onChange= {e => {handleChange(e, 'PACKAGE_STATUS')}}
                    disabled= {unoManiStatus ? false : true}
                  >
                    <option value= "1">訂單受理</option>
                    <option value= "2">已備貨</option>
                    <option value= "3">申請換貨</option>
                    <option value= "4">已出貨</option>
                  </PUDropdown>
                </PUCol>
                <PUCol>
                  <PUInput
                    placeholder= "請輸入物流單位"
                    value= {display.LOGISTICS}
                    onChange= {e => {handleChange(e , 'LOGISTICS')}}
                    disabled= {unoManiStatus ? false : true}
                  />
                </PUCol>
                <PUCol>
                  <PUInput
                    placeholder= "請輸入包裹號碼"
                    value= {display.PACKAGE_NUMBER}
                    onChange= {e => {handleChange(e, 'PACKAGE_NUMBER')}}
                    disabled= {unoManiStatus ? false : true}
                  />
                </PUCol>
                <PUCol>
                  <PUDropdown
                    value= {`${display.FINISH_STATUS}`}
                    onChange= {e => {handleChange(e, 'FINISH_STATUS')}}
                    disabled= {unoManiStatus ? false : true}
                  >
                    <option value= "1">進行中</option>
                    <option value= "2">結案</option>
                  </PUDropdown>
                </PUCol>
                <PUCol>
                  <textarea
                    className= "form-control"
                    row= "2"
                    value= {display.REMARK}
                    onChange= {e => {handleChange(e, 'REMARK')}}
                    disabled= {unoManiStatus ? false : true}
                  />
                </PUCol>
              </tr>
            </tbody>
            <tfoot></tfoot>
          </BasicTable>
        </Modal.Body>
        <Btn>
          <Button
            variant="success"
            style= {unoManiStatus ? {display: 'block'} : {display: 'none'}}
            onClick={handleSave}>儲存</Button>
          <Button 
            variant= "dark"
            style= {unoManiStatus ? {display: 'none'} : {display: 'block'}}
            onClick= {handlePUClose}>關閉</Button>
        </Btn>
      </ModalFormat>

      <HandleEndStatus
        showEnd= {showEnd}
        handleEndClose= {handleEndClose}
        unoManiStatus= {unoManiStatus}
        display= {display}
        setPanelInfo= {setPanelInfo}
        setPageCounter= {setPageCounter}
        setChangePagePara= {setChangePagePara}
      />
    </>
  )
}