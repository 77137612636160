import React from "react"
import Modal from 'react-bootstrap/Modal'
import { Button } from "react-bootstrap"
import { ModalBtn, ModalText } from "../../../middleware/utilityStyle"

export default function ResetPasswordFirstPU(props) {
  const {
    popData,
    handleResetClose,
    showReset,
    handleResetSecPopUpShow
  } = props
  return (
    <>
      <Modal
        show={showReset}
        onHide={handleResetClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>重置確認</Modal.Title>
        </Modal.Header>
        <ModalText>
          帳號: {popData.account} 的密碼將重置，真的確定要嗎？
        </ModalText>
        <ModalBtn>
          <Button variant="info" onClick={handleResetSecPopUpShow}>要</Button>
          <Button variant="light" onClick={handleResetClose}>先不要</Button>
        </ModalBtn>
      </Modal>
    </>
  )
}