import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { ModalFormat, ModalBtn } from "../../../middleware/utilityStyle"
import { TopTitle, PUSelect, PUInput, CodeBtn, TimeFormat, Textarea, SimpleInput } from "../../../views/operation/coupon/styledComponent.js"
import { insertCoupon, updateCoupon , getCoupon, getItemByItemID} from "../../../middleware/Api/operation/couponApi.js"
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import Box from '@mui/material/Box'

export default function Edit(props) {
  const {
    showInsert,
    handleInsertClose,
    edit,
    setEdit,
    myCoupon,
    setMyCoupon,
    setCouponInfo,
    nextCouponID,
    setNextCouponID,
    pageNum,
    rowsPerPage,
    setDataForPage
  } = props
  const history = useHistory()
  const [beginDate, setBeginDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date(new Date().setMonth(new Date().getMonth() + 1)))
  const [beginTime, setBeginTime] = useState(new Date(new Date().setHours(0,0,0)))
  const [endTime, setEndTime] = useState(new Date(new Date().setHours(0,0,0)))
  const [info, setInfo] = useState({
    codeAmount: 1,
    couponID: "",
    couponName: "",
    itemType: 1,
    itemID: 0,
    state: 1,
    newCouponID: ""
  })
  const [myCode, setMyCode] = useState("")
  const [myCodeName, setMyCodeName] = useState("")
  const [codeAmount, setCodeAmount] = useState(false)

  const handleInfo = (e, key) => {
    setInfo(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  useEffect(() => {
    setMyCode(myCoupon.COUPON_ID)
    setMyCodeName(myCoupon.COUPON_NAME)
    if (edit) {
      info.couponID = myCoupon.COUPON_ID
      info.couponName = myCoupon.COUPON_NAME
      info.itemType = myCoupon.ITEM_TYPE
      info.itemID = myCoupon.ITEM_ID === null ? 0 : myCoupon.ITEM_ID
      info.amount = myCoupon.EXCHANGE_NUM
      info.limit = myCoupon.LIMIT_NUM
      info.mailTitle = myCoupon.MAIL_TITLE
      info.mailMessage = myCoupon.MAIL_MESSAGE
      setBeginDate(myCoupon.BEGIN_DATE)
      setEndDate(myCoupon.EXPIRE_DATE)
      setBeginTime(new Date(myCoupon.BEGIN_DATE))
      setEndTime(new Date(myCoupon.EXPIRE_DATE))
    }
  }, [myCoupon, edit])

  useEffect(() => {
    if (info.couponID.length > 30) info.couponID = info.couponID.substring(0, 30)
    setMyCode(info.couponID.toUpperCase())
  }, [info.couponID])

  useEffect(() => {
    if (info.codeAmount > 1) setCodeAmount(true)
    else setCodeAmount(false)
  }, [info.codeAmount])

  useEffect(() => {
    if (info.couponName.length > 30) info.couponName = info.couponName.substring(0, 30)
    setMyCodeName(info.couponName)
  }, [info.couponName])

  const checkBeforeSubmit = () => {
    if (isNaN(info.itemID) || info.itemType != 3) {
      info.itemID = 0
      handleSubmit()
    }
    else {
      info.itemID = +info.itemID
      getItemByItemID(info.itemID).then(db => {
        if (db.error === errorCode.NULL_DATA.code) {
          alert("請輸入有效道具ID !")
          return
        }
        handleSubmit()
      })
    }
  }

  const handleSubmit = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      let count = info.codeAmount > 1 ? 1 : 0
      for (let key in info) {
        count++
        if (info[key] === "") count--
      }
      if (count < 10 || !Date.parse(beginDate) || !Date.parse(endDate) || !Date.parse(beginTime) || !Date.parse(endTime)) {
        alert("有欄位尚未填寫 !")
        return
      }
      if (isNaN(info.codeAmount) || info.codeAmount < 1) info.codeAmount = 1
      if (info.limit === "0" || info.limit === -1) info.limit = -1
      else if (edit && info.limit < myCoupon.NOW_NUM) {
        alert("兌換上限不可低於已兌換數量 !")
        return
      }
      if (isNaN(info.amount) || isNaN(info.limit)) {
        alert("兌換數量或上限輸入錯誤 !")
        return
      }
      let bd = new Date(beginDate).toDateString()
      let bt = new Date(beginTime).toTimeString()
      let ed = new Date(endDate).toDateString()
      let et = new Date(endTime).toTimeString()
      info.beginDate = new Date(new Date(`${bd} ${bt}`))
      info.endDate = new Date(new Date(`${ed} ${et}`))
      info.itemType = +info.itemType
      info.state = +info.state
      info.amount = +info.amount
      info.limit = +info.limit

      if (edit) {
        info.newCouponID = info.couponID
        info.couponID = myCoupon.COUPON_ID
        updateCoupon(info).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          if (db.data === null) {
            alert("序號修改失敗 !")
            return
          }
          if (db.data[0].RESULT === -9999) {
            alert("序號不可重複 !")
            return
          }
          getCoupon().then(db => {
            if (db.error === errorCode.INCORRECT_DATA.code) {
              alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
              return
            } 
            if (db.error === errorCode.INCORRECT_FORMAT.code) {
              alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            let data = mergeSort(db.data, "ID")
            setNextCouponID(data[0].ID + 1)
            data = data.filter(obj => obj.STATE !== 2)
            setCouponInfo(data)
            let result = data.slice(pageNum * rowsPerPage, (pageNum + 1) * rowsPerPage)
            setDataForPage(result)
          })
          if (Array.isArray(db.data) && db.data[0].RESULT === 1) {
            alert("修改序號成功 !")
            return
          }
        })
        setEdit(false)
      } else {
        for (let i = 0; i < info.codeAmount; i++) {
          getCoupon().then(db => {
            if (db.error === errorCode.INCORRECT_DATA.code) {
              alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
              return
            } 
            if (db.error === errorCode.INCORRECT_FORMAT.code) {
              alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            if (info.codeAmount > 1) info.couponID = db.code

            insertCoupon(info).then(db => {
              if (db.error === errorCode.INCORRECT_DATA.code) {
                alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                return
              }
              if (db.error === errorCode.INCORRECT_FORMAT.code) {
                alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                return
              }
              if (db.error === errorCode.EXCEPTION.code) {
                alert(`※  ${errorCode.EXCEPTION.memo}`)
                return
              }
              if (Array.isArray(db.data) && db.data[0].RESULT !== 1) {
                alert("新增序號失敗 !")
                return
              }
              getCoupon().then(db => {
                if (db.error === errorCode.INCORRECT_DATA.code) {
                  alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                  return
                } 
                if (db.error === errorCode.INCORRECT_FORMAT.code) {
                  alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                  return
                }
                if (db.error === errorCode.EXCEPTION.code) {
                  alert(`※  ${errorCode.EXCEPTION.memo}`)
                  return
                }
                let data = mergeSort(db.data, "ID")
                setNextCouponID(data[0].ID + 1)
                data = data.filter(obj => obj.STATE !== 2)
                setCouponInfo(data)
                let result = data.slice(pageNum * rowsPerPage, (pageNum + 1) * rowsPerPage)
                setDataForPage(result)
              })
            })
          })
        }
      }
      handleBack()
    })
  }

  function mergeSort(arr, keyName) {
    if (arr.length === 1 || arr.length === 0) {
      return arr;
    }
    let mid = Math.floor(arr.length / 2);
    let left = arr.slice(0, mid);
    let right = arr.slice(mid, arr.length);
    return merge(mergeSort(left, keyName), mergeSort(right, keyName), keyName);
  }
  
  function merge(left, right, keyName) {
    let result = [];
    let i = 0;
    let j = 0;
    while (i < left.length && j < right.length) {
      if (left[i][keyName] >= right[j][keyName]) {
        result.push(left[i]);
        i++;
      } else {
        result.push(right[j]);
        j++;
      }
    }
    while (i < left.length) {
      result.push(left[i]);
      i++;
    }
    while (j < right.length) {
      result.push(right[j]);
      j++;
    }
    return result;
  }

  const handleCode = () => {
    if (info.codeAmount > 1) return
    getCoupon().then(db => {
      if (db.error === errorCode.INCORRECT_DATA.code) {
        alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
        return
      } 
      if (db.error === errorCode.INCORRECT_FORMAT.code) {
        alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.EXCEPTION.code) {
        alert(`※  ${errorCode.EXCEPTION.memo}`)
        return
      }
      setMyCode(db.code)
      info.couponID = db.code
    })
  }

  const handleBack = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
        }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setEdit(false)
      setInfo({codeAmount: 1,couponID: "",couponName: "",itemType: 1,itemID: 0,state: 1,newCouponID:""})
      setMyCoupon({})
      setBeginDate(new Date())
      setEndDate(new Date(new Date().setMonth(new Date().getMonth() + 1)))
      setBeginTime(new Date(new Date().setHours(0,0,0)))
      setEndTime(new Date(new Date().setHours(0,0,0)))
      handleInsertClose()
    })
  }

  return (
  <>
    <ModalFormat
    show={showInsert}
    onHide={handleBack}
    backdrop="static"
    keyboard={false}
    size= "lg"
    >
    <Modal.Header closeButton>
        <TopTitle>{edit ? "序號設定" : "新增序號"}</TopTitle>
    </Modal.Header>
    <Modal.Body>
    <table className="table table-bordered">
      <tbody>
        <tr>
          <td>序號起始ID</td>
          <td>{edit ? myCoupon.ID : nextCouponID}</td>
        </tr>
        <tr style= { edit ? { display: "none" } : { display:"span" }}>
          <td>產生數量</td>
          <td>
            <PUInput value={info.codeAmount} onChange={e => handleInfo(e, "codeAmount")}></PUInput> ※超過1時，序號將固定為亂碼
          </td>
        </tr>        
        <tr>
          <td>序號</td>
          <td>
            <PUInput placeholder="請輸入序號" value={myCode}  disabled={codeAmount} onChange={e => handleInfo(e, "couponID")}></PUInput>
            <CodeBtn onClick={handleCode}>產生亂碼</CodeBtn>
          </td>
        </tr>
        <tr>
          <td>序號名稱</td>
          <td>
            <PUInput placeholder="請輸入名稱" value={myCodeName} onChange={e => handleInfo(e, "couponName")}></PUInput>
          </td>
        </tr>
        <tr>
          <td>兌換起始日期</td>
          <td style={{ display: "flex" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label= "日期"
                value={beginDate}
                onChange={(newValue) => {
                  setBeginDate(newValue);
                }}
                renderInput={({ inputRef, inputProps, InputProps }) => {
                  inputProps.placeholder = '開始日期'
                  return (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <input id= "datePicker" ref={inputRef} {...inputProps} />
                      <div id="dateBtn">{InputProps?.endAdornment}</div>
                    </Box>
                  )
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                label="時間"
                value={beginTime}
                onChange={(newValue) => {
                  setBeginTime(newValue);
                }}
                renderInput={({ inputRef, inputProps, InputProps }) => {
                  inputProps.placeholder = '開始時間'
                  return (
                    <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                      <input id= "timePicker" ref={inputRef} {...inputProps} />
                      {InputProps?.endAdornment}
                    </TimeFormat>
                  )
                }}
              />
            </LocalizationProvider>
          </td>
        </tr>
        <tr>
          <td>兌換結束日期</td>
          <td style={{ display: "flex" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label= "日期"
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={({ inputRef, inputProps, InputProps }) => {
                  inputProps.placeholder = '結束日期'
                  return (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <input id= "datePicker" ref={inputRef} {...inputProps} />
                      <div id="dateBtn">{InputProps?.endAdornment}</div>
                    </Box>
                  )
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                label="時間"
                value={endTime}
                onChange={(newValue) => {
                  setEndTime(newValue);
                }}
                renderInput={({ inputRef, inputProps, InputProps }) => {
                  inputProps.placeholder = '結束時間'
                  return (
                    <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                      <input id= "timePicker" ref={inputRef} {...inputProps} />
                      {InputProps?.endAdornment}
                    </TimeFormat>
                  )
                }}
              />
            </LocalizationProvider>
          </td>
        </tr>
        <tr>
          <td>兌換項目</td>
          <td>
            <PUSelect defaultValue={myCoupon.ITEM_TYPE} onChange={e => handleInfo(e, "itemType")}>
              <option value="1">金幣</option>
              <option value="2">鬥陣寶</option>
              <option value="3">道具</option>
            </PUSelect>
            若為道具<SimpleInput defaultValue={!myCoupon.ITEM_ID ? "" : myCoupon.ITEM_ID} placeholder="請輸入道具ID" onChange={e => handleInfo(e, "itemID")}></SimpleInput>
          </td>
        </tr>
        <tr>
          <td>兌換數量</td>
          <td>
            <PUInput defaultValue={myCoupon.EXCHANGE_NUM} placeholder="請輸入數量" onChange={e => handleInfo(e, "amount")}></PUInput>
          </td>
        </tr>
        <tr>
          <td>兌換上限</td>
          <td>
            <PUInput defaultValue={myCoupon.LIMIT_NUM === -1 ? 0 : myCoupon.LIMIT_NUM} placeholder="請輸入數字" onChange={e => handleInfo(e, "limit")}></PUInput> ※設定為0時視同無限制數量兌換
          </td>
        </tr>
        <tr>
          <td>開放狀態</td>
          <td>
            <PUSelect defaultValue={myCoupon.STATE} onChange={e => handleInfo(e, "state")}>
              <option value="1">開放</option>
              <option value="0">關閉</option>
            </PUSelect>
          </td>
        </tr>
        <tr>
          <td>信件標題</td>
          <td>
            <PUInput defaultValue={myCoupon.MAIL_TITLE} placeholder="請輸入名稱" onChange={e => handleInfo(e, "mailTitle")}></PUInput>
          </td>
        </tr>
        <tr>
          <td>信件內文</td>
          <td>
            <Textarea
              className="form-control"
              rows="3"
              defaultValue={myCoupon.MAIL_MESSAGE}
              placeholder="請輸入發送信件之內文"
              onChange={e => handleInfo(e, "mailMessage")}
            />
          </td>
        </tr>
      </tbody>
    </table>
    </Modal.Body>
    <ModalBtn>
        <Button variant="info" onClick= { checkBeforeSubmit }>確認</Button>
        <Button variant="secondary" onClick={ handleBack }>返回</Button>
    </ModalBtn>
    </ModalFormat>
  </>
  )
  }