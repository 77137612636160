import React from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { ModalBtn, ModalText } from "../../../middleware/utilityStyle"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { updateDzbMission } from "../../../middleware/Api/backstageManagement/dzbMissionSettingApi.js"

export default function DeleteHint(props) {
  const {
    showHint,
    handleHintClose,
    setReviseBtn,
    setTwoBtns,
    prizeDataCopy,
    prizePanelInfo
  } = props
  const history = useHistory()
  const handleOnHide = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleHintClose()
      setReviseBtn(true)
      setTwoBtns(false)
    })
  }
  const handleSubmit = () => {
    let updateList = []
    for (let i =0; i < prizePanelInfo.length; i++) {
      if (+prizePanelInfo[i].RANDOM_WEIGHT !== +prizeDataCopy[i].RANDOM_WEIGHT) {
        if (!(updateList.find(ele => ele.MISSION_ID === prizePanelInfo[i].MISSION_ID))) {
          updateList.push(prizePanelInfo[i])
        }
      }
      if (+prizePanelInfo[i].AWARD_MIN !== +prizeDataCopy[i].AWARD_MIN) {
        if (!(updateList.find(ele => ele.MISSION_ID === prizePanelInfo[i].MISSION_ID))) {
          updateList.push(prizePanelInfo[i])
        }
      }
      if (+prizePanelInfo[i].AWARD_MAX !== +prizeDataCopy[i].AWARD_MAX) {
        if (!(updateList.find(ele => ele.MISSION_ID === prizePanelInfo[i].MISSION_ID))) {
          updateList.push(prizePanelInfo[i])
        }
      }
    }
    handleOnHide()
    for (let k  = 0; k < updateList.length; k++) {
      updateDzbMission(+updateList[k].GAME_ID, +updateList[k].GAME_TYPE, updateList[k].MISSION_ID,
      +updateList[k].RANDOM_WEIGHT, +updateList[k].AWARD_MIN, +updateList[k].AWARD_MAX).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
      })
    }
  }

  const handleCancel = () => (
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleHintClose()
      setTwoBtns(true)
    })
  )
  return (
    <>
      <Modal
        show={showHint}
        onHide={handleOnHide}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>通知提示</Modal.Title>
        </Modal.Header>
        <ModalText>
          是否確定進行通知?
        </ModalText>
        <ModalBtn>
          <Button variant="info" onClick= {handleSubmit}>確定</Button>
          <Button variant="secondary" onClick= {handleCancel}>取消</Button>
        </ModalBtn>
      </Modal>
    </>
  )
}