import styled from 'styled-components'

export const Title = styled.td`
  text-align: center;
`

export const RadioWrapper = styled.div`
  display: flex;
`

export const FirstRadio = styled.div`
  margin-left: 20px;
`

export const SecondRadio = styled.div`
  margin-left: 30px;
`

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`

export const SubmitBtn = styled.button`
  color: #00e600;
  border: 1px solid #00e600;
  border-radius: 5px;
  transition: 0.7s;
  background: white;
  height: 60px;
  width: 120px;
  font-size: 18px;

  :hover {
    color: white;
    font-weight: bold;
    background: #00e600;
  }
`

export const ResetButton = styled.button`
  color: rgb(255, 26, 26);
  border: 1px solid rgb(255, 26, 26);
  border-radius: 5px;
  transition: 0.7s;
  background: white;
  height: 60px;
  width: 120px;
  font-size: 18px;

  :hover {
    color: white;
    font-weight: bold;
    background: rgb(255, 26, 26);
  }
`