import styled from 'styled-components'
import Box from '@mui/material/Box'

export const AlertMessage = styled.div`
  color: red;
  font-weight: 600;
  font-size: 25px;
`

export const Title = styled.td`
  text-align: center;
`

export const SendTimeWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 810px) {
    flex-direction: column;
  }
`

export const TimeFormat = styled(Box)`
  margin-left: 20px;
  @media screen and (max-width: 810px) {
    margin-left: 0px;
    margin-top: 10px;
  }
`

export const RadioFormat = styled.input`
  margin-right: 0.3125rem;
  margin-left: 0px;
  position: relative;
`

export const ItemRow = styled.div`
  @media screen and (max-width: 595px) {
    display: flex;
    flex-direction: column;
  }
`

export const ItemNum = styled.span`
  @media screen and (max-width: 595px) {
    margin-top: 0.5rem;
  }
`

export const ItemDivider = styled.div`
  margin-top: 0.5rem;
  @media screen and (max-width: 595px) {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
  }
`

export const ItemTitle = styled.span`
  margin-right: 1rem;
  margin-left: 1rem;
`

export const Dropdown = styled.select`
  border: 1px solid black;
  border-radius: 5px;
  height: 1.7rem;
  width: 7rem;
  text-align: center;
`

export const InputFormat = styled.input`
  border: 1px solid black;
  border-radius: 5px;
  height: 1.7rem;
  width: 7rem;
  text-align: center;
`

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SearchBtn = styled.button`
  color: orange;
  border: 1px solid orange;
  border-radius: 5px;
  transition: 0.7s;
  background: white;

  :hover {
    color: white;
    font-weight: bold;
    background: orange;
  }
`

export const BackBtn = styled.button`
  color: #b3b3b3;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  transition: 0.7s;
  background: white;

  :hover {
    color: white;
    font-weight: bold;
    background: #b3b3b3;
  }
`

export const SubmitBtn = styled.button`
  color: #00e600;
  border: 1px solid #00e600;
  border-radius: 5px;
  transition: 0.7s;
  background: white;

  :hover {
    color: white;
    font-weight: bold;
    background: #00e600;
  }
`

export const NextBtn = styled.button`
  color: #33d6ff;
  border: 1px solid #33d6ff;
  border-radius: 5px;
  transition: 0.7s;
  background: white;

  :hover {
    color: white;
    font-weight: bold;
    background: #33d6ff;
  }
`

export const Blank = styled.input`
border: 1px solid black;
border-radius: 5px;
height: 28px;
width: 250px;
`