import { getAuthToken } from '../../utils.js'
import { BaseUrl, UploadMaterial } from "../../../assets/config/urlForBuild"

//? 取得鬥陣寶係數設定 PID95 ?//
export const getUserItem = (uid, state, itemId, sRow, eRow) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/userItem?uid=${uid}&state=${state}&itemId=${itemId}&sRow=${sRow}&eRow=${eRow}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 新增鬥陣寶係數設定 PID96 ?//
export const addUserItem = (uid, itemId, itemNum, expiryDate, inUsed) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/userItem`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      uid,
      itemId,
      itemNum,
      expiryDate,
      inUsed
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 更改鬥陣寶係數設定 PID97 ?//
export const reviseUserItem = (uid, itemId, itemNum, expiryDate, inUsed) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/userItem`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      uid,
      itemId,
      itemNum,
      expiryDate,
      inUsed
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 刪除鬥陣寶係數設定 PID98 ?//
export const deleteUserItem = (uid, itemId) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/userItem/delete`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      uid, 
      itemId
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

export const uploadItem = formData => {
  return fetch(UploadMaterial, {
    method: 'POST',
    body: formData,
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}