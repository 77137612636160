import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { getAccessArr } from "../../../middleware/utils"
import { TimezoneTranslator } from "../../../middleware/dateUtils"

import AddRevisePU from "../../../components/popUpWindow/shopConfig/AddRevisePU"
import { getShopConfig } from "../../../middleware/Api/operation/shopConfigApi.js"

import {
  Dropdown, 
  CategoryName, 
  Frame,
  Avatar,
  LastPage
} from "./styledComponent"
import {
  BarBG,
  SearchButton,
  ResetButton,
  BasicTable,
  TableContent,
  BasicRowTitle,
  AddItemInfoBtn,
  PopDetail
} from "../../../middleware/utilityStyle.js"

export default function DetailSetting(props) {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const history = useHistory()
  const [change, setChange] = useState({
    navSwitch: -1
  })
  const [detailInfo, setDetailInfo] = useState([])
  const [detailCopy, setDetailCopy] = useState([])
  const [queryValue, setQueryValue]= useState({CLASS_ID: 0, CLASS_NAME: ''})
  const [targetObj, setTargetObj] = useState({
    PRODUCT_ID: 0, 
    CLASS_ID: 0, 
    ITEM_ID: 0,
    ORDER_ID: '',
    PRODUCT_TYPE: '',
    IMG_URL: '',
    PRODUCT_NAME: '',
    COST_TYPE: 1,
    COST_NUM: '',
    ITEM_TAG: 0,
    START_DATE: '',
    END_DATE: '',
    ACCOUNT_LIMIT: '',
    DAY_LIMIT: '',
    WEEK_LIMIT: '',
    MONTH_LIMIT: '',
    TOTAL_LIMIT: '',
    TEXT: '',
    STATUS: 0,
    DAY_CURRENT: '',
    WEEK_CURRENT: '',
    MONTH_CURRENT: '',
    TOTAL_CURRENT: '', 
    IS_PERMANENT: 1   
  })
  const [clickStatus, setClickStatus] = useState('')
  const urlQueryString = useLocation().search

  const tagName = new Map([
    [0, '無'],
    [1, '限量'],
    [2, '限時'],
    [3, '活動'],
    [4, '熱門'],
    [5, '推薦'],
  ])
  const transTag = new Map(tagName)

  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      let firstArr = urlQueryString.match(/(\w+)=([\u4e00-\u9fa5a-zA-Z0-9]+(([-]+|[\u4e00-\u9fa5a-zA-Z0-9]+|[_]+|))+)/gm)
      let secondArr = firstArr.map(str => str.match(/([\u4e00-\u9fa5a-zA-Z0-9]+(([-]+|[\u4e00-\u9fa5a-zA-Z0-9]+|[_]+|))+)/gm))
      let idObj = {}
      secondArr.forEach(arr => {idObj[arr[0]] = arr[1]})
      setQueryValue(idObj)
    })
  }, [useLocation().search])

  const handleChange = (e, key) => {
    setChange((prev) => ({
      ...prev,
      [key]: e
    }))
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      getShopConfig(+queryValue.CLASS_ID, -1).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setDetailCopy(db.data)
        if (+change.navSwitch === 1) {
          let searchList = db.data.filter(info => +info.STATUS === 1)
          setDetailInfo(searchList)
        } else if (+change.navSwitch === 0) {
          let searchArr = db.data.filter(info => +info.STATUS === 0) 
            setDetailInfo(searchArr)
        } else {
          setDetailInfo(db.data)
        }
      })
      setChange({navSwitch: -1})
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({navSwitch: -1})
    })
  }

  const handleLastPage = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
        }
      history.push("/admin/shopClassConfig")
    })
  }

  const [calender, setCalender] = useState({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null
  })

  const [showDetailSetting, setDetailSettingShow] = useState(false)
  const handleDetailSettingClose = () => {
    for (let key in calender) {
      calender[key] = null
    }
    setSwitchDelete(false)
    setSwitchSave(false)
    setDetailSettingShow(false)
  }
  const handleDetailSettingShow = () => {
    if (clickStatus === 'add') {
      for (let key in calender) {
        calender[key] = null
      }
    }
    setDetailSettingShow(true)
  }

  const [switchDelete, setSwitchDelete] = useState(false)
  const [switchSave, setSwitchSave] = useState(false)

  const handelAddItemDetail = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 120)){
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      setClickStatus('add')
      setSwitchSave(true)
      setTargetObj({
        PRODUCT_ID: 0, 
        CLASS_ID: 0, 
        ITEM_ID: 0,
        ORDER_ID: '',
        PRODUCT_TYPE: '',
        IMG_URL: '',
        PRODUCT_NAME: '',
        COST_TYPE: 1,
        COST_NUM: '',
        ITEM_TAG: 0,
        START_DATE: '',
        END_DATE: '',
        ACCOUNT_LIMIT: '',
        DAY_LIMIT: '',
        WEEK_LIMIT: '',
        MONTH_LIMIT: '',
        TOTAL_LIMIT: '',
        TEXT: '',
        STATUS: 0,
        DAY_CURRENT: '',
        WEEK_CURRENT: '',
        MONTH_CURRENT: '',
        TOTAL_CURRENT: '', 
        IS_PERMANENT: 1 
      })
      let newCalender = (JSON.parse(JSON.stringify(calender)))
      for (let key in newCalender) {
        newCalender[key] = null
      }
      setCalender(newCalender)
      handleDetailSettingShow()
    })
  }

  const handleReviseDetail = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setClickStatus('revise')
      setSwitchDelete(true)
      setSwitchSave(true)
      let checkTarget = detailCopy.find(ele => +ele.PRODUCT_ID === +e.target.dataset.id)
      setTargetObj(JSON.parse(JSON.stringify(checkTarget)))
      handleDetailSettingShow()
    })
  }

  const handleCheck = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setClickStatus('check')
      let checkTarget = detailCopy.find(ele => +ele.PRODUCT_ID === +e.target.dataset.id)
      setTargetObj(JSON.parse(JSON.stringify(checkTarget)))
      handleDetailSettingShow()
    })
  }

  const paymentNum = new Map([
    [1, '金幣'],
    [2, '鬥陣寶'],
  ])
  const transPayment = new Map(paymentNum)
  return (
    <>
      <BarBG className="navbar navbar-light bg-light">
        <div>
          <span>開關</span>
          <Dropdown id= "item1" value={change.navSwitch} onChange= {e => {handleChange(e.target.value, "navSwitch")}}>
            <option value="-1">全部</option>
            <option value="1">開啟</option>
            <option value="0">關閉</option>
          </Dropdown>
        </div>
        <div>
          <SearchButton onClick={handleSearch}>搜索</SearchButton>
          <ResetButton onClick={handleReset}>重置</ResetButton>
        </div>
      </BarBG>

      <AddItemInfoBtn variant="success" onClick= {handelAddItemDetail}>新增商品細項</AddItemInfoBtn>
      <CategoryName>分類商品設定 : {queryValue.CLASS_NAME}</CategoryName>

      <BasicTable className="table table-bordered">
        <thead>
          <BasicRowTitle>
            <th scope="col">ID</th>
            <th scope="col">排序</th>
            <th scope="col">子分類</th>
            <th scope="col">商品圖</th>
            <th scope="col">名稱</th>
            <th scope="col">付費</th>
            <th scope="col">價格</th>
            <th scope="col">標籤</th>
            <th scope="col">兌換時間(開始)</th>
            <th scope="col">兌換時間(結束)</th>
            <th scope="col">帳號限購</th>
            <th scope="col">單日限購</th>            
            <th scope="col">單周限購</th>
            <th scope="col">單月限購</th>
            <th scope="col">全服限購</th>
            <th scope="col">剩餘數量</th>
            <th scope="col">開關</th>
            <th scope="col">修改</th>
            <th scope="col">檢閱</th>
          </BasicRowTitle>
        </thead>
        <tbody>
          {
            Array.isArray(detailInfo) && detailInfo.length ? 
              detailInfo.map(ele=> {
                return (
                  <TableContent key= {ele.PRODUCT_ID}>
                    <td>{ele.PRODUCT_ID}</td>
                    <td>{ele.ORDER_ID}</td>
                    <td>{ele.PRODUCT_TYPE}</td>
                    <Frame>
                      <Avatar src={ele.IMG_URL} className="rounded" alt={ele.IMG_URL ? '商品圖片讀取失敗' : ''} />
                      <div style= {ele.IMG_URL ? {display: 'none'} : {display: 'block'}}>無商品圖片</div>
                    </Frame>
                    <td>{ele.PRODUCT_NAME}</td>
                    <td>{transPayment.get(+ele.COST_TYPE)}</td>
                    <td>{ele.COST_NUM}</td>
                    <td>{+!ele.ITEM_TAG ? '---' : transTag.get(+ele.ITEM_TAG)}</td>
                    <td>{ele.START_DATE ? TimezoneTranslator(ele.START_DATE) : '---'}</td>
                    <td>{ele.END_DATE ? TimezoneTranslator(ele.END_DATE) : '---'}</td>
                    <td>{!ele.ACCOUNT_LIMIT ? '---': ele.ACCOUNT_LIMIT}</td>
                    <td>{!ele.DAY_LIMIT ? '---': ele.DAY_LIMIT}</td>
                    <td>{!ele.WEEK_LIMIT ? '---': ele.WEEK_LIMIT}</td>
                    <td>{!ele.MONTH_LIMIT ? '---': ele.MONTH_LIMIT}</td>
                    <td>{!ele.TOTAL_LIMIT ? '---' : ele.TOTAL_LIMIT}</td>
                    <td>{!ele.TOTAL_LIMIT ? '---' : ele.TOTAL_LIMIT-ele.TOTAL_CURRENT}</td>
                    <td>{+ele.STATUS === 1 ? '開啟' : (+ele.STATUS === 0 ? '關閉' : '結束')}</td>
                    <PopDetail onClick= {handleReviseDetail} data-id= {ele.PRODUCT_ID}>修改</PopDetail>
                    <PopDetail onClick= {handleCheck} data-id= {ele.PRODUCT_ID}>查看</PopDetail>
                  </TableContent>
                )
              })
            :<tr key= "noInfo"><td colSpan= "19">尚無資料</td></tr> 
          }
        </tbody>
      </BasicTable>

      <LastPage onClick= {handleLastPage}>上一頁</LastPage>

      <AddRevisePU
        showDetailSetting= {showDetailSetting}
        handleDetailSettingClose= {handleDetailSettingClose}
        targetObj= {targetObj}
        clickStatus= {clickStatus}
        setClickStatus= {setClickStatus}
        calender= {calender}
        switchDelete= {switchDelete}
        switchSave= {switchSave}
        queryValue= {queryValue}
        detailCopy= {detailCopy}
        setDetailCopy= {setDetailCopy}
        setDetailInfo= {setDetailInfo}
      />
    </>
  )
}
