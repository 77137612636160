import { getAuthToken } from '../../utils.js'
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 取得商品訂單列表 PID39 ?//
export const orderList = (packageStatus, orderStatus, uid, orderID, sDate, eDate, sRow, eRow, allPage) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/exchange/order?packageStatus=${packageStatus}&orderStatus=${orderStatus}&uid=${uid}&orderID=${orderID}&sDate=${sDate}&eDate=${eDate}&sRow=${sRow}&eRow=${eRow}&allPage=${allPage}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 取得商品訂單內容 PID40 ?//
export const orderDetail = (orderIDList) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/exchange/order/${orderIDList}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 修改商品訂單內容 PID41 ?//
export const updateOrderDetail = (orderID, packageStatus, logistics, packageNum, remark, status) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/exchange/order`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      orderID,
      packageStatus,
      logistics,
      packageNum,
      remark,
      status
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 取得訂單讀取狀態 PID57 ?//
export const changeReadStatus = orderList => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/exchange/orderRead/${orderList}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}