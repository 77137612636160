import { useLocation, useHistory } from "react-router-dom"
import React, { useState } from "react"
import styled from "styled-components"
import { Container } from "react-bootstrap"
import { login } from "../middleware/Api/publicApi.js"
import RevisePassword from "../components/popUpWindow/login/revisePassword.js"
import { setAuthToken, setNavAcc, setAccessArr, setCharacterType } from "../middleware/utils.js"
import errorCode from "../assets/config/errorCode.json"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const AuthWrapper = styled.div`
  display: flex;
  justify-content: center;

  flex-direction: column;
  text-align: left;

  & .form-control:focus {
    border-color: #167bff;
    box-shadow: none;
  }

  & h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
  }

  & hr {
    background : #9a9a9a66;
  }
`

const AuthInner = styled.div`
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
`

const H3 = styled.h3`
  padding: 0px !important;
`

const Btn = styled.div`
  display: flex;
  justify-content: space-around;
`

const ReviseBtn = styled.button`
  margin-left: 700px;
  margin-top: 30px;
  color: #ff751a;
  border: 2px solid #ff751a;
  border-radius: 5px;
  transition: 0.7s;
  background: white;
  height: 3rem;

  :hover {
    color: white;
    font-weight: bold;
    background: #ff751a;
  }
`

function Login() {
  const location = useLocation()
  const mainPanel = React.useRef(null)
  React.useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    mainPanel.current.scrollTop = 0
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open")
      var element = document.getElementById("bodyClick")
      element.parentNode.removeChild(element)
    }
  }, [location])

  const history = useHistory()
  const [change, setChange] = useState({
    account: '',
    password: '',
    oldAccount: '',
    oldPassword: '',
    newPassword: '',
    checkNewPassword: ''
  })
  const [loginPage, setLoginPage] = useState(false)
  const [reviseFirst, setReviseFirst] = useState(false)
  const [showRevise, setShowRevise] = useState(false)
  const handleReviseClose = () => {
    setShowRevise(false)
  }
  const handleReviseShow = () => {
    setShowRevise(true)
  }

  const handleChange = e => {
    let newObj = Object.assign({}, change)
    newObj[e.target.name] = e.target.value
    setChange(newObj)
  }

  const handleSubmit = e => {
    e.preventDefault()
    const { account, password } = change
    if (!account || !password) {
      alert('請輸入空白處⊙ w ⊙')
      return
    } else {
      login(account, password).then(db => {
        if (db.error === errorCode.USER_NOT_FOUND.code) {
          alert(`※  帳號密碼不符`)
          setChange(prev => ({
            ...prev,
            account: '',
            password: ''
          }))
          return
        }
        if (db.error === errorCode.BLOCKADE.code) {
          alert('※  此用戶已禁用，欲重新啟用請洽負責人員')
          return
        }
        if (db.error === errorCode.SERVER_ERROR.code) {
          alert(`※  ${errorCode.SERVER_ERROR.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if (db.error) {
          alert('※  登入失敗，相關問題請洽詢工程部')
          return
        }

        if(db.msg){
          alert(`※  ${db.msg}`)
          return
        }
        
        if (!db.error && !db.msg) {
          setAuthToken(db.data.token)
          setAccessArr(JSON.stringify(db.data.user.accessArr))
          setCharacterType(JSON.stringify(db.data.user.type))
          if (!db.data.user.ifPasswordModify) {
            handleReviseShow()
            setReviseFirst(true)
          } else {
            setNavAcc(db.data.user.account)
            setReviseFirst(false)
            history.push('/admin/userInfo')
          }
        }
      })
    }
  }

  const handleUsualRevise = () => {
    setReviseFirst(false)
    setLoginPage(true)
    handleReviseShow()
  }
  return (
    <>
      <Wrapper className="wrapper">
        <div ref={mainPanel}>
          <Container fluid>
            <AuthWrapper>
              <AuthInner>
                <form onSubmit={handleSubmit}>
                  <H3>盛欣網絡科技股份有限公司</H3>
                  <hr size="8px" align="center" width="100%" />
                  <div className="form-group">
                    <label>Account</label>
                    <input
                      value={change.account}
                      name="account"
                      className="form-control"
                      placeholder="Enter Account"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      name="password"
                      value={change.password}
                      className="form-control"
                      placeholder="Enter Password"
                      onChange={handleChange}
                    />
                  </div>
                  <Btn>
                    <button
                      type="submit"
                      className="btn btn-info"
                      onClick={handleSubmit}
                    >登入</button>
                  </Btn>
                </form>
              </AuthInner>
            </AuthWrapper>
          </Container>

          <ReviseBtn onClick= {handleUsualRevise}>修改密碼</ReviseBtn>

          <RevisePassword
            show={showRevise}
            handleReviseClose= {handleReviseClose}
            setShow={setShowRevise}
            change={change}
            setChange={setChange}
            handleChange={handleChange}
            reviseFirst= {reviseFirst}
            loginPage= {loginPage}
          />
        </div>
      </Wrapper>
    </>
  )
}
export default Login