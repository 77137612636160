import { getAuthToken } from '../../utils.js'
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 取得任務獎勵清單 PID47 ?//
export const getDzbMission = (gameID, gameType) => {  //PID47
  const token = getAuthToken()
  return fetch(`${BaseUrl}/serverEnv/gameMission?gameID=${gameID}&gameType=${gameType}`, {
    method: 'GET',
    headers: {
      'Authorization' : `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 修改遊戲任務獎勵權重 PID48 ?//
export const updateDzbMission = (gameID, gameType, missionID, weight, awardMin, awardMax) => {  //PID48
  const token = getAuthToken()
  return fetch(`${BaseUrl}/serverEnv/gameMission`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      gameID,
      gameType,
      missionID,
      weight,
      awardMin,
      awardMax
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}