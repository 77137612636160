import { getAuthToken } from '../../utils.js'
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 取得暫存公告清單 PID17 ?//
export const announcementList = () => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/announcement`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 取得暫存公告詳細內容 PID18 ?//
export const announcementDetail = orderId => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/announcement/${orderId}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 新增暫存公告 PID19 ?//
export const addNewAnnouncement = (title, subtitle, contain, type, bDate, eDate, state, orderId) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/announcement`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      title,
      subtitle,
      contain,
      type,
      bDate,
      eDate,
      state,
      orderId
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 刪除站存公告內容 PID20 ?// 
export const deleteAnnouncement = orderId => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/announcement/${orderId}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      orderId
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 更新(修改)暫存公告內容 PID21 ?//
export const updateAnnouncement = (title, subtitle, contain, type, bDate, eDate, state, orderId, oldOrderId) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/announcement`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      title,
      subtitle,
      contain,
      type,
      bDate,
      eDate,
      state,
      orderId,
      oldOrderId
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 送出暫存公告(通知) PID22 ?//
export const submitAnnouncement = () => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/announcement/submit`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}