import styled from 'styled-components'
import { BasicInput } from "../../../middleware/utilityStyle"

export const GameType = styled.td`
  display: flex;
  justify-content: space-evenly;
`

export const MissionInput = styled(BasicInput)`
  width: 100px;
  text-align: center;
`

export const RBtn = styled.div`
  display: flex;
  justify-content: space-around;
`