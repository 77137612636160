import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { TimezoneTranslator } from "../../../middleware/dateUtils"
import { getAccessArr } from "../../../middleware/utils"
import Modal from "react-bootstrap/Modal"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import Box from '@mui/material/Box'
import { Button } from "react-bootstrap"
import {
  CheckStyle
} from "../../../views/eventManagement/dzbMissionEvent/styledComponent.js"
import {
  DetailInput,
  SendTimeWrapper,
  TimeFormat,
  LimitTimes,
  PUDropdown
} from "../../../views/operation/itemDetails/styledComponent"
import '../../../views/BTNmanipulateRecord/manipulateRecord.css'
import { BasicHeadTitle, ModalFormat, ModalBtn } from "../../../middleware/utilityStyle"
import DeleteHint from "./deleteHint.js"
import { getDZBMissionEventList, addDZBMissionEvent, reviseDZBMissionEvent } from "../../../middleware/Api/eventManagement/dzbMissionEventApi"

export default function AddRevisePU(props) {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const history = useHistory()
  const {
    showAddReviseMissionEvent,
    handleAddReviseMissionEventClose,
    handleCleanPopData,
    targetObj,
    clickStatus,
    switchSave,
    switchDelete,
    calender,
    setPageCounter,
    setPanelInfo,
    setDataCopy,
    setPage
  } = props

  const [calenderInfo, setCalenderInfo] = useState({})
  const [targetInfo, setTargetInfo] = useState({
    ACTIVITY_ID: '',
    ACTIVITY_NAME: '',
    START_DATE: null,
    END_DATE: null,
    CLOSE_DATE: null,
    GAME_ID: '',
    GEM_MISSION_STATE: 0,
    AD_RESET_TIMES: '',
    GEM_MISSION_TEXT: '',
    REACH_TIMES: '',
    SEND_GEM_NUM: ''
  })

  const [checkValueArr, setCheckValueArr] = useState([])
  const handleTarget = (e, key) => {
    if (key === 'GAME_ID') {
      let newArr = []
      const targetIndex = checkValueArr.findIndex(ele => ele === e)
      if(targetIndex >= 0){
        checkValueArr.splice(targetIndex, 1)
        newArr = [...checkValueArr]
      } else {
        newArr = [...checkValueArr, e]
      }
      let targetArr = newArr.filter(ele => ele.length)
      setCheckValueArr(targetArr)
      setTargetInfo(prev => ({
        ...prev,
        GAME_ID: targetArr.join(',')
      }))
    } else {
      setTargetInfo((prev) => ({
        ...prev,
          [key]: e
      }))
    }
    
  }

  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (clickStatus !== 'add') {
        setTargetInfo(JSON.parse(JSON.stringify(targetObj)))
        setCalenderInfo({
          startDate: targetObj.START_DATE,
          startTime: targetObj.START_DATE,
          endDate: targetObj.END_DATE,
          endTime: targetObj.END_DATE,
          closeDate: targetObj.CLOSE_DATE,
          closeTime: targetObj.CLOSE_DATE
        })
        setCheckValueArr(JSON.parse(JSON.stringify(targetObj)).GAME_ID.split(','))
      } else {
        setCalenderInfo({
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
          closeDate: null,
          closeTime: null
        })
      }
    })
  }, [targetObj, calender])

  const [showDeleteHint, setDeleteHintShow] = useState(false)
  const handleDeleteHintClose = () => setDeleteHintShow(false)
  const handleDeleteHintShow = () => setDeleteHintShow(true)

  const handlePopUpClose = () => {
    handleAddReviseMissionEventClose()
    handleCleanPopData()
    setTimeout(() => {
      setTargetInfo({
        ACTIVITY_ID: '',
        ACTIVITY_NAME: '',
        START_DATE: null,
        END_DATE: null,
        CLOSE_DATE: null,
        GAME_ID: '',
        GEM_MISSION_STATE: 0,
        AD_RESET_TIMES: '',
        GEM_MISSION_TEXT: '',
        REACH_TIMES: '',
        SEND_GEM_NUM: ''
      })
      let newCalenderInfo = (JSON.parse(JSON.stringify(calenderInfo)))
      for (let key in newCalenderInfo) {
        newCalenderInfo[key] = null
      }
      setCalenderInfo(newCalenderInfo)
    }, 500)
  }

  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 88)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      handleAddReviseMissionEventClose()
      handleDeleteHintShow()
    })
  }

  function addLastSecond(date) {
    let dateStr = new Date(date).toString()
    let dateArr = dateStr.split(' ')
    let dateTimeArr = dateStr.split(' ')[4].split(':')
    dateTimeArr.splice(2, 1, '59')  //ex: [ '09', '30', '00' ] -> [ '09', '30', '59' ]
    let lastTime = dateTimeArr.join(':')
    dateArr.splice(4, 1, lastTime)
    let result = dateArr.join(' ')
    return result
  }

  function dateMaker(startDate, startTime, endDate, endTime, closeDate, closeTime) {
    let SSet = ''
    let ESet = ''
    let CSet = ''

    let SDate = new Date(startDate).toString().split(' ').slice(0, 4).join(' ')
    let STime = new Date(startTime).toString().split(' ').slice(4).join(' ')
    let EDate = new Date(endDate).toString().split(' ').slice(0, 4).join(' ')
    let ETime = addLastSecond(endTime).split(' ').slice(4).join(' ')
    let CDate = new Date(closeDate).toString().split(' ').slice(0, 4).join(' ')
    let CTime = addLastSecond(closeTime).split(' ').slice(4).join(' ')

    SSet = `${SDate} ${STime}`
    ESet = `${EDate} ${ETime}`
    CSet = `${CDate} ${CTime}`
    if (new Date(SSet).getTime() > new Date(ESet).getTime()) {
      alert('※  結束時間不可早於開始時間')
      return
    }
    if (new Date(CSet).getTime() > new Date(ESet).getTime()) {
      alert('※  關閉時間不可晚於於結束時間')
      return
    }
    setTargetInfo(prev => ({
      ...prev,
      START_DATE: SSet,
      END_DATE: ESet,
      CLOSE_DATE: CSet
    }))
    return {SSet, ESet, CSet}
  }

  const handleSave = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (clickStatus === 'add') {
        if (!userPID.find(ele => +ele === 83)) {
          alert('※無此權限  ʕ๑•́ᴥ•̀๑ʔ')
          return
        }
      }
      if (clickStatus === 'revise') {
        if (!userPID.find(ele => +ele === 84)) {
          alert('※無此權限  ʕ๑•́ᴥ•̀๑ʔ')
          return
        }
      }      
      if (!targetInfo.ACTIVITY_NAME) {
        alert('※  請填入活動名稱')
        return
      }      
      let checkFillIn = []
      for (let key in calenderInfo) {
        checkFillIn.push(calenderInfo[key])  
      }
      if (!checkFillIn.every(ele => ele)) {
        alert('※  日期、時間不可為空')
        return
      }
      if (!checkValueArr.length) {
        alert('※  請選擇任務來源')
        return
      }
      if (!targetInfo.AD_RESET_TIMES) {
        setTargetInfo(prev => ({
          ...prev,
          AD_RESET_TIMES: 0
        }))
      }
      let {
        ACTIVITY_ID,
        ACTIVITY_NAME,
        GAME_ID,
        GEM_MISSION_STATE,
        AD_RESET_TIMES,
        GEM_MISSION_TEXT
      } = targetInfo
      let dateObj = dateMaker(calenderInfo.startDate, calenderInfo.startTime, calenderInfo.endDate, calenderInfo.endTime, calenderInfo.closeDate, calenderInfo.closeTime)
      if (clickStatus === 'add') {
        addDZBMissionEvent(ACTIVITY_NAME, new Date(dateObj.SSet).toISOString(), new Date(dateObj.ESet).toISOString(), new Date(dateObj.CSet).toISOString(),
        GAME_ID, +GEM_MISSION_STATE, +AD_RESET_TIMES, GEM_MISSION_TEXT).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          handlePopUpClose()
          setTimeout(() => {
            alert('新增成功  ʕ๑´ㅂ`๑ʔ')
          }, 500)
        })
      }
      if (clickStatus === 'revise') {
        reviseDZBMissionEvent(+ACTIVITY_ID, ACTIVITY_NAME, new Date(dateObj.SSet).toISOString(), new Date(dateObj.ESet).toISOString(), new Date(dateObj.CSet).toISOString(),
        GAME_ID, +GEM_MISSION_STATE, +AD_RESET_TIMES, GEM_MISSION_TEXT).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          setTimeout(() => {
            alert('修改成功  ʕ๑´ㅂ`๑ʔ')
          }, 500)
          handlePopUpClose()
        })
      }
      getDZBMissionEventList().then(db=> {
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if (db.error === errorCode.SERVER_ERROR.code) {
          alert(`※  ${errorCode.SERVER_ERROR.memo}`)
          return
        }
        let data = []
        for (var i = 0; i < db.data.length; i += 30) {
          data.push(db.data.slice(i, i + 30))
        }
        setPage(1)
        setPageCounter(data.length)
        setPanelInfo(data)
        setDataCopy(db.data)
      })
    })
  }
  return (
    <>
      <ModalFormat
        show={showAddReviseMissionEvent}
        onHide={handlePopUpClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{clickStatus === 'revise' ? '修改' : '新增' }鬥陣寶任務活動</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <BasicHeadTitle>ID</BasicHeadTitle>
              <td colSpan= "3" style= {{color: '#a6a6a6'}}>
                {clickStatus === 'revise' ? targetInfo.ACTIVITY_ID : '系統自動生成'}
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>活動名稱</BasicHeadTitle>
              <td colSpan= "3">
                <DetailInput
                  placeholder= "請輸入文字"
                  value= {targetInfo.ACTIVITY_NAME}
                  onChange= {e => {handleTarget(e.target.value, 'ACTIVITY_NAME')}}
                />
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>開放日期</BasicHeadTitle>
              <td colSpan= "3">
              <SendTimeWrapper>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label= "日期"
                      name= "startDate"
                      value={calenderInfo.startDate ? TimezoneTranslator(new Date(calenderInfo.startDate).toISOString()) : ''}
                      onChange= {newValue => {
                        setCalenderInfo(prev => ({
                          ...prev,
                          startDate: newValue
                        }))
                      }}
                      renderInput={({ inputRef, inputProps, InputProps }) => {
                        inputProps.placeholder = '請選擇開始日期'
                        return (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <DetailInput
                              id= "datePicker1"
                              ref={inputRef} {...inputProps}
                            />
                            <div id="dateBtn1">{InputProps?.endAdornment}</div>
                          </Box>
                        )
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label="時間"
                      name= "startTime"
                      value={calenderInfo.startTime ? TimezoneTranslator(new Date(calenderInfo.startTime).toISOString()) : ''}
                      onChange= {newValue => {
                        setCalenderInfo(prev => ({
                          ...prev,
                          startTime: newValue
                        }))
                      }}
                      renderInput={({ inputRef, inputProps, InputProps }) => {
                        inputProps.placeholder = '請選擇開始時間'
                        return (
                          <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                            <DetailInput
                            id= "timePicker1"
                            ref={inputRef} {...inputProps}
                          />
                            {InputProps?.endAdornment}
                          </TimeFormat>
                        )
                      }}
                    />
                  </LocalizationProvider>
                </SendTimeWrapper>
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>結束日期</BasicHeadTitle>
              <td colSpan= "3">
              <SendTimeWrapper>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label= "日期"
                      name= "endDate"
                      value={calenderInfo.endDate ? TimezoneTranslator(new Date(calenderInfo.endDate).toISOString()) : ''}
                      onChange= {newValue => {
                        setCalenderInfo(prev => ({
                          ...prev,
                          endDate: newValue
                        }))
                      }}
                      renderInput={({ inputRef, inputProps, InputProps }) => {
                        inputProps.placeholder = '請選擇結束日期'
                        return (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <DetailInput
                              id= "datePicker2"
                              ref={inputRef} {...inputProps}
                            />
                            <div id="dateBtn2">{InputProps?.endAdornment}</div>
                          </Box>
                        )
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label="時間"
                      name= "endTime"
                      value={calenderInfo.endTime ? TimezoneTranslator(new Date(calenderInfo.endTime).toISOString()) : ''}
                      onChange= {newValue => {
                        setCalenderInfo(prev => ({
                          ...prev,
                          endTime: newValue
                        }))
                      }}
                      renderInput={({ inputRef, inputProps, InputProps }) => {
                        inputProps.placeholder = '請選擇結束時間'
                        return (
                          <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                            <DetailInput
                            id= "timePicker2"
                            ref={inputRef} {...inputProps}
                          />
                            {InputProps?.endAdornment}
                          </TimeFormat>
                        )
                      }}
                    />
                  </LocalizationProvider>
                </SendTimeWrapper>
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>關閉日期</BasicHeadTitle>
              <td colSpan= "3">
                <SendTimeWrapper>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label= "日期"
                      name= "closeDate"
                      value= {calenderInfo.closeDate ? TimezoneTranslator(new Date(calenderInfo.closeDate).toISOString()) : ''}
                      onChange= {newValue => {
                        setCalenderInfo(prev => ({
                          ...prev,
                          closeDate: newValue
                        }))
                      }}
                      renderInput={({ inputRef, inputProps, InputProps }) => {
                        inputProps.placeholder = '請選擇結束日期'
                        return (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <DetailInput
                              id= "datePicker3"
                              ref= {inputRef} {...inputProps}
                            />
                            <div id="dateBtn3">{InputProps?.endAdornment}</div>
                          </Box>
                        )
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label="時間"
                      name= "closeTime"
                      value= {calenderInfo.closeTime ? TimezoneTranslator(new Date(calenderInfo.closeTime).toISOString()) : ''}
                      onChange= {newValue => {
                        setCalenderInfo(prev => ({
                          ...prev,
                          closeTime: newValue
                        }))
                      }}
                      renderInput= {({ inputRef, inputProps, InputProps }) => {
                        inputProps.placeholder = '請選擇結束時間'
                        return (
                          <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                            <DetailInput
                              id= "timePicker3"
                              ref= {inputRef} {...inputProps}
                            />
                            {InputProps?.endAdornment}
                          </TimeFormat>
                        )
                      }}
                    />
                  </LocalizationProvider>
                </SendTimeWrapper>
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>任務來源</BasicHeadTitle>
              <td colSpan= "3">
                <CheckStyle>
                  <span>
                    <input
                      type= "checkbox"
                      value= "7001"
                      onChange= {e => {handleTarget(e.target.value, 'GAME_ID')}}
                      checked= {targetInfo.GAME_ID.split(',').find(ele => +ele === 7001) ? true : false}
                    />暗棋
                  </span>
                  <span>
                    <input
                      type= "checkbox"
                      value= "7002"
                      onChange= {e => {handleTarget(e.target.value, 'GAME_ID')}}
                      checked= {targetInfo.GAME_ID.split(',').find(ele => +ele === 7002) ? true : false}
                    />麻將
                  </span>
                  <span>
                    <input
                      type= "checkbox"
                      value= "7004"
                      onChange= {e => {handleTarget(e.target.value, 'GAME_ID')}}
                      checked= {targetInfo.GAME_ID.split(',').find(ele => +ele === 7004) ? true : false}
                    />二人麻將
                  </span>
                </CheckStyle>
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>規則文案</BasicHeadTitle>
              <td colSpan= "3">
                <textarea
                  id= "TArea"
                  className="form-control"
                  rows= "5"
                  placeholder= "請填寫內文"
                  value= {targetInfo.GEM_MISSION_TEXT}
                  onChange= {e => {handleTarget(e.target.value, 'GEM_MISSION_TEXT')}}
                />
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>廣告重置字數</BasicHeadTitle>
              <td colSpan= "3">                
                <LimitTimes
                  placeholder= "請輸入數字"
                  value= {targetInfo.AD_RESET_TIMES}
                  onChange= {e => {handleTarget(e.target.value, 'AD_RESET_TIMES')}}
                />
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>開關</BasicHeadTitle>
              <td colSpan= "3">
                <PUDropdown
                  className= "btn dropdown-toggle"
                  value= {+targetInfo.GEM_MISSION_STATE}
                  onChange= {e => {handleTarget(e.target.value, 'GEM_MISSION_STATE')}}
                >
                  <option className= "dropdown-item" value= "0">關閉</option>
                  <option className= "dropdown-item" value= "1">開啟</option>
                </PUDropdown>
              </td>
            </tr>

          </tbody>
        </table>
        </Modal.Body>
        <ModalBtn>
          <Button style= {switchDelete ? {display: 'block'} : {display: 'none'}} onClick= {handleDelete} variant="danger">刪除</Button>
          <Button style= {switchSave ? {display: 'block'} : {display: 'none'}} onClick= {handleSave} variant="success">儲存</Button>
        </ModalBtn>
      </ModalFormat>

      <DeleteHint
        showDeleteHint= {showDeleteHint}
        handleDeleteHintClose= {handleDeleteHintClose}
        targetInfo= {targetInfo}
        setTargetInfo= {setTargetInfo}
        calenderInfo= {calenderInfo}
        setCalenderInfo = {setCalenderInfo}
        handleCleanPopData= {handleCleanPopData}
        setPage= {setPage}
        setPageCounter= {setPageCounter}
        setPanelInfo= {setPanelInfo}
        setDataCopy= {setDataCopy}
      />
    </>
  )
}