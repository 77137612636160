import React from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { ModalBtn, ModalText } from "../../../middleware/utilityStyle"
import { deleteGameList,  getGameList} from "../../../middleware/Api/operation/gameIdSettingApi"

export default function Delete(props) {
  const {
    showDelete,
    handleDeleteClose,
    popData,
    setDataCopy,
    setPanelInfo
  } = props
  const history = useHistory()
  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      deleteGameList(+popData.GAME_ID).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        getGameList().then(db => {
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          if (db.error === errorCode.NULL_DATA.code) {
            alert(`※  ${errorCode.NULL_DATA.memo}`)
            return
          }
          setPanelInfo(db.data)
          setDataCopy(db.data)
          handleDeleteClose()
        })
      })
    })
  }
  return (
    <>
      <Modal
        show={showDelete}
        onHide={handleDeleteClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>刪除提示</Modal.Title>
        </Modal.Header>
        <ModalText>
          確定刪除此筆資料?
        </ModalText>
        <ModalBtn>
          <Button variant="info" onClick= {handleDelete}>確定</Button>
          <Button variant="secondary" onClick= {handleDeleteClose}>取消</Button>
        </ModalBtn>
      </Modal>

    </>
  )
}