import React from "react"
import { useLocation } from "react-router-dom"
import { Navbar, Container, Button } from "react-bootstrap"
import styled from "styled-components"
import routes from "../../middleware/routes/routes"
import { getNavAcc} from "../../middleware/utils"

const BreadCrump = styled.div`
  color: #888888;
  margin-top: 15px;
  margin-left: 15px;
`

const Greeting = styled.div`
  color: #888888;
`

function Header() {
  const location = useLocation()
  const mobileSidebarToggle = e => {
    e.preventDefault()
    document.documentElement.classList.toggle("nav-open")
    var node = document.createElement("div")
    node.id = "bodyClick"
    node.onclick = function () {
      this.parentElement.removeChild(this)
      document.documentElement.classList.toggle("nav-open")
    }
    document.body.appendChild(node)
  }

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      for (let j = 0; j < routes[i].content.length; j++) {
        if (location.pathname.indexOf(routes[i].content[j].layout + routes[i].content[j].path) !== -1) {
          return [routes[i].title, routes[i].content[j].name]        
        }
      }
    }
    return "Brand"
  }
  const dayArr = new Map([
    [0, '日'],
    [1, '一'],
    [2, '二'],
    [3, '三'],
    [4, '四'],
    [5, '五'],
    [6, '六']
  ])
  let transDay = new Map(dayArr)
  return (
    <>
      <BreadCrump>當前位置 &gt; {getBrandText()[0]} &gt; {getBrandText()[1]}</BreadCrump>
      <Navbar bg="light" expand="lg">

        <Container fluid>
          <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
            <Button
              variant="dark"
              className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
              onClick={mobileSidebarToggle}
            >
              <i className="fas fa-ellipsis-v"></i>
            </Button>
            <Navbar.Brand
              href="#home"
              onClick={e => e.preventDefault()}
              className="mr-2"
            >
              {getBrandText()[1]}
            </Navbar.Brand>
          </div>
            <Greeting className= "greeting">歡迎，{getNavAcc()} ! 今天是{new Date().toLocaleDateString()} 星期{transDay.get(new Date().getDay())}</Greeting>
        </Container>
      </Navbar>
    </>
  )
}

export default Header