import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { today, yesterday, thisWeek, lastWeek, thisMonth, defaultDate, defaultDateE, TimezoneTranslator } from "../../middleware/dateUtils"
import { sessionVerified } from "../../middleware/Api/publicApi.js"
import { getRecord } from "../../middleware/Api/manipulateRecordApi"
import errorCode from "../../assets/config/errorCode.json"
import dataConversion from "../../assets/config/manipulateRecord.json"
import {
  FirstRow,
  BlankRWD,
  FirstBlank,
  Blank,
  SecondRow,
  RangeWrapper,
  DatePickerInput,
  RangeGroup,
  BtnGroup,
  Content
} from "./styledComponent"
import './manipulateRecord.css'
import {
  BarBG,
  DateWrapper,
  DateTitle,
  DateStack,
  ClickRange,
  SearchButton,
  ResetButton,
  BasicTable,
  BasicRowTitle,
  PageStyle
} from "../../middleware/utilityStyle.js"

export default function ManipulateRecord() {
  const history = useHistory()
  const [panelInfo, setPanelInfo] = useState([])
  const [change, setChange] = useState({
    navAccount: '',
    navType: '',
    navContent: '',
    maniDate: [new Date(defaultDate), new Date(defaultDateE)]
  })

  const [changePagePara, setChangePagePara] = useState({
    operator: 'all',
    operateType: 'all',
    operateContent: 'all',
    sDate: new Date(defaultDate).toISOString(),
    eDate: new Date(defaultDateE).toISOString()
  })
  const handleChange = (e, key) => {
    setChange((prev) => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const [pageCounter, setPageCounter] = useState(0)
  const [page, setPage] = useState(1)
  const handleChangePage = (event, newPage) => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPage(newPage)
      const {operator, operateType, operateContent, sDate, eDate} = changePagePara
      getRecord(operator, operateType, operateContent, sDate, eDate, 1+(newPage-1)*20, newPage*20).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setPanelInfo(db.data)
      })
    })
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      let operator = 'all'
      if (change.navAccount) {
        operator = change.navAccount
        setChangePagePara(prev => ({
          ...prev,
          operator
        }))
      }

      let operateType = 'all'
      if (change.navType) {
        operateType = change.navType
        setChangePagePara(prev => ({
          ...prev,
          operateType
        }))
      }

      let operateContent = 'all'
      if (change.navContent) {
        operateContent = change.navContent
        setChangePagePara(prev => ({
          ...prev,
          operateContent
        }))
      }

      let sDate = new Date(defaultDate).toISOString()
      let eDate = new Date(defaultDateE).toISOString()
      if (!change.maniDate[0] || !change.maniDate[1]) {
        alert('※  請輸入日期')
        return
      }

      if (change.maniDate[0] && change.maniDate[1]) {
        sDate = new Date(change.maniDate[0]).toISOString()
        let end = new Date(change.maniDate[1]).toString()
        eDate = new Date(end.replace('00:00:00', '23:59:59')).toISOString()
        setChangePagePara(prev => ({
          ...prev,
          sDate,
          eDate
        }))
      }
      getRecord(operator, operateType, operateContent, sDate, eDate, 1, 20).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          getRecord('all', 'all', 'all', new Date(defaultDate).toISOString(), new Date(defaultDateE).toISOString(), 1, 20).then(db => {
            if (db.error === errorCode.INCORRECT_DATA.code) {
              alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.INCORRECT_FORMAT.code) {
              alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            if (db.error === errorCode.NULL_DATA.code) {
              alert(`※  預設時間無資料`)
              return
            }
            setChange(prev => ({
              ...prev,
              maniDate: [new Date(defaultDate), new Date(defaultDateE)]
            }))
            setChangePagePara(prev => ({
              ...prev,
              operator: 'all',
              operateType: 'all',
              operateContent: 'all',
              sDate: new Date(defaultDate).toISOString(),
              eDate: new Date(defaultDateE).toISOString()
            }))
            if(db.data) {
              if (db.data.length > 0) {
                setPageCounter(
                  db.data[0].TOTAL_ROW_NUM%20 === 0
                  ? Math.floor(db.data[0].TOTAL_ROW_NUM/20)
                  : (Math.floor(db.data[0].TOTAL_ROW_NUM/20)) + 1
                )
              }
            }
            setPanelInfo(db.data)
            setPage(1)
          })
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if(db.data) {
          if (db.data.length > 0) {
            setPageCounter(
              db.data[0].TOTAL_ROW_NUM%20 === 0
              ? Math.floor(db.data[0].TOTAL_ROW_NUM/20)
              : (Math.floor(db.data[0].TOTAL_ROW_NUM/20)) + 1
            )
          }
        }
        setPanelInfo(db.data)
        setPage(1)
      })
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({
        navAccount: '',
        navType: '',
        navContent: '',
        maniDate: [new Date(defaultDate), new Date(defaultDateE)]
      })
    })
  }

  function  getTarget(dataUrl) {
    if (dataConversion.find(ele => ele.url === dataUrl)) {
      return dataConversion.find(ele => ele.url === dataUrl)
    }
  }
  const handleSendMail = content => {
    const {url, method} = content
    const {isNow} = content.req
    let target = {}
    target = getTarget(url)
    return `以「${target.isNow[isNow]}」做${target.method[method]}信件`
  }
  const handleAnnouncement = content => {
    const {url, method} = content
    let target = {}
    let result = ''
    if (url === '/announcement') {
      target = getTarget(url)
      if (method === 'POST' || method === 'PUT') {
        result = `以「${content.req.orderId}」做${target.method[method]}公告`
      }
    }
    if (/\/[a-zA-Z]*\/[0-9]*/gm.test(url) && method === 'DELETE') {
      target = getTarget('/announcement')
      result = `以「${content.req.orderId}」做${target.method[content.method]}`
    }
    if (url === '/announcement/submit') {
      target = getTarget('/announcement/submit')
      result = `以「所有公告」做通知`
    }
    return result
  }
  const handleNewsTicker = content => {
    const {url, method} = content
    let target = {}
    let result = ''
    if (url === '/newsTicker') {
      target = getTarget(url)
      if (method === 'POST' || method === 'PUT') {
        result = `以「${content.req.orderId}」做${target.method[method]}`
      }
    }
    if (/\/[a-zA-Z]*\/[0-9]*/gm.test(url) && method === 'DELETE') {
      target = getTarget('/newsTicker')
      result = `以「${content.req.orderId}」做${target.method[method]}`
    }
    if (url === '/newsTicker/submit') {
      target = getTarget('/newsTicker/submit')
      result = `以「所有跑馬燈」做通知`
    }
    return result
  }
  const handleItem = content => {
    const {url, method} = content
    let target = {}
    let result = ''
    target = getTarget(url)

    if (url === '/exchange/list') {
      if (method === 'POST') {
        result = `以「${content.res.data.CLASS_ID}，${content.req.className}」做${target.method[method]}`
      }
      if (method === 'PUT') {
        result = `以「${content.req.classID}，${content.req.className}」做${target.method[method]}`
      }
    }
    if (url === '/exchange/deleteList') {
      result = `以「${content.req.classID}, ${content.req.className}」做${target.method[method]}`
    }

    if (url === '/exchange') {
      if (method === 'POST') {
        result = `以「${content.res.data.ITEM_ID}，${content.req.itemName}」做${target.method[method]}`
      }
      if (method === 'PUT') {
        result = `以「${content.req.itemID}，${content.req.itemName}」做${target.method[method]}`
      }
    }
    if (url === '/exchange/delete') {
      result = `以「${content.req.itemID}, ${content.req.itemName}」做${target.method[method]}`
    }
    return result
  }
  const handleSendText = content => {
    let addressTo = content.req.sendAll ? "全部" : "特定"
    let target = getTarget(content.url)
    let result = `以「${addressTo}用戶，${content.req.SMScontain}」做${target.method[content.method]}`
    return result
  }
  const handleDzbMission = content => {
    const {missionID, weight, awardMin, awardMax} = content.req
    let target = getTarget(content.url)
    let result = `以「${missionID}，${weight}，${awardMin}，${awardMax}」做${target.method[content.method]}`
    return result
  }
  const handleOrder = content => {
    const {orderID, packageStatus, status} = content.req
    let deliveryStatusArr = new Map([
      [1, '訂單受理'],
      [2, '已備貨'],
      [3, '申請換貨'],
      [4, '已出貨']
    ])
    let deliveryStatusName = new Map(deliveryStatusArr)
    let result = `以「${orderID}」調整「${deliveryStatusName.get(packageStatus)}」、「${status === 1 ? '進行中' : '已結案'}」`
    return result
  }
  return(
    <>
      <BarBG className="navbar navbar-light bg-light">
        <FirstRow>
          <FirstBlank>
            操作人<Blank placeholder= "請輸入帳號名稱" value= {change.navAccount} onChange= {e=> {handleChange(e, 'navAccount')}}/>
          </FirstBlank>
          <BlankRWD>
            操作類型<Blank placeholder= "請輸入欲查詢類型" value= {change.navType} onChange= {e => {handleChange(e, 'navType')}}/>
          </BlankRWD>
          <BlankRWD>
            操作內容<Blank placeholder= "請輸入欲查詢文字" value= {change.navContent} onChange= {e=> {handleChange(e, 'navContent')}}/>
          </BlankRWD>
        </FirstRow>

        <SecondRow>
          <RangeWrapper>
            <DateWrapper>
              <DateTitle>操作時間</DateTitle>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateStack spacing={3}>
                  <DesktopDateRangePicker
                    startText=""
                    endText=""
                    value={change.maniDate}
                    onChange={(newValue) => {
                      setChange((prev) => ({
                        ...prev,
                        maniDate: newValue
                      }))
                    }}
                    renderInput={(startProps, endProps) => {
                      startProps.inputProps.placeholder = '請輸入日期'
                      endProps.inputProps.placeholder = '請輸入日期'
                      return (
                        <>
                          <DatePickerInput ref={startProps.inputRef} {...startProps.inputProps} />
                          <Box sx={{ mx: 1 }}>至</Box>
                          <DatePickerInput ref={endProps.inputRef} {...endProps.inputProps} />
                        </>
                      )
                    }}
                  />
                </DateStack>
              </LocalizationProvider>
            </DateWrapper>
            <RangeGroup>
              <ClickRange
                onClick={() => {
                  setChange((prev) => ({
                    ...prev,
                    maniDate: today
                  }))
              }}>今天</ClickRange>
              <ClickRange
                onClick={() => {
                  setChange((prev) => ({
                    ...prev,
                    maniDate: yesterday
                  }))
                }}>昨天</ClickRange>
              <ClickRange
                onClick={() => {
                  setChange((prev) => ({
                    ...prev,
                    maniDate: thisWeek
                  }))
                }}>本周</ClickRange>
              <ClickRange
                onClick={() => {
                  setChange((prev) => ({
                    ...prev,
                    maniDate: lastWeek
                  }))
                }}>上周</ClickRange>
              <ClickRange
                onClick={() => {
                  setChange((prev) => ({
                    ...prev,
                    maniDate: thisMonth
                  }))
                }}>本月</ClickRange>
            </RangeGroup>
          </RangeWrapper>
          <BtnGroup>
            <SearchButton onClick= {handleSearch}>搜索</SearchButton>
            <ResetButton onClick = {handleReset}>重置</ResetButton>
          </BtnGroup>
        </SecondRow>
      </BarBG>

      <BasicTable className="table table-hover table-bordered">
        <thead>
          <BasicRowTitle>
            <th scope= "col">操作人</th>
            <th scope= "col">操作類型</th>
            <Content scope= "col">操作內容</Content>
            <th scope= "col">操作時間</th>
          </BasicRowTitle>
        </thead>
        <tbody>
          {
            Array.isArray(panelInfo) && panelInfo.length > 0 ?
            panelInfo.map((ele, index) => {
              const operateContentUrl = JSON.parse(ele.OPERATE_CONTENT).url
              if (operateContentUrl === '/gameMail') {
                return (
                  <>
                    <tr key= {index}>
                      <td>{ele.OPERATOR}</td>
                      <td>{ele.OPERATE_TYPE}</td>
                      <td><textarea id= "TArea" value= {handleSendMail(JSON.parse(ele.OPERATE_CONTENT))} rows= "5" disabled/></td>
                      <td>{new Date(ele.LOG_DATE).toLocaleString()}</td>
                    </tr>
                  </>
                )
              }
              if (operateContentUrl.includes('/announcement')) {
                return (
                  <>
                    <tr key= {index}>
                      <td>{ele.OPERATOR}</td>
                      <td>{ele.OPERATE_TYPE}</td>
                      <td><textarea id= "TArea" value= {handleAnnouncement(JSON.parse(ele.OPERATE_CONTENT))} rows= "5" disabled/></td>
                      <td>{new Date(ele.LOG_DATE).toLocaleString()}</td>
                    </tr>
                  </>
                )
              }
              if (operateContentUrl.includes('/newsTicker')) {
                return (
                  <>
                    <tr key= {index}>
                      <td>{ele.OPERATOR}</td>
                      <td>{ele.OPERATE_TYPE}</td>
                      <td><textarea id= "TArea" value= {handleNewsTicker(JSON.parse(ele.OPERATE_CONTENT))} rows= "5" disabled/></td>
                      <td>{new Date(ele.LOG_DATE).toLocaleString()}</td>
                    </tr>
                  </>
                )
              } 
              if (operateContentUrl.includes('/exchange')) {
                return (
                  <>
                    <tr key= {index}>
                      <td>{ele.OPERATOR}</td>
                      <td>{ele.OPERATE_TYPE}</td>
                      <td><textarea id= "TArea" value= {handleItem(JSON.parse(ele.OPERATE_CONTENT))} rows= "5" disabled/></td>
                      <td>{new Date(ele.LOG_DATE).toLocaleString()}</td>
                    </tr>
                  </>
                )
              } 
              if (operateContentUrl === '/serverEnv/postSMS') { 
                return (
                  <>
                    <tr key= {index}>
                      <td>{ele.OPERATOR}</td>
                      <td>{ele.OPERATE_TYPE}</td>
                      <td><textarea id= "TArea" value= {handleSendText(JSON.parse(ele.OPERATE_CONTENT))} rows= "5" disabled/></td>
                      <td>{new Date(ele.LOG_DATE).toLocaleString()}</td>
                    </tr>
                  </>
                )
              }
              if (operateContentUrl ===  '/serverEnv/gameMission') {
                return (
                  <>
                    <tr key= {index}>
                      <td>{ele.OPERATOR}</td>
                      <td>{ele.OPERATE_TYPE}</td>
                      <td><textarea id= "TArea" value= {handleDzbMission(JSON.parse(ele.OPERATE_CONTENT))} rows= "5" disabled/></td>
                      <td>{new Date(ele.LOG_DATE).toLocaleString()}</td>
                    </tr>
                  </>
                )
              } 
              if (operateContentUrl === '/exchange/order') {
                return (
                  <>
                    <tr key= {index}>
                      <td>{ele.OPERATOR}</td>
                      <td>{ele.OPERATE_TYPE}</td>
                      <td><textarea id= "TArea" value= {handleOrder(JSON.parse(ele.OPERATE_CONTENT))} rows= "5" disabled/></td>
                      <td>{new Date(ele.LOG_DATE).toLocaleString()}</td>
                    </tr>
                  </>
                )
              } else {
                return (
                  <>
                    <tr key= {index}>
                      <td>{ele.OPERATOR}</td>
                      <td>{ele.OPERATE_TYPE}</td>
                      <td><textarea id= "TArea" value= {ele.OPERATE_CONTENT} rows= "5" disabled/></td>
                      <td>{new Date(ele.LOG_DATE).toLocaleString()}</td>
                    </tr>
                  </>
                )
              }
            })
            : <tr key= "noInfo"><td colSpan= "4">尚無資料</td></tr>
          }
        </tbody>
      </BasicTable>
      
      {
        Array.isArray(panelInfo) && panelInfo.length ?
          <Stack spacing={2}>
            <PageStyle count={pageCounter}
              color="warning"
              page= {page}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </Stack>
        : ''
      }
    </>
  )
}