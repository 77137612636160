import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { today, yesterday, thisWeek, lastWeek, thisMonth, defaultDate, defaultDateE, TimezoneTranslator } from "../../../middleware/dateUtils"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import keyName from "../../../assets/config/orderManagement.json"
import HandleUnoInfo from "../../../components/popUpWindow/orderManagement/handleUnoInfo"
import HandleMultiInfo from "../../../components/popUpWindow/orderManagement/handleMultiInfo"
import { orderList, changeReadStatus, orderDetail } from "../../../middleware/Api/operation/orderManagementApi"
import  generateExcel  from "../../../middleware/generateExcel.js"
import { getAccessArr } from "../../../middleware/utils"
import {
  InputBarBg,
  FirstRow,
  SecondRow,
  InputGroup,
  BlankRWD,
  Blank,
  Dropdown,
  RangeWrapper,
  DatePickerInput,
  RangeGroup,
  BtnGroup,
  Read,
  Unread,
  BottomBtn
} from "./styledComponent"
import {
  DateWrapper,
  DateTitle,
  DateStack,
  ClickRange,
  SearchButton,
  ResetButton,
  BasicTable,
  BasicRowTitle,
  TableContent,
  GenerateExcel,
  PopDetail,
  ManipulateGroup,
  CheckGroup,
  PageStyle,
  TableBtnGroup
} from "../../../middleware/utilityStyle.js"

export default function OrderManagement() {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })

  const history = useHistory()
  const [panelInfo, setPanelInfo] = useState([])
  const [change, setChange] = useState({
    dStatus: 0,
    oStatus: 0,
    playerID: '',
    orderID: '',
    redeemDate: [new Date(defaultDate).toISOString(), new Date(defaultDateE).toISOString()]
  })
  const [pageCounter, setPageCounter] = useState(0)
  const [changePagePara, setChangePagePara] = useState({
    packageStatus: 0,
    orderStatus: 0,
    uid: 0,
    orderID: 'all',
    sDate: '',
    eDate: ''
  })

  const [unoManiStatus, setUnoManiStatus] = useState(false)
  const [multiManiStatus, setMultiManiStatus] = useState(false)

  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
    })
  }, [panelInfo, pageCounter, unoManiStatus, multiManiStatus])

  const handleChange = (e, key) => {
    setChange((prev) => ({
      ...prev,
      [key]: e.target.value
    }))
  }
  const [page, setPage] = useState(1)
  //const [totalPage, setTotalPage] = useState(0)
  const handleChangePage = (event, newPage) => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPage(newPage)
      orderList(changePagePara.packageStatus, changePagePara.orderStatus, changePagePara.uid,
      changePagePara.orderID, changePagePara.sDate, changePagePara.eDate, 1+(newPage-1)*30, newPage*30, 0).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setPanelInfo(db.data)
      })
    })
  }

  const [checkValueArr, setCheckValueArr] = useState([])

  // function howManyPage(totalNum) {
  //   let result = 1
  //   if ((totalNum/30).toString().indexOf('.') >= 0) {
  //     result = Math.floor(totalNum/30) + 1
  //   } else {
  //     result = Math.floor(totalNum/30)
  //   }
  //   return result
  // }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      let packageStatus = 0
      if (!isNaN(+change.dStatus)) {
        packageStatus = +change.dStatus
        setChangePagePara(prev => ({
          ...prev,
          packageStatus: +change.dStatus
        }))
      }
      let orderStatus = 0
      if (!isNaN(+change.oStatus)) {
        orderStatus = +change.oStatus
        setChangePagePara(prev => ({
          ...prev,
          orderStatus: +change.oStatus
        }))
      }
      let uid = 0
      if (change.playerID) {
        if (isNaN(+change.playerID)) {
          alert('※  用戶ID請輸入數字')
          setChange(prev => ({
            ...prev,
            playerID: ''
          }))
          return
        }
        if (!isNaN(+change.playerID)) {
          uid = +change.playerID
          setChangePagePara(prev => ({
            ...prev,
            uid: +change.playerID
          }))
        }
      }
      let orderID = 'all'
      if (change.orderID !== '') {
        orderID = change.orderID
        setChangePagePara(prev => ({
          ...prev,
          orderID: change.orderID
        }))
      }
      if (change.orderID === '') {
        setChangePagePara(prev => ({
          ...prev,
          orderID: 'all'
        }))
      }

      let sDate = ''
      let eDate = ''
      if (change.redeemDate[0] !== null && change.redeemDate[1] !== null) {
        sDate = new Date(change.redeemDate[0]).toISOString()
        let endRDateStr = new Date(change.redeemDate[1]).toString()
        eDate = new Date(endRDateStr.replace('00:00:00', '23:59:59')).toISOString()
        setChangePagePara(prev => ({
          ...prev,
          sDate: sDate,
          eDate: eDate
        }))
      }
      if (change.redeemDate[0] === null && change.redeemDate[1] === null) {
        sDate = defaultDate.toISOString()
        eDate= defaultDateE.toISOString()
        setChangePagePara(prev => ({
          ...prev,
          sDate: sDate,
          eDate: eDate
        }))
      }
      orderList(packageStatus, orderStatus, uid, orderID, sDate, eDate, 1, 30, 0).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          orderList(0, 0, 0, 'all', new Date(defaultDate).toISOString(), new Date(defaultDateE).toISOString(), 1, 30, 0).then(db => {
            setChange(prev => ({
              ...prev,
              dStatus: 0,
              oStatus: 0,
              playerID: '',
              orderID: '',
              redeemDate: [new Date(defaultDate).toISOString(), new Date(defaultDateE).toISOString()]
            }))
            if (db.error === errorCode.INCORRECT_DATA.code) {
              alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.INCORRECT_FORMAT.code) {
              alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.NULL_DATA.code) {
              alert(`※  預設時間無資料`)
              setPanelInfo([])
              setPageCounter(0)
              setChange(prev => ({
                ...prev,
                dStatus: 0,
                oStatus: 0,
                playerID: '',
                orderID: ''
              }))
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            setPanelInfo(db.data)
            //setTotalPage(Math.round(howManyPage(db.data[0].TOTAL_ROW_NUM)))
            setPage(1)
            if(db.data) {
              if (db.data.length > 0) {
                setPageCounter(
                  db.data[0].TOTAL_ROW_NUM%30 === 0
                  ? Math.floor(db.data[0].TOTAL_ROW_NUM/30)
                  : (Math.floor(db.data[0].TOTAL_ROW_NUM/30)) + 1
                )
              }
            }
          })
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setPanelInfo(db.data)
        // //setTotalPage(Math.round(howManyPage(db.data[0].TOTAL_ROW_NUM)))
        setPage(1)
        if(db.data) {
          if (db.data.length > 0) {
            setPageCounter(
              db.data[0].TOTAL_ROW_NUM%30 === 0
              ? Math.floor(db.data[0].TOTAL_ROW_NUM/30)
              : (Math.floor(db.data[0].TOTAL_ROW_NUM/30)) + 1
            )
          }
        }
      })
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({
        dStatus: 0,
        oStatus: 0,
        playerID: '',
        orderID: '',
        redeemDate: [new Date(defaultDate), new Date(defaultDateE)]
      })
    })
  }

  const handleCheckboxValue = e => {
    let newArr = []
    const targetIndex = checkValueArr.findIndex(ele => ele === e.target.value)
    if(targetIndex >= 0){
      checkValueArr.splice(targetIndex, 1)
      newArr = [...checkValueArr]
    } else {
      newArr = [...checkValueArr, e.target.value]
    }
    setCheckValueArr(newArr)
  }

  const[targetOrderID, setTargetOrderID] = useState('')
  const [showManiUno, setManiUnoShow] = useState(false)
  const handleManiUnoClose = () => {
    orderList(changePagePara.packageStatus, changePagePara.orderStatus, changePagePara.uid,
    changePagePara.orderID, changePagePara.sDate, changePagePara.eDate, 1+(page-1)*30, page*30, 0).then(db => {
      if (db.error === errorCode.INCORRECT_DATA.code) {
        alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.INCORRECT_FORMAT.code) {
        alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.NULL_DATA.code) {
        alert(`※  ${errorCode.NULL_DATA.memo}`)
        return
      }
      if (db.error === errorCode.EXCEPTION.code) {
        alert(`※  ${errorCode.EXCEPTION.memo}`)
        return
      }
      setPanelInfo(db.data)
    })
    setManiUnoShow(false)
  }
  const handleManiUnoShow = () => setManiUnoShow(true)

  const handleManiUno = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 40)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (!userPID.find(ele => +ele === 57)) {
        alert('  ※無讀取狀態權限，無法進行動作ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      let find = panelInfo.find(ele => ele.ORDER_ID === e.target.dataset.orderid)
      if (+find.FINISH_STATUS === 2) {
        alert('此資料已結案，無法操作  ʕ ･ิᴥ･ิ ʔ')
        return
      }
      changeReadStatus(`${e.target.dataset.orderid}`).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
      })
      setUnoManiStatus(true)
      setTargetOrderID(e.target.dataset.orderid)
      handleManiUnoShow()
    })
  }

  const handleCheckUno = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 40)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (!userPID.find(ele => +ele === 57)) {
        alert('  ※無讀取狀態權限，無法進行動作ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      changeReadStatus(`${e.target.dataset.orderid}`).then(db => {
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
      })
      setUnoManiStatus(false)
      setTargetOrderID(e.target.dataset.orderid)
      handleManiUnoShow()
    })
  }

  const [showManiMulti, setManiMultiShow] = useState(false)
  const handleManiMultiClose = () => {
    orderList(changePagePara.packageStatus, changePagePara.orderStatus, changePagePara.uid,
    changePagePara.orderID, changePagePara.sDate, changePagePara.eDate, 1+(page-1)*30, page*30, 0).then(db => {
      if (db.error === errorCode.INCORRECT_DATA.code) {
        alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.INCORRECT_FORMAT.code) {
        alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.NULL_DATA.code) {
        alert(`※  ${errorCode.NULL_DATA.memo}`)
        return
      }
      if (db.error === errorCode.EXCEPTION.code) {
        alert(`※  ${errorCode.EXCEPTION.memo}`)
        return
      }
      setPanelInfo(db.data)
    })
    setManiMultiShow(false)
  }
    const handleManiMultiShow = () => {
    setManiMultiShow(true)
  }


  const [multiCopy, setMultiCopy]= useState([
    {
      ORDER_ID: '',
      LOG_DATE: '',
      UID: '',
      ITEM_ID: '',
      ITEM_NAME: '',
      RECEIVER_NAME: '',
      RECEIVER_PHONE: '',
      RECEIVER_ADDRESS: '',
      PRICE_TYPE: '',
      ITEM_PRICE: '',
      BALANCE: '',
      PACKAGE_STATUS: '',
      LOGISTICS: '',
      PACKAGE_NUMBER: '',
      FINISH_STATUS: '',
      REMARK: ''
    }
  ])

  const [displayMulti, setDisplayMulti] = useState([
    {
      ORDER_ID: '',
      LOG_DATE: '',
      UID: '',
      ITEM_ID: '',
      ITEM_NAME: '',
      RECEIVER_NAME: '',
      RECEIVER_PHONE: '',
      RECEIVER_ADDRESS: '',
      PRICE_TYPE: '',
      ITEM_PRICE: '',
      BALANCE: '',
      PACKAGE_STATUS: '',
      LOGISTICS: '',
      PACKAGE_NUMBER: '',
      FINISH_STATUS: '',
      REMARK: ''
    }
  ])

  const handleManiMulti = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 40)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (!userPID.find(ele => +ele === 57)) {
        alert('  ※無讀取狀態權限，無法進行動作ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (checkValueArr.length < 1) {
        alert('※  請勾選欲操作項目')
        return
      }
      changeReadStatus(checkValueArr.toString()).then(db => {
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
      })

      if (checkValueArr.length) {
        if (Array.isArray(checkValueArr)) {
          orderDetail(checkValueArr.toString()).then(db => {
            if (db.error === errorCode.INCORRECT_DATA.code) {
              alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.INCORRECT_FORMAT.code) {
              alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.NULL_DATA.code) {
              alert(`※  ${errorCode.NULL_DATA.memo}`)
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            setMultiCopy(JSON.parse(JSON.stringify(db.data)))
            setDisplayMulti(JSON.parse(JSON.stringify(db.data)))
            let finishCase = null
            for (let i = 0; i < checkValueArr.length; i++) {
              let target = (JSON.parse(JSON.stringify(db.data))).find(ele => ele.ORDER_ID === checkValueArr[i])
              if (+target.FINISH_STATUS === 1) {
                finishCase = false
              } else {
                finishCase = true
                break
              }
            }
            if (finishCase) {
              alert('※  批次操作不可包含已結案訂單')
              return
            }
            setMultiManiStatus(true)
            handleManiMultiShow()
          })
        }
      }
    })
  }

  const handleCheckMulti = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 40)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (!userPID.find(ele => +ele === 57)) {
        alert('  ※無讀取狀態權限，無法進行動作ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (checkValueArr.length < 1) {
        alert('※  請勾選欲查看項目')
        return
      }
      changeReadStatus(checkValueArr.toString()).then(db => {
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
      })
      setMultiManiStatus(false)
      handleManiMultiShow()
    })
  }

  let deliveryStatusArr = new Map([
    [1, '訂單受理'],
    [2, '已備貨'],
    [3, '申請換貨'],
    [4, '已出貨']
  ])
  let deliveryStatusName = new Map(deliveryStatusArr)

  const handleExcel = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      let columnTitleArr = Object.keys(keyName)
      let columnList = []
      let allData = []
      for (let i = 0; i < columnTitleArr.length; i++) {
        let obj = {
          name: keyName[columnTitleArr[i]].name
        }
        columnList.push(obj)
      }
      
      orderList(changePagePara.packageStatus, changePagePara.orderStatus, changePagePara.uid,
      changePagePara.orderID, changePagePara.sDate, changePagePara.eDate, 0, 0, 1).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if (db.error) {
          alert ('※  資料錯誤，無法產出excel報表，相關問題請洽詢工程部')
          return
        } else {
          allData = db.data
        }

        let rowList = []
        for (let j = 0; j < allData.length; j++) {
          let sArr = []
          for (let k = 0; k < columnList.length; k++) {
            let data = ''
            if (k === 1) {
              data = allData[j][columnTitleArr[1]] ? 'Y' : 'N'
            } else if (k === 2) {
              data = TimezoneTranslator(allData[j][columnTitleArr[2]]).slice(0, 10)
            } else if (k === 6) {
              data = deliveryStatusName.get(+allData[j][columnTitleArr[6]])
            } else if (k === 9) {
              data = +allData[j][columnTitleArr[9]] === 1 ? '進行中' : '結案'
            }
            else {
              data = allData[j][columnTitleArr[k]]
            }
            sArr.push(data)
          }
          rowList.push(sArr)
        }
        function date(str) {
          return `${new Date(str).getFullYear()}-${new Date(str).getMonth()+1}-${new Date(str).getDate()}`
        }
        let rangeS = date(changePagePara.sDate)
        let rangeE = date(changePagePara.eDate)
        generateExcel('兌換訂單處理.xlsx', `${rangeS}~${rangeE}`, columnList, rowList)
      })
    })
  }
  return(
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <FirstRow>
          <div>
            <span>配送狀態</span>
            <Dropdown id= "deliveryStatus" value= {change.dStatus} onChange= {e => {handleChange(e, 'dStatus')}}>
              <option value= "0">全部</option>
              <option value= "1">訂單受理</option>
              <option value= "2">已備貨</option>
              <option value= "3">申請換貨</option>
              <option value= "4">已出貨</option>
            </Dropdown>
          </div>
          <InputGroup>
            <span>單號狀態</span>
            <Dropdown id= "orderStatus" value= {change.oStatus} onChange= {e => {handleChange(e, 'oStatus')}}>
              <option value= "0">全部</option>
              <option value= "1">進行中</option>
              <option value= "2">結案</option>
            </Dropdown>
          </InputGroup>
          <BlankRWD>
            用戶ID查詢<Blank placeholder= "請輸入ID" value= {change.playerID} onChange= {e =>{handleChange(e, 'playerID')}}/>
          </BlankRWD>
          <BlankRWD>
            單號查詢<Blank placeholder= "請輸入單號" value= {change.orderID} onChange= {e => {handleChange(e, 'orderID')}}/>
          </BlankRWD>
        </FirstRow>

        <SecondRow>
          <RangeWrapper>
            <DateWrapper>
              <DateTitle>兌換日期</DateTitle>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateStack spacing={3}>
                  <DesktopDateRangePicker
                    startText=""
                    endText=""
                    value={change.redeemDate}
                    onChange={(newValue) => {
                      setChange((prev) => ({
                        ...prev,
                        redeemDate: newValue
                      }))
                    }}
                    renderInput={(startProps, endProps) => {
                      startProps.inputProps.placeholder = '開始日期'
                      endProps.inputProps.placeholder = '結束日期'
                      return (
                        <>
                          <DatePickerInput ref={startProps.inputRef} {...startProps.inputProps} />
                          <Box sx={{ mx: 1 }}>至</Box>
                          <DatePickerInput ref={endProps.inputRef} {...endProps.inputProps} />
                        </>
                      )
                    }}
                  />
                </DateStack>
              </LocalizationProvider>
            </DateWrapper>
            <RangeGroup>
              <ClickRange
                onClick={() => {
                  setChange((prev) => ({
                    ...prev,
                    redeemDate: today
                  }))
              }}>今天</ClickRange>
              <ClickRange
                onClick={() => {
                  setChange((prev) => ({
                    ...prev,
                    redeemDate: yesterday
                  }))
                }}>昨天</ClickRange>
              <ClickRange
                onClick={() => {
                  setChange((prev) => ({
                    ...prev,
                    redeemDate: thisWeek
                  }))
                }}>本周</ClickRange>
              <ClickRange
                onClick={() => {
                  setChange((prev) => ({
                    ...prev,
                    redeemDate: lastWeek
                  }))
                }}>上周</ClickRange>
              <ClickRange
                onClick={() => {
                  setChange((prev) => ({
                    ...prev,
                    redeemDate: thisMonth
                  }))
                }}>本月</ClickRange>
            </RangeGroup>
          </RangeWrapper>
          <BtnGroup>
            <SearchButton onClick= {handleSearch}>搜索</SearchButton>
            <ResetButton onClick = {handleReset}>重置</ResetButton>
          </BtnGroup>
        </SecondRow>
      </InputBarBg>
      
      <BottomBtn style= {{width: '100%'}}>
        <GenerateExcel onClick= {handleExcel}>產出EXCEL報表</GenerateExcel>
        <ManipulateGroup onClick= {handleManiMulti}>批次操作</ManipulateGroup>
        <CheckGroup onClick= {handleCheckMulti}>批次查看</CheckGroup>
      </BottomBtn>
      
      <TableBtnGroup>
        <BasicTable className="table table-bordered" style= {{marginBottom: '0px'}}>
          <thead>
            <BasicRowTitle>
              <th scope="col">批次</th>
              <th scope="col">交易單號</th>
              <th scope="col">讀取</th>
              <th scope="col">兌換時間</th>
              <th scope="col">玩家ID</th>
              <th scope="col">商品項目</th>
              <th scope="col">收件人</th>
              <th scope="col">配送狀態</th>
              <th scope="col">物流單位</th>
              <th scope="col">包裹號碼</th>
              <th scope="col">單號狀態</th>
              <th scope="col">功能</th>
              <th scope="col">檢閱</th>
            </BasicRowTitle>
          </thead>
          <tbody>
            {
              Array.isArray(panelInfo) && panelInfo.length ? 
                panelInfo.map((obj, index)=> {
                  return (
                    <TableContent key={index}>
                      <td>
                        <input type= "checkbox" value={obj.ORDER_ID} checked={checkValueArr.find(element => element === obj.ORDER_ID) ? true : false} onChange= {handleCheckboxValue}/>
                      </td>
                      <td>{obj.ORDER_ID}</td>
                      {
                        obj.READ_STATUS ? <Read>Y</Read>
                        : <Unread>N</Unread>
                      }
                      <td>{TimezoneTranslator(obj.LOG_DATE).slice(0, 10)}</td>
                      <td>{obj.UID}</td>
                      <td>{obj.ITEM_NAME}</td>
                      <td>{obj.RECEIVER_NAME}</td>
                      <td>{deliveryStatusName.get(+obj.PACKAGE_STATUS)}</td>
                      <td>{obj.LOGISTICS ? obj.LOGISTICS: '---'}</td>
                      <td>{obj.PACKAGE_NUMBER ? obj.PACKAGE_NUMBER : '---'}</td>
                      <td>{+obj.FINISH_STATUS === 1 ? '進行中' : '結案'}</td>
                      <PopDetail data-orderid= {obj.ORDER_ID} onClick= {handleManiUno}>操作</PopDetail>
                      <PopDetail data-orderid= {obj.ORDER_ID} onClick= {handleCheckUno}>查看</PopDetail>
                    </TableContent>
                  )
                })
              : <tr key= "noInfo"><td colSpan= "13">尚無資料</td></tr>
            }
          </tbody>
        </BasicTable>

        <BottomBtn>
          <GenerateExcel onClick= {handleExcel}>產出EXCEL報表</GenerateExcel>
          <ManipulateGroup onClick= {handleManiMulti}>批次操作</ManipulateGroup>
          <CheckGroup onClick= {handleCheckMulti}>批次查看</CheckGroup>
        </BottomBtn>
      </TableBtnGroup>

      {
        Array.isArray(panelInfo) && panelInfo.length ?
          <Stack spacing={2}>
            <PageStyle count={pageCounter}
              color="warning"
              page= {page}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </Stack>
        : ''
      }

      <HandleUnoInfo
        showManiUno= {showManiUno}
        handleManiUnoClose= {handleManiUnoClose}
        targetOrderID= {targetOrderID}
        unoManiStatus= {unoManiStatus}
        setPanelInfo= {setPanelInfo}
        page= {page}
        setPageCounter= {setPageCounter}
        changePagePara = {changePagePara}
        setChangePagePara= {setChangePagePara}
      />

      <HandleMultiInfo
        showManiMulti= {showManiMulti}
        handleManiMultiClose= {handleManiMultiClose}
        checkValueArr= {checkValueArr}
        multiManiStatus= {multiManiStatus}
        setPanelInfo= {setPanelInfo}
        page= {page}
        setPageCounter= {setPageCounter}
        setChangePagePara= {setChangePagePara}
        multiCopy= {multiCopy}
        setMultiCopy= {setMultiCopy}
        displayMulti= {displayMulti}
        changePagePara = {changePagePara}
        setDisplayMulti= {setDisplayMulti}
      />
    </>
  )
}