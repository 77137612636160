import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Stack from '@mui/material/Stack'

import { 
  SearchButton,
  BasicTable,
  BasicRowTitle,
  AddItemInfoBtn,
  PopDetail,
  PageStyle,
  TablePaginationStyle
} from "../../../middleware/utilityStyle"
import {
  InputBarBg,
  NavLeft,
  InputBarRWD,
  DropWrapper,
  Dropdown,
  BtnGroup
} from "./styledComponent"

import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import { getAdSetting } from "../../../middleware/Api/backstageManagement/adSetting"


import gameCategories from "../../../assets/config/gameCategories.json"
import gameLevel from "../../../assets/config/gameType.json"
import adMode from "../../../assets/config/adMode.json"
import errorCode from "../../../assets/config/errorCode.json"
import OC from "../../../components/popUpWindow/adSettingForFinalCalculate/occurrenceCondiction.json"
import OM from "../../../components/popUpWindow/adSettingForFinalCalculate/occurrenceMode.json"

import AddAdSettingComponent from "../../../components/popUpWindow/adSettingForFinalCalculate/addAdSetting"
import ReviseAdSettingComponent from "../../../components/popUpWindow/adSettingForFinalCalculate/reviseAdSetting"

import {settingData} from "../../../components/popUpWindow/adSettingForFinalCalculate/Contexts"

import { setAuthToken, getNavAcc, getAccessArr, setAccessArr, setCharacterType } from "../../../middleware/utils"

export default function adSettingForFinalCalculate() {
  const history = useHistory()
  const [change,setChange] = useState({
    navGame: '',
    navGameType:"",
    mode:"",
  })
  const [targetData, setTargetData] = useState([])
  const [popForAddItem, setPopForAddItem] = useState(false)
  const [popForReviseItem, setPopForReviseItem] = useState(false)
  const [reviseTarget, setReviseTarget] = useState({})
  const [rollSetting, setRollSetting] = useState({})
  const [rateSetting, setRateSetting] = useState({})
  const [noticeMessage, setNoticeMessage] = useState({})
  const [pageNum, setPageNum] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [dataForPage, setDataForPage] = useState([])
  
  const accessArr = getAccessArr()
  let userPID = []
    accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })

  const handleChange = e => {
    let newObj = Object.assign({}, change) //複製一份新的states
    newObj[e.target.name] = e.target.value
    setChange(newObj)
  }
  function gameCategory() {
    let gameList = []
    gameCategories.map((ele, index) => {
      gameList.push(<option value = {ele.gameID} key= {index}>{ele.gameName}</option>)
    })
    return gameList
  }

  function handleGameLevel (){
    let gameLevelList = []
    gameLevel.map((ele, index) => {

      gameLevelList.push(<option value = {ele.typeNum -1} key= {index}>{ele.typeName}</option>)
    })
    return gameLevelList
  }

  function getAdMode(){
    let modeList = []
    adMode.map((ele, index) => {

      modeList.push(<option value = {ele.modeNum} key= {index}>{ele.modeName}</option>)
    })
    return modeList
  }
  const handleChangePage=(event, newPage)=>{
    setPageNum(newPage)
    let toRow = (newPage+1) *rowsPerPage
    let result = targetData.slice(toRow-rowsPerPage ,toRow)
    setDataForPage(result)
  }
  const handleReset = () =>{
    sessionVerified().then(db =>{
      if (db.error===errorCode.CODE_NOT_MATCH.code){
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code){
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      let changeKeys = Object.keys(change)

      return setChange(changeKeys.reduce((o,k)=>{
        console.log(k)

        return {
          ...o,
          [k]: k === "navGame" || k === "navGameType"||k === "mode"? "":change[k]
        }
      }, {}))
      
    })
  }

  const handleSearch = () =>{
    sessionVerified().then(db =>{
      if (db.error===errorCode.CODE_NOT_MATCH.code){
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code){
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      getAdSetting().then(data => {
        let finalResult = JSON.parse(JSON.stringify(data.data))
        if (change.navGame !==""){
          finalResult = finalResult.filter(data =>data.GAME_ID === +change.navGame)
        }

        if (change.navGameType !==""){
          finalResult = finalResult.filter(data =>+data.GAME_TYPE === +change.navGameType)
        }

        if (change.mode !==""){
          finalResult = finalResult.filter(data =>+data.AWARD_TYPE === +change.mode)
        }
        setPageCount(finalResult.length%rowsPerPage===0? finalResult.length/ rowsPerPage: Math.floor(finalResult.length /rowsPerPage)+1)
        setTargetData(finalResult)
        let result = finalResult.slice(pageNum,rowsPerPage)
        setDataForPage(result)
      }).catch(error =>{ console.log(error)})
      
    })
  }
  const handleAddItem = () =>{
    sessionVerified().then(db=>{
      if (db.error===errorCode.CODE_NOT_MATCH.code){
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code){
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 92)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      setPopForAddItem(true)
    }) 
  }
  const handleChangeRowsPerPage  = (e)=>{
    
    setRowsPerPage(e.target.value)
    setPageNum(0)
    setDataForPage(targetData.slice(0 ,e.target.value))

  }

  const handleDetail = (e)=>{
    sessionVerified()
    .then(db=>{
      if (db.error===errorCode.CODE_NOT_MATCH.code){
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code){
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      let foundData = targetData.find(d=> +d.GRAC_ID === +e.target.dataset.id)
      setReviseTarget(foundData)
      setRollSetting(JSON.parse(foundData.AWARD_CONFIG).rollSetting)
      setRateSetting(JSON.parse(foundData.AWARD_CONFIG).rateSetting)
      setNoticeMessage(JSON.parse(foundData.HINT_CONFIG))
      setPopForReviseItem(true)
      
    })
  }
  console.log(targetData)
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <NavLeft>
          <InputBarRWD >
            <DropWrapper>
              遊戲
              <Dropdown name="navGame" value={change.navGame}  onChange={(e)=>{handleChange(e)}}>
                <option value="">全部</option>
                {gameCategory()}

              </Dropdown>
              遊戲場次
              <Dropdown name="navGameType" value={change.navGameType} onChange={(e)=>{handleChange(e)}}>
                <option value="">全部</option>
                {handleGameLevel()}
              </Dropdown>
              模式
              <Dropdown name="mode" value={change.mode}  onChange={(e)=>{handleChange(e)}}>
                <option value="">全部</option>
                {getAdMode()}
              </Dropdown>
            </DropWrapper>
            <BtnGroup>
            <SearchButton 
              onClick={handleSearch}
            >搜索</SearchButton>
            <SearchButton 
              onClick={handleReset}
            >重置</SearchButton>
            </BtnGroup>
          </InputBarRWD>
        </NavLeft>
      </InputBarBg>

      <BasicTable className="table table-hover table-bordered">
        <thead>
          <BasicRowTitle>
            <th scope="col">ID</th>
            <th scope="col">遊戲</th>
            <th scope="col">場次</th>
            <th scope="col">觸發條件</th>
            <th scope="col">統計模式</th>
            <th scope="col">場數</th>
            <th scope="col">每日上限</th>
            <th scope="col">模式</th>
            <th scope="col">已累積觀看</th>
            <th scope="col">開關</th>
            <th scope="col">設定</th>
          </BasicRowTitle>
        </thead>
        {Object.prototype.toString.call(targetData) === "[object Array]"&&targetData.length ?
          <tbody>
            {dataForPage.map(data=>{
              const {
                GRAC_ID,
                GAME_ID,
                GAME_TYPE,
                TRIGGER_TYPE,
                TRIGGER_MODEL,
                GOAL_TIMES,
                TRIGGER_LIMT_TIMES,
                AWARD_TYPE,
                AWARD_CONFIG,
                HINT_STATE,
                HINT_CONFIG,
                STATE,
                TOTAL_WATCH_TIMES
              } = data
                
              let gameName = gameCategories.find(set =>+set.gameID === +GAME_ID)
              let roomTypeName = gameLevel.find(set =>{
                return (+set.typeNum  - 1)=== +GAME_TYPE
              })

              let triggerCondiction = OC.find(c => c.oNum === TRIGGER_TYPE).oName
              let triggerMode = OM.find(m=>m.omMode === TRIGGER_MODEL).omName
              return (
                <>
                  <tr>
                  <td>{GRAC_ID}</td>
                  <td>{gameName.gameName}</td>
                  <td>{roomTypeName.typeName}</td>
                  <td>{triggerCondiction}</td>
                  <td>{triggerMode}</td>
                  <td>{GOAL_TIMES}</td>
                  <td>{TRIGGER_LIMT_TIMES}</td>
                  <td>{adMode.find(set=>set.modeNum === +AWARD_TYPE).modeName}</td>
                  <td>{TOTAL_WATCH_TIMES}</td>
                  <td>{STATE === 1? "開啟":"關閉"}</td>
                  <PopDetail onClick= {handleDetail} data-id= {GRAC_ID}>設定</PopDetail>
                  </tr>
                </>
              )
            })}
          </tbody>
          :<tbody><tr key= "noInfo"><td colSpan= "11">尚無資料</td></tr></tbody>
        }
      </BasicTable>
      <Stack spacing={2}>
      <TablePaginationStyle
        component="div"
        count={targetData.length}
        page={pageNum}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions ={[5,10, 25, 50, 100]}

      />

      </Stack>

      <AddItemInfoBtn
        onClick={handleAddItem}
      >新增</AddItemInfoBtn>

      <AddAdSettingComponent
        showAddRevise = {popForAddItem}
        handleSwitch ={setPopForAddItem}
        searchAct ={handleSearch}
      />
      <settingData.Provider value={[
        reviseTarget, setReviseTarget,
        rollSetting, setRollSetting,
        rateSetting, setRateSetting,
        noticeMessage, setNoticeMessage,
        [change.navGame,change.navGameType,change.mode],setTargetData,
        handleSearch]}>
        <ReviseAdSettingComponent
          showAddRevise ={popForReviseItem}
          handleSwitch ={setPopForReviseItem}
        />
      </settingData.Provider>

    </>
  )
}