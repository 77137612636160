import { getAuthToken } from "../../utils.js"
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 取得玩家列表 PID51 ?//
export const getUserList = (id, nickName, upLine, regSDate, regEDate, lastGameSDate, lastGameEDate) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/playerInfo/playerList?id=${id}&nickName=${nickName}&upLine=${upLine}&regSDate=${regSDate}&regEDate=${regEDate}&lastGameSDate=${lastGameSDate}&lastGameEDate=${lastGameEDate}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}