import React, { useState, useEffect }from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { PULeftTitle, PUSelect } from "../../../views/eventManagement/prizeLimit/styledComponent"
import { ModalFormat, ModalBtn, PUInput } from "../../../middleware/utilityStyle"
import ConfirmPU from "./ConfirmPU"

export default function AddPU(props) {
  const {
    showAddRevise,
    handleAddReviseShow,
    handleAddReviseClose,
    addStatus,
    popData,
    setPopData,
    setPanelInfo,
    allEvent,
    prizeType
  } = props
  const history = useHistory()
  const [form, setForm] = useState({
    ACTIVITY_ID: '',
    AWARD_TYPE: prizeType[0].num,
    AWARD_NUM: '',
    TOTAL_LIMIT: '',
    TOTAL_NUM: '',
    DAILY_LIMIT: '',
    DAILY_NUM: '',
    ITEM_ID:0
  })

  const handleForm = (e, key) => {
    setForm(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }
  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if(!addStatus) {
        setForm(JSON.parse(JSON.stringify(popData)))        
      }
      if(addStatus) {
        setForm({
          ACTIVITY_ID: allEvent[0].ACTIVITY_ID,
          AWARD_TYPE: prizeType[0].num,
          AWARD_NUM: '',
          TOTAL_LIMIT: '',
          TOTAL_NUM: '',
          DAILY_LIMIT: '',
          DAILY_NUM: '',
          ITEM_ID:0
        })
      }
    })
  }, [popData, addStatus])

  const handleSubmit = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }  
      let paraArr = 
        [
          +form.ACTIVITY_ID,
          +form.AWARD_TYPE,
          +form.AWARD_NUM,
          +form.TOTAL_LIMIT,
          +form.TOTAL_NUM,
          +form.DAILY_LIMIT,
          +form.DAILY_NUM
        ]
      let typePass = paraArr.every(ele => !isNaN(ele))
      if (!typePass) {
        alert('※  欄位請輸入數字')
        return
      }
      handleAddReviseClose()
      handleConfirmShow()
    })    
  }

  const handleBack = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleAddReviseClose()
      setTimeout(() => {
        if(addStatus) {
          setForm({
            ACTIVITY_ID: allEvent[0].ACTIVITY_ID,
            AWARD_TYPE: prizeType[0].num,
            AWARD_NUM: '',
            TOTAL_LIMIT: '',
            TOTAL_NUM: '',
            DAILY_LIMIT: '',
            DAILY_NUM: '',
            ITEM_ID:0,
          })
        }
        if (!addStatus) {
          setForm(JSON.parse(JSON.stringify(popData)))
        }
      }, 500)
    })
  }

  const [showConfirm, setConfirmShow] = useState(false)
  const handleConfirmClose = () => setConfirmShow(false)
  const handleConfirmShow = () => setConfirmShow(true)

  const handleReset = () => {
    setForm({
      ACTIVITY_ID: allEvent[0].ACTIVITY_ID,
      AWARD_TYPE: prizeType[0].num,
      AWARD_NUM: '',
      TOTAL_LIMIT: '',
      TOTAL_NUM: '',
      DAILY_LIMIT: '',
      DAILY_NUM: '',
      ITEM_ID:0,
    })
  }
  return (
    <>
      <ModalFormat
        show={showAddRevise}
        onHide={handleAddReviseClose}
        backdrop="static"
        keyboard={false}
        size= "lg"
      >
        <Modal.Header>
          <Modal.Title>{addStatus ? '新增' : '修改'}活動獎勵控制</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <PULeftTitle>活動名稱</PULeftTitle>
                <td>
                  <PUSelect value= {form.ACTIVITY_ID} onChange= {e => {handleForm(e, 'ACTIVITY_ID')}}>
                  {
                    Array.isArray(allEvent) && allEvent.length ?
                      allEvent.map((ele, index) => {
                        return <option value= {ele.ACTIVITY_ID} key= {index}>{ele.ACTIVITY_NAME}</option>
                      })
                    : ''
                  }
                  </PUSelect>
                </td>
              </tr>
              <tr>
                <PULeftTitle>獎勵類型</PULeftTitle>
                <td>
                  <PUSelect value= {form.AWARD_TYPE} onChange= {e => {handleForm(e, 'AWARD_TYPE')}}>
                  {
                    Array.isArray(prizeType) && prizeType.length ?
                      prizeType.map((ele, index) => {
                        return <option value= {ele.num} key= {index}>{ele.name}</option>
                      })
                    : ''
                  }
                  </PUSelect>
                  <span>道具ID <PUInput type = "number" min={0} value= {form.ITEM_ID} onChange= {e => {handleForm(e, 'ITEM_ID')}} disabled ={+form.AWARD_TYPE !== 6?"disabled":""} ></PUInput></span>
                </td>
              </tr>
              <tr>
                <PULeftTitle>獎勵數量</PULeftTitle>
                <td>
                  <PUInput placeholder= "請輸入數字" value= {form.AWARD_NUM} onChange= {e => {handleForm(e, 'AWARD_NUM')}}/>
                </td>
              </tr>
              <tr>
                <PULeftTitle>總次數限制</PULeftTitle>
                <td>
                  <PUInput placeholder= "請輸入數字" value= {form.TOTAL_LIMIT} onChange= {e => {handleForm(e, 'TOTAL_LIMIT')}}/>
                </td>
              </tr>
              <tr>
                <PULeftTitle>總次數</PULeftTitle>
                <td>
                  <PUInput placeholder= "請輸入數字" value= {form.TOTAL_NUM} onChange= {e => {handleForm(e, 'TOTAL_NUM')}}/>
                </td>
              </tr>
              <tr>
                <PULeftTitle>每日次數限制</PULeftTitle>
                <td>
                  <PUInput placeholder= "請輸入數字" value= {form.DAILY_LIMIT} onChange= {e => {handleForm(e, 'DAILY_LIMIT')}}/>
                </td>
              </tr>
              <tr>
                <PULeftTitle>每日次數</PULeftTitle>
                <td>
                  <PUInput placeholder= "請輸入數字" value= {form.DAILY_NUM} onChange= {e => {handleForm(e, 'DAILY_NUM')}}/>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <ModalBtn>
          <Button variant="info" onClick= {handleSubmit}>確定</Button>
          <Button variant= "warning" onClick= {handleReset}>重置</Button>
          <Button variant="secondary" onClick= {handleBack}>返回</Button>
        </ModalBtn>
      </ModalFormat>
      
      <ConfirmPU
        showConfirm= {showConfirm}
        handleAddReviseShow= {handleAddReviseShow}
        handleConfirmClose= {handleConfirmClose}
        addStatus= {addStatus}
        form= {form}
        setForm= {setForm}
        setPopData= {setPopData}
        setPanelInfo= {setPanelInfo}
      />
    </>
  )
}