import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker'
import { today, yesterday, thisWeek, lastWeek, thisMonth, defaultDate, defaultDateE, TimezoneTranslator } from "../../../middleware/dateUtils"
import errorCode from "../../../assets/config/errorCode.json"
import gameCategories from "../../../assets/config/gameCategories.json"
import { getUserGameInfo } from "../../../middleware/Api/playerInfo/gameCalculateApi"
import {
  InputBarBg,
  NavLeft,
  InputBarRWD,
  DropWrapper,
  Dropdown,
  RangeWrapper,
  Input,
  DatePickerInput,
  RangeGroup,
  BtnGroup
} from './styledComponent'

import {
  DateWrapper,
  DateTitle,
  ClickRange,
  SearchButton,
  ResetButton,
  BasicTable,
  BasicRowTitle,
  TableContent,
  PageStyle
} from "../../../middleware/utilityStyle.js"

export default function GameCalculate() {
  const history = useHistory()
  const [panelInfo, setPanelInfo] = useState([])
  const [change, setChange] = useState({
    navUserId: '',
    navGameType: '',
    navDate: [new Date(defaultDate), new Date(defaultDateE)]
  })

  const [page, setPage] = useState(1)
  const [pageCounter, setPageCounter] = useState(0)  //最大頁數
  const handleChange = e => {
    let newObj = Object.assign({}, change) //複製一份新的states
    newObj[e.target.name] = e.target.value
    setChange(newObj)
  }
  const [changePagePara, setChangePagePara] = useState({
    navUserId: 0,
    navGameType: 'all',
    sDate: new Date(defaultDate).toISOString(),
    eDate: new Date(defaultDateE).toISOString()
  })

  let urlQueryString = useLocation().search
  if (urlQueryString) {
    useEffect(() => {
      let firstArr = urlQueryString.match(/(\w+)=(\w+)/gm)
      let secondArr = firstArr.map(str => str.match(/(\w+)/gm))
      let UIDObj = {}
      secondArr.forEach(arr => UIDObj[arr[0]] = arr[1])
      setChange(prev => ({
        ...prev,
        navUserId: (+UIDObj.UID)
      }))
    }, [useLocation().search])
  }
  
  const handleChangePage = (event, newPage) => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPage(newPage)
      const {navUserId, navGameType, sDate, eDate} = changePagePara
      getUserGameInfo(+navUserId, navGameType, sDate, eDate, 1+(newPage-1)*30, newPage*30) .then(db=> {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setPanelInfo(db.data)
      })
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange(prev => ({
        ...prev,
        navUserId: '',
        navGameType: '',
        navDate: [new Date(defaultDate), new Date(defaultDateE)]
      }))
    })
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      let sDate = new Date(defaultDate).toISOString()
      let eDate = new Date(defaultDateE).toISOString()
      if (!change.navDate[0] || !change.navDate[1]) {
        alert('※ 請輸入日期')
        return
      }

      if (change.navDate[0] && change.navDate[1]) {
        sDate = new Date(change.navDate[0]).toLocaleString("zh-TW", { hour12: false, timeZone: "Asia/Taipei"});
        let endDate = new Date(change.navDate[1]).toString()
        eDate = new Date(endDate.replace('00:00:00', '23:59:59')).toLocaleString("zh-TW", { hour12: false, timeZone: "Asia/Taipei"})
        setChangePagePara(prev => ({
          ...prev,
          sDate,
          eDate
        }))
      }
      
      if (isNaN(+change.navUserId)) {
        alert('※  用戶ID請輸入數字')
        setChange(prev => ({
          ...prev,
          navUserId: ''
        }))
        return
      }
      let userId = 0
      if (+change.navUserId) {
        userId = +change.navUserId
        setChangePagePara(prev => ({
          ...prev,
          navUserId: +change.navUserId
        }))
      }

      let gameType = 'all'
      if (+change.navGameType) {
        gameType = change.navGameType
        setChangePagePara(prev => ({
          ...prev,
          navGameType: change.navGameType
        }))
      } else {
        setChangePagePara(prev => ({
          ...prev,
          navGameType: 'all'
        }))
      }
      function getInfo(userID, gameType, sDate, eDate, sRow, eRow) {
        getUserGameInfo(+userID, gameType, sDate , eDate, sRow, eRow).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.NULL_DATA.code) {
            alert(`※  ${errorCode.NULL_DATA.memo}`)
            getUserGameInfo(0, 'all', defaultDate.toISOString(), defaultDateE.toISOString(), 1, 30).then(db => {
              if (db.error === errorCode.INCORRECT_DATA.code) {
                alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                return
              }
              if (db.error === errorCode.INCORRECT_FORMAT.code) {
                alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                return
              }
              if (db.error === errorCode.NULL_DATA.code) {
                alert(`※  預設日期無資料`)
                return
              }
              if (db.error === errorCode.EXCEPTION.code) {
                alert(`※  ${errorCode.EXCEPTION.memo}`)
                return
              }
              if (db.data.length > 0) {
                setPanelInfo(db.data)
                setPage(1)
                setChange(prev => ({
                  ...prev,
                  navUserId: '',
                  navGameType: 'all',
                  navDate: [new Date(defaultDate), new Date(defaultDateE)]
                }))
                setChangePagePara(prev => ({
                  ...prev,
                  navUserId: '',
                  navGameType: 'all',
                  sDate: new Date(defaultDate).toISOString(),
                  eDate: new Date(defaultDateE).toISOString()
                }))
                setPageCounter(
                  db.data[0].TOTAL_ROW_NUM%30 === 0
                  ? Math.floor(db.data[0].TOTAL_ROW_NUM/30)
                  : (Math.floor(db.data[0].TOTAL_ROW_NUM/30)) + 1)
              }
              return
            })
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          if (db.data.length > 0) {
            setPanelInfo(db.data)
            setPage(1)
            setPageCounter(
              db.data[0].TOTAL_ROW_NUM%30 === 0
              ? Math.floor(db.data[0].TOTAL_ROW_NUM/30)
              : (Math.floor(db.data[0].TOTAL_ROW_NUM/30)) + 1
            )
          }
        })
      }

      if (change.navDate[0] !== null && change.navDate[1] !== null) {
        getInfo(+userId, gameType, sDate, eDate, 1, 30)
      }

      if (change.navDate[0] === null && change.navDate[1] === null) {
        getInfo(+userId, gameType, defaultDate.toISOString(), defaultDateE.toISOString(), 1, 30)
      }
    })
  }

  function gameCategory() {
    let gameList = []
    gameCategories.map((ele, index) => {
      gameList.push(<option value = {ele.gameID} key= {index}>{ele.gameName}</option>)
    })
    return gameList
  }
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <NavLeft>
          <InputBarRWD>
            <div>
              用戶ID<Input placeholder="請輸入欲查詢ID" name="navUserId" value={change.navUserId} onChange={handleChange} />
            </div>
            <DropWrapper>
              遊戲類型
              <Dropdown name="navGameType" value={change.navGameType} onChange={handleChange}>
                <option value="all">全部</option>
                {gameCategory()}
              </Dropdown>
            </DropWrapper>
          </InputBarRWD>
          <RangeWrapper>
            <DateWrapper>
              <DateTitle>創建時間</DateTitle>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                  <DesktopDateRangePicker
                    startText="創建時間"
                    endText="結束時間"
                    value={change.navDate}
                    onChange={newValue => {
                      setChange(prev => ({
                        ...prev,
                        navDate: newValue
                      }))
                    }}
                    renderInput={(startProps, endProps) => {
                      startProps.inputProps.placeholder = '開始日期'
                      endProps.inputProps.placeholder= '結束日期'
                      return (
                        <React.Fragment>
                          <DatePickerInput ref={startProps.inputRef} {...startProps.inputProps} />
                          <Box sx={{ mx: 1 }}>至</Box>
                          <DatePickerInput ref={endProps.inputRef} {...endProps.inputProps} />
                        </React.Fragment>
                      )
                    }}
                  />
                </Stack>
              </LocalizationProvider>
            </DateWrapper>
            <RangeGroup>
              <ClickRange
                onClick={() => {
                  setChange(prev => ({
                    ...prev,
                    navDate: today
                  }))
                }}>今天</ClickRange>
              <ClickRange
                onClick={() => {
                  setChange(prev => ({
                    ...prev,
                    navDate: yesterday
                  }))
                }}>昨天</ClickRange>
              <ClickRange
                onClick={() => {
                  setChange(prev => ({
                    ...prev,
                    navDate: thisWeek
                  }))
                }}>本周</ClickRange>
              <ClickRange
                onClick={() => {
                  setChange(prev => ({
                    ...prev,
                    navDate: lastWeek
                  }))
                }}>上周</ClickRange>
              <ClickRange
                onClick={() => {
                  setChange(prev => ({
                    ...prev,
                    navDate: thisMonth
                  }))
                }}>本月</ClickRange>
            </RangeGroup>
          </RangeWrapper>
        </NavLeft>
        <BtnGroup>
          <SearchButton onClick={handleSearch}>搜索</SearchButton>
          <ResetButton onClick={handleReset}>重置</ResetButton>
        </BtnGroup>
      </InputBarBg>

      <BasicTable className="table table-hover table-bordered">
        <thead>
          <BasicRowTitle>
            <th scope="col">用戶ID</th>
            <th scope="col">遊戲類型</th>
            <th scope="col">玩家1</th>
            <th scope="col">玩家2</th>
            <th scope="col">玩家3</th>
            <th scope="col">玩家4</th>
            <th scope="col">遊戲總時間</th>
            <th scope="col">創建時間</th>
            <th scope="col">結束時間</th>
          </BasicRowTitle>
        </thead>
        <tbody>
          {
            Array.isArray(panelInfo) && panelInfo.length ?
              (panelInfo.map((data, index) => {
                return (
                  <TableContent key= {index}>
                    <td>{data.UID}</td>
                    <td>{gameCategories.find(ele => +ele.gameID === +data.GAME_ID) ? gameCategories.find(ele => ele.gameID === data.GAME_ID).gameName : '---'}</td>
                    <td>{data.NICKNAME_LIST.split(',')[0] ? `${data.NICKNAME_LIST.split(',')[0]}(${data.UID_LIST.split(',')[0]}) ${data.RESULT_LIST.split(',')[0]}` : '-------'} </td>
                    <td>{data.NICKNAME_LIST.split(',')[1] ? `${data.NICKNAME_LIST.split(',')[1]}(${data.UID_LIST.split(',')[1]}) ${data.RESULT_LIST.split(',')[1]}` : '-------'} </td>
                    <td>{data.NICKNAME_LIST.split(',')[2] ? `${data.NICKNAME_LIST.split(',')[2]}(${data.UID_LIST.split(',')[2]}) ${data.RESULT_LIST.split(',')[2]}` : '-------'} </td>
                    <td>{data.NICKNAME_LIST.split(',')[3] ? `${data.NICKNAME_LIST.split(',')[3]}(${data.UID_LIST.split(',')[3]}) ${data.RESULT_LIST.split(',')[3]}` : '-------'} </td>
                    <td>{new Date(data.GAME_TIME*1000).toISOString().slice(11, 19)}</td>
                    <td>{TimezoneTranslator(data.START_TIME)}</td>
                    <td>{TimezoneTranslator(data.END_TIME)}</td>
                  </TableContent>
                )
              }))
            : <tr key= "noInfo"><td colSpan= "9">尚無資料</td></tr>
          }
        </tbody>
        <tfoot></tfoot>
      </BasicTable>
      {
        Array.isArray(panelInfo) && panelInfo.length ?
          <Stack spacing={2}>
            <PageStyle count={pageCounter}
              page= {page}
              color="warning"
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </Stack>
        : ''
      }
    </>
  )
}