import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { getAllAuth } from "../../middleware/Api/publicApi"

import AddAuth from "../../components/popUpWindow/auth/addAuth.js"
import ReviseAuth from "../../components/popUpWindow/auth/reviseAuth.js"
import DeleteAuth from "../../components/popUpWindow/auth/deleteAuth.js"
import { sessionVerified } from "../../middleware/Api/publicApi.js"
import errorCode from "../../assets/config/errorCode.json"
import {
  InputBarBg,
  InputBarRWD,
  FirstInputBar,
  InputBar,
  Input,
  BtnWrapper,
  BtnG1,
  Btn
} from "./styledComponent.js"
import {
  SearchButton,
  ResetButton,
  BasicTable,
  BasicRowTitle
} from "../../middleware/utilityStyle.js"

export default function AuthoritySetting() {
  const [panelInfo, setPanelInfo] = useState([])
  const [change, setChange] = useState({})
  const [popData, setPopData] = useState({})
  const [radioValue, setRadioValue] = useState('')
  const [authInfo, setAuthInfo] = useState([])
  const history = useHistory()

  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      getAllAuth().then(db => {
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setPanelInfo(db.data)
        setAuthInfo(db.data)
      })
    })
  }, [])

  const handleChange = e => {
    let newObj = Object.assign({}, change)
    newObj[e.target.name] = e.target.value
    setChange(newObj)
  }

  const handleRadioValue = e => {
    let chosenInfo = panelInfo.find(ele => ele.ID === +e.target.value)
    setPopData(chosenInfo)
    setRadioValue(+e.target.value)
  }

  const handleReset = () => {
    setChange({ navID: '', navName: '', navDes: '' })
  }

  const handleSearch = () => {
    if (!change.navID && !change.navName && !change.navDes) {
      setPanelInfo(authInfo)
    } else {
      const target = authInfo.find(ele => ele.ID === +change.navID || ele.accessName === change.navName || ele.description === change.navDes)
      if (target) {
        setPanelInfo([target])
      } else {
        setPanelInfo(authInfo)
        alert(`※  ${errorCode.RECORD_NOT_FOUND.memo}`)
      }
    }
  }

  {/* 新增 popUp */ }
  const [showAdd, setShowAdd] = useState(false);
  const handleAddClose = () => {
    setShowAdd(false)
    setChange({ popUpID: '', popUpName: '', popUpDescription: '' })
  }
  const handleAddShow = () => setShowAdd(true)

  {/* 修改 popUp */ }
  const [showRevise, setShowRevise] = useState(false)
  const handleReviseClose = () => setShowRevise(false)
  const handleReviseShow = () => {
    if (!radioValue) {
      alert('※  請選取欲修改之權限')
      return
    } else {
      setShowRevise(true)
    }
  }

  {/* 刪除 popUp */ }
  const [showDelete, setShowDelete] = useState(false)
  const handleDeleteClose = () => {
    setShowDelete(false)
    let newChange = Object.assign({}, change)
    newChange.reviseName = ''
    newChange.reviseDes = ''
    setChange(newChange)
  }
  const handleDeleteShow = () => {
    if (!radioValue) {
      alert('※  請選取欲刪除之權限')
      return
    } else {
      setShowDelete(true)
    }
  }
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <InputBarRWD>
          <FirstInputBar>
            權限ID<Input name='navID' placeholder="ID" value={change.navID} onChange={handleChange} />
          </FirstInputBar>
          <InputBar>
            權限名稱<Input name='navName' placeholder="Name" value={change.navName} onChange={handleChange} />
          </InputBar>
          <InputBar>
            權限描述<Input name='navDes' placeholder="Description" value={change.navDes} onChange={handleChange} />
          </InputBar>
        </InputBarRWD>
        <div>
          <SearchButton onClick={handleSearch}>搜索</SearchButton>
          <ResetButton onClick={handleReset}>重置</ResetButton>
        </div>
      </InputBarBg>

      <BasicTable className="table table-hover table-bordered">
        <thead>
          <BasicRowTitle>
            {/* <th scope="col">
              <input type="radio" id="flexCheckChecked" disabled />
            </th> */}
            <th scope="col">權限值</th>
            <th scope="col">權限名稱</th>
            <th scope="col">權限描述</th>
          </BasicRowTitle>
        </thead>
        <tbody>
          {
            Array.isArray(panelInfo) && panelInfo.length ?
              panelInfo.map((data) => {
                return (
                  <tr key={data.ID}>
                    {/* <th scope="row">
                      <input
                        type="radio"
                        name="authList"
                        value={data.ID}
                        onChange={handleRadioValue}
                        checked={(radioValue === data.ID) ? 'checked' : ''} />
                    </th> */}
                    <td>{data.ID}</td>
                    <td>{data.accessName}</td>
                    <td>{data.description}</td>
                  </tr>
                )
              })
              : <tr key= "noInfo"><td colSpan= "4">查無資料</td></tr>
          }
        </tbody>
        <tfoot></tfoot>
      </BasicTable>

      {/* <BtnWrapper className="btn-group">
        <BtnG1>
          <Btn className="butt-btn" onClick= {handleAddShow}>新增</Btn>
          <Btn className="butt-btn" onClick={handleReviseShow}>修改</Btn>
          <Btn className="butt-btn" onClick={handleDeleteShow}>刪除</Btn>
        </BtnG1>
      </BtnWrapper> */}

      {/* 新增 popUp */}
      <AddAuth
        showAdd={showAdd}
        handleAddClose={handleAddClose}
        change={change}
        setChange={setChange}
        handleChange={handleChange}
        setPanelInfo={setPanelInfo}
        history={history}
        setAuthInfo={setAuthInfo}
      />

      {/* 修改 popUp */}
      <ReviseAuth
        popData={popData}
        showRevise={showRevise}
        setShowRevise={setShowRevise}
        change={change}
        setChange={setChange}
        handleChange={handleChange}
        handleReviseClose={handleReviseClose}
        setPanelInfo={setPanelInfo}
        history={history}
        setAuthInfo={setAuthInfo}
      />

      {/* 刪除 popUp */}
      <DeleteAuth
        popData={popData}
        showDelete={showDelete}
        handleDeleteClose={handleDeleteClose}
        setPanelInfo={setPanelInfo}
        history={history}
        setAuthInfo={setAuthInfo}
      />
    </>
  )
}