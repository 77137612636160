import { getAuthToken } from '../../utils.js'
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 寄出簡訊 PID50 ?//
export const sendSMS = (sendAll, phoneArr, SMScontain) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/serverEnv/postSMS`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      sendAll,
      phoneArr,
      SMScontain
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}