import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { newsTickerList, submitNewsTicker } from "../../../middleware/Api/backstageManagement/newsTickerApi"
import NewsTickerInfo from "../../../components/popUpWindow/newsTicker/newsTickerInfo"
import { TimezoneTranslator } from "middleware/dateUtils.js"
import { getAccessArr } from "../../../middleware/utils"
import {
  AddBtn,
  InformBtn,
  BasicTable,
  BasicRowTitle,
  TableContent,
  PopDetail
} from "../../../middleware/utilityStyle"

export default function NewsTicker() {
  function DBNewsTickerList() {
    newsTickerList().then(db => {
      if (db.error === errorCode.NULL_DATA.code) {
        alert(`※  ${errorCode.NULL_DATA.memo}`)
        return
      }
      if (db.error === errorCode.EXCEPTION.code) {
        alert(`※  ${errorCode.EXCEPTION.memo}`)
        return
      }

      if (Array.isArray(db.data) && db.data.length) {
        db.data.sort(function order(a, b) {
          return a.ORDER_ID - b.ORDER_ID
        })
        db.data.sort(function order(a, b) {
          return a.TYPE - b.TYPE
        })
        setPanelInfo(db.data)
        setDataCopy(db.data)
      }
    })
  }

  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const [panelInfo, setPanelInfo] = useState([])
  const [dataCopy, setDataCopy] = useState([])
  const history = useHistory()
  const [checkDetails, setCheckDetails] = useState(false)
  const [targetObj, setTargetObj] = useState({
    ORDER_ID: '',
    STATE: '',
    TYPE: '',
    STATE: 1,
    BEGIN_DATE: '',
    EXPIRE_DATE: '',
    WEEK: '1,1,1,1,1,1,1',
    INTERVAL_START1: '',
    INTERVAL_END1: '',
    INTERVAL_START2: '',
    INTERVAL_END2: '',
    INTERVAL_START3: '',
    INTERVAL_END3: ''
  })
  
  const [editable, setEditable] = useState(false)
  const [deleteBtn, setDeleteBtn]= useState(false)
  const [okBtn, setOkBtn] = useState(false)
  const [cancelBtn, setCancelBtn]= useState(false)

  useEffect (()=> {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (userPID.find(ele => +ele === 25)) {
        setDeleteBtn(true)
      } else {
        setDeleteBtn(false)
      }
      if (userPID.find(ele => +ele === 25) || userPID.find(ele => +ele === 26)) {
        setCancelBtn(true)
      } else {
        setCancelBtn(false)
      }
      if (!userPID.find(ele => +ele === 25) && !userPID.find(ele => +ele === 26)) {
        setOkBtn(true)
      } else {
        setOkBtn(false)
      }
      DBNewsTickerList()
    })
  }, [])

  const [showInfoPU, setInfoPUShow] = useState(false)
  const handleInfoPUClose = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setInfoPUShow(false)
    })
  }
  const handleInfoPUShow = () => setInfoPUShow(true)

  const handleCleanData = () => {
    setTargetObj({
      ORDER_ID: '',
      STATE: '',
      TYPE: '',
      STATE: 1,
      BEGIN_DATE: '',
      EXPIRE_DATE: '',
      WEEK: '1,1,1,1,1,1,1',
      INTERVAL_START1: '',
      INTERVAL_END1: '',
      INTERVAL_START2: '',
      INTERVAL_END2: '',
      INTERVAL_START3: '',
      INTERVAL_END3: '',
      TEXT: ''
    })
  }

  const handleAddBtn = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 24)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        setEditable(false)
        return
      } else {
        setEditable(true)
      }
      if (dataCopy.length >= 20 ) {
        alert('※  清單資料已滿，無法新增')
        return
      }
      handleCleanData()
      setCheckDetails(false)
      handleInfoPUShow()
    })
  }

  const handleInfo = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      let checkTarget = dataCopy.find(ele => +ele.ORDER_ID === +e.target.dataset.id)
      setTargetObj(checkTarget)
      if (userPID.find(ele => +ele === 26)) {
        setEditable(true)
      } else {
        setEditable(false)
      }
      setCheckDetails(true)
      handleInfoPUShow()
    })
  }

  const typeName = new Map([
    [1, '即時'],
    [2, '時段'],
    [3, '常態']
  ])
  const transType = new Map(typeName)

  const handleSubmit = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 27)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      submitNewsTicker().then(db => {
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
      })
    })
  }
  return (
    <>
      <div>
        <AddBtn variant="success" onClick= {handleAddBtn}>新增</AddBtn>
        <InformBtn variant="warning" onClick= {handleSubmit}>通知</InformBtn>
      </div>
      <BasicTable className="table table-hover table-bordered">
        <thead>
          <BasicRowTitle>
            <th scope="col">排序</th>
            <th scope="col">狀態</th>
            <th scope="col">類型</th>
            <th scope="col">開始時間</th>
            <th scope="col">結束時間</th>
            <th scope= "col">指定星期</th>
            <th scope="col">內文</th>
            <th scope="col">詳細</th>
          </BasicRowTitle>
        </thead>
        <tbody>
          {
            Array.isArray(panelInfo) && panelInfo.length ?
              panelInfo.map((EachData, index) => {
                return (
                  <TableContent key= {index}>
                    <td>{EachData.ORDER_ID}</td>
                    <td>{+EachData.STATE === 1 ? '開啟' : '關閉'}</td>
                    <td>{transType.get(+EachData.TYPE)}</td>
                    <td>{+EachData.TYPE === 1 ? '-------' : TimezoneTranslator(EachData.BEGIN_DATE).slice(0, 16)}</td>
                    <td>{+EachData.TYPE === 1 ? '-------' : TimezoneTranslator(EachData.EXPIRE_DATE).slice(0, 16)}</td>
                    <td>{+EachData.TYPE === 1 ? '---' : EachData.WEEK.split(',').every(ele => +ele === 1) ? '每天' : '星期'}</td>
                    <td>{EachData.TEXT}</td>
                    <PopDetail data-id= {EachData.ORDER_ID} onClick= {handleInfo}>查看</PopDetail>
                  </TableContent>
                )
              })
              : <tr key= "noInfo"><td colSpan= "9">尚無資料</td></tr>
          }
        </tbody>
      </BasicTable>
        
      <NewsTickerInfo
        history= {history}
        showInfoPU= {showInfoPU}
        handleInfoPUShow= {handleInfoPUShow}
        handleInfoPUClose= {handleInfoPUClose}
        targetObj= {targetObj}
        editable= {editable}
        deleteBtn= {deleteBtn}
        cancelBtn= {cancelBtn}
        okBtn= {okBtn}
        checkDetails= {checkDetails}
        handleCleanData= {handleCleanData}
        setPanelInfo= {setPanelInfo}
        dataCopy= {dataCopy}
        setDataCopy= {setDataCopy}
        DBNewsTickerList= {DBNewsTickerList}
      />
    </>
  )
}