import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker'
import { today, yesterday, thisWeek, lastWeek, thisMonth, defaultDate, defaultDateE, TimezoneTranslator } from "../../../middleware/dateUtils"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import depositList from "../../../assets/config/depositList.json"
import { SearchDepositList, getProductList } from "../../../middleware/Api/playerInfo/depositListApi"
import {
  InputBarRWD,
  FirstInputBar,
  InputBar,
  SelectWrapper,
  Dropdown,
  SecondRow,
  Input,
  FirstSelect,
  DepositDateWrapper,
  DatePickerInput,
  LeftPart,
  RangeGroup,
  BtnGroup,
  CalculateTitle
} from './styledComponent'

import {
  BarBG,
  DateWrapper,
  DateTitle,
  DateStack,
  ClickRange,
  SearchButton,
  ResetButton,
  BasicTable,
  BasicRowTitle,
  TableContent,
  PageStyle
} from "../../../middleware/utilityStyle.js"

export default function DepositList() {
  const [panelInfo, setPanelInfo] = useState([])
  const [change, setChange] = useState({
    uid: '',
    orderID: '',
    itemID: '',
    platform: '',
    status: 5,
    depositDate: [new Date(defaultDate), new Date(defaultDateE)]    
  })

  const history = useHistory()
  const [changePagePara, setChangePagePara] = useState({
    uid: 0,
    orderID: 'all',
    itemID: 'all',
    platform: 0,
    status: 5,
    sDate: defaultDate.toISOString(),
    eDate: defaultDateE.toISOString()
  })

  const [pageCounter, setPageCounter] = useState(0)  //最大頁數
  const [pageTotalPrice, setPageTotalPrice] = useState(0)
  const [page, setPage] = useState(1)
  const [productList, setProductList] = useState([])
  const handleChange = e => {
    let newObj = Object.assign({}, change) //複製一份新的states
    newObj[e.target.name] = e.target.value
    setChange(newObj)
  }

  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      getProductList().then(db => {
        let list = []
        if (db.length) {
          db.map(ele => {
            if (!list.find(obj => obj.id === ele.id)) {
              list.push(ele)
            }
          })
          // list.sort(function order(a, b) {
          //   return a.num - b.num
          // })
          setProductList(list)
        }
      })
    })
  }, [])

  const handleChangePage = (event, newPage) => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      SearchDepositList(changePagePara.uid, changePagePara.orderID, changePagePara.itemID, changePagePara.platform, changePagePara.status,
      changePagePara.sDate, changePagePara.eDate, 1+(newPage-1)*30, newPage*30).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setPage(newPage)
        setPanelInfo(db.data)
        handlePagePrice(db.data)
      })
    })
  }

  const handlePagePrice = (searchInfo) => {
    if (searchInfo.length > 0) {
      let totalPrice = 0
      for (let i = 0; i < searchInfo.length; i++) {
        totalPrice += searchInfo[i].ITEM_PRICE
      }
      setPageTotalPrice(totalPrice)
    }
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      let uid = 0      
      if (change.uid) {
        if (isNaN(change.uid)) {
          alert('※  用戶ID請輸入數字')
          return
        } else {
          setChangePagePara(prev => ({
            ...prev,
            uid: +change.uid
          }))
          uid = +change.uid
        }        
      }

      let orderID = 'all'
      if (change.orderID !== '') {
        setChangePagePara(prev => ({
          ...prev,
          orderID: change.orderID
        }))
        orderID = change.orderID
      }

      let itemID = 'all'
      if (change.itemID !== 'all' && change.itemID !== '') {
        setChangePagePara(prev => ({
          ...prev,
          itemID: change.itemID
        }))
        itemID = change.itemID
      }

      let platform = 0
      if (+change.platform > 0) {
        setChangePagePara(prev => ({
          ...prev,
          platform: +change.platform
        }))
        platform = +change.platform
      }

      let status = 5
      if (change.status !== 5) {
        setChangePagePara(
          prev => ({
            ...prev,
            status
          })
        )
        status = +change.status      
      }

      let sDate = ''
      let eDate = ''
      if (change.depositDate[0] !== null && change.depositDate[1] !== null) {
        sDate = new Date(change.depositDate[0]).toISOString()
        let end = new Date(change.depositDate[1]).toString()
        eDate = new Date(end.replace('00:00:00', '23:59:59')).toISOString()        
      }
      if (change.depositDate[0] === null || change.depositDate[1] === null) {
        sDate = defaultDate.toISOString()
        eDate= defaultDateE.toISOString()
      }
      setChangePagePara(prev => ({
        ...prev,
        sDate,
        eDate
      }))

      SearchDepositList(uid, orderID, itemID, platform, status, sDate, eDate, 1, 30).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          SearchDepositList(0, 'all', 'all', 0, 5, defaultDate.toISOString(), defaultDateE.toISOString(), 1, 30).then(db => {
            setChange(prev => ({
              ...prev,
              uid: '',
              orderID: '',
              itemID: '',
              platform: 0,
              status: 5,
              depositDate: [new Date(defaultDate), new Date(defaultDateE)]
            }))
            setChangePagePara(prev => ({
              ...prev,
              uid: 0,
              orderID: 'all',
              itemID: 'all',
              platform: 0,
              status: 5,
              sDate: defaultDate.toISOString(),
              eDate: defaultDateE.toISOString()
            }))
            if (db.error === errorCode.INCORRECT_DATA.code) {
              alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.INCORRECT_FORMAT.code) {
              alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            if (db.error === errorCode.NULL_DATA.code) {
              alert('※  預設日期無資料')
              setPanelInfo([])
              return
            }
            if(db.data) {
              if (db.data.length > 0) {
                setPanelInfo(searchInfo)
                setPageCounter(
                  db.data[0].TOTAL_ROW_NUM%30 === 0
                  ? Math.floor(db.data[0].TOTAL_ROW_NUM/30)
                  : (Math.floor(db.data[0].TOTAL_ROW_NUM/30)) + 1
                )
                setPage(1)
                handlePagePrice(db.data)
              }
            }
          })
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if(db.data) {
          if (db.data.length > 0) {
            setPanelInfo(db.data)
            setPageCounter(
              db.data[0].TOTAL_ROW_NUM%30 === 0
              ? Math.floor(db.data[0].TOTAL_ROW_NUM/30)
              : (Math.floor(db.data[0].TOTAL_ROW_NUM/30)) + 1
            )
            setPage(1)
            handlePagePrice(db.data)
          }
        }
      })
    })
  }

  const transStatus = new Map([
    [1, '驗證中'],
    [2, '驗證成功'],
    [3, '驗證失敗'],
    [4, '道具發送失敗'],
    [5, '成功']
  ])

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({uid: '', orderID: '', itemID: '', platform: 0, status: 5, depositDate: [new Date(defaultDate), new Date(defaultDateE)]})
    })
  }

  function itemDropdown() {
    const optionList = []
    const { productIdPool } = require ("../../../assets/config/productId.json")
    optionList.push(
      <option value="all">全部</option>
    )
    if (productList.length) {
      productList.map((ele, index) => {
        optionList.push(
          <option key= {index} value= {ele.id}>{`${productIdPool[ele.id]}`}</option>
        )
      })
    }
    return optionList
  }

  const handleName = (type, itemID) => {
    let targetPlatform = depositList.find(ele => +ele.type === +type)
    if (targetPlatform) {
      return targetPlatform.item[itemID]
    }
  }
  return (
    <>
      <BarBG className="navbar navbar-light bg-light">
        <InputBarRWD>
          <FirstInputBar>
            用戶ID<Input placeholder="請輸入欲查詢ID" name="uid" value={change.uid} onChange={handleChange} />
          </FirstInputBar>
          <InputBar>
            訂單號
            <Input placeholder="請輸入欲查詢單號" name="orderID" value={change.orderID} onChange={handleChange} />
          </InputBar>
          <SelectWrapper>
            <span>購買道具</span>
            <Dropdown name="itemID" value= {change.itemID} onChange= {handleChange}>
              {itemDropdown()}
            </Dropdown>
          </SelectWrapper>
          <SelectWrapper>
            <span>儲值平台</span>
            <Dropdown name="platform" value={change.platform} onChange={handleChange}>
              <option value="0">全部</option>
              <option value="1">GOOGLE</option>
              <option value="2">IOS</option>
              <option value="3">MY CARD</option>
            </Dropdown>
          </SelectWrapper>
        </InputBarRWD>
        
        <SecondRow>
        <LeftPart>
          <FirstSelect>
            <span>訂單狀態</span>
            <Dropdown name="status" value={change.status} onChange={handleChange}>
              <option value="0">全部</option>
              <option value="1">驗證中</option>
              <option value="2">驗證成功</option>
              <option value="3">驗證失敗</option>
              <option value="4">道具發送失敗</option>
              <option value="5">成功</option>
            </Dropdown>
          </FirstSelect>
          <DepositDateWrapper>
            <DateWrapper>
              <DateTitle>儲值日期</DateTitle>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateStack spacing={3}>
                  <DesktopDateRangePicker
                    startText=""
                    endText=""
                    value={change.depositDate}
                    onChange={newValue => {
                      setChange(prev => ({
                        ...prev,
                        depositDate: newValue
                      }))
                    }}
                    renderInput={(startProps, endProps) => {
                      startProps.inputProps.placeholder = '開始日期'
                      endProps.inputProps.placeholder = '結束日期'
                      return (
                        <React.Fragment>
                          <DatePickerInput ref={startProps.inputRef} {...startProps.inputProps} />
                          <Box sx={{ mx: 1 }}>至</Box>
                          <DatePickerInput ref={endProps.inputRef} {...endProps.inputProps} />
                        </React.Fragment>
                      )
                    }}
                  />
                </DateStack>
              </LocalizationProvider>
            </DateWrapper>
            <RangeGroup>
              <ClickRange
                onClick={() => {
                  setChange(prev => ({
                    ...prev,
                    depositDate: today
                  }))
                }}>今天</ClickRange>
              <ClickRange
                onClick={() => {
                  setChange(prev => ({
                    ...prev,
                    depositDate: yesterday
                  }))
                }}>昨天</ClickRange>
              <ClickRange
                onClick={() => {
                  setChange(prev => ({
                    ...prev,
                    depositDate: thisWeek
                  }))
                }}>本周</ClickRange>
              <ClickRange
                onClick={() => {
                  setChange(prev => ({
                    ...prev,
                    depositDate: lastWeek
                  }))
                }}>上周</ClickRange>
              <ClickRange
                onClick={() => {
                  setChange(prev => ({
                    ...prev,
                    depositDate: thisMonth
                  }))
                }}>本月</ClickRange>
            </RangeGroup>
          </DepositDateWrapper>
        </LeftPart>
        <BtnGroup>
          <SearchButton onClick= {handleSearch}>搜索</SearchButton>
          <ResetButton onClick= {handleReset}>重置</ResetButton>
        </BtnGroup>
        </SecondRow>
      </BarBG>

      <BasicTable className="table table-hover table-bordered">
        <thead>
          <BasicRowTitle>
            <th scope="col">用戶ID</th>
            <th scope="col">用戶訂單號</th>
            <th scope="col">購買道具</th>
            <th scope="col">儲值平台</th>
            <th scope="col">所需金額(元)</th>
            <th scope="col">實際支付(元)</th>
            <th scope="col">訂單狀態</th>
            <th scope="col">儲值日期</th>
            <th scope="col">成功交易日期</th>
          </BasicRowTitle>
        </thead>
        <tbody>
          {
            Array.isArray(panelInfo) && panelInfo.length ?
              panelInfo.map((EachData, index) => {
                return (
                  <TableContent key= {index}>
                    <td>{EachData.UID}</td>
                    <td>{EachData.ORDER_ID}</td>
                    <td>{handleName(+EachData.INFULL_TYPE, EachData.ITEM_ID)}</td>
                    <td>{depositList.find(ele => +ele.type === +EachData.INFULL_TYPE).platform}</td>
                    <td>{EachData.ITEM_PRICE}</td>
                    <td>{EachData.ITEM_PRICE}</td>
                    <td>{transStatus.get(+EachData.INFULL_STATUS)}</td>
                    <td>{TimezoneTranslator(EachData.INFULL_DATE)}</td>
                    <td>{TimezoneTranslator(EachData.SUCCESS_DATE)}</td>
                  </TableContent>
                )
              })
              : <tr key= "noInfo"><td colSpan= "9">尚無資料</td></tr>
          }
        </tbody>
        <tfoot></tfoot>
      </BasicTable>
      
      <BasicTable className="table table-hover table-bordered">
        <thead>
        <CalculateTitle>
            <th scope="col">總計項目</th>
            <th scope="col">所需金額(元)</th>
            <th scope="col">實際支付(元)</th>
            <th scope="col">筆數</th>
          </CalculateTitle>
        </thead>
        <tbody>
          {
            Array.isArray(panelInfo) && panelInfo.length > 0 ?
            <>
              <tr>
                <th>本頁次總計</th>
                <td>{panelInfo.length > 0 ? pageTotalPrice : 0}</td>
                <td>{panelInfo.length > 0 ? pageTotalPrice : 0}</td>
                <td>{panelInfo.length > 0 ? panelInfo.length : 0}</td>
              </tr>

              <tr>
                <th>總資料總計</th>
                <td>{panelInfo.length > 0 ? panelInfo[0].TOTAL_PRICE : 0}</td>
                <td>{panelInfo.length > 0 ? panelInfo[0].TOTAL_PRICE : 0}</td>
                <td>{panelInfo.length > 0 ? panelInfo[0].TOTAL_ROW_NUM : 0}</td>
              </tr>
            </>
            : <tr key= "noInfo"><td colSpan= "4">尚無資料</td></tr>
          }
        </tbody>
      </BasicTable>
      {
        Array.isArray(panelInfo) && panelInfo.length ?
          <Stack spacing={2}>
            <PageStyle count={pageCounter}
              page= {page}
              color="warning"
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </Stack>
        : ''
      }
    </>
  )
}