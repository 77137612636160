import React from "react"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import Box from '@mui/material/Box'
import { TimezoneTranslator } from "../../../middleware/dateUtils"
import { ModalFormat } from "../../../middleware/utilityStyle"
import {
  Title,
  SendTimeWrapper,
  TimeFormat,
  RadioFormat,
  ItemDivider,
  ItemTitle,
  Dropdown,
  InputFormat,
  Blank,
  Btn
} from "../../../views/playerInfo/mailSearching/styledComponent"

export default function Details(props) {
  const {
    showDetailsPU,
    handleDetailsPUClose,
    targetObj
  } = props
  return (
    <>
      <ModalFormat
        show={showDetailsPU}
        onHide={handleDetailsPUClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>派送紀錄</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
          <tbody>
            <tr>
              <Title className="table-active">派送時間</Title>
              <td>
                <SendTimeWrapper>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="sendTime"
                      disabled
                      checked= {targetObj.SEND_TYPE}
                    />即時
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="sendTime"
                      disabled
                      checked= {!targetObj.SEND_TYPE}
                    />預約
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label= "日期"
                      disabled
                      value= {targetObj.SEND_TYPE ? '' : TimezoneTranslator(targetObj.SEND_DATE)}
                      onChange= {() => {}}
                      renderInput={({ inputRef, inputProps, InputProps }) => {
                        inputProps.placeholder = '日期'
                        return (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Blank id= "datePicker" ref={inputRef} {...inputProps} disabled />
                            <div id="dateBtn">{InputProps?.endAdornment}</div>
                          </Box>
                        )
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label="時間"
                      disabled
                      value= {targetObj.SEND_TYPE ? '' : TimezoneTranslator(targetObj.SEND_DATE)}
                      onChange= {() => {}}
                      renderInput={({ inputRef, inputProps, InputProps }) => {
                        inputProps.placeholder = '時間'
                        return (
                          <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                            <Blank id= "timePicker" ref={inputRef} {...inputProps} disabled />
                            {InputProps?.endAdornment}
                          </TimeFormat>
                        )
                      }}
                    />
                  </LocalizationProvider>
                </SendTimeWrapper>
              </td>
            </tr>

            <tr>
              <Title className="table-active">派送對象</Title>
              <td>
              <div>
                <RadioFormat
                  className="form-check-input"
                  type="radio"
                  name="addressTo"
                  disabled
                  checked= {targetObj.TO_ALL}
                />全服派送
              </div>
              <div>
                <RadioFormat
                  className="form-check-input"
                  type="radio"
                  name="addressTo"
                  disabled
                  checked= {!targetObj.TO_ALL}
                />指定派送
              </div>
              <div>
                <textarea
                  className="form-control"
                  id= "specificReceiver"
                  rows="3"
                  placeholder="請填寫玩家ID，派送多人請以逗點分開，例如：00123, 00124, 00125"
                  name= "receiver"
                  disabled
                  value= {targetObj.TARGET_UID !== 'all' ? targetObj.TARGET_UID : ''}
                />
              </div>
              </td>
            </tr>

            <tr>
              <Title className="table-active">信件標題</Title>
              <td>
                <Blank
                  placeholder= "請輸入標題"
                  disabled
                  value= {targetObj.TITLE}
                />
              </td>
            </tr>

            <tr>
              <Title className="table-active">信件內容</Title>
              <td>
                <div>
                  <textarea
                    className="form-control"
                    rows="7"
                    placeholder="請輸入內容"
                    disabled
                    value= {targetObj.MESSAGE}
                  />
                </div>
              </td>
            </tr>

            <tr>
              <Title className="table-active">派送道具</Title>
              <td>
                <div>
                  <span>
                    <ItemTitle>道具</ItemTitle>
                    <Dropdown value= {+targetObj.ITEM1_ID} disabled>
                      <option value="">請選擇</option>
                      <option value="1">金幣</option>
                      <option value="2">鬥陣寶</option>
                      <option value={+targetObj.ITEM1_ID > 100 ? +targetObj.ITEM1_ID : -1}>道具ID</option>
                    </Dropdown>
                    <ItemTitle style= {+targetObj.ITEM1_ID <= 100 ? {display:'none'} : {}}>道具ID</ItemTitle>
                    <InputFormat
                      placeholder= "ID"
                      style= {+targetObj.ITEM1_ID <= 100 ? {display:'none'} : {}}
                      disabled
                      value= {
                        +targetObj.ITEM1_ID ? +targetObj.ITEM1_ID : ''
                      }
                    />
                  </span>
                  <span>
                    <ItemTitle>數量</ItemTitle>
                    <InputFormat
                      placeholder= "請輸入數量"
                      disabled
                      value= {
                        +targetObj.ITEM1_NUM ? +targetObj.ITEM1_NUM : ''
                      }
                    />
                  </span>
                </div>
                <ItemDivider>
                  <span>
                    <ItemTitle>道具</ItemTitle>
                    <Dropdown value= {+targetObj.ITEM2_ID}disabled>
                      <option value="">請選擇</option>
                      <option value="1">金幣</option>
                      <option value="2">鬥陣寶</option>
                      <option value={+targetObj.ITEM1_ID > 100 ? +targetObj.ITEM1_ID : -1}>道具ID</option>
                    </Dropdown>
                    <ItemTitle style= {+targetObj.ITEM2_ID <= 100 ? {display:'none'} : {}}>道具ID</ItemTitle>
                    <InputFormat
                      placeholder= "ID"
                      style= {+targetObj.ITEM2_ID <= 100 ? {display:'none'} : {}}
                      disabled
                      value= {
                        +targetObj.ITEM2_ID ? +targetObj.ITEM2_ID : ''
                      }
                    />
                  </span>
                  <span>
                    <ItemTitle>數量</ItemTitle>
                    <InputFormat
                      placeholder= "請輸入數量"
                      disabled
                      value= {
                        +targetObj.ITEM2_NUM ? +targetObj.ITEM2_NUM : ''
                      }
                    />
                  </span>
                </ItemDivider>
                <ItemDivider>
                  <span>
                    <ItemTitle>道具</ItemTitle>
                    <Dropdown value= {+targetObj.ITEM3_ID}disabled>
                      <option value="">請選擇</option>
                      <option value="1">金幣</option>
                      <option value="2">鬥陣寶</option>
                      <option value={+targetObj.ITEM1_ID > 100 ? +targetObj.ITEM1_ID : -1}>道具ID</option>
                    </Dropdown>
                    <ItemTitle style= {+targetObj.ITEM3_ID <= 100 ? {display:'none'} : {}}>道具ID</ItemTitle>
                    <InputFormat
                      placeholder= "ID"
                      style= {+targetObj.ITEM3_ID <= 100 ? {display:'none'} : {}}
                      disabled
                      value= {
                        +targetObj.ITEM3_ID ? +targetObj.ITEM3_ID : ''
                      }
                    />
                  </span>
                  <span>
                    <ItemTitle>數量</ItemTitle>
                    <InputFormat
                      placeholder= "請輸入數量"
                      disabled
                      value= {
                        +targetObj.ITEM3_NUM ? +targetObj.ITEM3_NUM : ''
                      }
                    />
                  </span>
                </ItemDivider>
                <ItemDivider>
                  <span>
                    <ItemTitle>道具</ItemTitle>
                    <Dropdown value= {+targetObj.ITEM4_ID} disabled>
                      <option value="">請選擇</option>
                      <option value="1">金幣</option>
                      <option value="2">鬥陣寶</option>
                      <option value={+targetObj.ITEM1_ID > 100 ? +targetObj.ITEM1_ID : -1}>道具ID</option>
                    </Dropdown>
                    <ItemTitle style= {+targetObj.ITEM4_ID <= 100 ? {display:'none'} : {}}>道具ID</ItemTitle>
                    <InputFormat
                      placeholder= "ID"
                      style= {+targetObj.ITEM4_ID <= 100 ? {display:'none'} : {}}
                      disabled
                      value= {
                        +targetObj.ITEM4_ID ? +targetObj.ITEM4_ID : ''
                      }
                    />
                  </span>
                  <span>
                    <ItemTitle>數量</ItemTitle>
                    <InputFormat
                      id= "item4Num"
                      placeholder= "請輸入數量"
                      name= "fourthItemNum"
                      disabled
                      value= {
                        +targetObj.ITEM4_NUM ? +targetObj.ITEM4_NUM : ''
                      }
                    />
                  </span>
                </ItemDivider>
              </td>
            </tr>
          </tbody>
          <tfoot></tfoot>
          </table>
        </Modal.Body>
        <Btn>
          <Button variant="info" onClick={handleDetailsPUClose}>確認</Button>
        </Btn>
      </ModalFormat>
    </>
  )
}