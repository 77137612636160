import React from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { ModalFormat, ModalText, ModalBtn } from "../../../middleware/utilityStyle"
import { deleteUserItem } from "middleware/Api/operation/userItemApi.js"

export default function ConfirmPU(props) {
  const {
    showConfirm,
    handleAddReviseShow,
    handleConfirmClose,
    form,
    setPanelInfo,
    setPopData,
    cleanData
  } = props
  const history = useHistory()
  const {
    ITEM_ID, 
    UID,
    ITEM_NAME
  } = form
  
  const handleSubmit = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      deleteUserItem(+form.UID, +form.ITEM_ID).then(db => {
	    if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if (db.error === errorCode.SERVER_ERROR.code) {
          alert(`※  ${errorCode.SERVER_ERROR.memo}`)
          return
        }
        setPanelInfo([])
        setPopData({})
      })

      cleanData()
      handleConfirmClose()
    })
  }

  const handleBack = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v 。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v 。`)
        history.push("/login")
        return
      }
      handleConfirmClose()
      handleAddReviseShow()
    })
  }
  return (
    <>
      <ModalFormat
        show={showConfirm}
        onHide={handleConfirmClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>道具移除</Modal.Title>
        </Modal.Header>
        <ModalText>
          確定移除用戶{form.UID}的道具{form.ITEM_NAME}?<br />
          若該道具狀態為使用中，則會消除狀態<br />
          若該造型為套用中，則會恢復預設造型<br />
          <br />
        </ModalText>
        <ModalBtn>
          <Button variant="info" onClick= {handleSubmit}>確定</Button>
          <Button variant="secondary" onClick= {handleBack}>取消</Button>
        </ModalBtn>
      </ModalFormat>
    </>
  )
}