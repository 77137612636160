import { getAuthToken } from '../../utils.js'
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 儲值訂單查詢 PID30 ?//
export const SearchDepositList = (uid, orderID, itemID, platform, status, sDate, eDate,
sRow, eRow) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/order?uid=${uid}&orderID=${orderID}&itemID=${itemID}&platform=${platform}
  &status=${status}&sDate=${sDate}&eDate=${eDate}&sRow=${sRow}&eRow=${eRow}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

export const getProductList = () => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/order/productList`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}