const CURRENT_T = new Date().getTime()
const CURRENT_Y = new Date().getFullYear()
const CURRENT_M = new Date().getMonth()
const MONTH_DAY = new Date(CURRENT_Y, CURRENT_M + 1, 0).getDate()
const A_DAY = 24 * 60 * 60 * 1000
const OFFSET_DAY = new Date(CURRENT_T).getDay()

export const today = [new Date(`${new Date(CURRENT_T - A_DAY).toISOString().slice(0, 10)}T16:00:00.000Z`), new Date(`${new Date(CURRENT_T).toISOString().slice(0, 10)}T15:59:59.000Z`)]
export const yesterday = [new Date(`${new Date(CURRENT_T  - A_DAY * 2).toISOString().slice(0, 10)}T16:00:00.000Z`), new Date(`${new Date(CURRENT_T - A_DAY).toISOString().slice(0, 10)}T15:59:59.000Z`)]
export const thisWeek = [new Date(`${new Date(CURRENT_T-(OFFSET_DAY+1) * A_DAY).toISOString().slice(0, 10)}T16:00:00.000Z`), new Date(`${new Date(CURRENT_T+(7-OFFSET_DAY-1) * A_DAY).toISOString().slice(0, 10)}T15:59:59.000Z`)]
export const lastWeek = [new Date(`${new Date(CURRENT_T-(OFFSET_DAY+1) * A_DAY - A_DAY * 7).toISOString().slice(0, 10)}T16:00:00.000Z`), new Date(`${new Date(CURRENT_T+(7-OFFSET_DAY-1) * A_DAY - A_DAY * 7).toISOString().slice(0, 10)}T15:59:59.000Z`)]
export const thisMonth = [new Date(`${new Date(CURRENT_Y, CURRENT_M, 1).toISOString().slice(0, 10)}T16:00:00.000Z`), new Date(new Date(`${new Date(CURRENT_Y, CURRENT_M, MONTH_DAY).toISOString().slice(0, 10)}T15:59:59.000Z`).getTime()+ A_DAY)]

export function TimezoneTranslator(dateStr) {
  if (dateStr.indexOf('T') > -1 && dateStr.indexOf('Z') > -1) {
    let lessMin = new Date(dateStr).getTimezoneOffset()  //-480
    let dbDate = (new Date(new Date(dateStr).getTime()-(lessMin*60*1000)).toISOString())
    return(`${dbDate.slice(0, 10)} ${dbDate.slice(11, 19)}`)  
  } else {
    let year = new Date(dateStr).getFullYear()
    let month = new Date(dateStr).getMonth() + 1
    let date = new Date(dateStr).getDate()
    let hr = new Date(dateStr).getHours()
    let min = new Date(dateStr).getMinutes()
    let sec = new Date(dateStr).getSeconds()
    return(`${year}-${month < 10 ? '0'+month : month}-${date < 10 ? '0'+date : date} ${hr < 10 ? '0'+hr : hr}:${min < 10 ? '0'+min : min}:${sec < 10 ? '0'+sec : sec}`)
  }
}

export const defaultDate = new Date(`${new Date(CURRENT_T - A_DAY * 15).toISOString().slice(0, 10)}T16:00:00.000Z`)
//六天前00:00:00 ex: current 5/30 => 5/24
export const defaultDateE = new Date(`${new Date(CURRENT_T + A_DAY * 15).toISOString().slice(0, 10)}T15:59:59.000Z`)
//當天23:59:59