import React from "react"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { addNewsTicker, updateNewsTicker, newsTickerList } from "../../../middleware/Api/backstageManagement/newsTickerApi"
import { ModalBtn, ModalText } from "../../../middleware/utilityStyle"

export default function SavePU(props) {
  const {
    history,
    showSavePU,
    handleInfoPUShow,
    targetObj,
    chosenTarget,
    setChosenTarget,
    handleSavePUClose,
    handleCleanData,
    timeGroup,
    specificTime,
    setChange,
    setCheckPeriod,
    checkDetails,
    setPanelInfo,
    setDataCopy,
    DBNewsTickerList
  } = props

  let submitTarget= JSON.parse(JSON.stringify(chosenTarget))
  let {
    ORDER_ID,
    STATE,
    TYPE,
    BEGIN_DATE,
    EXPIRE_DATE,
    WEEK,
    INTERVAL_START1,
    INTERVAL_END1,
    INTERVAL_START2,
    INTERVAL_END2,
    INTERVAL_START3,
    INTERVAL_END3,
    TEXT
  } = submitTarget
  const handleOnHide = () => {
    handleSavePUClose()
    setTimeout(() => {
      handleCleanData()
      setChosenTarget({
        ORDER_ID: '',
        STATE: '',
        TYPE: '',
        STATE: 1,
        BEGIN_DATE: '',
        EXPIRE_DATE: '',
        WEEK: '1,1,1,1,1,1,1',
        INTERVAL_START1: '',
        INTERVAL_END1: '',
        INTERVAL_START2: '',
        INTERVAL_END2: '',
        INTERVAL_START3: '',
        INTERVAL_END3: ''
      })
      for (let key in timeGroup) {
        timeGroup[key] = null
      }
      for (let key in specificTime) {
        specificTime[key] = null
      }
      setChange({specificDay: '', periodStatus: ''})
      setCheckPeriod({period1: false, period2: false, period3: false})
    }, 500)
  }

  const handleSave = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (BEGIN_DATE === '') {
        BEGIN_DATE = new Date('2001-01-01T00:00:00.000Z')
      }
      if (EXPIRE_DATE === '') {
        EXPIRE_DATE = new Date('2100-12-31T23:59:59.000Z')
      }

      if (specificTime.start1) {
        INTERVAL_START1 = specificTime.start1
      } else {
        INTERVAL_START1 = '00:00:00'
      }
      if (specificTime.end1) {
        INTERVAL_END1 = specificTime.end1
      }else {
        INTERVAL_END1 = '23:59:59'
      }
      if (specificTime.start2) {
        INTERVAL_START2 = specificTime.start2
      } else {
        INTERVAL_START2 = '00:00:00'
      }
      if (specificTime.end2) {
        INTERVAL_END2 = specificTime.end2
      } else {
        INTERVAL_END2 = '23:59:59'
      }
      if (specificTime.start3) {
        INTERVAL_START3 = specificTime.start3
      } else {
        INTERVAL_START3 = '00:00:00'
      }
      if (specificTime.end3) {
        INTERVAL_END3 = specificTime.end3
      } else {
        INTERVAL_END3 = '23:59:59'
      }
      if (!checkDetails) {
        addNewsTicker(+ORDER_ID, +STATE, +TYPE, new Date(BEGIN_DATE), new Date(EXPIRE_DATE), WEEK, INTERVAL_START1, INTERVAL_END1,
          INTERVAL_START2, INTERVAL_END2, INTERVAL_START3, INTERVAL_END3, TEXT).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          DBNewsTickerList()
          handleOnHide()
        })
      }

      if (checkDetails) {
        updateNewsTicker(+targetObj.ORDER_ID, +ORDER_ID, +STATE, +TYPE, new Date(BEGIN_DATE), new Date(EXPIRE_DATE), WEEK, INTERVAL_START1, INTERVAL_END1,
        INTERVAL_START2, INTERVAL_END2, INTERVAL_START3, INTERVAL_END3, TEXT).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          DBNewsTickerList()
          handleOnHide()
        })
      }
    })
  }

  const handleCancel = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleSavePUClose()
      handleInfoPUShow()
    })
  }
  return (
    <Modal
      show={showSavePU}
      onHide={handleOnHide}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>送出確認</Modal.Title>
      </Modal.Header>
      <ModalText>
        確定要送出資料嗎?
      </ModalText>
      <ModalBtn>
        <Button variant="info" onClick={handleSave}>送出</Button>
        <Button variant="secondary" onClick={handleCancel}>取消</Button>
      </ModalBtn>
    </Modal>
  )
}