import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import gameCategories from "../../../assets/config/gameCategories.json"
import typeInfo from "../../../assets/config/gameType.json"
import InformHint from "../../../components/popUpWindow/dzbMissionRevise/informHint"
import { getDzbMission } from "../../../middleware/Api/backstageManagement/dzbMissionSettingApi.js"
import { getAccessArr } from "../../../middleware/utils"
import {
  NavRow,
  GameDropdown1,
  GameDropdown2,
  SearchBtn,
  ReviseInput,
  RBtn
} from "./styledComponent"
import { 
  BasicTable,
  BasicRowTitle,
  TableContent,
  ReviseBtnM,
  InformBtnM,
  CancelReviseBtnM
} from "../../../middleware/utilityStyle"

export default function DZBMissionSetting() {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const history = useHistory()
  const [change, setChange] = useState({
    gameID: 0,
    gameType: 0
  })
  const handleChange = (e, key) => {
    setChange(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const [prizePanelInfo, setPrizePanelInfo] = useState([])
  const [prizeDataCopy, setPrizeDataCopy] = useState([])
  const [reviseBtn, setReviseBtn] = useState(false)
  const [twoBtns, setTwoBtns] = useState(false)

  const handleReviseInfo = (e, key) => {
    let newArr = prizePanelInfo.concat()
    newArr[+e.target.dataset.index][key] = e.target.value
    setPrizePanelInfo(newArr)
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (+change.gameID === 0 || +change.gameType === 0) {
        alert('※  請選擇類別及場次')
        return
      }

      getDzbMission(+change.gameID, +change.gameType).then(db => {
        if (db.error) {
          setPrizePanelInfo([])
          setPrizeDataCopy([])
          setReviseBtn(false)
          setTwoBtns(false)
        }
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setReviseBtn(true)
        setPrizePanelInfo(JSON.parse(JSON.stringify(db.data)))
        setPrizeDataCopy(JSON.parse(JSON.stringify(db.data)))
      })
    })
  }

  const handleReviseBtn = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 48)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      setReviseBtn(false)
      setTwoBtns(true)
    })
  }

  const [showHint, setHintShow] = useState(false)
  const handleHintClose = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setHintShow(false)
    })
  }
  const handleHintShow = () => setHintShow(true)

  const handleInform = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      let pass = true
      prizePanelInfo.forEach(ele => {
        if (isNaN(+ele.RANDOM_WEIGHT) || isNaN(+ele.AWARD_MIN)) {
          alert('※  所有欄位請填入數字')
          pass = false
          return
        }
        if (isNaN(+ele.AWARD_MAX)) {
          alert('※  所有欄位請填入數字')
          pass = false
          return
        }
      })
      if (pass) {
        handleHintShow()
      }
    })
  }

  const handleCancel = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPrizePanelInfo(prizeDataCopy)
      setReviseBtn(true)
      setTwoBtns(false)
    })
  }
  function gameCategory() {
    let gameList = []
    gameCategories.map((ele, index) => {
      gameList.push(<option value= {ele.gameID} key = {index}>{ele.gameName}</option>)
    })
    return gameList
  }
  function gameType() {
    let typeList = []
    typeInfo.map((ele, index) => {
      typeList.push(<option value = {ele.typeNum} key = {index}>{ele.typeName}</option>)
    })
    return typeList
  }
  return (
    <>
      <NavRow>
        <span>
          <GameDropdown1 value= {change.gameID} onChange= {e => {handleChange(e, 'gameID')}}>
            <option value= "0">請選擇遊戲類別</option>
            {gameCategory()}
          </GameDropdown1>
          <GameDropdown2 value= {change.gameType} onChange= {e => {handleChange(e, 'gameType')}} disabled= {+change.gameID === 0 ? true : false}>
            <option value= "0">請選擇遊戲場次</option>
            {gameType()}
          </GameDropdown2>
        </span>
        <SearchBtn style= {+change.gameType === 0 ? {background: "#f2f2f2", border: "1px solid #a6a6a6", color: "#a6a6a6"} : {}} onClick= {handleSearch}>確定</SearchBtn>
      </NavRow>
      <BasicTable className="table table-bordered" style= {prizePanelInfo.length > 0 ? {display: 'table'} : {display: 'none'}}>
        <thead>
          <BasicRowTitle>
            <td colSpan= "3"></td>
            <td colSpan= "2">獎勵數量</td>
          </BasicRowTitle>
          <BasicRowTitle>
            <th scope="col">ID</th>
            <th scope="col">名稱</th>
            <th scope="col">權重</th>
            <th scope="col">最小值</th>
            <th scope="col">最大值</th>
          </BasicRowTitle>
        </thead>
        <tbody>
          {
            reviseBtn && prizePanelInfo.length ?
              Array.isArray(prizePanelInfo) && prizePanelInfo.length > 0 ? prizePanelInfo.map((obj, index) => {
                return (
                  <TableContent key= {index} data-id= {obj.MISSION_ID}>
                    <td>{obj.MISSION_ID}</td>
                    <td>{obj.MISSION_NAME}</td>
                    <td>{+obj.RANDOM_WEIGHT ? +obj.RANDOM_WEIGHT : 0}</td>
                    <td>{+obj.AWARD_MIN ? +obj.AWARD_MIN : 0}</td>
                    <td>{+obj.AWARD_MAX ? +obj.AWARD_MAX : 0}</td>
                  </TableContent>
                )
              })
              : <tr key= "noInfo"><td colSpan= "5">查無資料</td></tr>
          
            : Array.isArray(prizePanelInfo) && prizePanelInfo.length > 0 ? prizePanelInfo.map((obj, index) => {
                return (
                  <TableContent key= {index} data-id= {obj.MISSION_ID}>
                    <td>{obj.MISSION_ID}</td>
                    <td>{obj.MISSION_NAME}</td>
                    <td><ReviseInput value= {obj.RANDOM_WEIGHT} data-index= {index} onChange= {e => {handleReviseInfo(e, 'RANDOM_WEIGHT')}}/></td>
                    <td><ReviseInput value= {obj.AWARD_MIN} data-index= {index} onChange= {e => {handleReviseInfo(e, 'AWARD_MIN')}}/></td>
                    <td><ReviseInput value= {obj.AWARD_MAX} data-index= {index} onChange= {e => {handleReviseInfo(e, 'AWARD_MAX')}}/></td>
                  </TableContent>
                )
              })
              : <tr key= "noInfo"><td colSpan= "5">查無資料</td></tr>
          }
        </tbody>
      </BasicTable>
    
      <RBtn>
        <ReviseBtnM style= {reviseBtn ? {display: 'block'} : {display: 'none'}} onClick= {handleReviseBtn}>修改</ReviseBtnM>
        <InformBtnM style= {twoBtns ? {display: 'block'} : {display: 'none'}}  onClick= {handleInform}>通知</InformBtnM>
        <CancelReviseBtnM style= {twoBtns ? {display: 'block'} : {display: 'none'}} onClick= {handleCancel}>取消修改</CancelReviseBtnM>
      </RBtn>

      <InformHint
        showHint= {showHint}
        handleHintClose= {handleHintClose}
        setReviseBtn= {setReviseBtn}
        setTwoBtns= {setTwoBtns}
        prizeDataCopy= {prizeDataCopy}
        prizePanelInfo= {prizePanelInfo}
      />
    </>
  )
}