import React from "react"
import Modal from 'react-bootstrap/Modal'
import { Button } from "react-bootstrap"
import { ModalBtn, ModalText } from "../../../middleware/utilityStyle"

export default function ResetPassword(props) {
  const {
    popData,
    password,
    showResetSecPopUp,
    handleResetSecPopUpClose
  } = props
  return (
    <>
      <Modal
        show={showResetSecPopUp}
        onHide={handleResetSecPopUpClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>重置後新密碼</Modal.Title>
        </Modal.Header>
        <ModalText>
          <div>帳號: {popData.account}</div>
          <div>新密碼: {password}</div>
        </ModalText>
        <ModalBtn>
          <Button variant="info" onClick={handleResetSecPopUpClose}>確定</Button>
        </ModalBtn>
      </Modal>
    </>
  )
}