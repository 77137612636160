import styled from 'styled-components'
import { RangePack, DayRange, BasicInput } from '../../middleware/utilityStyle'

export const FirstRow = styled.div`
  display: flex;
  width: 100%;
  @media screen and (max-width: 1760px) {
    flex-direction: column;
    & div {
      display: flex;
      justify-content: center;
    }
  }
`

export const FirstBlank = styled.div`
  @media screen and (max-width: 1760px) {
    margin-top: 10px;
  }
`

export const BlankRWD = styled.div`
  margin-left: 20px;
  @media screen and (max-width: 1760px) {
    margin-top: 10px;
    margin-left: 0px;
  }
`

export const Blank = styled(BasicInput)`
  width: 250px;
  margin-left: 20px;
`

export const SecondRow = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 1760px) {
    flex-direction: column;
    justify-content: center;
  }
`

export const RangeWrapper = styled(RangePack)`
  @media screen and (max-width: 1760px) {
    flex-direction: column;
  }
`

export const DatePickerInput = styled(BasicInput)`
  width: 250px;
  @media screen and (max-width: 1760px) {
    width: 150px;
  }
`

export const RangeGroup = styled(DayRange)`
  @media screen and (max-width: 1760px) {
    margin-top: 10px;
  }
`

export const BtnGroup = styled.div`
  @media screen and (max-width: 1760px) {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
`

export const Content = styled.th`
  width: 1080px;
`