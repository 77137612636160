import { getAuthToken } from '../../utils.js'
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 取得活動獎勵控制 PID70 ?//
export const getPrizeLimit = (activityId, beginRowNum, endRowNum) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/prizeLimit?activityId=${activityId}&beginRowNum=${beginRowNum}&endRowNum=${endRowNum}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 新增活動獎勵控制 PID71 ?//
export const addPrizeLimit = (activityId, awardType, awardNum, totalLimit, totalNum, dailyLimit, dailyNum,ITEM_ID) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/prizeLimit`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      activityId,
      awardType,
      awardNum,
      totalLimit,
      totalNum,
      dailyLimit,
      dailyNum,
      ITEM_ID
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 修改活動獎勵控制 PID72?//
export const revisePrizeLimit = (aacId, activityId, awardType, awardNum, totalLimit, totalNum, dailyLimit, dailyNum,ITEM_ID) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/prizeLimit`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      aacId,
      activityId,
      awardType,
      awardNum,
      totalLimit,
      totalNum,
      dailyLimit,
      dailyNum,
      ITEM_ID
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 刪除活動獎勵控制 PID67 ?//
export const deletePrizeLimit = aacId => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/prizeLimit/delete`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      aacId
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}