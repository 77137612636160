import React from "react"
import Modal from 'react-bootstrap/Modal'
import { Button } from "react-bootstrap"
import { getAllAcc } from "../../../middleware/Api/publicApi"
import { changeStatusAcc } from "../../../middleware/Api/accountApi"
import { ModalBtn, ModalText } from "../../../middleware/utilityStyle"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"

export default function EnableAcc(props) {
  const {
    popData,
    setPopData,
    handleEnableClose,
    setPanelInfo,
    showEnable,
    history,
    setAllAccount
  } = props
  const { userID } = popData
  const handleEnable = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (popData.ifAvailable) {
        handleEnableClose()
        alert('※  此帳號已是啟用狀態')
        return
      }
      let newPopData = Object.assign({}, popData)
      newPopData.ifAvailable = true
      setPopData(newPopData)
  
      changeStatusAcc(userID, 1).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        getAllAcc().then(db => {
          if (db.error === errorCode.NULL_DATA.code) {
            alert(`※  ${errorCode.NULL_DATA.memo}`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          setPanelInfo(db.data)
          setAllAccount(db.data)
          handleEnableClose()
        })
      })
    })
  }
  return (
    <>
      <Modal
        show={showEnable}
        onHide={handleEnableClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>啟用確認</Modal.Title>
        </Modal.Header>
        <ModalText>
          帳號: {popData.account} 狀態將啟用，真的確定要嗎？
        </ModalText>
        <ModalBtn>
          <Button variant="info" onClick={handleEnable}>要</Button>
          <Button variant="light" onClick={handleEnableClose}>先不要</Button>
        </ModalBtn>
      </Modal>
    </>
  )
}