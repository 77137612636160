import { getAuthToken } from '../../utils.js'
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 取得遊戲設定清單 PID42 ?//
export const getGameList = () => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/gameList`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 新增遊戲設定資料 PID45 ?//
export const addGameList = (gameID, gameName) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/gameList`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      gameID,
      gameName
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 修改遊戲設定 PID43 ?//
export const reviseGameList = (gameID, nGameID, nGameName) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/gameList`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      gameID,
      nGameID,
      nGameName
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//?  刪除遊戲設定 PID44 ?//
export const deleteGameList = gameID => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/gameList/delete`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      gameID
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 取得遊戲清單詳細資料 PID89 ?//
export const getGameListDetail = () => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/gameList/quickMatch`, {
    method: "GET",
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 更改遊戲快速配桌設定 PID90 ?//
export const updateQuickMatch = (gameID, openState, quickMatch, time) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/gameList/quickMatch`, {
    method: "PUT",
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      gameID,
      openState,
      quickMatch,
      time
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}