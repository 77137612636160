import { getAuthToken } from '../../utils.js'
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 取得運營報表 PID49 ?//
export const getReport = (sDate, eDate, logType) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/operationReport?sDate=${sDate}&eDate=${eDate}&logType=${logType}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}