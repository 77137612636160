import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import { Redirect } from 'react-router'
import LoadingIndicator from './loadingIndicator'
import { getAuthToken, getAccessArr } from "../utils"
import { sessionVerified } from "../../middleware/Api/publicApi.js"
import allAuthPID from "../../assets/config/authUrlSetting.json"

export default function PrivateRoute(props) {
  const [isLoading, setIsLoading] = useState(true)
  const [isAuthed, setIsAuthed] = useState(false)
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })

  const pathUrl = props.path
  let btnPID = allAuthPID[pathUrl].render

  function checkPassport() {
    let passport = false
    if (btnPID && btnPID.length) {
      for(let i = 0; i < btnPID.length; i++) {
        if (userPID.find(ele => +ele === +btnPID[i])) {
          passport = true
        } else {
          passport = false
          return
        }
      }
    }
    return passport
  }

  useEffect(() => {
    if (getAuthToken()) {
      sessionVerified().then(db => {
        if (!db.error) {
          setIsAuthed(true)
        }
        setIsLoading(false)
      })
    } else {
      setIsAuthed(false)
      setIsLoading(false)
    }
  }, [props])

  const { component: Component, ...rest } = props

  return (
    isLoading ? <LoadingIndicator /> : <Route {...rest} render={props => (
      isAuthed
      ? checkPassport() ? <Component {...props} /> : alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
      : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
  )
}