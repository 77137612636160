import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import Box from '@mui/material/Box'
import { sessionVerified } from "../../../middleware/Api/publicApi"
import errorCode from "../../../assets/config/errorCode.json"
import { AddMail } from "../../../middleware/Api/playerInfo/mailmanApi"
import { getItemConfigByItemId } from "../../../middleware/Api/operation/shopConfigApi.js"
import { getNavAcc } from "../../../middleware/utils"
import {
  AlertMessage,
  Title,
  SendTimeWrapper,
  TimeFormat,
  RadioFormat,
  ItemDivider,
  ItemRow,
  ItemNum,
  ItemTitle,
  Dropdown,
  InputFormat,
  BtnWrapper,
  SearchBtn,
  BackBtn,
  SubmitBtn,
  NextBtn,
  Blank
} from "./styledComponent"


export default function Mailman() {
  const [change, setChange] = useState({
    sendTime: '1',
    addressTo: '1',
    receiver: '',
    mailTitle: '',
    content: '',
    firstItem: '',
    firstItemNum: '',
    secondItem: '',
    secondItemNum: '',
    thirdItem: '',
    thirdItemNum: '',
    fourthItem: '',
    fourthItemNum: ''
  })
  const [changeType, setChangeType] = useState({
    firstItemType: '',
    secondItemType: '',
    thirdItemType: '',
    fourthItemType: ''
  })
  const [dateTimeDisabled, setDateTimeDisabled] = useState(true)
  const [receiverDisabled, setReceiverDisabled] = useState(true)
  const [item1Disabled, setItem1Disabled] = useState(true)
  const [item2Disabled, setItem2Disabled] = useState(true)
  const [item3Disabled, setItem3Disabled] = useState(true)
  const [item4Disabled, setItem4Disabled] = useState(true)
  const [item1ID_Disabled, setItem1ID_Disabled] = useState(true)
  const [item2ID_Disabled, setItem2ID_Disabled] = useState(true)
  const [item3ID_Disabled, setItem3ID_Disabled] = useState(true)
  const [item4ID_Disabled, setItem4ID_Disabled] = useState(true)

  const [alertShow, setAlertShow] = useState(false)
  const [searchBtnShow, setSearchBtnShow] = useState(true)
  const [nextBtnShow, setNextBtnShow] = useState(true)
  const [backBtnShow, setBackBtnShow] = useState(false)
  const [submitBtnShow, setSubmitBtnShow] = useState(false)

  const [nextStep, setNextStep] = useState(false)

  const history = useHistory()
  const [dateValue, setDateValue] = useState(null)
  const [timeValue, setTimeValue] = useState(null)

  let itemInfo = [
    {item: change.firstItem, num: change.firstItemNum},
    {item: change.secondItem, num: change.secondItemNum},
    {item: change.thirdItem, num: change.thirdItemNum},
    {item: change.fourthItem, num: change.fourthItemNum}
  ]

  const handleChange = (e, key) => {
    setChange(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const handleSendNow = (e, key) => {
    setDateTimeDisabled(true)
    setDateValue(null)
    setTimeValue(null)
    handleChange(e, key)
  }

  const handleSendSpecific  = (e, key) => {
    setDateTimeDisabled(false)
    handleChange(e, key)
  }

  const handleAddressToAll = (e, key) => {
    handleChange(e, key)
    setReceiverDisabled(true)
    setChange(prev => ({
      ...prev,
      receiver: ''
    }))
  }

  const handleAddressToSpecific = (e, key) => {
    handleChange(e, key)
    setReceiverDisabled(false)
  }

  const handleTypeChange = (e, key) => {
    let typeKey = key + 'Type'
    setChangeType(prev => ({
      ...prev,
      [typeKey]: e.target.value
    }))
    
    if (e.target.value <= 2) {
      switch (key) {
        case 'firstItem' :
          setItem1ID_Disabled(true)
          handleFirstItem(e, key)
          break;
        case 'secondItem' :
          setItem2ID_Disabled(true)
          handleSecondItem(e, key)
          break;
        case 'thirdItem' :
          setItem3ID_Disabled(true)
          handleThirdItem(e, key)
          break;
        case 'fourthItem' :
          setItem4ID_Disabled(true)
          handleFourthItem(e, key)
          break; 
      }
    } else {
      switch (key) {
        case 'firstItem' :
          setItem1ID_Disabled(false)
          setChange(prev => ({
            ...prev,
            firstItem: '',
          }))
          break;
        case 'secondItem' :
          setItem2ID_Disabled(false)
          setChange(prev => ({
            ...prev,
            secondItem: '',
          }))
          break;
        case 'thirdItem' :
          setItem3ID_Disabled(false)
          setChange(prev => ({
            ...prev,
            thirdItem: '',
          }))
          break;
        case 'fourthItem' :
          setItem4ID_Disabled(false)
          setChange(prev => ({
            ...prev,
            fourthItem: '',
          }))
          break; 
      }
    }
  }

  const handleFirstItem = (e, key) => {
    handleChange(e, key)
    if (e.target.value === '') {
      alert("In")
      setItem1Disabled(true)
      setItem2Disabled(true)
      setItem3Disabled(true)
      setItem4Disabled(true)
      setChange(prev => ({
        ...prev,
        firstItemNum: '',
        secondItem: '',
        secondItemNum: '',
        thirdItem: '',
        thirdItemNum: '',
        fourthItem: '',
        fourthItemNum: ''
      }))
      setItem2ID_Disabled(true)
      setItem3ID_Disabled(true)
      setItem4ID_Disabled(true)
      setChangeType(prev => ({
        ...prev,
        secondItemType: '', 
        thirdItemType: '', 
        fourthItemType: ''
      }))
    } else {
      setItem1Disabled(false)
    }
  }
  const handleFirstItemNum = (e, key) => {
    handleChange(e, key)
    if (e.target.value === '' || !+change.firstItemNum) {
      setItem2Disabled(true)
      setItem3Disabled(true)
      setItem4Disabled(true)
      setChange(prev => ({
        ...prev,
        secondItem: '',
        secondItemNum: '',
        thirdItem: '',
        thirdItemNum: '',
        fourthItem: '',
        fourthItemNum: ''
      }))
      setItem2ID_Disabled(true)
      setItem3ID_Disabled(true)
      setItem4ID_Disabled(true)
      setChangeType(prev => ({
        ...prev,
        secondItemType: '', 
        thirdItemType: '', 
        fourthItemType: ''
      }))
    } else {
      setItem2Disabled(false)
    }
  }

  const handleSecondItem = (e, key) => {
    handleChange(e, key)
    if (e.target.value === '') {
      setItem2Disabled(true)
      setItem3Disabled(true)
      setItem4Disabled(true)
      setChange(prev => ({
        ...prev,
        secondItemNum: '',
        thirdItem: '',
        thirdItemNum: '',
        fourthItem: '',
        fourthItemNum: ''
      }))
      setItem3ID_Disabled(true)
      setItem4ID_Disabled(true)
      setChangeType(prev => ({
        ...prev,
        thirdItemType: '', 
        fourthItemType: ''
      }))
    } else {
      setItem2Disabled(false)
    }
  }
  const handleSecondItemNum = (e, key) => {
    handleChange(e, key)
    if (e.target.value === '' || !+change.secondItemNum) {
      setItem3Disabled(true)
      setItem4Disabled(true)
      setChange(prev => ({
        ...prev,
        thirdItem: '',
        thirdItemNum: '',
        fourthItem: '',
        fourthItemNum: ''
      }))
      setItem3ID_Disabled(true)
      setItem4ID_Disabled(true)
      setChangeType(prev => ({
        ...prev,
        thirdItemType: '', 
        fourthItemType: ''
      }))
    } else {
      setItem3Disabled(false)
    }
  }

  const handleThirdItem = (e, key) => {
    handleChange(e, key)
    if (e.target.value === '') {
      setItem3Disabled(true)
      setItem4Disabled(true)
      setChange(prev => ({
        ...prev,
        thirdItemNum: '',
        fourthItem: '',
        fourthItemNum: ''
      }))
      setItem4ID_Disabled(true)
      setChangeType(prev => ({
        ...prev,
        fourthItemType: ''
      }))
    } else {
      setItem3Disabled(false)
    }
  }
  const handleThirdItemNum = (e, key) => {
    handleChange(e, key)
    if (e.target.value === '' || !+change.thirdItemNum) {
      setItem4Disabled(true)
      setChange(prev => ({
        ...prev,
        fourthItem: '',
        fourthItemNum: ''
      }))
      setItem4ID_Disabled(true)
      setChangeType(prev => ({
        ...prev,
        fourthItemType: ''
      }))
    } else {
      setItem4Disabled(false)
    }
  }

  const handleFourthItem = (e, key) => {
    handleChange(e, key)
    if (e.target.value === '') {
      setItem4Disabled(true)
      setChange(prev => ({
        ...prev,
        fourthItemNum: ''
      }))
    } else {
      setItem4Disabled(false)
    }
  }

  const handleMailSearching = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      history.push('/admin/mailSearching')
    })
    
  }

  const handleLastPage = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setAlertShow(false)
      setSearchBtnShow(true)
      setNextBtnShow(true)
      setBackBtnShow(false)
      setSubmitBtnShow(false)
      setNextStep(false)
    })
    
  }

  const handleSubmit = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
    })
    
    let itemList = [
      {item: change.firstItem === '' ? 0 : +change.firstItem, num: change.firstItemNum === '' ? 0 : +change.firstItemNum},
      {item: change.secondItem === '' ? 0 : +change.secondItem, num: change.secondItemNum === '' ? 0 : +change.secondItemNum},
      {item: change.thirdItem === '' ? 0 : +change.thirdItem, num: change.thirdItemNum === '' ? 0 : +change.thirdItemNum},
      {item: change.fourthItem === '' ? 0 : +change.fourthItem, num: change.fourthItemNum === '' ? 0 : +change.fourthItemNum}
    ]
    
    let mailDate = ''
    if (+change.sendTime === 1) {
      mailDate  = new Date()
    } else {
      let date = new Date(dateValue).toDateString()
      let time = new Date(timeValue).toTimeString()
      mailDate = new Date(`${date} ${time}`)
    }

    let idList = []
    if (+change.addressTo === 1) {
      idList = []
    } else {
      let idStr = change.receiver
      idList = idStr.split(',')
    }
    let userName = getNavAcc()

    AddMail(+change.sendTime, +change.addressTo, idList, change.mailTitle,
    change.content, itemList, mailDate, userName).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setChange({
          sendTime: '1',
          addressTo: '1',
          receiver: '',
          mailTitle: '',
          content: '',
          firstItem: '',
          firstItemNum: '',
          secondItem: '',
          secondItemNum: '',
          thirdItem: '',
          thirdItemNum: '',
          fourthItem: '',
          fourthItemNum: '',
        })
        setChangeType(prev => ({
          firstItemType: '',
          secondItemType: '', 
          thirdItemType: '', 
          fourthItemType: ''
        }))
        setNextStep(false)
        setDateTimeDisabled(true)
        setReceiverDisabled(true)
        setDateValue(null)
        setTimeValue(null)
        setNextBtnShow(true)
        setSearchBtnShow(true)
        setAlertShow(false)
        setSubmitBtnShow(false)
        setBackBtnShow(false)
    })
  }

  const handleGetItemConfigByItemId = (e) => {
    return sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      return getItemConfigByItemId(e).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if (db.data.length <= 0) {
          return false
        }
        return true
      })
    }) 
  }

  const handleNextStep = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
  
      if(+change.sendTime === 0) {
        if (!dateValue || !timeValue) {
          alert('※  請輸入日期、時間')
          return
        }
        let date = new Date(dateValue).toDateString()
        let time = new Date(timeValue).toTimeString()
        let preserveTime = new Date(`${date} ${time}`).getTime()
        if (preserveTime < new Date().getTime()) {
          alert('※  預約時間不可早於當前時間')
          return
        }
      }
  
      if (change.addressTo === 0 && !change.receiver) {
        alert('※  請填寫派送對象')
        return
      }

      if(+change.addressTo === 0) {
        let idStr = change.receiver
        let list = idStr.split(',')
        if (list.every(ele => isNaN(+ele))) {
          alert('※  欲派送的玩家ID請填入數字')
          return
        }
      }
  
      if (!change.mailTitle) {
        alert('※  請輸入信件標題')
        return
      }
  
      if (!change.content) {
        alert('※  請輸入信件內容')
        return
      }
  
      const found = itemInfo.find(ele => ele.item && !ele.num)
      const checkNum = itemInfo.find(ele => ele.item && isNaN(ele.num))
      if (found) {
        alert('※  請輸入道具數量')
        return 
      }
  
      if (checkNum) {
        alert('※  道具數量請輸入數字')
        return
      }

      let checkFlag = false
      
      for (let itemIndex = 0; itemIndex < itemInfo.length && !checkFlag; itemIndex++) {
        if (itemInfo[itemIndex].item && itemInfo[itemIndex].item > 100) {
          handleGetItemConfigByItemId(itemInfo[itemIndex].item).then(result => {
            if (!result) {
              alert(`※  所輸入之道具ID不存在！`)
              return
            } else if (itemIndex == itemInfo.length - 1) {
              setDateTimeDisabled(true)
              setAlertShow(true)
              setSearchBtnShow(false)
              setNextBtnShow(false)
              setBackBtnShow(true)
              setSubmitBtnShow(true)
              setNextStep(true)
            }
          })
        } else if (itemIndex == itemInfo.length - 1) {
          setDateTimeDisabled(true)
          setAlertShow(true)
          setSearchBtnShow(false)
          setNextBtnShow(false)
          setBackBtnShow(true)
          setSubmitBtnShow(true)
          setNextStep(true)
        }
      }      
    })
  }
  return (
    <>
      <AlertMessage id= "alert" style= {alertShow ? {display: 'block'} : {display:'none'}}>※  請確認派送對象與道具數量是否正確</AlertMessage>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <Title className="table-active">派送時間</Title>
            <td>
              <SendTimeWrapper>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sendTime"
                    value = "1"
                    onChange= {e => {handleSendNow(e, 'sendTime')}}
                    checked= {+change.sendTime === 1 ? true : false}
                    disabled= {nextStep}
                  />即時
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sendTime"
                    value= "0"
                    onChange= {e => {handleSendSpecific(e, 'sendTime')}}
                    checked= {+change.sendTime === 0 ? true : false}
                    disabled= {nextStep}
                  />預約
                </div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label= "日期"
                    disabled = {dateTimeDisabled}
                    value={dateValue}
                    onChange={(newValue) => {
                      setDateValue(newValue);
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => {
                      inputProps.placeholder = '請選擇日期'
                      return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Blank id= "datePicker" ref={inputRef} {...inputProps} />
                          <div id="dateBtn">{InputProps?.endAdornment}</div>
                        </Box>
                      )
                    }}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    label="時間"
                    disabled = {dateTimeDisabled}
                    value={timeValue}
                    onChange={(newValue) => {
                      setTimeValue(newValue);
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => {
                      inputProps.placeholder = '請選擇時間'
                      return (
                        <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                          <Blank id= "timePicker" ref={inputRef} {...inputProps} />
                          {InputProps?.endAdornment}
                        </TimeFormat>
                      )
                    }}
                  />
                </LocalizationProvider>
              </SendTimeWrapper>
            </td>
          </tr>

          <tr>
            <Title className="table-active">派送對象</Title>
            <td>
            <div>
              <RadioFormat
                className="form-check-input"
                type="radio"
                name="addressTo"
                value= "1"
                onChange= {e => {handleAddressToAll(e, 'addressTo')}}
                checked= {+change.addressTo === 1 ? true : false}
                disabled= {nextStep}
              />全服派送
            </div>
            <div>
              <RadioFormat
                className="form-check-input"
                type="radio"
                name="addressTo"
                value="0"
                onChange= {e => {handleAddressToSpecific(e, 'addressTo')}}
                checked= {+change.addressTo === 0 ? true : false}
                disabled= {nextStep}
              />指定派送
            </div>
            <div>
              <textarea
                className="form-control"
                rows="3"
                placeholder="請填寫玩家ID，派送多人請以逗點分開，例如：00123, 00124, 00125"
                value= {change.receiver}
                onChange= {e => {handleChange(e, 'receiver')}}
                disabled= {nextStep ? nextStep : receiverDisabled}
              >
              </textarea>
            </div>
            </td>
          </tr>

          <tr>
            <Title className="table-active">信件標題</Title>
            <td>
              <Blank
                placeholder= "請輸入標題"
                value= {change.mailTitle}
                onChange= {e => {handleChange(e, 'mailTitle')}}
                disabled= {nextStep}
              />
            </td>
          </tr>

          <tr>
            <Title className="table-active">信件內容</Title>
            <td>
              <div>
                <textarea
                  className="form-control"
                  rows="7"
                  placeholder="請輸入內容"
                  value= {change.content}
                  onChange= {e => {handleChange(e, 'content')}}
                  disabled= {nextStep}
                />
              </div>
            </td>
          </tr>

          <tr>
            <Title className="table-active">派送道具</Title>
            <td>
              <ItemRow>
                <span>
                  <ItemTitle>道具</ItemTitle>
                  <Dropdown data-item= "1"
                    value={changeType.firstItemType}
                    onChange= {e => {handleTypeChange(e, 'firstItem')}}
                    disabled= {nextStep}
                  >
                    <option value="">請選擇</option>
                    <option value="1">金幣</option>
                    <option value="2">鬥陣寶</option>
                    <option value="3">道具ID</option>
                  </Dropdown>
                  <ItemTitle style= {item1ID_Disabled ? {display:'none'} : {}}>道具ID</ItemTitle>
                  <InputFormat
                    placeholder= "ID"
                    name= "firstItemId" 
                    value= {change.firstItem}
                    onChange= {e => {handleFirstItem(e, 'firstItem')}}
                    disabled= {nextStep ? nextStep : item1ID_Disabled}
                    style= {item1ID_Disabled ? {display:'none'} : {}}
                  />
                </span>
                <ItemNum>
                  <ItemTitle>數量</ItemTitle>
                  <InputFormat
                    placeholder= "請輸入數量"
                    name= "firstItemNum" 
                    value= {change.firstItemNum}
                    onChange= {e => {handleFirstItemNum(e, 'firstItemNum')}}
                    disabled= {nextStep ? nextStep : item1Disabled}
                  />
                </ItemNum>
              </ItemRow>
              <ItemDivider>
                <span>
                  <ItemTitle>道具</ItemTitle>
                  <Dropdown
                    data-item= "2"
                    value={changeType.secondItemType}
                    onChange= {e => {handleTypeChange(e, 'secondItem')}}
                    disabled= {nextStep ? nextStep : !+change.firstItem || !+change.firstItemNum ? true : false}
                  >
                    <option value="">請選擇</option>
                    <option value="1">金幣</option>
                    <option value="2">鬥陣寶</option>
                    <option value="3">道具ID</option>
                  </Dropdown>
                  <ItemTitle style= {item2ID_Disabled ? {display:'none'} : {}}>道具ID</ItemTitle>
                  <InputFormat
                    placeholder= "ID"
                    name= "secondItemId" 
                    value= {change.secondItem}
                    onChange= {e => {handleSecondItem(e, 'secondItem')}}
                    disabled= {nextStep ? nextStep : item2ID_Disabled}
                    style= {item2ID_Disabled ? {display:'none'} : {}}
                  />
                </span>
                <ItemNum>
                  <ItemTitle>數量</ItemTitle>
                  <InputFormat
                    placeholder= "請輸入數量"
                    value= {change.secondItemNum}
                    onChange= {e => {handleSecondItemNum(e, 'secondItemNum')}}
                    disabled= {nextStep ? nextStep : item2Disabled}
                  />
                </ItemNum>
              </ItemDivider>
              <ItemDivider>
                <span>
                  <ItemTitle>道具</ItemTitle>
                  <Dropdown
                    data-item= "3"
                    value={changeType.thirdItemType}
                    onChange= {e => {handleTypeChange(e, 'thirdItem')}}
                    disabled= {nextStep ? nextStep : !+change.secondItem || !+change.secondItemNum ? true : false}
                  >
                    <option value="">請選擇</option>
                    <option value="1">金幣</option>
                    <option value="2">鬥陣寶</option>
                    <option value="3">道具ID</option>
                  </Dropdown>
                  <ItemTitle style= {item3ID_Disabled ? {display:'none'} : {}}>道具ID</ItemTitle>
                  <InputFormat
                    placeholder= "ID"
                    name= "thirdItemId" 
                    value= {change.thirdItem}
                    onChange= {e => {handleThirdItem(e, 'thirdItem')}}
                    disabled= {nextStep ? nextStep : item3ID_Disabled}
                    style= {item3ID_Disabled ? {display:'none'} : {}}
                  />
                </span>
                <ItemNum>
                  <ItemTitle>數量</ItemTitle>
                  <InputFormat
                    placeholder= "請輸入數量"
                    value= {change.thirdItemNum} 
                    onChange= {e => {handleThirdItemNum(e, 'thirdItemNum')}}
                    disabled= {nextStep ? nextStep : item3Disabled}
                  />
                </ItemNum>
              </ItemDivider>
              <ItemDivider>
                <span>
                  <ItemTitle>道具</ItemTitle>
                  <Dropdown
                    data-item= "4"
                    value={changeType.fourthItemType}
                    onChange= {e => {handleTypeChange(e, 'fourthItem')}}
                    disabled= {nextStep ? nextStep : !+change.thirdItem || !+change.thirdItemNum ? true : false}
                  >
                    <option value="">請選擇</option>
                    <option value="1">金幣</option>
                    <option value="2">鬥陣寶</option>
                    <option value="3">道具ID</option>
                  </Dropdown>
                  <ItemTitle style= {item4ID_Disabled ? {display:'none'} : {}}>道具ID</ItemTitle>
                  <InputFormat
                    placeholder= "ID"
                    name= "fourthItemId" 
                    value= {change.fourthItem}
                    onChange= {e => {handleFourthItem(e, 'fourthItem')}}
                    disabled= {nextStep ? nextStep : item4ID_Disabled}
                    style= {item4ID_Disabled ? {display:'none'} : {}}
                  />
                </span>
                <ItemNum>
                  <ItemTitle>數量</ItemTitle>
                  <InputFormat
                    placeholder= "請輸入數量"
                    value= {change.fourthItemNum}
                    onChange= {e => {handleChange(e, 'fourthItemNum')}}
                    disabled= {nextStep ? nextStep : item4Disabled}
                  />
                </ItemNum>
              </ItemDivider>
            </td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>

      <BtnWrapper>
        <SearchBtn id= "searchBtn" style= { searchBtnShow ? {display: 'block'} : {display:'none'}} onClick= {handleMailSearching}>信件紀錄查詢</SearchBtn>
        <BackBtn id= "backBtn" style= { backBtnShow ? {display: 'block'} : {display:'none'}} onClick= {handleLastPage}>上一頁</BackBtn>
        <SubmitBtn id= "submitBtn" style= { submitBtnShow ? {display: 'block'} : {display:'none'}} onClick= {handleSubmit}>確定派送</SubmitBtn>
        <NextBtn id= "nextBtn" style= { nextBtnShow ? {display: 'block'} : {display:'none'}} onClick= {handleNextStep}>下一步</NextBtn>
      </BtnWrapper>
    </>
  )
}