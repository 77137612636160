import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import {
  RowUnoFormat,
  RowFormat,
  PUSelect,
  ContentBlock,
  Text
} from "../../../views/operation/versionList/styledComponent"
import { ModalFormat, PUInput, ModalText, ModalBtn} from "../../../middleware/utilityStyle"
import SendPU from "./sendPU"

export default function AddAndRevise(props) {
  const history = useHistory()
  const {
    showAddAndRevise,
    handleAddAndReviseShow,
    handleAddAndReviseClose,
    addStatus,
    popData
  } = props

  const [displayPop, setDisplayPop] = useState({})

  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setDisplayPop(JSON.parse(JSON.stringify(popData)))
    })
  }, [popData])

  const [PUWData, setPUWData] = useState({
    version: '',
    platform: '1',
    switch: '1',
    url: '',
    uContent: '',
    uLevel: '1',
    lowestVS: '',
    weeklyMT: ''
  })
  
  const handlePUWData = (e, key) => {
    if (addStatus) {
      setPUWData(prev => ({
        ...prev,
        [key]: e.target.value
      }))
    } else {
      setDisplayPop(prev => ({
        ...prev,
        [key]: e.target.value
      }))
    }
  }

  const [showSendPU, setSendPUShow] = useState(false)
  const handleSendPUClose = () => setSendPUShow(false)
  const handleSendPUShow = () => setSendPUShow(true)

  const handleCheck = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      
      if (addStatus ? isNaN(+PUWData.version) : isNaN(+displayPop.version)) {
        alert('※  版本號請輸入數字')
        setPUWData(prev => ({
          ...prev,
          version: ''
        }))
        setDisplayPop(prev => ({
          ...prev,
          version: ''
        }))
        return
      }
      if (addStatus ? !PUWData.url : !displayPop.url) {
        alert('※  請輸入短網址')
        return
      }
      if (addStatus ? !PUWData.uContent : !displayPop.uContent) {
        alert('※  請輸入更新內容')
        return
      }
      if (addStatus ? !PUWData.weeklyMT : !displayPop.weeklyMT) {
        alert('※  請輸入每週更新維護時間')
        return
      }
      handleAddAndReviseClose()
      handleSendPUShow()
    })
  }

  const handleOnHide = () => {
    handleAddAndReviseClose()
    setTimeout(() => {
      setPUWData({
        version: '',
        platform: '1',
        switch: '1',
        url: '',
        uContent: '',
        uLevel: '1',
        lowestVS: '',
        weeklyMT: ''
      })
      setDisplayPop(popData)
    }, 500)
  }
  return (
    <>
      <ModalFormat
        show={showAddAndRevise}
        onHide={handleOnHide}
        backdrop="static"
        keyboard={false}
        size= "xs"
      >
        <Modal.Header closeButton>
          <Modal.Title>{addStatus ? '新增版本號' : '修改版本號'}</Modal.Title>
        </Modal.Header>
        <ModalText>
          <RowUnoFormat>
            版本號 :<PUInput placeholder= "請輸入數字" value= {addStatus ? PUWData.version : displayPop.version} onChange= {e => {handlePUWData(e, 'version')}}/>
          </RowUnoFormat>
          <RowFormat>
            平台 :
            <PUSelect value= {addStatus ? PUWData.platform : displayPop.platform} onChange= {e => {handlePUWData(e, 'platform')}}>
              <option value= "1">Android</option>
              <option value= "2">ios</option>
            </PUSelect>
          </RowFormat>
          <RowFormat>
            開放 :
            <PUSelect value= {addStatus ? PUWData.switch : displayPop.switch} onChange= {e => {handlePUWData(e, 'switch')}}>
              <option value= "1">開放</option>
              <option value= "2">關閉</option>
            </PUSelect>
          </RowFormat>
          <RowFormat>
            短網址(URL) :<PUInput value= {addStatus ? PUWData.url : displayPop.url} onChange= {e => {handlePUWData(e, 'url')}}/>
          </RowFormat>
          <ContentBlock>
            更新內容 :<Text row= "3" value= {addStatus ? PUWData.uContent : displayPop.uContent} onChange= {e => {handlePUWData(e, 'uContent')}}/>
          </ContentBlock>
          <RowFormat>
            更新等級 :
            <PUSelect value= {addStatus ? PUWData.uLevel : displayPop.uLevel} onChange= {e => {handlePUWData(e, 'uLevel')}}>
              <option value= "1">強制更新</option>
              <option value= "2">提示更新</option>
            </PUSelect>
          </RowFormat>
          <RowFormat style= {+PUWData.platform === 1 ? {display: 'flex'} : {display: 'none'}}>
            最低版本 :
            <PUSelect value= {addStatus ? PUWData.lowestVS : displayPop.lowestVS} onChange= {e => {handlePUWData(e, 'lowestVS')}}>
              <option value= "8">Android8</option>
              <option value= "9">Android9</option>
              <option value= "10">Android10</option>
            </PUSelect>
          </RowFormat>
          <RowFormat style= {+PUWData.platform === 2 ? {display: 'flex'} : {display: 'none'}}>
            最低版本 :
            <PUSelect value= {addStatus ? PUWData.lowestVS : displayPop.lowestVS} onChange= {e => {handlePUWData(e, 'lowestVS')}}>
              <option value= "8">ios8</option>
              <option value= "9">ios9</option>
              <option value= "10">ios10</option>
            </PUSelect>
          </RowFormat>
          <RowFormat>
            每週更新維護時間 :<PUInput placeholder= "請備註更新維護的時間" value= {addStatus ? PUWData.weeklyMT : displayPop.weeklyMT} onChange= {e => {handlePUWData(e, 'weeklyMT')}}/>
          </RowFormat>
        </ModalText>
        <ModalBtn>
          <Button variant="info" onClick= {handleCheck}>
            確定
          </Button>
          <Button variant="secondary" onClick={handleOnHide}>
            返回
          </Button>
        </ModalBtn>
      </ModalFormat>

      <SendPU
        showSendPU= {showSendPU}
        handleAddAndReviseShow= {handleAddAndReviseShow}
        handleSendPUClose= {handleSendPUClose}
        addStatus= {addStatus}
        setPUWData= {setPUWData}
        setDisplayPop= {setDisplayPop}
        popData= {popData}
      />
    </>
  )
}