import styled from 'styled-components'
import { BarBG } from '../../../middleware/utilityStyle'

export const InputBarBg = styled(BarBG)`
  @media screen and (max-width: 1200px){
    & {
      flex-direction: column;
      justify-content: center;
    }
  }
`

export const InputBarRWD = styled.div`
  display: flex;
`

export const Dropdown = styled.select`
  margin-left: 20px;
  border: 1px solid black;
  border-radius: 5px;
  height: 1.7rem;
  width: 200px;
`

export const BtnGroup = styled.div`
  display: flex;
  @media screen and (max-width: 1200px) {
      margin-top: 10px;
  }
`

export const TitleBg = styled.th`
  background: #ed6c02;
`