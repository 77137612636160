import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import { getChessMultiInfo } from "../../../middleware/Api/operation/chessMultiplicationControlApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import {
  InputBarBg,
  InputBarRWD,
  GameWrapper,
  Dropdown,
  BtnGroup
} from "../gameIdSetting/styledComponent.js"
import { ReviseBtn } from "../../../middleware/utilityStyle"
import { TableForm } from "../../backstageManagement/dzbMissionReport/styledComponent.js"
import { SearchButton, BasicHeadTitle } from "../../../middleware/utilityStyle.js"
import ReviseChessMultiplication from "../../../components/popUpWindow/chessMultiplicationControl/reviseChessMultiplication"
import TitleList from "../../../assets/config/chessMultiplicationTitle.json"
import { getAccessArr } from "../../../middleware/utils"

export default function chessMultiplicationControl() {
  const history = useHistory()
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })

  const [tableInfo, setTableInfo] = useState({})
  const [dataCopy, setDataCopy] = useState({})
  const [change, setChange] = useState({
    chessType: ''
  })
  const handleChange = (e, key) => {
    setChange(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }
  const [checkboxValue, setCheckboxValue] = useState([])
  const handleCheckbox = e => {
    if (e.target.checked) {
      if (checkboxValue.find(value => value === e.target.value)) return
      setCheckboxValue([...checkboxValue, +e.target.value])
    } else {
      setCheckboxValue(checkboxValue.filter(value => value !== +e.target.value))
    }
  }

  const handleTitle = () => {
    let list = []
    list.push(<BasicHeadTitle key= "0">乘倍組合</BasicHeadTitle>)
    for (let i = 0; i < TitleList.length; i++) {
      list.push(<BasicHeadTitle key= {TitleList[i].id}>{TitleList[i].name}</BasicHeadTitle>)
    }
    return list
  }

  const handleBox = () => {
    let boxList = []
    boxList.push(<BasicHeadTitle></BasicHeadTitle>)
    if (tableInfo[change.chessType]) {      
      for (let i = 0; i < TitleList.length; i++) {
        boxList.push(
          <td key= {`box${i}`}>
            <input
              type="checkbox"
              name="multiplicationList"
              value={TitleList[i].id}
              onChange={handleCheckbox}
              checked={checkboxValue.find(ele => +ele === +TitleList[i].id) || 0}
            />
          </td>
        )
      }
    } else {
      for (let i = 0; i < TitleList.length; i++) {
        boxList.push(
          <td key= {`box${i}`}>
            <input
              type="checkbox"
              name="multiplicationList"
              value={TitleList[i].id}
              onChange={handleCheckbox}
              checked={checkboxValue.find(ele => +ele === +TitleList[i].id) || 0}
              disabled
            />
          </td>
        )
      }
    }
    return boxList
  }

  const key = ['0-5', '6-10', '11-15', '16-20', '21-25', '26-30', '31-35', '36-40', '41-45', '46-50', '51-55', '56-60', '61-65', '66-70', '71-75', '76-80', '81-85', '86-90', '91-95', '96-100', '101']
  const timesKey = [128, 98, 72, 56, 50, 48, 42, 40, 35, 32, 30, 28, 24, 21, 20, 18, 16, 15, 14, 12, 10, 8, 6]
  const handleContent = () => {
    let rowList = []    
    if (tableInfo[change.chessType]) {
      function handleCol(index) {
        let list = []
        for (let i = 0; i < key.length; i++) {
          list.push(<td>{tableInfo[change.chessType][key[i]].find(ele => +ele.times === timesKey[index]).rate}</td>)
        }
        return list
      }
      for (let j = 0; j < timesKey.length; j++) {
        rowList.push(<tr><th style= {{backgroundColor: 'rgb(237, 108, 2)'}}>{timesKey[j]}</th>{handleCol(j)}</tr>)        
      }
      return rowList
    } else {
      return <tr><td colSpan= '18'>尚無資料</td></tr>
    }
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 80 )) {
        alert('※ 無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      getChessMultiInfo(change.chessType).then(db => {
        if (db.data.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.data.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.data.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        let chessData = JSON.parse(db.data.data.data)
        setTableInfo(chessData)
        setDataCopy(chessData)
      })
    })
  }
  const [showRevise, setShowRevise] = useState(false)
  const handleReviseClose = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setShowRevise(false)
    })
  }
  const [displayData, setDisplayData] = useState([])
  const handleReviseShow = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 81 )) {
        alert('※ 無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (!checkboxValue.length) {
        alert('※  請選擇欲修改的項目')
        return
      }
      setDisplayData(JSON.parse(JSON.stringify(dataCopy)))
      setShowRevise(true)
    })
  }
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <InputBarRWD>
          <GameWrapper>
            <span>級別</span>
            <Dropdown
              value={change.chessType}
              onChange= {e => {handleChange(e, "chessType")}}
              onClick= {() => {
                setTableInfo({})
                setCheckboxValue([])
              }}
            >
              <option value="">請選擇級別</option>
              <option value= "chessA">初級場</option>
              <option value= "chessB">中級場</option>
              <option value= "chessC">高級場</option>
            </Dropdown>
          </GameWrapper>
        </InputBarRWD>
        <BtnGroup>
          <SearchButton onClick={handleSearch}>確定</SearchButton>
        </BtnGroup>
      </InputBarBg>

      <TableForm  className="table table-hover table-bordered">
        <thead>
          <tr><BasicHeadTitle colSpan= "22">倍數區間權重(玩家金幣/各級底注)</BasicHeadTitle></tr>
          <tr>{handleBox()}</tr>
          <tr key= "BasicHeadTitle">{handleTitle()}</tr>
        </thead>
        <tbody>
          {handleContent()}
        </tbody>
      </TableForm>
      <ReviseBtn onClick= {handleReviseShow}>修改</ReviseBtn>

      <ReviseChessMultiplication
        history= {history}
        showRevise= {showRevise}
        handleReviseClose = {handleReviseClose}
        checkboxValue= {checkboxValue}
        timesKey= {timesKey}
        dataCopy = {dataCopy}
        setDisplayData= {setDisplayData}
        displayData= {displayData}
        chessType= {change.chessType}
        setTableInfo= {setTableInfo}
        setCheckboxValue= {setCheckboxValue}
      />
    </>
  )
}