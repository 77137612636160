import React, { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import Box from '@mui/material/Box'
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import SavePU from "./saveBtnPU"
import CancelPU from "./cancelBtnPU"
import DeletePU from "./deleteBtnPU"
import {
  Title,
  RadioWrapper,
  TimeFormat,
  PickerWrapper,
  WeekGroup,
  TimeInput,
  PeriodGroup,
  TimeRange,
  DateTime
} from "../../../views/backstageManagement/newsTicker/styledComponent"
import { ModalFormat, ModalBtn } from "../../../middleware/utilityStyle"

export default function NewsTickerInfo(props) {
  const {
    history,
    showInfoPU,
    handleInfoPUShow,
    handleInfoPUClose,
    targetObj,
    deleteBtn,
    okBtn,
    cancelBtn,
    checkDetails,
    handleCleanData,
    setPanelInfo,
    dataCopy,
    setDataCopy,
    editable,
    DBNewsTickerList
  } = props
  //chosenTarget for this page's view

  const [chosenTarget, setChosenTarget] = useState({
    ORDER_ID: '',
    TYPE: '',
    STATE: 1,
    BEGIN_DATE: '',
    EXPIRE_DATE: '',
    WEEK: '1,1,1,1,1,1,1',
    INTERVAL_START1: '',
    INTERVAL_END1: '',
    INTERVAL_START2: '',
    INTERVAL_END2: '',
    INTERVAL_START3: '',
    INTERVAL_END3: '',
    TEXT: ''
  })

  const [timeGroup, setTimeGroup] = useState({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null
  })

  const [specificTime, setSpecificTime] = useState({
    start1: null,
    end1: null,
    start2: null,
    end2: null,
    start3: null,
    end3: null
  })

  const [change, setChange] = useState({specificDay: '', periodStatus: ''})
  const [checkPeriod, setCheckPeriod] = useState({period1: false, period2: false, period3: false})
  useEffect(()=> {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChosenTarget(JSON.parse(JSON.stringify(targetObj)))
      if (checkDetails) {
        setTimeGroup({
          startDate: targetObj.BEGIN_DATE,
          startTime: targetObj.BEGIN_DATE,
          endDate: targetObj.EXPIRE_DATE,
          endTime: targetObj.EXPIRE_DATE
        })
        setSpecificTime({
          start1: targetObj.INTERVAL_START1,
          end1: targetObj.INTERVAL_END1,
          start2: targetObj.INTERVAL_START2,
          end2: targetObj.INTERVAL_END2,
          start3: targetObj.INTERVAL_START3,
          end3: targetObj.INTERVAL_END3
        })
        setChange(prev => ({
          ...prev,
          specificDay: targetObj.WEEK.split(',').every(ele => +ele === 1) ? 0 : 1,
          periodStatus: Date.parse(targetObj.INTERVAL_START1) ? 1 : 0
        }))

        if (Date.parse(targetObj.INTERVAL_START1) === 0) {
          setCheckPeriod(prev => ({
            ...prev,
            period1: false
          }))
        } else {
          setCheckPeriod(prev => ({
            ...prev,
            period1: true
          }))
        }
        if (Date.parse(targetObj.INTERVAL_START2) === 0) {
          setCheckPeriod(prev => ({
            ...prev,
            period2: false
          }))
        } else {
          setCheckPeriod(prev => ({
            ...prev,
            period2: true
          }))
        }
        if (Date.parse(targetObj.INTERVAL_START3) === 0) {
          setCheckPeriod(prev => ({
            ...prev,
            period3: false
          }))
        } else {
          setCheckPeriod(prev => ({
            ...prev,
            period3: true
          }))
        }
      } else {
        setChange({
          specificDay: 0,
          periodStatus: 0
        })
        setChosenTarget(prev => ({
          ...prev,
          WEEK: '1,1,1,1,1,1,1'
        }))
        for (let key in timeGroup) {
          timeGroup[key] = null
        }
        for (let key in checkPeriod) {
          checkPeriod[key] = false
        }
        for (let key in specificTime) {
          specificTime[key] = null
        }
      }
    })
  }, [targetObj, checkDetails])

  const  handleTarget = e => {
    let newT = Object.assign({}, chosenTarget)
    newT[e.target.name] = e.target.value
    setChosenTarget(newT)
  }

  const handleChange = (e, key) => {
    setChange(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const handleWeek = e => {
    let dayArr = chosenTarget.WEEK.split(',')
    if (+dayArr[+e.target.dataset.dayindex] === 1) {
      dayArr.splice(+e.target.dataset.dayindex, 1, '0')
      setChosenTarget(prev => ({
        ...prev,
        WEEK: dayArr.join(',')
      }))
    } else if (+dayArr[+e.target.dataset.dayindex] === 0) {
      dayArr.splice(+e.target.dataset.dayindex, 1, '1')
      setChosenTarget(prev => ({
        ...prev,
        WEEK: dayArr.join(',')
      }))
    }
  }

  const handleEveryDay = e => {
    handleChange(e, 'specificDay')
    setChosenTarget(prev => ({
      ...prev,
      WEEK: '1,1,1,1,1,1,1'
    }))
  }

  const handleSpecificDay = e => {
    setChosenTarget(prev=> ({
      ...prev,
      WEEK: '0,0,0,0,0,0,0'
    }))
  }

  const handleCheckPeriod = e => {
    if (!e.target.checked) {
      setSpecificTime(prev => ({
        ...prev,
        [`start${+e.target.dataset.periodindex}`]: null,
        [`end${+e.target.dataset.periodindex}`]: null
      }))
    }
    if (checkPeriod[`period${+e.target.dataset.periodindex}`] === true) {
      setCheckPeriod(prev => ({
        ...prev,
        [`period${+e.target.dataset.periodindex}`]: false
      }))
      e.target.value === false
    } else if (checkPeriod[`period${+e.target.dataset.periodindex}`] === false) {
      setCheckPeriod(prev => ({
        ...prev,
        [`period${+e.target.dataset.periodindex}`]: true
      }))
      e.target.value === true
    }
  }  

  const handleWholeDay = e => {
    for (let key in checkPeriod) {
      checkPeriod[key] = false
    }
    for (let key in specificTime) {
      specificTime[key] = null
    }
  }

  const handleOnHide = () => {
    handleInfoPUClose()
    setTimeout(() => {
      handleCleanData()
      setChosenTarget({
        ORDER_ID: '',
        STATE: '',
        TYPE: '',
        STATE: 1,
        BEGIN_DATE: '',
        EXPIRE_DATE: '',
        WEEK: '1,1,1,1,1,1,1',
        INTERVAL_START1: '',
        INTERVAL_END1: '',
        INTERVAL_START2: '',
        INTERVAL_END2: '',
        INTERVAL_START3: '',
        INTERVAL_END3: ''
      })
      for (let key in timeGroup) {
        timeGroup[key] = null
      }
      for (let key in specificTime) {
        specificTime[key] = null
      }
      setChange({specificDay: '', periodStatus: ''})
      setCheckPeriod({period1: false, period2: false, period3: false})
    }, 500)
  }

  const handleSave = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!checkDetails) {
        if (Array.isArray(dataCopy) && dataCopy.length) {
          if (dataCopy.find(ele => +ele.ORDER_ID === +chosenTarget.ORDER_ID)) {
            alert('※ 排序欄位與資料庫重複，請重新填入')
            return
          }
        }
      }
      if (!chosenTarget.ORDER_ID) {
        alert('※  請輸入排序欄位')
        return
      }
      if (isNaN(+chosenTarget.ORDER_ID)) {
        alert('※  排序請輸入數字')
        return
      }
      if (!chosenTarget.TYPE) {
        alert('※  請選擇跑馬燈類型')
        return
      }
      if (+chosenTarget.TYPE === 1) {
        handleInfoPUClose()
        handleSavePUShow()
      } else {
        for (let key in timeGroup) {
          if (!timeGroup[key]) {
            alert('※  開始及結束的日期、時間不可為空')
            return
          }
        }

        let saveStartTime = new Date(
          new Date(timeGroup.startDate).getFullYear(),
          new Date(timeGroup.startDate).getMonth(),
          new Date(timeGroup.startDate).getDate(),
          new Date(timeGroup.startTime).getHours(),
          new Date(timeGroup.startTime).getMinutes()
          )
  
        let saveEndTime = new Date(
          new Date(timeGroup.endDate).getFullYear(),
          new Date(timeGroup.endDate).getMonth(),
          new Date(timeGroup.endDate).getDate(),
          new Date(timeGroup.endTime).getHours(),
          new Date(timeGroup.endTime).getMinutes()
        )
        if (saveStartTime > saveEndTime) {
          alert('※  結束時間不可早於開始時間')
          return
        } else {
          setChosenTarget((prev)=> ({
            ...prev,
            BEGIN_DATE: saveStartTime,
            EXPIRE_DATE: saveEndTime
          }))
        }

        if (+change.specificDay === 1) {
          if (!chosenTarget.WEEK.split(',').find(ele => +ele === 1)) {
            alert('※  請勾選欲指定的星期')
            return
          }
        }
        if (+change.periodStatus === 1) {
          let valueArr = Object.values(checkPeriod)
          if (valueArr.every(ele => ele === false)) {
            alert('※  請勾選欲指定的時段')
            return
          }
          if (checkPeriod.period1) {
            if (!specificTime.start1 || !specificTime.end1) {
              alert('※  時段一欄位不可為空')
              return
            }
          }
          if (checkPeriod.period2) {
            if (!specificTime.start2 || !specificTime.end2) {
              alert('※  時段二欄位不可為空')
              return
            }
          }
          if (checkPeriod.period3) {
            if (!specificTime.start3 || !specificTime.end3) {
              alert('※  時段三欄位不可為空')
              return
            }
          }
        }
        if (!chosenTarget.TEXT) {
          alert('※  請輸入內容')
          return
        }
      } 
      handleInfoPUClose()
      handleSavePUShow()
    })
  }

  const [showSavePU, setShowSavePU] = useState(false)
  const handleSavePUClose = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setShowSavePU(false)
    })
  }
  const handleSavePUShow = () => setShowSavePU(true)

  const [showCancelPU, setShowCancelPU] = useState(false)
  const handleCancelPUClose = () => setShowCancelPU(false)
  const handleCancelPUShow = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleInfoPUClose()
      setShowCancelPU(true)
    })
  }

  const [showDeletePU, setShowDeletePU] = useState(false)
  const handleDeletePUClose = () => setShowDeletePU(false)
  const handleDeletePUShow = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleInfoPUClose()
      setShowDeletePU(true)
    })
  }
  return (
    <>
      <ModalFormat
        show={showInfoPU}
        onHide={handleOnHide}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{checkDetails ? '跑馬燈' : '新增跑馬燈'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
          <tbody>
            <tr>
              <Title className="table-active">排序</Title>
                <td>
                  <textarea
                    className="form-control"
                    rows="1"
                    placeholder="請輸入排序數字"
                    name= "ORDER_ID"
                    value= {chosenTarget.ORDER_ID}
                    onChange= {handleTarget}
                    disabled= {editable ? false : true}
                  />
                </td>
            </tr>

            <tr >
              <Title className="table-active">狀態</Title>
              <td>
                <RadioWrapper>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      id= "sendNow"
                      type="radio"
                      name="STATE"
                      value= "1"
                      onChange= {handleTarget}
                      disabled= {editable ? false : true}
                      checked= {+chosenTarget.STATE === 1 ? true : false}
                    />開啟
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      id= "sendSpecific"
                      type="radio"
                      name="STATE"
                      value= "0"
                      onChange= {handleTarget}
                      disabled= {editable ? false : true}
                      checked= {+chosenTarget.STATE === 0 ? true : false}
                    />關閉
                  </div>
                  
                </RadioWrapper>
              </td>
            </tr>

            <tr>
              <Title className="table-active">類型</Title>
              <td>
                <RadioWrapper>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="TYPE"
                      value= "1"
                      onChange= {handleTarget}
                      disabled= {editable ? false : true}
                      checked= {+chosenTarget.TYPE === 1 ? true : false}
                    />即時
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="TYPE"
                      value= "2"
                      onChange= {handleTarget}
                      disabled= {editable ? false : true}
                      checked= {+chosenTarget.TYPE === 2 ? true : false}
                    />時段
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="TYPE"
                      value= "3"
                      onChange= {handleTarget}
                      disabled= {editable ? false : true}
                      checked= {+chosenTarget.TYPE === 3 ? true : false}
                    />常態
                  </div>
                </RadioWrapper>
              </td>
            </tr>

            <tr style= {+chosenTarget.TYPE !== 1 ? {display:'table-row'} : {display:'none'}}>
              <Title className="table-active">開始時間</Title>
              <td>
                <PickerWrapper>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label= "日期"
                    value={timeGroup.startDate}
                    disabled= {editable ? false : true}
                    onChange={(newValue) => {
                      setTimeGroup((prev) => ({
                        ...prev,
                        startDate: newValue
                      }))
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => {
                      inputProps.placeholder = '日期'
                      return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <DateTime name= "startDatePicker" disabled= {editable ? false : true} ref={inputRef} {...inputProps} />
                          {InputProps?.endAdornment}
                        </Box>
                      )
                    }}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    label="時間"
                    value={timeGroup.startTime}
                    disabled= {editable ? false : true}
                    onChange={(newValue) => {
                      setTimeGroup((prev) => ({
                        ...prev,
                        startTime: newValue
                      }))
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => {
                      inputProps.placeholder = '時間'
                      return (
                        <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                          <DateTime name= "startTimePicker" disabled= {editable ? false : true} ref={inputRef} {...inputProps} />
                          {InputProps?.endAdornment}
                        </TimeFormat>
                      )
                    }}
                  />
                </LocalizationProvider>
                </PickerWrapper> 
              </td>
            </tr>

            <tr style= {+chosenTarget.TYPE !== 1 ? {display:'table-row'} : {display:'none'}}>
              <Title className="table-active">結束時間</Title>
              <td>
                <PickerWrapper>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label= "日期"
                      value= {timeGroup.endDate}
                      disabled= {editable ? false : true}
                      onChange={(newValue) => {
                        setTimeGroup((prev) => ({
                          ...prev,
                          endDate: newValue
                        }))
                      }}
                      renderInput={({ inputRef, inputProps, InputProps }) => {
                        inputProps.placeholder = '日期'
                        return (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <DateTime name= "endDatePicker" disabled= {editable ? false : true} ref={inputRef} {...inputProps} />
                            {InputProps?.endAdornment}
                          </Box>
                        )
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label="時間"
                      value= {timeGroup.endTime}
                      disabled= {editable ? false : true}
                      onChange={(newValue) => {
                        setTimeGroup((prev) => ({
                          ...prev,
                          endTime: newValue
                        }))
                      }}
                      renderInput={({ inputRef, inputProps, InputProps }) => {
                        inputProps.placeholder = '時間'
                        return (
                          <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                            <DateTime name= "endTimePicker" disabled= {editable ? false : true} ref={inputRef} {...inputProps} />
                            {InputProps?.endAdornment}
                          </TimeFormat>
                        )
                      }}
                    />
                  </LocalizationProvider>
                </PickerWrapper>
              </td>
            </tr>

            <tr style= {+chosenTarget.TYPE !== 1 ? {display:'table-row'} : {display:'none'}}>
              <Title className="table-active">指定星期</Title>
              <td>
                <RadioWrapper>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      value= "0"
                      onChange= {handleEveryDay}
                      disabled= {editable ? false : true}
                      checked= {+change.specificDay === 0 ? true : false}
                    />每天
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      value= "1"
                      onChange= {e => {handleChange(e, 'specificDay')}}
                      onClick= {handleSpecificDay}
                      disabled= {editable ? false : true}
                      checked= {+change.specificDay === 1 ? true : false}
                    />星期
                  </div>
                </RadioWrapper>
                <div style= {+chosenTarget.TYPE === 1 ? {display: 'none'} : {display: 'block'}}>
                  <WeekGroup>
                    <input
                      type= "checkbox"
                      data-dayindex= "0"
                      value= {chosenTarget.WEEK.split(',')[0]}
                      onChange= {handleWeek}
                      checked= {+chosenTarget.WEEK.split(',')[0] === 1 ? true: false}
                      disabled= {editable ? +change.specificDay === 0 ? true : false : true}/> 一
                  </WeekGroup>
                  <WeekGroup>
                    <input
                      type= "checkbox"
                      data-dayindex= "1"
                      value= {chosenTarget.WEEK.split(',')[1]}
                      onChange= {handleWeek}
                      checked= {+chosenTarget.WEEK.split(',')[1] === 1 ? true: false}
                      disabled= {editable ? +change.specificDay === 0 ? true : false : true}/> 二
                  </WeekGroup>
                  <WeekGroup>
                    <input
                      type= "checkbox"
                      data-dayindex= "2"
                      value= {chosenTarget.WEEK.split(',')[2]}
                      onChange= {handleWeek}
                      checked= {+chosenTarget.WEEK.split(',')[2] === 1 ? true: false}
                      disabled= {editable ? +change.specificDay === 0 ? true : false : true}/> 三
                  </WeekGroup>
                  <WeekGroup>
                    <input
                      type= "checkbox"
                      data-dayindex= "3"
                      value= {chosenTarget.WEEK.split(',')[3]}
                      onChange= {handleWeek}
                      checked= {+chosenTarget.WEEK.split(',')[3] === 1 ? true: false}
                      disabled= {editable ? +change.specificDay === 0 ? true : false : true}/> 四
                  </WeekGroup>
                  <WeekGroup>
                    <input
                      type= "checkbox"
                      data-dayindex= "4"
                      value= {chosenTarget.WEEK.split(',')[4]}
                      onChange= {handleWeek}
                      checked= {+chosenTarget.WEEK.split(',')[4] === 1 ? true: false}
                      disabled= {editable ? +change.specificDay === 0 ? true : false : true}/> 五
                  </WeekGroup>
                  <WeekGroup>
                    <input
                      type= "checkbox"
                      data-dayindex= "5"
                      value= {chosenTarget.WEEK.split(',')[5]}
                      onChange= {handleWeek}
                      checked= {+chosenTarget.WEEK.split(',')[5] === 1 ? true: false}
                      disabled= {editable ? +change.specificDay === 0 ? true : false : true}/> 六
                  </WeekGroup>
                  <WeekGroup>
                    <input
                      type= "checkbox"
                      data-dayindex= "6"
                      value= {chosenTarget.WEEK.split(',')[6]}
                      onChange= {handleWeek}
                      checked= {+chosenTarget.WEEK.split(',')[6] === 1 ? true: false}
                      disabled= {editable ? +change.specificDay === 0 ? true : false : true}/> 日
                  </WeekGroup>
                </div>
              </td>
            </tr>

            <tr style= {+chosenTarget.TYPE !== 1 ? {display:'table-row'} : {display:'none'}}>
              <Title className="table-active">指定時段</Title>
              <td>
                <RadioWrapper>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      value= "0"
                      onChange= {e => {handleChange(e, 'periodStatus')}}
                      onClick= {handleWholeDay}
                      disabled= {editable ? false : true}
                      checked= {+change.periodStatus === 0 ? true : false}
                    />整天
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      value= "1"
                      onChange= {e => {handleChange(e, 'periodStatus')}}
                      disabled= {editable ? false : true}
                      checked= {+change.periodStatus === 1 ? true : false}
                    />時段
                  </div>
                </RadioWrapper>

                <PeriodGroup style= {+chosenTarget.TYPE === 1 ? {display: 'none'} : {display: 'flex'}}>
                  <span>
                    <input
                    type= "checkbox"
                    onChange= {handleCheckPeriod}
                    data-periodindex= "1"
                    value= {checkPeriod.period1}
                    checked = {checkPeriod.period1}
                    disabled= {editable ? +change.periodStatus === 0 ? true : false : true}
                  /> 時段一
                  </span>
                  <PickerWrapper>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="時間"
                        value={Date.parse(specificTime.start1) === 0 ?  null : specificTime.start1 }
                        checked= {+change.periodStatus === 0 ? false : true}
                        disabled= {editable ? checkPeriod.period1 ? false : true : true}
                        onChange={(newValue) => {
                          setSpecificTime(prev => ({
                            ...prev,
                            start1: newValue
                          }))
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '開始時間'
                          return (
                            <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                              <TimeInput name= "1stStartTimePicker" disabled= {editable ? false : true} ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </TimeFormat>
                          )
                        }}
                      />
                    </LocalizationProvider>
                    <TimeRange>至</TimeRange>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                        label="時間"
                        value= {Date.parse(specificTime.start1) === 0 ? null : specificTime.end1}
                        disabled= {editable ? checkPeriod.period1 ? false : true : true}
                        onChange={(newValue) => {
                          setSpecificTime(prev => ({
                            ...prev,
                            end1: newValue
                          }))
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '結束時間'
                          return (
                            <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                              <TimeInput name= "1stEndTimePicker" disabled= {editable ? false : true} ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </TimeFormat>
                          )
                        }}
                      />
                    </LocalizationProvider>
                  </PickerWrapper>
                </PeriodGroup>

                <PeriodGroup style= {+chosenTarget.TYPE === 1 ? {display: 'none'} : {display: 'flex'}}>
                  <span>
                    <input
                      type= "checkbox"
                      onChange= {handleCheckPeriod}
                      data-periodindex= "2"
                      value= {checkPeriod.period2}
                      checked = {checkPeriod.period2}
                      disabled= {editable ? +change.periodStatus === 0 ? true : false : true}
                    /> 時段二
                  </span>
                  <PickerWrapper>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="時間"
                        value= {Date.parse(specificTime.start2) === 0 ? null : specificTime.start2}
                        disabled= {editable ? checkPeriod.period2 ? false : true : true}
                        onChange={(newValue) => {
                          setSpecificTime(prev => ({
                            ...prev,
                            start2: newValue
                          }))
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '開始時間'
                          return (
                            <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                              <TimeInput name= "2ndStartTimePicker" disabled= {editable ? false : true} ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </TimeFormat>
                          )
                        }}
                      />
                    </LocalizationProvider>
                    <TimeRange>至</TimeRange>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="時間"
                        value={Date.parse(specificTime.start2) === 0 ? null : specificTime.end2}
                        disabled= {editable ? checkPeriod.period2 ? false : true : true}
                        onChange={(newValue) => {
                          setSpecificTime(prev => ({
                            ...prev,
                            end2: newValue
                          }))
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '結束時間'
                          return (
                            <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                              <TimeInput name= "2ndEndTimePicker" disabled= {editable ? false : true} ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </TimeFormat>
                          )
                        }}
                      />
                    </LocalizationProvider>
                  </PickerWrapper>
                </PeriodGroup>

                <PeriodGroup style= {+chosenTarget.TYPE === 1 ? {display: 'none'} : {display: 'flex'}}>
                  <span>
                    <input
                      type= "checkbox"
                      onChange= {handleCheckPeriod}
                      data-periodindex= "3"
                      value= {checkPeriod.period3}
                      checked= {checkPeriod.period3}
                      disabled= {editable ? +change.periodStatus === 0 ? true : false : true}
                    /> 時段三
                  </span>
                  <PickerWrapper>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="時間"
                        value={Date.parse(specificTime.start3) === 0 ? null : specificTime.start3}
                        disabled= {editable ? checkPeriod.period3 ? false : true : true}
                        onChange={(newValue) => {
                          setSpecificTime(prev => ({
                            ...prev,
                            start3: newValue
                          }))
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '開始時間'
                          return (
                            <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                              <TimeInput name= "3rdStartTimePicker" disabled= {editable ? false : true} ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </TimeFormat>
                          )
                        }}
                      />
                    </LocalizationProvider>
                    <TimeRange>至</TimeRange>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="時間"
                        value= {Date.parse(specificTime.start3) === 0 ? null : specificTime.end3}
                        disabled= {editable ? checkPeriod.period3 ? false : true : true}
                        onChange= {(newValue) => {
                          setSpecificTime(prev => ({
                            ...prev,
                            end3: newValue
                          }))
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '結束時間'
                          return (
                            <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                              <TimeInput name= "3rdEndTimePicker" disabled= {editable ? false : true} ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </TimeFormat>
                          )
                        }}
                      />
                    </LocalizationProvider>
                  </PickerWrapper>
                </PeriodGroup>
              </td>
            </tr>
            
            <tr>
              <Title className="table-active">內容</Title>
              <td>
                <textarea
                  className="form-control"
                  rows="5"
                  placeholder="請輸入內容"
                  name= "TEXT"
                  value= {chosenTarget.TEXT}
                  disabled= {editable ? false : true}
                  onChange= {handleTarget}
                />
              </td>
            </tr>
          </tbody>
          <tfoot></tfoot>
          </table>
        </Modal.Body>
        
        <ModalBtn>
          <Button style= {editable ? {display: 'block'} : {display: 'none'}} variant="success" onClick= {handleSave}>儲存</Button>
          <Button style= {checkDetails ? okBtn  ? {display: 'block'} : {display: 'none'} : {display: 'none'}} variant="info" onClick={handleInfoPUClose}>確認</Button>
          <Button style= {checkDetails ? cancelBtn  ? {display: 'block'} : {display: 'none'} : {display: 'none'}} variant="secondary" onClick= {handleCancelPUShow}>取消</Button>
          <Button style= {checkDetails ? deleteBtn  ? {display: 'block'} : {display: 'none'} : {display: 'none'}} variant="danger" onClick= {handleDeletePUShow}>刪除</Button>
        </ModalBtn>
      </ModalFormat>

      <SavePU
        history= {history}
        showSavePU= {showSavePU}
        handleInfoPUShow= {handleInfoPUShow}
        targetObj= {targetObj}
        chosenTarget= {chosenTarget}
        setChosenTarget= {setChosenTarget}
        handleSavePUClose= {handleSavePUClose}
        handleCleanData= {handleCleanData}
        timeGroup= {timeGroup}
        specificTime= {specificTime}
        setChange= {setChange}
        setCheckPeriod= {setCheckPeriod}
        checkDetails= {checkDetails}
        setPanelInfo= {setPanelInfo}
        setDataCopy= {setDataCopy}
        DBNewsTickerList= {DBNewsTickerList}
      />

      <CancelPU
        history= {history}
        showCancelPU= {showCancelPU}
        handleInfoPUShow= {handleInfoPUShow}
        handleCancelPUClose= {handleCancelPUClose}
        handleCleanData= {handleCleanData}
        setChosenTarget= {setChosenTarget}
        timeGroup= {timeGroup}
        specificTime= {specificTime}
        setChange= {setChange}
        setCheckPeriod= {setCheckPeriod}
      />

      <DeletePU
        history= {history}
        showDeletePU= {showDeletePU}
        handleInfoPUShow= {handleInfoPUShow}
        handleDeletePUClose= {handleDeletePUClose}
        handleCleanData= {handleCleanData}
        chosenTarget= {chosenTarget}
        setChosenTarget= {setChosenTarget}
        timeGroup= {timeGroup}
        specificTime= {specificTime}
        setChange= {setChange}
        setCheckPeriod= {setCheckPeriod}
        setPanelInfo= {setPanelInfo}
        setDataCopy= {setDataCopy}
        DBNewsTickerList= {DBNewsTickerList}
      />
    </>
  )
}