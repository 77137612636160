import { getAuthToken } from "../../utils"
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 取得當前在線人數 PID54 ?//
export const getCurrentUsers = gameID => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/serverEnv/gameOnlineNow?gameID=${gameID}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}