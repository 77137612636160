import { getAuthToken } from '../utils.js'
import { BaseUrl } from "../../assets/config/urlForBuild"

const MemberUrl = `${BaseUrl}/member`

//? 註冊新帳號 PID7 ?//
export const addAcc = (account, userName, userType) => {
  const token = getAuthToken()
  return fetch(`${MemberUrl}/register`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      account,
      userName,
      userType
    })
  })
    .then(res => res.json())
    .catch(err => {
      console.log('err: ', err)
    })
}

//? 修改帳號類型(角色) PID2 ?//
export const reviseAcc = (userID, type) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/accSetting`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      userID,
      type
    })
  })
    .then(res => res.json())
    .catch(err => {
      console.log('err: ', err)
    })
}

//? 刪除帳號 PID3 ?//
export const deleteAcc = (userID) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/accSetting/delete`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      userID
    })
  })
    .then(res => res.json())
    .catch(err => {
      console.log('err: ', err)
    })
}

//? 修改帳號啟用禁用狀態 PID6 ?//
export const changeStatusAcc = (userID, available) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/accSetting/available`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      userID,
      available
    })
  })
    .then(res => res.json())
    .catch(err => {
      console.log('err: ', err)
    })
}

//? 重置密碼 PID5 ?//
export const resetPSWD = (account) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/accSetting/resetPwd`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      account
    })
  })
    .then(res => res.json())
    .catch(err => {
      console.log('err: ', err)
    })
}