import React, { useState, useEffect }from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import {
  PULeftTitle,
  PUSelect
} from "../../../views/eventManagement/eventUserList/styledComponent"
import { ModalFormat, ModalBtn, PUInput } from "../../../middleware/utilityStyle"
import { reviseUserEventLimit } from "../../../middleware/Api/eventManagement/eventUserListApi"

export default function AddPU(props) {
  const {
    showAddRevise,
    handleAddReviseClose,
    popData,
    setPopData,
    setPanelInfo,
    allEvent,
    userPID
  } = props
  const history = useHistory()
  const [form, setForm] = useState({
    ACTIVITY_ID: '',
    UID: null,
    MODIFY_NUM: null,
    MODIFY_TYPE: 1
  })

  const handleForm = (e, key) => {
    setForm(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }
  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (popData.ACTIVITY_ID) {
        setForm(prev => ({
          ...prev,
          ACTIVITY_ID: popData.ACTIVITY_ID,
          UID: popData.UID
        }))
      } 
      if (!popData.ACTIVITY_ID) {
        if (allEvent.length) {
          setForm(prev => ({
            ...prev,
            ACTIVITY_ID: allEvent[0].ACTIVITY_ID
          }))
        }
      }
      setForm(prev => ({
        ...prev,
        MODIFY_TYPE: 1,
        MODIFY_NUM: ''
      }))
    })
  }, [popData])

  const handleSubmit = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 69)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (isNaN(form.UID)) {
        alert('※  用戶ID欄位請輸入數字')
        setForm(prev => ({
          ...prev,
          UID: ''
        }))
        return
      }
      if (!form.MODIFY_NUM) {
        alert('※  請輸入贈送數量')
        return
      }
      if (isNaN(form.MODIFY_NUM)) {
        alert('※  贈送數量欄位請輸入數字')
        setForm(prev => ({
          ...prev,
          MODIFY_NUM: ''
        }))
        return
      }
      reviseUserEventLimit(form.ACTIVITY_ID, form.UID, form.MODIFY_NUM, form.MODIFY_TYPE).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if (db.error === errorCode.SERVER_ERROR.code) {
          alert(`※  ${errorCode.SERVER_ERROR.memo}`)
          return
        }
      })
      setPanelInfo([])
      setPopData({})
      handleAddReviseClose()
    })    
  }

  const handleOnHide = () => {
    setTimeout(() => {
      setForm(JSON.parse(JSON.stringify(popData)))
    }, 500)
    handleAddReviseClose()
  }
  return (
    <>
      <ModalFormat
        show={showAddRevise}
        onHide={handleOnHide}
        backdrop="static"
        keyboard={false}
        size= "lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>增加活動次數</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <PULeftTitle>活動名稱</PULeftTitle>
                <td>
                  <PUSelect value= {form.ACTIVITY_ID} onChange= {e => {handleForm(e, 'ACTIVITY_ID')}}>
                    {
                      Array.isArray(allEvent) && allEvent.length ?
                        allEvent.map((ele, index) => {
                          return <option value= {ele.ACTIVITY_ID} key= {index}>{ele.ACTIVITY_NAME}</option>
                        })
                      : ''
                    }
                  </PUSelect>
                </td>
              </tr>

              <tr>
                <PULeftTitle>活動途徑</PULeftTitle>
                <td>
                  <PUSelect value= {form.MODIFY_TYPE} onChange= {e => {handleForm(e, 'MODIFY_TYPE')}}>
                    <option value= "1">10積分</option>
                    <option value= "2">廣告</option>
                  </PUSelect>
                </td>
              </tr>

              <tr>
                <PULeftTitle>用戶ID</PULeftTitle>
                <td>
                  <PUInput placeholder= "請輸入用戶ID" value= {form.UID} onChange= {e => {handleForm(e, 'UID')}}/>
                </td>
              </tr>
              
              <tr>
                <PULeftTitle>贈送數量</PULeftTitle>
                <td>
                  <PUInput placeholder= "請輸入數字" value= {form.MODIFY_NUM} onChange= {e => {handleForm(e, 'MODIFY_NUM')}}/>
                </td>
              </tr>              
            </tbody>
          </table>
        </Modal.Body>
        <ModalBtn>
          <Button variant="info" onClick= {handleSubmit}>確定</Button>
        </ModalBtn>
      </ModalFormat>
    </>
  )
}