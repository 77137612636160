import React, { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { ModalFormat, ModalText, ModalBtn } from "../../../middleware/utilityStyle"

export default function SendPU(props) {
  const {
    showSendPU,
    handleAddAndReviseShow,
    handleSendPUClose,
    addStatus,
    setPUWData,
    setDisplayPop,
    popData
  } = props

  const handleBack = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleSendPUClose()
      handleAddAndReviseShow()
    })
  }

  const handleOnHide = () => {
    handleSendPUClose()
    setPUWData({
      version: '',
      platform: '1',
      switch: '1',
      url: '',
      uContent: '',
      uLevel: '1',
      lowestVS: '',
      weeklyMT: ''
    })
    setDisplayPop(popData)
  }

  const handleSubmit = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleOnHide()
    })
  }
  return (
    <>
      <ModalFormat
        show={showSendPU}
        onHide={handleOnHide}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{addStatus ? '新增' : '修改'}確認</Modal.Title>
        </Modal.Header>
        <ModalText>
          資料無誤，確定{addStatus ? '新增' : '修改'}?
        </ModalText>
        <ModalBtn>
          <Button variant="info" onClick= {handleSubmit}>
            確定
          </Button>
          <Button variant="secondary" onClick={handleBack}>返回</Button>
        </ModalBtn>
      </ModalFormat>
    </>
  )
}