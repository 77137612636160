import styled from 'styled-components'
import Col from 'react-bootstrap/Col'
import { BasicInput } from '../../../middleware/utilityStyle'

export const Dropdown = styled.select`
  margin-left: 20px;
  border: 1px solid black;
  border-radius: 5px;
  height: 28px;
  width: 5rem;
`

export const Input = styled(BasicInput)`
  width: 100%;
  height: 1.5rem;
`

export const RadioGroup = styled(Col)`
  display: flex;
  justify-content: space-between;
`