import React, { useState, useEffect } from "react"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { ModalBtn, ModalText } from "../../../middleware/utilityStyle"
import { addShopConfig, getShopConfig, reviseShopConfig, uploadItem } from "../../../middleware/Api/operation/shopConfigApi.js"

export default function HandleEndStatus(props) {
  const {
    showEnd,
    handleEndClose,
    calenderInfo,
    targetInfo,
    setTargetInfo,
    queryValue,
    setDetailCopy,
    setDetailInfo,
    clickStatus,
    imgData,
    fileData,
    setFileData,
    dateMaker,
    setLimitState
  } = props

  const handleCancel = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleEndClose()
    })
  }

  const handleCheckEnd = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (clickStatus === 'add') {
        if (fileData) {
            uploadItem(imgData).then(db => {
              if (db.error === errorCode.INCORRECT_DATA.code) {
                alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                return
              }
              if (db.error === errorCode.INCORRECT_FORMAT.code) {
                alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                return
              }
              if (db.error === errorCode.EXCEPTION.code) {
                alert(`※  ${errorCode.EXCEPTION.memo}`)
                return
              }
              setTargetInfo(prev => ({
                ...prev,
                IMG_URL: db.data.url
              }))
              let targetUrl = db.data.url
              
              let startDateWrite = targetInfo.START_DATE;
              let endDateWrite = targetInfo.END_DATE;
              if (+targetInfo.IS_PERMANENT === 0) {
                let result = dateMaker(calenderInfo.startDate, calenderInfo.startTime, calenderInfo.endDate, calenderInfo.endTime)
                setTargetInfo(prev => ({
                  ...prev,
                  START_DATE: new Date(result.SSet),
                  END_DATE: new Date(result.ESet)
                }))
                startDateWrite = new Date(result.SSet)
                endDateWrite = new Date(result.ESet)
              }
  
              addShopConfig(
                +targetInfo.CLASS_ID, 
                +targetInfo.ITEM_ID, 
                +targetInfo.ORDER_ID, 
                targetInfo.PRODUCT_TYPE,
                targetUrl, 
                targetInfo.PRODUCT_NAME,  
                +targetInfo.COST_TYPE, 
                +targetInfo.COST_NUM,
                +targetInfo.ITEM_TAG, 
                startDateWrite, 
                endDateWrite, 
                +targetInfo.ACCOUNT_LIMIT,
                +targetInfo.DAY_LIMIT,
                +targetInfo.WEEK_LIMIT,
                +targetInfo.MONTH_LIMIT,
                +targetInfo.TOTAL_LIMIT,
                targetInfo.TEXT, 
                +targetInfo.STATUS, 
                +targetInfo.IS_PERMANENT).then(db => {
                  if (db.error === errorCode.INCORRECT_DATA.code) {
                    alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                    return
                  }
                  if (db.error === errorCode.INCORRECT_FORMAT.code) {
                    alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                    return
                  }
                  if (db.error === errorCode.EXCEPTION.code) {
                    alert(`※  ${errorCode.EXCEPTION.memo}`)
                    return
                  }
                  getDetail(+queryValue.CLASS_ID)
                  handleDetailSettingClose()
                  setTimeout(() => {
                    alert('※  商品細項設定儲存成功')
                  }, 200)
                })
            })
          }
        
          if (!fileData) {
            let startDateWrite = targetInfo.START_DATE;
            let endDateWrite = targetInfo.END_DATE;
            if (+targetInfo.IS_PERMANENT === 0) {
              let result = dateMaker(calenderInfo.startDate, calenderInfo.startTime, calenderInfo.endDate, calenderInfo.endTime)
              setTargetInfo(prev => ({
                ...prev,
                START_DATE: new Date(result.SSet),
                END_DATE: new Date(result.ESet)
              }))
              startDateWrite = new Date(result.SSet)
              endDateWrite = new Date(result.ESet)
            }
  
            addShopConfig(
              +targetInfo.CLASS_ID, 
              +targetInfo.ITEM_ID, 
              +targetInfo.ORDER_ID, 
              targetInfo.PRODUCT_TYPE, 
              targetInfo.IMG_URL, 
              targetInfo.PRODUCT_NAME,  
              +targetInfo.COST_TYPE, 
              +targetInfo.COST_NUM,
              +targetInfo.ITEM_TAG, 
              startDateWrite, 
              endDateWrite, 
              +targetInfo.ACCOUNT_LIMIT,
              +targetInfo.DAY_LIMIT,
              +targetInfo.WEEK_LIMIT,
              +targetInfo.MONTH_LIMIT,
              +targetInfo.TOTAL_LIMIT,
              targetInfo.TEXT, 
              +targetInfo.STATUS, 
              +targetInfo.IS_PERMANENT).then(db => {
                if (db.error === errorCode.INCORRECT_DATA.code) {
                  alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                  return
                }
                if (db.error === errorCode.INCORRECT_FORMAT.code) {
                  alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                  return
                }
                if (db.error === errorCode.EXCEPTION.code) {
                  alert(`※  ${errorCode.EXCEPTION.memo}`)
                  return
                }
                getDetail(+queryValue.CLASS_ID)
                handleDetailSettingClose()
                setTimeout(() => {
                  alert('※  商品細項設定儲存成功')
                }, 200)
              })
          }
      }
      
      if (clickStatus === 'revise') {
        if (fileData) {
            uploadItem(imgData).then(db => {
              if (db.error === errorCode.INCORRECT_DATA.code) {
                alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                return
              }
              if (db.error === errorCode.INCORRECT_FORMAT.code) {
                alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                return
              }
              if (db.error === errorCode.EXCEPTION.code) {
                alert(`※  ${errorCode.EXCEPTION.memo}`)
                return
              }
              setTargetInfo(prev => ({
                ...prev,
                IMG_URL: db.data.url
              }))
              let targetUrl = db.data.url
  
              let startDateWrite = targetInfo.START_DATE;
              let endDateWrite = targetInfo.END_DATE;
              if (+targetInfo.IS_PERMANENT === 0) {
                let result = dateMaker(calenderInfo.startDate, calenderInfo.startTime, calenderInfo.endDate, calenderInfo.endTime)
                setTargetInfo(prev => ({
                  ...prev,
                  START_DATE: new Date(result.SSet),
                  END_DATE: new Date(result.ESet)
                }))
                startDateWrite = new Date(result.SSet)
                endDateWrite = new Date(result.ESet)
              }
  
              reviseShopConfig(
                +targetInfo.PRODUCT_ID, 
                +targetInfo.CLASS_ID, 
                +targetInfo.ITEM_ID, 
                +targetInfo.ORDER_ID, 
                targetInfo.PRODUCT_TYPE,
                targetUrl, 
                targetInfo.PRODUCT_NAME,  
                +targetInfo.COST_TYPE, 
                +targetInfo.COST_NUM,
                +targetInfo.ITEM_TAG, 
                startDateWrite, 
                endDateWrite, 
                +targetInfo.ACCOUNT_LIMIT,
                +targetInfo.DAY_LIMIT,
                +targetInfo.WEEK_LIMIT,
                +targetInfo.MONTH_LIMIT,
                +targetInfo.TOTAL_LIMIT,
                targetInfo.TEXT, 
                +targetInfo.STATUS, 
                +targetInfo.IS_PERMANENT).then(db => {
                  if (db.error === errorCode.INCORRECT_DATA.code) {
                    alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                    return
                  }
                  if (db.error === errorCode.INCORRECT_FORMAT.code) {
                    alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                    return
                  }
                  if (db.error === errorCode.EXCEPTION.code) {
                    alert(`※  ${errorCode.EXCEPTION.memo}`)
                    return
                  }
                  getDetail(+queryValue.CLASS_ID)
                  handleDetailSettingClose()
                  setTimeout(() => {
                    alert('※  商品細項設定儲存成功')
                  }, 200)
                })
            })
          }
          
          if (!fileData) {
            let startDateWrite = targetInfo.START_DATE;
            let endDateWrite = targetInfo.END_DATE;
            if (+targetInfo.IS_PERMANENT === 0) {
              let result = dateMaker(calenderInfo.startDate, calenderInfo.startTime, calenderInfo.endDate, calenderInfo.endTime)
              setTargetInfo(prev => ({
                ...prev,
                START_DATE: new Date(result.SSet),
                END_DATE: new Date(result.ESet)
              }))
              startDateWrite = new Date(result.SSet)
              endDateWrite = new Date(result.ESet)
            }
  
            reviseDetailList(
              +targetInfo.PRODUCT_ID,
              +targetInfo.CLASS_ID, 
              +targetInfo.ITEM_ID, 
              +targetInfo.ORDER_ID, 
              targetInfo.PRODUCT_TYPE, 
              targetInfo.IMG_URL, 
              targetInfo.PRODUCT_NAME,  
              +targetInfo.COST_TYPE, 
              +targetInfo.COST_NUM,
              +targetInfo.ITEM_TAG, 
              startDateWrite, 
              endDateWrite, 
              +targetInfo.ACCOUNT_LIMIT,
              +targetInfo.DAY_LIMIT,
              +targetInfo.WEEK_LIMIT,
              +targetInfo.MONTH_LIMIT,
              +targetInfo.TOTAL_LIMIT,
              targetInfo.TEXT, 
              +targetInfo.STATUS, 
              +targetInfo.IS_PERMANENT).then(db => {
                if (db.error === errorCode.INCORRECT_DATA.code) {
                  alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                  return
                }
                if (db.error === errorCode.INCORRECT_FORMAT.code) {
                  alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                  return
                }
                if (db.error === errorCode.EXCEPTION.code) {
                  alert(`※  ${errorCode.EXCEPTION.memo}`)
                  return
                }
                getDetail(+queryValue.CLASS_ID)
                handleDetailSettingClose()
                setTimeout(() => {
                  alert('※  商品細項設定儲存成功')
                }, 200)
              })
          }
      }
      setLimitState({perDay: 0, server: 0})
      setFileData(false)
      handleEndClose()
      setTimeout(() => {
        alert('※  商品細項設定儲存成功')
      }, 200)

      getShopConfig(+queryValue.CLASS_ID, -1).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        setDetailCopy(db.data)
        setDetailInfo(db.data)
      })
    })
  }
  return (
    <>
      <Modal
        show={showEnd}
        onHide={handleEndClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>結束確認</Modal.Title>
        </Modal.Header>
        <ModalText>
          結束之後無法再編輯與調整，是否進入結束狀態？
        </ModalText>
        <ModalBtn>
          <Button variant="secondary" onClick={handleCancel}>取消</Button>
          <Button variant="warning" onClick= {handleCheckEnd}>結束</Button>
        </ModalBtn>
      </Modal>
    </>
  )
}