import { getAuthToken } from '../../utils.js'
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 取得好禮兌換序號清單 PID128 ?//
export const getCoupon = () => {
    const token = getAuthToken()
    return fetch(`${BaseUrl}/coupon`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(res => res.json())
    .catch(err => console.log('err: ', err))
}

//? 新增好禮兌換序號 PID129 ?//
export const insertCoupon = (info) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/coupon`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify(info)
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 刪除好禮兌換序號 PID130 ?//
export const deleteCoupon = (couponID) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/coupon/delete`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      couponID
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 修改好禮兌換序號 PID131 ?//
export const updateCoupon = (info) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/coupon`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify(info)
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

// 確認是否為有效道具ID
export const getItemByItemID = (itemID) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/shopConfig/itemConfig?itemId=${itemID}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}