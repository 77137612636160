import styled from 'styled-components'
import { BarBG, BasicInput } from "../../middleware/utilityStyle"

export const InputBarBg = styled(BarBG)`
  @media screen and (max-width: 700px){
    & {
      flex-direction: column;
    }
    & div {
      margin-top: 10px;
    }
    & div + div {
      margin-top: 10px;
    }
  }
`

export const Input = styled(BasicInput)`
  width: 350px;
  margin-left: 20px;
  @media screen and (min-width:400px) and (max-width: 600px){
    & {
      width: 300px;
    }
  }
  @media screen and (max-width: 400px){
    & {
      width: 150px;
    }
  }
`

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: 600px) {
    & {
      flex-direction: column;
    }
  }
`

export const BtnG1 = styled.div`
  display: flex;
  @media screen and (max-width: 1020px) {
    & {
      justify-content: center;
    }
  }
`

export const BtnG2 = styled.div`
  display: flex;
  margin-left: 20px;
  @media screen and (max-width: 1020px) {
    & {
      justify-content: center;
    }
  }
  @media screen and (max-width: 600px) {
    & {
      margin-left: 0px;
    }
  }
`

export const Btn = styled.div`
  color: orange;
  text-align: center;
  cursor: pointer;
  border: 1px solid orange;
  border-radius: 30px;
  background: #e6e6e6;
  width: 100px;
  margin-top: 15px;
  transition: 0.7s all;

  :hover {
    color: white;
    font-weight: bold;
    background: orange;
  }

  & + & {
    margin-left: 20px
  }
`

export const PopupName = styled.div`
  font-size: 17px;
  color: #9A9A9A;
  border: 1px solid orange;
  border-radius: 10px;
  display: flex;
  justify-content: center;
`