import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import AnnouncementInfo from "../../../components/popUpWindow/announcement/announcementInfo"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { announcementList, submitAnnouncement } from "../../../middleware/Api/playerInfo/announcementApi"
import { TimezoneTranslator } from "../../../middleware/dateUtils"
import { getAccessArr } from "../../../middleware/utils"
import {
  AddBtn,
  InformBtn,
  BasicTable,
  BasicRowTitle,
  TableContent,
  PopDetail
} from "../../../middleware/utilityStyle"

export default function Announcement() {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const [panelInfo, setPanelInfo] = useState([])
  const [dataCopy, setDataCopy] = useState([])
  const history = useHistory()
  const [checkDetails, setCheckDetails] = useState(false)

  const [targetObj, setTargetObj] = useState({
    ORDER_ID: '',
    STATE: '',
    TYPE: '',
    BEGIN_DATE: '',
    EXPIRE_DATE: '',
    TITLE: ''
  })
  
  const [editable, setEditable] = useState(false)
  const [okBtn, setOkBtn] = useState(false)
  const [cancelBtn, setCancelBtn] = useState(false)
  const [deleteBtn, setDeleteBtn] = useState(false)

  useEffect (()=> {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (userPID.find(ele => +ele === 20)) {
        setDeleteBtn(true)
      } else {
        setDeleteBtn(false)
      }
      if (userPID.find(ele => +ele === 20) || userPID.find(ele => +ele === 21)) {
        setCancelBtn(true)
      } else {
        setCancelBtn(false)
      }
      if (!userPID.find(ele => +ele === 20) && !userPID.find(ele => +ele === 21)) {
        setOkBtn(true)
      } else {
        setOkBtn(false)
      }
      announcementList().then(db => {
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if (Array.isArray(db.data) && db.data.length) {
          db.data.sort(function order(a, b) {
            return a.ORDER_ID - b.ORDER_ID
          })
          db.data.sort(function order(a, b) {
            return b.TYPE - a.TYPE
          })
          setPanelInfo(db.data)
          setDataCopy(db.data)
        }
      })
    })
  }, [])

  const [showInfoPU, setInfoPUShow] = useState(false)
  const handleInfoPUClose = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setInfoPUShow(false)
    })
  }
  const handleInfoPUShow = () => setInfoPUShow(true)

  const handleCleanData = () => {
    setTargetObj({
      ORDER_ID: '',
      STATE: '',
      TYPE: '',
      BEGIN_DATE: '',
      EXPIRE_DATE: '',
      TITLE: ''
    })
  }
  
  const handleAddBtn = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 19)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        setEditable(false)
        return
      } else {
        setEditable(true)
      }
      if (dataCopy.length >= 20) {
        alert('※  清單資料已滿，無法新增')
        return
      }
      handleCleanData()
      setCheckDetails(false)
      handleInfoPUShow()
    })
    
  }

  const handleInfo = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 18)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      let checkTarget = dataCopy.find(ele => +ele.ORDER_ID === +e.target.dataset.id)
      setTargetObj(checkTarget)
      if (userPID.find(ele => +ele === 21)) {
        setEditable(true)
      } else {
        setEditable(false)
      }
      handleInfoPUShow()
      setCheckDetails(true)
    })
  }

  const handleSubmit = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 22)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      submitAnnouncement().then(db => {
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
      })
    })
  }
  return (
    <>
    <div>
      <AddBtn variant="success" onClick= {handleAddBtn}>新增</AddBtn>
      <InformBtn variant="warning" onClick= {handleSubmit}>通知</InformBtn>
    </div>
      <BasicTable className="table table-hover table-bordered">
        <thead>
          <BasicRowTitle>
            <th scope="col">排序</th>
            <th scope="col">狀態</th>
            <th scope="col">類型</th>
            <th scope="col">開始時間</th>
            <th scope="col">結束時間</th>
            <th scope="col">頁籤標題</th>
            <th scope="col">詳細</th>
          </BasicRowTitle>
        </thead>
        <tbody>
          {
            Array.isArray(panelInfo) && panelInfo.length ?
              panelInfo.map((EachData, index) => {
                return (
                  <TableContent key= {index}>
                    <td>{+EachData.ORDER_ID}</td>
                    <td>{+EachData.STATE === 1 ? '開啟' : '關閉'}</td>
                    <td>{+EachData.TYPE === 2 ? '即時' : '常態'}</td>
                    <td>{TimezoneTranslator(EachData.BEGIN_DATE)}</td>
                    <td>{TimezoneTranslator(EachData.EXPIRE_DATE)}</td>
                    <td>{EachData.TITLE}</td>
                    <PopDetail onClick= {handleInfo} data-id= {EachData.ORDER_ID}>查看</PopDetail>
                  </TableContent>
                )
              })
              : <tr key= "noInfo"><td colSpan= "7">尚無資料</td></tr>
          }
        </tbody>
        <tfoot></tfoot>
      </BasicTable>

      <AnnouncementInfo
        history= {history}
        showInfoPU= {showInfoPU}
        handleInfoPUShow= {handleInfoPUShow}
        handleInfoPUClose= {handleInfoPUClose}
        targetObj= {targetObj}
        setTargetObj= {setTargetObj}
        editable= {editable}
        okBtn= {okBtn}
        cancelBtn= {cancelBtn}
        deleteBtn= {deleteBtn}
        checkDetails= {checkDetails}
        handleCleanData= {handleCleanData}
        setPanelInfo= {setPanelInfo}
        dataCopy= {dataCopy}
        setDataCopy= {setDataCopy}
      />
    </>
  )
}