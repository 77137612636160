import styled from 'styled-components'
import Box from '@mui/material/Box'
import { BarBG, DayRange, RangePack, BasicInput, BasicTable, TableContent, BtnPosition } from "../../../middleware/utilityStyle"

export const CheckStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`
export const BTN = styled(BtnPosition)`
  width: 500px;
`

export const IdInputBar = styled.div`
  display: flex;
  @media screen and (max-width: 1530px) {
    justify-content: center;
  }
`

export const IdInput = styled(BasicInput)`
  margin-left: 20px;
  width: 250px;
  @media screen and (max-width: 715px) {
    width: 125px;
  }
`

export const TableForm = styled(BasicTable)`
  table-layout: fixed;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  
  & thead tr th {
    background: #ed6c02;
    z-index: 2;
    position:sticky;
    top: 0;
  }

  & th {
    width: 200px;
    height: 50px;
  }

  & td:first-child, th:first-child {
    position: sticky;
    left: 0;
  }

  & th:first-child{
    z-index: 3;
  }
`

export const STitle = styled.th`
  background: #fee3cd;
`

export const NoData = styled.tr`
  font-size: 20px;
  font-weight: 600 !important;
  text-align: center;
`

export const InputBarBg = styled(BarBG)`
  @media screen and (max-width: 1750px){
    & {
      flex-direction: column;
      justify-content: center;
    }
  }
`
export const NavLeft = styled.div`
  display: flex;
  @media screen and (max-width: 1530px) {
    flex-direction: column;
    justify-content: center;
  }
`

export const RangeWrapper = styled(RangePack)`
  margin-Left: 20px;
  @media screen and (max-width: 1530px) {
    flex-direction: column;
    margin-Left: 0px;
    margin-top: 10px;
  }
`

export const DatePickerInput = styled(BasicInput)`
  width: 250px;
  @media screen and (max-width: 715px) {
    width: 125px;
  }
`

export const RangeGroup = styled(DayRange)`
  @media screen and (max-width: 1530px) {
    margin-top: 10px;
  }
`

export const BtnGroup = styled.div`
  @media screen and (max-width: 1650px) {
    margin-top: 10px;
  }
`
export const Dropdown = styled.select`
  border: 1px solid black;
  border-radius: 5px;
  height: 2.4rem;
  width: 100px;
`
export const Group = styled.div`
  @media screen and (max-width: 1750px){
    & {
      margin-top: 10px;
    }
  }
`

export const NoInfo = styled.td`
  background: transparent !important;
  width: 100%;
`

export const PULeftTitle = styled.th`
  text-align: center;
`

export const DateTimeWrapper = styled.div`
  display: flex;
  height: 40px;
`

export const PUSelect = styled.select`
  border: 1px solid black;
  border-radius: 5px;
  height: 28px;
  width: 120px;
`

export const TimeFormat = styled(Box)`
  margin-left: 20px;
  @media screen and (max-width: 810px) {
    margin-left: 0px;
    margin-top: 10px;
  }
`

export const TimeInput = styled(BasicInput)`
  width: 150px;
  height: 25px;
  & + div {
    margin-left: 0px;
  }
`