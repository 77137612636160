import React from "react"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { getAllAcc } from "../../../middleware/Api/publicApi"
import { addAcc } from "../../../middleware/Api/accountApi"
import { BigSquareTitle, BigSquareInput, ModalBtn } from '../../../middleware/utilityStyle'
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"

export default function AddAccountFirstPU(props) {
  const {
    change,
    setChange,
    setShowAdd,
    setDefaultPassword,
    handleAddSecPUShow,
    setPanelInfo,
    characterIdList,
    showAdd,
    handleAddClose,
    handleChange,
    history,
    setAllAccount
  } = props

  const handleAddAccount = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!change.addAccValue || !change.addNameValue) {
        alert('請輸入空白處⊙ w ⊙')
        return
      }
      addAcc(change.addAccValue, change.addNameValue, +change.addAuthValue).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.ACCOUNT_DUPLICATE.code) {
          alert(`※  ${errorCode.ACCOUNT_DUPLICATE.memo}`)
          let newChange = Object.assign({}, change)
          newChange.addAccValue = ''
          setChange(newChange)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setShowAdd(false)
        setChange(prev => ({
          ...prev,
          addAccValue: '',
          addNameValue: ''
        }))
        setDefaultPassword(db.data.password)
        handleAddSecPUShow()
  
        getAllAcc().then(db => {
          if (db.error === errorCode.NULL_DATA.code) {
            alert(`※  ${errorCode.NULL_DATA.memo}`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          setPanelInfo(db.data)
          setAllAccount(db.data)
        })
      })
    })
  }
  return (
    <Modal
      show={showAdd}
      onHide={handleAddClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>新增帳號</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <BigSquareTitle>帳號</BigSquareTitle>
              <td>
                <BigSquareInput placeholder="請輸入帳號"  value={change.addAccValue} onChange={e => {handleChange(e, 'addAccValue')}} />
              </td>
            </tr>
            <tr>
              <BigSquareTitle>姓名</BigSquareTitle>
              <td>
                <BigSquareInput placeholder="請輸入姓名" name="addNameValue" value={change.addNameValue} onChange={e => {handleChange(e, 'addNameValue')}} />
              </td>
            </tr>
            <tr>
              <BigSquareTitle>角色</BigSquareTitle>
              <td>
                <select className="btn dropdown-toggle" value={change.addAuthValue} onChange={e => {handleChange(e, 'addAuthValue')}}>
                  {
                    Array.isArray(characterIdList) && characterIdList.length > 0 ?
                      characterIdList.map((obj, index) => {
                        return (
                          <option className="dropdown-item" key= {index} value={obj.characterID}>{obj.characterName}</option>
                        )
                      })
                      : ''
                  }
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <ModalBtn>
        <Button variant="info" type="submit" onClick={handleAddAccount}>確定</Button>
        <Button variant="light" onClick={handleAddClose}>返回</Button>
      </ModalBtn>
    </Modal>
  )
}