import React from "react"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { ModalFormat, ModalText, ModalBtn } from "../../../middleware/utilityStyle"

export default function DeletePU(props) {
  const {
    showDelete,
    handleDeleteClose
  } = props

  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleDeleteClose()
    })
  }
  return (
    <>
      <ModalFormat
        show={showDelete}
        onHide={handleDeleteClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>刪除確認</Modal.Title>
        </Modal.Header>
        <ModalText>
          資料無誤，確定刪除此筆資料?
        </ModalText>
        <ModalBtn>
          <Button variant="info" onClick= {handleDelete}>
            確定
          </Button>
          <Button variant="secondary" onClick={handleDeleteClose}>返回</Button>
        </ModalBtn>
      </ModalFormat>
    </>
  )
}