import React, { useState, useEffect }from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { PULeftTitle, PUSelect } from "../../../views/eventManagement/prizeWeight/styledComponent"
import { ModalFormat, ModalBtn, PUInput } from "../../../middleware/utilityStyle"
import ConfirmPU from "./ConfirmPU"

export default function AddPU(props) {
  const {
    showAddRevise,
    handleAddReviseShow,
    handleAddReviseClose,
    addStatus,
    popData,
    prizeType,
    setPanelInfo,
    allEvent,
    setPopData
  } = props
  const history = useHistory()
  const [form, setForm] = useState({
    AAC_ID: '',
    ACTIVITY_ID: '', //預測抓資料
    LEVEL_NUM: 1,
    AWARD_TYPE: 1,
    STATE: 2,
    AWARD_NUM: '',
    RANDOM_WEIGHT: '',
    ITEM_ID:0,
  })
  const handleForm = (e, key) => {
    setForm(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if(!addStatus) {
        setForm(JSON.parse(JSON.stringify(popData)))
      }
      if(addStatus) {
        setForm({
          AAC_ID: '',
          ACTIVITY_ID: allEvent[0].ACTIVITY_ID,
          LEVEL_NUM: 1,
          AWARD_TYPE: 1,
          STATE: 2,
          AWARD_NUM: '',
          RANDOM_WEIGHT: '',
          ITEM_ID:0,
        })
      }
    })
  }, [popData, addStatus])

  const handleSubmit = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      const {AAC_ID, ACTIVITY_ID, LEVEL_NUM, AWARD_TYPE, STATE, AWARD_NUM, RANDOM_WEIGHT} = form
      if (isNaN(LEVEL_NUM)) {
        alert('※  概率級別欄位請輸入數字')
        return
      }
      if (isNaN(AWARD_NUM)) {
        alert('※  獎勵數量欄位請輸入數字')
        return
      }
      if (isNaN(RANDOM_WEIGHT)) {
        alert('※  隨機權重欄位請輸入數字')
        return
      }
      handleAddReviseClose()
      handleConfirmShow()
    })
    
  }

  const handleBack = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleAddReviseClose()
      setTimeout(() => {
        if (addStatus) {
          setForm({
            AAC_ID: '',
            ACTIVITY_ID: '', //預測抓資料
            LEVEL_NUM: 1,
            AWARD_TYPE: 1,
            STATE: 2,
            AWARD_NUM: '',
            RANDOM_WEIGHT: '',
            ITEM_ID:0,
          })
        }
        if (!addStatus) {
          setForm(JSON.parse(JSON.stringify(popData)))
        }
      }, 500)
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setForm({
        AAC_ID: '',
        ACTIVITY_ID: '',
        LEVEL_NUM: 1,
        AWARD_TYPE: 1,
        STATE: 2,
        AWARD_NUM: '',
        RANDOM_WEIGHT: ''
      })
    })
  }

  const [showConfirm, setConfirmShow] = useState(false)
  const handleConfirmClose = () => setConfirmShow(false)
  const handleConfirmShow = () => setConfirmShow(true)

  function typeOptions() {
    let list = []
    for (let i  = 0; i < prizeType.length; i++) {
      list.push(<option value= {prizeType[i].num}>{prizeType[i].name}</option>)
    }
    return list
  }
  return (
    <>
      <ModalFormat
        show={showAddRevise}
        onHide={handleAddReviseClose}
        backdrop="static"
        keyboard={false}
        size= "lg"
      >
        <Modal.Header>
          <Modal.Title>{addStatus ? '新增' : '修改'}活動獎品配置</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <PULeftTitle>活動名稱</PULeftTitle>
                <td>
                  <PUSelect value= {form.ACTIVITY_ID} onChange= {e => {handleForm(e, 'ACTIVITY_ID')}}>
                  {
                    Array.isArray(allEvent) && allEvent.length ?
                      allEvent.map((ele, index) => {
                        return <option value= {ele.ACTIVITY_ID} key= {index}>{ele.ACTIVITY_NAME}</option>
                      })
                    : ''
                  }
                  </PUSelect>
                </td>
              </tr>
              <tr>
                <PULeftTitle>概率級別</PULeftTitle>
                <td>
                  <PUInput placeholder= "請輸入數字" value= {form.LEVEL_NUM} onChange= {e => {handleForm(e, 'LEVEL_NUM')}}/>
                </td>
              </tr>

              <tr>
                <PULeftTitle>獎勵類型</PULeftTitle>
                <td>
                  <PUSelect value= {form.AWARD_TYPE} onChange= {e => {handleForm(e, 'AWARD_TYPE')}}>
                    {typeOptions()}
                    
                  </PUSelect>
                  <span>道具ID <PUInput type = "number" min={0} value= {form.ITEM_ID} onChange= {e => {handleForm(e, 'ITEM_ID')}} disabled ={+form.AWARD_TYPE !== 6?"disabled":""} ></PUInput></span>
                </td>
              </tr>

              <tr>
                <PULeftTitle>獎勵數量</PULeftTitle>
                <td>
                  <PUInput placeholder= "請輸入數字" value= {form.AWARD_NUM} onChange= {e => {handleForm(e, 'AWARD_NUM')}}/>
                </td>
              </tr>

              <tr>
                <PULeftTitle>隨機權重</PULeftTitle>
                <td>
                  <PUInput placeholder= "請輸入數字" value= {form.RANDOM_WEIGHT} onChange= {e => {handleForm(e, 'RANDOM_WEIGHT')}}/>
                </td>
              </tr>

              <tr>
                <PULeftTitle>狀態</PULeftTitle>
                <td>
                  <PUSelect value= {form.STATE} onChange= {e => {handleForm(e, 'STATE')}}>
                    <option value= "2">有效</option>
                    <option value= "1">無效</option>
                  </PUSelect>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <ModalBtn>
          <Button variant="info" onClick= {handleSubmit}>確定</Button>
          <Button variant= "warning" onClick= {handleReset}>重置</Button>
          <Button variant="secondary" onClick= {handleBack}>返回</Button>
        </ModalBtn>
      </ModalFormat>
      
      <ConfirmPU
        showConfirm= {showConfirm}
        handleAddReviseShow= {handleAddReviseShow}
        handleConfirmClose= {handleConfirmClose}
        addStatus= {addStatus}
        form= {form}
        setPanelInfo= {setPanelInfo}
        setPopData= {setPopData}
      />
    </>
  )
}