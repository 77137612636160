import Modal from 'react-bootstrap/Modal'
import React from "react"
import { getAllAuth } from "../../../middleware/Api/publicApi"
import { addAuth } from "../../../middleware/Api/authApi"
import { Button } from "react-bootstrap"
import { BigSquareTitle, BigSquareInput, ModalBtn } from '../../../middleware/utilityStyle'
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"

export default function AddAuth(props) {
  const {
    showAdd,
    handleAddClose,
    change,
    setChange,
    handleChange,
    setPanelInfo,
    history,
    setAuthInfo
  } = props
  const handleAddAuth = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!change.ID || !change.name || !change.description) {
        alert('請輸入空白處⊙ w ⊙')
        return
      } else {
        if (isNaN(+change.ID)) {
          alert('※  權限值請輸入數字')
          let newChange = Object.assign({}, change)
          newChange.ID = ''
          setChange(newChange)
          return
        }
        addAuth(+change.ID, change.name, change.description).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(errorCode.INCORRECT_DATA.memo, '，相關問題請洽詢工程部')
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          if (db.error === errorCode.REPEAT_DATA.code) {
            alert('※  權限ID不可重複')
            let newChange = Object.assign({}, change)
            newChange.ID = ''
            setChange(newChange)
            return
          } else {
            handleAddClose()
            setChange({ ID: '', name: '', description: '' })
            getAllAuth().then(db => {
              if (db.error === errorCode.NULL_DATA.code) {
                alert(`※  ${errorCode.NULL_DATA.memo}`)
                return
              }
              if (db.error === errorCode.EXCEPTION.code) {
                alert(`※  ${errorCode.EXCEPTION.memo}`)
                return
              } else {
                setPanelInfo(db.data)
                setAuthInfo(db.data)
              }
            })
          }
        })
      }
    })
  }
  return (
    <Modal
      show= {showAdd}
      onHide= {handleAddClose}
      backdrop= "static"
      keyboard= {false}
    >
      <Modal.Header closeButton>
        <Modal.Title>新增權限</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className= "table table-bordered">
          <tbody>
            <tr>
            <BigSquareTitle>權限ID</BigSquareTitle>
            <td>
              <BigSquareInput placeholder= "請輸入系統權限ID" name= "ID" value= {change.ID} onChange= {handleChange} />
            </td>
            </tr>
            <tr>
              <BigSquareTitle>權限名稱</BigSquareTitle>
              <td>
                <BigSquareInput placeholder= "請輸入權限名稱" name= "name" value= {change.name} onChange= {handleChange} />
              </td>
            </tr>
            <tr>
              <BigSquareTitle>權限描述</BigSquareTitle>
              <td>
                <BigSquareInput placeholder= "請輸入權限描述" name= "description" value= {change.description} onChange= {handleChange} />
              </td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <ModalBtn>
        <Button variant= "info" type= "submit" onClick= {handleAddAuth}>確定</Button>
        <Button variant= "light" onClick= {handleAddClose}>返回</Button>
      </ModalBtn>
    </Modal>
  )
}