import styled from 'styled-components'
import Box from '@mui/material/Box'
import { BarBG, RangePack, DayRange, BasicInput, BtnPosition } from '../../../middleware/utilityStyle'

export const InputBarBg = styled(BarBG)`
  @media screen and (max-width: 1650px){
    flex-direction: column;
  }
`

export const NavLeft = styled.div`
  display: flex;
  @media screen and (max-width: 1530px) {
    flex-direction: column;
    justify-content: center;
  }
`

export const IdInputBar = styled.div`
  display: flex;
  @media screen and (max-width: 1530px) {
    justify-content: center;
  }
`

export const IdInput = styled(BasicInput)`
  margin-left: 20px;
  width: 250px;
  @media screen and (max-width: 715px) {
    width: 125px;
  }
`

export const RangeWrapper = styled(RangePack)`
  margin-Left: 20px;
  @media screen and (max-width: 1530px) {
    flex-direction: column;
    margin-Left: 0px;
    margin-top: 10px;
  }
`

export const DatePickerInput = styled(BasicInput)`
  width: 250px;
  @media screen and (max-width: 715px) {
    width: 125px;
  }
`

export const RangeGroup = styled(DayRange)`
  @media screen and (max-width: 1530px) {
    margin-top: 10px;
  }
`

export const BtnGroup = styled.div`
  @media screen and (max-width: 1650px) {
    margin-top: 10px;
  }
`

export const PULeftTitle = styled.th`
  text-align: center;
`

export const DateTimeWrapper = styled.div`
  display: flex;
`

export const PUSelect = styled.select`
  border: 1px solid black;
  border-radius: 5px;
  height: 28px;
  width: 240px;
`

export const TimeFormat = styled(Box)`
  margin-left: 20px;
  @media screen and (max-width: 810px) {
    margin-left: 0px;
    margin-top: 10px;
  }
`

export const BTN = styled(BtnPosition)`
  width: 500px;
`

export const WeekGroup = styled.span`
  margin-right: 10px;
`

export const InputBarRWD = styled.div`
  display: flex;
  @media screen and (max-width: 1350px){
    & {
      flex-direction: column;
    }
  }
`

export const Dropdown = styled.select`
  margin-left: 20px;
  border: 1px solid black;
  border-radius: 5px;
  height: 1.7rem;
  width: 200px;
`

export const PopFrame = styled.label`
  height: 100px;
  width: 100px;
  position: relative;
  border: 1px solid;
  margin-left: 10px;
`

export const Avatar = styled.img`
  max-height: 100%;  
  max-width: 100%; 
  width: auto;
  height: auto;
  position: absolute;  
  top: 0;  
  bottom: 0;  
  left: 0;  
  right: 0;  
  margin: auto;
`

export const NoImg = styled.div`
  display:flex;
  text-align: center;
`
export const LabelBtn = styled.label`
  color: #002080;
  width: 20%;
  height: 100%;
  text-align: center;
  margin-left: 10px;
  border: 1px solid;
  : hover {
    color: #0000cc;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
  }
`
export const Group = styled.div`
  @media screen and (max-width: 1750px){
    & {
      margin-top: 10px;
    }
  }
`

export const TimeInput = styled(BasicInput)`
  width: 150px;
  height: 25px;
  & + div {
    margin-left: 0px;
  }
`
