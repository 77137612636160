import { getAuthToken } from "../../utils"
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 取得鬥陣任務報表 PID52 ?//
export const getDzbMissionReport = (sDate, eDate, gameID, gameType) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/operationReport/inGameMission?sDate=${sDate}&eDate=${eDate}&gameID=${gameID}&gameType=${gameType}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}