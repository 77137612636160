import { getAuthToken } from '../utils.js'
import { BaseUrl } from "../../assets/config/urlForBuild"

const MemberUrl = `${BaseUrl}/member`

//? 登入 ?//
export const login = (account, password) => {
  return fetch(`${MemberUrl}/login`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      account,
      password
    })
  })
    .then(res => {
      let contentType = res.headers.get("content-type")
      if(contentType && contentType.includes("application/json")) {
        return res.json()
      } else {
        return {msg: '伺服器連線失敗', data: res}
      }
    })
    .catch(err => {
      console.log('err: ', err)
    })
}

//? 驗證 ?//
export const sessionVerified = () => {
  const token = getAuthToken()
  return fetch(`${MemberUrl}/login`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
    .then(res => res.json())
    .catch(err => {
      console.log('err: ', err)
    })
}

//? 修改密碼 ?//
export const firstLogin = (account, oldPassword, newPassword) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/accSetting/pwd`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      account, oldPassword, newPassword
    })
  })
    .then(res => res.json())
    .catch(err => {
      console.log('err: ', err)
    })
}


//? 取得所有帳號資訊 PID1 ?//
export const getAllAcc = () => {  
  const token = getAuthToken()
  return fetch(`${BaseUrl}/accSetting`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
    .then(res => res.json())
    .catch(err => {
      console.log('err: ', err)
    })
}

//? 取得所有角色資訊 ?//
export const getAllCharacter = () => {  //PID8
  const token = getAuthToken()
  return fetch(`${BaseUrl}/character`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
    .then(res => res.json())
    .catch(err => {
      console.log('err: ', err)
    })
}

//? 取得全部權限 ?//
export const getAllAuth = () => {  //PID14
  const token = getAuthToken()
  return fetch(`${BaseUrl}/access`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
    .then(res => res.json())
    .catch(err => {
      console.log('err: ', err)
    })
}

//? 取得活動名稱清單 PID74?//
export const getAllEventList = () => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/eventUserList/all`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
  .catch(err => {console.log('err: ', err)})
}