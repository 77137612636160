import styled from 'styled-components'
import Box from '@mui/material/Box'
import Modal from "react-bootstrap/Modal"
import { BarBG, RangePack, DayRange, BasicInput } from '../../../middleware/utilityStyle'

export const InputBarBg = styled(BarBG)`
  @media screen and (max-width: 1650px) {
    display: flex;
    flex-direction: column;
  }
`

export const InputBarRWD = styled.div`
  display: flex;
  @media screen and (max-width: 1500px) {
    flex-direction: column;
  }
` 

export const RangeWrapper = styled(RangePack)`
  @media screen and (max-width: 1500px) {
    flex-direction: column;
    justify-content: center;
  }
`

export const DatePickerInput = styled(BasicInput)`
  width: 195px;
`

export const RangeGroup = styled(DayRange)`
  @media screen and (max-width: 1500px) {
    margin-top: 10px;
  }
`

export const InputBar = styled.div`
  margin-left: 20px;
  @media screen and (max-width: 1500px) {
    margin-left: 0px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
`

export const Input = styled(BasicInput)`
  width: 350px;
  margin-left: 20px;
`

export const BtnGroup = styled.div`
  @media screen and (max-width: 1650px) {
    margin-top: 10px;
  }
`

export const Title = styled.td`
  text-align: center;
`

export const SendTimeWrapper = styled.div`
  display: flex;
`

export const TimeFormat = styled(Box)`
  margin-left: 20px;
`

export const RadioFormat = styled.input`
  margin-right: 0.3125rem;
  margin-left: 0px;
  position: relative;
`

export const ItemDivider = styled.div`
  margin-top: 0.5rem;
`

export const ItemTitle = styled.span`
  margin-right: 1rem;
  margin-left: 1rem;
`

export const Dropdown = styled.select`
  border: 1px solid black;
  border-radius: 5px;
  height: 1.7rem;
  width: 7rem;
  text-align: center;
`

export const InputFormat = styled(BasicInput)`
  height: 1.7rem;
  width: 7rem;
  text-align: center;
`

export const Blank = styled(BasicInput)`
  height: 28px;
  width: 200px;
`

export const Btn = styled(Modal.Footer)`
  display: flex;
  justify-content: center !important;
`