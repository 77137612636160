import React, { useState, useEffect , createContext }from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal" 
import { Button } from "react-bootstrap"
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import errorCode from "../../../assets/config/errorCode.json"

import { ModalFormat, ModalBtn } from "../../../middleware/utilityStyle"
import {
  PULeftTitle,
  PUSelect,
  PUInput
} from  "../../../views/backstageManagement/adSettingForFinalCalculate/styledComponent"
import {sessionVerified} from "../../../middleware/Api/publicApi"
import {insertData} from "../../../middleware/Api/backstageManagement/adSetting"


import gameOptions from "../../../assets/config/gameCategories.json"
import roomTypes from "../../../assets/config/gameType.json"
import occurrenceCondiction from "./occurrenceCondiction.json"
import occurrenceMode from "./occurrenceMode.json"
import prizeMode from "./prizeMode"
import prizeContent from "./prizeContent"

import {data, rateSettingData, noticeMessageData} from "./Contexts"
import SelectComponent from "./selectComponent"
import RateSettingComponent from "./rateSettingComponent"
import NoticeMessageComponent from "./noticeMessageComponent"

import { setAuthToken, getNavAcc, getAccessArr, setAccessArr, setCharacterType } from "../../../middleware/utils"


const formTemplate = {
  chosenGame:"",
  chosenGameType:"",
  occurrenceCondiction:"",
  collectMod:"",
  totalTimes:"",
  dailyOccurrence: "",
  prizeMode:"",
}

const switchTemplate = {switch:"0"} 


export default function AddAdSettingComponent(props){
  const history = useHistory()
  const {
    showAddRevise,
    handleSwitch,
    searchAct
  }=props

  const accessArr = getAccessArr()
  let userPID = []
    accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })

  const [form, setForm] = useState({...formTemplate})
  const [rollSetting, setRollSetting] = useState({})
  const [rateSetting, setRateSetting] = useState({...switchTemplate})
  const [noticeMessage, setNoticeMessage] = useState({...switchTemplate})
  const [settingSwitch, setSettingSwitch] = useState("0")

  const handleCancel = () =>{
    setForm({...formTemplate})
    setRollSetting({})
    setRateSetting({...switchTemplate})
    setNoticeMessage({...switchTemplate})
    handleSwitch(false)
  }

  const handleConfirm = () =>{
    sessionVerified().then(db =>{
      if (db.error===errorCode.CODE_NOT_MATCH.code){
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code){
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      if (!userPID.find(ele => +ele === 92)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      const formKeys = Object.keys(formTemplate)
      for (let key of formKeys){
        if (form[key]==="") {
          alert("訊息請提供完整")
          return
        }
      }
      const rollSettingKeys = Object.keys(rollSetting)
      let directType = false
      let rollType = false

      for (let key of rollSettingKeys){

        let target = rollSetting[key]
        if (Object.prototype.toString.call(target) ==="[object Object]"){
          directType = +key<1?true:false
          rollType =+key>0?true:false
          const {itemID, prizeType, quantity,weight} = target
          if (+prizeType > 0 ){

            if (form.prizeMode === "1"){
              if (quantity === "" || weight=== ""){
                alert(`拉霸滾輪設定 ${key===0?key+1:key}項, 需要提供權重和數量`)
                return
              }
              if (+prizeType ===3 && itemID ===''){
                alert(`拉霸滾輪設定 ${key}項, 需要提供道具ID`)
                return
              }
            }
            if (form.prizeMode === "0"){
              if (quantity === ""){
                alert(`拉霸滾輪設定 ${+key===0?+key+1:+key}項, 需要提供權重和數量`)
                return
              }
              if (+prizeType ===3 && itemID ===''){
                alert(`拉霸滾輪設定 ${1}項, 需要提供道具ID`)
                return
              }
            }
            // if (quantity === "" || weight=== ""){
            //   if(form.prizeMode === "1"){
            //     alert(`拉霸滾輪設定 ${key===0?key+1:key}項, 需要提供權重和數量`)
            //     return
            //   }
            // }
            // if (+prizeType ===3 && itemID ===''){
            //   alert(`拉霸滾輪設定 ${key}項, 需要提供道具ID`)
            //   return
            // }
          }
        }
      }
      if(!rollType&&form.prizeMode === "1"){
        alert("拉霸滾輪設定 需要至少一組設定")
        return 
      }
      if(!directType&&form.prizeMode === "0"){
        alert("獎勵內容 需要提供設定")
        return 
      }
      if(rateSetting.switch ==="1"){
        let keys = Object.keys(rateSetting).filter(key =>key !== "switch")
        if (keys.length === 0 ) {
          alert("至少需要一組倍率設定")
          return
        }
        for(let key of keys){
          let {rate,weight} = rateSetting[key]
          if (rate !=='' && weight ===''){
            alert(`拉霸倍率設定 第${key}項,須提供權重`)
            return
          }
          if (rate ==='' && weight !==''){
            alert(`拉霸倍率設定 第${key}項,須提供倍率`)
            return
          }
        }
      }
      if (noticeMessage.switch ==="1"){
        let keys = Object.keys(noticeMessage).filter(key =>key !== "switch")
        if (keys.length ===0|| noticeMessage["message"]==='') return alert("提示視窗: 請提供視窗訊息")
      }
      // let messageOnly = Object.keys(noticeMessage).filter(key =>key !== "switch").reduce((a,v)=>{
      //   return {
      //     ...a,
      //     [v]:noticeMessage .message
      //   }

      // },{})
      
      // let noSwitchRateSetting = Object.keys(rateSetting).filter(key => key !== "switch")
      // let newRateSetting = noSwitchRateSetting.reduce((i,k)=>{
      //   return {
      //     ...i,
      //     [k]:rateSetting[k]
      //   }
      // },{})
      let configDetail = {
        rollSetting,
        rateSetting
        ,
      }
      let result = {
        GAME_ID:+form.chosenGame, // 遊戲代號
        GAME_TYPE:+form.chosenGameType , //遊戲等級
        TRIGGER_TYPE:+form.occurrenceCondiction, // 廣告觸發類型
        TRIGGER_MODEL:+form.collectMod, // 觸發模式
        GOAL_TIMES:+form.totalTimes, // 共 X 場
        TRIGGER_LIMT_TIMES:+form.dailyOccurrence,//每日觸發次數限制
        AWARD_TYPE:+form.prizeMode, //獎勵方式
        AWARD_CONFIG:JSON.stringify(configDetail), // 獎勵設定
        HINT_STATE:+noticeMessage.switch, //提示開關
        HINT_CONFIG:JSON.stringify(noticeMessage), // 提示內容
        STATE:+settingSwitch //設定開關
      }
      insertData(result).then(data =>{
        alert("設定新增完成")

      }).catch(error =>{ console.log(error)})

      searchAct()

      handleCancel()
      
    })
  }
  const handleForm = (e, key) => {
    if (key ==="prizeMode"){
      setRollSetting({})
    }
    setForm(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }
  const handleRollSetting = (key, data)=>{
    let vs = Object.values(data)[0] ==="0"

    if(!rollSetting[key]){
      setRollSetting({
        ...rollSetting,
        [key]:data
      })
    } else if (vs) {
      let ks = Object.keys(rollSetting).filter(k => k !== key)
      let result  = ks.reduce((o,k)=>{
        return {
          ...o,
          [k]:rollSetting(k)
        }
      },{})
      setRollSetting(result)
    }else {
      let {prizeType,itemID,quantity,weight} = data
      if (prizeType==="0"&& itemID===""&& quantity===""&&weight===""){
        let oldKeys = Object.keys(rollSetting).filter(k=> k!==`${key}`)
        let result = oldKeys.reduce((a,i)=>{
          return{
            ...a,
            [i]:rollSetting[i]
          }
        },{})
        setRollSetting(result)
      }else {
        setRollSetting(prev => ({
          ...prev,
          [key]: data
        }))
      }
    }
  }
  const handleSetRateSetting = (key,data) =>{
    if (key ==="switch"&& data=== "0"){
      let keys = Object.keys(rateSetting).filter(key =>key !== "switch")
      let copy ={}
      if (key ==="switch"){
        copy[key] = data
      }
      setRateSetting(copy)
    }else if (data.rate==="" && data.weight==="") {
      let keys = Object.keys(rateSetting).filter(k=> +k!== key)
      let result = {}
      keys.forEach(k=>{
        result[k] = rateSetting[k]
      })
      setRateSetting(result)

    }else{
      setRateSetting(prev => ({
        ...prev,
        [key]: data
      }))
    }
    
  }



  const selectionLayOut = (target,formKey ) =>{
    return (
      <PUSelect
      id = {formKey}
      value={form[formKey]}
      onChange={e =>{handleForm(e,formKey)}}
      >
      <option value="" >請選擇</option>
      {target.map((set,index) =>{
                const [first, second] = Object.keys(set)
        if (formKey ==="chosenGameType"){
          return (
            <>
              <option value={set[first]-1 } >{set[second]}</option>
            </>
          )
        } else {
          return (
            <>
              <option value={set[first]} >{set[second]}</option>
            </>
          )
        }


      })}
      </PUSelect>
      
    )
  }
  const handleMode = () =>{
    let count = 10
    if(form.prizeMode ==="") return
    if (form.prizeMode ==="0") return (
      <>
      <tr>
        <PULeftTitle>獎勵內容</PULeftTitle>
        <td>
          <Container fluid>
            <data.Provider value ={[rollSetting, handleRollSetting]}>
              <SelectComponent dataNumber={0}/>
            </data.Provider>
          </Container>
        </td>
      </tr>
      </>
    )
    if (form.prizeMode ==="1") return (
      <>
        <tr>
          <PULeftTitle>拉霸滾輪設定</PULeftTitle>
          <td>
            <Container fluid>
              <data.Provider value ={[rollSetting, handleRollSetting]}>
              <SelectComponent dataNumber={1}/>
              <SelectComponent dataNumber={2}/>
              <SelectComponent dataNumber={3}/>
              <SelectComponent dataNumber={4}/>
              <SelectComponent dataNumber={5}/>
              <SelectComponent dataNumber={6}/>
              <SelectComponent dataNumber={7}/>
              <SelectComponent dataNumber={8}/>
              <SelectComponent dataNumber={9}/>
              <SelectComponent dataNumber={10}/>
              </data.Provider>  
            </Container>

          </td>
        </tr>
        <tr>
        <PULeftTitle>拉霸倍率設定</PULeftTitle>
        <td>
        <div>
          <select
            value={rateSetting.switch}
            onChange={e=>{handleSetRateSetting("switch", e.target.value)}}
          >
            <option value={"0"} selected>關閉</option>
            <option value={"1"}>開啟</option>

          </select>
        </div>
        <Container fluid>
        <rateSettingData.Provider value = {[rateSetting,handleSetRateSetting]} >
          <RateSettingComponent dataNumber ={1} />
          <RateSettingComponent dataNumber ={2} />
          <RateSettingComponent dataNumber ={3} />
          <RateSettingComponent dataNumber ={4} />
          <RateSettingComponent dataNumber ={5} />
          <RateSettingComponent dataNumber ={6} />
          <RateSettingComponent dataNumber ={7} />
          <RateSettingComponent dataNumber ={8} />
          <RateSettingComponent dataNumber ={9} />
          <RateSettingComponent dataNumber ={10} />
        </rateSettingData.Provider>
        </Container>
        </td>
        </tr>
      </>
    )
  }
  return ( 
  <>
      <Modal
        size="lg"
        show={showAddRevise}
        onHide={handleSwitch}
        aria-labelledby="example-modal-sizes-title-lg"
        // backdrop="static"
        // keyboard={false}
        // size= "lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>新增廣告管理</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <PULeftTitle>ID</PULeftTitle>
                <td><Container>{"系統自動產生"}</Container></td>
              </tr>
              <tr>
                <PULeftTitle>遊戲</PULeftTitle>
                <td>
                  <Container fluid>
                    <Row xs={"auto"}>
                      <Col>{selectionLayOut(gameOptions,"chosenGame")}</Col>
                      <Col>
                        <label for ="chosenGameType">場次:</label>
                        {selectionLayOut(roomTypes,"chosenGameType")}
                      </Col>
                    </Row>
                  </Container>
                </td>
              </tr>
              <tr>
                <PULeftTitle>觸發條件</PULeftTitle>
                <td>
                  <Container fluid>
                    <Row>
                      <Col>{selectionLayOut(occurrenceCondiction,"occurrenceCondiction" )}</Col>
                      <Col>
                        <label>
                          模式:
                          {selectionLayOut(occurrenceMode,"collectMod")}
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                      <label>
                        共<PUInput type="number" onChange={e =>{handleForm(e,"totalTimes")}} min={0}></PUInput>場
                      </label>
                      </Col>
                    </Row>
                  </Container>
                </td>
              </tr>
              <tr>
                <PULeftTitle>每日觸發上限</PULeftTitle>
                <td>
                <Container>
                <PUInput 
                type="number"
                placeholder="0 代表無限制"
                value={form.dailyOccurrence}
                onChange={e=>{handleForm(e,"dailyOccurrence")}}
                min={0}
                ></PUInput>
                </Container>
                </td>
              </tr>
              <tr>
              <PULeftTitle>獎勵模式</PULeftTitle>
              <td>
                <Container>
                {selectionLayOut(prizeMode,"prizeMode")}
                </Container>
              </td>
              </tr>
              {handleMode()}
              <tr>
              <PULeftTitle>提示視窗</PULeftTitle>
              <td>
                <Container>
                  <noticeMessageData.Provider value={[noticeMessage, setNoticeMessage]}>
                    <NoticeMessageComponent/>
                  </noticeMessageData.Provider>
                </Container>
              </td>
              </tr>
              <tr>
                <PULeftTitle>開關</PULeftTitle>
                <td>
                  <Container>
                  <PUSelect
                  value={settingSwitch}
                  onChange={e=>{setSettingSwitch(e.target.value)}}
                  >
                    <option value={"0"} selected>關閉</option>
                    <option value={"1"} >開啟</option>
                  </PUSelect>
                  </Container>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <ModalBtn>
          <Button variant="warning" onClick={() =>{handleCancel()}} >取消</Button>
          <Button variant="info" onClick={() =>{handleConfirm()}} >確定</Button>
          
        </ModalBtn>
      </Modal>
  </>
  )
}