import React, { useEffect, useState } from "react"
import { useHistory, useLocation} from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import DetailSetting from "../../../components/popUpWindow/itemDetails/detailSetting"
import { detailList } from "../../../middleware/Api/operation/itemSettingApi"
import { TimezoneTranslator } from "../../../middleware/dateUtils"
import { getAccessArr } from "../../../middleware/utils"
import {
  Dropdown,
  CategoryName,
  Frame,
  Avatar,
  LastPage
} from "./styledComponent"
import {
  BarBG,
  SearchButton,
  ResetButton,
  BasicTable,
  TableContent,
  BasicRowTitle,
  AddItemInfoBtn,
  PopDetail
} from "../../../middleware/utilityStyle.js"

export default function ItemDetails() {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const history = useHistory()
  const [change, setChange] = useState({
    navSwitch: 2
  })
  const [detailInfo, setDetailInfo] = useState([])
  const [detailCopy, setDetailCopy] = useState([])
  const [queryValue, setQueryValue]= useState({CLASS_ID: 0, CLASS_NAME: ''})
  const [targetObj, setTargetObj] = useState({
    ITEM_ID: 0,
    ORDER_ID: '',
    ITEM_IMG: '',
    ITEM_NAME: '',
    PRICE_TYPE: 2,
    ITEM_PRICE: '',
    ITEM_TAG: 0,
    BEGIN_DATE: '',
    EXPIRE_DATE: '',
    PERSON_DAILY_LIMIT: '',
    TOTAL_NUM: '',
    NOW_NUM: '',
    OPEN_EXCHANGE: 0,
    ITEM_CLASS: '',
    ITEM_DETAIL: '',
    IS_PERMANENT: 1
  })
  const [clickStatus, setClickStatus] = useState('')
  const urlQueryString = useLocation().search

  const tagName = new Map([
    [0, '無'],
    [1, '限量'],
    [2, '限時'],
    [3, '活動'],
    [4, '熱門'],
    [5, '推薦'],
  ])
  const transTag = new Map(tagName)

  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      let firstArr = urlQueryString.match(/(\w+)=([\u4e00-\u9fa5a-zA-Z0-9]+(([-]+|[\u4e00-\u9fa5a-zA-Z0-9]+|[_]+|))+)/gm)
      let secondArr = firstArr.map(str => str.match(/([\u4e00-\u9fa5a-zA-Z0-9]+(([-]+|[\u4e00-\u9fa5a-zA-Z0-9]+|[_]+|))+)/gm))
      let idObj = {}
      secondArr.forEach(arr => {idObj[arr[0]] = arr[1]})
      setQueryValue(idObj)
    })
  }, [useLocation().search])

  const handleChange = (e, key) => {
    setChange((prev) => ({
      ...prev,
      [key]: e
    }))
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      detailList(+queryValue.CLASS_ID).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setDetailCopy(db.data)
        if (+change.navSwitch === 1) {
          let searchList = db.data.filter(info => +info.OPEN_EXCHANGE === 1)
          setDetailInfo(searchList)
        } else if (+change.navSwitch === 0) {
          let searchArr = db.data.filter(info => +info.OPEN_EXCHANGE === 0) 
            setDetailInfo(searchArr)
        } else {
          setDetailInfo(db.data)
        }
      })
      setChange({navSwitch: 2})
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({navSwitch: 2})
    })
  }

  const handleLastPage = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
        }
      history.push("/admin/itemSetting")
    })
  }

  const [calender, setCalender] = useState({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null
  })

  const [showDetailSetting, setDetailSettingShow] = useState(false)
  const handleDetailSettingClose = () => {
    for (let key in calender) {
      calender[key] = null
    }
    setSwitchDelete(false)
    setSwitchSave(false)
    setDetailSettingShow(false)
  }
  const handleDetailSettingShow = () => {
    if (clickStatus === 'add') {
      for (let key in calender) {
        calender[key] = null
      }
    }
    setDetailSettingShow(true)
  }

  const [switchDelete, setSwitchDelete] = useState(false)
  const [switchSave, setSwitchSave] = useState(false)

  const handelAddItemDetail = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 36)){
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      setClickStatus('add')
      setSwitchSave(true)
      setTargetObj({
        ITEM_ID: 0,
        ORDER_ID: '',
        ITEM_IMG: '',
        ITEM_NAME: '',
        PRICE_TYPE: 2,
        ITEM_PRICE: '',
        ITEM_TAG: 0,
        BEGIN_DATE: '',
        EXPIRE_DATE: '',
        PERSON_DAILY_LIMIT: '',
        TOTAL_NUM: '',
        NOW_NUM: '',
        OPEN_EXCHANGE: 0,
        ITEM_CLASS: '',
        ITEM_DETAIL: '',
        IS_PERMANENT: 1
      })
      let newCalender = (JSON.parse(JSON.stringify(calender)))
      for (let key in newCalender) {
        newCalender[key] = null
      }
      setCalender(newCalender)
      handleDetailSettingShow()
    })
  }

  const handleReviseDetail = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setClickStatus('revise')
      setSwitchDelete(true)
      setSwitchSave(true)
      let checkTarget = detailCopy.find(ele => +ele.ITEM_ID === +e.target.dataset.id)
      setTargetObj(JSON.parse(JSON.stringify(checkTarget)))
      handleDetailSettingShow()
    })
  }

  const handleCheck = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setClickStatus('check')
      let checkTarget = detailCopy.find(ele => +ele.ITEM_ID === +e.target.dataset.id)
      setTargetObj(JSON.parse(JSON.stringify(checkTarget)))
      handleDetailSettingShow()
    })
  }

  const paymentNum = new Map([
    [2, '鬥陣寶'],
  ])
  const transPayment = new Map(paymentNum)
  return (
    <>
      <BarBG className="navbar navbar-light bg-light">
        <div>
          <span>開關</span>
          <Dropdown id= "item1" value={change.navSwitch} onChange= {e => {handleChange(e.target.value, "navSwitch")}}>
            <option value="2">全部</option>
            <option value="1">開啟</option>
            <option value="0">關閉</option>
          </Dropdown>
        </div>
        <div>
          <SearchButton onClick={handleSearch}>搜索</SearchButton>
          <ResetButton onClick={handleReset}>重置</ResetButton>
        </div>
      </BarBG>

      <AddItemInfoBtn variant="success" onClick= {handelAddItemDetail}>新增商品細項</AddItemInfoBtn>
      <CategoryName>分類商品設定 : {queryValue.CLASS_NAME}</CategoryName>

      <BasicTable className="table table-bordered">
        <thead>
          <BasicRowTitle>
            <th scope="col">商品ID</th>
            <th scope="col">排序</th>
            <th scope="col">商品圖</th>
            <th scope="col">名稱</th>
            <th scope="col">付費</th>
            <th scope="col">價格</th>
            <th scope="col">標籤</th>
            <th scope="col">兌換時間(開始)</th>
            <th scope="col">兌換時間(結束)</th>
            <th scope="col">單日限購</th>
            <th scope="col">全服限量</th>
            <th scope="col">剩餘數量</th>
            <th scope="col">開關</th>
            <th scope="col">修改</th>
            <th scope="col">檢閱</th>
          </BasicRowTitle>
        </thead>
        <tbody>
          {
            Array.isArray(detailInfo) && detailInfo.length ? 
              detailInfo.map(ele=> {
                return (
                  <TableContent key= {ele.ITEM_ID}>
                    <td>{ele.ITEM_ID}</td>
                    <td>{ele.ORDER_ID}</td>
                    <Frame>
                      <Avatar src={ele.ITEM_IMG} className="rounded" alt={ele.ITEM_IMG ? '商品圖片讀取失敗' : ''} />
                      <div style= {ele.ITEM_IMG ? {display: 'none'} : {display: 'block'}}>無商品圖片</div>
                    </Frame>
                    <td>{ele.ITEM_NAME}</td>
                    <td>{transPayment.get(+ele.PRICE_TYPE)}</td>
                    <td>{ele.ITEM_PRICE}</td>
                    <td>{+!ele.ITEM_TAG ? '---' : transTag.get(+ele.ITEM_TAG)}</td>
                    <td>{ele.BEGIN_DATE === '2001-01-01T00:00:00.000Z' ? '---' : TimezoneTranslator(ele.BEGIN_DATE)}</td>
                    <td>{ele.EXPIRE_DATE === '2100-12-31T23:59:59.000Z' ? '---' : TimezoneTranslator(ele.EXPIRE_DATE)}</td>
                    <td>{!ele.PERSON_DAILY_LIMIT ? '---': ele.PERSON_DAILY_LIMIT}</td>
                    <td>{!ele.TOTAL_NUM ? '---' : ele.TOTAL_NUM}</td>
                    <td>{!ele.TOTAL_NUM ? '---' : ele.TOTAL_NUM-ele.NOW_NUM}</td>
                    <td>{+ele.OPEN_EXCHANGE === 1 ? '開啟' : '關閉'}</td>
                    <PopDetail onClick= {handleReviseDetail} data-id= {ele.ITEM_ID}>修改</PopDetail>
                    <PopDetail onClick= {handleCheck} data-id= {ele.ITEM_ID}>查看</PopDetail>
                  </TableContent>
                )
              })
            :<tr key= "noInfo"><td colSpan= "15">尚無資料</td></tr> 
          }
        </tbody>
      </BasicTable>

      <LastPage onClick= {handleLastPage}>上一頁</LastPage>

      <DetailSetting
        showDetailSetting= {showDetailSetting}
        handleDetailSettingClose= {handleDetailSettingClose}
        targetObj= {targetObj}
        clickStatus= {clickStatus}
        setClickStatus= {setClickStatus}
        calender= {calender}
        switchDelete= {switchDelete}
        switchSave= {switchSave}
        queryValue= {queryValue}
        detailCopy= {detailCopy}
        setDetailCopy= {setDetailCopy}
        setDetailInfo= {setDetailInfo}
      />
    </>
  )
}