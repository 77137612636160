import { getAuthToken } from "../../utils.js"
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? add new setting 
export const  insertData = ({
  GAME_ID,
  GAME_TYPE,
  TRIGGER_TYPE,
  TRIGGER_MODEL,
  GOAL_TIMES,
  TRIGGER_LIMT_TIMES,
  AWARD_TYPE,
  AWARD_CONFIG,
  HINT_STATE,
  HINT_CONFIG,
  STATE
}) =>{
  const token = getAuthToken()
  return fetch(
    `${BaseUrl}/adSetting/insertData`,
    {
      method:"POST",
      headers:{
        'Authorization': `Bearer ${token}`,
        'content-type': 'application/json'
      },
      body:JSON.stringify({
        GAME_ID,
        GAME_TYPE,
        TRIGGER_TYPE,
        TRIGGER_MODEL,
        GOAL_TIMES,
        TRIGGER_LIMT_TIMES,
        AWARD_TYPE,
        AWARD_CONFIG,
        HINT_STATE,
        HINT_CONFIG,
        STATE
      })
    }
  )
  
}

//? add new setting 
export const getAdSetting = () =>{
  const token = getAuthToken()
  return fetch(
    `${BaseUrl}/adSetting/getSetting`,
    {
      method:"GET",
      headers:{
        'Authorization': `Bearer ${token}`,
        'content-type': 'application/json'
      }
    }
  ).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? delete setting 
export const deleteSetting = ({GRAC_ID}) =>{
  const token = getAuthToken()
  return fetch(
    `${BaseUrl}/adSetting/deleteSetting`,
    {
      method:"POST",
      headers:{
        'Authorization': `Bearer ${token}`,
        'content-type': 'application/json'
      },
      body:JSON.stringify({
        GRAC_ID,
      })
    }
  ).then(res => res.json())
  .catch(err => console.log('err: ', err))

}

//? updating setting
export const updateSetting = ({
  GRAC_ID,
  GAME_ID,
  GAME_TYPE,
  TRIGGER_TYPE,
  TRIGGER_MODEL,
  GOAL_TIMES,
  TRIGGER_LIMT_TIMES,
  AWARD_TYPE,
  AWARD_CONFIG,
  HINT_STATE,
  HINT_CONFIG,
  STATE
})=>{
  const token = getAuthToken()
  return fetch(
    `${BaseUrl}/adSetting/updateSetting`,
    {
      method:"POST",
      headers:{
        'Authorization': `Bearer ${token}`,
        'content-type': 'application/json'
      },
      body:JSON.stringify({
        GRAC_ID,
        GAME_ID,
        GAME_TYPE,
        TRIGGER_TYPE,
        TRIGGER_MODEL,
        GOAL_TIMES,
        TRIGGER_LIMT_TIMES,
        AWARD_TYPE,
        AWARD_CONFIG,
        HINT_STATE,
        HINT_CONFIG,
        STATE
      })
    }
  )
}