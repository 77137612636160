import styled from 'styled-components'
import Box from '@mui/material/Box'
import { BasicInput } from "../../../middleware/utilityStyle"

export const BtnGroup = styled.div`
  @media screen and (max-width: 1650px) {
    margin-top: 10px;
  }
`

export const Title = styled.td`
  text-align: center;
`

export const SendTimeWrapper = styled.div`
  display: flex;
`

export const TimeFormat = styled(Box)`
  margin-left: 20px;
`

export const PickerWrapper = styled.div`
  display: flex;
`

export const PickerInput = styled(BasicInput)`
  height: 28px;
`