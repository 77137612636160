import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker'
import { today, yesterday, thisWeek, lastWeek, thisMonth, defaultDate, defaultDateE } from "../../../middleware/dateUtils"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { getReport } from "../../../middleware/Api/gameReport/operationReportApi"
import reportTitle from "../../../assets/config/operationReport.json"
import  generateExcel  from "../../../middleware/generateExcel.js"

import {
  InputBarBg,
  RangeWrapper,
  DatePickerInput,
  RangeGroup,
  BtnGroup,
  TableTitle,
  TableForm,
  NoData,
  Info,
  MidTitle,
  STitle,
  SingleTitle,
  SecondLayer,
  ThirdLayer,
  PageStyle,
  TRow,
  TableDate,
  DateInfo,
  TableWrapper
} from "./styledComponent"
import {
  DateWrapper,
  DateTitle,
  ClickRange,
  SearchButton,
  ResetButton,
  GenerateExcel
} from "../../../middleware/utilityStyle.js"

export default function reportAI() {
  let nameList = []
  reportTitle.map(ele => {
    nameList.push([ele.chName])
  })
  const rowList = nameList
  let keyName = []
  for (let i = 0; i < rowList.length; i++) {
    let target = reportTitle.find(ele => ele.chName === rowList[i][0])
    keyName.push(target.varName)
  }

  function date(str) {
    return `${new Date(str).getFullYear()}-${new Date(str).getMonth()+1}-${new Date(str).getDate()}`
  }

  function dealExcel(DBData) {
    let columnList = [{name: '日期'}]
    for (let i = 0; i < DBData.length; i++) {
      let obj = {
        name: new Date(DBData[i].LOG_DATE).toLocaleDateString()
      }
      columnList.push(obj)
    }

    for (let j = 0; j < rowList.length; j++) {
      for (let k = 0; k < DBData.length; k++) {
        if (DBData[k][keyName[j]] || DBData[k][keyName[j]] === 0) {
          rowList[j].push(DBData[k][keyName[j]])
        } else {
          rowList[j].push('N/A')
        }        
      }
    }
    let rangeS = date(DBData[0].LOG_DATE)
    let rangeE = date(DBData[(DBData.length)-1].LOG_DATE)
    generateExcel('運營報表.xlsx', `${rangeS}~${rangeE}`, columnList, rowList)
  }

  const history = useHistory()
  const [panelInfo, setPanelInfo] = useState([])
  const A_DAY = 24 * 60 * 60 * 1000
  let oneDayBF = new Date(new Date (defaultDate).getTime()-A_DAY)
  let sevenDaysBF = new Date(new Date(defaultDateE).getTime()-A_DAY)
  const [dateValue, setDateValue] = useState([oneDayBF, sevenDaysBF])
  //Result State
  const [signUpR, setSignUpR] = useState(false)
  const [aliveR, setAliveR] = useState(true)
  const [peakNumR, setPeakNumR] = useState(false)
  const [depositR, setDepositR] = useState(false)
  const [publishGoldR, setPublishGoldR] = useState(false)
  const [publishGoldR_seven, setPublishGoldR_seven] = useState(false)
  const [publishGoldR_mission, setPublishGoldR_mission] = useState(false)
  const [consumeGoldR, setConsumeGoldR] = useState(false)
  const [recycleGoldR, setRecycleGoldR] = useState(false)
  const [playTimeCounterR, setPlayTimeCounterR] = useState(false)
  const [playTimeCounterR_chess, setPlayTimeCounterR_chess] = useState(false)
  const [playTimeCounterR_MJ, setPlayTimeCounterR_MJ] = useState(false)
  const [playTimeCounterR_domino, setPlayTimeCounterR_domino] = useState(false)
  const [playTimeCounterR_MJ2P, setPlayTimeCounterR_MJ2P] = useState(false)
  const [publishDzbR, setPublishDzbR] = useState(false)
  const [publishDzbR_chess, setPublishDzbR_chess] = useState(false)
  const [publishDzbR_MJ, setPublishDzbR_MJ] = useState(false)
  const [publishDzbR_domino, setPublishDzbR_domino] = useState(false)
  const [publishDzbR_MJ2P, setPublishDzbR_MJ2P] = useState(false)
  const [publishDzbR_mission, setPublishDzbR_mission] = useState(false)
  const [consumeDzbR, setConsumeDzbR] = useState(false)
  const [adR, setAdR] = useState(false)

  const handleLevel = () => {
    setPlayTimeCounterR(false)
    setPlayTimeCounterR_chess(false)
    setPlayTimeCounterR_MJ(false)
    setPlayTimeCounterR_domino(false)
    setPlayTimeCounterR_MJ2P(false)
  }

  const handPublishDzbleLevel = () => {
    setPublishDzbR(false)
    setPublishDzbR_chess(false)
    setPublishDzbR_MJ(false)
    setPublishDzbR_domino(false)
    setPublishDzbR_MJ2P(false)
    setPublishDzbR_mission(false)
  }

  const handPublishGoldLevel = () => {
    setPublishGoldR(false)
    setPublishGoldR_seven(false)
    setPublishGoldR_mission(false)
  }

  const handleClick = data => {
    if (data.state === 'signUpR') {
      signUpR ? setSignUpR(false) : setSignUpR(true)
    }
    if (data.state === 'aliveR') {
      aliveR ? setAliveR(false) : setAliveR(true)
    }
    if (data.state === 'peakNumR') {
      peakNumR ? setPeakNumR(false) : setPeakNumR(true)
    }
    if (data.state === 'depositR') {
      depositR ? setDepositR(false) : setDepositR(true)
    }
    if (data.state === 'publishGoldR') {
      publishGoldR ? handPublishGoldLevel() : setPublishGoldR(true)
    }
    if (data.state === 'consumeGoldR') {
      consumeGoldR ? setConsumeGoldR(false) : setConsumeGoldR(true)
    }
    if (data.state === 'recycleGoldR') {
      recycleGoldR ? setRecycleGoldR(false) : setRecycleGoldR(true)
    }
    if (data.state === 'playTimeCounterR') {
      playTimeCounterR ? handleLevel() : setPlayTimeCounterR(true)
    }
    if (data.state === 'publishDzbR') {
      publishDzbR ? handPublishDzbleLevel() : setPublishDzbR(true)
    }
    if (data.state === 'consumeDzbR') {
      consumeDzbR ? setConsumeDzbR(false) : setConsumeDzbR(true)
    }
    if (data.state === 'adR') {
      adR ? setAdR(false) : setAdR(true)
    }
  }

  const handleDisplay = data => {
    if (data.state) {    
      if (data.style !== 'M') {
        if (eval(data.state)) {
          return {display: 'block'}
        } else {
          return {display: 'none'}
        }
      }
    }
  }

  const handleTitle = () => {
    let TList = []
    for (let i = 0; i < reportTitle.length; i++) {
      if (reportTitle[i].style === 'S') {
        TList.push(<STitle style= {handleDisplay(reportTitle[i])}>{reportTitle[i].chName}</STitle>)
      }
      if (reportTitle[i].style === 'M') {        
        TList.push(<MidTitle onClick= {() => {handleClick(reportTitle[i])}}>{reportTitle[i].chName}</MidTitle>)
      }
      if (reportTitle[i].style === 'singleData') {
        TList.push(<SingleTitle>{reportTitle[i].chName}</SingleTitle>)
      }
      if (reportTitle[i].style === 'Second') {
        TList.push(<SecondLayer style= {handleDisplay(reportTitle[i])} onClick= {() => {handleSecondLayer(reportTitle[i])}}>{reportTitle[i].chName}</SecondLayer>)
      }
      if (reportTitle[i].style === 'Third') {
        TList.push(<ThirdLayer style= {handleDisplay(reportTitle[i])} onClick= {() => {handleClick(reportTitle[i])}}>{reportTitle[i].chName}</ThirdLayer>)
      }
    }
    return TList
  }

  const handleInfo = ele => {
    let infoList = []
    for (let i = 0; i < rowList.length; i++) {
      let target = reportTitle.find(obj => obj.chName === rowList[i][0])
      let AINoData = ['TOTAL_AD', 'SIGN_UP_AD', 'MAKE_UP_AD', 'CHANGE_NICKNAME_AD', 'BANKRUPT_AD', 'GIFT_AD', 'ACTIVITY_AD', 'MINI_GAME_AD', 'ADSETTING_AD']
      if (target.varName) {
        if (AINoData.indexOf(target.varName) >= 0) {
          infoList.push(
            <Info style= {handleDisplay(target)}
              onClick= {() => {if (target.style === 'M') {
              handleClick(reportTitle[i])
          }}}>0</Info>)
        } else {
          infoList.push(
            <Info style= {handleDisplay(target)}
              onClick= {() => {if (target.style === 'M') {
              handleClick(reportTitle[i])
          }}}>{ele[keyName[i]]}</Info>)
        }        
      }
      if (!target.varName) {
        infoList.push(
          <Info style= {handleDisplay(target)}
          onClick= {() => {if (target.style === 'M') {
            handleClick(reportTitle[i])
        }}}>No Data</Info>)
      }
    }
    return infoList
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      if (dateValue[0] === null || dateValue[1] === null) {
        alert('※請輸入日期')
        return
      }
      if (dateValue[0] && dateValue[1]) {
        let sDate = new Date(dateValue[0]).toISOString()
        let end = new Date(dateValue[1]).toString()
        let eDate = new Date(end.replace('00:00:00', '23:59:59')).toISOString()
        getReport(sDate, eDate, 2).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          if (db.error === errorCode.NULL_DATA.code) {
            alert(`※  ${errorCode.RECORD_NOT_FOUND.memo}`)
            setDateValue([oneDayBF, sevenDaysBF])
            getReport(oneDayBF.toString(), sevenDaysBF.toString(), 2).then(db => {
              if (db.error === errorCode.INCORRECT_DATA.code) {
                alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                return
              }
              if (db.error === errorCode.INCORRECT_FORMAT.code) {
                alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                return
              }
              if (db.error === errorCode.NULL_DATA.code) {
                alert(`※  預設日期無資料`)
                return
              }
              if (db.error === errorCode.EXCEPTION.code) {
                alert(`※  ${errorCode.EXCEPTION.memo}`)
                return
              }
              setPanelInfo(db.data)
            })
            return
          }
          setPanelInfo(db.data)
        })
      }
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setDateValue([oneDayBF, sevenDaysBF])
    })
  }

  const handleExcel = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      if (dateValue[0] === null || dateValue[1] === null) {
        alert('※請輸入日期')
        return
      }

      let sDate = ''
      let end = ''
      let eDate = ''
      if (dateValue[0] && dateValue[1]) {
        sDate = new Date(dateValue[0]).toISOString()
        end = new Date(dateValue[1]).toString()
        eDate = new Date(end.replace('00:00:00', '23:59:59')).toISOString()
      }

      getReport(sDate, eDate, 2).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }

        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  此日期尚無資料`)
          return
        }
        dealExcel(db.data)
        setPanelInfo(db.data)
      })
    })
  }

  const handleSecondLayer = data => {
    if (data.varName === 'CHESS_MULTI') {
      playTimeCounterR_chess ? setPlayTimeCounterR_chess(false) : setPlayTimeCounterR_chess(true)
    }
    if (data.varName === 'MJ_MULTI') {
      playTimeCounterR_MJ ? setPlayTimeCounterR_MJ(false) : setPlayTimeCounterR_MJ(true)
    }
    if (data.varName === 'DOMINO_MULTI') {
      playTimeCounterR_domino ? setPlayTimeCounterR_domino(false) : setPlayTimeCounterR_domino(true)
    }
    if (data.varName === 'MJ2P_MULTI') {
      playTimeCounterR_MJ2P ? setPlayTimeCounterR_MJ2P(false) : setPlayTimeCounterR_MJ2P(true)
    }
    if (data.varName === 'MISSION_GEM_GIVING') {
      publishDzbR_chess ? setPublishDzbR_chess(false) : setPublishDzbR_chess(true)
      publishDzbR_MJ ? setPublishDzbR_MJ(false) : setPublishDzbR_MJ(true)
      publishDzbR_domino ? setPublishDzbR_domino(false) : setPublishDzbR_domino(true)
      publishDzbR_MJ2P ? setPublishDzbR_MJ2P(false) : setPublishDzbR_MJ2P(true)
    }
    if (data.varName === 'SEVENDAY_TOTAL_GOLD_GIVING') {
      publishGoldR_seven ? setPublishGoldR_seven(false) : setPublishGoldR_seven(true)
    }
    if (data.varName === 'MISSION_GOLD_GIVING') {
      publishGoldR_mission ? setPublishGoldR_mission(false) : setPublishGoldR_mission(true)
    }
    if (data.varName === 'TOTAL_DAILY_MISSION_GEM_GIVING') {
      publishDzbR_mission ? setPublishDzbR_mission(false) : setPublishDzbR_mission(true)
    }
  }
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <RangeWrapper>
          <DateWrapper>
            <DateTitle>查詢時間</DateTitle>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                <DesktopDateRangePicker
                  startText="創建時間"
                  endText="結束時間"
                  value={dateValue}
                  onChange={(newValue) => {
                    setDateValue(newValue)
                  }}
                  renderInput={(startProps, endProps) => {
                    startProps.inputProps.placeholder = '開始日期'
                    endProps.inputProps.placeholder= '結束日期'
                    return (
                      <React.Fragment>
                        <DatePickerInput ref={startProps.inputRef} {...startProps.inputProps} />
                        <Box sx={{ mx: 1 }}>至</Box>
                        <DatePickerInput ref={endProps.inputRef} {...endProps.inputProps} />
                      </React.Fragment>
                    )
                  }}
                />
              </Stack>
            </LocalizationProvider>
          </DateWrapper>
          <RangeGroup>
            <ClickRange onClick={() => { setDateValue(today) }}>今天</ClickRange>
            <ClickRange onClick={() => { setDateValue(yesterday) }}>昨天</ClickRange>
            <ClickRange onClick={() => { setDateValue(thisWeek) }}>本周</ClickRange>
            <ClickRange onClick={() => { setDateValue(lastWeek) }}>上周</ClickRange>
            <ClickRange onClick={() => { setDateValue(thisMonth) }}>本月</ClickRange>
          </RangeGroup>
        </RangeWrapper>

        <BtnGroup>
          <SearchButton onClick={handleSearch}>搜索</SearchButton>
          <ResetButton onClick={handleReset}>重置</ResetButton>
        </BtnGroup>
      </InputBarBg>
      
      <GenerateExcel style= {{marginTop: '30px'}} onClick= {handleExcel}>產出EXCEL報表</GenerateExcel>

      <TableWrapper>
        <TableTitle className="table-hover table-bordered" id= "table-demo">
          <tbody>
          <TRow>
              <TableDate>日期</TableDate>
              {handleTitle()}
            </TRow>
          </tbody>
        </TableTitle>

        <TableForm className={panelInfo.length ? "table-bordered table-hover" : "table-bordered"} id= "table-demo">
          <tbody>
            {
              Array.isArray(panelInfo) && panelInfo.length > 0 ?
              panelInfo.map((ele, index) => {
                  return (
                    <TRow key= {index}>
                      <DateInfo>{new Date(ele?.LOG_DATE).toLocaleDateString()}</DateInfo>
                      {handleInfo(ele)}
                    </TRow>
                  )
                })
              : <tr style= {{display: 'inline-block'}}><NoData>尚無資料</NoData></tr>
            }
          </tbody>
        </TableForm>
      </TableWrapper>

      <GenerateExcel style= {{marginTop: '30px'}} onClick= {handleExcel}>產出EXCEL報表</GenerateExcel>
    </>
  )
}