import { getAuthToken } from "../utils"
import { BaseUrl } from "../../assets/config/urlForBuild"

//? 取得操作紀錄 PID53 ?//
export const getRecord = (account, operateType, operateContent, sDate, eDate, sRow, eRow) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/sysBehavior?account=${account}&operateType=${operateType}&operateContent=${operateContent}&sDate=${sDate}&eDate=${eDate}&sRow=${sRow}&eRow=${eRow}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}