import React, { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import CategorySetting from "../../../components/popUpWindow/itemSetting/categorySetting"
import { itemCategory } from "../../../middleware/Api/operation/itemSettingApi"
import { getAccessArr } from "../../../middleware/utils"
import gameCategories from "../../../assets/config/gameCategories.json"
import generateExcel from "../../../middleware/generateExcel.js"
import { getDZBMissionEventReport } from "../../../middleware/Api/eventManagement/dzbMissionEventApi.js"
import {
  InputBarBg,
  NavLeft,
  BtnGroup,
  BTN,
  Dropdown
} from "../prizeLimit/styledComponent.js"
import {
  RangeWrapper,
  DatePickerInput,
  RangeGroup,
  Group,
  TableForm,
  STitle,
  NoData,
  NoInfo
} from "../../backstageManagement/dzbMissionReport/styledComponent"
import {
  SearchButton,
  ResetButton,
  BasicTable,
  BasicRowTitle,
  TableContent,
  BasicHeadTitle,
  PageStyle,
  TableBtnGroup,
  NewBtn,
  ReviseBtn,
  DeleteBtn,
  GenerateExcel
} from "../../../middleware/utilityStyle.js"

export default function DZBMissionEventReport () {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const history = useHistory() 
  const [change, setChange] = useState({
    gameID: 7001
  })
  const handleChange = (e, key) => {
    setChange((prev) => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const A_DAY = 24 * 60 * 60 * 1000
  const [mTitle, setMTitle] = useState([])
  const [allData, setAllData] = useState([])
  const [dObj, setDObj] = useState()
  const [dCounter, setDCounter] = useState()
  const [firstDate, setFirstDate] = useState()
  const [noData, setNoData] = useState(true)
  const [DBData, setDBData] = useState([])

  const urlQueryString = useLocation().search
  const [queryValue, setQueryValue]= useState({activityID: 0, activityName: ''})
  useEffect(() => {
    let firstArr = urlQueryString.match(/(\w+)=([\u4e00-\u9fa5a-zA-Z0-9]+(([-]+|[\u4e00-\u9fa5a-zA-Z0-9]+|[_]+|))+)/gm)
    let secondArr = firstArr.map(str => str.match(/([\u4e00-\u9fa5a-zA-Z0-9]+(([-]+|[\u4e00-\u9fa5a-zA-Z0-9]+|[_]+|))+)/gm))
    let idObj = {}
    secondArr.forEach(arr => {idObj[arr[0]] = arr[1]})
    setQueryValue(idObj)
  }, [urlQueryString])
  
  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      
      function getMissionIDKey(dataArr) {
        let noRepeatName = []
        if (dataArr.length) {
          dataArr.map(ele => {
            noRepeatName.push(ele.MISSION_ID.split('_')[0])
          })
          return [...new Set(noRepeatName)]
        }
      }
      
      function orderedMissionID(dataArr, nameList) {  //para: arr, arr
        let missionContentList = []
        for (let i = 0; i < nameList.length; i++) {
          let data = dataArr.filter(ele => ele.MISSION_ID.indexOf(nameList[i]) >= 0)
          data.sort(function order(a, b) {
            return (+a.MISSION_ID.split('_')[1]) - (+b.MISSION_ID.split('_')[1])
          })
          let tempArr = []
          for (let l =0 ; l < data.length; l++) {
            if (!tempArr.find(ele => ele.MISSION_ID === data[l].MISSION_ID)) {
              let obj = {
                MISSION_ID: data[l].MISSION_ID,
                MISSION_NAME: data[l].MISSION_NAME
              }
              tempArr.push(obj)
            }            
          }
          missionContentList.push(...tempArr)
        }
        return missionContentList
      }
      
      getDZBMissionEventReport(+queryValue.activityID, +change.gameID).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          setNoData(true)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        const searchData = db.data
        let obj = {}
        let dateArr = []
        let daysRange = 0
        searchData.map(ele => dateArr.push(ele.LOG_DATE))
        dateArr.sort((a, b) => {
          return new Date(b).getTime() - new Date(a).getTime()
        })
        function getDayRangeObj(targetD) {
          if (dateArr.length === 1) {
            setDCounter(1)
            daysRange = 1
          } else {
            daysRange = (new Date(dateArr[0]).getTime() - new Date(dateArr[dateArr.length - 1]).getTime())/A_DAY + 1
            setDCounter(daysRange)
          }
          setFirstDate(new Date(dateArr[0]))

          for (let j = 0; j < daysRange; j++) {
            let unoDayArr = []
            unoDayArr.push(targetD.filter(ele => new Date(ele.LOG_DATE).getTime() === new Date(dateArr[0]).getTime()-A_DAY*j))
            obj[`day${j+1}`] = unoDayArr
          }
          setDObj(obj)
        }

        function getFourDataObj(keyArr) {
          let dataOBJ = {
            appearList: [],
            reachList: [],
            rPercentage: [],
            totalSendDzb: []
          }            
          for (let d = 0; d < daysRange; d++) {
            let AList = []
            let RList = []
            let RPList = []
            let dzbList = []
            for (let i = 0; i < keyArr.length; i++) {
            let target = obj[`day${d+1}`][0].find(ele => ele.MISSION_ID === keyArr[i].MISSION_ID)
            if (target) {
              AList.push(target.APPEAR_TIME)
              RList.push(target.REACH_TIME)
              RPList.push(get2Decimal(target.REACH_TIME, target.APPEAR_TIME))
              dzbList.push(target.SEND_GEM)
            } else {
              AList.push('---')
              RList.push('---')
              RPList.push('---')
              dzbList.push('---')
            }  
          }
            dataOBJ.appearList.push(AList)
            dataOBJ.reachList.push(RList)
            dataOBJ.rPercentage.push(RPList)
            dataOBJ.totalSendDzb.push(dzbList)
          }
          setAllData(dataOBJ)
        }
        setNoData(false)
        setDBData(db.data)
        let missionCategory = getMissionIDKey(searchData)
        let orderedList = orderedMissionID(searchData, missionCategory)
        setMTitle(orderedList)
        getDayRangeObj(searchData)
        getFourDataObj(orderedList)
      })
    })
  }
  
  function get2Decimal(reachT, appearT) {
    let nStr = (Math.round(reachT / appearT * 10000) / 100.00).toString()
    let dot = nStr.indexOf('.')
    let result = 0
    if (!reachT || !appearT) {
      result = '0.00'
    } else {
      if (dot > 0) {
        if (nStr.slice(dot+1).length === 1) {
          result = nStr + '0'
        }
        if (nStr.slice(dot+1).length === 2) {
          result = nStr
        }
      }
      if (dot < 0) {
        result = nStr + '.00'
      }
    }  
    return result + ' %'
  }

  function TableDate() {
    let titleList = []
    if (dCounter && firstDate) {
      for (let k = 0; k < dCounter; k++) {
        titleList.push(<BasicHeadTitle style= {firstDate ? {} : {display: 'none'}}>{new Date(firstDate.getTime()-(A_DAY * k)).toLocaleDateString()}<br/>出現次數</BasicHeadTitle>)
        titleList.push(<BasicHeadTitle style= {firstDate ? {} : {display: 'none'}}>{new Date(firstDate.getTime()-(A_DAY * k)).toLocaleDateString()}<br/>達成次數</BasicHeadTitle>)
        titleList.push(<BasicHeadTitle style= {firstDate ? {} : {display: 'none'}}>{new Date(firstDate.getTime()-(A_DAY * k)).toLocaleDateString()}<br/>達成率</BasicHeadTitle>)
        titleList.push(<BasicHeadTitle style= {firstDate ? {} : {display: 'none'}}>{new Date(firstDate.getTime()-(A_DAY * k)).toLocaleDateString()}<br/>鬥陣寶總發出數</BasicHeadTitle>)
      }
    }
    return (
      <>
        {noData ? [] : titleList}
      </>
    )
  }
  
  function DataGroup(displayData, dataLength, index) {
    let list = []
    for (let i = 0; i < dataLength; i++) {
      list.push(<td>{displayData.appearList[i][index]}</td>)
      list.push(<td>{displayData.reachList[i][index]}</td>)
      list.push(<td>{displayData.rPercentage[i][index]}</td>)
      list.push(<td>{displayData.totalSendDzb[i][index]}</td>)
    }
    return (
      <>
        {noData ? [] : list}
      </>
    )
  }
  
  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({gameID: 7001})
    })
  }
  
  const handleExcel = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      let columnList = [{name: '鬥陣寶任務內容'}]
      let rowList = []
      if (dObj) {
        for (let k = 0; k < dCounter; k++) {
          let obj1 = {
            name: `${new Date(firstDate.getTime()-(A_DAY * k)).toLocaleDateString()}出現次數`
          }
          let obj2 = {
            name: `${new Date(firstDate.getTime()-(A_DAY * k)).toLocaleDateString()}達成次數`
          }
          let obj3 = {
            name: `${new Date(firstDate.getTime()-(A_DAY * k)).toLocaleDateString()}達成率`
          }
          let obj4 = {
            name: `${new Date(firstDate.getTime()-(A_DAY * k)).toLocaleDateString()}鬥陣寶總發出數`
          }

          columnList.push(obj1)
          columnList.push(obj2)
          columnList.push(obj3)
          columnList.push(obj4)
        }
        
        for(let j = 0; j < mTitle.length ; j++) {
          rowList.push([DBData.find(ele => ele.MISSION_ID === mTitle[j].MISSION_ID).MISSION_NAME])
        }

        for(let k = 0; k < mTitle.length ; k++) {
          let unoMData = []
          for (let i = 0; i < dCounter; i++) {
            let appearTime = dObj[`day${i+1}`][0].find(ele => ele.MISSION_ID === mTitle[k].MISSION_ID).APPEAR_TIME
            let reachTime = dObj[`day${i+1}`][0].find(ele => ele.MISSION_ID === mTitle[k].MISSION_ID).REACH_TIME
            let dzbQuantity = dObj[`day${i+1}`][0].find(ele => ele.MISSION_ID === mTitle[k].MISSION_ID).SEND_GEM
            unoMData.push(dObj[`day${i+1}`][0].length > 0 ? appearTime : '---')
            unoMData.push(dObj[`day${i+1}`][0].length > 0 ? reachTime : '---')
            unoMData.push(dObj[`day${i+1}`][0].length > 0 ? get2Decimal(reachTime, appearTime) : '---')
            unoMData.push(dObj[`day${i+1}`][0].length > 0 ? dzbQuantity : '---')
          }
          rowList[k].push(...unoMData)
        }
      }
      if (columnList.length > 1 && rowList.length) {
        generateExcel('鬥陣寶任務活動報表.xlsx', `${queryValue.activityName}`, columnList, rowList)
      } else {
        alert('※ 請搜索資料')
        return
      }
    })
  }

  function gameCategory() {
    let gameList = []
    gameCategories.map((ele, index) => {
      gameList.push(<option value= {ele.gameID} key= {index}>{ele.gameName}</option>)
    })
    return gameList
  }
  return(
    <>
    <InputBarBg className="navbar navbar-light bg-light">
      <NavLeft>
        <div>
          <span>遊戲</span>
          <Dropdown value= {change.gameID} onChange= {e=> {handleChange(e, 'gameID')}}>
            {gameCategory()}
          </Dropdown>
        </div>
      </NavLeft>      
      <BtnGroup>
        <SearchButton onClick={handleSearch}>搜索</SearchButton>
        <ResetButton onClick={handleReset}>重置</ResetButton>
      </BtnGroup>
    </InputBarBg>

    <GenerateExcel style= {{marginTop: '30px'}} onClick= {handleExcel}>產出EXCEL報表</GenerateExcel>
    
    <TableForm  className="table table-hover table-bordered">
        <thead>
          <tr key= "BasicHeadTitle">
            <BasicHeadTitle>鬥陣任務活動報表</BasicHeadTitle>
            <NoInfo style={!noData ? {display: 'none'} : {display: 'table-cell'}}>尚無資料</NoInfo>
            {TableDate()}
          </tr>
        </thead>
        <tbody>
          {
            mTitle.length && Array.isArray(mTitle) ?
              allData.appearList ?
              mTitle.map((ele, index) => {
                return (
                  <>
                    <TableContent key= {`list${index}`}>
                      <STitle style={noData ? {display: 'none'} : {display: 'table-cell'}}>{ele?.MISSION_NAME}</STitle>
                      {DataGroup(allData, allData.appearList.length, index)}
                    </TableContent>
                  </>
                )
              })
              : <NoData></NoData>
            : <NoData></NoData>
          }
        </tbody>
      </TableForm>
  </>)
}