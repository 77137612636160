import React, { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { getAccessArr } from "../../../middleware/utils"
import { getDZBMissionIndividualSetting, reviseDZBMissionIndividualSetting } from "../../../middleware/Api/eventManagement/dzbMissionEventApi.js"
import {
  BasicTable,
  BasicRowTitle,
  TableContent,
  BigSquareInput,
  ReviseBtnM,
  InformBtnM,
  CancelReviseBtnM
} from "../../../middleware/utilityStyle.js"
import {
  GameType,
  MissionInput,
  RBtn
} from "./styledComponent.js"

export default function dzbMissionIndividualSetting() {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const urlQueryString = useLocation().search
  const [queryValue, setQueryValue]= useState({activityID: 0, activityName: ''})

  const history = useHistory()
  const [dataCopy, setDataCopy] = useState([])
  const [displayData, setDisplayData] = useState([])
  const [reviseMode, setReviseMode] = useState(false)
  const [missionNameList,setMissionNameList] = useState([])
  
  //const [friendArr, setFriendArr] = useState([])
  const [leisureArr, setLeisureArr] = useState([])
  const [beginnerArr, setBeginnerArr] = useState([])
  const [medArr, setMedArr] = useState([])
  const [advArr, setAdvArr] = useState([])

  //const [friendAll, setFriendAll] = useState(false)
  const [leisureAll, setLeisureAll] = useState(false)
  const [beginnerAll, setBeginnerAll] = useState(false)
  const [medAll, setMedAll] = useState(false)
  const [advAll, setAdvAll] = useState(false)
  const [switchAll, setSwitchAll] = useState(false)

  function getTypeInfo(num, targetTypeIndex) {
    let BIN = transToBIN(num)
    let BINStr = BIN.toString()
    let BLength = BINStr.length
    if ( BLength < 5) {
      let missingZero = 5 - BLength
      for (let i = 0; i < missingZero; i++) {
        BINStr = '0' + BINStr
      }
    }
    if (targetTypeIndex === 99) {
      return BIN
    } else {
      return BIN[targetTypeIndex]
    }
  }

  function getMissionIDKey(dataArr) {
    let noRepeatName = []
    if (!dataArr.length) return
    dataArr.map(ele => {
      if (!ele.MISSION_ID) {
        noRepeatName.push(ele.split('_')[0])
      } else {
        noRepeatName.push(ele.MISSION_ID.split('_')[0])
      }
    })
    return [...new Set(noRepeatName)]
  }

  function orderedByMissionID(dataArr, nameList) {  //para: arr, arr
    let missionContentList = []
    if (!dataArr.length) return
    if (dataArr[0].MISSION_ID) {
      for (let i = 0; i < nameList.length; i++) {
        let data = dataArr.filter(ele => ele.MISSION_ID.indexOf(nameList[i]) >= 0)
        data.sort(function order(a, b) {
          return (+a.MISSION_ID.split('_')[1]) - (+b.MISSION_ID.split('_')[1])
        })
        let tempArr = []
        for (let l =0 ; l < data.length; l++) {
          if (!tempArr.find(ele => ele.MISSION_ID === data[l].MISSION_ID)) {
            tempArr.push(data[l])
          }            
        }
        missionContentList.push(...tempArr)
      }
    } else {
      for (let i = 0; i < nameList.length; i++) {
        let data = dataArr.filter(ele => ele.indexOf(nameList[i]) >= 0)
        data.sort(function order(a, b) {
          return (+a.split('_')[1]) - (+b.split('_')[1])
        })
        let tempArr = []
        for (let l =0 ; l < data.length; l++) {
          if (!tempArr.find(ele => ele === data[l])) {
            tempArr.push(data[l])
          }            
        }
        missionContentList.push(...tempArr)
      }
    }
    return missionContentList
  }

  function transToBIN(num) {
    return (num).toString(2)
  }

  function transToDEC(numStr) {
    return (parseInt(numStr, 2))
  }

  function getMissionSetting(activityID) {
    getDZBMissionIndividualSetting(activityID).then(db=> {
      if (db.error === errorCode.NULL_DATA.code) {
        alert(`※  ${errorCode.NULL_DATA.memo}`)
        return
      }
      if (db.error === errorCode.EXCEPTION.code) {
        alert(`※  ${errorCode.EXCEPTION.memo}`)
        return
      }
      let searchData = JSON.parse(JSON.stringify(db.data))
      let missionCategory = getMissionIDKey(searchData)
      let orderedList = orderedByMissionID(searchData, missionCategory)
      setDataCopy(JSON.parse(JSON.stringify(orderedList)))
      setDisplayData(JSON.parse(JSON.stringify(orderedList)))
      let allMissionName = []
      db.data.map(ele => {
        allMissionName.push(ele.MISSION_ID)
      })
      let missionIDArr = getMissionIDKey(allMissionName)
      let missionList = orderedByMissionID(allMissionName, missionIDArr)
      setMissionNameList(missionList)

      let friendMission = []
      let leisureMission = []
      let beginnerMission = []
      let medMission = []
      let advMission = []
      orderedList.map(ele => {
        let friendState = getTypeInfo(ele.GAME_TYPE, 0)
        let leisureState = getTypeInfo(ele.GAME_TYPE, 1)
        let beginnerState = getTypeInfo(ele.GAME_TYPE, 2)
        let medState = getTypeInfo(ele.GAME_TYPE, 3)
        let advState = getTypeInfo(ele.GAME_TYPE, 4)
        if (friendState) {
          friendMission.push(ele.MISSION_ID)
        }
        if (leisureState) {
          leisureMission.push(ele.MISSION_ID)
        }
        if (beginnerState) {
          beginnerMission.push(ele.MISSION_ID)
        }
        if (medState) {
          medMission.push(ele.MISSION_ID)
        }
        if (advState) {
          advMission.push(ele.MISSION_ID)
        }
        setFriendArr(friendMission)
        setLeisureArr(leisureMission)
        setBeginnerArr(beginnerMission)
        setMedArr(medMission)
        setAdvArr(advMission)
      })
    })
  }

  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      let firstArr = urlQueryString.match(/(\w+)=([\u4e00-\u9fa5a-zA-Z0-9]+(([-]+|[\u4e00-\u9fa5a-zA-Z0-9]+|[_]+|))+)/gm)
      let secondArr = firstArr.map(str => str.match(/([\u4e00-\u9fa5a-zA-Z0-9]+(([-]+|[\u4e00-\u9fa5a-zA-Z0-9]+|[_]+|))+)/gm))
      let idObj = {}
      secondArr.forEach(arr => {idObj[arr[0]] = arr[1]})
      setQueryValue(idObj)
      getMissionSetting(+idObj.activityID)
    })
  }, [])

  const handleReviseInfo = (e, key) => {
    let newArr = displayData.concat()
    newArr[+e.target.dataset.index][key] = e.target.value
    setDisplayData(newArr)
  }

  const handleDisplayType = (missionID, num) => {
    displayData.find(ele => ele.MISSION_ID === missionID)
    let friendState = getTypeInfo(num, 0)
    let leisureState = getTypeInfo(num, 1)
    let beginnerState = getTypeInfo(num, 2)
    let medState = getTypeInfo(num, 3)
    let advState = getTypeInfo(num, 4)
    return (
      <>
        <span>
          <input
            type= "checkbox"
            checked= {leisureState === '1' ? true : false}
            disabled
          />休閒
        </span>
        <span>
          <input
            type= "checkbox"
            checked= {beginnerState === '1' ? true : false}
            disabled
          />初級
        </span>
        <span>
          <input
            type= "checkbox"
            checked= {medState === '1' ? true : false}
            disabled
          />中級
        </span>
        <span>
          <input
            type= "checkbox"
            checked= {advState === '1' ? true : false}
            disabled
          />高級
        </span>
      </>
    )
  }

  const handleLeisureCheck = e => {
    let newArr = []
    const targetIndex = leisureArr.findIndex(ele => ele === e.target.dataset.missionid)
    if (targetIndex >= 0) {
      leisureArr.splice(targetIndex, 1)
      newArr = [...leisureArr]
    } else {
      newArr = [...leisureArr, e.target.dataset.missionid]
    }
    let missionIDArr = getMissionIDKey(newArr)
    let missionList = orderedByMissionID(newArr, missionIDArr)
    setLeisureArr(missionList)
    let newData = displayData.concat()
    let reviseTarget = displayData.find(ele => ele.MISSION_ID === e.target.dataset.missionid)
    let reviseInfo = getTypeInfo(+reviseTarget.GAME_TYPE, 99)
    let infoArr = Array.from(reviseInfo.toString())
    if (!+infoArr[1]) {
      infoArr.splice(1, 1, '1')
    } else if (+infoArr[1]) {
      infoArr.splice(1, 1, '0')
      setLeisureAll(false)
    }
    let hexResult = transToDEC(infoArr.join(''))
    newData[e.target.dataset.index].GAME_TYPE = +hexResult
    setDisplayData(newData)
  }

  const handleBeginnerCheck = e => {
    let newArr = []
    const targetIndex = beginnerArr.findIndex(ele => ele === e.target.dataset.missionid)
    if (targetIndex >= 0) {
      beginnerArr.splice(targetIndex, 1)
      newArr = [...beginnerArr]
    } else {
      newArr = [...beginnerArr, e.target.dataset.missionid]
    }
    let missionIDArr = getMissionIDKey(newArr)
    let missionList = orderedByMissionID(newArr, missionIDArr)
    setBeginnerArr(missionList)
    let newData = displayData.concat()
    let reviseTarget = displayData.find(ele => ele.MISSION_ID === e.target.dataset.missionid)
    let reviseInfo = getTypeInfo(+reviseTarget.GAME_TYPE, 99)
    let infoArr = Array.from(reviseInfo.toString())
    if (!+infoArr[2]) {
      infoArr.splice(2, 1, '1')
    } else if (+infoArr[2]) {
      infoArr.splice(2, 1, '0')
      setBeginnerAll(false)
    }
    let hexResult = transToDEC(infoArr.join(''))
    newData[e.target.dataset.index].GAME_TYPE = +hexResult
    setDisplayData(newData)
  }

  const handleMedCheck = e => {
    let newArr = []
    const targetIndex = medArr.findIndex(ele => ele === e.target.dataset.missionid)
    if (targetIndex >= 0) {
      medArr.splice(targetIndex, 1)
      newArr = [...medArr]
    } else {
      newArr = [...medArr, e.target.dataset.missionid]
    }
    let missionIDArr = getMissionIDKey(newArr)
    let missionList = orderedByMissionID(newArr, missionIDArr)
    setMedArr(missionList)
    let newData = displayData.concat()
    let reviseTarget = displayData.find(ele => ele.MISSION_ID === e.target.dataset.missionid)
    let reviseInfo = getTypeInfo(+reviseTarget.GAME_TYPE, 99)
    let infoArr = Array.from(reviseInfo.toString())
    if (!+infoArr[3]) {
      infoArr.splice(3, 1, '1')
    } else if (+infoArr[3]) {
      infoArr.splice(3, 1, '0')
      setMedAll(false)
    }
    let hexResult = transToDEC(infoArr.join(''))
    newData[e.target.dataset.index].GAME_TYPE = +hexResult
    setDisplayData(newData)
  }

  const handleAdvCheck = e => {
    let newArr = []
    const targetIndex = advArr.findIndex(ele => ele === e.target.dataset.missionid)
    if (targetIndex >= 0) {
      advArr.splice(targetIndex, 1)
      newArr = [...advArr]
    } else {
      newArr = [...advArr, e.target.dataset.missionid]
    }
    let missionIDArr = getMissionIDKey(newArr)
    let missionList = orderedByMissionID(newArr, missionIDArr)
    setAdvArr(missionList)
    let newData = displayData.concat()
    let reviseTarget = displayData.find(ele => ele.MISSION_ID === e.target.dataset.missionid)
    let reviseInfo = getTypeInfo(+reviseTarget.GAME_TYPE, 99)
    let infoArr = Array.from(reviseInfo.toString())
    if (!+infoArr[4]) {
      infoArr.splice(4, 1, '1')
    } else if (+infoArr[4]) {
      infoArr.splice(4, 1, '0')
      setAdvAll(false)
    }
    let hexResult = transToDEC(infoArr.join(''))
    newData[e.target.dataset.index].GAME_TYPE = +hexResult
    setDisplayData(newData)
  }

  const handleState = (e, state) => {
    let newData = displayData.concat()
    if (state) {      
      newData[e.target.dataset.index].MISSION_STATE = 0
      setDisplayData(newData)
      setSwitchAll(false)
    }
    if (!state) {
      newData[e.target.dataset.index].MISSION_STATE = 1
      setDisplayData(newData)
    }
  }

  const handleCheckAllLeisure = () => {    
    let status = false
    if (leisureAll) {
      setLeisureAll(false)
      status = false
      setLeisureArr([])
    } else {
      setLeisureAll(true)
      status = true
      setLeisureArr(missionNameList)
    }
    let newArr = displayData.concat()
    newArr.map(ele => {
      let targetInfo = getTypeInfo(+ele.GAME_TYPE, 99)
      let infoArr = Array.from(targetInfo.toString())
      if (status) {
        if (!+infoArr[1]) {
          infoArr.splice(1, 1, '1')
        }
      }
      if (!status) {
        if (+infoArr[1]) {
          infoArr.splice(1, 1, '0')
        }
      }      
      let hexResult = transToDEC(infoArr.join(''))
      ele.GAME_TYPE = +hexResult  
    })
  }

  const handleCheckAllBeginner = () => {
    let status = false
    if (beginnerAll) {
      setBeginnerAll(false)
      status = false
      setBeginnerArr([])
    } else {
      setBeginnerAll(true)
      status = true
      setBeginnerArr(missionNameList)
    }
    let newArr = displayData.concat()
    newArr.map(ele => {
      let targetInfo = getTypeInfo(+ele.GAME_TYPE, 99)
      let infoArr = Array.from(targetInfo.toString())
      if (status) {
        if (!+infoArr[2]) {
          infoArr.splice(2, 1, '1')
        }
      }
      if (!status) {
        if (+infoArr[2]) {
          infoArr.splice(2, 1, '0')
        }
      }      
      let hexResult = transToDEC(infoArr.join(''))
      ele.GAME_TYPE = +hexResult  
    })
  }

  const handleCheckAllMed = () => {    
    let status = false
    if (medAll) {
      setMedAll(false)
      status = false
      setMedArr([])
    } else {
      setMedAll(true)
      status = true
      setMedArr(missionNameList)
    }
    let newArr = displayData.concat()
    newArr.map(ele => {
      let targetInfo = getTypeInfo(+ele.GAME_TYPE, 99)
      let infoArr = Array.from(targetInfo.toString())
      if (status) {
        if (!+infoArr[3]) {
          infoArr.splice(3, 1, '1')
        }
      }
      if (!status) {
        if (+infoArr[3]) {
          infoArr.splice(3, 1, '0')
        }
      }      
      let hexResult = transToDEC(infoArr.join(''))
      ele.GAME_TYPE = +hexResult  
    })
  }

  const handleCheckAllAdv = () => {
    let status = false
    if (advAll) {
      setAdvAll(false)
      status = false
      setAdvArr([])
    } else {
      setAdvAll(true)
      status = true
      setAdvArr(missionNameList)
    }
    let newArr = displayData.concat()
    newArr.map(ele => {
      let targetInfo = getTypeInfo(+ele.GAME_TYPE, 99)
      let infoArr = Array.from(targetInfo.toString())
      if (status) {
        if (!+infoArr[4]) {
          infoArr.splice(4, 1, '1')
        }
      }
      if (!status) {
        if (+infoArr[4]) {
          infoArr.splice(4, 1, '0')
        }
      }      
      let hexResult = transToDEC(infoArr.join(''))
      ele.GAME_TYPE = +hexResult  
    })
  }

  const handleCheckAllSwitch = () => {    
    let status = false
    if (switchAll) {
      setSwitchAll(false)
      status = false
    } else {
      setSwitchAll(true)
      status = true
    }
    let newArr = displayData.concat()
    newArr.map(ele => {
      if (status) {
        if (!+ele.MISSION_STATE) {
          ele.MISSION_STATE = 1
        }
      }
      if (!status) {
        if (+ele.MISSION_STATE) {
          ele.MISSION_STATE = 0
        }
      }
    })
    setDisplayData(newArr)
  }

  const handleReviseType = (index, missionID, num) => {
    //let friendState = getTypeInfo(num, 0)
    let leisureState = getTypeInfo(num, 1)
    let beginnerState = getTypeInfo(num, 2)
    let medState = getTypeInfo(num, 3)
    let advState = getTypeInfo(num, 4)
    return (
      <>
        <span>
          <input
            type= "checkbox"
            checked= {leisureState === '1' ? true : false}
            data-missionid = {missionID}
            data-index= {index}
            onChange= {handleLeisureCheck}
          />休閒
        </span>
        <span>
          <input
            type= "checkbox"
            checked= {beginnerState === '1' ? true : false}
            data-missionid = {missionID}
            data-index= {index}
            onChange= {handleBeginnerCheck}
          />初級
        </span>
        <span>
          <input
            type= "checkbox"
            checked= {medState === '1' ? true : false}
            data-missionid = {missionID}
            data-index= {index}
            onChange= {handleMedCheck}
          />中級
        </span>
        <span>
          <input
            type= "checkbox"
            checked= {advState === '1' ? true : false}
            data-missionid = {missionID}
            data-index= {index}
            onChange= {handleAdvCheck}
          />高級
        </span>
      </>
    )
  }

  const handleContent = () => {
    let list = []
    displayData.map((data, index) => {
      list.push(
        <>
          <TableContent key= {index}>
            <td>{data.MISSION_ID}</td>
            <td>
              <BigSquareInput value= {data.MISSION_NAME} data-index= {index} onChange={e => {handleReviseInfo(e, 'MISSION_NAME')}}/>
            </td>
            <td>{data.MISSION_LEVEL}</td>
            <td>
              <MissionInput value= {data.MISSION_WEIGHT} data-index= {index} onChange={e => {handleReviseInfo(e, 'MISSION_WEIGHT')}}/>                      
            </td>
            <td>
              <MissionInput value= {data.GEM_NUM} data-index= {index} onChange={e => {handleReviseInfo(e, 'GEM_NUM')}}/>
            </td>
            <td>{handleReviseType(index, data.MISSION_ID, data.GAME_TYPE)}</td>
            
            <td>
              <span>
                <input
                  type= "checkbox"
                  checked= {data.MISSION_STATE ? true : false}
                  data-index= {index}
                  data-missionid= {data.MISSION_ID}
                  onChange= {e => {handleState(e, data.MISSION_STATE)}}
                />開啟
              </span>
            </td>
          </TableContent>
        </>
      )
    })
    list.push(
      <tr>
        <td colSpan= "5"></td>
        <GameType>
          <span>
            <input type= "checkbox" checked= {leisureAll ? true : false} onChange= {handleCheckAllLeisure}/>休閒全選
          </span>
          <span>
            <input type= "checkbox" checked= {beginnerAll ? true : false} onChange= {handleCheckAllBeginner}/>初級全選
          </span>
          <span>
            <input type= "checkbox" checked= {medAll ? true : false} onChange= {handleCheckAllMed}/>中級全選
          </span>
          <span>
            <input type= "checkbox" checked= {advAll ? true : false} onChange= {handleCheckAllAdv}/>高級全選
          </span>
        </GameType>
        <td>
          <span>
            <input type= "checkbox" checked= {switchAll ? true : false} onChange= {handleCheckAllSwitch}/>開啟全選
          </span>
        </td>
      </tr>
    )
    
    return list
  }

  const handleRevise = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 86)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      setReviseMode(true)
    })
  }
  const handleSubmit = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      let reviseObjArr = []
      let keyName = Object.keys(dataCopy[0])
      dataCopy.map(ele => {
        let displayObj = displayData.find(displayEle => displayEle.MISSION_ID === ele.MISSION_ID)
        for (let i = 0; i < keyName.length; i++) {
          if (keyName[i] === 'MISSION_ID' || keyName[i] === 'MISSION_NAME') {
            if (displayObj[keyName[i]] !== ele[keyName[i]]) {
              reviseObjArr.push(displayObj)
            }
          } else {
            if (+displayObj[keyName[i]] !== +ele[keyName[i]]) {
              reviseObjArr.push(displayObj)
            }
          }
        }
      })
      if (!reviseObjArr.length) {
        alert('資料未更動  ʕ◍•́ᴥ•̀◍ʔ')
        setReviseMode(false)
        return
      }
      let checkRevisePass = []
      reviseObjArr.map(ele => {
        reviseDZBMissionIndividualSetting(+ele.ACTIVITY_ID, +ele.GAME_ID, ele.MISSION_ID, ele.MISSION_NAME,
        +ele.MISSION_WEIGHT, +ele.GEM_NUM, +ele.GAME_TYPE, +ele.MISSION_STATE).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          checkRevisePass.push(db.data.error)
          })
        }
      )
      setTimeout(() => {
        if (checkRevisePass.every(ele => !ele)) {
          alert('修改成功  ʕ๑´ㅂ`๑ʔ')
        }
      }, 500)
      getMissionSetting(+queryValue.activityID)
      setReviseMode(false)
    })
  }

  const handleCancel = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setReviseMode(false)
      setDisplayData(dataCopy)
    })
  }
  return (
    <>
      <BasicTable className="table table-hover table-bordered">
        <thead>
          <BasicRowTitle>
            <th scope="col">ID</th>
            <th scope="col">名稱</th>
            <th scope="col">任務難度</th>
            <th scope="col">任務權重</th>
            <th scope="col">獎勵鬥陣寶</th>
            <th scope="col">開放場次</th>
            <th scope="col">任務開關</th>
          </BasicRowTitle>
        </thead>
        <tbody>
          {
            reviseMode && displayData.length ?
              Array.isArray(displayData) ? handleContent() : <tr key= "noInfo"><td colSpan= "7">尚無資料</td></tr>
            
            :Array.isArray(displayData) && displayData.length ? (displayData.map((data, index) => {
              return (
                <TableContent key= {index}>
                  <td>{data.MISSION_ID} </td>
                  <td>{data.MISSION_NAME}</td>
                  <td>{data.MISSION_LEVEL}</td>
                  <td>{data.MISSION_WEIGHT}</td>
                  <td>{data.GEM_NUM}</td>
                  <GameType>{handleDisplayType(data.MISSION_ID, data.GAME_TYPE, index)}</GameType>
                  <td>
                    <span>
                      <input
                        type= "checkbox"
                        checked= {data.MISSION_STATE ? true : false}
                        disabled
                      />開啟
                    </span>
                  </td>
                </TableContent>
              )
            }))
              : <tr key= "noInfo"><td colSpan= "7">尚無資料</td></tr>
          }
        </tbody>
        <tfoot></tfoot>
      </BasicTable>
      <RBtn>
        <ReviseBtnM style= {reviseMode ? {display: 'none'} : {display: 'block'}} onClick= {handleRevise}>修改</ReviseBtnM>
        <InformBtnM style= {reviseMode ? {display: 'block'} : {display: 'none'}} onClick= {handleSubmit}>送出</InformBtnM>
        <CancelReviseBtnM style= {reviseMode ? {display: 'block'} : {display: 'none'}} onClick= {handleCancel}>取消修改</CancelReviseBtnM>
      </RBtn>
    </>
  )
}