import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import RevisePassword_2 from "./revisePassword_2"
import { login } from "../../../middleware/Api/publicApi.js"
import { setAuthToken, setAccessArr, setCharacterType } from "../../../middleware/utils.js"
import { BigSquareTitle, BigSquareInput, ModalBtn } from "../../../middleware/utilityStyle"

export default function RevisePassword(props) {
  const {
    show,
    handleReviseClose,
    change,
    setChange,
    handleChange,
    reviseFirst,
    loginAccount,
    loginPage
  } = props

  const [showDoubleCheck, setShowDoubleCheck] = useState(false);
  const handleDoubleCheckClose = () => {
    setShowDoubleCheck(false)
  }
  const handleDoubleCheckShow = () => {
    handleReviseClose()
    setShowDoubleCheck(true)
  }

  const handleTheSamePassword = () => {
    if (!loginPage) {
      if (!reviseFirst) {
        if (loginAccount !== change.oldAccount) {
          alert('與目前使用者不相符 ʕ ಠᴥಠ ʔ')
          setChange(prev => ({
            ...prev,
            oldAccount: ''
          }))
          return
        }
      }
    }

    if (!change.oldAccount || !change.oldPassword || !change.newPassword || !change.checkNewPassword) {
      alert('請輸入空白處⊙ w ⊙')
      return
    }
    
    if (change.oldPassword === change.newPassword) {
      alert('新密碼不可與原密碼相同 ʕﾉಠᴥಠʔﾉ')
      setChange(prev => ({
        ...prev,
        newPassword: '',
        checkNewPassword: ''
      }))
      return
    }

    if (change.newPassword === change.checkNewPassword) {
      login(change.oldAccount, change.oldPassword).then(db => {
        if (db.error) {
          alert('※ 登入失敗，相關問題請洽詢工程部')
          setChange(prev => ({
            ...prev,
            oldPassword: ''
          }))
          return
        }

        if(db.msg){
          alert(`※  ${db.msg}`)
          return
        }

        if (!db.error && !db.msg) {
          setAuthToken(db.data.token)
          setAccessArr(JSON.stringify(db.data.user.accessArr))
          if (!db.data.user.accessArr.find(ele => +ele.P_ID === 4)) {
            alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
            handleReviseClose()
            return
          }
          setCharacterType(JSON.stringify(db.data.user.type))
          handleDoubleCheckShow()
        }
      })
    } else {
      alert('※  新密碼兩者不相符')
      setChange(prev => ({
        ...prev,
        newPassword: '',
        checkNewPassword: ''
      }))
    }
  }

  const handleReturn = () => {
    setChange(prev => ({
      ...prev,
      oldAccount: '',
      oldPassword: '',
      newPassword: '',
      checkNewPassword: ''
    }))
    handleReviseClose()
  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleReturn}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{reviseFirst ? '第一次登入' : '修改密碼'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <BigSquareTitle>原帳號</BigSquareTitle>
                <td>
                  <BigSquareInput
                    placeholder="original account"
                    name="oldAccount"
                    value={change.oldAccount}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <BigSquareTitle>原密碼</BigSquareTitle>
                <td>
                  <BigSquareInput
                    type="password"
                    placeholder="original password"
                    name="oldPassword"
                    value={change.oldPassword}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <BigSquareTitle>新密碼</BigSquareTitle>
                <td>
                  <BigSquareInput
                    type="password"
                    placeholder="new password"
                    name="newPassword"
                    value={change.newPassword}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <BigSquareTitle>確認新密碼</BigSquareTitle>
                <td>
                  <BigSquareInput
                    type="password"
                    placeholder="check new password"
                    name="checkNewPassword"
                    value={change.checkNewPassword}
                    onChange={handleChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <ModalBtn>
          <Button variant="info" onClick={handleTheSamePassword}>確定</Button>
          <Button variant="light" onClick={handleReturn}>返回</Button>
        </ModalBtn>
      </Modal>

      <RevisePassword_2
        showDoubleCheck={showDoubleCheck}
        setShowDoubleCheck={setShowDoubleCheck}
        handleDoubleCheckClose={handleDoubleCheckClose}
        change={change}
        setChange={setChange}
      />
    </>
  )
}