import React, { useState, useEffect }from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import Box from '@mui/material/Box'
import {
  PULeftTitle,  
  DateTimeWrapper,
  PUSelect,
  TimeFormat
} from "../../../views/eventManagement/eventSetting/styledComponent"
import { ModalFormat, PUInput, ModalBtn } from "../../../middleware/utilityStyle"
import ConfirmPU from "./ConfirmPU"

export default function AddPU(props) {
  const {
    showAddRevise,
    handleAddReviseShow,
    handleAddReviseClose,
    addStatus,
    popData,
    typeName,
    setPanelInfo,
    setPopData
  } = props
  const history = useHistory()
  const [form, setForm] = useState({
    ACTIVITY_ID: '',
    ACTIVITY_TYPE: 1,
    ACTIVITY_NAME: '',
    START_DATE: null,
    END_DATE: null,
    STATE: 1,
    BAR_START: '',
    BAR_END: '',
    BAR_STATE: 0,
    BAR_TARGET_NUM: '',
    IF_RESET: 0,
    BAR_REWARD: 1
  })

  const handleForm = (e, key) => {
    setForm(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const [dateGroup, setDateGroup] = useState({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null
  })

  const [chargeDateGroup, setChargeDateGroup] = useState({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null
  })

  const cleanData = () => {
    setForm({
      ACTIVITY_ID: '',
      ACTIVITY_TYPE: 1,
      ACTIVITY_NAME: '',
      START_DATE: '',
      END_DATE: '',
      STATE: 1,
      BAR_START: '',
      BAR_END: '',
      BAR_STATE: 0,
      BAR_TARGET_NUM: 0,
      IF_RESET: 0,
      BAR_REWARD: 1
    })
    setDateGroup({
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null
    })
    setChargeDateGroup({
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null
    })
  }
  
  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if(!addStatus) {
        setForm(JSON.parse(JSON.stringify(popData)))
        setDateGroup({
          startDate: popData.START_DATE,
          startTime: popData.START_DATE,
          endDate: popData.END_DATE,
          endTime: popData.END_DATE
        })
        setChargeDateGroup({
          startDate: popData.BAR_START,
          startTime: popData.BAR_START,
          endDate: popData.BAR_END,
          endTime: popData.BAR_END
        })
      }
      if(addStatus) {
        cleanData()
      }
    })
  }, [popData, addStatus])

  const handleSubmit = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!form.ACTIVITY_ID) {
        alert('※  請輸入活動ID')
        return
      }
      if (isNaN(+form.ACTIVITY_ID)) {
        alert('※  活動ID欄位請輸入數字')
        setForm(prev => ({
          ...prev,
          ACTIVITY_ID: ''
        }))
        return
      }
      if (!form.ACTIVITY_NAME) {
        alert('※  請輸入活動名稱')
        return
      }
      
      for (let key in dateGroup) {
        if (!dateGroup[key]) {
          alert('※  開始及結束的日期、時間不可為空')
        return
        }
      }

      for (let key in chargeDateGroup) {
        if (!chargeDateGroup[key]) {
          alert('※  上架及下架的日期、時間不可為空')
        return
        }
      }

      let saveStartTime = new Date(
        new Date(dateGroup.startDate).getFullYear(),
        new Date(dateGroup.startDate).getMonth(),
        new Date(dateGroup.startDate).getDate(),
        new Date(dateGroup.startTime).getHours(),
        new Date(dateGroup.startTime).getMinutes()
        )

      let saveEndTime = new Date(
        new Date(dateGroup.endDate).getFullYear(),
        new Date(dateGroup.endDate).getMonth(),
        new Date(dateGroup.endDate).getDate(),
        new Date(dateGroup.endTime).getHours(),
        new Date(dateGroup.endTime).getMinutes()
      )
      if (saveStartTime.getTime() > saveEndTime.getTime()) {
        alert('※  結束時間不可早於開始時間')
        return
      } else {
        setForm((prev)=> ({
          ...prev,
          START_DATE: saveStartTime,
          END_DATE: saveEndTime
        }))
      }

      let BarStartTime = new Date(
        new Date(chargeDateGroup.startDate).getFullYear(),
        new Date(chargeDateGroup.startDate).getMonth(),
        new Date(chargeDateGroup.startDate).getDate(),
        new Date(chargeDateGroup.startTime).getHours(),
        new Date(chargeDateGroup.startTime).getMinutes()
        )

      let BarEndTime = new Date(
        new Date(chargeDateGroup.endDate).getFullYear(),
        new Date(chargeDateGroup.endDate).getMonth(),
        new Date(chargeDateGroup.endDate).getDate(),
        new Date(chargeDateGroup.endTime).getHours(),
        new Date(chargeDateGroup.endTime).getMinutes()
      )
      if (BarStartTime.getTime() > BarEndTime.getTime()) {
        alert('※  下架時間不可早於上架時間')
        return
      } else {
        setForm((prev)=> ({
          ...prev,
          BAR_START: BarStartTime,
          BAR_END: BarEndTime
        }))
      }

      handleAddReviseClose()
      handleConfirmShow()
    })    
  }

  const handleBack = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleAddReviseClose()
      setTimeout(() => {
        if (addStatus) {
          cleanData()
        }
        if (!addStatus) {
          setForm(JSON.parse(JSON.stringify(popData)))
          setDateGroup({
            startDate: popData.START_DATE,
            startTime: popData.START_DATE,
            endDate: popData.END_DATE,
            endTime: popData.END_DATE
          })
          setChargeDateGroup({
            startDate: popData.BAR_START,
            startTime: popData.BAR_START,
            endDate: popData.BAR_END,
            endTime: popData.BAR_END
          })
        }
      }, 500)
    })
  }

  const handleOnHide = () => {
    handleAddReviseClose()
    if (addStatus) {
      cleanData()
    }
  }

  const [showConfirm, setConfirmShow] = useState(false)
  const handleConfirmClose = () => setConfirmShow(false)
  const handleConfirmShow = () => setConfirmShow(true)

  function handleType() {
    let list = []
    for (let i = 0; i < typeName.length; i++) {
      list.push(<option value= {typeName[i].type}>{typeName[i].name}</option>)
    }
    return list
  }
  return (
    <>
      <ModalFormat
        show={showAddRevise}
        onHide={handleOnHide}
        backdrop="static"
        keyboard={false}
        size= "lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{addStatus ? '新增' : '修改'}活動配置</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <PULeftTitle>活動ID</PULeftTitle>
                <td>
                  <PUInput placeholder= "請輸入活動ID" value= {form.ACTIVITY_ID} onChange= {e => {handleForm(e, 'ACTIVITY_ID')}}
                    disabled= {addStatus ? false : true} style= {addStatus ? {} : {cursor: 'not-allowed'}}
                  />
                </td>
              </tr>
              <tr>
                <PULeftTitle>類型</PULeftTitle>
                <td>
                  <PUSelect value= {form.ACTIVITY_TYPE} onChange= {e => {handleForm(e, 'ACTIVITY_TYPE')}}>
                    {handleType()}
                  </PUSelect>
                </td>
              </tr>
              <tr>
                <PULeftTitle>活動名稱</PULeftTitle>
                <td>
                  <PUInput placeholder= "請輸入活動名稱" value= {form.ACTIVITY_NAME} onChange= {e => {handleForm(e, 'ACTIVITY_NAME')}}
                    disabled= {addStatus ? false : true} style= {addStatus ? {} : {cursor: 'not-allowed'}}
                  />
                </td>
              </tr>
              <tr>
                <PULeftTitle>活動開始時間</PULeftTitle>
                <td>
                  <DateTimeWrapper>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label= "日期"
                        value={dateGroup.startDate}
                        onChange={(newValue) => {
                          setDateGroup(prev => ({
                            ...prev,
                            startDate: newValue
                          }))
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇日期'
                          return (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <PUInput id= "datePicker1" ref={inputRef} {...inputProps} />
                              <div id="dateBtn1">{InputProps?.endAdornment}</div>
                            </Box>
                          )
                        }}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="時間"
                        value={dateGroup.startTime}
                        onChange={(newValue) => {
                          setDateGroup(prev => ({
                            ...prev,
                            startTime: newValue
                          }))
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇時間'
                          return (
                            <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                              <PUInput id= "timePicker1" ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </TimeFormat>
                          )
                        }}
                      />
                    </LocalizationProvider>
                  </DateTimeWrapper>
                </td>
              </tr>
              <tr>
                <PULeftTitle>活動結束時間</PULeftTitle>
                <td>
                  <DateTimeWrapper>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label= "日期"
                        value={dateGroup.endDate}
                        onChange={(newValue) => {
                          setDateGroup(prev => ({
                            ...prev,
                            endDate: newValue
                          }))
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇日期'
                          return (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <PUInput id= "datePicker2" ref={inputRef} {...inputProps} />
                              <div id="dateBtn2">{InputProps?.endAdornment}</div>
                            </Box>
                          )
                        }}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="時間"
                        value={dateGroup.endTime}
                        onChange={(newValue) => {
                          setDateGroup(prev => ({
                            ...prev,
                            endTime: newValue
                          }))
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇時間'
                          return (
                            <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                              <PUInput id= "timePicker2" ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </TimeFormat>
                          )
                        }}
                      />
                    </LocalizationProvider>
                  </DateTimeWrapper>
                </td>
              </tr>
              <tr>
                <PULeftTitle>活動狀態</PULeftTitle>
                <td>
                  <PUSelect value= {form.STATE} onChange= {e => {handleForm(e, 'STATE')}}>
                    <option value= "2">開啟</option>
                    <option value= "1">關閉</option>
                  </PUSelect>
                </td>
              </tr>
              <tr>
                <PULeftTitle>集氣活動<br/>顯示開關</PULeftTitle>
                <td>
                  <PUSelect value= {form.BAR_STATE} onChange= {e => {handleForm(e, 'BAR_STATE')}}>
                    <option value= "1">開啟</option>
                    <option value= "0">關閉</option>
                  </PUSelect>
                </td>
              </tr>
              <tr>
                <PULeftTitle>集氣活動<br/>上架時間</PULeftTitle>
                <td>
                  <DateTimeWrapper>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label= "日期"
                        value={chargeDateGroup.startDate}
                        onChange={(newValue) => {
                          setChargeDateGroup(prev => ({
                            ...prev,
                            startDate: newValue
                          }))
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇日期'
                          return (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <PUInput id= "datePicker1" ref={inputRef} {...inputProps} />
                              <div id="dateBtn3">{InputProps?.endAdornment}</div>
                            </Box>
                          )
                        }}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="時間"
                        value={chargeDateGroup.startTime}
                        onChange={(newValue) => {
                          setChargeDateGroup(prev => ({
                            ...prev,
                            startTime: newValue
                          }))
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇時間'
                          return (
                            <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                              <PUInput id= "timePicker3" ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </TimeFormat>
                          )
                        }}
                      />
                    </LocalizationProvider>
                  </DateTimeWrapper>
                </td>
              </tr>
              <tr>
                <PULeftTitle>集氣活動<br/>下架時間</PULeftTitle>
                <td>
                  <DateTimeWrapper>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label= "日期"
                        value={chargeDateGroup.endDate}
                        onChange={(newValue) => {
                          setChargeDateGroup(prev => ({
                            ...prev,
                            endDate: newValue
                          }))
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇日期'
                          return (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <PUInput id= "datePicker4" ref={inputRef} {...inputProps} />
                              <div id="dateBtn4">{InputProps?.endAdornment}</div>
                            </Box>
                          )
                        }}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="時間"
                        value={chargeDateGroup.endTime}
                        onChange={(newValue) => {
                          setChargeDateGroup(prev => ({
                            ...prev,
                            endTime: newValue
                          }))
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇時間'
                          return (
                            <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                              <PUInput id= "timePicker4" ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </TimeFormat>
                          )
                        }}
                      />
                    </LocalizationProvider>
                  </DateTimeWrapper>
                </td>
              </tr>
              <tr>
                <PULeftTitle>集氣值上限</PULeftTitle>
                <td>
                  <PUInput placeholder= "請輸入數字" value= {form.BAR_TARGET_NUM} onChange= {e => {handleForm(e, 'BAR_TARGET_NUM')}}/>
                  <PUSelect value= {form.IF_RESET} onChange= {e => {handleForm(e, 'IF_RESET')}} style= {{marginLeft: '55px'}}>
                    <option value= "0">不重置</option>
                    <option value= "1">重置</option>
                  </PUSelect>
                </td>
                
              </tr>
              <tr>
                <PULeftTitle>集氣達標獎勵</PULeftTitle>
                <td>
                  <PUSelect value= {form.BAR_REWARD} onChange= {e => {handleForm(e, 'BAR_REWARD')}}>
                    <option value= "1">抽獎序號</option>
                  </PUSelect>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <ModalBtn>
          <Button variant="info" onClick= {handleSubmit}>確定</Button>
          <Button variant="secondary" onClick= {handleBack}>返回</Button>
        </ModalBtn>
      </ModalFormat>
      
      <ConfirmPU
        showConfirm= {showConfirm}
        handleAddReviseShow= {handleAddReviseShow}
        handleConfirmClose= {handleConfirmClose}
        addStatus= {addStatus}
        form= {form}
        setPanelInfo= {setPanelInfo}
        setPopData= {setPopData}
        setForm= {setForm}
        setDateGroup= {setDateGroup}
        setChargeDateGroup= {setChargeDateGroup}
        cleanData = {cleanData}
      />
    </>
  )
}