import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import AddAndRevise from "../../../components/popUpWindow/gameIdSetting/AddAndRevise"
import Delete from "../../../components/popUpWindow/gameIdSetting/Delete"
import QuickMatch from "../../../components/popUpWindow/gameIdSetting/QuickMatch"
import { getGameList } from "../../../middleware/Api/operation/gameIdSettingApi"
import { getAccessArr } from "../../../middleware/utils"
import {
  InputBarBg,
  InputBarRWD,
  Input,
  GameWrapper,
  Dropdown,
  BtnGroup,
  BTN
} from "./styledComponent.js"
import {
  Read,
  Unread
} from "../orderManagement/styledComponent.js"
import {
  ClickRange,
  SearchButton,
  ResetButton,
  BasicTable,
  BasicRowTitle,
  TableContent,
  TableBtnGroup,
  NewBtn,
  ReviseBtn,
  DeleteBtn
} from "../../../middleware/utilityStyle.js"

export default function gameIdSetting() {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const history = useHistory()
  const [panelInfo, setPanelInfo] = useState([])
  const [dataCopy, setDataCopy] = useState([])
  const [nameArr, setNameArr] = useState([])
  const [popData, setPopData] = useState({
    GAME_ID: '',
    GAME_NAME: ''
  })
  const [editData, setEditData] = useState({
    GAME_ID: 7001,
    GAME_NAME: ""
  })
  const [change, setChange] = useState({
    gameId: '',
    gameName: 'all'
  })
  const handleChange = (e, key) => {
    setChange(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const [edit, setEdit] = useState(0)
  const [openState, setOpenState] = useState("")

  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      getGameList().then(db => {
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if (Array.isArray(db.data) && db.data.length) {
          let nArr = []
          db.data.map(ele => nArr.push(ele.GAME_NAME))
          setNameArr(nArr)
          setPanelInfo(db.data)
          setDataCopy(db.data)
        }
      })
    })
  }, [])

  const handleRadio = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPopData(dataCopy.find(ele => +ele.GAME_ID === +e.target.value))
    })
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      if (isNaN(+change.gameId)) {
        alert('※  遊戲欄位請輸入數字')
        setChange(prev => ({
          ...prev,
          gameId: ''
        }))
        return
      }
      if (!+change.gameId && !change.gameName) {
        if (!dataCopy.length) {
          alert(`※  ${errorCode.RECORD_NOT_FOUND.memo}`)
        } else {
          setPanelInfo(dataCopy)
        }
      }
      let found
      if (change.gameId) {
        if (change.gameName !== 'all') {
          let foundByID = dataCopy.filter(ele => +ele.GAME_ID === +change.gameId)
          let foundByName = dataCopy.filter(ele => ele.GAME_NAME === change.gameName)
          if (foundByID.length && foundByName.length) {
            if (foundByID[0].GAME_NAME === change.gameName && foundByName[0].GAME_ID === +change.gameId) { //V
              found = foundByID
            }
          }
          if (!foundByID.length || !foundByName.length) {
            alert(`※  ${errorCode.RECORD_NOT_FOUND.memo}`)
              setPanelInfo(dataCopy)
              return
          }
        }
        if (change.gameName === 'all') {
          found = dataCopy.filter(ele => +ele.GAME_ID === +change.gameId)
        }
      }

      if (!change.gameId) {
        if (change.gameName !== 'all') {
          found = dataCopy.filter(ele => ele.GAME_NAME === dataCopy.find(ele => ele.GAME_NAME === change.gameName).GAME_NAME)
        }
        if (change.gameName === 'all') {
          setPanelInfo(dataCopy)
          return
        }
      }
      if(found) {
        setPanelInfo(found)
      } else {
        alert(`※  ${errorCode.RECORD_NOT_FOUND.memo}`)
        setPanelInfo(dataCopy)
      }
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({
        gameId: '',
        gameName: ''
      })
    })
  }
  const [addStatus, setAddStatus] = useState(false)
  const [showAddAndRevise, setShowAddAndRevise] = useState(false)
  const handleAddAndReviseClose = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setShowAddAndRevise(false)
    })
  }
  const handleAddAndReviseShow = () => setShowAddAndRevise(true)

  const handleAdd = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 45)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      setAddStatus(true)
      handleAddAndReviseShow()
    })
  }

  const handleRevise = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 43)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (!+popData.GAME_ID) {
        alert('※  請選擇欲修改的項目')
        return
      }
      setAddStatus(false)
      handleAddAndReviseShow()
    })
  }

  const [showDelete, setDeleteShow] = useState(false)
  const handleDeleteClose = () => setDeleteShow(false)
  const handleDeleteShow = () => setDeleteShow(true)

  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 44)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (!popData.GAME_ID) {
        alert('※  請選擇欲修改的項目')
        return
      }
      handleDeleteShow()
    })
  }

  const [showQuickMatch, setQuickMatchShow] = useState(false)
  const handleQuickMatchShow = () => setQuickMatchShow(true)
  const handleQuickMatchClose = () => setQuickMatchShow(false)

  const handleQuickMatch = (gameID, gameName) => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 90)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }

      setEditData({
        GAME_ID: gameID,
        GAME_NAME: gameName
      })
      setEdit(1)
      handleQuickMatchShow()
    })
  }
  
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch()
    }
  };

  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <InputBarRWD>
          <div>
            遊戲ID<Input placeholder="請輸入遊戲ID" value={change.gameId} onChange= {e => {handleChange(e, 'gameId')}} onKeyDown={handleKeyDown} />
          </div>

          <GameWrapper>
            <span>遊戲</span>
            <Dropdown id= "item1" value={change.gameName} onChange= {e => {handleChange(e, "gameName")}}>
              <option value="all">全部</option>
              {
                Array.isArray(nameArr) && nameArr.length ?
                  nameArr.map((ele, index) => {
                    return <option value= {ele} key= {index}>{ele}</option>
                  })
                : ''
              }
            </Dropdown>
          </GameWrapper>

        </InputBarRWD>
        <BtnGroup>
          <SearchButton onClick={handleSearch}>搜索</SearchButton>
          <ResetButton onClick={handleReset}>重置</ResetButton>
        </BtnGroup>
      </InputBarBg>

      <TableBtnGroup>
        <BasicTable className="table table-hover table-bordered">
          <thead>
            <BasicRowTitle>
              <th>
                <input type= "radio" disabled/>
              </th>
              <th>遊戲ID</th>
              <th>遊戲名稱</th>
              <th>快速配對狀態</th>
              <th>快速配對設定</th>
            </BasicRowTitle>
          </thead>
          <tbody>
            {
              Array.isArray(panelInfo) && panelInfo.length > 0 ?
              panelInfo.map((ele, index) => {
                return (
                  <TableContent key= {index}>
                    <td>
                      <input type= "radio" name= "gameIdList" value= {ele.GAME_ID} onChange= {handleRadio}/>
                    </td>
                    <td>{ele.GAME_ID}</td>
                    <td>{ele.GAME_NAME}</td>
                    {
                      openState[ele.GAME_ID] ? <Read>開啟</Read>
                      : <Unread>關閉</Unread>
                    }
                    <td><ClickRange onClick={() => handleQuickMatch(ele.GAME_ID, ele.GAME_NAME)}>修改</ClickRange></td>
                  </TableContent>
                )
              })
              : <tr key= "noInfo"><td colSpan= "5">尚無資料</td></tr>
            }
          </tbody>
        </BasicTable>

        <BTN>
          <NewBtn onClick= {handleAdd}>新增</NewBtn>
          <ReviseBtn onClick= {handleRevise}>修改</ReviseBtn>
          <DeleteBtn onClick= {handleDelete}>刪除</DeleteBtn>
        </BTN>
      </TableBtnGroup>
      
      <AddAndRevise
        showAddAndRevise= {showAddAndRevise}
        handleAddAndReviseClose= {handleAddAndReviseClose}
        addStatus= {addStatus}
        popData= {popData}
        setDataCopy= {setDataCopy}
        setPanelInfo= {setPanelInfo}
        setNameArr= {setNameArr}
      />

      <Delete
        showDelete= {showDelete}
        handleDeleteClose= {handleDeleteClose}
        popData= {popData}
        setDataCopy= {setDataCopy}
        setPanelInfo= {setPanelInfo}
      />

      <QuickMatch
        showQuickMatch = {showQuickMatch}
        handleQuickMatchClose = {handleQuickMatchClose}
        editData = {editData}
        edit = {edit}
        setEdit = {setEdit}
        setOpenState = {setOpenState}
        panelInfo = {panelInfo}
      />
    </>
  )
}