import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import { BarBG, RangePack, DayRange, BasicInput, BtnPosition } from '../../../middleware/utilityStyle'

export const InputBarBg = styled(BarBG)`
  @media screen and (max-width: 1760px) {
  }
`

export const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 1760px) {
    flex-direction: column;
    & div {
      display: flex;
      justify-content: center;
    }
  }
`

export const SecondRow = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 1760px) {
    flex-direction: column;
    justify-content: center;
  }
`

export const InputGroup = styled.div`
  margin-left: 20px;
  @media screen and (max-width: 1760px) {
    margin-left: 0px;
    margin-top: 10px;
  }
`

export const BlankRWD = styled.div`
  @media screen and (max-width: 1760px) {
    margin-top: 10px;
  }
`

export const Blank = styled(BasicInput)`
  width: 250px;
  margin-left: 20px;
`

export const Dropdown = styled.select`
  margin-left: 20px;
  border: 1px solid black;
  border-radius: 5px;
  height: 28px;
  width: 250px;
`

export const RangeWrapper = styled(RangePack)`
  @media screen and (max-width: 1760px) {
    flex-direction: column;
  }
`

export const DatePickerInput = styled(BasicInput)`
  width: 250px;
  @media screen and (max-width: 1760px) {
    width: 150px;
  }
`

export const RangeGroup = styled(DayRange)`
  @media screen and (max-width: 1760px) {
    margin-top: 10px;
  }
`

export const BtnGroup = styled.div`
  @media screen and (max-width: 1760px) {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
`

export const Read = styled.td`
  color: #00cc00;
`

export const Unread = styled.td`
  color: red;
`

export const PUCol = styled.td`
  vertical-align: middle !important;
`

export const PUDropdown = styled.select`
  border: 1px solid black;
  border-radius: 5px;
  height: 28px;
  width: 100px;
`

export const PUInput = styled(BasicInput)`
  width: 125px;
  height: 28px;
`

export const Btn = styled(Modal.Footer)`
  display: flex;
  justify-content: flex-end !important;
`

export const BottomBtn = styled(BtnPosition)`
  margin-top: 30px;
  width: 700px;
`