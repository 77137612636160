import React from "react"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { ModalBtn, ModalText } from "../../../middleware/utilityStyle"

export default function CancelPU(props) {
  const {
    history,
    showCancelPU,
    handleCancelPUClose,
    handleInfoPUShow,
    handleCleanData,
    setChosenTarget,
    timeGroup,
    specificTime,
    setChange,
    setCheckPeriod
  } = props

  const handleOnHide = () => {
    handleCancelPUClose()
      setTimeout(() => {
        handleCleanData()
        setChosenTarget({
          ORDER_ID: '',
          STATE: '',
          TYPE: '',
          STATE: 1,
          BEGIN_DATE: '',
          EXPIRE_DATE: '',
          WEEK: '1,1,1,1,1,1,1',
          INTERVAL_START1: '',
          INTERVAL_END1: '',
          INTERVAL_START2: '',
          INTERVAL_END2: '',
          INTERVAL_START3: '',
          INTERVAL_END3: ''
        })
        for (let key in timeGroup) {
          timeGroup[key] = null
        }
        for (let key in specificTime) {
          specificTime[key] = null
        }
        setChange({specificDay: '', periodStatus: ''})
        setCheckPeriod({period1: false, period2: false, period3: false})
      }, 500)
  }

  const handleCancel = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleOnHide()
    })
  }

  const handleRefuseCancel = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleCancelPUClose()
      handleInfoPUShow()
    })
  }
  return (
    <Modal
      show={showCancelPU}
      onHide={handleOnHide}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>取消確認</Modal.Title>
      </Modal.Header>
      <ModalText>
        確定要取消變更資料嗎?
      </ModalText>
      <ModalBtn>
        <Button variant="info" onClick={handleCancel}>確認</Button>
        <Button variant="secondary" onClick={handleRefuseCancel}>取消</Button>
      </ModalBtn>
    </Modal>
  )
}