import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import Box from '@mui/material/Box'
import { Button } from "react-bootstrap"
import {
  DetailInput,
  SendTimeWrapper,
  TimeFormat,
  TimeLimited,
  LimitGroup,
  Limit,
  LimitTimes,
  PUDropdown,
  PopFrame,
  Avatar,
  NoImg,
  DetailTitle,
  LabelBtn
} from "../../../views/operation/itemDetails/styledComponent"
import { ModalFormat, ModalBtn, PopDetail, BasicHeadTitle } from "../../../middleware/utilityStyle"
import DeleteHint from "../../../components/popUpWindow/itemSetting/deleteHint"
import HandleEndStatus from "../../../components/popUpWindow/itemDetails/handleEndStatus"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { addDetailList, detailList, reviseDetailList, uploadItem } from "../../../middleware/Api/operation/itemSettingApi"
import { getAccessArr } from "../../../middleware/utils"

export default function DetailSetting(props) {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const history = useHistory()
  const {
    showDetailSetting,
    handleDetailSettingClose,
    targetObj,
    clickStatus,
    setClickStatus,
    calender,
    switchDelete,
    switchSave,
    queryValue,
    detailCopy,
    setDetailCopy,
    setDetailInfo
  } = props

  function getDetail(classID) {
    detailList(classID).then(db => {
      if (db.error === errorCode.INCORRECT_DATA.code) {
        alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.INCORRECT_FORMAT.code) {
        alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.EXCEPTION.code) {
        alert(`※  ${errorCode.EXCEPTION.memo}`)
        return
      }
      if (db.error === errorCode.NULL_DATA.code) {
        alert(`※  ${errorCode.NULL_DATA.memo}`)
        return
      }
      setDetailCopy(db.data)
      setDetailInfo(db.data)
    })
  }

  const [targetInfo, setTargetInfo] = useState({
    ITEM_ID: 0,
    ORDER_ID: '',
    ITEM_IMG: '',
    ITEM_NAME: '',
    PRICE_TYPE: 2,
    ITEM_PRICE: '',
    ITEM_TAG: 0,
    BEGIN_DATE: '',
    EXPIRE_DATE: '',
    PERSON_DAILY_LIMIT: '',
    TOTAL_NUM: '',
    NOW_NUM: '',
    OPEN_EXCHANGE: 0,
    ITEM_CLASS: '',
    ITEM_DETAIL: '',
    IS_PERMANENT: 1,
  })
  const [calenderInfo, setCalenderInfo] = useState({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null
  })

  const [limitState, setLimitState] = useState({perDay: 0, server: 0})
  const [deleteDetail, setDeleteDetail] = useState(false)

  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setTargetInfo(JSON.parse(JSON.stringify(targetObj)))
      if (clickStatus !== 'add') {
        setCalenderInfo({
          startDate: targetObj.BEGIN_DATE,
          startTime: targetObj.BEGIN_DATE,
          endDate: targetObj.EXPIRE_DATE,
          endTime: targetObj.EXPIRE_DATE
        })
        setLimitState({
          perDay: +targetObj.PERSON_DAILY_LIMIT ? 1 : 0,
          server: +targetObj.TOTAL_NUM ? 1 : 0
        })
        if (clickStatus === 'revise' && +targetObj.OPEN_EXCHANGE === 2) {
          setClickStatus('check')
          setTimeout(()=> {
            alert('※  此商品兌換已結束，無法進行修改')
          },200)
          return
        }
      } else {
        setCalenderInfo(JSON.parse(JSON.stringify(calender)))
        setLimitState({
          perDay: 0,
          server: 0
        })
      }
      setDeleteDetail(true)
    })
  }, [targetObj, calender, detailCopy])

  const [imgData, setImgData] = useState()
  const [fileData, setFileData] = useState(false)
  const handleUploadImg = e => {
    e.preventDefault()
    const file = e.target.files[0]
    if(file) {
      const formData = new FormData()
      formData.append('file', file)
      setImgData(formData)
      setFileData(true)
      const imgReader = new FileReader()
      imgReader.readAsDataURL(file)
      imgReader.onload = e => {
        setTargetInfo(prev => ({
          ...prev,
          ITEM_IMG: e.target.result
        }))
      }
    }
  }

  const [showDeleteHint, setDeleteHintShow] = useState(false)
  const handleDeleteHintClose = () => setDeleteHintShow(false)
  const handleDeleteHintShow = () => setDeleteHintShow(true)

  const [showEnd, setEndShow] = useState(false)
  const handleEndClose = () => setEndShow(false)
  const handleEndShow = () => setEndShow(true)

  const handleTarget = (e, key) => {
    setTargetInfo((prev) => ({
      ...prev,
        [key]: e
    }))
  }

  const handleTerm = (e, key) => {
    if (+e === 1) {
      setTargetInfo((prev) => ({
        ...prev,
        [key]: e,
        BEGIN_DATE: null,
        EXPIRE_DATE: null
      }))

      for (let key in calenderInfo) {
        calenderInfo[key] = null
      }
    }
  }

  const handleLimit = (e, key) => {
    setLimitState(prev => ({
      ...prev,
      [key]: e
    }))
  }

  const handlePerDayClose = () => {
    setTargetInfo(prev => ({
      ...prev,
      PERSON_DAILY_LIMIT: ''
    }))
  }

  const handleServerClose = () => {
    setTargetInfo((prev) => ({
      ...prev,
      TOTAL_NUM: ''
    }))
  }

  const handlePopUpClose = () => {
    handleDetailSettingClose()
    setTimeout(() => {
      setTargetInfo({
        ITEM_ID: 0,
        ORDER_ID: '',
        ITEM_IMG: '',
        ITEM_NAME: '',
        PRICE_TYPE: 2,
        ITEM_PRICE: '',
        ITEM_TAG: 0,
        BEGIN_DATE: '',
        EXPIRE_DATE: '',
        PERSON_DAILY_LIMIT: '',
        TOTAL_NUM: '',
        NOW_NUM: '',
        OPEN_EXCHANGE: 0,
        ITEM_CLASS: '',
        ITEM_DETAIL: '',
        IS_PERMANENT: ''
      })
      setFileData(false)
      for (let key in calenderInfo) {
        calenderInfo[key] = ''
      }
    }, 500)
  }

  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 38)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      handleDetailSettingClose()
      handleDeleteHintShow()
      for (let key in calenderInfo) {
        calenderInfo[key] = ''
      }
    })
  }

  function dateMaker(startDate, startTime, endDate, endTime) {
    let SSet = ''
    let ESet = ''
    if (!startDate || !startTime) {
      alert('※  請輸入開始日期及時間')
      return
    }
    if (!endDate || !endTime) {
      alert('※  請輸入結束日期及時間')
      return
    }
    let SDate = new Date(startDate).toString().split(' ').slice(0, 4).join(' ')
    let STime = new Date(startTime).toString().split(' ').slice(4).join(' ')
    let EDate = new Date(endDate).toString().split(' ').slice(0, 4).join(' ')
    let ETime = new Date(endTime).toString().split(' ').slice(4).join(' ')
  
    SSet = `${SDate} ${STime}`
    ESet = `${EDate} ${ETime}`
    if (new Date(SSet).getTime() >= new Date(ESet).getTime()) {
      alert('※  結束時間不可早於或等於開始日期')
      return
    }
    return {SSet, ESet}
  }

  const handleDeleteItem = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setTargetInfo(prev => ({
        ...prev,
        ITEM_IMG: ''
      }))
    })
  }

  const handleSave = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (clickStatus === 'add') {
        if (!userPID.find(ele => +ele === 36)) {
          alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
          return
        }
      }

      if (clickStatus === 'revise') {
        if (!userPID.find(ele => +ele === 37)) {
          alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
          return
        }
      }
      
      if (!targetInfo.ORDER_ID) {
        alert('※  請填入排序欄位')
        return
      }
      if (isNaN(+targetInfo.ORDER_ID)) {
        alert('※  排序欄位請填入數字')
        return
      }
      if (clickStatus === 'add') {
        if (detailCopy.find(ele => +ele.ORDER_ID === +targetInfo.ORDER_ID)) {
          alert('※  排序不可重複')
          setTargetInfo(prev => ({
            ...prev,
            ORDER_ID: ''
          }))
          return
        }
      }
      
      if (clickStatus === 'revise') {
        if (+targetObj.ORDER_ID !== +targetInfo.ORDER_ID) {
          if (detailCopy.find(ele => +ele.ORDER_ID === +targetInfo.ORDER_ID)) {
            alert('※  排序不可重複')
          setTargetInfo(prev => ({
            ...prev,
            ORDER_ID: +targetObj.ORDER_ID
          }))
          return
          }
        }
      }

      if (targetInfo.ITEM_NAME.length > 12) {
        alert('※  名稱欄位字數不符')
        return
      }
      if (targetInfo.ITEM_NAME.indexOf(' ') >= 0) {
        alert('※  名稱欄位不可包含空白格')
        setTargetInfo(prev => ({
          ...prev,
          ITEM_NAME: ''
        }))
        return
      }
      if (!targetInfo.ITEM_PRICE) {
        alert('※  請填入價格欄位')
        return
      }
      if (isNaN(+targetInfo.ITEM_PRICE)) {
        alert('※  價格欄位請填入數字')
        return
      }
      if (+targetInfo.OPEN_EXCHANGE === 2) {
        handleDetailSettingClose()
        handleEndShow()
        return
      }

      if (clickStatus === 'add') {
        if (fileData) {
          uploadItem(imgData).then(db => {
            if (db.error === errorCode.INCORRECT_DATA.code) {
              alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.INCORRECT_FORMAT.code) {
              alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            setTargetInfo(prev => ({
              ...prev,
              ITEM_IMG: db.data.url
            }))
            let targetUrl = db.data.url
            
            if (+targetInfo.IS_PERMANENT === 0) {
              let result = dateMaker(calenderInfo.startDate, calenderInfo.startTime, calenderInfo.endDate, calenderInfo.endTime)
              setTargetInfo(prev => ({
                ...prev,
                BEGIN_DATE: new Date(result.SSet),
                EXPIRE_DATE: new Date(result.ESet)
              }))
              addDetailList(+targetInfo.ORDER_ID, targetUrl, targetInfo.ITEM_NAME, +targetInfo.PRICE_TYPE, +targetInfo.ITEM_PRICE,
                +targetInfo.ITEM_TAG, new Date(result.SSet), new Date(result.ESet), +targetInfo.PERSON_DAILY_LIMIT,
                +targetInfo.TOTAL_NUM, targetInfo.ITEM_DETAIL, +targetInfo.OPEN_EXCHANGE, +queryValue.CLASS_ID,
                +targetInfo.IS_PERMANENT).then(db => {
                  if (db.error === errorCode.INCORRECT_DATA.code) {
                    alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                    return
                  }
                  if (db.error === errorCode.INCORRECT_FORMAT.code) {
                    alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                    return
                  }
                  if (db.error === errorCode.EXCEPTION.code) {
                    alert(`※  ${errorCode.EXCEPTION.memo}`)
                    return
                  }
                  getDetail(+queryValue.CLASS_ID)
                  handleDetailSettingClose()
                  setTimeout(() => {
                    alert('※  商品細項設定儲存成功')
                  }, 200)
                })
            }
            if (+targetInfo.IS_PERMANENT === 1) {
              addDetailList(+targetInfo.ORDER_ID, targetUrl, targetInfo.ITEM_NAME, +targetInfo.PRICE_TYPE, +targetInfo.ITEM_PRICE,
                +targetInfo.ITEM_TAG, targetInfo.BEGIN_DATE, targetInfo.EXPIRE_DATE, +targetInfo.PERSON_DAILY_LIMIT,
                +targetInfo.TOTAL_NUM, targetInfo.ITEM_DETAIL, +targetInfo.OPEN_EXCHANGE, +queryValue.CLASS_ID,
                +targetInfo.IS_PERMANENT).then(db => {
                  if (db.error === errorCode.INCORRECT_DATA.code) {
                    alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                    return
                  }
                  if (db.error === errorCode.INCORRECT_FORMAT.code) {
                    alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                    return
                  }
                  if (db.error === errorCode.EXCEPTION.code) {
                    alert(`※  ${errorCode.EXCEPTION.memo}`)
                    return
                  }
                  getDetail(+queryValue.CLASS_ID)
                  handleDetailSettingClose()
                  setTimeout(() => {
                    alert('※  商品細項設定儲存成功')
                  }, 200)
                })
            }
          })
        }
      
        if (!fileData) {
          if (+targetInfo.IS_PERMANENT === 0) {
            let result = dateMaker(calenderInfo.startDate, calenderInfo.startTime, calenderInfo.endDate, calenderInfo.endTime)
            setTargetInfo(prev => ({
              ...prev,
              BEGIN_DATE: new Date(result.SSet),
              EXPIRE_DATE: new Date(result.ESet)
            }))
            addDetailList(+targetInfo.ORDER_ID, targetInfo.ITEM_IMG, targetInfo.ITEM_NAME, +targetInfo.PRICE_TYPE, +targetInfo.ITEM_PRICE,
              +targetInfo.ITEM_TAG, new Date(result.SSet), new Date(result.ESet), +targetInfo.PERSON_DAILY_LIMIT,
              +targetInfo.TOTAL_NUM, targetInfo.ITEM_DETAIL, +targetInfo.OPEN_EXCHANGE, +queryValue.CLASS_ID,
              +targetInfo.IS_PERMANENT).then(db => {
                if (db.error === errorCode.INCORRECT_DATA.code) {
                  alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                  return
                }
                if (db.error === errorCode.INCORRECT_FORMAT.code) {
                  alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                  return
                }
                if (db.error === errorCode.EXCEPTION.code) {
                  alert(`※  ${errorCode.EXCEPTION.memo}`)
                  return
                }
                getDetail(+queryValue.CLASS_ID)
                handleDetailSettingClose()
                setTimeout(() => {
                  alert('※  商品細項設定儲存成功')
                }, 200)
              })
          }
          if (+targetInfo.IS_PERMANENT === 1) {
            addDetailList(+targetInfo.ORDER_ID, targetInfo.ITEM_IMG, targetInfo.ITEM_NAME, +targetInfo.PRICE_TYPE, +targetInfo.ITEM_PRICE,
              +targetInfo.ITEM_TAG, targetInfo.BEGIN_DATE, targetInfo.EXPIRE_DATE, +targetInfo.PERSON_DAILY_LIMIT,
              +targetInfo.TOTAL_NUM, targetInfo.ITEM_DETAIL, +targetInfo.OPEN_EXCHANGE, +queryValue.CLASS_ID,
              +targetInfo.IS_PERMANENT).then(db => {
                if (db.error === errorCode.INCORRECT_DATA.code) {
                  alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                  return
                }
                if (db.error === errorCode.INCORRECT_FORMAT.code) {
                  alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                  return
                }
                if (db.error === errorCode.EXCEPTION.code) {
                  alert(`※  ${errorCode.EXCEPTION.memo}`)
                  return
                }
                getDetail(+queryValue.CLASS_ID)
                handleDetailSettingClose()
                setTimeout(() => {
                  alert('※  商品細項設定儲存成功')
                }, 200)
              })
          }
        }
      }
      
      if (clickStatus === 'revise') {
        if (fileData) {
          uploadItem(imgData).then(db => {
            if (db.error === errorCode.INCORRECT_DATA.code) {
              alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.INCORRECT_FORMAT.code) {
              alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            setTargetInfo(prev => ({
              ...prev,
              ITEM_IMG: db.data.url
            }))
            let targetUrl = db.data.url
            if (+targetInfo.IS_PERMANENT === 0) {
              let result = dateMaker(calenderInfo.startDate, calenderInfo.startTime, calenderInfo.endDate, calenderInfo.endTime)
              setTargetInfo(prev => ({
                ...prev,
                BEGIN_DATE: new Date(result.SSet),
                EXPIRE_DATE: new Date(result.ESet)
              }))
              reviseDetailList(+targetInfo.ORDER_ID, targetUrl, targetInfo.ITEM_NAME, +targetInfo.PRICE_TYPE,
                +targetInfo.ITEM_PRICE, +targetInfo.ITEM_TAG, new Date(result.SSet), new Date(result.ESet), +targetInfo.PERSON_DAILY_LIMIT,
                +targetInfo.TOTAL_NUM, targetInfo.ITEM_DETAIL, +targetInfo.OPEN_EXCHANGE, +queryValue.CLASS_ID, +targetInfo.ITEM_ID,
                +targetInfo.IS_PERMANENT).then(db => {
                  if (db.error === errorCode.INCORRECT_DATA.code) {
                    alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                    return
                  }
                  if (db.error === errorCode.INCORRECT_FORMAT.code) {
                    alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                    return
                  }
                  if (db.error === errorCode.EXCEPTION.code) {
                    alert(`※  ${errorCode.EXCEPTION.memo}`)
                    return
                  }
                  getDetail(+queryValue.CLASS_ID)
                  handleDetailSettingClose()
                  setTimeout(() => {
                    alert('※  商品細項設定儲存成功')
                  }, 200)
                })
            }
        
            if (+targetInfo.IS_PERMANENT === 1) {
              reviseDetailList(+targetInfo.ORDER_ID, targetUrl, targetInfo.ITEM_NAME, +targetInfo.PRICE_TYPE,
                +targetInfo.ITEM_PRICE, +targetInfo.ITEM_TAG, targetInfo.BEGIN_DATE, targetInfo.EXPIRE_DATE, +targetInfo.PERSON_DAILY_LIMIT,
                +targetInfo.TOTAL_NUM, targetInfo.ITEM_DETAIL, +targetInfo.OPEN_EXCHANGE, +queryValue.CLASS_ID, +targetInfo.ITEM_ID,
                +targetInfo.IS_PERMANENT).then(db => {
                  if (db.error === errorCode.INCORRECT_DATA.code) {
                    alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                    return
                  }
                  if (db.error === errorCode.INCORRECT_FORMAT.code) {
                    alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                    return
                  }
                  if (db.error === errorCode.EXCEPTION.code) {
                    alert(`※  ${errorCode.EXCEPTION.memo}`)
                    return
                  }
                  getDetail(+queryValue.CLASS_ID)
                  handleDetailSettingClose()
                  setTimeout(() => {
                    alert('※  商品細項設定儲存成功')
                  }, 200)
                })
            }
          })
        }
        
        if (!fileData) {
          if (+targetInfo.IS_PERMANENT === 0) {
            let result = dateMaker(calenderInfo.startDate, calenderInfo.startTime, calenderInfo.endDate, calenderInfo.endTime)
            setTargetInfo(prev => ({
              ...prev,
              BEGIN_DATE: new Date(result.SSet),
              EXPIRE_DATE: new Date(result.ESet)
            }))
            reviseDetailList(+targetInfo.ORDER_ID, targetInfo.ITEM_IMG, targetInfo.ITEM_NAME, +targetInfo.PRICE_TYPE,
              +targetInfo.ITEM_PRICE, +targetInfo.ITEM_TAG, new Date(result.SSet), new Date(result.ESet), +targetInfo.PERSON_DAILY_LIMIT,
              +targetInfo.TOTAL_NUM, targetInfo.ITEM_DETAIL, +targetInfo.OPEN_EXCHANGE, +queryValue.CLASS_ID, +targetInfo.ITEM_ID,
              +targetInfo.IS_PERMANENT).then(db => {
                if (db.error === errorCode.INCORRECT_DATA.code) {
                  alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                  return
                }
                if (db.error === errorCode.INCORRECT_FORMAT.code) {
                  alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                  return
                }
                if (db.error === errorCode.EXCEPTION.code) {
                  alert(`※  ${errorCode.EXCEPTION.memo}`)
                  return
                }
                getDetail(+queryValue.CLASS_ID)
                handleDetailSettingClose()
                setTimeout(() => {
                  alert('※  商品細項設定儲存成功')
                }, 200)
              })
          }
      
          if (+targetInfo.IS_PERMANENT === 1) {
            reviseDetailList(+targetInfo.ORDER_ID, targetInfo.ITEM_IMG, targetInfo.ITEM_NAME, +targetInfo.PRICE_TYPE,
              +targetInfo.ITEM_PRICE, +targetInfo.ITEM_TAG, targetInfo.BEGIN_DATE, targetInfo.EXPIRE_DATE, +targetInfo.PERSON_DAILY_LIMIT,
              +targetInfo.TOTAL_NUM, targetInfo.ITEM_DETAIL, +targetInfo.OPEN_EXCHANGE, +queryValue.CLASS_ID, +targetInfo.ITEM_ID,
              +targetInfo.IS_PERMANENT).then(db => {
                if (db.error === errorCode.INCORRECT_DATA.code) {
                  alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                  return
                }
                if (db.error === errorCode.INCORRECT_FORMAT.code) {
                  alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                  return
                }
                if (db.error === errorCode.EXCEPTION.code) {
                  alert(`※  ${errorCode.EXCEPTION.memo}`)
                  return
                }
                getDetail(+queryValue.CLASS_ID)
                handleDetailSettingClose()
                setTimeout(() => {
                  alert('※  商品細項設定儲存成功')
                }, 200)
              })
          }
        }
      }
      setTimeout(() => {
        setLimitState({perDay: 0, server: 0})
      }, 2000)
      setFileData(false)
    })
  }
  return (
    <>
      <ModalFormat
        show={showDetailSetting}
        onHide={handlePopUpClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>商品細項設定 : </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <BasicHeadTitle>排序</BasicHeadTitle>
              <td colSpan= "3">
                <DetailInput
                  placeholder= "請輸入數字"
                  value= {targetInfo.ORDER_ID}
                  onChange= {e => {handleTarget(e.target.value, 'ORDER_ID')}}
                  disabled= {clickStatus === 'check' ? true : false}
                />
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>商品圖</BasicHeadTitle>
              <PopFrame>
                <Avatar
                  src={targetInfo.ITEM_IMG}
                  className="rounded"
                />
                <NoImg style= {targetInfo.ITEM_IMG ? {display: 'none'} : {display: 'block'}}>無商品圖</NoImg>
              </PopFrame>
              <LabelBtn style={clickStatus === 'check' ? {display: 'none'} : {display: ''}}>
                <span>上傳</span>
                <input type= "file" name= "file" style={{display: "none", cursor: 'pointer'}} onChange= {handleUploadImg} disabled= {+targetObj.OPEN_EXCHANGE === 2 ? true : false}/>
              </LabelBtn>
              <PopDetail style={clickStatus === 'check' ? {display: 'none'} : {display: ''}} onClick= {handleDeleteItem}>刪除</PopDetail>
            </tr>

            <tr>
              <BasicHeadTitle>商品名稱</BasicHeadTitle>
              <td colSpan= "3">
                <DetailInput
                  placeholder= "名稱最多12個字"
                  value= {targetInfo.ITEM_NAME}
                  onChange= {e => {handleTarget(e.target.value, 'ITEM_NAME')}}
                  disabled= {clickStatus === 'check' ? true : false}
                />
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>付費</BasicHeadTitle>
              <td colSpan= "3">
                <PUDropdown
                  className= "btn dropdown-toggle"
                  value= {+targetInfo.PRICE_TYPE}
                  onChange= {e =>{handleTarget(e.target.value, 'PRICE_TYPE')}}
                  disabled= {clickStatus === 'check' ? true : false}
                >
                  <option className= "dropdown-item" value= "2">鬥陣寶</option>
                </PUDropdown>
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>價格</BasicHeadTitle>
              <td colSpan= "3">
                <DetailInput
                  placeholder= "請輸入兌換價格"
                  value= {targetInfo.ITEM_PRICE}
                  onChange= {e => {handleTarget(e.target.value, 'ITEM_PRICE')}}
                  disabled= {clickStatus === 'check' ? true : false}
                />
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>標籤設定</BasicHeadTitle>
              <td colSpan= "3">
                <PUDropdown
                  className= "btn dropdown-toggle"
                  value= {targetInfo.ITEM_TAG}
                  onChange= {e => {handleTarget(e.target.value, 'ITEM_TAG')}}
                  disabled= {clickStatus === 'check' ? true : false}
                >
                  <option className= "dropdown-item" value= "0">無</option>
                  <option className= "dropdown-item" value= "1">限量</option>
                  <option className= "dropdown-item" value= "2">限時</option>
                  <option className= "dropdown-item" value= "3">活動</option>
                  <option className= "dropdown-item" value= "4">熱門</option>
                  <option className= "dropdown-item" value= "5">推薦</option>
                </PUDropdown>
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>※時間設定</BasicHeadTitle>
              <td colSpan= "3">
                <input
                  type= "radio"
                  value= "1"
                  onChange= {e => {handleTerm(e.target.value, 'IS_PERMANENT')}}
                  checked= {+targetInfo.IS_PERMANENT === 1 ? true : false}
                  disabled= {clickStatus === 'check' ? true : false}
                />永久
                <TimeLimited
                  type= "radio"
                  value= "0"
                  onChange= {e => {handleTarget(e.target.value, 'IS_PERMANENT')}}
                  checked= {+targetInfo.IS_PERMANENT === 0 ? true : false}
                  disabled= {clickStatus === 'check' ? true : false}
                />期間限定
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>開始日期</BasicHeadTitle>
              <td colSpan= "3">
                <SendTimeWrapper>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label= "日期"
                      name= "startDate"
                      value= {calenderInfo.startDate === "2001-01-01T00:00:00.000Z"? '' : calenderInfo.startDate}
                      onChange= {(newValue) => {
                        setCalenderInfo(prev => ({
                          ...prev,
                          startDate: newValue
                        }))
                      }}
                      disabled= {clickStatus === 'check' ? true : +targetInfo.IS_PERMANENT === 1 ? true :false}
                      renderInput={({ inputRef, inputProps, InputProps }) => {
                        inputProps.placeholder = '請選擇開始日期'
                        return (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <DetailInput
                              id= "datePicker"
                              ref= {inputRef} {...inputProps}
                              disabled= {clickStatus === 'check' ? true : +targetInfo.IS_PERMANENT === 1 ? true :false}
                            />
                            <div id="dateBtn1">{InputProps?.endAdornment}</div>
                          </Box>
                        )
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label="時間"
                      name= "startTime"
                      value= {calenderInfo.startTime === "2001-01-01T00:00:00.000Z" ? '' : calenderInfo.startTime}
                      onChange= {(newValue) => {
                        setCalenderInfo(prev => ({
                          ...prev,
                          startTime: newValue
                        }))
                      }}
                      disabled= {clickStatus === 'check' ? true : +targetInfo.IS_PERMANENT === 1 ? true :false}
                      renderInput= {({ inputRef, inputProps, InputProps }) => {
                        inputProps.placeholder = '請選擇開始時間'
                        return (
                          <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                            <DetailInput
                              id= "timePicker"
                              ref= {inputRef} {...inputProps}
                              disabled= {clickStatus === 'check' ? true : +targetInfo.IS_PERMANENT === 1 ? true :false}
                            />
                            {InputProps?.endAdornment}
                          </TimeFormat>
                        )
                      }}
                    />
                  </LocalizationProvider>
                </SendTimeWrapper>
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>結束日期</BasicHeadTitle>
              <td colSpan= "3">
                <SendTimeWrapper>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label= "日期"
                      name= "endDate"
                      value={calenderInfo.endDate === "2100-12-31T23:59:59.000Z" ? '' : calenderInfo.endDate}
                      onChange= {(newValue) => {
                        setCalenderInfo(prev => ({
                          ...prev,
                          endDate: newValue
                        }))
                      }}
                      disabled= {clickStatus === 'check' ? true : +targetInfo.IS_PERMANENT === 1 ? true :false}
                      renderInput={({ inputRef, inputProps, InputProps }) => {
                        inputProps.placeholder = '請選擇結束日期'
                        return (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <DetailInput
                              id= "datePicker"
                              ref={inputRef} {...inputProps}
                              disabled= {clickStatus === 'check' ? true : +targetInfo.IS_PERMANENT === 1 ? true :false}
                            />
                            <div id="dateBtn2">{InputProps?.endAdornment}</div>
                          </Box>
                        )
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label="時間"
                      name= "endTime"
                      value={calenderInfo.endTime === "2100-12-31T23:59:59.000Z" ? '' : calenderInfo.endTime}
                      onChange= {(newValue) => {
                        setCalenderInfo(prev => ({
                          ...prev,
                          endTime: newValue
                        }))
                      }}
                      disabled= {clickStatus === 'check' ? true : +targetInfo.IS_PERMANENT === 1 ? true :false}
                      renderInput={({ inputRef, inputProps, InputProps }) => {
                        inputProps.placeholder = '請選擇結束時間'
                        return (
                          <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                            <DetailInput
                            id= "timePicker"
                            ref={inputRef} {...inputProps}
                            disabled= {clickStatus === 'check' ? true : +targetInfo.IS_PERMANENT === 1 ? true :false}
                          />
                            {InputProps?.endAdornment}
                          </TimeFormat>
                        )
                      }}
                    />
                  </LocalizationProvider>
                </SendTimeWrapper>
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>單日限購</BasicHeadTitle>
              <td colSpan= "3">
                <LimitGroup>
                  <div>
                    <input
                      type ="radio"
                      value= "0"
                      onChange=  {e => {handleLimit(e.target.value, 'perDay')}}
                      checked= {!+limitState.perDay ? true : false}
                      disabled= {clickStatus === 'check' ? true : false}
                      onClick= {handlePerDayClose}
                    />關閉
                  </div>
                  <Limit>
                    <div>
                      <input
                        type ="radio"
                        value= "1"
                        onChange=  {e => {handleLimit(e.target.value, 'perDay')}}
                        checked= {+limitState.perDay === 1  ? true : false}
                        disabled= {clickStatus === 'check' ? true : false}
                      />開啟 : 次數
                    </div>
                    <LimitTimes
                      value= {!+targetInfo.PERSON_DAILY_LIMIT ? '' : targetInfo.PERSON_DAILY_LIMIT}
                      placeholder= "請輸入數字"
                      onChange= {e => {handleTarget(e.target.value, 'PERSON_DAILY_LIMIT')}}
                      disabled= {clickStatus === 'check' ? true : !+limitState.perDay ? true : false}
                    />
                  </Limit>
                </LimitGroup>
              </td>

            </tr>

            <tr>
              <BasicHeadTitle>全服限購</BasicHeadTitle>
              <td colSpan= "3">
              <LimitGroup>
                <div>
                  <input
                    type ="radio"
                    value= "0"
                    onChange=  {e=> {handleLimit(e.target.value, 'server')}}
                    checked= {!+limitState.server ? true : false}
                    disabled= {clickStatus === 'check' ? true : false}
                    onClick= {handleServerClose}
                  />關閉
                </div>
                <Limit>
                  <div>
                    <input
                      type ="radio"
                      value= "1"
                      onChange=  {e => {handleLimit(e.target.value, 'server')}}
                      checked= {+limitState.server === 1 ? true : false}
                      disabled= {clickStatus === 'check' ? true : false}
                    />開啟 : 次數
                  </div>
                  <LimitTimes
                    placeholder= "請輸入數字"
                    value= {!+targetInfo.TOTAL_NUM ? '' : targetInfo.TOTAL_NUM}
                    onChange= {e => {handleTarget(e.target.value, 'TOTAL_NUM')}}
                    disabled= {clickStatus === 'check' ? true : !+limitState.server ? true : false}
                  />
                </Limit>
              </LimitGroup>
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>說明文</BasicHeadTitle>
              <td colSpan= "3">
                <textarea
                  className="form-control"
                  rows= "5"
                  placeholder= "請填寫內文"
                  value= {targetInfo.ITEM_DETAIL}
                  onChange= {e => {handleTarget(e.target.value, 'ITEM_DETAIL')}}
                  disabled= {clickStatus === 'check' ? true : false}
                />
              </td>
            </tr>

            <tr>
              <BasicHeadTitle>開關</BasicHeadTitle>
              <td colSpan= "3">
                <PUDropdown
                  className= "btn dropdown-toggle"
                  value= {+targetInfo.OPEN_EXCHANGE}
                  onChange= {e => {handleTarget(e.target.value, 'OPEN_EXCHANGE')}}
                  disabled= {clickStatus === 'check' ? true : false}
                >
                  <option className= "dropdown-item" value= "0">關閉</option>
                  <option className= "dropdown-item" value= "1">開啟</option>
                  <option className= "dropdown-item" value= "2">結束</option>
                </PUDropdown>
              </td>
            </tr>

          </tbody>
        </table>
        </Modal.Body>
        <ModalBtn>
          <Button style= {switchDelete ? {display: 'block'} : {display: 'none'}} onClick= {handleDelete} variant="danger">刪除</Button>
          <Button style= {switchSave ? {display: 'block'} : {display: 'none'}} onClick= {handleSave} variant="success">儲存</Button>
        </ModalBtn>
      </ModalFormat>

      <DeleteHint
        showDeleteHint= {showDeleteHint}
        handleDeleteHintClose= {handleDeleteHintClose}
        deleteDetail= {deleteDetail}
        targetInfo= {targetInfo}
        setDetailCopy= {setDetailCopy}
        setDetailInfo= {setDetailInfo}
        queryValue= {queryValue}
      />

      <HandleEndStatus
        showEnd= {showEnd}
        handleEndClose= {handleEndClose}
        calenderInfo= {calenderInfo}
        targetInfo= {targetInfo}
        setTargetInfo= {setTargetInfo}
        queryValue= {queryValue}
        setDetailCopy= {setDetailCopy}
        setDetailInfo= {setDetailInfo}
        clickStatus= {clickStatus}
        imgData= {imgData}
        fileData= {fileData}
        setFileData= {setFileData}
        dateMaker= {dateMaker}
        setLimitState= {setLimitState}
      />
    </>
  )
}