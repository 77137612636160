import { getAuthToken } from "../../utils"
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 取得玩家戰績 PI16 ?//
export const getUserGameInfo = (id, gameId, bTime, eTime, bRow, eRow) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/playerInfo/playerGameLog?id=${id}&gameId=${gameId}&bTime=${bTime}&eTime=${eTime}&bRow=${bRow}&eRow=${eRow}`, {
    method: 'GET',
    headers: {
      'Authorization' : `Bearer ${token}`,
      'content-type': 'application/json' 
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}