import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import "../../../views/operation/orderManagement/styled.css"
import HandleEndStatus from "./handleEndStatus"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { defaultDate, defaultDateE, TimezoneTranslator } from "../../../middleware/dateUtils"
import {
  PUCol,
  PUDropdown,
  PUInput,
  Btn
} from "../../../views/operation/orderManagement/styledComponent"
import { ModalFormat, BasicTable } from "../../../middleware/utilityStyle"
import { orderDetail, updateOrderDetail, orderList } from "../../../middleware/Api/operation/orderManagementApi"
import { getAccessArr } from "../../../middleware/utils"

export default function HandleMultiInto(props) {
  const history = useHistory()
  const {
    showManiMulti,
    handleManiMultiClose,
    checkValueArr,
    multiManiStatus,
    setPanelInfo,
    page,
    setPageCounter,
    changePagePara,
    setChangePagePara,
    multiCopy,
    setMultiCopy,
    displayMulti,
    setDisplayMulti
  } = props

  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })

  useEffect (() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (checkValueArr.length) {
        if (Array.isArray(checkValueArr)) {
          orderDetail(checkValueArr.toString()).then(db => {
            if (db.error === errorCode.INCORRECT_DATA.code) {
              alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.INCORRECT_FORMAT.code) {
              alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.NULL_DATA.code) {
              alert(`※  ${errorCode.NULL_DATA.memo}`)
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            db.data.sort(function order(a,b) {
              return new Date(b.LOG_DATE) - new Date(a.LOG_DATE)
            })
            setMultiCopy(JSON.parse(JSON.stringify(db.data)))
            setDisplayMulti(JSON.parse(JSON.stringify(db.data)))
          })
        }
      }
    })
  }, [checkValueArr, multiManiStatus])

  const handleChange = e => {
    if (multiManiStatus) {
      let newArr = Object.assign([], displayMulti)
      newArr[e.target.dataset.index][e.target.name] = e.target.value
      setDisplayMulti(newArr)
    }
  }
  
  const nameTrans = [
    [1, "金幣"],
    [2, "鬥陣寶"],
    [3, "兌換券"],
  ]
  const paymentNameTrans = new Map(nameTrans)

  const [showEnd, setEndShow] = useState(false)
  const handleEndClose = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      orderList(changePagePara.packageStatus, changePagePara.orderStatus, changePagePara.uid,
      changePagePara.orderID, changePagePara.sDate, changePagePara.eDate, 1+(page-1)*30, page*30, 0).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setPanelInfo(db.data)
      })
      setEndShow(false)
    })
  }
  const handleEndShow = () => setEndShow(true)

  const handleSave = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 41)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      let compareTheSame = true
      let error = 0
      for (let i = 0; i < displayMulti.length; i++) {
        for(let key in displayMulti[i]){
          if (typeof multiCopy[i][key] === 'string') {
            if (displayMulti[i][key] !== multiCopy[i][key]) {
              compareTheSame = false
              break
            }
          }
          if (typeof multiCopy[i][key] === 'number') {
            if (+displayMulti[i][key] !== +multiCopy[i][key]) {
              compareTheSame = false
              break
            }
          }
        }
        if (compareTheSame) {
          continue
        }
        if (!compareTheSame) {
          if (+displayMulti[i].FINISH_STATUS === 2 || +multiCopy[i].FINISH_STATUS === 2) {
            handleManiMultiClose()
            handleEndShow()
            return
          }
          if (+displayMulti[i].FINISH_STATUS !== 2 && +multiCopy[i].FINISH_STATUS !== 2) {
            updateOrderDetail(`${displayMulti[i].ORDER_ID}`, +displayMulti[i].PACKAGE_STATUS, displayMulti[i].LOGISTICS,
            displayMulti[i].PACKAGE_NUMBER, displayMulti[i].REMARK, +displayMulti[i].FINISH_STATUS).then(db => {
              if (db.error === errorCode.INCORRECT_DATA.code) {
                alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                return
              }
              if (db.error === errorCode.INCORRECT_FORMAT.code) {
                alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                return
              }
              if (db.error === errorCode.EXCEPTION.code) {
                alert(`※  ${errorCode.EXCEPTION.memo}`)
                return
              }
              handleManiMultiClose()
              if (db.error) {
                error = 1
                alert('批次儲存失敗，相關問題請洽詢工程部  ʕ๑•́ᴥ•̀๑ʔ')
                return
              }
            })
          }
        }
      }
      if(compareTheSame) {
        alert('所有資料未更動  ʕ◍•́ᴥ•̀◍ʔ')
        handleManiMultiClose()
        return
      }
      if (!error) {
        setTimeout(() => {
          alert('訂單設定儲存成功  ʕ๑´ㅂ`๑ʔ')
        }, 400)
      }
    })
  }

  const handlePUClose = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleManiMultiClose()
    })
  }

  const handleOnHide = () => {
    handleManiMultiClose()
    setTimeout(() => {
      setDisplayMulti(JSON.parse(JSON.stringify(multiCopy)))
    },200)
  }
  return (
    <>
      <ModalFormat
        show= {showManiMulti}
        onHide= {handleOnHide}
        backdrop= "static"
        dialogClassName="modal-XXL"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body >
          <BasicTable className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">交易單號</th>
                <th scope="col">兌換時間</th>
                <th scope="col">玩家ID</th>
                <th scope="col">商品ID</th>
                <th scope="col">商品項目</th>
                <th scope="col">收件人</th>
                <th scope= "col">連絡電話</th>
                <th scope= "col">收件地址</th>
                <th scope= "col">付費方式</th>
                <th scope= "col">價格</th>
                <th scope= "col">餘額</th>
                <th scope="col">配送狀態</th>
                <th scope="col">物流單位</th>
                <th scope="col">包裹號碼</th>
                <th scope="col">單號狀態</th>
                <th scope="col">備註</th>
              </tr>
            </thead>
            <tbody>
            {
              displayMulti.length === multiCopy.length ?
                Array.isArray(displayMulti) && displayMulti.length > 0 ?
                displayMulti.map((obj, index) => {
                  return (
                    <tr key= {index}>
                      <PUCol>{obj.ORDER_ID}</PUCol>
                      <PUCol>{TimezoneTranslator(obj.LOG_DATE).slice(0, 16)}</PUCol>
                      <PUCol>{obj.UID}</PUCol>
                      <PUCol>{obj.ITEM_ID}</PUCol>
                      <PUCol>{obj.ITEM_NAME}</PUCol>
                      <PUCol>{obj.RECEIVER_NAME}</PUCol>
                      <PUCol>{obj.RECEIVER_PHONE}</PUCol>
                      <PUCol>{obj.RECEIVER_ADDRESS}</PUCol>
                      <PUCol>{paymentNameTrans.get(obj.PRICE_TYPE)}</PUCol>
                      <PUCol>{obj.ITEM_PRICE}</PUCol>
                      <PUCol>{obj.PRICE_TYPE === 3 ? "-" : obj.BALANCE}</PUCol>
                      <PUCol>
                        <PUDropdown
                          name= "PACKAGE_STATUS"
                          data-index= {index}
                          value= {multiManiStatus ? obj.PACKAGE_STATUS : multiCopy[index].PACKAGE_STATUS}
                          onChange= {handleChange}
                          disabled= {multiManiStatus ? +obj.FINISH_STATUS === 2 ? true : false : true}
                        >
                          <option value= "1">訂單受理</option>
                          <option value= "2">已備貨</option>
                          <option value= "3">申請換貨</option>
                          <option value= "4">已出貨</option>
                        </PUDropdown>
                      </PUCol>
                      <PUCol>
                        <PUInput
                          name= "LOGISTICS"
                          data-index= {index}
                          placeholder= "請輸入物流單位"
                          value= {multiManiStatus ? obj.LOGISTICS : multiCopy[index].LOGISTICS}
                          onChange= {handleChange}
                          disabled= {multiManiStatus ? +obj.FINISH_STATUS === 2 ? true : false : true}
                        />
                      </PUCol>
                      <PUCol>
                        <PUInput
                          name= "PACKAGE_NUMBER"
                          data-index= {index}
                          placeholder= "請輸入包裹號碼"
                          value= {multiManiStatus ? obj.PACKAGE_NUMBER : multiCopy[index].PACKAGE_NUMBER}
                          onChange= {handleChange}
                          disabled= {multiManiStatus ? +obj.FINISH_STATUS === 2 ? true : false : true}
                        />
                      </PUCol>
                      <PUCol>
                        <PUDropdown
                          name= "FINISH_STATUS"
                          data-index= {index}
                          value= {multiManiStatus ? +obj.FINISH_STATUS : +multiCopy[index].FINISH_STATUS}
                          onChange= {handleChange}
                          disabled= {multiManiStatus ? +obj.FINISH_STATUS === 2 ? true : false : true}
                        >
                          <option value= "1">進行中</option>
                          <option value= "2">結案</option>
                        </PUDropdown>
                      </PUCol>
                      <PUCol>
                        <textarea
                          name= "REMARK"
                          data-index= {index}
                          className= "form-control"
                          row= "2"
                          value= {multiManiStatus ? obj.REMARK : multiCopy[index].REMARK}
                          onChange= {handleChange}
                          disabled= {multiManiStatus ? +obj.FINISH_STATUS === 2 ? true : false : true}
                        />
                      </PUCol>
                    </tr>
                  )
                })
                : <tr key= "noInfo"><td colSpan= "16">尚無資料</td></tr>
              : ''
            }
            </tbody>
            <tfoot></tfoot>
          </BasicTable>
        </Modal.Body>
        <Btn>
          <Button
            variant="success"
            style= {multiManiStatus ? {display: 'block'} : {display: 'none'}}
            onClick={handleSave}>儲存</Button>
          <Button 
            variant= "dark"
            style= {multiManiStatus ? {display: 'none'} : {display: 'block'}}
            onClick= {handlePUClose}>關閉</Button>
        </Btn>
      </ModalFormat>

      <HandleEndStatus
        showEnd= {showEnd}
        handleEndClose= {handleEndClose}
        multiManiStatus= {multiManiStatus}
        setPanelInfo= {setPanelInfo}
        setPageCounter= {setPageCounter}
        setChangePagePara= {setChangePagePara}
        displayMulti= {displayMulti}
      />
    </>
  )
}