import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker'
import { today, yesterday, thisWeek, lastWeek, thisMonth, defaultDate, defaultDateE } from "../../../middleware/dateUtils"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import generateExcel from "../../../middleware/generateExcel.js"
import errorCode from "../../../assets/config/errorCode.json"
import gameCategories from "../../../assets/config/gameCategories.json"
import chessJSON from "../../../assets/config/ChessMissionList.json"
import mjJSON  from "../../../assets/config/MJMissionList.json"
import { getDzbMissionReport, getDzbMissionList } from "../../../middleware/Api/backstageManagement/dzbMissionReportApi"

import {
  InputBarBg,
  RangeWrapper,
  DatePickerInput,
  RangeGroup,
  Group,
  TableForm,
  STitle,
  NoData,
  Dropdown,
  NoInfo
} from "./styledComponent"
import {
  DateWrapper,
  DateTitle,
  ClickRange,
  SearchButton,
  ResetButton,
  BasicHeadTitle,
  TableContent,
  GenerateExcel
} from "../../../middleware/utilityStyle.js"

export default function dzbMissionReport() {
  let NToday = new Date(new Date(today[1]).toString().replace('23:59:59', '00:00:00')).toISOString()
  let NYesterday = new Date(new Date(yesterday[1]).toString().replace('23:59:59', '00:00:00')).toISOString()
  let NThisWeek = new Date(new Date(thisWeek[1]).toString().replace('23:59:59', '00:00:00')).toISOString()
  let NLastWeek = new Date(new Date(lastWeek[1]).toString().replace('23:59:59', '00:00:00')).toISOString()
  let NThisMonth = new Date(new Date(thisMonth[1]).toString().replace('23:59:59', '00:00:00')).toISOString()
  let NDefaultDateE = new Date(new Date(defaultDateE).toString().replace('23:59:59', '00:00:00')).toISOString()

  const [mTitle, setMTitle] = useState([])
  const history = useHistory()
  const [change, setChange] = useState({
    date: [new Date(defaultDate).toISOString(), NDefaultDateE],
    gameId: 7001,
    gameType: 2
  })
  const handleChange = (e, key) => {
    setChange(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  function get2Decimal(reachT, appearT) {
    let nStr = (Math.round(reachT / appearT * 10000) / 100.00).toString()
    let dot = nStr.indexOf('.')
    let result = 0
    if (!reachT || !appearT) {
      result = '0.00'
    } else {
      if (dot < 0) {
        result = nStr + '.00'
      }
      if (dot > 0) {
        result = nStr
      }
    }
    return (result + ' %')
  }
  const A_DAY = 24 * 60 * 60 * 1000
  const [allData, setAllData] = useState([])
  const [dObj, setDObj] = useState()
  const [dCounter, setDCounter] = useState()
  const [firstDate, setFirstDate] = useState()
  const [noData, setNoData] = useState(true)
  const [DBData, setDBData] = useState([])

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      function getMissionIDKey(dataArr) {
        let noRepeatName = []
        dataArr.map(ele => {
          noRepeatName.push(ele.MISSION_ID.split('_')[0])
        })
        return [...new Set(noRepeatName)]
      }

      function orderedMissionID(dataArr, nameList) {  //para: arr, arr
        let missionContentList = []
        for (let i = 0; i < nameList.length; i++) {
          let data = dataArr.filter(ele => ele.MISSION_ID.indexOf(nameList[i]) >= 0)
          data.sort(function order(a, b) {
            return (+a.MISSION_ID.split('_')[1]) - (+b.MISSION_ID.split('_')[1])
          })
          let tempArr = []
          for (let l =0 ; l < data.length; l++) {
            if (!tempArr.find(ele => ele.MISSION_ID === data[l].MISSION_ID)) {
              let obj = {
                MISSION_ID: data[l].MISSION_ID,
                MISSION_NAME: data[l].MISSION_NAME
              }
              tempArr.push(obj)
            }            
          }
          missionContentList.push(...tempArr)
        }
        return missionContentList
      }

      let sDate = ''
      let eDate = ''
      if (!change.date[0] || !change.date[1]) {
        alert('※  請輸入日期')
        return
      }

      if (change.date[0] !== null && change.date[1] !== null) {
        sDate = new Date(change.date[0])
        eDate = new  Date(change.date[1])
        if ((-(sDate-eDate)/A_DAY) + 1) {
          setFirstDate(new Date(change.date[1]))
        } else {
          setFirstDate()
        }
      }
      let daysCounter =(-(sDate-eDate)/A_DAY) + 1
      setDCounter(daysCounter)

      getDzbMissionReport(sDate.toISOString(), eDate.toISOString(), +change.gameId, +change.gameType).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          setNoData(true)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }

        setNoData(false)
        setDBData(db.data)
        const searchData = db.data

        let obj = {}
        function getDayRangeObj(targetD) {
          for (let j = 0; j < daysCounter; j++) {
            let unoDayArr = []
            unoDayArr.push(targetD.filter(ele => new Date(ele.LOG_DATE).getTime() === new Date(eDate).getTime()-A_DAY*j))
            obj[`day${j+1}`] = unoDayArr
          }
          setDObj(obj)
        }
        
        function getFourDataObj(keyArr) {
          let dataOBJ = {
            appearList: [],
            reachList: [],
            rPercentage: [],
            totalSendDzb: []
          }

          for (let d = 0; d < daysCounter; d++) {
            let AList = []
            let RList = []
            let RPList = []
            let dzbList = []
            for (let i = 0; i < keyArr.length; i++) {
              let target = obj[`day${d+1}`][0].find(ele => ele.MISSION_ID === keyArr[i].MISSION_ID)

              if (target) {
                AList.push(target.APPEAR_TIME)
                RList.push(target.REACH_TIME)
                RPList.push(get2Decimal(target.REACH_TIME, target.APPEAR_TIME))
                dzbList.push(target.SEND_GEM)
              } else {
                AList.push('---')
                RList.push('---')
                RPList.push('---')
                dzbList.push('---')
              }  
            }
            dataOBJ.appearList.push(AList)
            dataOBJ.reachList.push(RList)
            dataOBJ.rPercentage.push(RPList)
            dataOBJ.totalSendDzb.push(dzbList)
          }
          setAllData(dataOBJ)
        }
        
        if (+change.gameId) {
          let missionCategory = getMissionIDKey(searchData)
          let orderedList = orderedMissionID(searchData, missionCategory)
          setMTitle(orderedList)
          getDayRangeObj(searchData)
          getFourDataObj(orderedList)
        }
      })
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({
        date: [new Date(defaultDate).toISOString(), NDefaultDateE],
        gameId: 7001,
        gameType: 2
      })
    })
  }

  function TableDate() {
    let titleList = []
    if (dCounter && firstDate) {
      for (let k = 0; k < dCounter; k++) {
        titleList.push(<BasicHeadTitle style= {firstDate ? {} : {display: 'none'}}>{new Date(firstDate.getTime()-(A_DAY * k)).toLocaleDateString()}<br/>出現次數</BasicHeadTitle>)
        titleList.push(<BasicHeadTitle style= {firstDate ? {} : {display: 'none'}}>{new Date(firstDate.getTime()-(A_DAY * k)).toLocaleDateString()}<br/>達成次數</BasicHeadTitle>)
        titleList.push(<BasicHeadTitle style= {firstDate ? {} : {display: 'none'}}>{new Date(firstDate.getTime()-(A_DAY * k)).toLocaleDateString()}<br/>達成率</BasicHeadTitle>)
        titleList.push(<BasicHeadTitle style= {firstDate ? {} : {display: 'none'}}>{new Date(firstDate.getTime()-(A_DAY * k)).toLocaleDateString()}<br/>鬥陣寶總發出數</BasicHeadTitle>)
      }
    }
    return (
      <>
        {noData ? [] : titleList}
      </>
    )
  }

  function DataGroup(displayData, dataLength, index) {
    let list = []
    for (let i = 0; i < dataLength; i++) {
      list.push(<td>{displayData.appearList[i][index]}</td>)
      list.push(<td>{displayData.reachList[i][index]}</td>)
      list.push(<td>{displayData.rPercentage[i][index]}</td>)
      list.push(<td>{displayData.totalSendDzb[i][index]}</td>)
    }
    return (
      <>
        {noData ? [] : list}
      </>
    )
  }

  const handleExcel = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      let columnList = [{name: '鬥陣寶任務內容'}]
      let rowList = []

      if (dObj) {
        for (let k = 0; k < dCounter; k++) {
          let obj1 = {
            name: `${new Date(firstDate.getTime()-(A_DAY * k)).toLocaleDateString()}出現次數`
          }
          let obj2 = {
            name: `${new Date(firstDate.getTime()-(A_DAY * k)).toLocaleDateString()}達成次數`
          }
          let obj3 = {
            name: `${new Date(firstDate.getTime()-(A_DAY * k)).toLocaleDateString()}達成率`
          }
          let obj4 = {
            name: `${new Date(firstDate.getTime()-(A_DAY * k)).toLocaleDateString()}鬥陣寶總發出數`
          }
          columnList.push(obj1)
          columnList.push(obj2)
          columnList.push(obj3)
          columnList.push(obj4)
        }

        for(let j = 0; j < mTitle.length ; j++) {
          rowList.push([DBData.find(ele => ele.MISSION_ID === mTitle[j].MISSION_ID).MISSION_NAME])
        }

        for(let k = 0; k < mTitle.length ; k++) {
          let unoMData = []
          for (let i = 0; i < dCounter; i++) {
            let appearTime = dObj[`day${i+1}`][0].find(ele => ele.MISSION_ID === mTitle[k].MISSION_ID).APPEAR_TIME
            let reachTime = dObj[`day${i+1}`][0].find(ele => ele.MISSION_ID === mTitle[k].MISSION_ID).REACH_TIME
            let dzbQuantity = dObj[`day${i+1}`][0].find(ele => ele.MISSION_ID === mTitle[k].MISSION_ID).SEND_GEM
            unoMData.push(dObj[`day${i+1}`][0].length > 0 ? appearTime : '---')
            unoMData.push(dObj[`day${i+1}`][0].length > 0 ? reachTime : '---')
            unoMData.push(dObj[`day${i+1}`][0].length > 0 ? get2Decimal(reachTime, appearTime) : '---')
            unoMData.push(dObj[`day${i+1}`][0].length > 0 ? dzbQuantity : '---')
          }
          rowList[k].push(...unoMData)
        }
      }
      function date(str) {
        return `${new Date(str).getFullYear()}-${new Date(str).getMonth()+1}-${new Date(str).getDate()}`
      }
      let rangeS = date(change.date[0])
      let rangeE = date(change.date[1])
      if (columnList.length > 1 && rowList.length) {
        generateExcel('鬥陣任務報表.xlsx', `${rangeS}~${rangeE}`, columnList, rowList)
      } else {
        alert('※ 請搜索資料')
        return
      }
    })
  }

  function gameCategory() {
    let gameList = []
    gameCategories.map((ele, index) => {
      gameList.push(<option value = {ele.gameID} key= {index}>{ele.gameName}</option>)
    })
    return gameList
  }
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <RangeWrapper>
          <DateWrapper>
            <DateTitle>查詢時間</DateTitle>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                <DesktopDateRangePicker
                  startText="創建時間"
                  endText="結束時間"
                  value={change.date}
                  onChange={(newValue) => {
                    setChange(prev => ({
                      ...prev,
                      date: newValue
                    }))
                  }}
                  renderInput={(startProps, endProps) => {
                    startProps.inputProps.placeholder = '開始日期'
                    endProps.inputProps.placeholder= '結束日期'
                    return (
                      <React.Fragment>
                        <DatePickerInput ref={startProps.inputRef} {...startProps.inputProps} />
                        <Box sx={{ mx: 1 }}>至</Box>
                        <DatePickerInput ref={endProps.inputRef} {...endProps.inputProps} />
                      </React.Fragment>
                    )
                  }}
                />
              </Stack>
            </LocalizationProvider>
          </DateWrapper>
          <RangeGroup>
            <ClickRange onClick={() => { setChange(prev => ({...prev, date: [today[0], NToday]})) }}>今天</ClickRange>
            <ClickRange onClick={() => { setChange(prev => ({...prev, date: [yesterday[0], NYesterday]})) }}>昨天</ClickRange>
            <ClickRange onClick={() => { setChange(prev => ({...prev, date: [thisWeek[0], NThisWeek]})) }}>本周</ClickRange>
            <ClickRange onClick={() => { setChange(prev => ({...prev, date: [lastWeek[0], NLastWeek]})) }}>上周</ClickRange>
            <ClickRange onClick={() => { setChange(prev => ({...prev, date: [thisMonth[0], NThisMonth]})) }}>本月</ClickRange>
          </RangeGroup>
        </RangeWrapper>

        <Group>
          <div>
            <span>遊戲</span>
            <Dropdown
              value= {change.gameId}
              onChange= {e => {
                handleChange(e, 'gameId')
              }}
            >
              {gameCategory()}
            </Dropdown>
          </div>
        </Group>

        <Group>
          <div>
            <span>場次</span>
            <Dropdown
              value= {change.gameType}
              onChange= {e => {
                handleChange(e, 'gameType')
              }}
            >
              <option value= "2">初級</option>
              <option value= "3">中級</option>
              <option value= "4">高級</option>
            </Dropdown>
          </div>
        </Group>

        <Group>
          <SearchButton onClick={handleSearch}>搜索</SearchButton>
          <ResetButton onClick={handleReset}>重置</ResetButton>
        </Group>
      </InputBarBg>
      <GenerateExcel style= {{marginTop: '30px'}} onClick= {handleExcel}>產出EXCEL報表</GenerateExcel>

      <TableForm  className="table table-hover table-bordered">
        <thead>
          <tr key= "BasicHeadTitle">
            <BasicHeadTitle>鬥陣任務報表內容</BasicHeadTitle>
            <NoInfo style={!noData ? {display: 'none'} : {display: 'table-cell'}}>尚無資料</NoInfo>
            {TableDate()}
          </tr>
        </thead>
        <tbody>
          {
            mTitle.length > 0 && Array.isArray(mTitle) ?
              allData.appearList && mTitle.length > 0 ?
              mTitle.map((ele, index) => {
                return (
                  <>
                    <TableContent key= {`list${index}`}>
                      <STitle style={noData ? {display: 'none'} : {display: 'table-cell'}}>{ele?.MISSION_NAME}</STitle>
                      {DataGroup(allData, allData.appearList.length, index)}
                    </TableContent>
                  </>
                )
              })
              : <NoData></NoData>
            : <NoData></NoData>
          }
        </tbody>
      </TableForm>
    </>
  )
}