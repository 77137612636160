import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Stack from '@mui/material/Stack'
import { SearchButton, ResetButton, BasicRowTitle, TableContent, PageStyle } from "../../../middleware/utilityStyle"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import gameCategories from "../../../assets/config/gameCategories.json"
import gameType from "../../../assets/config/gameType.json"
import { getMatchStatus } from "../../../middleware/Api/backstageManagement/backstageMatchSysApi.js"
import webSocket from 'socket.io-client'
import {
  InputBarBg,
  InputBarRWD,
  Dropdown,
  BtnGroup,
} from "../../operation/concurrentOnlineUsers/styledComponent.js"

export default function BackstageMatchSys() {
  const history = useHistory()

  const [panelInfo, setPanelInfo] = useState([])
  const [pageCounter, setPageCounter] = useState(0)
  const [page, setPage] = React.useState(1)
  const [gameID, setGameID] = useState("0")

  const handleChange = (e) => {
    setGameID(e.target.value)
    localStorage.setItem("backstageMatchGameID", e.target.value)
  }

  function dealData(db) {
    let curGameID = +localStorage.getItem("backstageMatchGameID")
    if (db && db.length) {
      let allRoomArr = []
      let displayInfo = []
      for (let i = 0; i < db.length; i++) {
        for (let j=0; j < db[i].roomStatus.length; j++) {
          let targetObj= db[i].roomStatus[j]
          if (targetObj.playerInfoArr) {
            let official = targetObj.playerInfoArr.filter(ele => +ele.state === 2)
            targetObj.officialCount = official.length ? official.length : 0
            let AI = targetObj.playerInfoArr.filter(ele => ele.state === 3)
            targetObj.AICount = AI.length ? AI.length : 0
            allRoomArr.push(targetObj)
          }          
        }
      }
      allRoomArr.sort(function order(a, b) {
        return a.roomStatus - b.roomStatus
      })

      if (curGameID !== 0) allRoomArr = allRoomArr.filter(obj => obj.gameID === curGameID)
      if (allRoomArr.length) {
        for (let i = 0; i < allRoomArr.length; i += 20) {
        displayInfo.push(allRoomArr.slice(i, i + 20))
        }
        setPageCounter(displayInfo.length)
        setPanelInfo(displayInfo)
      } else {
        setPageCounter(0)
        setPanelInfo([])
      }
    }
  }

  function updateNewData(firstData, game, newData) {
    if (game === 7001) {
      firstData.splice(0, 1, newData)
    }
    if (game === 7002) {
      firstData.splice(1, 1, newData)
    }
    if (game === 7003) {
      firstData.splice(2, 1, newData)
    }
    if (game === 7004) {
      firstData.splice(3, 1, newData)
    }
    return firstData
  }

  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      localStorage.setItem("backstageMatchGameID", 0)

      getMatchStatus().then(db => {
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        dealData(db.data)
        let wsObj = webSocket("https://dzxqwebservice-test.shengxingamers.com:5080", {reconnectionAttempts: 3})
        //https://dzxq-formal-admin-alb-363087245.ap-northeast-1.elb.amazonaws.com:5080
        wsObj.on('disconnect', () => {
          if(!wsObj.connected) {
            alert('※  伺服器斷線，請重新整理頁面，若持續異常請洽詢工程部')
            wsObj.removeAllListeners()
          }
          return
        })

        if(webSocket("https://dzxqwebservice-test.shengxingamers.com:5080")){
          wsObj.on('connect', () => {
            if (wsObj.connected) {
              console.log('Client Connected!')
            }
          })

          wsObj.on('hint', message => {
            console.log('Hint:', message)
          })

          wsObj.on('data', (newObj) => {
            let whichGame = newObj.gameID
            let newList = updateNewData(db.data, +whichGame, newObj)
            dealData(newList)
          })
        }
        return () => {
          wsObj.removeAllListeners()
          wsObj.disconnect()
        }
      })
    })
  }, [])

  const handleChangePage = (event, newPage) => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPage(newPage)
    })
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
    })
    getMatchStatus().then(db => {
      if (db.error === errorCode.NULL_DATA.code) {
        alert(`※  ${errorCode.NULL_DATA.memo}`)
        return
      }
      if (db.error === errorCode.EXCEPTION.code) {
        alert(`※  ${errorCode.EXCEPTION.memo}`)
        return
      }
      dealData(db.data)
    })
  }

  const handleReset = () => {
    setGameID("0")
    localStorage.setItem("backstageMatchGameID", 0)
  }

  function gameCategory() {
    let gameList = []
    gameCategories.map((ele, index) => {
      gameList.push(<option value = {ele.gameID} key= {index}>{ele.gameName}</option>)
    })
    return gameList
  }

  console.log('panelInfo: ', panelInfo)
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light" style= {{marginBottom: "1rem"}}>
        <InputBarRWD>
          <div>
            <span>遊戲類型</span>
            <Dropdown
              value= {gameID}
              onChange= {e => { handleChange(e) }}
            >
              <option value="0">全部</option>
              {gameCategory()}
            </Dropdown>
          </div>
        </InputBarRWD>

        <BtnGroup>
          <SearchButton onClick= {handleSearch}>搜索</SearchButton>
          <ResetButton onClick= {handleReset}>重置</ResetButton>
        </BtnGroup>
      </InputBarBg>

      <table className="table table-hover table-bordered" style= {{textAlign: 'center'}}>
        <thead>
          <BasicRowTitle>
            <th scope="col">開局編號</th>
            <th scope="col">遊戲類型</th>
            <th scope="col">場次種類</th>
            <th scope="col">配對人數</th>
            <th scope="col">房間狀態</th>
          </BasicRowTitle>
        </thead>
        <tbody>
          {
            Array.isArray(panelInfo) && panelInfo.length > 0 ?
            panelInfo[page-1].map((ele, index)=> {
              return (
                <TableContent key= {index}>
                  <td>{ele.roomNum}</td>
                  <td>{gameCategories.find(obj => obj.gameID === +ele.gameID).gameName}</td>
                  <td>{gameType.find(typeObj => typeObj.typeNum -1 === +ele.roomType) ? gameType.find(typeObj => typeObj.typeNum -1 === +ele.roomType).typeName : '競技場' }</td>
                  <td>
                    <span>{`${ele.playerCount}(${ele.officialCount ? ele.officialCount : 0})`}</span>
                    <span style= {{color: 'red'}}>{`(${ele.AICount})`}</span>
                  </td>
                  <td>{+ele.roomStatus === 0 || +ele.roomStatus === 1 ? '配對中' : '已遊玩'}</td>
                </TableContent>
              )
            })
            : <tr key= "noInfo"><td colSpan= "5">尚無資料</td></tr>
          }
        </tbody>
      </table>

      {
        Array.isArray([panelInfo]) && panelInfo.length ?
          <Stack spacing={2}>
            <PageStyle count={pageCounter}
              color="warning"
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </Stack>
        : ''
      }
    </>
  )
}