import styled from 'styled-components'
import * as Icon from 'react-bootstrap-icons'
import { BarBG, RangePack, DayRange, BasicInput } from '../../../middleware/utilityStyle'

export const InputBarBg = styled(BarBG)`
  @media screen and (max-width: 1800px){
    & {
      flex-direction: column;
      justify-content: center;
    }
  }
`

export const NavLeft = styled.div`
  display: flex;
  @media screen and (max-width: 1800px){
    & {
      flex-direction: column;
      justify-content: center;
    }
  }
`

export const InputBarRWD = styled.div`
  display: flex;
  align-items: center;
`

export const DropWrapper = styled.span`
  margin-left: 20px;
`

export const Dropdown = styled.select`
  margin-left: 20px;
  border: 1px solid black;
  border-radius: 5px;
  height: 28px;
  width: 200px;
  @media screen and (max-width: 700px) {
    width: 150px;
  }
`

export const InputBar = styled.div`
  margin-left: 20px;
`

export const Input = styled(BasicInput)`
  width: 200px;
  margin-left: 20px;
  @media screen and (max-width: 700px) {
    width: 150px;
  }
`

export const DatePickerInput = styled(BasicInput)`
  @media screen and (max-width: 700px) {
    width: 150px;
  }
`

export const IconStyle = styled(Icon.CalendarRange)`
  color: orange;
`

export const RangeWrapper = styled(RangePack)`
  margin-left: 20px;
  @media screen and (max-width: 1800px) {
    margin-top: 10px;
    flex-direction: column;
    margin-left: 0px;
  }
`

export const RangeInputStyle = styled(BasicInput)`
  width: 150px;
`

export const Start = styled.div`
  margin-left: 20px;
`

export const StartInput = styled(BasicInput)`
  width: 150px;
  margin-left: 20px;
`

export const End = styled.div`
  margin-left: 5px;
`

export const EndInput = styled(BasicInput)`
  width: 150px;
  margin-left: 5px;
`

export const RangeGroup = styled(DayRange)`
  @media screen and (max-width: 1800px) {
    margin-top: 10px;
  }
`

export const BtnGroup = styled.div`
  @media screen and (max-width: 1800px){
    margin-top: 10px;
  }
`