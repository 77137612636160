import Modal from 'react-bootstrap/Modal'
import { Button } from "react-bootstrap"
import { ModalText, ModalBtn } from "../../../middleware/utilityStyle"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import { sendSMS } from "../../../middleware/Api/operation/textSendingApi"
import errorCode from "../../../assets/config/errorCode.json"

export default function HandleSubmit(props) {
  const {
    showSubmit,
    handleSubmitClose,
    change,
    setChange,
    phoneList
  } = props
  const handleCheck = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      sendSMS(+change.toAll, phoneList, change.content).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
      })
      setChange({toAll : '', phoneNum: '', content: ''})
      handleSubmitClose()
    })
  }
  return (
    <>
      <Modal
        show={showSubmit}
        onHide={handleSubmitClose}
        backdrop="static"
        keyboard={false}
      >
      <Modal.Header closeButton>
        <Modal.Title>二次確認</Modal.Title>
      </Modal.Header>
      <ModalText>
        確定要發送訊息給{+change.toAll ? '全部玩家' : '特定用戶'} ?
      </ModalText>
      <ModalBtn>
        <Button variant="info" onClick= {handleCheck}>確認</Button>
        <Button variant="secondary" onClick={handleSubmitClose}>返回</Button>
      </ModalBtn>
    </Modal>
    </>
  )
  
}