import React from "react"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { ModalBtn, ModalText } from "../../../middleware/utilityStyle"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { updateUserType } from "../../../middleware/Api/playerInfo/userInfoApi"

export default function ClickControl(props) {
  const {
    showPop,
    handlePopClose,
    display,
    setDisplay,
    originType,
    setOriginType,
    setPanelInfo
  } = props

  const handleCheck = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handlePopClose()
      const {UID, USER_TYPE} = display
      updateUserType(+UID, +USER_TYPE).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setOriginType(+display.USER_TYPE)
        setPanelInfo(prev => ({
          ...prev,
          USER_TYPE: +display.USER_TYPE
        }))
      })
    })
  }

  const handleBack = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handlePopClose()
      setDisplay(prev => ({
        ...prev,
        USER_TYPE: originType
      }))
    })
  }
  return (
    <>
      <Modal
        show={showPop}
        onHide={handleBack}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>確認提示</Modal.Title>
        </Modal.Header>
        <ModalText>
          確定變更此用戶類型？
        </ModalText>
        <ModalBtn>
          <Button variant="info" onClick={handleCheck}>確定</Button>
          <Button variant="secondary" onClick= {handleBack}>返回</Button>
        </ModalBtn>
      </Modal>
    </>
  )
}