import styled from 'styled-components'
import { BarBG, RangePack, DayRange, BasicInput, BasicTable, BasicHeadTitle, TableContent } from '../../../middleware/utilityStyle'

export const InputBarBg = styled(BarBG)`
  @media screen and (max-width: 1895px){
    & {
      flex-direction: column;
      justify-content: center;
    }
  }
`

export const RangeWrapper = styled(RangePack)`
  @media screen and (max-width: 1110px) {
    flex-direction: column;
  }
`

export const DatePickerInput = styled(BasicInput)`
  @media screen and (max-width: 630px) {
    width: 125px;
  }
`

export const RangeGroup = styled(DayRange)`
  @media screen and (max-width: 1110px) {
    margin-top: 10px;
  }
`

export const BtnGroup = styled.div`
  @media screen and (max-width: 1895px){
    & {
      margin-top: 10px;
    }
  }
`

export const TableTitle = styled(BasicTable)`
  table-layout: fixed;
  display: inline-block;
  white-space: nowrap;
`

export const TableForm = styled(BasicTable)`
  table-layout: fixed;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
`

export const TableDate = styled(BasicHeadTitle)`
  width: 200px;
  background: #80e5ff;
`

export const DateInfo = styled(BasicHeadTitle)`
  width: 145px;
  background: #80e5ff;
`

export const MidTitle = styled.th`
  display: block;
  background: #80e5ff;
  width: 201px;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const STitle = styled.th`
  display: block;
  background: #e6faff;
  width: 201px;
`

export const SingleTitle = styled.th`
  display: block;
  background: rgb(235, 235, 235);
  width: 201px;
`

export const SecondLayer = styled.th`
  display: block;
  background: #fdab68;
  width: 201px;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const ThirdLayer = styled.th`
  display: block;
  background: #fee3cd;
  width: 201px;
`

export const Info = styled.td`
  display: block;
  width: 146px;
`

export const NoData = styled.td`
  font-size: 25px;
  font-weight: 600;
  vertical-align: middle;
  width: 100vw;
  border: transparent !important;
`

export const TRow = styled(TableContent)`
  display: inline-block;
`

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: row;
`