import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { sessionVerified, getAllEventList } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import Stack from '@mui/material/Stack'
import prizeType from "../../../assets/config/eventPrizeType.json"
import AddRevisePU from "../../../components/popUpWindow/prizeWeight/AddRevisePU"
import DeletePU from "../../../components/popUpWindow/prizeWeight/DeletePU"
import { getAccessArr } from "../../../middleware/utils"
import { getPrizeWeight } from "../../../middleware/Api/eventManagement/prizeWeightApi"
import {
  InputBarBg,
  NavLeft,
  BtnGroup,
  Dropdown,
  SecondCell,
  BTN
} from "./styledComponent"
import {
  SearchButton,
  ResetButton,
  BasicTable,
  BasicRowTitle,
  TableContent,
  PageStyle,
  TableBtnGroup,
  NewBtn,
  ReviseBtn,
  DeleteBtn,
} from "../../../middleware/utilityStyle.js"

export default function PrizeWeight() {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })

  const history = useHistory()
  const [panelInfo, setPanelInfo] = useState([])
  const [change, setChange] = useState({activityID: 0, state: 0})
  const handleChange = (e, key) => {
    setChange(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }
  const [popData, setPopData] = useState({})
  const [allEvent, setAllEvent] = useState([])
  useEffect(() => {
    getAllEventList().then(db => {
      if (db.error === errorCode.NULL_DATA.code) {
        alert(`※  ${errorCode.NULL_DATA.memo}`)
        return
      }
      if (db.error === errorCode.SERVER_ERROR.code) {
        alert(`※  ${errorCode.SERVER_ERROR.memo}`)
        return
      }
      if (Array.isArray(db.data) && db.data.length) {
        setAllEvent(db.data)
        if (!change.activityID) {
          setChange(prev => ({
            ...prev,
            activityID: db.data[0].ACTIVITY_ID
          }))
        }
      }
    })
  }, [panelInfo, popData])

  const handleRadio = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPopData(panelInfo.find(ele => +ele.AAC_ID === +e.target.value))
    })
  }

  const [changePagePara, setChangePagePara] = useState({
    activityID: '',
    state: 0
  })

  const [pageCounter, setPageCounter] = useState(0)
  const [page, setPage] = useState(1)

  function handleGetPrizeWeight(id, state, sR, eR) {
    getPrizeWeight(id, state, sR, eR).then(db => {
      if (db.error === errorCode.INCORRECT_DATA.code) {
        alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.INCORRECT_FORMAT.code) {
        alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.EXCEPTION.code) {
        alert(`※  ${errorCode.EXCEPTION.memo}`)
        return
      }
      if (db.error === errorCode.SERVER_ERROR.code) {
        alert(`※  ${errorCode.SERVER_ERROR.memo}`)
        return
      }
      if (db.error === errorCode.NULL_DATA.code) {
        alert(`※  ${errorCode.NULL_DATA.memo}`)
        setPanelInfo([])
        return
      }
      setChangePagePara({
        activityID: +id,
        state: +state
      })
      if(db.data) {
        if (db.data.length > 0) {
          setPageCounter(
            db.data[0].TOTAL_ROW_NUM%30 === 0
            ? Math.floor(db.data[0].TOTAL_ROW_NUM/30)
            : (Math.floor(db.data[0].TOTAL_ROW_NUM/30)) + 1
          )
        }
      }
      setPanelInfo(db.data)
      setPage(1)
    })
  }

  const handleChangePage = (event, newPage) => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPage(newPage)
      const {activityID, state} = changePagePara
      handleGetPrizeWeight(activityID, state, 1+(newPage-1)*30, newPage*30)
    })
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleGetPrizeWeight(change.activityID, change.state, 1, 30)
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({activityID: allEvent[0].ACTIVITY_ID, state: 0})
    })
  }

  const [addStatus, setAddStatus] = useState(false)
  const[showAddRevise, setAddReviseShow] = useState(false)
  const handleAddReviseClose = () => setAddReviseShow(false)
  const handleAddReviseShow = () => setAddReviseShow(true)
  const handleAdd = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 65)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      setAddStatus(true)
      handleAddReviseShow()
    })
  }
  const handleRevise = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!+popData.AAC_ID) {
        alert('※  請選擇欲修改的項目')
        return
      }
      if (!userPID.find(ele => +ele === 66)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      setAddStatus(false)
      handleAddReviseShow()
    })
  }

  const [showDeletePU, setDeletePUShow] = useState(false)
  const handleDeletePUClose = () => setDeletePUShow(false)
  const handleDeletePUShow = () => setDeletePUShow(true)
  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!+popData.AAC_ID) {
        alert('※  請選擇欲刪除的項目')
        return
      }
      if (!userPID.find(ele => +ele === 67)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      handleDeletePUShow()
    })
  }

  const handleType = typeNum => {
    if (prizeType.find(ele => +ele.num === +typeNum)) {
      return prizeType.find(ele => +ele.num === +typeNum).name
    }
  }

  const transState = new Map([
    [1, '關閉'],
    [2, '開啟']
  ])
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <NavLeft>
          <div>
            <span>活動名稱</span>
            <Dropdown value= {change.activityID} onChange= {e=> {handleChange(e, 'activityID')}}>
              {
                Array.isArray(allEvent) && allEvent.length ?
                  allEvent.map((ele, index) => {
                    return <option value= {ele.ACTIVITY_ID} key= {index}>{ele.ACTIVITY_NAME}</option>
                  })
                : ''
              }
            </Dropdown>
          </div>
          <SecondCell>
            <span>狀態</span>
            <Dropdown value= {change.state} onChange= {e=> {handleChange(e, 'state')}}>
              <option value= "0">全部</option>
              <option value= "2">有效</option>
              <option value= "1">無效</option>
            </Dropdown>
          </SecondCell>
        </NavLeft>
      
        <BtnGroup>
          <SearchButton onClick={handleSearch}>搜索</SearchButton>
          <ResetButton onClick={handleReset}>重置</ResetButton>
        </BtnGroup>
      </InputBarBg>
      
      <TableBtnGroup>
        <BasicTable className="table table-hover table-bordered">
          <thead>
            <BasicRowTitle>
              <th>
                <input type= "radio" disabled/>
              </th>
              <th>活動名稱</th>
              <th>概率級別</th>
              <th>獎勵類型</th>
              <th>獎勵數量</th>
              <th>狀態</th>
              <th>隨機權重</th>
            </BasicRowTitle>
          </thead>
          <tbody>
            {
              Array.isArray(panelInfo) && panelInfo.length > 0 ?
              panelInfo.map((ele, index) => {
                return (
                  <TableContent key= {index}>
                    <td>
                      <input type= "radio" name= "eventList" value= {ele.AAC_ID} onChange= {handleRadio}/>
                    </td>
                    <td>{allEvent.find(obj => +obj.ACTIVITY_ID === +ele.ACTIVITY_ID).ACTIVITY_NAME}</td>
                    <td>{ele.LEVEL_NUM}</td>
                    <td>{+ele.AWARD_TYPE !==6?handleType(ele.AWARD_TYPE):`${handleType(ele.AWARD_TYPE)}(${ele.ITEM_ID})`}</td>
                    <td>{ele.AWARD_NUM}</td>
                    <td>{transState.get(+ele.STATE)}</td>
                    <td>{ele.RANDOM_WEIGHT}</td>
                  </TableContent>
                )
              })
              : <tr key= "noInfo"><td colSpan= "7">尚無資料</td></tr>
            }
          </tbody>
        </BasicTable>        
        <BTN>
          <NewBtn onClick= {handleAdd}>新增</NewBtn>
          <ReviseBtn onClick= {handleRevise}>修改</ReviseBtn>
          <DeleteBtn onClick= {handleDelete}>刪除</DeleteBtn>
        </BTN>
      </TableBtnGroup>

      <AddRevisePU
        showAddRevise= {showAddRevise}
        handleAddReviseShow= {handleAddReviseShow}
        handleAddReviseClose= {handleAddReviseClose}
        addStatus= {addStatus}
        popData= {popData}
        prizeType= {prizeType}
        setPanelInfo= {setPanelInfo}
        allEvent= {allEvent}
        setPopData= {setPopData}
      />

      <DeletePU
        showDeletePU= {showDeletePU}
        handleDeletePUClose= {handleDeletePUClose}
        popData= {popData}
        setPopData= {setPopData}
        setPanelInfo= {setPanelInfo}
      />

      {
        Array.isArray(panelInfo) && panelInfo.length ?
          <Stack spacing={2}>
            <PageStyle count={pageCounter}
              color="warning"
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </Stack>
        : ''
      }
    </>
  )
}