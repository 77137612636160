import styled from 'styled-components'
import { BarBG, BasicInput, BtnPosition } from '../../../middleware/utilityStyle'

export const InputBarBg = styled(BarBG)`
  @media screen and (max-width: 700px){
    & {
      flex-direction: column;
    }
  }
`

export const InputBarRWD = styled.div`
  display: flex;
` 

export const InputBar = styled.div`
  margin-left: 20px;
`

export const Input = styled(BasicInput)`
  width: 150px;
  margin-left: 20px;
`

export const GameWrapper = styled.div`
  margin-left: 20px;
`

export const Dropdown = styled.select`
  margin-left: 20px;
  border: 1px solid black;
  border-radius: 5px;
  height: 28px;
  width: 150px;
`

export const BtnGroup = styled.div`
  @media screen and (max-width: 700px) {
    margin-top: 10px;
  }
`

export const PUInputBar = styled.div`
  display: flex;
  justify-content: space-evenly;
  : nth-child(2) {
    margin-top: 10px;
  }
`

export const BTN = styled(BtnPosition)`
  width: 500px;
`

export const Title = styled.td`
  text-align: center;
  width: 150px;
`

export const PUInput = styled(BasicInput)`
  width: 120px;
  margin-left: 20px;
  height: 28px;
`

export const PUSelect = styled.select`
  box-sizing: border-box;
  border: 1px solid black;
  border-radius: 5px;
  margin: 0 20px 0 20px;
  height: 28px;
  width: 120px;
`

export const TopTitle = styled.h2`
  text-align: center;
  width: 50rem;
  font-size: 2.5rem;
  margin-bottom: 5px;

  :hover {
    font-weight: bold;
  }
`