import React, { useState, useEffect }from "react"
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import gameTypeList from "../../../assets/config/gameCategories.json"
import Box from '@mui/material/Box'
import {
  PULeftTitle,  
  DateTimeWrapper,
  PUSelect,
  TimeFormat, 
  TimeInput
} from "../../../views/eventManagement/arenaConfig/styledComponent"
import { ModalFormat, PUInput, ModalBtn } from "../../../middleware/utilityStyle"
import { addArenaConfig, reviseArenaConfig } from "../../../middleware/Api/eventManagement/arenaConfigApi.js"
import { FlagFill } from "react-bootstrap-icons"
import { DAY_MARGIN } from "@mui/x-date-pickers/internals";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function AddPU(props) {
  const {
    showAddRevise,
    handleAddReviseShow,
    handleAddReviseClose,
    addStatus,
    popData,
    setPanelInfo,
    setPopData
  } = props
  const history = useHistory()
  const [form, setForm] = useState({
    LADDER_ID: null,
    LADDER_NAME: '',
    LADDER_GAME_TYPE: 31,
    GAME_ID: 7002,
    START_DATE: null,
    END_DATE: null,
    GAME_PRIZE_POOL_STATE: 0,
    GAME_PRIZE_POOL: 0,
    GAME_PRIZE_POOL_WEIGHT: null,
    GAME_PRIZE_POOL_PAY: null,
    ONLINE_ACT_CODE_STATE: 0, 
    ONLINE_ACT_CODE: '', 
    ONLINE_NUM_STATE: 0, 
    ACT_START_TIME: null,
    ACT_END_TIME: null,
    LADDER_WEEK1: '1,1,1,1,1,1,1',
    LADDER_START_TIME1: null,
    LADDER_END_TIME1: null,
    LADDER_WEEK2: '0,0,0,0,0,0,0',
    LADDER_START_TIME2: null,
    LADDER_END_TIME2: null,
    SIGN_UP_START_TIME1: null,
    SIGN_UP_END_TIME1: null,
    SIGN_UP_START_TIME2: null,
    SIGN_UP_END_TIME2: null,
    BONUS_START_TIME1_1: null,
    BONUS_END_TIME1_1: null,
    BOUNS_RATIO1_1: 1,
    BONUS_START_TIME1_2: null,
    BONUS_END_TIME1_2: null,
    BOUNS_RATIO1_2: 1,
    BONUS_START_TIME1_3: null,
    BONUS_END_TIME1_3: null,
    BOUNS_RATIO1_3: 1,
    BONUS_START_TIME2_1: null,
    BONUS_END_TIME2_1: null,
    BOUNS_RATIO2_1: 1,
    BONUS_START_TIME2_2: null,
    BONUS_END_TIME2_2: null,
    BOUNS_RATIO2_2: 1,
    BONUS_START_TIME2_3: null,
    BONUS_END_TIME2_3: null,
    BOUNS_RATIO2_3: 1,
    BONUS_START_WEEK1_1: '0,0,0,0,0,0,0',
    BONUS_START_WEEK1_2: '0,0,0,0,0,0,0',
    BONUS_START_WEEK1_3: '0,0,0,0,0,0,0',
    BONUS_START_WEEK2_1: '0,0,0,0,0,0,0',
    BONUS_START_WEEK2_2: '0,0,0,0,0,0,0',
    BONUS_START_WEEK2_3: '0,0,0,0,0,0,0',
    BONUS_START_DATE1_1: null,
    BONUS_START_DATE1_2: null,
    BONUS_START_DATE1_3: null,
    BONUS_START_DATE2_1: null,
    BONUS_START_DATE2_2: null,
    BONUS_START_DATE2_3: null,
    BONUS_END_DATE1_1: null,
    BONUS_END_DATE1_2: null,
    BONUS_END_DATE1_3: null,
    BONUS_END_DATE2_1: null,
    BONUS_END_DATE2_2: null,
    BONUS_END_DATE2_3: null,
    SCORE_RULE: '0,0,0,0,0,0,5,300,100,1,0,0,0,0,0',
    LADDER_TYPE: 1
  })

  const handleForm = (e, key) => {
    setForm(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const [change, setChange] = useState({typeStatus: 7001})
  const handleChange = (e, key) => {
    setChange(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const handleScoreRule = (e, key) => {
    setScoreRule(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const [scoreRule, setScoreRule] = useState({
    winScore: null,
    loseScore: null, 
    tieScore: null, 
    roundCountLimit: 20,
    stopLoss: 0, 
    deduct: 30
  })

  const [dateGroup, setDateGroup] = useState({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null
  })

  const [bonusDatGroup, setBonusDatGroup] = useState({
    bonusStartDate11: null,
    bonusStartTime11: '1970-01-01T00:00:00.000Z',
    bonusEndDate11: null,
    bonusEndTime11: '1970-01-01T23:59:59.000Z', 
    bonusStartDate12: null,
    bonusStartTime12: '1970-01-01T00:00:00.000Z',
    bonusEndDate12: null,
    bonusEndTime12: '1970-01-01T23:59:59.000Z', 
    bonusStartDate13: null,
    bonusStartTime13: '1970-01-01T00:00:00.000Z',
    bonusEndDate13: null,
    bonusEndTime13: '1970-01-01T23:59:59.000Z'
  })

  const [intervalTimeGroup, setIntervalTimeGroup] = useState({
    startTime11: '1970-01-01T00:00:00.000Z',
    endTime11: '1970-01-01T00:00:00.000Z',
    startTime12: '1970-01-01T00:00:00.000Z',
    endTime12: '1970-01-01T00:00:00.000Z',
    startTime13: '1970-01-01T00:00:00.000Z',
    endTime13: '1970-01-01T00:00:00.000Z', 
    signStartTime: '1970-01-01T00:00:00.000Z',
    signEndTime: '1970-01-01T00:00:00.000Z',
    ladderStartTime: '1970-01-01T00:00:00.000Z',
    ladderEndTime: '1970-01-01T00:00:00.000Z'
  })

  const cleanData = () => {
    setForm({
      LADDER_ID: null,
      LADDER_NAME: '',
      LADDER_GAME_TYPE: 31,
      GAME_ID: 7002,
      START_DATE: null,
      END_DATE: null,
      GAME_PRIZE_POOL_STATE: 0,
      GAME_PRIZE_POOL: 0,
      GAME_PRIZE_POOL_WEIGHT: null,
      GAME_PRIZE_POOL_PAY: null,
      ONLINE_ACT_CODE_STATE: 0, 
      ONLINE_ACT_CODE: '', 
      ONLINE_NUM_STATE: 0, 
      ACT_START_TIME: null,
      ACT_END_TIME: null,
      LADDER_WEEK1: '1,1,1,1,1,1,1',
      LADDER_START_TIME1: null,
      LADDER_END_TIME1: null,
      LADDER_WEEK2: '0,0,0,0,0,0,0',
      LADDER_START_TIME2: null,
      LADDER_END_TIME2: null,
      SIGN_UP_START_TIME1: null,
      SIGN_UP_END_TIME1: null,
      SIGN_UP_START_TIME2: null,
      SIGN_UP_END_TIME2: null,
      BONUS_START_TIME1_1: null,
      BONUS_END_TIME1_1: null,
      BOUNS_RATIO1_1: 1,
      BONUS_START_TIME1_2: null,
      BONUS_END_TIME1_2: null,
      BOUNS_RATIO1_2: 1,
      BONUS_START_TIME1_3: null,
      BONUS_END_TIME1_3: null,
      BOUNS_RATIO1_3: 1,
      BONUS_START_TIME2_1: null,
      BONUS_END_TIME2_1: null,
      BOUNS_RATIO2_1: 1,
      BONUS_START_TIME2_2: null,
      BONUS_END_TIME2_2: null,
      BOUNS_RATIO2_2: 1,
      BONUS_START_TIME2_3: null,
      BONUS_END_TIME2_3: null,
      BOUNS_RATIO2_3: 1,
      BONUS_START_WEEK1_1: '0,0,0,0,0,0,0',
      BONUS_START_WEEK1_2: '0,0,0,0,0,0,0',
      BONUS_START_WEEK1_3: '0,0,0,0,0,0,0',
      BONUS_START_WEEK2_1: '0,0,0,0,0,0,0',
      BONUS_START_WEEK2_2: '0,0,0,0,0,0,0',
      BONUS_START_WEEK2_3: '0,0,0,0,0,0,0',
      BONUS_START_DATE1_1: null,
      BONUS_START_DATE1_2: null,
      BONUS_START_DATE1_3: null,
      BONUS_START_DATE2_1: null,
      BONUS_START_DATE2_2: null,
      BONUS_START_DATE2_3: null,
      BONUS_END_DATE1_1: null,
      BONUS_END_DATE1_2: null,
      BONUS_END_DATE1_3: null,
      BONUS_END_DATE2_1: null,
      BONUS_END_DATE2_2: null,
      BONUS_END_DATE2_3: null,
      SCORE_RULE: '0,0,0,0,0,0,5,300,100,1,0,0,0,0,0',
      LADDER_TYPE: 1
    })
    setDateGroup({
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null
    })
    setScoreRule({
      winScore: null,
      loseScore: null, 
      tieScore: null, 
      roundCountLimit: 20,
      stopLoss: 0, 
      deduct: 30
    })
    setChange({
      typeStatus: 7001
    })
    setIntervalTimeGroup({
      startTime11: '1970-01-01T00:00:00.000Z',
      endTime11: '1970-01-01T00:00:00.000Z',
      startTime12: '1970-01-01T00:00:00.000Z',
      endTime12: '1970-01-01T00:00:00.000Z',
      startTime13: '1970-01-01T00:00:00.000Z',
      endTime13: '1970-01-01T00:00:00.000Z', 
      signStartTime: '1970-01-01T00:00:00.000Z',
      signEndTime: '1970-01-01T00:00:00.000Z',
      ladderStartTime: '1970-01-01T00:00:00.000Z',
      ladderEndTime: '1970-01-01T00:00:00.000Z'
    })
    setBonusDatGroup({
      bonusStartDate11: null,
      bonusStartTime11: '1970-01-01 00:00:00 +08:00',
      bonusEndDate11: null,
      bonusEndTime11: '1970-01-01 23:59:59 +08:00', 
      bonusStartDate12: null,
      bonusStartTime12: '1970-01-01 00:00:00 +08:00',
      bonusEndDate12: null,
      bonusEndTime12: '1970-01-01 23:59:59 +08:00', 
      bonusStartDate13: null,
      bonusStartTime13: '1970-01-01 00:00:00 +08:00',
      bonusEndDate13: null,
      bonusEndTime13: '1970-01-01 23:59:59 +08:00'
    })
  }

  const dayCheck = 0;

  const handleRadio = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPopData(panelInfo.find(ele => +ele.LADDER_ID === +e.target.value))
    })
  }
  
  useEffect(() => {    
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if(!addStatus) {
        setForm(JSON.parse(JSON.stringify(popData)))
        setDateGroup({
          startDate: popData.ACT_START_TIME,
          startTime: popData.ACT_START_TIME,
          endDate: popData.ACT_END_TIME,
          endTime: popData.ACT_END_TIME,
        })

        let scoceRuleList = popData.SCORE_RULE.split(",")
        setScoreRule({
          winScore: scoceRuleList[0],
          loseScore: scoceRuleList[1], 
          tieScore: scoceRuleList[2], 
          roundCountLimit: scoceRuleList[3], 
          stopLoss: scoceRuleList[5], 
          deduct: scoceRuleList[4]
        })

        setChange({
          typeStatus: +popData.GAME_ID
        })

        setIntervalTimeGroup({
          startTime11: popData.BONUS_START_TIME1_1,
          endTime11: popData.BONUS_END_TIME1_1,
          startTime12: popData.BONUS_START_TIME1_2,
          endTime12: popData.BONUS_END_TIME1_2,
          startTime13: popData.BONUS_START_TIME1_3,
          endTime13: popData.BONUS_END_TIME1_3, 
          signStartTime: popData.SIGN_UP_START_TIME1,
          signEndTime: popData.SIGN_UP_END_TIME1,
          ladderStartTime: popData.LADDER_START_TIME1,
          ladderEndTime: popData.LADDER_END_TIME1
        })

        setBonusDatGroup({
          bonusStartDate11: popData.BONUS_START_DATE1_1,
          bonusStartTime11: '1970-01-01 00:00:00 +08:00',
          bonusEndDate11: popData.BONUS_END_DATE1_1,
          bonusEndTime11: '1970-01-01 23:59:59 +08:00', 
          bonusStartDate12: popData.BONUS_START_DATE1_2,
          bonusStartTime12: '1970-01-01 00:00:00 +08:00',
          bonusEndDate12: popData.BONUS_END_DATE1_2,
          bonusEndTime12: '1970-01-01 23:59:59 +08:00', 
          bonusStartDate13: popData.BONUS_START_DATE1_3,
          bonusStartTime13: '1970-01-01 00:00:00 +08:00',
          bonusEndDate13: popData.BONUS_END_DATE1_3,
          bonusEndTime13: '1970-01-01 23:59:59 +08:00'
        })
      }
      if(addStatus) {
        cleanData()
      }
    })
  }, [popData, addStatus])

  async function handleSubmit () {
    sessionVerified().then(async db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      
      if (!+form.LADDER_ID) {
        alert('※  請輸入競技活動ID，且不可為 0')
        return
      }

      if (!form.GAME_PRIZE_POOL_PAY && form.GAME_PRIZE_POOL_PAY != 0) {
        alert('※  請輸入報名費用')
        return
      }

      if (isNaN(+form.GAME_PRIZE_POOL_PAY)) {
        alert('※  報名費用欄位請輸入數字')
        setForm(prev => ({
          ...prev,
          MIN_RATIO: '', 
          MAX_RATIO: ''
        }))
        return
      }
      if (+form.GAME_PRIZE_POOL_PAY < 0) {
        alert('※  報名費用不得為負數')
        return
      }

      if (!form.LADDER_NAME) {
        alert('※  請輸入競技活動名稱')
        return
      }

      if (!form.GAME_PRIZE_POOL_WEIGHT && form.GAME_PRIZE_POOL_WEIGHT != 0) {
        alert('※  請輸入彩金池%數')
        return
      }
      
      for (let key in dateGroup) {
        if (!dateGroup[key]) {
          alert('※  開始及結束的日期、時間不可為空')
        return
        }
      }

      let saveStartTime = new Date(
        new Date(dateGroup.startDate).getFullYear(),
        new Date(dateGroup.startDate).getMonth(),
        new Date(dateGroup.startDate).getDate(),
        new Date(dateGroup.startTime).getHours(),
        new Date(dateGroup.startTime).getMinutes()
        )
  
      let saveEndTime = new Date(
        new Date(dateGroup.endDate).getFullYear(),
        new Date(dateGroup.endDate).getMonth(),
        new Date(dateGroup.endDate).getDate(),
        new Date(dateGroup.endTime).getHours(),
        new Date(dateGroup.endTime).getMinutes()
      )

      let saveBonusStartDate11 = new Date(
        new Date(bonusDatGroup.bonusStartDate11).getFullYear(),
        new Date(bonusDatGroup.bonusStartDate11).getMonth(),
        new Date(bonusDatGroup.bonusStartDate11).getDate(),
        new Date(bonusDatGroup.bonusStartTime11).getHours(),
        new Date(bonusDatGroup.bonusStartTime11).getMinutes()
      )

      let saveBonusEndDate11 = new Date(
        new Date(bonusDatGroup.bonusEndDate11).getFullYear(),
        new Date(bonusDatGroup.bonusEndDate11).getMonth(),
        new Date(bonusDatGroup.bonusEndDate11).getDate(),
        new Date(bonusDatGroup.bonusEndTime11).getHours(),
        new Date(bonusDatGroup.bonusEndTime11).getMinutes()
      )

      let saveBonusStartDate12 = new Date(
        new Date(bonusDatGroup.bonusStartDate12).getFullYear(),
        new Date(bonusDatGroup.bonusStartDate12).getMonth(),
        new Date(bonusDatGroup.bonusStartDate12).getDate(),
        new Date(bonusDatGroup.bonusStartTime12).getHours(),
        new Date(bonusDatGroup.bonusStartTime12).getMinutes()
      )
  
      let saveBonusEndDate12 = new Date(
        new Date(bonusDatGroup.bonusEndDate12).getFullYear(),
        new Date(bonusDatGroup.bonusEndDate12).getMonth(),
        new Date(bonusDatGroup.bonusEndDate12).getDate(),
        new Date(bonusDatGroup.bonusEndTime12).getHours(),
        new Date(bonusDatGroup.bonusEndTime12).getMinutes()
      )

      let saveBonusStartDate13 = new Date(
        new Date(bonusDatGroup.bonusStartDate13).getFullYear(),
        new Date(bonusDatGroup.bonusStartDate13).getMonth(),
        new Date(bonusDatGroup.bonusStartDate13).getDate(),
        new Date(bonusDatGroup.bonusStartTime13).getHours(),
        new Date(bonusDatGroup.bonusStartTime13).getMinutes()
      )
  
      let saveBonusEndDate13 = new Date(
        new Date(bonusDatGroup.bonusEndDate13).getFullYear(),
        new Date(bonusDatGroup.bonusEndDate13).getMonth(),
        new Date(bonusDatGroup.bonusEndDate13).getDate(),
        new Date(bonusDatGroup.bonusEndTime13).getHours(),
        new Date(bonusDatGroup.bonusEndTime13).getMinutes()
      )

      if (saveStartTime > saveEndTime) {
        alert('※  結束時間不可早於開始時間')
        return
      }

      let saveScoreRule = form.GAME_ID == 7001 ? "30,15,10,10,0,0,5,0,0,0,0,0,0,0,0" : "0,0,0,0,0,0,5,300,100,1,0,0,0,0,0"
      let tempScoreRule = saveScoreRule.split(",")
      tempScoreRule[0] = scoreRule.winScore ? scoreRule.winScore : 0
      tempScoreRule[1] = scoreRule.loseScore ? scoreRule.loseScore : 0
      tempScoreRule[2] = scoreRule.tieScore ? scoreRule.tieScore : 0
      tempScoreRule[3] = scoreRule.roundCountLimit
      tempScoreRule[5] = scoreRule.stopLoss
      tempScoreRule[4] = scoreRule.deduct
      saveScoreRule = tempScoreRule.toString()
      
      if (!addStatus) {
        reviseArenaConfig(
          +form.LADDER_ID,
          form.LADDER_NAME, 
          form.LADDER_GAME_TYPE, 
          +change.typeStatus, 
          saveStartTime, 
          saveEndTime,
          +form.GAME_PRIZE_POOL_STATE, 
          +form.GAME_PRIZE_POOL, 
          form.GAME_PRIZE_POOL_WEIGHT, 
          +form.GAME_PRIZE_POOL_PAY, 
          +form.ONLINE_ACT_CODE_STATE, 
          form.ONLINE_ACT_CODE, 
          +form.ONLINE_NUM_STATE, 
          saveStartTime, 
          saveEndTime, 
          form.LADDER_WEEK1, 
          intervalTimeGroup.ladderStartTime, 
          intervalTimeGroup.ladderEndTime, 
          form.LADDER_WEEK2, 
          form.LADDER_START_TIME2, 
          form.LADDER_END_TIME2,
          intervalTimeGroup.signStartTime, 
          intervalTimeGroup.signEndTime, 
          form.SIGN_UP_START_TIME2, 
          form.SIGN_UP_END_TIME2, 
          intervalTimeGroup.startTime11 ? intervalTimeGroup.startTime11 : '1970-01-01T00:00:00.000Z', 
          intervalTimeGroup.endTime11 ? intervalTimeGroup.endTime11 : '1970-01-01T00:00:00.000Z', 
          form.BOUNS_RATIO1_1, 
          intervalTimeGroup.startTime12 ? intervalTimeGroup.startTime12 : '1970-01-01T00:00:00.000Z', 
          intervalTimeGroup.endTime12 ? intervalTimeGroup.endTime12 : '1970-01-01T00:00:00.000Z', 
          form.BOUNS_RATIO1_2, 
          intervalTimeGroup.startTime13 ? intervalTimeGroup.startTime13 : '1970-01-01T00:00:00.000Z', 
          intervalTimeGroup.endTime13 ? intervalTimeGroup.endTime13 : '1970-01-01T00:00:00.000Z', 
          form.BOUNS_RATIO1_3, 
          form.BONUS_START_TIME2_1, 
          form.BONUS_END_TIME2_1, 
          form.BOUNS_RATIO2_1, 
          form.BONUS_START_TIME2_2, 
          form.BONUS_END_TIME2_2, 
          form.BOUNS_RATIO2_2, 
          form.BONUS_START_TIME2_3, 
          form.BONUS_END_TIME2_3, 
          form.BOUNS_RATIO2_3, 
          form.BOUNS_RATIO1_1 > 1 ? '1,1,1,1,1,1,1' : '0,0,0,0,0,0,0',
          form.BOUNS_RATIO1_1 > 1 ? '1,1,1,1,1,1,1' : '0,0,0,0,0,0,0',
          form.BOUNS_RATIO1_1 > 1 ? '1,1,1,1,1,1,1' : '0,0,0,0,0,0,0',
          form.BONUS_START_WEEK2_1,
          form.BONUS_START_WEEK2_2,
          form.BONUS_START_WEEK2_3,
          saveBonusStartDate11,
          saveBonusStartDate12,
          saveBonusStartDate13,
          form.BONUS_START_DATE2_1,
          form.BONUS_START_DATE2_2,
          form.BONUS_START_DATE2_3,
          saveBonusEndDate11,
          saveBonusEndDate12,
          saveBonusEndDate13,
          form.BONUS_END_DATE2_1,
          form.BONUS_END_DATE2_2,
          form.BONUS_END_DATE2_3,
          saveScoreRule, 
          +form.LADDER_TYPE).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          if (db.error === errorCode.SERVER_ERROR.code) {
            alert(`※  ${errorCode.SERVER_ERROR.memo}`)
            return
          }
          setPanelInfo([])
          setPopData({})
        })
      }
      if (addStatus) {
        addArenaConfig(
          +form.LADDER_ID,
          form.LADDER_NAME, 
          form.LADDER_GAME_TYPE, 
          +change.typeStatus, 
          saveStartTime, 
          saveEndTime,
          +form.GAME_PRIZE_POOL_STATE, 
          +form.GAME_PRIZE_POOL, 
          form.GAME_PRIZE_POOL_WEIGHT, 
          +form.GAME_PRIZE_POOL_PAY, 
          +form.ONLINE_ACT_CODE_STATE, 
          form.ONLINE_ACT_CODE, 
          +form.ONLINE_NUM_STATE, 
          saveStartTime, 
          saveEndTime, 
          form.LADDER_WEEK1, 
          intervalTimeGroup.ladderStartTime, 
          intervalTimeGroup.ladderEndTime, 
          form.LADDER_WEEK2, 
          form.LADDER_START_TIME2 ? form.LADDER_START_TIME2 : '1970-01-01T00:00:00.000Z', 
          form.LADDER_END_TIME2 ? form.LADDER_END_TIME2 : '1970-01-01T00:00:00.000Z',
          intervalTimeGroup.signStartTime, 
          intervalTimeGroup.signEndTime, 
          form.SIGN_UP_START_TIME2 ? form.SIGN_UP_START_TIME2 : '1970-01-01T00:00:00.000Z', 
          form.SIGN_UP_END_TIME2 ? form.SIGN_UP_END_TIME2 : '1970-01-01T00:00:00.000Z', 
          intervalTimeGroup.startTime11 ? intervalTimeGroup.startTime11 : '1970-01-01T00:00:00.000Z', 
          intervalTimeGroup.endTime11 ? intervalTimeGroup.endTime11 : '1970-01-01T00:00:00.000Z', 
          form.BOUNS_RATIO1_1, 
          intervalTimeGroup.startTime12 ? intervalTimeGroup.startTime12 : '1970-01-01T00:00:00.000Z', 
          intervalTimeGroup.endTime12 ? intervalTimeGroup.endTime12 : '1970-01-01T00:00:00.000Z', 
          form.BOUNS_RATIO1_2, 
          intervalTimeGroup.startTime13 ? intervalTimeGroup.startTime13 : '1970-01-01T00:00:00.000Z', 
          intervalTimeGroup.endTime13 ? intervalTimeGroup.endTime13 : '1970-01-01T00:00:00.000Z', 
          form.BOUNS_RATIO1_3, 
          form.BONUS_START_TIME2_1 ? form.BONUS_START_TIME2_1 : '1970-01-01T00:00:00.000Z',
          form.BONUS_END_TIME2_1 ? form.BONUS_END_TIME2_1 : '1970-01-01T00:00:00.000Z',
          form.BOUNS_RATIO2_1, 
          form.BONUS_START_TIME2_2 ? form.BONUS_START_TIME2_2 : '1970-01-01T00:00:00.000Z',
          form.BONUS_END_TIME2_2 ? form.BONUS_END_TIME2_2 : '1970-01-01T00:00:00.000Z',
          form.BOUNS_RATIO2_2, 
          form.BONUS_START_TIME2_3 ? form.BONUS_START_TIME2_3 : '1970-01-01T00:00:00.000Z',
          form.BONUS_END_TIME2_3 ? form.BONUS_END_TIME2_3 : '1970-01-01T00:00:00.000Z',
          form.BOUNS_RATIO2_3, 
          form.BOUNS_RATIO1_1 > 1 ? '1,1,1,1,1,1,1' : '0,0,0,0,0,0,0',
          form.BOUNS_RATIO1_1 > 1 ? '1,1,1,1,1,1,1' : '0,0,0,0,0,0,0',
          form.BOUNS_RATIO1_1 > 1 ? '1,1,1,1,1,1,1' : '0,0,0,0,0,0,0',
          form.BONUS_START_WEEK2_1,
          form.BONUS_START_WEEK2_2,
          form.BONUS_START_WEEK2_3,
          saveBonusStartDate11,
          saveBonusStartDate12,
          saveBonusStartDate13,
          form.BONUS_START_DATE2_1 ? form.BONUS_START_DATE2_1 : new Date('1970-01-01 00:00:00 +08:00'), 
          form.BONUS_START_DATE2_2 ? form.BONUS_START_DATE2_2 : new Date('1970-01-01 00:00:00 +08:00'), 
          form.BONUS_START_DATE2_3 ? form.BONUS_START_DATE2_3 : new Date('1970-01-01 00:00:00 +08:00'), 
          saveBonusEndDate11,
          saveBonusEndDate12,
          saveBonusEndDate13,
          form.BONUS_END_DATE2_1 ? form.BONUS_END_DATE2_1 : new Date('1970-01-01 00:00:00 +08:00'), 
          form.BONUS_END_DATE2_2 ? form.BONUS_END_DATE2_2 : new Date('1970-01-01 00:00:00 +08:00'), 
          form.BONUS_END_DATE2_3 ? form.BONUS_END_DATE2_3 : new Date('1970-01-01 00:00:00 +08:00'), 
          saveScoreRule, 
          +form.LADDER_TYPE
        ).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          if (db.error === errorCode.SERVER_ERROR.code) {
            alert(`※  ${errorCode.SERVER_ERROR.memo}`)
            return
          }
          setPanelInfo([])
          setPopData({})
        })
      }

      cleanData()
      handleConfirmClose()
      handleAddReviseClose()
    })    
  }

  const handleBack = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleAddReviseClose()
      setTimeout(() => {
        if (addStatus) {
          cleanData()
        }
        if (!addStatus) {
          setForm(JSON.parse(JSON.stringify(popData)))
          setDateGroup({
            startDate: popData.BEGIN_DATE,
            startTime: popData.BEGIN_DATE,
            endDate: popData.END_DATE,
            endTime: popData.END_DATE
          })
          let scoceRuleList = popData.SCORE_RULE.split(",")
          setScoreRule({
            winScore: scoceRuleList[0],
            loseScore: scoceRuleList[1], 
            tieScore: scoceRuleList[2], 
            roundCountLimit: scoceRuleList[3], 
            stopLoss: scoceRuleList[5],
            deduct: scoceRuleList[4]
          })
          setChange({
            typeStatus: popData.GAME_ID
          })
          setIntervalTimeGroup({
            startTime11: popData.BONUS_START_TIME1_1,
            endTime11: popData.BONUS_END_TIME1_1,
            startTime12: popData.BONUS_START_TIME1_2,
            endTime12: popData.BONUS_END_TIME1_2,
            startTime13: popData.BONUS_START_TIME1_3,
            endTime13: popData.BONUS_END_TIME1_3, 
            signStartTime: popData.SIGN_UP_START_TIME1,
            signEndTime: popData.SIGN_UP_END_TIME1,
            ladderStartTime: popData.LADDER_START_TIME1,
            ladderEndTime: popData.LADDER_END_TIME1
          })
          setBonusDatGroup({
            bonusStartDate11: popData.BONUS_START_DATE1_1,
            bonusStartTime11: '1970-01-01 00:00:00 +08:00',
            bonusEndDate11: popData.BONUS_END_DATE1_1,
            bonusEndTime11: '1970-01-01 23:59:59 +08:00', 
            bonusStartDate12: popData.BONUS_START_DATE1_2,
            bonusStartTime12: '1970-01-01 00:00:00 +08:00',
            bonusEndDate12: popData.BONUS_END_DATE1_2,
            bonusEndTime12: '1970-01-01 23:59:59 +08:00', 
            bonusStartDate13: popData.BONUS_START_DATE1_3,
            bonusStartTime13: '1970-01-01 00:00:00 +08:00',
            bonusEndDate13: popData.BONUS_END_DATE1_3,
            bonusEndTime13: '1970-01-01 23:59:59 +08:00'
          })
        }
      }, 500)
    })
  }

  const handleOnHide = () => {
    handleAddReviseClose()
    if (addStatus) {
      cleanData()
    }
  }

  const handleDate = e => {
    let saveStartTime = new Date(
      new Date(dateGroup.startDate).getFullYear(),
      new Date(dateGroup.startDate).getMonth(),
      new Date(dateGroup.startDate).getDate(),
      new Date(dateGroup.startTime).getHours(),
      new Date(dateGroup.startTime).getMinutes()
      )

    let saveEndTime = new Date(
      new Date(dateGroup.endDate).getFullYear(),
      new Date(dateGroup.endDate).getMonth(),
      new Date(dateGroup.endDate).getDate(),
      new Date(dateGroup.endTime).getHours(),
      new Date(dateGroup.endTime).getMinutes()
    )

    setForm(prev => ({
      ...prev,
      BEGIN_DATE: saveStartTime,
      END_DATE: saveEndTime
    }))
  }

  const handleTime = e => {
    setForm(prev => ({
      ...prev,
      SIGN_UP_START_TIME1: intervalTimeGroup.signStartTime, 
      SIGN_UP_END_TIME1: intervalTimeGroup.signEndTime,
      LADDER_START_TIME1: intervalTimeGroup.ladderStartTime, 
      LADDER_END_TIME1: intervalTimeGroup.ladderEndTime,
      BONUS_START_TIME1_1: intervalTimeGroup.startTime11, 
      BONUS_END_TIME1_1: intervalTimeGroup.endTime11,
      BONUS_START_TIME1_2: intervalTimeGroup.startTime12, 
      BONUS_END_TIME1_2: intervalTimeGroup.endTime12,
      BONUS_START_TIME1_3: intervalTimeGroup.startTime13, 
      BONUS_END_TIME1_3: intervalTimeGroup.endTime13
    }))
  }

  const [showConfirm, setConfirmShow] = useState(false)
  const handleConfirmClose = () => setConfirmShow(false)
  const handleConfirmShow = () => setConfirmShow(true)

  function handleGameType() {
    let list = []
    for (let i = 0; i < gameTypeList.length; i++) {
      if (+gameTypeList[i].gameID < 7003) {
        let checked = +gameTypeList[i].gameID == form.GAME_ID ? "checked" : ""
        //list.push(<input type= "radio" id={gameTypeList[i].gameID} name= "arenaList" value= {+gameTypeList[i].gameID} onChange= {e => {handleChange(e, 'typeStatus')}} checked/>)
        list.push(
        <div className="form-check form-check-inline"><input
                        className="form-check-input"
                        type="radio"
                        value= {+gameTypeList[i].gameID}
                        onChange= {e => {handleChange(e, 'typeStatus')}}
                        checked= {+change.typeStatus === +gameTypeList[i].gameID ? true : false}
                      />{gameTypeList[i].gameName}&nbsp;&nbsp;
                  </div>)

        //list.push(<label for={gameTypeList[i].gameID}></label>)
      }
    }    
    return list
  }

  return (
    <>
      <ModalFormat
        show={showAddRevise}
        onHide={handleOnHide}
        backdrop="static"
        keyboard={false}
        size= "lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{addStatus ? '新增' : '修改'}競技活動配置</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <PULeftTitle>競技活動ID</PULeftTitle>
                <td>
                  <PUInput placeholder= {addStatus ? '請輸入競技活動ID' : form.LADDER_ID} value= {form.LADDER_ID}
                    onChange= {e => {handleForm(e, 'LADDER_ID')}}
                    disabled={addStatus ? false : true} style= {addStatus ? {} : {cursor: 'not-allowed'}}
                  />
                </td>
              </tr>
              <tr>
                <PULeftTitle>競技類型</PULeftTitle>
                <td>
                  積分賽
                </td>
              </tr>
              <tr>
                <PULeftTitle>級別</PULeftTitle>
                <td>
                  <PUSelect value= {form.GAME_ID} onChange= {e => {handleForm(e, 'GAME_ID')}}>
                    <option value= "0" disabled={true} selected>無</option>
                  </PUSelect>
                </td>
              </tr>
              <tr>
                <PULeftTitle>競技遊戲</PULeftTitle>
                <td>
                  {handleGameType()}
                </td>
              </tr>
              <tr>
                <PULeftTitle>競技活動名稱</PULeftTitle>
                <td>
                  <PUInput placeholder= "請輸入競技活動名稱" value= {form.LADDER_NAME} onChange= {e => {handleForm(e, 'LADDER_NAME')}}/>
                </td>
              </tr>
              <tr>
                <PULeftTitle>活動狀態</PULeftTitle>
                <td>
                  <PUSelect value= {form.GAME_TYPE} onChange= {e => {handleForm(e, 'GAME_TYPE')}}>
                    <option value= "1" disabled={true} selected>開啟</option>
                  </PUSelect>
                </td>
              </tr>



              <tr>
                <PULeftTitle>報名費用</PULeftTitle>
                <td>
                  <PUInput placeholder= "請輸入數字" value= {form.GAME_PRIZE_POOL_PAY} onChange= {e => {handleForm(e, 'GAME_PRIZE_POOL_PAY')}}/>
                  &nbsp;金幣
                </td>
              </tr>
              <tr>
                <PULeftTitle>彩金池</PULeftTitle>
                <td>
                  <PUSelect value= {form.GAME_PRIZE_POOL_STATE} onChange= {e => {handleForm(e, 'GAME_PRIZE_POOL_STATE')}}>
                    <option value= "0">關閉</option>
                    <option value= "1">開啟</option>
                  </PUSelect>
                </td>
              </tr>
              <tr>
                <PULeftTitle>報名費用入彩金池%</PULeftTitle>
                <td>
                  <PUInput placeholder= "請輸入數字" value= {form.GAME_PRIZE_POOL_WEIGHT} onChange= {e => {handleForm(e, 'GAME_PRIZE_POOL_WEIGHT')}}/>
                  &nbsp;%
                </td>
              </tr>

              <tr>
                <PULeftTitle>報名開始時間</PULeftTitle>
                <td>
                  <DateTimeWrapper>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        value={dayjs.utc(intervalTimeGroup.signStartTime)}
                        timezone="Asia/Taipei"
                        onChange={(newValue) => {
                          setIntervalTimeGroup(prev => ({
                            ...prev,
                            signStartTime: dayjs.utc(newValue)
                          }))
                          handleTime()
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇時間'
                          return (
                            <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                              <TimeInput id= "timePicker1" ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </TimeFormat>
                          )
                        }}
                      />
                    </LocalizationProvider>
                  </DateTimeWrapper>
                </td>
              </tr>

              <tr>
                <PULeftTitle>報名結束時間</PULeftTitle>
                <td>
                  <DateTimeWrapper>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        value={dayjs.utc(intervalTimeGroup.signEndTime)}
                        timezone="Asia/Taipei"
                        onChange={(newValue) => {
                          setIntervalTimeGroup(prev => ({
                            ...prev,
                            signEndTime: dayjs.utc(newValue)
                          }))
                          handleTime()
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇時間'
                          return (
                            <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                              <TimeInput id= "timePicker2" ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </TimeFormat>
                          )
                        }}
                      />
                    </LocalizationProvider>
                  </DateTimeWrapper>
                </td>
              </tr>    

              <tr>
                <PULeftTitle>競技開始時間</PULeftTitle>
                <td>
                  <DateTimeWrapper>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        value={dayjs.utc(intervalTimeGroup.ladderStartTime)}
                        timezone="Asia/Taipei"
                        onChange={(newValue) => {
                          setIntervalTimeGroup(prev => ({
                            ...prev,
                            ladderStartTime: newValue
                          }))
                          handleTime()
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇時間'
                          return (
                            <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                              <TimeInput id= "timePicker11" ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </TimeFormat>
                          )
                        }}
                      />
                    </LocalizationProvider>
                  </DateTimeWrapper>
                </td>
              </tr>

              <tr>
                <PULeftTitle>競技結束時間</PULeftTitle>
                <td>
                  <DateTimeWrapper>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        value={dayjs.utc(intervalTimeGroup.ladderEndTime)}
                        timezone="Asia/Taipei"
                        onChange={(newValue) => {
                          setIntervalTimeGroup(prev => ({
                            ...prev,
                            ladderEndTime: newValue
                          }))
                          handleTime()
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇時間'
                          return (
                            <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                              <TimeInput id= "timePicker12" ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </TimeFormat>
                          )
                        }}
                      />
                    </LocalizationProvider>
                  </DateTimeWrapper>
                </td>
              </tr>          

              <tr>
                <PULeftTitle>活動開始時間</PULeftTitle>
                <td>
                  <DateTimeWrapper>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label= "日期"
                        value={dateGroup.startDate}
                        onChange={(newValue) => {
                          setDateGroup(prev => ({
                            ...prev,
                            startDate: newValue
                          }))
                          handleDate()
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇日期'
                          return (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <PUInput id= "datePicker1" ref={inputRef} {...inputProps} />
                              <div id="dateBtn1">{InputProps?.endAdornment}</div>
                            </Box>
                          )
                        }}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="時間"
                        value={dateGroup.startTime}
                        onChange={(newValue) => {
                          setDateGroup(prev => ({
                            ...prev,
                            startTime: newValue
                          }))
                          handleDate()
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇時間'
                          return (
                            <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                              <PUInput id= "timePicker3" ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </TimeFormat>
                          )
                        }}
                      />
                    </LocalizationProvider>
                  </DateTimeWrapper>
                </td>
              </tr>
              <tr>
                <PULeftTitle>活動結束時間</PULeftTitle>
                <td>
                  <DateTimeWrapper>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label= "日期"
                        value={dateGroup.endDate}
                        onChange={(newValue) => {
                          setDateGroup(prev => ({
                            ...prev,
                            endDate: newValue
                          }))
                          handleDate()
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇日期'
                          return (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <PUInput id= "datePicker2" ref={inputRef} {...inputProps} />
                              <div id="dateBtn2">{InputProps?.endAdornment}</div>
                            </Box>
                          )
                        }}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="時間"
                        value={dateGroup.endTime}
                        onChange={(newValue) => {
                          setDateGroup(prev => ({
                            ...prev,
                            endTime: newValue
                          }))
                          handleDate()
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇時間'
                          return (
                            <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                              <PUInput id= "timePicker4" ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </TimeFormat>
                          )
                        }}
                      />
                    </LocalizationProvider>
                  </DateTimeWrapper>
                </td>
              </tr>

              <tr>
                <PULeftTitle>分數設定</PULeftTitle>
                <td> 
                  <pre>                  
                    勝場計分&nbsp;
                    <PUSelect disabled={true} value= {change.typeStatus == 7001 ? "winner" : "score"} onChange= {e => {}}>
                      <option value= "winner">贏家</option>
                      <option value= "score">以結算分數計</option>                    
                    </PUSelect>
                    &nbsp;得分&nbsp;
                    <PUInput disabled={change.typeStatus == 7001 ? false : true} placeholder= "請輸入分數" value= {scoreRule.winScore} onChange= {e => {handleScoreRule(e, 'winScore')}}/>
                  </pre>
                  <pre> 
                    敗場計分&nbsp;
                    <PUSelect disabled={true} value= {+change.typeStatus == 7001 ? "winner" : "score"} onChange= {e => {}}>
                      <option value= "winner">輸家</option>
                      <option value= "score">以結算分數計</option>
                    </PUSelect>
                    &nbsp;得分&nbsp;
                    <PUInput disabled={change.typeStatus == 7001 ? false : true} placeholder= "請輸入分數" value= {scoreRule.loseScore} onChange= {e => {handleScoreRule(e, 'loseScore')}}/>
                  </pre>
                  <pre> 
                    和局計分&nbsp;
                    <PUSelect disabled={true} value= {change.typeStatus == 7001 ? "winner" : "score"} onChange= {e => {}}>
                      <option value= "winner">平局</option>
                      <option value= "score">以結算分數計</option>                    
                    </PUSelect>
                    &nbsp;得分&nbsp;
                    <PUInput disabled={change.typeStatus == 7001 ? false : true} placeholder= "請輸入分數" value= {scoreRule.tieScore} onChange= {e => {handleScoreRule(e, 'tieScore')}}/>
                  </pre>
                  <pre> 
                    投降計分（暗棋）&nbsp;
                    <PUSelect value= {change.typeStatus == 7001 ? scoreRule.roundCountLimit : 0} onChange= {e => {handleScoreRule(e, 'roundCountLimit')}}>
                      <option value= "0">一律計分</option>
                      <option value= "5">5步以下</option>
                      <option value= "10">10步以下</option>
                      <option value= "15">15步以下</option>
                      <option value= "20">20步以下</option>
                      <option value= "99999">不計分</option>
                    </PUSelect>
                  </pre>
                  <pre> 
                    倒扣分數（請輸入正數）&nbsp;
                    <PUInput disabled={change.typeStatus == 7001 ? false : true} placeholder= "請輸入分數" value= {scoreRule.deduct} onChange= {e => {handleScoreRule(e, 'deduct')}}/>
                  </pre>
                  <pre> 
                    負分開關&nbsp;
                    <PUSelect value= {scoreRule.stopLoss} onChange= {e => {handleScoreRule(e, 'stopLoss')}}>
                      <option value= "0">關閉</option>
                      <option value= "1">開啟</option>                    
                    </PUSelect>
                  </pre>
                </td>
              </tr>
              

              <tr>
                <PULeftTitle>活動加成1</PULeftTitle>
                <td>      
                  <pre>                  
                    乘倍值&nbsp;
                    <PUSelect value= {form.BOUNS_RATIO1_1} onChange= {e => {handleForm(e, 'BOUNS_RATIO1_1')}}>
                      <option value= "1.0">1.0</option>
                      <option value= "1.1">1.1</option>
                      <option value= "1.2">1.2</option>
                      <option value= "1.3">1.3</option>
                      <option value= "1.4">1.4</option>
                      <option value= "1.5">1.5</option>
                      <option value= "1.6">1.6</option>
                      <option value= "1.7">1.7</option>
                      <option value= "1.8">1.8</option>
                      <option value= "1.9">1.9</option>
                      <option value= "2.0">2.0</option>                    
                    </PUSelect>
                  </pre>
                  <pre> 
                    起迄日期時間
                  </pre>
                  <pre>
                    <DateTimeWrapper>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label= "日期"
                          value={bonusDatGroup.bonusStartDate11}
                          onChange={(newValue) => {
                            setBonusDatGroup(prev => ({
                              ...prev,
                              bonusStartDate11: newValue
                            }))
                          }}
                          renderInput={({ inputRef, inputProps, InputProps }) => {
                            inputProps.placeholder = '請選擇日期'
                            return (
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <PUInput id= "datePicker1" ref={inputRef} {...inputProps} />
                                <div id="dateBtn1">{InputProps?.endAdornment}</div>
                              </Box>
                            )
                          }}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label= "日期"
                          value={bonusDatGroup.bonusEndDate11}
                          onChange={(newValue) => {
                            setBonusDatGroup(prev => ({
                              ...prev,
                              bonusEndDate11: newValue
                            }))
                          }}
                          renderInput={({ inputRef, inputProps, InputProps }) => {
                            inputProps.placeholder = '請選擇日期'
                            return (
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <PUInput id= "datePicker1" ref={inputRef} {...inputProps} />
                                <div id="dateBtn1">{InputProps?.endAdornment}</div>
                              </Box>
                            )
                          }}
                        />
                      </LocalizationProvider>
                    </DateTimeWrapper>
                  </pre>
                  <pre>
                    <DateTimeWrapper>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          value={dayjs.utc(intervalTimeGroup.startTime11)}
                          timezone="Asia/Taipei"
                          onChange={(newValue) => {
                            setIntervalTimeGroup(prev => ({
                              ...prev,
                              startTime11: newValue
                            }))
                            handleTime()
                          }}
                          renderInput={({ inputRef, inputProps, InputProps }) => {
                            inputProps.placeholder = '請選擇時間'
                            return (
                              <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                                <TimeInput id= "timePicker5" ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </TimeFormat>
                            )
                          }}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          value={dayjs.utc(intervalTimeGroup.endTime11)}
                          timezone="Asia/Taipei"
                          onChange={(newValue) => {
                            setIntervalTimeGroup(prev => ({
                              ...prev,
                              endTime11: newValue
                            }))
                            handleTime()
                          }}
                          renderInput={({ inputRef, inputProps, InputProps }) => {
                            inputProps.placeholder = '請選擇時間'
                            return (
                              <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                                <TimeInput id= "timePicker6" ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </TimeFormat>
                            )
                          }}
                        />
                      </LocalizationProvider>
                    </DateTimeWrapper>
                  </pre>
                </td>
              </tr>

              <tr>
                <PULeftTitle>活動加成2</PULeftTitle>
                <td>      
                  <pre>                  
                    乘倍值&nbsp;
                    <PUSelect value= {form.BOUNS_RATIO1_2} onChange= {e => {handleForm(e, 'BOUNS_RATIO1_2')}}>
                      <option value= "1.0">1.0</option>
                      <option value= "1.1">1.1</option>
                      <option value= "1.2">1.2</option>
                      <option value= "1.3">1.3</option>
                      <option value= "1.4">1.4</option>
                      <option value= "1.5">1.5</option>
                      <option value= "1.6">1.6</option>
                      <option value= "1.7">1.7</option>
                      <option value= "1.8">1.8</option>
                      <option value= "1.9">1.9</option>
                      <option value= "2.0">2.0</option>                    
                    </PUSelect>
                  </pre>
                  <pre> 
                    起迄日期時間
                  </pre>
                  <pre>
                    <DateTimeWrapper>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label= "日期"
                          value={bonusDatGroup.bonusStartDate12}
                          onChange={(newValue) => {
                            setBonusDatGroup(prev => ({
                              ...prev,
                              bonusStartDate12: newValue
                            }))
                          }}
                          renderInput={({ inputRef, inputProps, InputProps }) => {
                            inputProps.placeholder = '請選擇日期'
                            return (
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <PUInput id= "datePicker1" ref={inputRef} {...inputProps} />
                                <div id="dateBtn1">{InputProps?.endAdornment}</div>
                              </Box>
                            )
                          }}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label= "日期"
                          value={bonusDatGroup.bonusEndDate12}
                          onChange={(newValue) => {
                            setBonusDatGroup(prev => ({
                              ...prev,
                              bonusEndDate12: newValue
                            }))
                          }}
                          renderInput={({ inputRef, inputProps, InputProps }) => {
                            inputProps.placeholder = '請選擇日期'
                            return (
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <PUInput id= "datePicker1" ref={inputRef} {...inputProps} />
                                <div id="dateBtn1">{InputProps?.endAdornment}</div>
                              </Box>
                            )
                          }}
                        />
                      </LocalizationProvider>
                    </DateTimeWrapper>
                  </pre>
                  <pre>
                    <DateTimeWrapper>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          value={dayjs.utc(intervalTimeGroup.startTime12)}
                          timezone="Asia/Taipei"
                          onChange={(newValue) => {
                            setIntervalTimeGroup(prev => ({
                              ...prev,
                              startTime12: newValue
                            }))
                            handleTime()
                          }}
                          renderInput={({ inputRef, inputProps, InputProps }) => {
                            inputProps.placeholder = '請選擇時間'
                            return (
                              <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                                <TimeInput id= "timePicker7" ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </TimeFormat>
                            )
                          }}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          value={dayjs.utc(intervalTimeGroup.endTime12)}
                          timezone="Asia/Taipei"
                          onChange={(newValue) => {
                            setIntervalTimeGroup(prev => ({
                              ...prev,
                              endTime12: newValue
                            }))
                            handleTime()
                          }}
                          renderInput={({ inputRef, inputProps, InputProps }) => {
                            inputProps.placeholder = '請選擇時間'
                            return (
                              <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                                <TimeInput id= "timePicker8" ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </TimeFormat>
                            )
                          }}
                        />
                      </LocalizationProvider>
                    </DateTimeWrapper>
                  </pre>
                </td>
              </tr>

              <tr>
                <PULeftTitle>活動加成3</PULeftTitle>
                <td>      
                  <pre>                  
                    乘倍值&nbsp;
                    <PUSelect value= {form.BOUNS_RATIO1_3} onChange= {e => {handleForm(e, 'BOUNS_RATIO1_3')}}>
                      <option value= "1.0">1.0</option>
                      <option value= "1.1">1.1</option>
                      <option value= "1.2">1.2</option>
                      <option value= "1.3">1.3</option>
                      <option value= "1.4">1.4</option>
                      <option value= "1.5">1.5</option>
                      <option value= "1.6">1.6</option>
                      <option value= "1.7">1.7</option>
                      <option value= "1.8">1.8</option>
                      <option value= "1.9">1.9</option>
                      <option value= "2.0">2.0</option>                    
                    </PUSelect>
                  </pre>
                  <pre> 
                    起迄日期時間
                  </pre>
                  <pre>
                    <DateTimeWrapper>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label= "日期"
                          value={bonusDatGroup.bonusStartDate13}
                          onChange={(newValue) => {
                            setBonusDatGroup(prev => ({
                              ...prev,
                              bonusStartDate13: newValue
                            }))
                          }}
                          renderInput={({ inputRef, inputProps, InputProps }) => {
                            inputProps.placeholder = '請選擇日期'
                            return (
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <PUInput id= "datePicker1" ref={inputRef} {...inputProps} />
                                <div id="dateBtn1">{InputProps?.endAdornment}</div>
                              </Box>
                            )
                          }}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label= "日期"
                          value={bonusDatGroup.bonusEndDate13}
                          onChange={(newValue) => {
                            setBonusDatGroup(prev => ({
                              ...prev,
                              bonusEndDate13: newValue
                            }))
                          }}
                          renderInput={({ inputRef, inputProps, InputProps }) => {
                            inputProps.placeholder = '請選擇日期'
                            return (
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <PUInput id= "datePicker1" ref={inputRef} {...inputProps} />
                                <div id="dateBtn1">{InputProps?.endAdornment}</div>
                              </Box>
                            )
                          }}
                        />
                      </LocalizationProvider>
                    </DateTimeWrapper>
                  </pre>
                  <pre>
                    <DateTimeWrapper>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          value={dayjs.utc(intervalTimeGroup.startTime13)}
                          timezone="Asia/Taipei"
                          onChange={(newValue) => {
                            setIntervalTimeGroup(prev => ({
                              ...prev,
                              startTime13: newValue
                            }))
                            handleTime()
                          }}
                          renderInput={({ inputRef, inputProps, InputProps }) => {
                            inputProps.placeholder = '請選擇時間'
                            return (
                              <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                                <TimeInput id= "timePicker9" ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </TimeFormat>
                            )
                          }}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          value={dayjs.utc(intervalTimeGroup.endTime13)}
                          timezone="Asia/Taipei"
                          onChange={(newValue) => {
                            setIntervalTimeGroup(prev => ({
                              ...prev,
                              endTime13: newValue
                            }))
                            handleTime()
                          }}
                          renderInput={({ inputRef, inputProps, InputProps }) => {
                            inputProps.placeholder = '請選擇時間'
                            return (
                              <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                                <TimeInput id= "timePicker10" ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </TimeFormat>
                            )
                          }}
                        />
                      </LocalizationProvider>
                    </DateTimeWrapper>
                  </pre>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <ModalBtn>
          <Button variant="secondary" onClick= {handleBack}>返回</Button>
          <Button variant="info" onClick= {handleSubmit}>確定</Button>          
        </ModalBtn>
      </ModalFormat>
    </>
  )
}