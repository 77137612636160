import React from "react"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { deleteNewsTicker, newsTickerList } from "../../../middleware/Api/backstageManagement/newsTickerApi"
import { ModalBtn, ModalText } from "../../../middleware/utilityStyle"

export default function DeletePU(props) {
  const {
    history,
    showDeletePU,
    handleDeletePUClose,
    handleInfoPUShow,
    handleCleanData,
    chosenTarget,
    setPanelInfo,
    setDataCopy,
    setChosenTarget,
    timeGroup,
    specificTime,
    setChange,
    setCheckPeriod,
    DBNewsTickerList
  } = props

  const handleOnHide = () => {
    handleDeletePUClose()
    setTimeout(() => {
      handleCleanData()
      setChosenTarget({
        ORDER_ID: '',
        STATE: '',
        TYPE: '',
        STATE: 1,
        BEGIN_DATE: '',
        EXPIRE_DATE: '',
        WEEK: '1,1,1,1,1,1,1',
        INTERVAL_START1: '',
        INTERVAL_END1: '',
        INTERVAL_START2: '',
        INTERVAL_END2: '',
        INTERVAL_START3: '',
        INTERVAL_END3: ''
      })
      for (let key in timeGroup) {
        timeGroup[key] = null
      }
      for (let key in specificTime) {
        specificTime[key] = null
      }
      setChange({specificDay: '', periodStatus: ''})
      setCheckPeriod({period1: false, period2: false, period3: false})
    }, 500)
  }

  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      deleteNewsTicker(chosenTarget.ORDER_ID).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        DBNewsTickerList()
        handleOnHide()
      })
    })
  }

  const handleRefuseDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleDeletePUClose()
      handleInfoPUShow()
    })
  }
  return (
    <Modal
      show={showDeletePU}
      onHide={handleOnHide}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>刪除確認</Modal.Title>
      </Modal.Header>
      <ModalText>
        確定要刪除資料嗎?
      </ModalText>
      <ModalBtn>
        <Button variant="danger" onClick={handleDelete}>刪除</Button>
        <Button variant="secondary" onClick={handleRefuseDelete}>取消</Button>
      </ModalBtn>
    </Modal>
  )
}