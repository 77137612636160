import React, { useState, useEffect }from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { PULeftTitle } from "../../../views/operation/userItem/styledComponent"
import { ModalFormat, PUInput, ModalBtn } from "../../../middleware/utilityStyle"
import { addUserItem, reviseUserItem } from "../../../middleware/Api/operation/userItemApi.js"
import { getItemConfigByItemId } from "../../../middleware/Api/operation/shopConfigApi.js"
import DeletePU from "./DeletePU"

export default function AddPU(props) {
  const {
    showAddRevise,
    handleAddReviseShow,
    handleAddReviseClose,
    addStatus,
    popData,
    setPanelInfo,
    setPopData
  } = props
  const history = useHistory()
  const [form, setForm] = useState({
    UID: '',
    ITEM_ID: '',
    ITEM_NAME: '',
    ITEM_NUM: 1,
    MAIN_TYPE: 1,
    SUB_TYPE: 1001,
    DETAIL_TYPE: 1001001,
    EXPIRE_DATE: null,
    STATUS: 0, 
    START_DATE: null, 
    END_DATE: null
  })

  const handleForm = (e, key) => {
    setForm(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const cleanData = () => {
    setForm({
      UID: '',
      ITEM_ID: '',
      ITEM_NAME: '',
      ITEM_NUM: 1,
      MAIN_TYPE: 1,
      SUB_TYPE: 1001,
      DETAIL_TYPE: 1001001,
      EXPIRE_DATE: null,
      STATUS: 0, 
      START_DATE: null, 
      END_DATE: null
    })
  }
  
  useEffect(() => {    
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if(!addStatus) {
        setForm(JSON.parse(JSON.stringify(popData)))
      }
      if(addStatus) {
        cleanData()
      }
    })
  }, [popData, addStatus])

  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }

      handleAddReviseClose()
      handleConfirmShow()
    })
  }

  async function handleSubmit () {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      if (isNaN(+form.ITEM_ID)) {
        alert('※  道具ID請輸入數字')
        setForm(prev => ({
          ...prev,
          ITEM_ID: ''
        }))
        return
      }
      if (isNaN(+form.UID)) {
        alert('※  用戶ID請輸入數字')
        setForm(prev => ({
          ...prev,
          UID: ''
        }))
        return
      }
      if (isNaN(+form.ITEM_NUM)) {
        alert('※  數量請輸入數字')
        setForm(prev => ({
          ...prev,
          ITEM_NUM: ''
        }))
        return
      }

      getItemConfigByItemId(+form.ITEM_ID).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if (db.data.length <= 0) {
          alert(`※  無此道具ID！`)
          return
        }

        const ammountLimit = JSON.parse(db.data[0].DETAIL_CONFIG).ammountLimit

        sessionVerified().then(async db => {
          if (db.error === errorCode.CODE_NOT_MATCH.code) {
            alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
            history.push("/login")
            return
          }
          if (db.error === errorCode.SESSION_EXPIRED.code) {
            alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
            history.push("/login")
            return
          }
          
          if (+form.ITEM_NUM > +ammountLimit) {
            alert('※  超過持有上限，請重新確認數量')
            return
          }
          
          if (!addStatus) {
            reviseUserItem(+form.UID, +form.ITEM_ID, +form.ITEM_NUM, form.EXPIRE_DATE, +form.STATUS).then(db => {
            if (db.error === errorCode.INCORRECT_DATA.code) {
                alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                return
              }
              if (db.error === errorCode.INCORRECT_FORMAT.code) {
                alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                return
              }
              if (db.error === errorCode.EXCEPTION.code) {
                alert(`※  ${errorCode.EXCEPTION.memo}`)
                return
              }
              if (db.error === errorCode.SERVER_ERROR.code) {
                alert(`※  ${errorCode.SERVER_ERROR.memo}`)
                return
              }
              setPanelInfo([])
              setPopData({})
            })
          }
          if (addStatus) {
            addUserItem(+form.UID, +form.ITEM_ID, +form.ITEM_NUM, '2100-01-01 00:00:00 +08:00', 0).then(db => {
            if (db.error === errorCode.INCORRECT_DATA.code) {
                alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
                return
              }
              if (db.error === errorCode.INCORRECT_FORMAT.code) {
                alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
                return
              }
              if (db.error === errorCode.EXCEPTION.code) {
                alert(`※  ${errorCode.EXCEPTION.memo}`)
                return
              }
              if (db.error === errorCode.SERVER_ERROR.code) {
                alert(`※  ${errorCode.SERVER_ERROR.memo}`)
                return
              }
              setPanelInfo([])
              setPopData({})
            })
          }
    
          cleanData()
          handleConfirmClose()
          handleAddReviseClose()
        }) 
      })
    })

  }

  const handleBack = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleAddReviseClose()
      setTimeout(() => {
        if (addStatus) {
          cleanData()
        }
        if (!addStatus) {
          setForm(JSON.parse(JSON.stringify(popData)))
        }
      }, 500)
    })
  }

  const handleOnHide = () => {
    handleAddReviseClose()
    if (addStatus) {
      cleanData()
    }
  }

  const [showConfirm, setConfirmShow] = useState(false)
  const handleConfirmClose = () => setConfirmShow(false)
  const handleConfirmShow = () => setConfirmShow(true)

  return (
    <>
      <ModalFormat
        show={showAddRevise}
        onHide={handleOnHide}
        backdrop="static"
        keyboard={false}
        size= "lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{addStatus ? '新增道具' : '道具修改'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <PULeftTitle>用戶ID</PULeftTitle>
                <td>
                  <PUInput placeholder= {addStatus ? '請輸入用戶ID' : form.UID} value= {form.UID}
                    disabled={addStatus ? false : true} onChange={e => {handleForm(e, 'UID')}}
                  />
                </td>
              </tr>
              <tr>
                <PULeftTitle>道具ID</PULeftTitle>
                <td>
                  <PUInput placeholder= {addStatus ? '請輸入道具ID' : form.ITEM_ID} value= {form.ITEM_ID}
                    disabled={addStatus ? false : true} onChange={e => {handleForm(e, 'ITEM_ID')}}
                  />
                </td>
              </tr>
              <tr style={addStatus ? {display: 'none'} : {}}>
                <PULeftTitle>道具名稱</PULeftTitle>
                <td>
                  <PUInput placeholder= {form.ITEM_NAME} value= {form.ITEM_NAME}
                    disabled={true} style= {{cursor: 'not-allowed'}}
                  />
                </td>
              </tr>
              <tr>
                <PULeftTitle>{addStatus ? '' : '持有'}數量</PULeftTitle>
                <td>
                  <PUInput placeholder= {addStatus ? '請輸入數字' : form.ITEM_NUM} value= {form.ITEM_NUM}
                  onChange={e => {handleForm(e, 'ITEM_NUM')}}
                  />造型類數量固定為1
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <ModalBtn>
          <Button style={addStatus ? {display: 'none'} : {display: 'block'}} variant="danger" onClick= {handleDelete}>刪除</Button>
          <Button variant="secondary" onClick= {handleBack}>{addStatus ? '返回' : '取消'}</Button>
          <Button variant="info" onClick= {handleSubmit}>確認</Button>          
        </ModalBtn>
      </ModalFormat>
      
      <DeletePU
        showConfirm= {showConfirm}
        handleAddReviseShow= {handleAddReviseShow}
        handleConfirmClose= {handleConfirmClose}
        addStatus= {addStatus}
        form= {form}
        setPanelInfo= {setPanelInfo}
        setPopData= {setPopData}
        cleanData = {cleanData}
      />
    </>
  )
}