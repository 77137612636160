import Modal from "react-bootstrap/Modal"
import React from "react"
import { useHistory } from "react-router-dom"
import { Button } from "react-bootstrap"
import { firstLogin } from "../../../middleware/Api/publicApi"
import { setAuthToken, setAccessArr, setCharacterType } from "../../../middleware/utils.js"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { ModalBtn, ModalText } from "../../../middleware/utilityStyle"

export default function RevisePassword_2(props) {
  const history = useHistory()
  const {
    change,
    setChange,
    showDoubleCheck,
    handleDoubleCheckClose
  } = props
  
  const handleFirstTime = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      firstLogin(change.oldAccount, change.oldPassword, change.checkNewPassword)
      .then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.PERMISSION_DENY.code) {
          alert(`${errorCode.PERMISSION_DENY.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setChange(prev => ({
          ...prev,
          password: '',
          oldAccount: '',
          oldPassword: '',
          newPassword: '',
          checkNewPassword: ''
        }))
        handleDoubleCheckClose()
        setAuthToken('')
        setAccessArr('')
        setCharacterType('')
        setTimeout(() => {
          alert('成功修改密碼 ฅʕ ・ᴥ・ʔฅ')
        }, 300)
        history.push('/login')
      })
    })    
  }

  const handleCancel = () => {
    handleDoubleCheckClose()
    setChange(prev => ({
      ...prev,
      oldAccount: '',
      oldPassword: '',
      newPassword: '',
      checkNewPassword: ''
    }))
  }
  return (
    <>
      <Modal
        show={showDoubleCheck}
        onHide={handleCancel}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>二次確認</Modal.Title>
        </Modal.Header>
        <ModalText>
          以上資料將變動，確定要變更嗎？
        </ModalText>
        <ModalBtn>
          <Button variant="info" onClick={handleFirstTime}>確定</Button>
          <Button variant="light" onClick={handleCancel}>取消</Button>
        </ModalBtn>
      </Modal>
    </>
  )
}