import React, { useState } from "react"
import { useLocation, NavLink, useHistory } from "react-router-dom"
import { Nav, TabPane } from "react-bootstrap"
import styled from "styled-components"
import * as Icon from "react-bootstrap-icons"
import { setAuthToken, getNavAcc, getAccessArr, setAccessArr, setCharacterType } from "../../middleware/utils"
import RevisePassword from "../../components/popUpWindow/login/revisePassword.js"

const SmallTitle = styled.p`
  font-size: 12px !important;
`

const ButtonGroup = styled.div`
  margin-left: 25px;
`

const Button = styled.button`
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 25px;
  border: 1px solid orange;
  background: DarkGray;
  color: WhiteSmoke;
  font-size: 15px;
  transition: all 0.7s;
  width: 80px;
  height: 30px;

  : hover {
    font-weight: bold;
    color: WhiteSmoke;
    background: orange;
    transform: translate(3px, 3px);
  }
`

const Category = styled.div`
  font-size: 20px;
  margin-left: 10px;
  margin-bottom: 10px;
`

const IconStyle = styled(Icon.ArrowRightSquareFill)`
  color: #ff8c1a;
  font-size: 1rem;
  margin-left: 10px;
  margin-right: 10px;
`

const DownIcon = styled(Icon.ArrowDownSquareFill)`
  color: #ff8c1a;
  font-size: 1rem;
  margin-left: 10px;
  margin-right: 10px;
`

const MainTitle = styled.span`
  : hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export default function Sidebar({ color, image, routes }) {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const location = useLocation()
  const history = useHistory()
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : ""
  }

  const handleAccountBtnClick = () => {
    history.push("/admin/accountSetting")
  }

  const handleAuthSetting = () => {
    history.push("/admin/authoritySetting")
  }

  const [loginAccount, setLoginAccount] = useState('')
  const [loginPage, setLoginPage] = useState(false)
  const [reviseFirst, setReviseFirst] = useState(false)
  const [showRevise, setShowRevise] = useState(false)
  const handleReviseClose = () => {
    setShowRevise(false)
  }
  const handleReviseShow = () => {
    setShowRevise(true)
  }

  const [change, setChange] = useState({
    account: '',
    password: '',
    oldAccount: '',
    oldPassword: '',
    newPassword: '',
    checkNewPassword: ''
  })

  const handleChange = e => {
    let newObj = Object.assign({}, change)
    newObj[e.target.name] = e.target.value
    setChange(newObj)
  }

  const handleChangePassword = () => {
    if (!userPID.find(ele => +ele === 4)) {
      alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
      return
    }
    setLoginAccount(getNavAcc())
    setLoginPage(false)
    handleReviseShow()
    setReviseFirst(false)
  }

  const handleRoleSetting = () => {
    history.push("/admin/roleSetting")
  }

  const handleLogOut = () => {
    setAuthToken('')
    setAccessArr('')
    setCharacterType('')
    history.push("/login")
  }

  const handleManipulateRecord = () => {
    history.push("/admin/manipulateRecord")
  }

  const [TPlayerInfo, setTPlayerInfo] = useState(false)  //玩家資訊
  const [TGameReport, setTGameReport] = useState(false) //遊戲報表
  const [TOperation, setTOperation] = useState(false)  //營運管理
  const [TCampaign, setTCampaign] = useState(false)  //活動管理
  const [TBackstage, setTBackstage] = useState(false)  //後台營運管理

  const SidebarItem = content => {
    let res = content.map((sObj, key) => {
      return (
        <li
          className={activeRoute(sObj.layout + sObj.path)}
          key={key}
          style= {eval(sObj.state) ? {display: 'block'} : {display: 'none'}}
        >
          <NavLink
            to={sObj.layout + sObj.path}
            className="nav-link"
            activeClassName="active"
            style= {{textAlign: 'center', padding: '0px'}}
          >
            <SmallTitle>{sObj.name}</SmallTitle>
          </NavLink>
        </li>
      )
    })
    return res
  }
  return (
    <>
      <div className="sidebar" data-image={image} data-color={color}>
        <div className="sidebar-background" />
        <div className="sidebar-wrapper">
          <ButtonGroup>
            <div className="row_1">
              <Button className="mainPageBtn" onClick={handleAccountBtnClick}>帳號設定</Button>
              <Button className="mainPageBtn" onClick={handleAuthSetting}>權限設定</Button>
            </div>
            <div className="row_2">
              <Button className="mainPageBtn" onClick={handleChangePassword}>修改密碼</Button>
              <Button className="mainPageBtn" onClick={handleRoleSetting}>角色設定</Button>
            </div>
            <div className="row_3">
              <Button className="mainPageBtn" onClick={handleLogOut}>退出</Button>
              <Button className="mainPageBtn" onClick={handleManipulateRecord}>操作紀錄</Button>
            </div>
          </ButtonGroup>
          <div className="logo d-flex align-items-center justify-content-start"></div>
            <Nav>
              <Category>項目選單</Category>
              <div>
                <li onClick= {() => {
                  if (TPlayerInfo) {
                    setTPlayerInfo(false)
                  } else {
                    setTPlayerInfo(true)
                  }}}
                >{TPlayerInfo ? <DownIcon/> : <IconStyle/>}
                <MainTitle>{routes.find(ele => ele.title === '玩家資訊' && !ele.redirect).title}</MainTitle></li>
                {SidebarItem(routes.find(ele => ele.title === '玩家資訊' && !ele.redirect).content)}
              </div>
              <div>
                <li onClick= {() => {
                  if (TGameReport) {
                    setTGameReport(false)
                  } else {
                    setTGameReport(true)
                  }}}
                >{TGameReport ? <DownIcon/> : <IconStyle/>}
                <MainTitle>{routes.find(ele => ele.title === '遊戲報表' && !ele.redirect).title}</MainTitle></li>
                {SidebarItem(routes.find(ele => ele.title === '遊戲報表' && !ele.redirect).content)}
              </div>
              <div>
                <li onClick= {() => {
                  if (TOperation) {
                    setTOperation(false)
                  } else {
                    setTOperation(true)
                  }}}
                >{TOperation ? <DownIcon/> : <IconStyle/>}
                <MainTitle>{routes.find(ele => ele.title === '營運管理' && !ele.redirect).title}</MainTitle></li>
                {SidebarItem(routes.find(ele => ele.title === '營運管理' && !ele.redirect).content)}
              </div>
              <div>
                <li onClick= {() => {
                  if (TCampaign) {
                    setTCampaign(false)
                  } else {
                    setTCampaign(true)
                  }}}
                >{TCampaign ? <DownIcon/> : <IconStyle/>}
                <MainTitle>{routes.find(ele => ele.title === '活動管理' && !ele.redirect).title}</MainTitle></li>
                {SidebarItem(routes.find(ele => ele.title === '活動管理' && !ele.redirect).content)}
              </div>
              <div>
                <li onClick= {() => {
                  if (TBackstage) {
                    setTBackstage(false)
                  } else {
                    setTBackstage(true)
                  }}}
                >{TBackstage ? <DownIcon/> : <IconStyle/>}
                <MainTitle>{routes.find(ele => ele.title === '後台運營管理' && !ele.redirect).title}</MainTitle></li>
                {SidebarItem(routes.find(ele => ele.title === '後台運營管理' && !ele.redirect).content)}
              </div>
            </Nav>
          </div>
      </div>
      
      <RevisePassword
        show={showRevise}
        handleReviseClose= {handleReviseClose}
        setShow={setShowRevise}
        change={change}
        setChange={setChange}
        handleChange={handleChange}
        reviseFirst= {reviseFirst}
        loginAccount = {loginAccount}
        loginPage= {loginPage}
      />
    </>
  )
}