import React from "react"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { ModalBtn, ModalText } from "../../../middleware/utilityStyle"

export default function AddAccountSecondPU(props) {
  const {
    showPopUp,
    handleClose,
    defaultPassword,
    error
  } = props

  return (
    <Modal
      show={showPopUp}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>新密碼</Modal.Title>
      </Modal.Header>
      <ModalText>
        {defaultPassword ? `您的密碼為: ${defaultPassword}` : error}
      </ModalText>
      <ModalBtn>
        <Button variant="info" onClick={handleClose}>確定</Button>
      </ModalBtn>
    </Modal>
  )
}

