import React from "react"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { ModalText, ModalBtn } from "../../../middleware/utilityStyle"

export default function CancelPU(props) {
  const {
    history,
    showCancelPU,
    handleCancelPUClose,
    handleInfoPUShow,
    setTargetList,
    handleCleanData,
    dateGroup,
    detailCopy
  } = props

  const handleCancel = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleCancelPUClose()
      handleCleanData()
      setTargetList({
        ORDER_ID: '',
        STATE: 1,
        TYPE: '',
        BEGIN_DATE: '',
        EXPIRE_DATE: '',
        TITLE: '',
        TEXT: '',
        SUBTITLE: ''
      })
      for (let key in dateGroup) {
        dateGroup[key] = null
      }
    })
  }

  const handleRefuseCancel = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleCancelPUClose()
      handleInfoPUShow()
    })
  }

  const handleOnHide = () => {
    handleCancelPUClose()
    setTargetList(detailCopy)
    handleCleanData()
    for (let key in dateGroup) {
      dateGroup[key] = null
    }
  }
  return (
    <Modal
      show={showCancelPU}
      onHide={handleOnHide}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>取消確認</Modal.Title>
      </Modal.Header>
      <ModalText>
        確定要取消變更資料嗎?
      </ModalText>
      <ModalBtn>
        <Button variant="info" onClick={handleCancel}>確認</Button>
        <Button variant="secondary" onClick={handleRefuseCancel}>上一步</Button>
      </ModalBtn>
    </Modal>
  )
}