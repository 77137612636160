import { getAuthToken } from "../../utils.js"
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 取得歷史在線人數 PID46 ?//
export const getUsersHistory = (gameID, sDate) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/serverEnv/gameOnlineLog?gameID=${gameID}&sDate=${sDate}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}