import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import AddAndRevise from "../../../components/popUpWindow/versionList/addAndRevise.js"
import DeletePU from "../../../components/popUpWindow/versionList/deletePU.js"
import {
  InputBarBg,
  InputBarRWD,
  Input,
  GameWrapper,
  Dropdown,
  BtnGroup,
  BTN
} from "./styledComponent"
import {
  SearchButton,
  ResetButton,
  BasicTable,
  BasicRowTitle,
  TableContent,
  NewBtn,
  ReviseBtn,
  DeleteBtn,
  LaunchBtn,
  MaintainBtn,
  TableBtnGroup
} from "../../../middleware/utilityStyle.js"

export default function versionList() {
  let list = []
  const history = useHistory()
  const [dataCopy, setDataCopy] = useState({})
  const [panelInfo, setPanelInfo] = useState([])
  const [popData, setPopData] = useState({})
  const [change, setChange] = useState({
    version: '',
    platform: ''
  })
  const [addStatus, setAddStatus] = useState(false)

  const platformName = new Map([
    [1,  'Android'],
    [2, 'ios']
  ])
  const transPlatform = new Map(platformName)

  const iosVS = new Map([
    [8, 'ios 8.7'],
    [9, 'ios 9.5'],
    [10, 'ios 10.11']
  ])
  const transIos = new Map(iosVS)

  const androidVS = new Map([
    [8, 'android 8.7'],
    [9, 'android 9.5'],
    [10, 'android 10.11']
  ])
  const transAndroid = new Map(androidVS)

  useEffect(() => {
    setPanelInfo(list)
    setDataCopy(list)
  }, [])

  const handleChange = (e, key) => {
    setChange(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (isNaN(+change.version)) {
        alert('※  版本號請輸入數字')
        return
      }
      if (change.version && +change.platform !== 0) {
        setPanelInfo(dataCopy.filter(ele => +ele.version === +change.version && +ele.platform === +change.platform))
      }
      if (change.version && +change.platform === 0) {
        setPanelInfo(dataCopy.filter(ele => +ele.version === +change.version && +ele.platform !== 0))
      }
      if (!change.version && +change.platform === 0) {
        setPanelInfo(dataCopy)
      }
      if (!change.version && +change.platform !== 0) {
        setPanelInfo(dataCopy.filter(ele => +ele.platform === +change.platform))
      }
      setChange({
        version: '',
        platform: 0
      })
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({
        version: '',
        platform: 0
      })
    })
  }

  const handleRadio = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPopData(list.find(ele => +ele.version === +e.target.value))
    })
  }

  const [showAddAndRevise, setAddAndReviseShow] = useState(false)
  const handleAddAndReviseClose = () => setAddAndReviseShow(false)
  const handleAddAndReviseShow = () => setAddAndReviseShow(true)

  const handleAdd = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setAddStatus(true)
      handleAddAndReviseShow()
    })
  }

  const handleRevise = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      
      if (!popData.version) {
        alert('※  請選擇欲修改的項目')
        return
      }
      setAddStatus(false)
      handleAddAndReviseShow()
    })
  }

  const [showDelete, setDeleteShow] = useState(false)
  const handleDeleteClose = () => setDeleteShow(false)
  const handleDeleteShow = () => setDeleteShow(true)

  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!popData.version) {
        alert('※  請選取欲刪除的項目')
        return
      }
      handleDeleteShow()
    })
  }

  
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <InputBarRWD>
          <div>
            版本號<Input placeholder="請輸入版本號" value={change.version} onChange= {e => {handleChange(e, 'version')}} />
          </div>
          <GameWrapper>
            <span>平台</span>
            <Dropdown id= "item1" value={change.platform} onChange= {e => {handleChange(e, "platform")}}>
              <option value="0">全部</option>
              <option value="1">Android</option>
              <option value="2">ios</option>
            </Dropdown>
          </GameWrapper>
        </InputBarRWD>

        <BtnGroup>
          <SearchButton onClick={handleSearch}>搜索</SearchButton>
          <ResetButton onClick={handleReset}>重置</ResetButton>
        </BtnGroup>
      </InputBarBg>
      <TableBtnGroup>
        <BasicTable className="table table-hover table-bordered">
          <thead>
            <BasicRowTitle>
              <th>
                <input type= "radio" disabled/>
              </th>
              <th>版本號</th>
              <th>平台</th>
              <th>開放</th>
              <th>商店短網址</th>
              <th>更新內容</th>
              <th>更新等級</th>
              <th>最低平台版本</th>
              <th>遊戲每週維護時間</th>
            </BasicRowTitle>
          </thead>
          <tbody>
            {
              Array.isArray(panelInfo) && panelInfo.length > 0 ?
              panelInfo.map((ele, index) => {
                return (
                  <TableContent key= {index}>
                    <td>
                      <input type= "radio" name= "gameIdList" value= {ele.version} onChange= {handleRadio}/>
                    </td>
                    <td>{ele.version}</td>
                    <td>{transPlatform.get(+ele.platform)}</td>
                    <td>{+ele.switch === 1 ? '開啟' : '關閉'}</td>
                    <td>{ele.url}</td>
                    <td>{ele.uContent}</td>
                    <td>{+ele.uLevel === 1 ? '強制更新' : '提示更新'}</td>
                    <td>{+ele.platform === 1 ? transAndroid.get(+ele.lowestVS) : transIos.get(+ele.lowestVS)}</td>
                    <td>{ele.weeklyMT}</td>
                  </TableContent>
                )
              })
              : <tr key= "noInfo"><td colSpan= "9">尚無資料</td></tr>
            }
          </tbody>
        </BasicTable>
        <BTN>
          <LaunchBtn>開啟</LaunchBtn>
          <MaintainBtn>維護</MaintainBtn>
          <NewBtn onClick= {handleAdd}>新增</NewBtn>
          <ReviseBtn onClick= {handleRevise}>修改</ReviseBtn>
          <DeleteBtn onClick= {handleDelete}>刪除</DeleteBtn>
        </BTN>        
      </TableBtnGroup>
        

      <AddAndRevise
        showAddAndRevise= {showAddAndRevise}
        handleAddAndReviseClose= {handleAddAndReviseClose}
        handleAddAndReviseShow= {handleAddAndReviseShow}
        addStatus= {addStatus}
        popData= {popData}
      />

      <DeletePU
        showDelete= {showDelete}
        handleDeleteClose= {handleDeleteClose}
      />
    </>
  )
}