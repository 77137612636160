import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import Stack from '@mui/material/Stack'
import { TimezoneTranslator } from "../../../middleware/dateUtils"
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker'
import errorCode from "../../../assets/config/errorCode.json"
import AddRevisePU from "../../../components/popUpWindow/arenaConfig/AddRevisePU"
import DeletePU from "../../../components/popUpWindow/arenaConfig/DeletePU"
import { getAccessArr } from "../../../middleware/utils"
import arenaConfigTitle from "../../../assets/config/arenaConfig.json"
import { getArenaConfig } from "../../../middleware/Api/eventManagement/arenaConfigApi.js"
import Box from '@mui/material/Box'
import { today, yesterday, thisWeek, lastWeek, thisMonth, defaultDate, defaultDateE } from "../../../middleware/dateUtils"

import {
  BasicTable,
  BasicRowTitle,
  TableContent,
  AddBtn,
  PopDetail,
  PageStyle, 
  BasicHeadTitle,
  DateWrapper, 
  DateTitle, 
  ClickRange,
  SearchButton,
  ResetButton,
  TableBtnGroup,
  NewBtn,
  ReviseBtn,
  DeleteBtn
} from "../../../middleware/utilityStyle.js"
import {
  InputBarBg,
  TableForm,
  STitle,
  NoData, 
  NavLeft,
  RangeWrapper,
  DatePickerInput,
  RangeGroup,
  Group,
  Dropdown, 
  NoInfo, 
  BTN, 
  IdInputBar, 
  IdInput
} from "./styledComponent"

export default function arenaConfig() {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const history = useHistory()
  const [panelInfo, setPanelInfo] = useState([])
  const [change, setChange] = useState({sDate: '', eDate: '', gameType: 0, roomType: 0})
  const handleChange = (e, key) => {
    setChange(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }
  const [dateValue, setDateValue] = useState([null, null])
  const [popData, setPopData] = useState({
    LADDER_ID: 0,
    LADDER_NAME: '',
    LADDER_GAME_TYPE: 0,
    GAME_ID: 7002,
    START_DATE: null,
    END_DATE: null,
    GAME_PRIZE_POOL_STATE: 0,
    GAME_PRIZE_POOL: 0,
    GAME_PRIZE_POOL_WEIGHT: 0,
    GAME_PRIZE_POOL_PAY: 5000,
    ONLINE_ACT_CODE_STATE: 0, 
    ONLINE_ACT_CODE: '', 
    ONLINE_NUM_STATE: 0, 
    ACT_START_TIME: null,
    ACT_END_TIME: null,
    LADDER_WEEK1: '1,1,1,1,1,1,1',
    LADDER_START_TIME1: null,
    LADDER_END_TIME1: null,
    LADDER_WEEK2: '0,0,0,0,0,0,0',
    LADDER_START_TIME2: null,
    LADDER_END_TIME2: null,
    SIGN_UP_START_TIME1: null,
    SIGN_UP_END_TIME1: null,
    SIGN_UP_START_TIME2: null,
    SIGN_UP_END_TIME2: null,
    BONUS_START_TIME1_1: null,
    BONUS_END_TIME1_1: null,
    BOUNS_RATIO1_1: 1,
    BONUS_START_TIME1_2: null,
    BONUS_END_TIME1_2: null,
    BOUNS_RATIO1_2: 1,
    BONUS_START_TIME1_3: null,
    BONUS_END_TIME1_3: null,
    BOUNS_RATIO1_3: 1,
    BONUS_START_TIME2_1: null,
    BONUS_END_TIME2_1: null,
    BOUNS_RATIO2_1: 1,
    BONUS_START_TIME2_2: null,
    BONUS_END_TIME2_2: null,
    BOUNS_RATIO2_2: 1,
    BONUS_START_TIME2_3: null,
    BONUS_END_TIME2_3: null,
    BOUNS_RATIO2_3: 1,
    BONUS_START_WEEK1_1: '0,0,0,0,0,0,0',
    BONUS_START_WEEK1_2: '0,0,0,0,0,0,0',
    BONUS_START_WEEK1_3: '0,0,0,0,0,0,0',
    BONUS_START_WEEK2_1: '0,0,0,0,0,0,0',
    BONUS_START_WEEK2_2: '0,0,0,0,0,0,0',
    BONUS_START_WEEK2_3: '0,0,0,0,0,0,0',
    BONUS_START_DATE1_1: null,
    BONUS_START_DATE1_2: null,
    BONUS_START_DATE1_3: null,
    BONUS_START_DATE2_1: null,
    BONUS_START_DATE2_2: null,
    BONUS_START_DATE2_3: null,
    BONUS_END_DATE1_1: null,
    BONUS_END_DATE1_2: null,
    BONUS_END_DATE1_3: null,
    BONUS_END_DATE2_1: null,
    BONUS_END_DATE2_2: null,
    BONUS_END_DATE2_3: null,
    SCORE_RULE: '0,0,0,0,0,0,5,300,100,1,0,0,0,0,0',
    LADDER_TYPE: 1
  })

  useEffect(() => {
  }, [panelInfo])

  const handleRadio = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPopData(panelInfo.find(ele => +ele.LADDER_ID === +e.target.value))
    })
  }

  const [changePagePara, setChangePagePara] = useState({
    sDate: new Date(defaultDate),
    eDate: new Date(defaultDateE), 
    ladderId: 0
  })

  const [pageCounter, setPageCounter] = useState(0)
  const [page, setPage] = useState(1)
  const handleChangePage = (event, newPage) => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPage(newPage)
      const {sDate, eDate, ladderId} = changePagePara
      handleGetArenaConfig(sDate, eDate, ladderId, 1+(newPage-1)*10, newPage*10)
    })
  }

  function handleGetArenaConfig(sD, eD, ladderId, sR, eR) {
    getArenaConfig(new Date(sD).toISOString(), new Date(eD).toISOString(), +ladderId, sR, eR).then(db => {
      if (db.error === errorCode.INCORRECT_DATA.code) {
        alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.INCORRECT_FORMAT.code) {
        alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.EXCEPTION.code) {
        alert(`※  ${errorCode.EXCEPTION.memo}`)
        return
      }
      if (db.error === errorCode.SERVER_ERROR.code) {
        alert(`※  ${errorCode.SERVER_ERROR.memo}`)
        return
      }
      if (db.error === errorCode.NULL_DATA.code) {
        alert(`※  ${errorCode.NULL_DATA.memo}`)
        setPanelInfo([])
        return
      }
      setChangePagePara({
        sDate: new Date(sD),
        eDate: new Date(eD), 
        ladderId: ladderId
      })
      if(db.data) {
        if (db.data.length > 0) {
          setPageCounter(
            db.data[0].TOTAL_ROW_NUM%10 === 0
            ? Math.floor(db.data[0].TOTAL_ROW_NUM/10)
            : (Math.floor(db.data[0].TOTAL_ROW_NUM/10)) + 1
          )
        }
      }
      setPanelInfo(db.data)
      setPage(1)
    })
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      let ladderId = change.ladderId ? change.ladderId : 0;

      let sDate = ''
      let eDate = ''
      if  (dateValue[0] !== null || dateValue[1] !== null) {        
        sDate = new Date (dateValue[0]).toISOString()
        let endRDateStr = new Date (dateValue[1]).toString()
        eDate = new Date(endRDateStr.replace('00:00:00', '23:59:59')).toISOString()
      }
      if  (dateValue[0] === null && dateValue[1] === null) {
        sDate = defaultDate.toISOString()
        eDate= defaultDateE.toISOString()
      }
      handleGetArenaConfig(sDate, eDate, ladderId, 1, 10)
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({sDate: '', eDate: '', ladderId: 0})
      setDateValue([null, null])
    })
  }

  const [addStatus, setAddStatus] = useState(true)
  const[showAddRevise, setAddReviseShow] = useState(false)
  const [showDeletePU, setDeletePUShow] = useState(false)
  const handleAddReviseClose = () => setAddReviseShow(false)
  const handleAddReviseShow = () => setAddReviseShow(true)
  const handleDeletePUClose = () => setDeletePUShow(false)
  const handleDeletePUShow = () => setDeletePUShow(true)

  const handleAdd = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 100)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      setAddStatus(true)
      handleAddReviseShow()
    })
  }

  const handleRevise = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 101)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (!+popData.LADDER_ID) {
        alert('※  請選擇欲修改的項目')
        return
      }
      //setPopData(panelInfo.find(ele => +ele.LADDER_ID === +e.target.dataset.id))
      setAddStatus(false)
      handleAddReviseShow()
    })
  }

  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 102)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (!+popData.LADDER_ID) {
        alert('※  請選擇欲刪除的項目')
        return
      }
      handleDeletePUShow()
    })
  }

  function dateFormat(target) {
    let date = new Date(target)
    let result = date.getFullYear().toString() + "-" + (date.getMonth()+1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0") + " " + date.getHours().toString().padStart(2, "0") + ":" + date.getMinutes().toString().padStart(2, "0") + ":" + date.getSeconds().toString().padStart(2, "0")
    return result
  }

  function arenaList() {
    let arenaList = []
    for (let i = 0; i < panelInfo.length; i++) {
      arenaList.push(<BasicHeadTitle ><input type= "radio" name= "arenaList" value= {panelInfo[i].LADDER_ID} onChange= {handleRadio}/></BasicHeadTitle>)
    }
    return arenaList;
  }

  function toCurrency(num){
    var parts = num.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}

  function DataGroup(varName, index) {
    
    let varList = []
    for (let i = 0; i < panelInfo.length; i++) {
      if (varName == "SCORE_RULE") {
        if (index == 5) {
          varList.push(<td>{+panelInfo[i][varName].split(',')[index] ? "有" : "無"}</td>)
        } else
        {
          varList.push(<td>{panelInfo[i][varName].split(',')[index]}</td>)
        }        
      } else if (varName == "LADDER_TYPE") {
        varList.push(<td>{"積分賽"}</td>)
      } else if (varName == "LADDER_GAME_TYPE") {
        varList.push(<td>{"---"}</td>)
      } else if (varName == "LADDER_STATE") {
        varList.push(<td>{"開啟"}</td>)
      } else if (varName == "SIGN_UP_START_TIME1" || varName == "SIGN_UP_END_TIME1") {
        varList.push(<td>{new Date(panelInfo[i][varName]).toLocaleTimeString("zh-TW", { hour12: false, timeZone: "GMT"})}</td>)
      } else if (varName == "ACT_START_TIME" || varName == "ACT_END_TIME") {
        varList.push(<td>{new Date(panelInfo[i][varName]).toLocaleString("zh-TW", { hour12: false, timeZone: "Asia/Taipei"})}</td>)
      } else if (varName == "BONUS_START_TIME1_1" 
      || varName == "BONUS_END_TIME1_1" 
      || varName == "BONUS_START_TIME1_2" 
      || varName == "BONUS_END_TIME1_2" 
      || varName == "BONUS_START_TIME1_3" 
      || varName == "BONUS_END_TIME1_3") {
        varList.push(<td>{new Date(panelInfo[i][varName]).toLocaleTimeString("zh-TW", { hour12: false, timeZone: "GMT"})}</td>)
      } else if (varName == "GAME_PRIZE_POOL_WEIGHT") {
        varList.push(<td>{panelInfo[i][varName] + "%"}</td>)
      } else if (varName == "GAME_PRIZE_POOL_PAY") {
        varList.push(<td>{toCurrency(+panelInfo[i][varName])}</td>)
      } else if (varName == "GAME_PRIZE_POOL_STATE") {
        varList.push(<td>{panelInfo[i][varName] ? "開啟" : "關閉"}</td>)
      } else {
        varList.push(<td>{panelInfo[i][varName]}</td>)
      }
    }
    
    return varList
  }

  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <NavLeft>
          <IdInputBar>
            競技場ID<IdInput placeholder= "請輸入競技場ID" value= {change.ladderId} onChange= {e=> {handleChange(e, 'ladderId')}}/>
          </IdInputBar>
          <RangeWrapper>
            <DateWrapper>
              <DateTitle>查詢時間</DateTitle>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                  <DesktopDateRangePicker
                    startText="創建時間"
                    endText="結束時間"
                    value={dateValue}
                    onChange={(newValue) => {
                      setDateValue(newValue)
                    }}
                    renderInput={(startProps, endProps) => {
                      startProps.inputProps.placeholder = '開始日期'
                      endProps.inputProps.placeholder= '結束日期'
                      return (
                        <React.Fragment>
                          <DatePickerInput ref={startProps.inputRef} {...startProps.inputProps} />
                          <Box sx={{ mx: 1 }}>至</Box>
                          <DatePickerInput ref={endProps.inputRef} {...endProps.inputProps} />
                        </React.Fragment>
                      )
                    }}
                  />
                </Stack>
              </LocalizationProvider>
            </DateWrapper>
            <RangeGroup>
              <ClickRange onClick={() => { setDateValue(today) }}>今天</ClickRange>
              <ClickRange onClick={() => { setDateValue(yesterday) }}>昨天</ClickRange>
              <ClickRange onClick={() => { setDateValue(thisWeek) }}>本周</ClickRange>
              <ClickRange onClick={() => { setDateValue(lastWeek) }}>上周</ClickRange>
              <ClickRange onClick={() => { setDateValue(thisMonth) }}>本月</ClickRange>
            </RangeGroup>
          </RangeWrapper>
        </NavLeft>      
        <Group>
          <SearchButton onClick={handleSearch}>搜索</SearchButton>
          <ResetButton onClick={handleReset}>重置</ResetButton>
        </Group>
      </InputBarBg>

      <TableForm  className="table table-hover table-bordered">
        <thead>
          <tr key= "BasicHeadTitle">
            <BasicHeadTitle></BasicHeadTitle>
            {arenaList()}
           </tr>
         </thead>
        <tbody>
          {
            arenaConfigTitle.map((ele, index) => {
              return (
                <>
                  <TableContent key= {`list${index}`}>
                    <STitle style={{display: 'table-cell'}}>{ele.chName}</STitle>
                    {DataGroup(ele.varName, ele.index)}
                  </TableContent>
                </>
              )
            })
          }
        </tbody>
        <tfoot></tfoot>
      </TableForm>

      <BTN>
        <NewBtn onClick= {handleAdd}>新增</NewBtn>
        <Dropdown disabled = {true} value= {0} onChange= {e => {}}>
          <option value="0">積分賽</option>
        </Dropdown>
        <ReviseBtn onClick= {handleRevise}>修改</ReviseBtn>
        <DeleteBtn onClick= {handleDelete}>刪除</DeleteBtn>
      </BTN>     

      <AddRevisePU
        showAddRevise= {showAddRevise}
        handleAddReviseShow= {handleAddReviseShow}
        handleAddReviseClose= {handleAddReviseClose}
        addStatus= {addStatus}
        popData= {addStatus ? {
          LADDER_ID: 0,
          LADDER_NAME: '',
          LADDER_GAME_TYPE: 31,
          GAME_ID: 7002,
          START_DATE: null,
          END_DATE: null,
          GAME_PRIZE_POOL_STATE: 0,
          GAME_PRIZE_POOL: 0,
          GAME_PRIZE_POOL_WEIGHT: 0,
          GAME_PRIZE_POOL_PAY: 5000,
          ONLINE_ACT_CODE_STATE: 0, 
          ONLINE_ACT_CODE: '', 
          ONLINE_NUM_STATE: 0, 
          ACT_START_TIME: null,
          ACT_END_TIME: null,
          LADDER_WEEK1: '1,1,1,1,1,1,1',
          LADDER_START_TIME1: null,
          LADDER_END_TIME1: null,
          LADDER_WEEK2: '0,0,0,0,0,0,0',
          LADDER_START_TIME2: null,
          LADDER_END_TIME2: null,
          SIGN_UP_START_TIME1: null,
          SIGN_UP_END_TIME1: null,
          SIGN_UP_START_TIME2: null,
          SIGN_UP_END_TIME2: null,
          BONUS_START_TIME1_1: null,
          BONUS_END_TIME1_1: null,
          BOUNS_RATIO1_1: 1,
          BONUS_START_TIME1_2: null,
          BONUS_END_TIME1_2: null,
          BOUNS_RATIO1_2: 1,
          BONUS_START_TIME1_3: null,
          BONUS_END_TIME1_3: null,
          BOUNS_RATIO1_3: 1,
          BONUS_START_TIME2_1: null,
          BONUS_END_TIME2_1: null,
          BOUNS_RATIO2_1: 1,
          BONUS_START_TIME2_2: null,
          BONUS_END_TIME2_2: null,
          BOUNS_RATIO2_2: 1,
          BONUS_START_TIME2_3: null,
          BONUS_END_TIME2_3: null,
          BOUNS_RATIO2_3: 1,
          BONUS_START_WEEK1_1: '0,0,0,0,0,0,0',
          BONUS_START_WEEK1_2: '0,0,0,0,0,0,0',
          BONUS_START_WEEK1_3: '0,0,0,0,0,0,0',
          BONUS_START_WEEK2_1: '0,0,0,0,0,0,0',
          BONUS_START_WEEK2_2: '0,0,0,0,0,0,0',
          BONUS_START_WEEK2_3: '0,0,0,0,0,0,0',
          BONUS_START_DATE1_1: null,
          BONUS_START_DATE1_2: null,
          BONUS_START_DATE1_3: null,
          BONUS_START_DATE2_1: null,
          BONUS_START_DATE2_2: null,
          BONUS_START_DATE2_3: null,
          BONUS_END_DATE1_1: null,
          BONUS_END_DATE1_2: null,
          BONUS_END_DATE1_3: null,
          BONUS_END_DATE2_1: null,
          BONUS_END_DATE2_2: null,
          BONUS_END_DATE2_3: null,
          SCORE_RULE: '0,0,0,0,0,0,5,300,100,1,0,0,0,0,0',
          LADDER_TYPE: 1
        } : popData}
        setPanelInfo= {setPanelInfo}
        setPopData= {setPopData}
      />

      <DeletePU
        popData= {popData}
        setPopData= {setPopData}
        showDeletePU= {showDeletePU}
        handleDeletePUClose= {handleDeletePUClose}
        setPanelInfo= {setPanelInfo}
      />

      {
        Array.isArray(panelInfo) && panelInfo.length ?
          <Stack spacing={2}>
            <PageStyle count={pageCounter}
              page= {page}
              color="warning"
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </Stack>
        : ''
      }

      
    </>
  )
}