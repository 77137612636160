import styled from 'styled-components'
import { BarBG, BasicInput, BtnPosition } from 'middleware/utilityStyle'

export const InputBarBg = styled(BarBG)`
  @media screen and (max-width: 700px){
    & {
      flex-direction: column;
    }
  }
`

export const InputBarRWD = styled.div`
  display: flex;
` 

export const Input = styled(BasicInput)`
  width: 150px;
  margin-left: 20px;
`

export const GameWrapper = styled.div`
  margin-left: 20px;
`

export const Dropdown = styled.select`
  margin-left: 20px;
  border: 1px solid black;
  border-radius: 5px;
  height: 28px;
  width: 150px;
`

export const BtnGroup = styled.div`
  @media screen and (max-width: 700px) {
    margin-top: 10px;
  }
`

export const RowUnoFormat = styled.div`
  display: flex;
  justify-content: space-between;
  width: 400px;
`

export const RowFormat = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 400px;
`

export const PUSelect = styled.select`
  border: 1px solid black;
  border-radius: 5px;
  height: 28px;
  width: 240px;
`

export const ContentBlock = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 400px;
`

export const Text = styled.textarea`
  width: 240px;
`

export const BTN = styled(BtnPosition)`
  width: 1000px;
`