import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { getAllAcc, getAllCharacter } from "../../middleware/Api/publicApi"
import { resetPSWD } from "../../middleware/Api/accountApi"

import AddAccountFirstPU from "../../components/popUpWindow/account/add1Acc"
import AddAccountSecondPU from "../../components/popUpWindow/account/add2Acc"
import ReviseAcc from "../../components/popUpWindow/account/reviseAcc"
import DeleteAcc from "../../components/popUpWindow/account/deleteAcc"
import BanAcc from "../../components/popUpWindow/account/banAcc"
import EnableAcc from "../../components/popUpWindow/account/enableAcc"
import ResetPasswordFirstPU from "../../components/popUpWindow/account/resetPswdAcc"
import ResetPasswordSecondPU from "../../components/popUpWindow/account/resetPswdAcc_2"
import { sessionVerified } from "../../middleware/Api/publicApi.js"
import { getAccessArr } from "../../middleware/utils"
import errorCode from "../../assets/config/errorCode.json"
import { TimezoneTranslator } from "../../middleware/dateUtils"
import {
  InputBarBg,
  InputBarRWD,
  InputBar,
  Input,
  BtnWrapper,
  BtnG1,
  BtnG2,
  Btn
} from "./styledComponent.js"
import {
  SearchButton,
  ResetButton,
  BasicTable,
  BasicRowTitle
} from "../../middleware/utilityStyle.js"

export default function AccountSetting() {
  const [panelInfo, setPanelInfo] = useState([])
  const [allAccount, setAllAccount] = useState([])
  const [popData, setPopData] = useState({})
  const [radioValue, setRadioValue] = useState('')
  const [characterIdList, setCharacterIdList] = useState([])
  const [change, setChange] = useState({
    navAccount: '',
    navName: '',
    addAccValue: '',
    addNameValue: '',
    addAuthValue: '',
    reviseAuth: ''
  })
  const [error, setError] = useState('')
  const history = useHistory()
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      getAllAcc().then(db => {
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        setPanelInfo(db.data)
        setAllAccount(db.data)
      })
      getAllCharacter().then(db => {
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        setCharacterIdList(db.data)
        setChange(prev => ({
          ...prev,
          addAuthValue: db.data[0].characterID,
          reviseAuth: db.data[0].characterID
        }))
      })
    })
  }, [])


  const handleChange = (e, key) => {
    setChange(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange(prev => ({
        ...prev,
        navAccount: '',
        navName: ''
      }))
    })
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!change.navAccount && !change.navName) {
        setPanelInfo(allAccount)
      } else {
        const found = allAccount.find(ele => ele.account === change.navAccount || ele.userName === change.navName)
        if (found) {
          setPanelInfo([found])
        } else {
          setPanelInfo(allAccount)
          alert(`※  ${errorCode.RECORD_NOT_FOUND.memo}`)
        }
      }
    })
  }

  const handleRadioValue = e => {
    let chosenUserInfo = panelInfo.find(userInfo => userInfo.account === e.target.value)
    setPopData(chosenUserInfo)
    setRadioValue(e.target.value)
  }
  {/* 新增 Btn 1st popUp */ }
  const [showAdd, setShowAdd] = useState(false)
  const handleAddClose = () => {
    setShowAdd(false)
    setChange(prev => ({
      ...prev,
      addAccValue: '',
      addNameValue: '',
      addAuthValue: characterIdList[0].characterID
    }))
  }
  const handleAddShow = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 7)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      setShowAdd(true)
    })
  }

  {/* 新增 Btn 2nd popUp */ }
  const [showAddSecPopUp, setShowAddSecPopUp] = useState(false)
  const handleAddSecPopUpClose = () => {
    setShowAddSecPopUp(false)
    getAllAcc()
      .then(db => {
        setPanelInfo(db.data)
      })
  }
  const handleAddSecPopUpShow = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setShowAddSecPopUp(true)
    })
  }
  const [defaultPassword, setDefaultPassword] = useState('')

  {/* 修改 Btn popUp */ }
  const [showRevise, setShowRevise] = useState(false)
  const handleReviseClose = () => setShowRevise(false)
  const handleReviseShow = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange(prev => ({
        ...prev,
        reviseAuth: 0
      }))
      if (!userPID.find(ele => +ele === 2)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (!radioValue) {
        alert('※  請選取欲修改之帳號')
        return
      } else {
        setShowRevise(true)
      }
    })
  }

  {/* 刪除 Btn popUp */ }
  const [showDelete, setShowDelete] = useState(false)
  const handleDeleteClose = () => setShowDelete(false)
  const handleDeleteShow = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 3)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (!radioValue) {
        alert('※  請選取欲刪除之帳號')
        return
      } else {
        setShowDelete(true)
      }
    })
  }

  {/* 禁用 Btn popUp */ }
  const [showBan, setShowBan] = useState(false)
  const handleBanClose = () => setShowBan(false)
  const handleBanShow = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 6)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (!radioValue) {
        alert('※  請選取欲禁用之帳號')
        return
      } else {
        setShowBan(true)
      }
    })
  }

  {/* 啟用 Btn popUp */ }
  const [showEnable, setShowEnable] = useState(false)
  const handleEnableClose = () => setShowEnable(false)
  const handleEnableShow = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 6)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (!radioValue) {
        alert('※  請選取欲啟用之帳號')
        return
      } else {
        setShowEnable(true)
      }
    })
  }

  {/* 重置密碼 Btn 1st popUp */ }
  const [showReset, setShowReset] = useState(false)
  const handleResetClose = () => setShowReset(false)
  const handleResetShow = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 5)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (!radioValue) {
        alert('※  請選取欲重置密碼之帳號')
        return
      } else {
        setShowReset(true)
      }
    })
  }
  const [password, setPassword] = useState('')

  {/* 重置密碼 Btn 2nd popUp */ }
  const [showResetSecPopUp, setShowResetSecPopUp] = useState(false)
  const handleResetSecPopUpClose = () => {
    setShowResetSecPopUp(false)
  }
  const handleResetSecPopUpShow = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      resetPSWD(popData.account).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setPassword(db.data)
        handleResetClose()
        setShowResetSecPopUp(true)
      })
    })
  }
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <InputBarRWD>
          <div>
            帳號<Input placeholder="Account" value={change.navAccount} onChange={e => {handleChange(e, 'navAccount')}} />
          </div>
          <InputBar>
            姓名<Input placeholder="Name" value={change.navName} onChange={e => {handleChange(e, 'navName')}} />
          </InputBar>
        </InputBarRWD>
        <div>
          <SearchButton onClick={handleSearch}>搜索</SearchButton>
          <ResetButton onClick={handleReset}>重置</ResetButton>
        </div>
      </InputBarBg>

      <BasicTable className="table table-hover table-bordered">
        <thead>
          <BasicRowTitle>
            <th scope="col">
              <input type="radio" id="flexCheckChecked" disabled />
            </th>
            <th scope="col">帳號</th>
            <th scope="col">角色</th>
            <th scope="col">姓名</th>
            <th scope="col">最後登入時間</th>
            <th scope="col">註冊時間</th>
            <th scope="col">帳號狀態</th>
          </BasicRowTitle>
        </thead>
        <tbody>
          {
            Array.isArray(panelInfo) ?
              panelInfo.map((EachData, index) => {
                return (
                  <tr key= {index}>
                    <th scope="row">
                      <input
                        type="radio"
                        id={EachData.account}
                        name="accountList"
                        value={EachData.account}
                        onChange={handleRadioValue}
                        checked={radioValue === EachData.account ? 'checked' : ''}
                      />
                    </th>
                    <td>{EachData.account}</td>
                    <td>
                      {
                        Array.isArray(characterIdList) && characterIdList.length > 0 ?
                          characterIdList.find(character => character.characterID === EachData.type) ?
                            characterIdList.find(character => character.characterID === EachData.type).characterName :
                            '無此角色'
                          : ''
                      }
                    </td>
                    <td>{EachData.userName}</td>
                    <td>{EachData.lastLogin ? TimezoneTranslator(EachData.lastLogin.toString()) : ''}</td>
                    <td>{EachData.regDate ? TimezoneTranslator(EachData.regDate.toString()).slice(0, 10) : ''}</td>
                    <td>{EachData.ifAvailable ? '啟用' : '禁用'}</td>
                  </tr>
                )
              })
              : <tr key= "noInfo"><td colSpan= "7">查無資料</td></tr>
          }
        </tbody>
        <tfoot></tfoot>
      </BasicTable>

      <BtnWrapper className="btn-group">
        <BtnG1>
          <Btn className="butt-btn" onClick={handleAddShow}>新增</Btn>
          <Btn className="butt-btn" onClick={handleReviseShow}>修改</Btn>
          <Btn className="butt-btn" onClick={handleDeleteShow}>刪除</Btn>
        </BtnG1>
        <BtnG2>
          <Btn className="butt-btn" onClick={handleBanShow}>禁用</Btn>
          <Btn className="butt-btn" onClick={handleEnableShow}>啟用</Btn>
          <Btn className="butt-btn" onClick={handleResetShow}>重置密碼</Btn>
        </BtnG2>
      </BtnWrapper>

      {/* 新增帳號 1st popUp */}
      <AddAccountFirstPU
        setPanelInfo={setPanelInfo}
        handleChange={handleChange}
        characterIdList={characterIdList}
        showAdd={showAdd}
        setShowAdd={setShowAdd}
        handleAddClose={handleAddClose}
        change={change}
        setChange={setChange}
        handleAddSecPUShow={handleAddSecPopUpShow}
        setError={setError}
        setDefaultPassword={setDefaultPassword}
        history={history}
        setAllAccount={setAllAccount}
      />

      {/* 新增帳號 2nd popUp */}
      <AddAccountSecondPU
        showPopUp={showAddSecPopUp}
        handleClose={handleAddSecPopUpClose}
        defaultPassword={defaultPassword}
        error={error}
      />

      {/* 修改 popUp */}
      <ReviseAcc
        setPanelInfo={setPanelInfo}
        characterIdList={characterIdList}
        data={popData}
        show={showRevise}
        setShow={setShowRevise}
        change={change}
        handleChange={handleChange}
        history={history}
        setAllAccount={setAllAccount}
      />

      {/* 刪除 popUp */}
      <DeleteAcc
        popData={popData}
        setPanelInfo={setPanelInfo}
        showDelete={showDelete}
        handleDeleteClose={handleDeleteClose}
        setRadioValue={setRadioValue}
        history={history}
        setAllAccount={setAllAccount}
      />

      {/* 禁用 popUp */}
      <BanAcc
        change={change}
        popData={popData}
        setPopData={setPopData}
        showBan={showBan}
        handleBanClose={handleBanClose}
        setPanelInfo={setPanelInfo}
        history={history}
        setAllAccount={setAllAccount}
      />

      {/* 啟用 popUp */}
      <EnableAcc
        change={change}
        popData={popData}
        setPopData={setPopData}
        showEnable={showEnable}
        handleEnableClose={handleEnableClose}
        setPanelInfo={setPanelInfo}
        history={history}
        setAllAccount={setAllAccount}
      />

      {/* 重置密碼 1st popUp */}
      <ResetPasswordFirstPU
        popData={popData}
        showReset={showReset}
        handleResetClose={handleResetClose}
        handleResetSecPopUpShow={handleResetSecPopUpShow}
      />

      {/* 重置密碼 2nd popUp */}
      <ResetPasswordSecondPU
        popData={popData}
        password={password}
        setPassword={setPassword}
        showResetSecPopUp={showResetSecPopUp}
        setResetSecPopUp={setShowResetSecPopUp}
        handleResetSecPopUpClose={handleResetSecPopUpClose}
      />
    </>
  )
}