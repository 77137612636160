import React, { useState, useEffect , createContext, useContext }from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap" 
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"


import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import {
  PULeftTitle,
  PUSelect,
  PUInput
} from  "../../../views/backstageManagement/adSettingForFinalCalculate/styledComponent"
import { ModalFormat, ModalBtn } from "../../../middleware/utilityStyle"
import {updateSetting, getAdSetting} from "../../../middleware/Api/backstageManagement/adSetting"

import gameOptions from "../../../assets/config/gameCategories.json"
import roomTypes from "../../../assets/config/gameType.json"
import occurrenceCondiction from "./occurrenceCondiction.json"
import occurrenceMode from "./occurrenceMode.json"
import prizeMode from "./prizeMode"
import prizeContent from "./prizeContent"

import {data, rateSettingData, noticeMessageData, settingData} from "./Contexts"
import SelectComponent from "./selectComponent"
import RateSettingComponent from "./rateSettingComponent"
import NoticeMessageComponent from "./noticeMessageComponent"
import DeletePopUp from "./deletePopUp"

import { setAuthToken, getNavAcc, getAccessArr, setAccessArr, setCharacterType } from "../../../middleware/utils.js"

export default function ReviseAdSettingComponent({
  showAddRevise,
  handleSwitch,
}){

  const history = useHistory()
  
  const [
    reviseTarget, setReviseTarget,
    rollSetting, setRollSetting,
    rateSetting, setRateSetting,
    noticeMessage, setNoticeMessage,
    change,setTargetData,
    handleSearch
  ] = useContext(settingData)
  const [deleteWindow, setDeleteWindow]=useState(false)
  const accessArr = getAccessArr()
  let userPID = []
    accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })

  const handleRollSetting = (key, data)=>{
    let targetKeys  = Object.keys(rollSetting)
    if (+data.prizeType === 0 ) {
      targetKeys = targetKeys.filter(k=>+k !==key) 
      let result = {} 
      targetKeys.forEach(k=>{
        result[k] = rollSetting[k]
      }) 
      setRollSetting(result)
      return 
    }

    setRollSetting(prev => ({
      ...prev,
      [key]: data
    }))
  }
  const handleSetRateSetting = (key,data) =>{
    if (key ==="switch"&& data=== "0"){
      let keys = Object.keys(rateSetting).filter(key =>key !== "switch")
      let copy ={}
      if (key ==="switch"){
        copy[key] = data
      }
      setRateSetting(copy)
    }else if (data.rate==="" && data.weight==="") {
      let keys = Object.keys(rateSetting).filter(k=> +k!== key)
      let result = {}
      keys.forEach(k=>{
        result[k] = rateSetting[k]
      })
      setRateSetting(result)

    }else{
      setRateSetting(prev => ({
        ...prev,
        [key]: data
      }))
    }
    
  }
  const handleConfirm = ()=>{
    sessionVerified().then(db =>{
      if (db.error===errorCode.CODE_NOT_MATCH.code){
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code){
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 93)) {
        alert('  ※無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      const formKeys = Object.keys(reviseTarget)
      for (let key of formKeys){
        if (reviseTarget[key]==="") {
          alert("訊息請提供完整")
          return
        }
      }
      const rollSettingKeys = Object.keys(rollSetting)
      let directType = false
      let rollType = false
      for (let key of rollSettingKeys){
        let target = rollSetting[key]
        const {itemID, prizeType, quantity,weight} = target
        if (+key< 1 &&reviseTarget.AWARD_TYPE === 0){
          if (Object.prototype.toString.call(target) ==="[object Object]"){
            if (+prizeType > 0 ){
              if (quantity === "") {
                alert(`獎勵內容 ${key>10?key-10:key}項, 需要提供數量`)
                return
              }
            }
            directType = +key<1 ?true:false
          }
        } else if (+key > 0 &&reviseTarget.AWARD_TYPE === 1) {
          if (Object.prototype.toString.call(target) ==="[object Object]"){
            if (+prizeType > 0 ){
              if (quantity === "" || weight=== ""){
                alert(`拉霸滾輪設定 ${key>10?key-10:key}項, 需要提供權重和數量`)
                return
              }
            }
            rollType =+key>0?true:false
          }
        }
      }
      if(!rollType&&reviseTarget.AWARD_TYPE === 1){
        alert("拉霸滾輪設定 需要至少一組設定")
        return 
      }
      if(!directType&&reviseTarget.AWARD_TYPE === 0){
        alert("獎勵內容 需要提供設定")
        return 
      }
      if(rateSetting.switch ==="1"){
        let keys = Object.keys(rateSetting).filter(key =>key !== "switch")
        if (keys.length === 0 ) {
          alert("至少需要一組倍率設定")
          return
        }
        for(let key of keys){
          let {rate,weight} = rateSetting[key]
          if (rate !=='' && weight ===''){
            alert(`拉霸倍率設定 第${key}項,須提供權重`)
            return
          }
          if (rate ==='' && weight !==''){
            alert(`拉霸倍率設定 第${key}項,須提供倍率`)
            return
          }
        }
      }
      if (noticeMessage.switch ==="1"){
        let keys = Object.keys(noticeMessage).filter(key =>key !== "switch")
        if (keys.length ===0|| noticeMessage["message"]==='') alert("提示視窗: 請提供視窗訊息")
      }
      let configDetail = {
        rollSetting,
        rateSetting,
      }
      let result = {
        GRAC_ID:reviseTarget.GRAC_ID,
        GAME_ID:+reviseTarget.GAME_ID,
        GAME_TYPE:+reviseTarget.GAME_TYPE,
        TRIGGER_TYPE:+reviseTarget.TRIGGER_TYPE,
        TRIGGER_MODEL:+reviseTarget.TRIGGER_MODEL,
        GOAL_TIMES:+reviseTarget.GOAL_TIMES,
        TRIGGER_LIMT_TIMES:+reviseTarget.TRIGGER_LIMT_TIMES,
        AWARD_TYPE:+reviseTarget.AWARD_TYPE,
        AWARD_CONFIG:JSON.stringify(configDetail),
        HINT_STATE:+noticeMessage.switch,
        HINT_CONFIG:JSON.stringify(noticeMessage),
        STATE:+reviseTarget.STATE
      }
      updateSetting(result).then(data =>{
      }).catch(error =>{ console.log(error)})

      getAdSetting().then(data => {
        let finalResult = JSON.parse(JSON.stringify(data.data))
        const [navGame, navGameType,mode] = change
        if (navGame !==""){
          finalResult = finalResult.filter(data =>data.GAME_ID === +navGame)
        }

        if (navGameType !==""){
          finalResult = finalResult.filter(data =>+data.GAME_TYPE === +navGameType)
        }

        if (mode !==""){
          finalResult = finalResult.filter(data =>+data.TRIGGER_TYPE === +mode)
        }

        setTargetData(finalResult)
      }).catch(error =>{ console.log(error)})

      handleSwitch(false)
      handleSearch()
    })
  }

  const handleModifyData = (e, key) => {
    if (key ==="AWARD_TYPE"){
      setRollSetting({})
    }
    setReviseTarget(prev => ({
      ...prev,
      [key]: key !== "HINT_CONFIG"&& key !=="AWARD_CONFIG"? +e.target.value:e.target.value
    }))
  }

  const handleMode = () =>{
    if (`${reviseTarget.AWARD_TYPE}` ==="0") return (
      <>
      <tr>
        <PULeftTitle>獎勵內容</PULeftTitle>
        <td>
          <Container fluid>
            <data.Provider value ={[rollSetting, handleRollSetting]}>
              <SelectComponent dataNumber={0}/>
            </data.Provider>
          </Container>
        </td>

      </tr>
      </>
    )
    if (`${reviseTarget.AWARD_TYPE}` ==="1") return (
      <>
        <tr>
          <PULeftTitle>拉霸滾輪設定</PULeftTitle>
          <td>
            <Container fluid>
              <data.Provider value ={[rollSetting, handleRollSetting]}>
              <SelectComponent dataNumber={1}/>
              <SelectComponent dataNumber={2}/>
              <SelectComponent dataNumber={3}/>
              <SelectComponent dataNumber={4}/>
              <SelectComponent dataNumber={5}/>
              <SelectComponent dataNumber={6}/>
              <SelectComponent dataNumber={7}/>
              <SelectComponent dataNumber={8}/>
              <SelectComponent dataNumber={9}/>
              <SelectComponent dataNumber={10}/>
              </data.Provider>  
            </Container>
          </td>
        </tr>
        <tr>
        <PULeftTitle>拉霸倍率設定</PULeftTitle>
        <td>
        <div>
          <select
            value={rateSetting.switch}
            onChange={e=>{handleSetRateSetting("switch", e.target.value)}}
          >
            <option value={"0"} selected>關閉</option>
            <option value={"1"}>開啟</option>

          </select>
        </div>
        <Container fluid>
        <rateSettingData.Provider value = {[rateSetting,handleSetRateSetting]} >
          <RateSettingComponent dataNumber ={1} />
          <RateSettingComponent dataNumber ={2} />
          <RateSettingComponent dataNumber ={3} />
          <RateSettingComponent dataNumber ={4} />
          <RateSettingComponent dataNumber ={5} />
          <RateSettingComponent dataNumber ={6} />
          <RateSettingComponent dataNumber ={7} />
          <RateSettingComponent dataNumber ={8} />
          <RateSettingComponent dataNumber ={9} />
          <RateSettingComponent dataNumber ={10} />
        </rateSettingData.Provider>
        </Container>
        </td>
        </tr>
      </>
    )
  }


  const selectionLayOut = (target,formKey ) =>{
    return (
      <PUSelect
      id = {formKey}
      value={reviseTarget[formKey]}
      onChange={e =>{handleModifyData(e,formKey)}}
      >
      {target.map((set,index) =>{

        const [first, second] = Object.keys(set)
        if (formKey ==="GAME_TYPE"){
          return (
            <>
              <option value={set[first] - 1} >{set[second]}</option>
            </>
          )
        }else {
          return (
            <>
              <option value={set[first]} >{set[second]}</option>
            </>
          )
        }
        

      })}
      </PUSelect>
      
    )
  }
  return(
    <>
      <Modal
        size="lg"
        show={showAddRevise}
        onHide={handleSwitch}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>設定廣告管理</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
                <tbody>
                  <tr>
                  <PULeftTitle>ID</PULeftTitle>
                  <td><Container>{reviseTarget.GRAC_ID}</Container></td>
                  </tr>
                  <tr>
                    <PULeftTitle>遊戲</PULeftTitle>
                    <td>
                      <Container fluid>
                      <Row xs={"auto"}>
                        <Col>{selectionLayOut(gameOptions,"GAME_ID")}</Col>
                        <Col>
                          <label for ="chosenGameType">場次:</label>
                          {selectionLayOut(roomTypes,"GAME_TYPE")}
                        </Col>
                      </Row>
                      </Container>
                    </td>
                  </tr>
                  <tr>
                  <PULeftTitle>觸發條件</PULeftTitle>
                  <td>
                  <Container fluid>
                    <Row>
                      <Col>{selectionLayOut(occurrenceCondiction,"TRIGGER_TYPE" )}</Col>
                      <Col>
                        <label>
                          模式:
                          {selectionLayOut(occurrenceMode,"TRIGGER_MODEL")}
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                      <label>
                        共<PUInput value={reviseTarget.GOAL_TIMES} type="number" onChange={e =>{handleModifyData(e,"GOAL_TIMES")}} min={0}></PUInput>場
                      </label>
                      </Col>
                    </Row>
                  </Container>
                </td>
                  </tr>
                  <tr>
                    <PULeftTitle>每日觸發上限</PULeftTitle>
                    <td>
                    <Container>
                    <PUInput 
                    type="number"
                    placeholder="0 代表無限制"
                    value={reviseTarget.TRIGGER_LIMT_TIMES}
                    onChange={e=>{handleModifyData(e,"TRIGGER_LIMT_TIMES")}}
                    min={0}
                    ></PUInput>
                    </Container>
                    </td>
                  </tr>
                  <tr>
                  <PULeftTitle>獎勵模式</PULeftTitle>
                  <td>
                    <Container>
                    {selectionLayOut(prizeMode,"AWARD_TYPE")}
                    </Container>
                  </td>
                  </tr>
                  {handleMode()}
                  <tr>
                  <PULeftTitle>提示視窗</PULeftTitle>
                    <td>
                      <Container>
                        <noticeMessageData.Provider value={[noticeMessage, setNoticeMessage]}>
                          <NoticeMessageComponent/>
                        </noticeMessageData.Provider>
                      </Container>
                    </td>
                  </tr>
                  <tr>
                    <PULeftTitle>開關</PULeftTitle>
                    <td>
                      <Container>
                      <PUSelect
                      value={reviseTarget.STATE}
                      onChange={e=>{handleModifyData(e,"STATE")}}
                      >
                        <option value={"0"} selected>關閉</option>
                        <option value={"1"} >開啟</option>
                      </PUSelect>
                      </Container>
                    </td>
                  </tr>
                </tbody>
          </table>
        </Modal.Body>
        <ModalBtn>
        <Button variant="danger" onClick={() =>{setDeleteWindow(true)}} >刪除</Button>
        <Button variant="warning" onClick={() =>{handleSwitch(false)}} >取消</Button>
        <Button variant="info" onClick={() =>{handleConfirm()}} >確定</Button>

        </ModalBtn>

      </Modal>

      <DeletePopUp
        showDeletePU={deleteWindow}
        handleDeletePUClose={setDeleteWindow}
        popData = {reviseTarget}
        handleSwitch = {handleSwitch}
        reload ={handleSearch}
      />
    </>
  )
}