import { getAuthToken } from '../../utils.js'
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 取得競技場設定 PID99 ?//
export const getArenaConfig = (sDate, eDate, arenaId, sRow, eRow) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/arenaConfig?arenaId=${arenaId}&sDate=${sDate}&eDate=${eDate}&sRow=${sRow}&eRow=${eRow}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 新增競技場設定 PID100 ?//
export const addArenaConfig = (
  ladderId,
  ladderName, 
  ladderGameType, 
  gameId, 
  startDate, 
  endDate,
  gamePrizePoolState, 
  gamePrizePool, 
  gamePrizePoolWeight, 
  gamePrizePoolPay, 
  onlineActCodeState, 
  onlineActCode, 
  onlineNumState, 
  actStartTime, 
  actEndTime, 
  ladderWeek1, 
  ladderStartTime1, 
  ladderEndTime1, 
  ladderWeek2, 
  ladderStartTime2, 
  ladderEndTime2,
  signUpStartTime1, 
  signUpEndTime1, 
  signUpStartTime2, 
  signUpEndTime2, 
  BonusStartTime11, 
  BonusEndTime11, 
  BonusRatio11, 
  BonusStartTime12, 
  BonusEndTime12, 
  BonusRatio12, 
  BonusStartTime13, 
  BonusEndTime13, 
  BonusRatio13, 
  BonusStartTime21, 
  BonusEndTime21, 
  BonusRatio21, 
  BonusStartTime22, 
  BonusEndTime22, 
  BonusRatio22, 
  BonusStartTime23, 
  BonusEndTime23, 
  BonusRatio23, 
  BonusStartWeek11, 
  BonusStartWeek12,
  BonusStartWeek13,
  BonusStartWeek21,
  BonusStartWeek22,
  BonusStartWeek23,
  BonusStartDate11,
  BonusStartDate12,
  BonusStartDate13,
  BonusStartDate21,
  BonusStartDate22,
  BonusStartDate23,
  BonusEndDate11,
  BonusEndDate12,
  BonusEndDate13,
  BonusEndDate21,
  BonusEndDate22,
  BonusEndDate23,
  scoreRule, 
  ladderType) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/arenaConfig`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      ladderId, 
      ladderName, 
      ladderGameType, 
      gameId, 
      startDate, 
      endDate,
      gamePrizePoolState, 
      gamePrizePool, 
      gamePrizePoolWeight, 
      gamePrizePoolPay, 
      onlineActCodeState, 
      onlineActCode, 
      onlineNumState, 
      actStartTime, 
      actEndTime, 
      ladderWeek1, 
      ladderStartTime1, 
      ladderEndTime1, 
      ladderWeek2, 
      ladderStartTime2, 
      ladderEndTime2,
      signUpStartTime1, 
      signUpEndTime1, 
      signUpStartTime2, 
      signUpEndTime2, 
      BonusStartTime11, 
      BonusEndTime11, 
      BonusRatio11, 
      BonusStartTime12, 
      BonusEndTime12, 
      BonusRatio12, 
      BonusStartTime13, 
      BonusEndTime13, 
      BonusRatio13, 
      BonusStartTime21, 
      BonusEndTime21, 
      BonusRatio21, 
      BonusStartTime22, 
      BonusEndTime22, 
      BonusRatio22, 
      BonusStartTime23, 
      BonusEndTime23, 
      BonusRatio23, 
      BonusStartWeek11, 
      BonusStartWeek12,
      BonusStartWeek13,
      BonusStartWeek21,
      BonusStartWeek22,
      BonusStartWeek23,
      BonusStartDate11,
      BonusStartDate12,
      BonusStartDate13,
      BonusStartDate21,
      BonusStartDate22,
      BonusStartDate23,
      BonusEndDate11,
      BonusEndDate12,
      BonusEndDate13,
      BonusEndDate21,
      BonusEndDate22,
      BonusEndDate23,
      scoreRule, 
      ladderType
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 更改競技場設定 PID101 ?//
export const reviseArenaConfig = (
  ladderId,
  ladderName, 
  ladderGameType, 
  gameId, 
  startDate, 
  endDate,
  gamePrizePoolState, 
  gamePrizePool, 
  gamePrizePoolWeight, 
  gamePrizePoolPay, 
  onlineActCodeState, 
  onlineActCode, 
  onlineNumState, 
  actStartTime, 
  actEndTime, 
  ladderWeek1, 
  ladderStartTime1, 
  ladderEndTime1, 
  ladderWeek2, 
  ladderStartTime2, 
  ladderEndTime2,
  signUpStartTime1, 
  signUpEndTime1, 
  signUpStartTime2, 
  signUpEndTime2, 
  BonusStartTime11, 
  BonusEndTime11, 
  BonusRatio11, 
  BonusStartTime12, 
  BonusEndTime12, 
  BonusRatio12, 
  BonusStartTime13, 
  BonusEndTime13, 
  BonusRatio13, 
  BonusStartTime21, 
  BonusEndTime21, 
  BonusRatio21, 
  BonusStartTime22, 
  BonusEndTime22, 
  BonusRatio22, 
  BonusStartTime23, 
  BonusEndTime23, 
  BonusRatio23, 
  BonusStartWeek11, 
  BonusStartWeek12,
  BonusStartWeek13,
  BonusStartWeek21,
  BonusStartWeek22,
  BonusStartWeek23,
  BonusStartDate11,
  BonusStartDate12,
  BonusStartDate13,
  BonusStartDate21,
  BonusStartDate22,
  BonusStartDate23,
  BonusEndDate11,
  BonusEndDate12,
  BonusEndDate13,
  BonusEndDate21,
  BonusEndDate22,
  BonusEndDate23,
  scoreRule, 
  ladderType
) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/arenaConfig`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      ladderId,
      ladderName, 
      ladderGameType, 
      gameId, 
      startDate, 
      endDate,
      gamePrizePoolState, 
      gamePrizePool, 
      gamePrizePoolWeight, 
      gamePrizePoolPay, 
      onlineActCodeState, 
      onlineActCode, 
      onlineNumState, 
      actStartTime, 
      actEndTime, 
      ladderWeek1, 
      ladderStartTime1, 
      ladderEndTime1, 
      ladderWeek2, 
      ladderStartTime2, 
      ladderEndTime2,
      signUpStartTime1, 
      signUpEndTime1, 
      signUpStartTime2, 
      signUpEndTime2, 
      BonusStartTime11, 
      BonusEndTime11, 
      BonusRatio11, 
      BonusStartTime12, 
      BonusEndTime12, 
      BonusRatio12, 
      BonusStartTime13, 
      BonusEndTime13, 
      BonusRatio13, 
      BonusStartTime21, 
      BonusEndTime21, 
      BonusRatio21, 
      BonusStartTime22, 
      BonusEndTime22, 
      BonusRatio22, 
      BonusStartTime23, 
      BonusEndTime23, 
      BonusRatio23, 
      BonusStartWeek11, 
      BonusStartWeek12,
      BonusStartWeek13,
      BonusStartWeek21,
      BonusStartWeek22,
      BonusStartWeek23,
      BonusStartDate11,
      BonusStartDate12,
      BonusStartDate13,
      BonusStartDate21,
      BonusStartDate22,
      BonusStartDate23,
      BonusEndDate11,
      BonusEndDate12,
      BonusEndDate13,
      BonusEndDate21,
      BonusEndDate22,
      BonusEndDate23,
      scoreRule, 
      ladderType
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 刪除競技場設定 PID102 ?//
export const deleteArenaConfig = ladderId => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/arenaConfig/delete`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      ladderId
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}
