import React from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { ModalBtn, ModalText } from "../../../middleware/utilityStyle"
import { deleteCoupon } from "../../../middleware/Api/operation/couponApi.js"

export default function Remove(props) {
  const {
    showDelete,
    handleDeleteClose,
    box,
  } = props
  const history = useHistory()
  const handleRemove = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
      alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
      history.push("/login")
      return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
      alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
      history.push("/login")
      return
      }
      for (let i = 0; i < box.length; i++) {
        deleteCoupon(box[i]).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
              alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
              return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
              alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
              return
          }
          if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
          }
        })
      }
      window.location.reload()
    })
  }
  return (
  <>
    <Modal
    show={showDelete}
    onHide={handleDeleteClose}
    backdrop="static"
    keyboard={false}
    >
    <Modal.Header closeButton>
        <Modal.Title>刪除序號</Modal.Title>
    </Modal.Header>
    <ModalText>
        確定要刪除所選序號嗎?
    </ModalText>
    <ModalBtn>
        <Button variant="info" onClick= {handleRemove}>確認</Button>
        <Button variant="secondary" onClick= {handleDeleteClose}>取消</Button>
    </ModalBtn>
    </Modal>
  </>
  )
  }