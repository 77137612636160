import styled from 'styled-components'
import * as Icon from 'react-bootstrap-icons'
import { BarBG, RangePack, DayRange, BasicInput } from '../../../middleware/utilityStyle'

export const Text = styled.textarea`
  width: 500px;
`

export const PULeftTitle = styled.th`
  text-align: center;
`

export const PUSelect = styled.select`
  border: 1px solid black;
  border-radius: 5px;
  height: 28px;
  width: 180px;
`

export const PUInput = styled.input`
  border: 1px solid black;
  border-radius: 5px;
  height: 28px;
  width: 180px;
`


export const InputBarBg = styled(BarBG)`
  @media screen and (max-width: 1800px){
    & {
      flex-direction: column;
      justify-content: center;
    }
  }
`

export const NavLeft = styled.div`
  display: flex;
  @media screen and (max-width: 1800px){
    & {
      flex-direction: column;
      justify-content: center;
    }
  }
`

export const InputBarRWD = styled.div`
  display: flex;
  align-items: center;
`
export const DropWrapper = styled.span`
  margin-left: 20px;
`

export const Dropdown = styled.select`
  margin-left: 20px;
  border: 1px solid black;
  border-radius: 5px;
  height: 28px;
  width: 200px;
  @media screen and (max-width: 700px) {
    width: 150px;
  }
`
export const BtnGroup = styled.div`
  @media screen and (max-width: 1800px){
    margin-top: 10px;
  }
`