import React from "react"
import Modal from 'react-bootstrap/Modal'
import { Button } from "react-bootstrap"
import { updateCharacter } from "../../../middleware/Api/roleApi"
import { PopupName } from "../../../views/BTNroleSetting/styledComponent.js"
import { BasicTable, BigSquareTitle, BigSquareInput, ModalFormat, ModalBtn } from '../../../middleware/utilityStyle'
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"

export default function ReviseRole(props) {
  const {
    change,
    setChange,
    handleChange,
    showRevise,
    handleReviseClose,
    setPanelInfo,
    popData,
    setPopData,
    history,
    setAllRole
  } = props

  const handleRevise = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!change.reviseName || !change.reviseDes) {
        alert('請輸入空白處⊙ w ⊙')
        return
      }
      if (popData.characterName === change.reviseName) {
        alert('※  修改名稱需與原本不同')
        let newChange = Object.assign({}, change)
        newChange.reviseName = ''
        setChange(newChange)
        return
      }
      updateCharacter(popData.characterID, change.reviseName, change.reviseDes).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setPanelInfo(db.data)
        setAllRole(db.data)
        let newPopData = Object.assign({}, popData)
        newPopData.characterName = change.reviseName
        newPopData.description = change.reviseDes
        setPopData(newPopData)
        handleReviseClose()
      })
    })
  }

  return (
    <>
      <ModalFormat
        show={showRevise}
        onHide={handleReviseClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>修改角色</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PopupName>原角色名稱: {popData.characterName}</PopupName>
          <BasicTable className="table table-bordered">
            <tbody>
              <tr>
                <BigSquareTitle>角色名稱</BigSquareTitle>
                <td>
                  <BigSquareInput placeholder="請輸入欲修改角色名稱" name="reviseName" value={change.reviseName} onChange={handleChange} />
                </td>
              </tr>
              <tr>
                <BigSquareTitle>角色描述</BigSquareTitle>
                <td>
                  <BigSquareInput placeholder="請輸入角色名稱的備註" name="reviseDes" value={change.reviseDes} onChange={handleChange} />
                </td>
              </tr>
            </tbody>
          </BasicTable>
        </Modal.Body>
        <ModalBtn>
          <Button variant="info" onClick={handleRevise}>確定</Button>
          <Button variant="light" onClick={handleReviseClose}>取消</Button>
        </ModalBtn>
      </ModalFormat>
    </>
  )
}