import React, { useState, useEffect }from "react"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import { reviseChessMultiInfo } from "../../../middleware/Api/operation/chessMultiplicationControlApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { TableForm } from "../../../views/backstageManagement/dzbMissionReport/styledComponent.js"
import { PopInput } from "../../../views/operation/chessMultiplicationControl/styledComponent.js"
import { ModalBtn, BasicHeadTitle, ModalFormat } from "../../../middleware/utilityStyle"
import TitleList from "../../../assets/config/chessMultiplicationTitle.json"
import { getAccessArr } from "../../../middleware/utils"

export default function ReviseChessMultiplication(props) {
  const {
    history,
    showRevise,
    handleReviseClose,
    checkboxValue,
    timesKey,
    dataCopy,
    displayData,
    setDisplayData,
    chessType,
    setTableInfo,
    setCheckboxValue
  } = props

  const [reviseList, setReviseList] = useState([])
  const accessArr = getAccessArr()
  let userPID = []
    accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      checkboxValue.sort((a, b) => {
        return a - b
      })
      let list = []
      for (let i =0; i < checkboxValue.length; i++) {
        list.push(TitleList.find(ele => +ele.id === +checkboxValue[i]).key)
      }
      setReviseList(list)
      setDisplayData(JSON.parse(JSON.stringify(dataCopy)))
    })
  }, [checkboxValue])

  const handleRevise = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 81 )) {
        alert('※ 無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      if (displayData[chessType]) {
        let checkNum = []
        for (let i = 0; i < reviseList.length; i++) {
          for (let j = 0; j < timesKey.length; j++) {
            checkNum.push(displayData[chessType][reviseList[i]][j].rate)
          }
        }
        let allNum = checkNum.every(ele => !isNaN(+ele))
        if (!allNum) {
          alert('請輸入數字')
          return
        }
      }
      reviseChessMultiInfo(JSON.stringify(displayData), chessType).then(db => {        
        if (db.data.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.data.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.data.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        handleReviseClose()
        setTableInfo({})
        setCheckboxValue([])
        setTimeout(() => {
          alert(db.data)
        }, 500)
      })
    })
  }

  const handleBack = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleReviseClose()
      setTimeout(() => {
        setDisplayData(JSON.parse(JSON.stringify(dataCopy)))
      }, 500)
    })
  }

  const handleName = () => {
    let title = []
    title.push(<td style= {{backgroundColor: 'rgb(237, 108, 2)', width: '150px'}}></td>)
    for (let k = 0; k < reviseList.length; k++) {
      title.push(
        <BasicHeadTitle style= {{backgroundColor: 'rgb(237, 108, 2)', width: '150px'}}>{TitleList.find(ele => ele.key === reviseList[k]).name}</BasicHeadTitle>
      )
    }
    return (
      <tr>
        {title}
      </tr>
    )
  }
  
  const handleReviseInfo = e => {
    let newDisplay = JSON.parse(JSON.stringify(displayData))
    let target = newDisplay[chessType][e.target.dataset.levelkey].find(ele => +ele.times === +e.target.dataset.times)
    target.rate = e.target.value
    setDisplayData(newDisplay)
  }

  const handleContent = () => {
    let tableList = []
    function handleCol(index) {
      let colList = []
      for (let l = 0; l < reviseList.length; l++) {
        colList.push(
          <td key= {l}>
            <PopInput
              value={displayData[chessType] ? displayData[chessType][reviseList[l]].find(ele => +ele.times === +timesKey[index]).rate : {}}
              data-levelkey= {reviseList[l]}
              data-times= {timesKey[index]}
              onChange= {e => {handleReviseInfo(e)}}
            />
          </td>
        )
      }
      return colList      
    }
    for (let i =0; i < timesKey.length; i++) {
      tableList.push(<tr><th style= {{backgroundColor: 'rgb(237, 108, 2'}}>{timesKey[i]}</th>{handleCol(i)}</tr>)
    }
    return tableList
  }
  return (
    <>
      <ModalFormat
        show={showRevise}
        onHide={handleReviseClose}
        backdrop="static"
        keyboard={false}
        size= 'xl'
      >
        <Modal.Header>
          <Modal.Title>修改權重</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableForm>
            <thead>
              {handleName()}
            </thead>
            <tbody>
              {handleContent()}
            </tbody>
          </TableForm>
        </Modal.Body>
        <ModalBtn>
          <Button variant="info" onClick= {handleRevise}>確定</Button>
          <Button variant="secondary" onClick= {handleBack}>返回</Button>
        </ModalBtn>
      </ModalFormat>
    </>
  )
}