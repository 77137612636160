import React, { useState, useEffect }from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { PUInputBar } from "../../../views/operation/gameIdSetting/styledComponent"
import { ModalBtn, PUInput } from "../../../middleware/utilityStyle"
import { reviseGameList, getGameList, addGameList } from "../../../middleware/Api/operation/gameIdSettingApi"
export default function AddAndRevise(props) {
  const {
    showAddAndRevise,
    handleAddAndReviseClose,
    addStatus,
    popData,
    setDataCopy,
    setPanelInfo,
    setNameArr
  } = props
  const history = useHistory()
  const [form, setForm] = useState({
    GAME_ID: '',
    GAME_NAME: ''
  })
  const handleForm = (e, key) => {
    setForm(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }
  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!addStatus) {
        setForm(JSON.parse(JSON.stringify(popData)))
      }
      if (addStatus) {
        setForm({
          GAME_ID: '',
          GAME_NAME: ''
        })
      }
    })
  }, [popData, addStatus])

  const handleAddAndRevise = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      if (+popData.GAME_ID === +form.GAME_ID && popData.GAME_NAME === form.GAME_NAME) {
        handleAddAndReviseClose()
        setTimeout(() => {
          alert('未更動資料  ʕ◍•́ᴥ•̀◍ʔ')
        }, 200)
        return
      }

      if (!form.GAME_ID || !form.GAME_NAME) {
        alert('請輸入空白處⊙ w ⊙')
        return
      }

      if (isNaN(+form.GAME_ID)) {
        alert('遊戲ID請輸入數字  ʕﾉಠᴥಠʔﾉ')
        setForm(prev => ({
          ...prev,
          GAME_ID: ''
        }))
        return
      }
      if (addStatus) {
        addGameList(+form.GAME_ID, form.GAME_NAME).then(db => {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
        })
      }
      if (!addStatus) {
        reviseGameList(+popData.GAME_ID, +form.GAME_ID, form.GAME_NAME).then(db=> {
          if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
        })
      }
      getGameList().then(db => {
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        let nArr = []
        db.data.map(ele => nArr.push(ele.GAME_NAME))
        setNameArr(nArr)
        setPanelInfo(db.data)
        setDataCopy(db.data)
        handleAddAndReviseClose()
      })
    })
  }

  const handleBack = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleAddAndReviseClose()
      setTimeout(() => {
        if (addStatus) {
          setForm({
            GAME_ID: '',
            GAME_NAME: ''
          })
        }
        if (!addStatus) {
          setForm(JSON.parse(JSON.stringify(popData)))
        }
      }, 500)
    })
  }
  return (
    <>
      <Modal
        show={showAddAndRevise}
        onHide={handleAddAndReviseClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{addStatus ? '新增' : '修改'}遊戲設定</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PUInputBar>
            遊戲ID: <PUInput value= {form.GAME_ID} placeholder= "請輸入數字" onChange= {e => {handleForm(e, 'GAME_ID')}}/>
          </PUInputBar>
          <PUInputBar>
            遊戲名稱: <PUInput value= {form.GAME_NAME} placeholder= "請輸入遊戲名稱" onChange= {e => {handleForm(e, 'GAME_NAME')}}/>
          </PUInputBar>
        </Modal.Body>
        <ModalBtn>
          <Button variant="info" onClick= {handleAddAndRevise}>確定</Button>
          <Button variant="secondary" onClick= {handleBack}>返回</Button>
        </ModalBtn>
      </Modal>
    </>
  )
}