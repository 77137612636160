import { getAuthToken } from '../../utils.js'
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 取得玩家資訊 PID15 ?//
export const getUserInfo = (id, nickName) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/playerInfo?id=${id}&nickName=${nickName}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
  }).then(res =>  res.json())
  .catch(err => console.log('err: ', err))
}

//? 瀏覽玩家手機號碼 PID56 ?//
export const getPhone = id => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/playerInfo/playerPhone?id=${id}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}
//? 修改用戶類型 PID59 ?//
export const updateUserType = (uid, type) => {  
  const token = getAuthToken()
  return fetch(`${BaseUrl}/playerInfo/userType`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json' 
    },
    body: JSON.stringify({
      uid,
      type
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}