import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import gameTypeList from "../../../assets/config/gameCategories.json"
import hallType from "../../../assets/config/hallType.json"
import roomType from "../../../assets/config/gameType.json"
import { getAccessArr } from "../../../middleware/utils"

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker'
import { today, yesterday, thisWeek, lastWeek, thisMonth, defaultDate, defaultDateE } from "../../../middleware/dateUtils"
import AddRevisePU from "../../../components/popUpWindow/gemRatioConfig/AddRevisePU"
import DeletePU from "../../../components/popUpWindow/gemRatioConfig/DeletePU"
import { getGemRatio } from "../../../middleware/Api/backstageManagement/gemRatioConfigApi.js"

import {
  InputBarBg,
  NavLeft,
  IdInputBar,
  IdInput,
  RangeWrapper,
  DatePickerInput,
  RangeGroup,
  BtnGroup,
  BTN,
  InputBarRWD, 
  Group,
  Dropdown
} from "./styledComponent"
import {
  DateWrapper,
  DateTitle,
  ClickRange,
  SearchButton,
  ResetButton,
  BasicTable,
  BasicRowTitle,
  TableContent,
  PageStyle,
  TableBtnGroup,
  NewBtn,
  ReviseBtn,
  DeleteBtn
} from "../../../middleware/utilityStyle.js"

export default function GemRatioConfig() {
  const accessArr = getAccessArr()
  let userPID = []
  accessArr.map(ele => {
    userPID.push(ele.P_ID)
  })
  const history = useHistory()
  const [panelInfo, setPanelInfo] = useState([])
  const [change, setChange] = useState({sDate: '', eDate: '', gameType: 0, roomType: 0})
  const handleChange = (e, key) => {
    setChange(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }
  const [dateValue, setDateValue] = useState([null, null])
  const [popData, setPopData] = useState({
    GMGRC_ID: '',
    GAME_TYPE: 99,
    GAME_TYPE_IMG: '',
    HALL_TYPE: 99,
    HALL_TYPE_IMG: '',
    ROOM_TYPE: 99,
    ROOM_TYPE_IMG: '',
    MIN_RATIO: 1,
    MAX_RATIO: 1,
    BEGIN_DATE: null,
    END_DATE: null,
    INTERVAL_WEEK: '0,0,0,0,0,0,0',
    INTERVAL_BEGIN: null,
    INTERVAL_END: null,
    OPEN_STATE: 1
  })

  useEffect(() => {
  }, [panelInfo])

  const handleRadio = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPopData(panelInfo.find(ele => +ele.GMGRC_ID === +e.target.value))
    })
  }

  const [changePagePara, setChangePagePara] = useState({
    sDate: new Date(defaultDate),
    eDate: new Date(defaultDateE), 
    gameType: 99, 
    roomType: 99
  })

  const [pageCounter, setPageCounter] = useState(0)
  const [page, setPage] = useState(1)
  const handleChangePage = (event, newPage) => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPage(newPage)
      const {sDate, eDate, gameType, roomType} = changePagePara
      handleGetRadio(sDate, eDate, gameType, roomType, 1+(newPage-1)*10, newPage*10)
    })
  }

  function handleGetRadio(sD, eD, gameType, roomType, sR, eR) {
    getGemRatio(new Date(sD).toISOString(), new Date(eD).toISOString(), +gameType, +roomType, sR, eR).then(db => {
      if (db.error === errorCode.INCORRECT_DATA.code) {
        alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.INCORRECT_FORMAT.code) {
        alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
        return
      }
      if (db.error === errorCode.EXCEPTION.code) {
        alert(`※  ${errorCode.EXCEPTION.memo}`)
        return
      }
      if (db.error === errorCode.SERVER_ERROR.code) {
        alert(`※  ${errorCode.SERVER_ERROR.memo}`)
        return
      }
      if (db.error === errorCode.NULL_DATA.code) {
        alert(`※  ${errorCode.NULL_DATA.memo}`)
        setPanelInfo([])
        return
      }
      setChangePagePara({
        sDate: new Date(sD),
        eDate: new Date(eD), 
	    gameType: gameType, 
	    roomType: roomType
      })
      if(db.data) {
        if (db.data.length > 0) {
          setPageCounter(
            db.data[0].TOTAL_ROW_NUM%10 === 0
            ? Math.floor(db.data[0].TOTAL_ROW_NUM/10)
            : (Math.floor(db.data[0].TOTAL_ROW_NUM/10)) + 1
          )
        }
      }
      setPanelInfo(db.data)
      setPage(1)
    })
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }

      let gameType = change.gameType;
      let roomType = change.roomType;

      let sDate = ''
      let eDate = ''
      if  (dateValue[0] !== null || dateValue[1] !== null) {        
        sDate = new Date (dateValue[0]).toISOString()
        let endRDateStr = new Date (dateValue[1]).toString()
        eDate = new Date(endRDateStr.replace('00:00:00', '23:59:59')).toISOString()
      }
      if  (dateValue[0] === null && dateValue[1] === null) {
        sDate = defaultDate.toISOString()
        eDate= defaultDateE.toISOString()
      }
      handleGetRadio(sDate, eDate, gameType, roomType, 1, 10)
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({sDate: '', eDate: '', gameType: 0, RoomType: 0})
      setDateValue([null, null])
    })
  }

  const [addStatus, setAddStatus] = useState(false)
  const[showAddRevise, setAddReviseShow] = useState(false)
  const handleAddReviseClose = () => setAddReviseShow(false)
  const handleAddReviseShow = () => setAddReviseShow(true)

  const handleAdd = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 96)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      setAddStatus(true)
      handleAddReviseShow()
    })
  }

  const handleRevise = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!userPID.find(ele => +ele === 97)) {
        alert('※  無此權限ʕ๑•́ᴥ•̀๑ʔ')
        return
      }
      setPopData(panelInfo.find(ele => +ele.GMGRC_ID === +e.target.dataset.id))
      setAddStatus(false)
      handleAddReviseShow()
    })
  }

  function dateFormat(target) {
    let date = new Date(target)
    let result = date.getFullYear().toString() + "-" + (date.getMonth()+1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0") + " " + date.getHours().toString().padStart(2, "0") + ":" + date.getMinutes().toString().padStart(2, "0") + ":" + date.getSeconds().toString().padStart(2, "0")
    return result
  }

  function gameCategory() {
    let list = []
    for (let i = 0; i < gameTypeList.length; i++) {
      list.push(<option value= {gameTypeList[i].gameID}>{gameTypeList[i].gameName}</option>)
    }
    return list
  }

  function hallCategory() {
    let list = []
    for (let i = 0; i < hallType.length; i++) {
      list.push(<option value= {hallType[i].typeNum}>{hallType[i].typeName}</option>)
    }
    return list
  }

  const transStatus = new Map ([
    [0, '關閉'],
    [1, '開啟']
  ])
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <NavLeft>
          <RangeWrapper>
            <DateWrapper>
              <DateTitle>查詢時間</DateTitle>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                  <DesktopDateRangePicker
                    startText="創建時間"
                    endText="結束時間"
                    value={dateValue}
                    onChange={(newValue) => {
                      setDateValue(newValue)
                    }}
                    renderInput={(startProps, endProps) => {
                      startProps.inputProps.placeholder = '開始日期'
                      endProps.inputProps.placeholder= '結束日期'
                      return (
                        <React.Fragment>
                          <DatePickerInput ref={startProps.inputRef} {...startProps.inputProps} />
                          <Box sx={{ mx: 1 }}>至</Box>
                          <DatePickerInput ref={endProps.inputRef} {...endProps.inputProps} />
                        </React.Fragment>
                      )
                    }}
                  />
                </Stack>
              </LocalizationProvider>
            </DateWrapper>
            <RangeGroup>
              <ClickRange onClick={() => { setDateValue(today) }}>今天</ClickRange>
              <ClickRange onClick={() => { setDateValue(yesterday) }}>昨天</ClickRange>
              <ClickRange onClick={() => { setDateValue(thisWeek) }}>本周</ClickRange>
              <ClickRange onClick={() => { setDateValue(lastWeek) }}>上周</ClickRange>
              <ClickRange onClick={() => { setDateValue(thisMonth) }}>本月</ClickRange>
            </RangeGroup>
          </RangeWrapper>
        </NavLeft>
        <Group>
          <div>
            <span>遊戲</span>
            <Dropdown
              value= {change.gameType}
              onChange= {e => {
                handleChange(e, 'gameType')
              }}
            >
              <option value="0">全部</option>
              {gameCategory()}
            </Dropdown>
          </div>
        </Group>

        <Group>
          <div>
            <span>場次</span>
            <Dropdown
              value= {change.hallType}
              onChange= {e => {
                handleChange(e, 'hallType')
              }}
            >
              <option value="0">全部</option>
              {hallCategory()}
            </Dropdown>
          </div>
        </Group>
      
        <Group>
          <SearchButton onClick={handleSearch}>搜索</SearchButton>
          <ResetButton onClick={handleReset}>重置</ResetButton>
        </Group>
      </InputBarBg>
      
      <TableBtnGroup>
        <BasicTable className="table table-hover table-bordered">
          <thead>
            <BasicRowTitle>
              <th>ID</th>
              <th>遊戲</th>
              <th>廳別（若有）</th>
              <th>場次</th>
              <th>最小活動係數</th>
              <th>最大活動係數</th>
              <th>變更起始時間</th>
              <th>變更結束時間</th>
              <th>開放狀態</th>
              <th>修改</th>
            </BasicRowTitle>
          </thead>
          <tbody>
            {
              Array.isArray(panelInfo) && panelInfo.length > 0 ?
              panelInfo.map((ele, index) => {
                return (
                  <TableContent key= {index}>
                    <td>{ele.GMGRC_ID}</td>
                    <td>{gameTypeList.find(obj=> +obj.gameID === +ele.GAME_TYPE) ? gameTypeList.find(obj=> +obj.gameID === +ele.GAME_TYPE).gameName : '全部'}</td>
                    <td>{hallType.find(obj=> +obj.typeNum === +ele.HALL_TYPE) ? hallType.find(obj=> +obj.typeNum === +ele.HALL_TYPE).typeName : '全部'} </td>
                    <td>{roomType.find(obj=> +obj.typeNum === +ele.ROOM_TYPE) ? roomType.find(obj=> +obj.typeNum === +ele.ROOM_TYPE).typeName : '全部'}</td>
                    <td>{ele.MIN_RATIO}</td>
                    <td>{ele.MAX_RATIO}</td>
                    <td>{dateFormat(ele.BEGIN_DATE)}</td>
                    <td>{dateFormat(ele.END_DATE)}</td>
                    <td>{transStatus.get(+ele.OPEN_STATE)}</td>
                    <td><ReviseBtn data-id = {ele.GMGRC_ID} onClick= {handleRevise}>修改</ReviseBtn></td>
                  </TableContent>
                )
              })
              : <tr key= "noInfo"><td colSpan= "10">尚無資料</td></tr>
            }
          </tbody>
        </BasicTable>        
        <BTN>
          <NewBtn onClick= {handleAdd}>新增</NewBtn>
        </BTN>
      </TableBtnGroup>

      <AddRevisePU
        showAddRevise= {showAddRevise}
        handleAddReviseShow= {handleAddReviseShow}
        handleAddReviseClose= {handleAddReviseClose}
        addStatus= {addStatus}
        popData= {popData}
        gameTypeList  = {gameTypeList}
	      hallTypeList = {hallType}
	      roomTypeList = {roomType}
        setPanelInfo= {setPanelInfo}
        setPopData= {setPopData}
      />

      {
        Array.isArray([panelInfo]) && panelInfo.length ?
          <Stack spacing={2}>
            <PageStyle count={pageCounter}
              color="warning"
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </Stack>
        : ''
      }
    </>
  )
}
