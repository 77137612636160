import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { today, yesterday, thisWeek, lastWeek, thisMonth, defaultDate, defaultDateE, TimezoneTranslator } from "../../../middleware/dateUtils"
import Details from "../../../components/popUpWindow/mailSearching/details"
import { SearchMail } from "../../../middleware/Api/playerInfo/mailSearchingApi"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import {
  InputBarBg,
  InputBarRWD,
  RangeWrapper,
  DatePickerInput,
  RangeGroup,
  InputBar,
  Input,
  BtnGroup
} from "./styledComponent.js"
import "./mailSearching.css"
import {
  DateWrapper,
  DateTitle,
  DateStack,
  ClickRange,
  SearchButton,
  ResetButton,  
  BasicTable,
  BasicRowTitle,
  TableContent,
  PopDetail,
  PageStyle
} from "../../../middleware/utilityStyle.js"

const itemName = new Map([
  [1, '金幣'],
  [2, '鬥陣寶']
])
const transItem = new Map(itemName)

export default function MailSearching() {
  const [panelInfo, setPanelInfo] = useState([]);  //Data State
  const [dataCopy, setDataCopy] = useState([])
  const [change, setChange] = useState({sender: ''})
  const history = useHistory()
  const [dateValue, setDateValue] = useState([new Date(defaultDate).toISOString(), new Date(defaultDateE).toISOString()])
  const [pageCounter, setPageCounter] = useState(0)  //最大頁數
  const [page, setPage] = useState(1)
  const [targetObj, setTargetObj] = useState(
    {
      TO_ALL: '',
      SEND_DATE: '',
      TARGET_UID: '',
      TITLE: '',
      MESSAGE: '',
      ITEM1_ID: '',
      ITEM1_NUM: '',
      ITEM2_ID: '',
      ITEM2_NUM: '',
      ITEM3_ID: '',
      ITEM3_NUM: '',
      ITEM4_ID: '',
      ITEM4_NUM: ''
    }
  )

  const[changePagePara, setChangePagePara] = useState({
    userName: 'all',
    sDate: new Date(defaultDate).toISOString(),
    eDate: new Date(defaultDateE).toISOString()
  })

  const [showDetailsPU, setDetailsPUShow] = useState(false)
  const handleDetailsPUClose = () => setDetailsPUShow(false)
  const handleDetailsPUShow = () => setDetailsPUShow(true)
  const handleChange = e => {
    let newObj = Object.assign({}, change) //複製一份新的states
    newObj[e.target.name] = e.target.value
    setChange(newObj)
  }

  const handleChangePage = (event, newPage) => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPage(newPage)
      SearchMail(changePagePara.userName, changePagePara.sDate, changePagePara.eDate, 1+(newPage-1)*30, newPage*30).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setPanelInfo(db.data)
        setDataCopy(db.data)
      })
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({sender: ''})
      setDateValue([new Date(defaultDate).toISOString(), new Date(defaultDateE).toISOString()])
    })
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      let sDate = ''
      let eDate = ''
      if (dateValue[0] !== null && dateValue[1] !== null) {
        sDate = new Date(dateValue[0]).toISOString()
        let endRDateStr = new Date(dateValue[1]).toString()
        eDate = new Date(endRDateStr.replace('00:00:00', '23:59:59')).toISOString()
        setChangePagePara(prev => ({
          ...prev,
          sDate,
          eDate
        }))
      }
      if (dateValue[0] === null && dateValue[1] === null) {
        sDate = new Date(defaultDate).toISOString()
        eDate = new Date(defaultDateE).toISOString()
        setChangePagePara(prev => ({
          ...prev,
          sDate,
          eDate
        }))
      }

      let userName = 'all'
      if (change.sender) {
        userName = change.sender
        setChangePagePara(prev => ({
          ...prev,
          userName: change.sender
        }))
      }

      SearchMail(userName, sDate, eDate, 1, 30).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.NULL_DATA.code) {
          alert(`※  ${errorCode.NULL_DATA.memo}`)
          setChange({sender: ''})
          setDateValue([new Date(defaultDate).toISOString(), new Date(defaultDateE).toISOString()])
          SearchMail('all', new Date(defaultDate).toISOString(), new Date(defaultDateE).toISOString(), 1, 30).then(db => {
            if (db.error === errorCode.INCORRECT_DATA.code) {
              alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.INCORRECT_FORMAT.code) {
              alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
              return
            }
            if (db.error === errorCode.NULL_DATA.code) {
              alert(`※  預設日期${errorCode.NULL_DATA.memo}`)
              setPanelInfo([])
              return
            }
            if (db.error === errorCode.EXCEPTION.code) {
              alert(`※  ${errorCode.EXCEPTION.memo}`)
              return
            }
            setDataCopy(db.data)
            setPanelInfo(db.data)
            setPage(1)
            if(db.data) {
              if (db.data.length > 0) {
                setPageCounter(
                  db.data[0].TOTAL_ROW_NUM%30 === 0
                  ? Math.floor(db.data[0].TOTAL_ROW_NUM/30)
                  : (Math.floor(db.data[0].TOTAL_ROW_NUM/30)) + 1
                )
              }
            }
          })
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setDataCopy(db.data)
        setPanelInfo(db.data)
        setPage(1)
        if(db.data) {
          if (db.data.length > 0) {
            setPageCounter(
              db.data[0].TOTAL_ROW_NUM%30 === 0
              ? Math.floor(db.data[0].TOTAL_ROW_NUM/30)
              : (Math.floor(db.data[0].TOTAL_ROW_NUM/30)) + 1
            )
          }
        }
      })
    })
  }

  const handleDetail = e => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      let checkTarget = dataCopy.find(ele => +ele.ID === +e.target.dataset.id)
      setTargetObj(checkTarget)
      handleDetailsPUShow()
    })
  }

  function itemControl(item1, num1, item2, num2, item3, num3, item4, num4) {
    let itemList = [
      {name: item1, num: num1},
      {name: item2, num: num2},
      {name: item3, num: num3},
      {name: item4, num: num4}
    ]
    let itemArr = itemList.filter(obj => obj.name)
    if (itemArr.length < 1) {
      return '---'
    } else {
      let result = itemArr.map(obj => {
        if(obj.name && obj.num) {
          if (!transItem.get(obj.name)) {
            return(`道具ID：${obj.name} ${obj.num}個 `)
          }
          return(`${transItem.get(obj.name)} ${obj.num}個 `)
        }
      }).toString()
      return result
    } 
  }
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <InputBarRWD>
          <RangeWrapper>
            <DateWrapper>
              <DateTitle>發送日期</DateTitle>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateStack spacing={3}>
                  <DesktopDateRangePicker
                    startText=""
                    endText=""
                    value={dateValue}
                    onChange={(newValue) => {
                      setDateValue(newValue)
                    }}
                    renderInput={(startProps, endProps) => {
                      startProps.inputProps.placeholder = '開始日期'
                      endProps.inputProps.placeholder = '結束日期'
                      return (
                        <>
                          <DatePickerInput ref={startProps.inputRef} {...startProps.inputProps} />
                          <Box sx={{ mx: 1 }}>至</Box>
                          <DatePickerInput ref={endProps.inputRef} {...endProps.inputProps} />
                        </>
                      )
                    }}
                  />
                </DateStack>
              </LocalizationProvider>
            </DateWrapper>
            <RangeGroup>
              <ClickRange onClick={() => { setDateValue(today) }}>今天</ClickRange>
              <ClickRange onClick={() => { setDateValue(yesterday) }}>昨天</ClickRange>
              <ClickRange onClick={() => { setDateValue(thisWeek) }}>本周</ClickRange>
              <ClickRange onClick={() => { setDateValue(lastWeek) }}>上周</ClickRange>
              <ClickRange onClick={() => { setDateValue(thisMonth) }}>本月</ClickRange>
            </RangeGroup>
          </RangeWrapper>
          <InputBar>
            派送人<Input placeholder="請輸入派送的人員名稱" name= "sender" value= {change.sender} onChange= {handleChange}/>
          </InputBar>
        </InputBarRWD>
        <BtnGroup>
          <SearchButton onClick= {handleSearch}>搜索</SearchButton>
          <ResetButton onClick= {handleReset}>重置</ResetButton>
        </BtnGroup>
      </InputBarBg>

      <BasicTable className="table table-hover table-bordered">
        <thead>
          <BasicRowTitle>
            <th scope="col">派送類型</th>
            <th scope="col">派送時間</th>
            <th scope="col">派送對象</th>
            <th scope="col">信件標題</th>
            <th scope="col">內文標題</th>
            <th scope="col">派送道具</th>
            <th scope="col">派送人</th>
            <th scope="col">檢閱</th>
          </BasicRowTitle>
        </thead>
        <tbody>
          {
            Array.isArray(panelInfo) && panelInfo.length ?
              panelInfo.map((EachData, index) => {
                return (
                  <TableContent key={index}>
                    <td>{+EachData.SEND_TYPE ? '即時' : '預約'}</td>
                    <td>{TimezoneTranslator(EachData.SEND_DATE)}</td>
                    <td>{EachData.TO_ALL ? '全服派送' : '指定對象'}</td>
                    <td>{EachData.TITLE}</td>
                    <td>
                      <textarea id= "TArea"value= {EachData.MESSAGE} rows="5" disabled/>
                      </td>
                    <td>
                      {itemControl(EachData.ITEM1_ID, EachData.ITEM1_NUM, EachData.ITEM2_ID, EachData.ITEM2_NUM, EachData.ITEM3_ID, EachData.ITEM3_NUM, EachData.ITEM4_ID, EachData.ITEM4_NUM)}
                    </td>
                    <td>{EachData.OPERATOR}</td>
                    <PopDetail onClick= {handleDetail} data-id= {EachData.ID}>查看</PopDetail>
                  </TableContent>
                )
              })
              : <tr key= "noInfo"><td colSpan= "8">尚無資料</td></tr>
          }
        </tbody>
        <tfoot></tfoot>
      </BasicTable>

      {
        Array.isArray(panelInfo) && panelInfo.length ?
          <Stack spacing={2}>
            <PageStyle count={pageCounter}
              color="warning"
              page= {page}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </Stack>
        : ''
      }

      <Details
        showDetailsPU= {showDetailsPU}
        handleDetailsPUClose= {handleDetailsPUClose}
        targetObj= {targetObj}
      />
    </>
  )
}