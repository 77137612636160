import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import AddRevise from "../../../components/popUpWindow/parameterSetting/AddRevise"
import DeletePU from "../../../components/popUpWindow/parameterSetting/DeletePU"
import {
  InputBarBg,
  InputBarRWD,
  InputBar,
  Input,
  BTN
} from "./styledComponent"
import {
  SearchButton,
  ResetButton,
  BasicTable,
  BasicRowTitle,
  TableContent,
  TableBtnGroup,
  NewBtn,
  ReviseBtn,
  DeleteBtn
} from "../../../middleware/utilityStyle.js"

export default function ParameterSetting() {
  let list = []
  const history = useHistory()
  const [panelInfo, setPanelInfo] = useState([])
  const [change, setChange] = useState({
    paraKey: '',
    paraDescription: ''
  })
  const handleChange = (e, key) => {
    setChange(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const [popData, setPopData] = useState({
    paraId: '',
    paraKey: '',
    paraValue: '',
    paraContent: ''
  })
  const handleRadio = e => {
    setPopData(list.find(ele => +ele.paraId === +e.target.value))
  }

  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setPanelInfo(list)
      setChange({
        paraKey: '',
        paraDescription: ''
      })
    })
  }

  const handleReset = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setChange({
        paraKey: '',
        paraDescription: ''
      })
    })
  }

  const [addStatus, setAddStatus] = useState(false)
  const [showAddRevise, setAddReviseShow] = useState(false)
  const handleAddReviseClose = () => setAddReviseShow(false)
  const handleAddReviseShow = () => setAddReviseShow(true)

  const handleAdd = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      setAddStatus(true)
      handleAddReviseShow()
    })
  }

  const handleRevise = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!popData.paraKey) {
        alert('※  請選擇欲修改的項目')
        return
      }
      setAddStatus(false)
      handleAddReviseShow()
    })
  }

  const [showDeletePU, setDeletePUShow] = useState(false)
  const handleDeletePUClose = () => setDeletePUShow(false)
  const handleDeletePUShow = () => setDeletePUShow(true)
  const handleDelete = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      
      if (!popData.paraKey) {
        alert('※  請選擇欲刪除的項目')
        return
      }
      handleDeletePUShow()
    })
  }
  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light">
        <InputBarRWD>
          <div>
            參數值<Input placeholder="請輸入參數key值" value={change.paraKey} onChange={e => {handleChange(e, 'paraKey')}} />
          </div>
          <InputBar>
            參數描述<Input placeholder="請輸入參數描述" value={change.paraDescription} onChange={e => {handleChange(e, 'paraDescription')}} />
          </InputBar>
        </InputBarRWD>
        <div>
          <SearchButton onClick={handleSearch}>搜索</SearchButton>
          <ResetButton onClick={handleReset}>重置</ResetButton>
        </div>
      </InputBarBg>
      <TableBtnGroup>
        <BasicTable className="table table-hover table-bordered">
          <thead>
            <BasicRowTitle>
              <th>
                <input type= "radio" disabled/>
              </th>
              <th>參數 key</th>
              <th>參數 value</th>
              <th>參數內容詳情</th>
            </BasicRowTitle>
          </thead>
          <tbody>
            {
              Array.isArray(panelInfo) && panelInfo.length > 0 ?
              panelInfo.map((ele, index) => {
                return (
                  <TableContent key= {index}>
                    <td>
                      <input type= "radio" name= "paraList" value= {ele.paraId} onChange= {handleRadio}/>
                    </td>
                    <td>{ele.paraKey}</td>
                    <td>{ele.paraValue}</td>
                    <td>{ele.paraContent}</td>
                  </TableContent>
                )
              })
              : <tr key= "noInfo"><td colSpan= "4">尚無資料</td></tr>
            }
          </tbody>
        </BasicTable>

        <BTN>
          <NewBtn onClick= {handleAdd}>新增</NewBtn>
          <ReviseBtn onClick= {handleRevise}>修改</ReviseBtn>
          <DeleteBtn onClick= {handleDelete}>刪除</DeleteBtn>
        </BTN>
      </TableBtnGroup>
        

      <AddRevise
        showAddRevise= {showAddRevise}
        handleAddReviseShow= {handleAddReviseShow}
        handleAddReviseClose= {handleAddReviseClose}
        addStatus= {addStatus}
        popData= {popData}
      />

      <DeletePU
        showDeletePU= {showDeletePU}
        handleDeletePUClose= {handleDeletePUClose}
      />
    </>
  )
}