import styled from 'styled-components'
import { BarBG, BasicInput } from "../../../middleware/utilityStyle"

export const InputBarBg = styled(BarBG)`
  @media screen and (max-width: 1650px){
    flex-direction: column;
  }
`

export const NavLeft = styled.div`
  display: flex;
  @media screen and (max-width: 1530px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
`

export const IdInputBar = styled.div`
  display: flex;
  @media screen and (max-width: 1530px) {
    justify-content: center;
  }
`

export const IdInput = styled(BasicInput)`
  margin-left: 20px;
  width: 250px;
  @media screen and (max-width: 715px) {
    width: 125px;
  }
`

export const BtnGroup = styled.div`
  @media screen and (max-width: 1650px) {
    margin-top: 10px;
  }
`

export const AddBtn = styled.button`
  color: #00cc00;
  border: 1px solid #00cc00;
  border-radius: 5px;
  transition: 0.7s;
  background: white;
  height: 2.5rem;
  width: 8rem;
  margin-top: 20px;

  :hover {
    color: white;
    font-weight: bold;
    background: #00cc00;
  }
`

export const PULeftTitle = styled.th`
  text-align: center;
`

export const PUSelect = styled.select`
  border: 1px solid black;
  border-radius: 5px;
  height: 28px;
  width: 240px;
`

export const Dropdown = styled.select`
  margin-left: 20px;
  border: 1px solid black;
  border-radius: 5px;
  height: 1.7rem;
  width: 200px;
`

export const SecondCell = styled.div`
  margin-left: 20px;
  @media screen and (max-width: 1530px) {
    margin-top: 10px;
    margin-left: 0px;
  }
`