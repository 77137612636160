import { getAuthToken } from '../../utils.js'
import { BaseUrl, UploadMaterial, UploadUrl } from "../../../assets/config/urlForBuild"

//? 取得鬥陣寶係數設定 PID95 ?//
export const getGemRatio = (sDate, eDate, gameType, roomType, sRow, eRow) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/gemRatio?gameType=${gameType}&roomType=${roomType}&sDate=${sDate}&eDate=${eDate}&sRow=${sRow}&eRow=${eRow}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 新增鬥陣寶係數設定 PID96 ?//
export const addGemRatio = (gameType, gameURL, hallType, hallURL, roomType, roomURL, minRatio, maxRatio, sDate, eDate, weekDate, sTime, eTime, state) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/gemRatio`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      gameType,
      gameURL,
      hallType,
      hallURL,
      roomType,
      roomURL,
      minRatio,
      maxRatio,
      sDate,
      eDate,
      weekDate,
      sTime, 
      eTime, 
      state
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 更改鬥陣寶係數設定 PID97 ?//
export const reviseGemRatio = (gmgrcId, gameType, gameURL, hallType, hallURL, roomType, roomURL, minRatio, maxRatio, sDate, eDate, weekDate, sTime, eTime, state) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/gemRatio`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      gmgrcId,
      gameType,
      gameURL,
      hallType,
      hallURL,
      roomType,
      roomURL,
      minRatio,
      maxRatio,
      sDate,
      eDate,
      weekDate,
      sTime, 
      eTime, 
      state
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 刪除鬥陣寶係數設定 PID98 ?//
export const deleteGemRatio = gmgrcId => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/gemRatio/delete`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      gmgrcId
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

export const uploadItem = formData => {
  return fetch(UploadMaterial, {
    method: 'POST',
    body: formData,
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}