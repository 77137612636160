import { getAuthToken } from '../../utils.js'
import { BaseUrl, UploadUrl } from "../../../assets/config/urlForBuild"

//? 取得商品分類列表 PID31 ?//
export const itemCategory = () => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/exchange/list`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 新增商品分類 PID32 ?//
export const addCategory = (orderID, className, classState) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/exchange/list`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      orderID,
      className,
      classState
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 修改商品分類 PID33 ?//
export const reviseCategory = (orderID, className, classState, classID) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/exchange/list`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      orderID,
      className,
      classState,
      classID
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 刪除商品分類 PID34 ?//
export const deleteCategory= (classID, className) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/exchange/deleteList`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      classID,
      className
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 取得商品清單 PID35 ?//
export const detailList = classID => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/exchange?classID=${classID}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

export const uploadItem = formData => {
  return fetch(UploadUrl, {
    method: 'POST',
    body: formData,
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 新增兌換商品 PID36 ?//
export const addDetailList = (orderID, itemImg, itemName, currency, price,itemTag, sDate, eDate, dailyLimit, totalLimit, detail, openStatus, className, isPermanent) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/exchange`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      orderID,
      itemImg,
      itemName,
      currency,
      price,
      itemTag,
      sDate,
      eDate,
      dailyLimit,
      totalLimit,
      detail,
      openStatus,
      className,
      isPermanent
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 修改兌換商品 PID37?//
export const reviseDetailList = (orderID, itemImg, itemName, currency, price,itemTag, sDate, eDate, dailyLimit, totalLimit, detail, openStatus, className, itemID, isPermanent) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/exchange`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      orderID,
      itemImg,
      itemName,
      currency,
      price,
      itemTag,
      sDate,
      eDate,
      dailyLimit,
      totalLimit,
      detail,
      openStatus,
      className,
      itemID,
      isPermanent
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}

//? 刪除兌換商品 PID38 ?//
export const deleteDetailList = (itemID, itemName) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/exchange/delete`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      itemID,
      itemName
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}