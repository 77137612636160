import React, { useState, useEffect }from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import Box from '@mui/material/Box'
import {
  PULeftTitle,  
  DateTimeWrapper,
  PUSelect,
  PUSelectOwn,
  TimeFormat, 
  PopFrame,
  Avatar,
  NoImg,
  LabelBtn,
  PUInputOwn
} from "../../../views/operation/itemConfig/styledComponent"
import { ModalFormat, PUInput, ModalBtn } from "../../../middleware/utilityStyle"
import { addItemConfig, reviseItemConfig, uploadItem } from "middleware/Api/operation/itemConfigApi.js"

export default function AddPU(props) {
  const {
    showAddRevise,
    handleAddReviseClose,
    addStatus,
    popData,
    itemMainType,
    setPanelInfo,
    setPopData
  } = props
  const history = useHistory()
  const [form, setForm] = useState({
    ITEM_ID: '',
    ITEM_NAME: '',
    MAIN_IMG: '',
    MAIN_TYPE: 1,
    SUB_TYPE: 1001,
    DETAIL_TYPE: 1001001,
    PREVIEW_IMG: '',
    ICON_IMG: '',
    TEXT: '',
    DETAIL_CONFIG: '',
    STATUS: 0
  })

  const handleForm = (e, key) => {
    setForm(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const handleMainTypeChange = (e, key) => {
    setForm(prev => ({
      ...prev,
      MAIN_TYPE: e.target.value, 
      SUB_TYPE: e.target.value * 1000 + 1,
      DETAIL_TYPE: e.target.value * 1000000 + 1001
    }))
  }

  const handleSubTypeChange = (e, key) => {
    setForm(prev => ({
      ...prev,
      SUB_TYPE: e.target.value,
      DETAIL_TYPE: e.target.value * 1000 + 1
    }))
  }

  const [optionChange, setOptionChange] = useState({
    itemType: 1
  })

  const [configGroup, setConfigGroup] = useState({
    effectTimeHour: 0,
    effectTimeMin: 0,
    expiryStartDate: null,
    expiryStartTime: null,
    expiryEndDate: null,
    expiryEndTime: null,
    ammountLimit: 0, 
    ifCanSale: 0, 
    currency: "gold", 
    price: 0
  })

  const [effectSettingGroup, setEffectSettingGroup] = useState({
    targetType: "gold",
    changeType: "plus",
    itemId: null,
    getNum: null,
    multi: 1, 
    usedAtOnce: 1
  })

  const [mergeSettingGroup, setMergeSettingGroup] = useState({
    ifCanMerge: 1,
    cost: null,
    convertTarget: "gold",
    itemId: null,
    convertNum: null
  })

  const [exchangeSettingGroup, setExchangeSettingGroup] = useState({
    personalInfo: 0
  })

  const handleOptionChange = (e, key) => {
    setOptionChange(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const handleConfigGroup = (e, key) => {
    setConfigGroup(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const handleEffectSettingGroup = (e, key) => {
    setEffectSettingGroup(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const handleMergeSettingGroup = (e, key) => {
    setMergeSettingGroup(prev => ({
      ...prev,
      [key]: e.target.value
    }))
  }

  const handleExchangeSettingGroup = (e, key) => {
    setExchangeSettingGroup(prev => ({
      ...prev,
      [key]: e
    }))
  }

  const [mainImgData, setMainImgData] = useState()
  const [mainFileData, setMainFileData] = useState(false)
  const handleUploadMainImg = e => {
    e.preventDefault()
    const file = e.target.files[0]
    if(file) {
      const formData = new FormData()
      formData.append('file', file)
      setMainImgData(formData)
      setMainFileData(true)
      const imgReader = new FileReader()
      imgReader.readAsDataURL(file)
      imgReader.onload = e => {
        setForm(prev => ({
          ...prev,
          MAIN_IMG: e.target.result
        }))
      }
    }
  }
  const [previewImgData, setPreviewImgData] = useState()
  const [previewFileData, setPreviewFileData] = useState(false)
  const handleUploadPreviewImg = e => {
    e.preventDefault()
    const file = e.target.files[0]
    if(file) {
      const formData = new FormData()
      formData.append('file', file)
      setPreviewImgData(formData)
      setPreviewFileData(true)
      const imgReader = new FileReader()
      imgReader.readAsDataURL(file)
      imgReader.onload = e => {
        setForm(prev => ({
          ...prev,
          PREVIEW_IMG: e.target.result
        }))
      }
    }
  }
  const [iconImgData, setIconImgData] = useState()
  const [iconFileData, setIconFileData] = useState(false)
  const handleUploadIconImg = e => {
    e.preventDefault()
    const file = e.target.files[0]
    if(file) {
      const formData = new FormData()
      formData.append('file', file)
      setIconImgData(formData)
      setIconFileData(true)
      const imgReader = new FileReader()
      imgReader.readAsDataURL(file)
      imgReader.onload = e => {
        setForm(prev => ({
          ...prev,
          ICON_IMG: e.target.result
        }))
      }
    }
  }

  const cleanData = () => {
    setForm({
      ITEM_ID: '',
      ITEM_NAME: '',
      MAIN_IMG: '',
      MAIN_TYPE: 1,
      SUB_TYPE: 1001,
      DETAIL_TYPE: 1001001,
      PREVIEW_IMG: '',
      ICON_IMG: '',
      TEXT: '',
      DETAIL_CONFIG: '',
      STATUS: 0
    })
    setConfigGroup({
      effectTimeHour: null,
      effectTimeMin: null,
      expiryStartDate: null,
      expiryStartTime: null,
      expiryEndDate: null,
      expiryEndTime: null,
      ammountLimit: 0, 
      ifCanSale: 0, 
      currency: "gold", 
      price: 0
    })

    setEffectSettingGroup({
      targetType: "gold",
      changeType: "plus",
      itemId: null,
      getNum: null,
      multi: 1, 
      usedAtOnce: 1
    })
    setMergeSettingGroup({
      ifCanMerge: 1,
      cost: null,
      convertTarget: "gold",
      itemId: null,
      convertNum: null
    })
    setExchangeSettingGroup({
      personalInfo: 0
    })

    setOptionChange({
      itemType: 1
    })

    setMainFileData(false)
    setPreviewFileData(false)
    setIconFileData(false)
  }
  
  useEffect(() => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      
      if(!addStatus) {
        cleanData()
        setForm(JSON.parse(JSON.stringify(popData)))
        let configJSON = JSON.parse(popData.DETAIL_CONFIG ? popData.DETAIL_CONFIG : "{}")
        setConfigGroup({
          effectTimeHour: configJSON.effectTime ? parseInt(configJSON.effectTime / 60) : 0,
          effectTimeMin: configJSON.effectTime ? (configJSON.effectTime % 60) : 0,
          expiryStartDate: configJSON.expiryStart ? configJSON.expiryStart : null,
          expiryStartTime: configJSON.expiryStart ? configJSON.expiryStart : null,
          expiryEndDate: configJSON.expiryEnd ? configJSON.expiryEnd : null,
          expiryEndTime: configJSON.expiryEnd ? configJSON.expiryEnd : null,
          ammountLimit: configJSON.ammountLimit ? configJSON.ammountLimit : 0,
          ifCanSale: configJSON.ifCanSale ? configJSON.ifCanSale : 0,
          currency: configJSON.currency ? configJSON.currency : "gold",
          price: configJSON.price ? configJSON.price : 0
        })
        let effectSetting = configJSON.effectSetting;
        setEffectSettingGroup({
          targetType: effectSetting ? effectSetting.targetType : "gold",
          changeType: effectSetting ? effectSetting.changeType : "plus",
          itemId: (effectSetting ? effectSetting.targetType : "gold") == "missionReset" ? 1 : (effectSetting ? effectSetting.itemId : null),
          getNum: effectSetting ? effectSetting.getNum : null,
          multi: effectSetting ? effectSetting.multi : 1, 
          usedAtOnce: effectSetting ? effectSetting.usedAtOnce : 1
        })
        let mergeSetting = configJSON.mergeSetting;
        setMergeSettingGroup({
          ifCanMerge: mergeSetting ? +mergeSetting.ifCanMerge : 1,
          cost: mergeSetting ? mergeSetting.cost : null,
          convertTarget: mergeSetting ? mergeSetting.convertTarget : "gold",
          itemId: mergeSetting ? mergeSetting.itemId : null,
          convertNum: mergeSetting ? mergeSetting.convertNum : null
        })
        let exchangeSetting = configJSON.exchangeSetting;
        setExchangeSettingGroup({
          personalInfo: exchangeSetting ? +exchangeSetting.personalInfo : 0
        })
      } else {
        cleanData()
      }
    })
  }, [popData, addStatus])

  async function handelFileUpload() {
    let mainTargetUrl = form.MAIN_IMG
    if (mainFileData) {
      await uploadItem(mainImgData).then(async db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setForm(prev => ({
          ...prev,
          MAIN_IMG: db.data.url
        }))
        mainTargetUrl = db.data.url
      })
    }
    let previewTargetUrl = form.PREVIEW_IMG
    if (previewFileData) {
      await uploadItem(previewImgData).then(async db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setForm(prev => ({
          ...prev,
          PREVIEW_IMG: db.data.url
        }))
        
        previewTargetUrl = db.data.url
      })
    }
    let iconTargetUrl = form.ICON_IMG
    if (iconFileData) {
      await uploadItem(iconImgData).then(async db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
          alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.INCORRECT_FORMAT.code) {
          alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
          return
        }
        if (db.error === errorCode.EXCEPTION.code) {
          alert(`※  ${errorCode.EXCEPTION.memo}`)
          return
        }
        setForm(prev => ({
          ...prev,
          ICON_IMG: db.data.url
        }))
        
        iconTargetUrl = db.data.url
      })
    }
    return {
      'mainTargetUrl': mainTargetUrl, 
      'previewTargetUrl': previewTargetUrl, 
      'iconTargetUrl': iconTargetUrl
    }
  } 

  async function handleSubmit () {
    sessionVerified().then(async db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      if (!+form.ITEM_ID) {
        alert('※  請輸入道具ID')
        return
      }
      if (!form.ITEM_NAME || form.ITEM_NAME.replace(" ", "") == "") {
        alert('※  請輸入道具名稱')
        return
      }

      if (form.MAIN_TYPE == 1) {
        if (!configGroup.ammountLimit) {
          alert('※  請輸入道具持有上限')
          return
        }
        if (configGroup.ifCanSale && !+configGroup.price) {
          alert('※  請輸入道具販售價格')
          return
        } 
        if (!effectSettingGroup.getNum) {
          alert('※  請輸入使用後的異動數量')
          return
        }       
      }

      if (form.MAIN_TYPE == 2) {
        if (!configGroup.ammountLimit) {
          alert('※  請輸入道具持有上限')
          return
        }
        if (configGroup.ifCanSale && !+configGroup.price) {
          alert('※  請輸入道具販售價格')
          return
        } 
        if (effectSettingGroup.ifCanMerge && !effectSettingGroup.cost) {
          alert('※  請輸入合成消耗數量')
          return
        }
        if (effectSettingGroup.ifCanMerge && +effectSettingGroup.cost < 1) {
          alert('※  合成消耗數量不可小於 1')
          return
        }
        if (effectSettingGroup.ifCanMerge && !effectSettingGroup.convertNum) {
          alert('※  請輸入合成後的獲得數量')
          return
        }  
      }

      if (form.MAIN_TYPE == 4) {
        if (!configGroup.ammountLimit) {
          alert('※  請輸入道具持有上限')
          return
        }
        if (configGroup.ifCanSale && !+configGroup.price) {
          alert('※  請輸入道具販售價格')
          return
        }
      }

      let getResult = await handelFileUpload();

      let saveStartTime = configGroup.expiryStartDate ? new Date(
        new Date(configGroup.expiryStartDate).getFullYear(),
        new Date(configGroup.expiryStartDate).getMonth(),
        new Date(configGroup.expiryStartDate).getDate(),
        new Date(configGroup.expiryStartTime).getHours(),
        new Date(configGroup.expiryStartTime).getMinutes()
        ) : new Date(1970, 0, 1, 0, 0, 0)
  
      let saveEndTime = configGroup.expiryEndDate ? new Date(
        new Date(configGroup.expiryEndDate).getFullYear(),
        new Date(configGroup.expiryEndDate).getMonth(),
        new Date(configGroup.expiryEndDate).getDate(),
        new Date(configGroup.expiryEndTime).getHours(),
        new Date(configGroup.expiryEndTime).getMinutes()
      ) : new Date(2100, 0, 1, 0, 0, 0)
      /*
      if (saveStartTime > saveEndTime) {
        alert('※  結束時間不可早於開始時間')
        return
      }*/

      let detailConfig = JSON.stringify({
        "effectTime": configGroup.effectTimeHour * 60 + configGroup.effectTimeMin, 
        "expiryStart": saveStartTime, 
        "expiryEnd": saveEndTime, 
        "ammountLimit": configGroup.ammountLimit, 
        "ifCanSale": +configGroup.ifCanSale, 
        "currency": configGroup.currency, 
        "price": +configGroup.price, 
        "effectSetting" : {
          "targetType": effectSettingGroup.targetType,	
          "changeType": effectSettingGroup.changeType, 
          "itemId": effectSettingGroup.itemId, 
          "getNum": +effectSettingGroup.getNum, 
          "multi": +effectSettingGroup.multi,
          "usedAtOnce": +effectSettingGroup.usedAtOnce
        }, 
        "mergeSetting" : {
          "ifCanMerge": +mergeSettingGroup.ifCanMerge, 
          "cost": +mergeSettingGroup.cost, 			
          "convertTarget": mergeSettingGroup.convertTarget, 	
          "itemId": mergeSettingGroup.itemId, 		
          "convertNum": +mergeSettingGroup.convertNum
        },
        "exchangeSetting" : {
          "personalInfo": +exchangeSettingGroup.personalInfo
        }
      })
      
      if (!addStatus) {
        reviseItemConfig(
          +form.ITEM_ID, 
          form.ITEM_NAME, 
          getResult.mainTargetUrl, 
          +form.MAIN_TYPE,
          +form.SUB_TYPE,
          +form.DETAIL_TYPE,
          getResult.previewTargetUrl,
          getResult.iconTargetUrl,
          form.TEXT,
          detailConfig,
          +form.STATUS).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          if (db.error === errorCode.SERVER_ERROR.code) {
            alert(`※  ${errorCode.SERVER_ERROR.memo}`)
            return
          }
          setPanelInfo([])
          setPopData({})
        })
      }
      if (addStatus) {
        addItemConfig(
          +form.ITEM_ID, 
          form.ITEM_NAME, 
          getResult.mainTargetUrl, 
          +form.MAIN_TYPE,
          +form.SUB_TYPE,
          +form.DETAIL_TYPE,
          getResult.previewTargetUrl,
          getResult.iconTargetUrl,
          form.TEXT,
          detailConfig,
          +form.STATUS).then(db => {
        if (db.error === errorCode.INCORRECT_DATA.code) {
            alert(`※  ${errorCode.INCORRECT_DATA.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.INCORRECT_FORMAT.code) {
            alert(`※  ${errorCode.INCORRECT_FORMAT.memo}，相關問題請洽詢工程部`)
            return
          }
          if (db.error === errorCode.EXCEPTION.code) {
            alert(`※  ${errorCode.EXCEPTION.memo}`)
            return
          }
          if (db.error === errorCode.SERVER_ERROR.code) {
            alert(`※  ${errorCode.SERVER_ERROR.memo}`)
            return
          }
          setPanelInfo([])
          setPopData({})
        })
      }

      cleanData()
      handleAddReviseClose()
    })    
  }

  const handleBack = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      handleAddReviseClose()
      setTimeout(() => {
        if (addStatus) {
          cleanData()
        }
        if (!addStatus) {
          setForm(JSON.parse(JSON.stringify(popData)))
          let configJSON = JSON.parse(popData.DETAIL_CONFIG)
          setConfigGroup({
            effectTimeHour: configJSON.effectTime ? (configJSON.effectTime / 60) : 0,
            effectTimeMin: configJSON.effectTime ? (configJSON.effectTime % 60) : 0,
            expiryStartDate: configJSON.expiryStart ? configJSON.expiryStart : null,
            expiryStartTime: configJSON.expiryStart ? configJSON.expiryStart : null,
            expiryEndDate: configJSON.expiryEnd ? configJSON.expiryEnd : null,
            expiryEndTime: configJSON.expiryEnd ? configJSON.expiryEnd : null,
            ammountLimit: configJSON.ammountLimit ? configJSON.ammountLimit : 0, 
            ifCanSale: configJSON.ifCanSale ? configJSON.ifCanSale : 0, 
            currency: configJSON.currency ? configJSON.currency : "gold", 
            price: configJSON.price ? configJSON.price : 0
          })
          let effectSetting = configJSON.effectSetting;
          setEffectSettingGroup({
            targetType: effectSetting ? effectSetting.targetType : "gold",
            changeType: effectSetting ? effectSetting.changeType : "plus",
            itemId: effectSetting ? effectSetting.itemId : null,
            getNum: effectSetting ? effectSetting.getNum : null,
            multi: effectSetting ? effectSetting.multi : 1, 
            usedAtOnce: effectSetting ? effectSetting.usedAtOnce : 1
          })
          let mergeSetting = configJSON.mergeSetting;
          setMergeSettingGroup({
            ifCanMerge: mergeSetting ? mergeSetting.ifCanMerge : 1,
            cost: mergeSetting ? mergeSetting.cost : null,
            convertTarget: mergeSetting ? mergeSetting.convertTarget : "gold",
            itemId: mergeSetting ? mergeSetting.itemId : null,
            convertNum: mergeSetting ? mergeSetting.convertNum : null
          })
          let exchangeSetting = configJSON.exchangeSetting;
          setExchangeSettingGroup({
            personalInfo: exchangeSetting ? +exchangeSetting.personalInfo : 0
          })
        }
      }, 500)
    })
  }

  const handleOnHide = () => {
    handleAddReviseClose()
    if (addStatus) {
      cleanData()
    }
  }

  function handleMainType() {
    let list = []
    for (let i = 0; i < itemMainType.length; i++) {
      list.push(<option key={itemMainType[i].mainType} value= {itemMainType[i].mainType}>{itemMainType[i].mainName}</option>)
    }    
    return list
  }

  function handleSubType(mainType) {
    let list = []
    for (let i = 0; i < itemMainType.length; i++) {
      if (mainType == itemMainType[i].mainType) {
        let subItem = itemMainType[i].subItem
        for (let j = 0; j < subItem.length; j++) {
          list.push(<option value= {subItem[j].subType}>{subItem[j].subName}</option>)
        }
      }
    }    
    return list
  }

  function handleDetailType(mainType, subType) {
    let list = []
    for (let i = 0; i < itemMainType.length; i++) {
      if (mainType == itemMainType[i].mainType) {
        let subItem = itemMainType[i].subItem
        for (let j = 0; j < subItem.length; j++) {
          if (subType == subItem[j].subType) {
            let detailItem = subItem[j].detailItem
            for (let k = 0; k < detailItem.length; k++) {
              list.push(<option value= {detailItem[k].detailType}>{detailItem[k].detailName}</option>)
            }
          }          
        }
      }
    }
    return list
  }

  const handlePersonalInfo = () => {
    let state = +exchangeSettingGroup.personalInfo
    if (state) handleExchangeSettingGroup(0, "personalInfo");
    else handleExchangeSettingGroup(1, "personalInfo");
  }

  return (
    <>
      <ModalFormat
        show={showAddRevise}
        onHide={handleOnHide}
        backdrop="static"
        keyboard={false}
        size= "lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{addStatus ? '新增' : '修改'}道具</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <PULeftTitle>道具ID</PULeftTitle>
                <td>
                  <PUInput disabled={addStatus ? false : true} placeholder= '請輸入數字' value= {form.ITEM_ID} onChange= {e => {handleForm(e, 'ITEM_ID')}}/>
                </td>
              </tr>
              <tr>
                <PULeftTitle>道具名稱</PULeftTitle>
                <td>
                  <PUInput placeholder= '請輸入名稱' value= {form.ITEM_NAME} onChange= {e => {handleForm(e, 'ITEM_NAME')}}/>
                </td>
              </tr>
              <tr>
                <PULeftTitle>道具大圖</PULeftTitle>
                <td style={{display : "flex", alignItems: "center"}}>
                  <PopFrame>
                    <Avatar
                      src={form.MAIN_IMG}
                      className="rounded"
                    />
                    <NoImg style= {form.MAIN_IMG ? {display: 'none'} : {display: 'block'}}>無示意圖</NoImg>             
                  </PopFrame>
                  <LabelBtn>
                    <span>上傳</span>
                    <input type= "file" name= "file" style={{display: "none", cursor: 'pointer'}} onChange= {handleUploadMainImg}/>
                  </LabelBtn>
                </td>
              </tr>
              <tr>
                <PULeftTitle>道具ICON</PULeftTitle>
                <td style={{display : "flex", alignItems: "center"}}>
                  <PopFrame>
                    <Avatar
                      src={form.ICON_IMG}
                      className="rounded"
                    />
                    <NoImg style= {form.ICON_IMG ? {display: 'none'} : {display: 'block'}}>無示意圖</NoImg>
                  </PopFrame>
                  <LabelBtn>
                    <span>上傳</span>
                    <input type= "file" name= "file" style={{display: "none", cursor: 'pointer'}} onChange= {handleUploadIconImg}/>
                  </LabelBtn>
                </td>
              </tr>
              <tr>
                <PULeftTitle>預覽圖</PULeftTitle>
                <td style={{display : "flex", alignItems: "center"}}>
                  <PopFrame>
                    <Avatar
                      src={form.PREVIEW_IMG}
                      className="rounded"
                    />
                    <NoImg style= {form.PREVIEW_IMG ? {display: 'none'} : {display: 'block'}}>無示意圖</NoImg>
                  </PopFrame>
                  <LabelBtn>
                    <span>上傳</span>
                    <input type= "file" name= "file" style={{display: "none", cursor: 'pointer'}} onChange= {handleUploadPreviewImg}/>
                  </LabelBtn>
                </td>
              </tr>
              <tr>
                <PULeftTitle>道具說明</PULeftTitle>
                <td>
                  <textarea
                    className="form-control"
                    rows="7"
                    value= {form.TEXT}
                    onChange= {e => {handleForm(e, 'TEXT')}}
                  />
                </td>
              </tr>
              <tr>
                <PULeftTitle>類型</PULeftTitle>
                <td>
                  <PUSelect value= {form.MAIN_TYPE} onChange= {e => {handleMainTypeChange(e, 'MAIN_TYPE')}}>
                    {handleMainType()}
                  </PUSelect>
                </td>
              </tr>
              <tr style = {form.MAIN_TYPE == 1 ? {} : {display: 'none'}}>
                <PULeftTitle>時效性<br/>固定時間</PULeftTitle>
                <td>
                  <PUInput placeholder= "請輸入數字" value= {configGroup.effectTimeHour} onChange= {e => {handleConfigGroup(e, 'effectTimeHour')}}/>
                  &nbsp;時;&nbsp;&nbsp;
                  <PUInput placeholder= "請輸入數字" value= {configGroup.effectTimeMin} onChange= {e => {handleConfigGroup(e, 'effectTimeMin')}}/>
                  &nbsp;分&nbsp;&nbsp;
                </td>
              </tr>
              <tr style = {form.MAIN_TYPE != 2 ? {display: 'none'} : {}}>
                <PULeftTitle>材料合成設定</PULeftTitle>
                <td>
                  是否可合成&nbsp;
                  <PUSelect value= {mergeSettingGroup.ifCanMerge} onChange= {e => {handleMergeSettingGroup(e, 'ifCanMerge')}}>
                    <option value= "1">是</option>
                    <option value= "0">否</option>
                  </PUSelect>
                  &nbsp;&nbsp;每次合成消耗&nbsp;
                  <PUInputOwn placeholder= "請輸入數字" value= {mergeSettingGroup.cost == null ? '' : mergeSettingGroup.cost} onChange= {e => {handleMergeSettingGroup(e, 'cost')}}/>
                  &nbsp;個碎片
                  &nbsp;&nbsp;合成後變為&nbsp;
                  <PUSelect value= {mergeSettingGroup.convertTarget} onChange= {e => {handleMergeSettingGroup(e, 'convertTarget')}}>
                    <option value= "gold">金幣</option>
                    <option value= "gem">鬥陣寶</option>
                    <option value= "item">道具</option>
                  </PUSelect>
                  &nbsp;&nbsp;
                  <PUInputOwn placeholder= "請輸入數字" value= {mergeSettingGroup.convertNum == null ? '' : mergeSettingGroup.convertNum} onChange= {e => {handleMergeSettingGroup(e, 'convertNum')}}/>
                  &nbsp;個
                  &nbsp;&nbsp;若為道具
                  <PUInputOwn placeholder= "請輸入道具ID" value= {mergeSettingGroup.itemId} onChange= {e => {handleMergeSettingGroup(e, 'itemId')}}/>
                </td>
              </tr>
              <tr style = {form.MAIN_TYPE == 3 || form.MAIN_TYPE == 4 ? {display: 'none'} : {}}>
                <PULeftTitle>{form.MAIN_TYPE == 1 ? '' : '材料'}時效性<br/>起始時間</PULeftTitle>
                <td>
                  <DateTimeWrapper>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label= "日期"
                        value={configGroup.expiryStartDate}
                        onChange={(newValue) => {
                          setConfigGroup(prev => ({
                            ...prev,
                            expiryStartDate: newValue
                          }))
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇日期'
                          return (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <PUInput id= "datePicker1" ref={inputRef} {...inputProps} />
                              <div id="dateBtn1">{InputProps?.endAdornment}</div>
                            </Box>
                          )
                        }}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="時間"
                        value={configGroup.expiryStartTime}
                        onChange={(newValue) => {
                          setConfigGroup(prev => ({
                            ...prev,
                            expiryStartTime: newValue
                          }))
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇時間'
                          return (
                            <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                              <PUInput id= "timePicker1" ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </TimeFormat>
                          )
                        }}
                      />
                    </LocalizationProvider>
                  </DateTimeWrapper>
                </td>
              </tr>
              <tr style = {form.MAIN_TYPE == 3 ? {display: 'none'} : {}}>
                <PULeftTitle>{form.MAIN_TYPE == 2 ? "材料" : ""}時效性<br/>結束時間</PULeftTitle>
                <td>
                  <DateTimeWrapper>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label= "日期"
                        value={configGroup.expiryEndDate}
                        onChange={(newValue) => {
                          setConfigGroup(prev => ({
                            ...prev,
                            expiryEndDate: newValue
                          }))
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇日期'
                          return (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <PUInput id= "datePicker2" ref={inputRef} {...inputProps} />
                              <div id="dateBtn2">{InputProps?.endAdornment}</div>
                            </Box>
                          )
                        }}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="時間"
                        value={configGroup.expiryEndTime}
                        onChange={(newValue) => {
                          setConfigGroup(prev => ({
                            ...prev,
                            expiryEndTime: newValue
                          }))
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => {
                          inputProps.placeholder = '請選擇時間'
                          return (
                            <TimeFormat sx={{ display: 'flex', alignItems: 'center' }}>
                              <PUInput id= "timePicker2" ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </TimeFormat>
                          )
                        }}
                      />
                    </LocalizationProvider>
                  </DateTimeWrapper>
                </td>
              </tr>
              <tr style = {form.MAIN_TYPE == 3 ? {display: 'none'} : {}}>
                <PULeftTitle>{form.MAIN_TYPE == 1 ? '道具' : form.MAIN_TYPE == 2 ? '材料' : "兌換"}持有上限</PULeftTitle>
                <td>
                  <PUInput placeholder= "請輸入數字" value= {configGroup.ammountLimit == 0 ? '' : configGroup.ammountLimit} onChange= {e => {handleConfigGroup(e, 'ammountLimit')}}/>
                  &nbsp;個
                </td>
              </tr>
              <tr style = {form.MAIN_TYPE == 3 ? {display: 'none'} : {}}>
                <PULeftTitle>{form.MAIN_TYPE == 1 ? '道具' : form.MAIN_TYPE == 2 ? '材料' : "兌換"}販售設定</PULeftTitle>
                <td>
                  是否可販售&nbsp;
                  <PUSelect value= {configGroup.ifCanSale} onChange= {e => {handleConfigGroup(e, 'ifCanSale')}}>
                    <option value= "1">是</option>
                    <option value= "0">否</option>
                  </PUSelect>
                  &nbsp;&nbsp;販售貨幣&nbsp;
                  <PUSelect value= {configGroup.currency} onChange= {e => {handleConfigGroup(e, 'currency')}}>
                    <option value= "gold">金幣</option>
                    <option value= "gem">鬥陣寶</option>
                  </PUSelect>
                  &nbsp;&nbsp;販售比&nbsp;1&nbsp;:&nbsp;
                  <PUInputOwn placeholder= "請輸入數字" value= {configGroup.price} onChange= {e => {handleConfigGroup(e, 'price')}}/>
                  &nbsp;個
                </td>
              </tr>
              <tr style = {form.MAIN_TYPE != 3 ? {display: 'none'} : {}}>
                <PULeftTitle>造型設定</PULeftTitle>
                <td>
                  造型分類&nbsp;
                  <PUSelect value= {form.SUB_TYPE} onChange= {e => {handleSubTypeChange(e, 'SUB_TYPE')}}>
                    {handleSubType(form.MAIN_TYPE)}
                  </PUSelect>
                  &nbsp;&nbsp;細項分類&nbsp;
                  <PUSelect value= {form.DETAIL_TYPE} onChange= {e => {handleForm(e, 'DETAIL_TYPE')}}>
                    {handleDetailType(form.MAIN_TYPE, form.SUB_TYPE)}
                  </PUSelect>
                </td>
              </tr>
              <tr style = {form.MAIN_TYPE != 1 ? {display: 'none'} : {}}>
                <PULeftTitle>每次使用消耗</PULeftTitle>
                <td>
                  可同時使用多個&nbsp;
                  <PUSelect value= {effectSettingGroup.usedAtOnce} onChange= {e => {handleEffectSettingGroup(e, 'usedAtOnce')}}>
                    <option value= "1">是</option>
                    <option value= "0">否</option>
                  </PUSelect>
                </td>
              </tr>
              <tr style = {form.MAIN_TYPE != 1 ? {display: 'none'} : {}}>
                <PULeftTitle>使用效果</PULeftTitle>
                <td>
                  <PUSelect value= {optionChange.itemType} onChange= {e => {handleOptionChange(e, 'itemType')}}>
                    <option value= "1">個人資訊</option>
                    <option value= "2">遊戲</option>
                    <option value= "3">活動</option>
                  </PUSelect>
                  &nbsp;&nbsp;的&nbsp;
                  <PUSelect value= {effectSettingGroup.targetType} onChange= {e => {handleEffectSettingGroup(e, 'targetType')}}>
                    <option display={ optionChange.itemType == 1 ? '' : 'none'} value= "nickName">暱稱更改次數</option>
                    <option display={ optionChange.itemType == 1 ? '' : 'none'} value= "gold">持有金幣</option>
                    <option display={ optionChange.itemType == 1 ? '' : 'none'} value= "gem">持有鬥陣寶</option>
                    <option display={ optionChange.itemType == 1 ? '' : 'none'} value= "item">道具</option>
                    <option display={ optionChange.itemType == 2 ? '' : 'none'} value= "missionReset">任務重置卷</option>
                    <option display={ optionChange.itemType == 3 ? '' : 'none'} value= "lotteryChance">活動積分</option>
                    <option display={ optionChange.itemType == 3 ? '' : 'none'} value= "lotteryPoint">集氣條次數</option>
                  </PUSelect>
                  &nbsp;
                  <PUSelect value= {effectSettingGroup.changeType} onChange= {e => {handleEffectSettingGroup(e, 'changeType')}}>
                    <option value= "plus">增加</option>
                    <option value= "minus">減少</option>
                    <option value= "equal">直接設定</option>
                  </PUSelect>
                  &nbsp;
                  <PUInputOwn placeholder= "請輸入數字" value= {effectSettingGroup.getNum == null ? '' : effectSettingGroup.getNum} onChange= {e => {handleEffectSettingGroup(e, 'getNum')}}/>
                  &nbsp;
                  <PUSelectOwn value= {effectSettingGroup.multi} onChange= {e => {handleEffectSettingGroup(e, 'multi')}}>
                    <option value= "1">個</option>
                    <option value= "10">十</option>
                    <option value= "100">百</option>
                    <option value= "1000">千</option>
                    <option value= "10000">萬</option>
                  </PUSelectOwn>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  若為道具&nbsp;
                  <PUInput placeholder= "請輸入道具ID" value= {effectSettingGroup.itemId == null ? '' : effectSettingGroup.itemId} onChange= {e => {handleEffectSettingGroup(e, 'itemId')}}/>
                </td>
              </tr>
              <tr style={form.MAIN_TYPE != 4 ? { display: "none" } : {}}>
                <PULeftTitle>兌換設定</PULeftTitle>
                <td>
                  <input type="checkBox" onChange={ handlePersonalInfo } checked={exchangeSettingGroup.personalInfo === 1 ? true : false}></input>&nbsp;需要輸入個資
                </td>
              </tr>
              <tr>
                <PULeftTitle>道具開關</PULeftTitle>
                <td>
                  <PUSelect value= {form.STATUS} onChange= {e => {handleForm(e, 'STATUS')}}>
                    <option value= "1">開</option>
                    <option value= "0">關</option>
                  </PUSelect>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <ModalBtn>
          <Button variant="secondary" onClick= {handleBack}>取消</Button>
          <Button variant="info" onClick= {handleSubmit}>{addStatus ? '新增' : '儲存'}</Button>          
        </ModalBtn>
      </ModalFormat>
    </>
  )
}