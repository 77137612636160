import { getAuthToken } from '../../utils.js'
import { BaseUrl } from "../../../assets/config/urlForBuild"

//? 取得活動用戶列表 PID68 ?//
export const getEventUserList = (activityId, uid, beginRowNum, endRowNum ) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/eventUserList?activityId=${activityId}&uid=${uid}&beginRowNum=${beginRowNum}&endRowNum=${endRowNum}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}


//? 更改用戶活動次數 PID69 ?//
export const reviseUserEventLimit = (activityId, uid, modifyNum, modifyType) => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/eventUserList`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      activityId,
      uid,
      modifyNum,
      modifyType
    })
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}